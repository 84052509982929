import React from 'react';
import {
  Vanpool,
  Car,
  Train,
  Rail,
  Boat,
  Bus,
  Taxi,
  Bike,
  Walk,
  Block
} from '../../shared/icons';
import dateUtility from '../../shared/dateUtility';
import classList from '../../shared/classList';

const options = [
  'Vanpool',
  'Car',
  'Carpool (Driver)',
  'Carpool (Rider)',
  'Commuter Rail',
  'Light Rail',
  'Ferry',
  'Bus',
  'Ride-Hailing (TNC)',
  'Bike',
  'Walk',
  'Did not commute'
];
const icons = {
  Vanpool: <Vanpool className="less-shift" />,
  Car: <Car />,
  'Carpool (Driver)': <Car />,
  'Carpool (Rider)': <Car />,
  'Commuter Rail': <Train className="less-shift" />,
  'Light Rail': <Rail className="less-shift" />,
  Ferry: <Boat />,
  Bus: <Bus className="less-shift" />,
  'Ride-Hailing (TNC)': <Taxi />,
  Bike: <Bike />,
  Walk: <Walk />,
  'Did not commute': <Block className="less-shift" />
};

const labels = {
  'Ride-Hailing (TNC)': 'Ride-Hailing TNC',
  'Carpool (Driver)': 'Carpool Driver',
  'Carpool (Rider)': 'Carpool Rider',
  'Did not commute': 'Did Not Commute'
};

function DayStatusEditor({ value, onChange }) {
  return (
    <div className="editor">
      {options.map(option => (
        <div
          className={`icon ${value === option ? 'selected' : ''}`}
          key={option}
          onClick={e => e.stopPropagation() || onChange(option)}
        >
          {icons[option]}
          <span
            className={`label ${option
              .toLowerCase()
              .split(' ')
              .join('-')}`}
          >
            {option in labels ? labels[option] : option}
          </span>
        </div>
      ))}
    </div>
  );
}

export function EmptyDay() {
  return <div className="day empty future"></div>;
}

export default function DayStatus({
  date,
  toWork,
  toHome,
  saved,
  editable,
  orientation,
  differentToWork,
  differentToHome,
  selected,
  select,
  change
}) {
  const current = dateUtility.isToday(date);
  const future = dateUtility.afterToday(date);
  const dayOfWeek = dateUtility.dayOfWeek(date);
  const dayOfMonth = parseInt(date.split('-').pop(), 10);

  return (
    <div
      className={classList('day', dayOfWeek.toLowerCase(), {
        future,
        current,
        saved,
        empty: !editable
      })}
    >
      <div className="dow mobile-only">{dayOfWeek}</div>
      <div className="date">{dayOfMonth}</div>
      <div
        className={classList('to-work', {
          selected: selected.date === date && selected.work,
          different: differentToWork,
          greyed: ['R', 'W'].includes(orientation)
        })}
        onClick={e => editable && (e.stopPropagation() || select(date, 'work'))}
      >
        {icons[toWork]}
        {selected.date === date && selected.work ? (
          <DayStatusEditor
            value={toWork}
            onChange={value => change(date, 'work', value)}
          />
        ) : null}
      </div>
      <div
        className={classList('to-home', {
          selected: selected.date === date && selected.home,
          different: differentToHome,
          greyed: ['R', 'H'].includes(orientation)
        })}
        onClick={e => editable && (e.stopPropagation() || select(date, 'home'))}
      >
        {icons[toHome]}
        {selected.date === date && selected.home ? (
          <DayStatusEditor
            value={toHome}
            onChange={value => change(date, 'home', value)}
          />
        ) : null}
      </div>
    </div>
  );
}
