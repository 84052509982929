import { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import dateUtility from '../shared/dateUtility';
import analyticsService from '../shared/analytics/analytics-service';

export default function AlertsCollapsible(props) {
    const { alert, markRead } = props;
    const [isOpen, setIsOpen] = useState(false);

    const getDate = (date) => {
        const today = dateUtility.isToday(date);
        if (today) {
            return "Today";
        } else {
            return dateUtility.parseDateAsMonthDay(date);
        }
    }

    const isRead = () => {
        if (!isOpen) {
            if (!alert.isRead) {
                markRead(alert);
            }
        }
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        if(isOpen){
            analyticsService.analyticsProcessEvent({
                "event": "click_generic",
                "context": {
                    "event_action": "expand alert"
                }
            })
        }
    },[isOpen])

    function createMarkup(description){
        return {__html: description};
      }

    function Heading() {
        let tempClass = 'arrow ';
        tempClass += isOpen ? 'up' : 'down';
        return (
            <div className='collapsible-heading-container' aria-expanded={isOpen} aria-controls={alert.alertId} tabIndex={0} role='button' key={props.index}>
                <div className='header-content'>
                    <div className='heading desktop'>
                        <div className={`circle ${alert.isRead ? 'read' : 'unread'}`}></div>
                        <div className='right-section'>
                            <span className='summary-text'>{alert.summary}</span>
                            <div className='createdDate'>
                                {getDate(alert.createdDate)}
                            </div>
                        </div>
                    </div>
                </div>

                <i className={tempClass}></i>
            </div>
        );
    }
    return (
        <Collapsible open={isOpen} className='large-collapsible-container' handleTriggerClick={() => { isRead() }} trigger={Heading()}>
            {isOpen && <div className='collapsible-content' id={alert.alertId}>
                <div className='content-container' dangerouslySetInnerHTML={createMarkup(alert.description)}>
                    {/* {alert.description} */}
                </div>
            </div>}
        </Collapsible>
    );
}