import * as actionTypes from '../redux/actionTypes';
import NA_VALUE from '../shared/na';

export const initialState = {
  summary: {
    monthlyAccountSummary: []
  },
  summaryError: false,
  accountSummaryLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ACCOUNT_SUMMARY_ERROR:
      return { ...state, summaryError: true, accountSummaryLoaded: false };
    case actionTypes.MONTHLY_ACCOUNT_SUMMARY_LOADED:
      const _summary = {
        ...state.summary,
        monthlyAccountSummary: action.data,
        summaryError: false,
        accountSummaryLoaded: true
      };
      return {
        ...state,
        summary: _summary
      };
    default:
      return state;
  }
};
