import localStorageService from '../shared/localStorageService';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const localEhiUserKey = 'EhiUser';

const localStorageKeys = ['login', 'passwordReset', 'mobileDeepLink', 'deeplink'];

const privateSearchCriteria = 'PrivateSearchCriteria';
  
const UserService = {};

UserService.getAuthentication = function() {
  return localStorageService.getJsonItem(localEhiUserKey);
};

UserService.getUserProfile = function() {
  if (localStorageService.getJsonItem(localEhiUserKey)) {
    return localStorageService.getJsonItem(localEhiUserKey).profile;
  }
};

UserService.setAuthorization = userProfile => {
  const storageItem = UserService.getAuthentication();
  const storage = storageItem
    ? { token: storageItem.token, profile: userProfile, account: storageItem.account, profileId: storageItem.profileId, accessToken: storageItem.accessToken  }
    : { profile: userProfile };
  localStorageService.setJsonItem(localEhiUserKey, storage);
};

UserService.setAccount = (account,profileId) => {
  const storageItem = UserService.getAuthentication();
  const storage = storageItem
    ? { token: storageItem.token, profile: storageItem.profile, account:account, profileId: profileId }
    : { account: account, profileId: profileId };
  localStorageService.setJsonItem(localEhiUserKey, storage);
};

UserService.setAccessToken = accessToken => {
  const storageItem = UserService.getAuthentication();
  const storage = storageItem
    ? { token: storageItem.token, profile: storageItem.profile, account: storageItem.account, profileId: storageItem.profileId, accessToken: accessToken }
    : { accessToken: accessToken };
  localStorageService.setJsonItem(localEhiUserKey, storage);
};


UserService.setAuthentication = token => {
  localStorageService.setJsonItem(localEhiUserKey, { token });
};

UserService.clearAuthentication = () => {
  localStorageService.clear(localEhiUserKey);
  localStorageKeys.forEach(localStorageService.clear);
  cookies.remove("ehiToken");
};

UserService.isAuthenticated = function() {
  return !!localStorageService.getJsonItem(localEhiUserKey);
};

UserService.hasPermissions = function(roles) {
  const permissions = localStorageService.getJsonItem(localEhiUserKey).profile
    .permissions;

  if (roles instanceof Array) {
    return roles.some(subrole => permissions.includes(subrole));
  }

  return permissions.includes(roles);
};

UserService.savePrivateSearchCriteria =  function(employerId, vanpoolInfo) {
  var searchCriteria = {
    employerId: employerId,
    vanpoolInfo: vanpoolInfo
  }
  localStorageService.setJsonItem(privateSearchCriteria, searchCriteria);
};

UserService.getPrivateSearchCriteria =  function() {
  if (localStorageService.getJsonItem(privateSearchCriteria)) {
    return localStorageService.getJsonItem(privateSearchCriteria);
  }
}



export default UserService;
