import HeroBanner from '../../../../_styles/images/HeroBanner_onboarding.png'

function FirstScreen(props) {

  return (
    <div className='screen-container'>
      <h1 className='heading'>
        Your seat is saved
      </h1>
      <div className='content-container'>
        <div className='content'>
          <div className='herobanner'>
            <img src={HeroBanner} aria-hidden='true'/>
          </div>
          <h2 className='sub-heading'>Welcome to your vanpool!</h2>
          {
            props.isPredelivery ?
            <p className='information'>Thanks for choosing Commute with Enterprise! We hope you're excited for your new vanpool.<br/><br/>One of our representatives will reach out with a start date as soon as more riders join.<br/>In the meantime, get ready to hit the road. Click NEXT to review your route and add a profile picture.</p>
            :
            <p className='information'>
              You’re on the road to a better commute fueled by Enterprise, <span className='sub-text'>a leading mobility provider</span>. You’ll receive an email from us with more information on what to expect on your first day. In the meantime, click through to review route details and see who will be riding with you. 
            </p>
          }
        </div>
      </div>
    </div>
  );
}

export default FirstScreen;