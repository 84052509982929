import Cone from '../../_styles/images/error/cone.svg';
import Trees from '../../_styles/images/error/Trees.svg';
import Trees2 from '../../_styles/images/error/Trees2.svg';
import Vans from '../../_styles/images/error/Vans.svg';
function ErrorScreen() {
  return (
    <div className='error-screen-container'>
      <div className='error-screen'>
        <div className='content'>

          <div className='heading'>
            <h2>We ran into an error trying to load this data</h2>
          </div>
          <div className='comment'>
            Please try again
          </div>
          <div className='picture'>
            <div className='left'>
              <img src={Trees} alt="error" />
              <img src={Trees2} alt="error" />
            </div>
            <div className='right'>
              <img src={Vans} alt="error" />
              <img src={Cone} alt="error" />
            </div>
          </div>
        </div>
        <div className='line' />
      </div>
    </div>
  );
}

export default ErrorScreen