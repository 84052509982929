import React from 'react';

// NOTE: switching to a div is to avoid accessibility issues with nested controls inside of a button

export default function Radio({
  children,
  onSelect,
  disabled,
  className,
  selected
}) {
  const props = {
    onKeyPress: e =>
      e.target === e.currentTarget && e.key === 'Enter' && onSelect(),
    onClick: onSelect,
    className: `radio-btn ${className ? className : ''} ${
      selected ? 'selected' : ''
    }`,
    disabled
  };

  if (selected) {
    return <div {...props}>{children}</div>;
  }

  return <button {...props}>{children}</button>;
}
