import React, { Component } from 'react';
import { connect } from 'react-redux';
import CurrencyFormatter from '../../../shared/currencyFormatter';
import DateUtility from '../../../shared/dateUtility';
import { CheckCircle } from '../../../shared/icons';
import * as actionTypes from '../../../redux/actionTypes';

class LastPayment extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    let lastPayment = this.props.lastPayment;
    if (lastPayment) {
      let totalCharges = lastPayment.totalCharges
        ? CurrencyFormatter.formatUSD(lastPayment.totalCharges)
        : "";
      let date = lastPayment.date
        ? DateUtility.parseDate(lastPayment.date)
        : "";
      let lastPaymentDisplay  =  totalCharges == "" &&  date == "" ? 'N/A' : `${totalCharges} on ${date}`
      return (
        <div className="last-payment">
          <div className="flex">
            <CheckCircle />
            <div>
              <h4>MY LAST PAYMENT</h4>
              <p>
               {lastPaymentDisplay}
              </p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="last-payment">
        <div className="flex">
          <CheckCircle />
          <div>
            <h4>MY LAST PAYMENT</h4>
            <p>
            {'N/A'}
            </p>
          </div>
        </div>
      </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    lastPayment: state.payments.lastPayment
  };
}
function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.LAST_PAYMENT_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LastPayment);
