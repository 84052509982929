import UserService from '../shared/user-service';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';

export default {
  isAuthorized: () => {
    const userProfile = UserService.getUserProfile();
    if (userProfile) {
      return isAllowed([
        PERMISSION_VANPOOL_STATUS_RENTAL,
        PERMISSION_ACCOUNT_DASHBOARD_ADMIN
      ]);
    }
    return false;
  }
};
