import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import InVanpool from './InVanpool';
import NotInVanpool from './NotInVanpool';
import VanpoolQuestion from './VanpoolQuestion';
import UserService from '../shared/user-service';
import CommuteUserContactDetails from './CommuteUserContactDetails';

const prefix = '/registration';

export default function Registration() {
  const profile = UserService.getUserProfile();

  if (profile.linkVanpool && !profile.isFederatedLogin && !profile.registrationComplete) {
    return (
      <div className="registration">
        <Switch>
          <Route path={prefix + '/vanpool'} component={InVanpool} />
          <Redirect to={prefix + '/vanpool'} />
        </Switch>
      </div>
    );
  }

  return (
    <div className="registration">
      <Switch>
        <Route path={prefix + '/vanpool'} component={InVanpool} />
        <Route path={prefix + '/commute-profile'} component={NotInVanpool} />
        <Route path={prefix + '/commute-user-contact-details'} component={CommuteUserContactDetails} />
        <Route path={prefix} component={VanpoolQuestion} />
        <Redirect to={prefix} />
      </Switch>
    </div>
  );
}
