import Loading from '../../shared/loading/Loading';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import { getFullDate } from '../helpers/commonHelper';
import DiscardChanges from '../../profile/components/common/discardChangesPopup/DiscardChanges';
import { useRef } from 'react';
import DateInput from '../../shared/inputs/DateInput';
import analyticsService from '../../shared/analytics/analytics-service';
import AddressSection from '../../profile/components/common/AddressSection';
import { isEmpty, isEqual } from 'lodash';
import { initialJoinCommuteState } from '../JoinCommuteReducer';
import { getDateObjFromString, getDateString } from '../../payment-history/helper';
import dateUtility from '../../shared/dateUtility';
import { getAgeFromDOB } from '../../shared/helpers/dateHelpers';

function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    [ref, handler]
  );
}

function ApplyToCoordinate(props) {

  const navigationAlertNode = useRef();

  const [birthDate, updateBirthDate] = useState(null);
  const [birthDateString, updateBirthDateString] = useState('');
  const [isDateError, updateIsDateError] = useState(false);
  const [homeAddress, updateHomeAddress] = useState({});
  const [homeAddressValid, updateHomeAddressValid] = useState(true);
  const [continueDisabled, updateContinueDisabled] = useState(true);
  const [isDataChanged, updateIsDataChanged] = useState(false);
  const [isfutureError, updateIsfutureError] = useState(false);

  function updateDate(dateInfo) {
    updateBirthDateString(dateInfo.value);
    updateIsDateError(!dateInfo.valid);
  }

  useEffect(() => {
    if (isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    }
    analyticsService.pageInfo("apply to coordinate");
  }, []);

  useEffect(() => {
    if (birthDateString.length === 10) {
      const date = new Date(birthDateString);
      updateBirthDate(date);
      const today = new Date();
      if (date > today) {
        updateIsfutureError(true);
      } else {
        updateIsfutureError(false);
      }
      const day = getFullDate(date);
      updateBirthDate(day);
      updateIsDateError(false);
    } else {
      updateIsDateError(true);
      updateIsfutureError(false);
    }

  }, [birthDateString])

  function onHomeAddressChange(value, valid) {
    updateHomeAddress(value);
    const prevHomeAddressValid = homeAddressValid;
    updateHomeAddressValid(valid);
    if (value !== "" && prevHomeAddressValid !== valid && !valid) {
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": "Please enter a valid address"
        }
      })
    }
  }

  function resetState() {
    updateHomeAddress({});
    updateBirthDate({});
  }

  useEffect(() => {
    if (props.isDesiredDriverRole) {
      if (homeAddress && !isEmpty(homeAddress) && homeAddressValid) {
        updateContinueDisabled(false);
      } else {
        updateContinueDisabled(true);
      }
      if (!isEmpty(homeAddress)) {
        updateIsDataChanged(true);
      } else {
        updateIsDataChanged(false);
      }
    } else {
      if (homeAddress && !isEmpty(homeAddress) && birthDate && !isEmpty(birthDate) && homeAddressValid && !isDateError && !isfutureError) {
        updateContinueDisabled(false);
      } else {
        updateContinueDisabled(true);
      }
      if (!isEmpty(homeAddress) || !isEmpty(birthDate)) {
        updateIsDataChanged(true);
      } else {
        updateIsDataChanged(false);
      }
    }
  }, [homeAddress, homeAddressValid, birthDate, isDateError, isfutureError])

  function setApplicationData() {
    let appData;
    const profilePhones = props.profile && props.profile.driverApplicationData && props.profile.driverApplicationData.phones && props.profile.driverApplicationData.phones;
    const phone = props && props.userAuth && props.userAuth.phone;
    if (phone) {
      phone.isPreferred = true;
    }
    if (props.isDesiredDriverRole) {
      if ((props.profile && props.profile.driverApplicationData && props.profile.driverApplicationData.apiData && !isEmpty(props.profile.driverApplicationData) && !isEmpty(props.profile.driverApplicationData.apiData))) {
        appData = props.profile.driverApplicationData;
      } else {
        appData = { apiData: { driverCoordInformation: {} } };
      }
      appData = {
        driverCoordInformation: {
          ...appData.apiData.driverCoordInformation,
          homeAddress,
          firstName: props && props.userAuth && props.userAuth.firstName,
          lastName: props && props.userAuth && props.userAuth.lastName,
          phones: (profilePhones && profilePhones.length > 0) ? profilePhones : phone ? [phone] : []
        }
      }
    } else {
      appData = {
        driverCoordInformation: {
          homeAddress,
          dateOfBirth: getDateString(birthDate),
          firstName: props && props.userAuth && props.userAuth.firstName,
          lastName: props && props.userAuth && props.userAuth.lastName,
          phones: [phone]
        }
      }
    }
    analyticsService.analyticsProcessEvent({
      "event": "apply_to_coordinate_click",
      "context": {
        "event_action": "continue"
      }
    });
    props.setApplicationData(appData);
    props.updateLoading(true);
    props.getDocusignContract(props.isDesiredDriverRole, props.selectedVan.vanpoolId, props.history, '/JoinCommute/add-payment');
  }

  useEffect(() => {
    if (props.driverApplicationData && props.driverApplicationData.apiData) {
      const appData = props.driverApplicationData.apiData.driverCoordInformation;
      const dateObj = getDateObjFromString(appData.dateOfBirth);
      const homeAddressFromStore = appData.homeAddress;
      updateHomeAddress(homeAddressFromStore);
      updateHomeAddressValid(true);
      if (dateObj && dateObj.date) {
        updateBirthDate(dateObj.date);
        const dateValue = dateUtility.formatexpenses(dateObj.date);
        updateDate({
          value: dateValue,
          valid: true
        });
      }
    }
  }, [props.driverApplicationData])

  return (
    <div className='profile-overview-page coordinator-page'>
      <Loading isLoading={props.isLoading} />
      <div className='profile-edit-page edit-license-page apply-coordinator-page'>
        <div className='view-container' role='group'>
          <div className='content-container' role='group'>
            <div className='heading'>Apply to Coordinate</div>
            <div className='small-heading'>Thank you for volunteering to coordinate a commute.<br />
              To complete your application, please provide the following information:</div>
            {!props.isDesiredDriverRole && <div className='state-date'>
              <div className='field'>
                <fieldset className='licence-picker-container'>
                  <div className='label spread'>
                    <label htmlFor="dateInput">Date of Birth*</label>
                  </div>
                  <DateInput
                    value={birthDateString}
                    aria-invalid={isfutureError} isError={isfutureError}
                    onChange={(date) => updateDate(date)}
                    required
                  />
                </fieldset>
              </div>
            </div>}
            <div>
              <AddressSection onChange={onHomeAddressChange} address={homeAddress} label="Home Address*" />
            </div>
            <button className='save-button' disabled={continueDisabled} onClick={setApplicationData}>Continue</button>
          </div>
        </div>
        <DiscardChanges
          heading='Unsaved Changes'
          text='Are you sure you want to continue? Any unsaved changes will be lost.'
          cancelButtonText='Cancel'
          confirmButtonText='Yes, continue'
          when={isDataChanged}
          goBack={() => {
            resetState();
            return props.history.goBack()}}
          shouldBlockNavigation={location => {
            if (isDataChanged) {
              if (location.pathname !== "/JoinCommute/apply/coordinator" && location.pathname !== "/agreement" && location.pathname !== "/JoinCommute/add-payment") {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          }}
          handleValidation={() => {
            return isDataChanged;
          }}
          reference={navigationAlertNode}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    isLoaded: state.profile.isLoaded,
    isLoading: state.joinCommute.loading,
    isDesiredDriverRole: state.joinCommute.drivingScheduleData.isDesiredDriverRole,
    selectedVan: state.joinCommute.selectedVan,
    userAuth: state.userAuth,
    joinCommuteData: state.joinCommute,
    driverApplicationData: state.profile.driverApplicationData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveSelectedPlan(selectedPackage) {
      dispatch({ type: actionTypes.SET_SELECTED_PLAN, data: selectedPackage });
    },
    updateLoading(data) {
      dispatch({ type: actionTypes.LOADER_ACTIVE, data });
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo) {
      dispatch({ type: actionTypes.APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo } })
    },
    setApplicationData(apiData) {
      dispatch({ type: actionTypes.SET_APPLY_TO_DRIVE_DATA, data: apiData });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyToCoordinate);