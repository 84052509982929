import React from 'react';
import { Bus } from '../shared/icons';
import StandardBarChart from './StandardBarChart';
import { GRID_TYPE_COMMUTE } from './chart-service';

export default function RecordedTripsChart(props) {
  const { timePeriod, data } = props;

  return (
    <div className="commute-modes-container">
      <div className="top-bar">
        <div className="label">
          <Bus className="less-shift" /> Primary Modes of Commute
        </div>
        <div className="selected-dates">{timePeriod}</div>
      </div>
      <div className="content">
        <ChartAnalysisLabel data={data.datasets} />
        <StandardBarChart
          gridData={data}
          height={100}
          displayType={GRID_TYPE_COMMUTE}
        />
      </div>
    </div>
  );
}

function ChartAnalysisLabel({ data }) {
  const isEmptyDataset = datasets => datasets.length === 0;
  return isEmptyDataset(data) ? (
    <div className="no-bar-data">No data for selected time period</div>
  ) : null;
}
