import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as actionTypes from '../../redux/actionTypes';
import PersonalInformation from '../PersonalInformation';
import QuestionYesNo from '../../shared/inputs/questionYesNo';
import DriverInformation from '../driver-application/DriverInformation';
import DocusignPopup from '../../shared/docusign/DocusignPopup';
import config from '../../appConfig';
import Callable from '../../shared/Callable';
import { getDateString } from '../../payment-history/helper';
import ContractAgreement from '../../contracts/contractAgreement';
import dateUtility from '../../shared/dateUtility';
import { addDays, getFullDate } from '../../join-commute/helpers/commonHelper';
import CoordinatorConfirmationPopup from '../ThankyouPopup';
import ThankyouScreen from '../ThankyouScreen'

export class CoordinatorApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applyToBeDriver: this.shouldApplyToBeDriver(),
      personalInformationIsValid: false,
      militaryLicense: false,
      foreignLicense: false,
      driverInformationIsValid: false,
      continueClicked: false,
      isSubscription: this.props.profile.isSubscription,
      loading: false,
      showThankyou: false
    };

    this.update = updates => this.setState(updates);
  }

  componentDidMount() {
    this.props.init();
  }


  getEffectiveDate() {
    const futureDate = addDays(30, new Date());
    const fullDate = getFullDate(futureDate);
    const dateString = getDateString(fullDate);
    return dateString;
  }


  save() {
    if (this.state.isSubscription) {
      let isDriver = this.state.applyToBeDriver ? true : false;
      let isPendingApplication = true;
      this.setState({ loading: true });
      this.props.getDocusignContract(isDriver, this.props.profile.vanpoolId, this.props.history, "/coordinator-application", isPendingApplication);
    } else {
      this.setState({ loading: true });
      this.props.save(this.state);
    }

  }

  shouldApplyToBeDriver() {
    return this.props.profile.isDriver || this.props.profile.isPendingDriver;
  }

  applyToBeDriverQuestion() {
    return !this.shouldApplyToBeDriver() ? (
      <QuestionYesNo
        className="apply-to-be-driver-question"
        question="Would you also like to apply to be a driver?"
        onChange={value => this.setState({ applyToBeDriver: value })}
        value={this.state.applyToBeDriver}
      />
    ) : null;
  }

  pendingNotification() {
    let title = '';
    let message = '';

    if (
      this.props.profile.isPendingCoordinator &&
      this.props.profile.isPendingDriver
    ) {
      title = `Vanpool Coordinator/Driver Application`;
      message = `Thank you for volunteering to be the Coordinator/Driver of your commute. Before you can proceed, please complete and sign the Vanpool Coordinator and Driver Application. Should you have any questions, contact us at `;
    } else if (this.props.profile.isPendingCoordinator) {
      title = `Vanpool Coordinator Application`;
      message = `Thank you for volunteering to be the Coordinator of your commute. Before you can proceed, please complete and sign the Vanpool Coordinator and Driver Application. Should you have any questions, contact us at `;
    }

    return (
      <div className="pending-notification">
        <h2>{title}</h2>
        <p>
          {message}
          <Callable phone="800-VAN-4-WORK" />
        </p>
        <div className="tools">
          <button
            id="continue"
            className="button primary"
            onClick={() => {
              this.setState({ continueClicked: true });
            }}
          >
            Continue
          </button>
        </div>
      </div>
    );
  }

  shouldShowPendingNofication() {
    return (
      this.props.profile.isPendingCoordinator && !this.state.continueClicked
    );
  }

  updateLoading(data) {
    this.setState({ loading: !this.state.loading });
    this.props.updateLoading(true);
  }

  render() {
    const {
      applyToBeDriver,
      personalInformationIsValid,
      driverInformationIsValid,
      loading
    } = this.state;
    let validInformation = applyToBeDriver
      ? personalInformationIsValid && driverInformationIsValid
      : personalInformationIsValid;


    if (this.props.contractInfo.agreementURL && !this.props.contractInfo.isAgreed) {
      return (
        <ContractAgreement fromCoordinatorApplication={true} loading={loading} contractInfo={this.props.contractInfo} history={this.props.history} agreementURL={this.props.contractInfo.agreementURL} saveAgreementDetails={this.props.saveAgreementUrl} navigateTo={"/dashboard"} updateLoading={() => this.updateLoading} updateContractStatus={(data) => {}} isPendingApplication={true} />
      )
    }
    if (this.props.profile.isPendingCoordinator && this.props.contractInfo.agreementId && this.props.contractInfo.isAgreed && this.props.contractInfo.isPendingApplication) {
      let phones = [];
      let primaryPhone = this.state.phone;
      primaryPhone.isPreferred = true;
      phones.push(primaryPhone);
      let secondaryPhone = this.state.secondPhone;
      if(secondaryPhone && secondaryPhone.number ) {
          secondaryPhone.isPreferred = false;
        phones.push(this.state.secondPhone)
      }
      const payload = {
        dateOfBirth: dateUtility.parseDateYYYY(this.state.birthDate),
        firstName: this.props.profile.firstName,
        lastName: this.props.profile.lastName,
        homeAddress: this.state.homeAddress,
        isCoordinatorApplication: true,
        isDriverApplication: this.state.applyToBeDriver,
        phones
      }
      if(this.state.applyToBeDriver){
        payload.licenses = this.state.licenses.map((license, i) => ({
          ...license,
          isLatestLicense: i === 0
        }));
        payload.isActiveMilitary = this.state.militaryLicense ? true : false;
        payload. isForeignLicense = this.state.foreignLicense;
      }
      const application = 'coordinator';
      this.props.SubmitCoordinatorApplication(payload, this.props.history, '/dashboard', application);
      this.props.saveAgreementUrl({ isPendingApplication: false, agreementURL: null, agreementId: this.props.contractInfo.agreementId, clickwrapId: this.props.contractInfo.clickwrapId, isAgreed: this.props.contractInfo.isAgreed })
    }

    return (
      <div>
        {this.shouldShowPendingNofication() ? (
          this.pendingNotification()
        ) : (
          <div className="coordinator-application">
            <h2>Coordinator Application</h2>
            <p>
              You have requested to be a vanpool coordinator. Please complete
              the below form. All fields are required.
            </p>
            <PersonalInformation onChange={this.update} />
            {this.applyToBeDriverQuestion()}
            {applyToBeDriver ? (
              <DriverInformation onChange={this.update} />
            ) : null}
            <div className="tools">
              <a className="button cancel" href={config.brochureUrl}>
                Cancel
              </a>
              <button
                className="button primary save"
                onClick={() => this.save()}
                disabled={!validInformation}
              >
                Review &amp; Sign
              </button>
            </div>
            <DocusignPopup
              status={url => this.props.status(url)}
              loading={loading}
              redirectUrl={'/'}
              cancelRedirectUrl={'/login'}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.userAuth,
    contractInfo: state.joinCommute.contractInfo,
    subscriptionSummary: state.PaymentOverview.subscriptionSummary,
    commuteProfile: state.commuteProfile,
    show: state.application.showCoordinatorThankyou
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    save(data) {
      dispatch({ type: actionTypes.SUBMIT_COORDINATOR_APPLICATION, data });
    },
    status(url) {
      dispatch({
        type: actionTypes.UPDATE_COORDINATOR_APPLICATION_DOCUSIGN_STATUS,
        data: url
      });
    },
    setApplicationData(apiData, secondaryData) {
      dispatch({ type: actionTypes.SET_APPLY_TO_DRIVE_DATA, data: apiData, secondaryData });
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo, isPendingApplication) {
      dispatch({ type: actionTypes.APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo, isPendingApplication } })
    },
    applyToDrive(data, history, navigateTo) {
      dispatch({ type: actionTypes.APPLY_TO_DRIVE, data, history, navigateTo })
    },
    saveAgreementUrl(data) {
      dispatch({
        type: actionTypes.SAVE_CONTRACT_DETAILS,
        data
      });
    },
    updateContractStatus(data) {
      dispatch({ type: actionTypes.UPDATE_CONTRACT_CLICKWRAP_STATUS, data: data });
    },
    updateLoading(data) {
      dispatch({ type: actionTypes.LOADER_ACTIVE, data });
    },
    init() {
      dispatch({ type: actionTypes.COMMUTE_PROFILE_LOADING });
      dispatch({ type: actionTypes.USER_PERSONAL_INFO_LOADING });
    },
    SubmitCoordinatorApplication(data, history, navigateTo, application) {
      dispatch({ type: actionTypes.SUMBIT_SUBSCRIPTION_COORDINATOR_APPLICATION, data, history, navigateTo, application })
    },
    refreshDashboard(){
      dispatch({ type: actionTypes.UPDATE_USER_PROFILE_DATA });
      dispatch({ type: actionTypes.SET_REFRESH_DASHBOARD, refresh:true });
    },
    closeConfirmation() {
      dispatch({ type: actionTypes.SHOW_COORDINATOR_THANKYOU_SCREEN, data: false});
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoordinatorApplication);
