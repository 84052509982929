import React, { Component } from 'react';
import Profile from '../../shared/profile-image';

export class Vanpooler extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
    this.opening = false;

    this.close = () => {
      this.setState({ open: this.opening });
      this.opening = false;
    };
  }

  componentDidMount() {
    window.addEventListener('click', this.close);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
  }

  render() {
    const { firstName, lastName, driver } = this.props;
    const { open } = this.state;

    return (
      <div
        className={`vanpooler ${open ? 'open' : ''}`}
        onClick={() => (this.opening = !open)}
      >
        <Profile>{firstName + ' ' + lastName}</Profile>
        <div className="name">
          {firstName + ' ' + lastName}
          {driver ? <div className="driver">Driver</div> : null}
        </div>
      </div>
    );
  }
}

export default function({ vanpoolers }) {
  return (
    <div className="vanpoolers">
      {vanpoolers.map(vanpooler => (
        <Vanpooler {...vanpooler} key={vanpooler.id} />
      ))}
    </div>
  );
}
