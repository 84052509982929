import { call, put, select, takeLatest } from "redux-saga/effects";
import * as actionTypes from '../redux/actionTypes';
import config from "../appConfig";
import Http from "../shared/http-service";
import { getMonthFromDateString, getYearFromDateString } from "../shared/helpers/dateHelpers";
import { weekdays_lower } from "../join-commute/helpers/commonHelper";
import logger from "../shared/logger";
import dateUtility from "../shared/dateUtility";
import { delay } from "core-js";
import UserService from "../shared/user-service";

export default function* DriverScheduleSaga() {
    yield takeLatest(actionTypes.GET_DRIVER_SCHEDULE, getDriverSchedule);
    yield takeLatest(actionTypes.UPDATE_DRIVER_PREFERENCES, updateDriverPreferences);
    yield takeLatest(actionTypes.UPDATE_DRIVER_SCHEDULE, updateDriverSchedule);
    yield takeLatest(actionTypes.GET_DRIVER_PREFERENCES, getDriverPreferences);
}

const driver_url = `${config.kongApiBaseUrl}/driver`

function* getDriverSchedule() {
    const user = UserService.getUserProfile();
    if (user.isInVanpool) {
        try {
            const response = yield call(() => Http.get(`${driver_url}/schedule`));
            const state = yield select();
            let participants = [];
            if (state.roster.participants && state.roster.participants.length) {
                participants = state.roster.rawData;
            } else {
                const participantResponse = yield call(() => Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/participants`));
                if (participantResponse) {
                    participants = participantResponse.participants;
                }
            }
            if (response) {
                const fullArray = [];
                const refinedData = response.driverScheduleList.reduce(function (r, a) {
                    const dateObject = new Date(a.date);
                    const today = new Date();
                    const isPresentDay = dateUtility.isToday(dateObject);
                    if (!dateUtility.checkPreviousDate(dateObject, today) || isPresentDay) {
                        const day = weekdays_lower[dateObject.getDay()];
                        const todayDate = dateObject.getDate();
                        const month = getMonthFromDateString(a.date);
                        const year = getYearFromDateString(a.date);
                        if (a.scheduledDriverData && a.scheduledDriverData[0]) {
                            const participantInfo = participants.find(p => p.participantId === a.scheduledDriverData[0].driverId);
                            a.scheduledDriverData[0].participantInfo = participantInfo;
                        }
                        r[year] = r[year] || {};
                        r[year][month] = r[year][month] || [];
                        r[year][month].push({
                            ...a, isPresentDay, dateObject, day, todayDate, dateString: a.date, month, year
                        });
                    }
                    return r;
                }, {});
                Object.keys(refinedData)
                    .sort((a, b) => a - b)
                    .forEach(year => {
                        Object.keys(refinedData[year])
                            .sort((a, b) => a - b)
                            .forEach(month => {
                                refinedData[year][month].forEach(dayData => {
                                    fullArray.push(dayData);
                                });
                            });
                    });
                yield put({ type: actionTypes.SET_DRIVER_SCHEDULE, data: { driverScheduleList: refinedData, apiData: response, fullArray } });
            }
        } catch (e) {
            logger.log(e);
            yield put({ type: actionTypes.GET_DRIVER_SCHEDULE_ERROR, errorString: e });

        }
    }
}

function* updateDriverPreferences(action) {
    try {
        const response = yield call(() => Http.post(`${driver_url}/preferences`, action.data));
        // yield delay(3000);
        if (response.success) {
            yield put({ type: actionTypes.GET_DRIVER_PREFERENCES });
            yield put({ type: actionTypes.UPDATE_DRIVER_PREFERENCES_SUCCESS });
            yield put({ type: actionTypes.RESET_DRIVER_SCHEDULE_PRESENT_STATE });
        } else {
            yield put({ type: actionTypes.UPDATE_DRIVER_PREFERENCES_ERROR });
        }
    } catch (e) {
        logger.log(e);
    }
}

function* updateDriverSchedule(action) {
    try {
        yield call(() => Http.post(`${driver_url}/schedule`, action.data));
        yield put({ type: actionTypes.GET_DRIVER_SCHEDULE });
        yield put({ type: actionTypes.UPDATE_DRIVER_SCHEDULE_SUCCESS });
        yield put({ type: actionTypes.RESET_DRIVER_SCHEDULE_PRESENT_STATE });
        if (action.history) {
            action.history.push('/driver-schedule');
        }
    } catch (e) {
        logger.log(e);
        yield put({ type: actionTypes.UPDATE_DRIVER_SCHEDULE_FAILURE });
    }
}

function* getDriverPreferences() {
    try {
        const driverPreferences = yield call(() => Http.get(`${driver_url}/preferences`, null, () => { }));
        if (driverPreferences) {
            yield put({ type: actionTypes.SET_DRIVER_PREFERENCES, data: driverPreferences });
        }
    } catch (e) {
        yield put({ type: actionTypes.GET_DRIVER_PREFERENCES_FAILURE });
    }
}