import React from 'react';
import { CheckCircle } from '../../shared/icons';
import BackTo from './BackTo';
import OneTimeSuccessConfirmation from './OneTimeSuccessConfirmation';
import RecurringSuccessConfirmation from './RecurringSuccessConfirmation';
import {useEffect} from 'react';
import analyticsService from '../../shared/analytics/analytics-service';

export default function SuccessConfirmation(props) {

  useEffect(() => {
    analyticsService.getPageName({
      'pagename': 'payment/make a payment/payment complete',
      'process': 'make a payment'
    });
  }, []);
  
  return (
    <div className="review payment-success">
      <section>
        <h2 className="title">
          <CheckCircle /> Thank You!
        </h2>
        <p>
          Your payment has been submitted. You can view all of your payments in
          Payments.
        </p>
      </section>
      {props.recurring ? (
        <RecurringSuccessConfirmation {...props} />
      ) : (
        <OneTimeSuccessConfirmation {...props} />
      )}
      <div className="tools section">
        <BackTo modify={props.modify} recurring={props.recurring} />
      </div>
    </div>
  );
}
