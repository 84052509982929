import React, { Component } from 'react';
import * as actionTypes from '../redux/actionTypes';
import { connect } from 'react-redux';
import { AddressInput } from '../shared/AddressInput';

const NO_WORKSITE_SELECTED = 'NO_WORKSITE_SELECTED';
const OTHER_SELECTED = '';

class WorkAddressSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      worksiteOtherSelected:
        !!this.props.workAddress && !this.props.worksiteId ? true : false,
      workAddress: this.props.workAddress
    };

    this.updateForm = this.props.update;
  }

  componentDidMount() {
    this.props.init();
  }

  updateWorkAddressByWorksiteId(worksiteId, worksites) {
    const workAddress = this.lookUpWorksiteAddress(worksiteId, worksites);
    if (!worksiteId) {
      this.setState({ worksiteOtherSelected: true, workAddress });
    } else {
      this.setState({ worksiteOtherSelected: false, workAddress });
    }
    this.updateForm({
      worksiteId,
      workAddress
    });
  }

  defaultWorksite(workAddress) {
    return workAddress ? OTHER_SELECTED : NO_WORKSITE_SELECTED;
  }

  updateWorkAddress(workAddress) {
    this.setState({ workAddress });
    this.updateForm({ workAddress });
  }

  lookUpWorksiteAddress(worksiteId, worksites) {
    return !!worksiteId && worksiteId !== NO_WORKSITE_SELECTED
      ? worksites.find(
          worksite => worksite.worksiteId === parseInt(worksiteId, 10)
        ).address
      : null;
  }

  render() {
    const {
      worksiteId = this.defaultWorksite(this.props.workAddress),
      worksites,
      isPartner
    } = this.props;

    return (
      <div>
        {isPartner ? (
          <div className="select-switch pad-desk">
            <div className="select work-address-select">
              <select
                value={worksiteId}
                className="worksite-select"
                onChange={e =>
                  this.updateWorkAddressByWorksiteId(e.target.value, worksites)
                }
              >
                <option
                  disabled
                  hidden
                  className="hidden"
                  value={NO_WORKSITE_SELECTED}
                >
                  Select an Address
                </option>
                {worksites.length
                  ? worksites.map(site => (
                      <option
                        key={site.worksiteId}
                        value={site.worksiteId}
                      >{`${site.worksiteName}, ${site.address}`}</option>
                    ))
                  : null}
                <option value="">Other</option>
              </select>
            </div>
          </div>
        ) : null}
        {this.state.worksiteOtherSelected || !isPartner ? (
          <AddressInput
            placeholder="Address"
            className="addr-edit"
            id="commuteEnd"
            value={this.state.workAddress}
            onChange={value => this.updateWorkAddress(value.address)}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { worksites: state.commuteProfile.worksites };
};

const mapDispatchToProps = dispatch => {
  return {
    init() {
      dispatch({ type: actionTypes.PARTNER_WORKSITES_LOADING });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkAddressSelect);
