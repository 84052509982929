import React from 'react';
import Tooltip from '../../../shared/Tooltip';
import { getNextYears, MONTHS } from '../../helpers/commonHelper';
import CardInput from '../cardInput/CardInput';
import Dropdown from '../dropdown/Dropdown';

const cvvMessage = 'Your CVV is a three-digit code on the back of your card. If your card is American Express, the CVV is a four-digit code on the front of your card.';

const CardInformation = React.forwardRef((props, ref) => {
  function formattedNumber (number) {
    return number.split('')
      .map((c, i) => (i % 4 === 3 ? c + ' ' : c))
      .join('')
      .trim();
  }

  function maskedCardNumber (number) {
    return `**** **** **** ${number}`;
  }

  return (
    <div className='card-container' ref={ref}>
      <div className='card-number'>
        <CardInput
          cardType={props.cardType}
          cardValid={props.cardValid}
          value={formattedNumber(props.cardNumber)}
          onChange={props.checkCardNumber}
          federalIssue={props.federalIssue}
          sameCardNumber={props.sameCardNumber}
          disabled={props.isCardInputDisabled}
          disabledValue = {maskedCardNumber(props.cardNumber)}
          focus={props.focus || false}
          />
      </div>
      <div className='date'>
        <div className='fields'>
          <Dropdown
            label="Expiration date*"
            onChange={(value) => props.onDateChange('month', value)}
            value={props.expirationDate.month}
            options={MONTHS}
            error={!props.dateValid}
            name='expiry month' />
          <Dropdown
            label=""
            onChange={(value) => props.onDateChange('year', value)}
            value={props.expirationDate.year}
            options={getNextYears()}
            error={!props.dateValid}
            name='expiry year'
            />
        </div>
        <div className='error-message' role="alert" aria-errormessage={'Expiration date cannot be in the past'}>
          {!props.dateValid &&
            <span>Expiration date cannot be in the past</span>
          }
        </div>
      </div>
      <div className='card-info'>
        <div className='cvv' role='group' aria-label='cvv information'>
          <fieldset className='field'>
            <div className='label'>
              CVV*
              <Tooltip message={cvvMessage} tooltipLabel='CVV'/>
            </div>
            <input id='cvv' required aria-invalid={props.cvv && !props.cvvValid} className={props.cvv && !props.cvvValid ? 'error' : ''} placeholder='Security Code' value={props.cvv} onChange={(e) => props.checkCVV(e.target.value)} type='password' disabled={props.isCvvDisabled}></input>
          </fieldset>
        </div>
        <div className='zip' role='group' aria-label='zip code'>
          <fieldset className='field'>
            <div className='label'>ZIP Code*</div>
            <input id='zip' required aria-invalid={props.zip && !props.zipValid} className={props.zip && !props.zipValid ? 'error' : ''} placeholder='Zip Code' value={props.zip} onChange={(e) => props.checkZip(e.target.value)}></input>
          </fieldset>
        </div>
      </div>
      {((props.cvv && !props.cvvValid) || (props.zip && !props.zipValid)) && <div className='error-message cvv-error'>
        {props.cvv && !props.cvvValid &&
          <span role="alert" aria-errormessage='Please enter a valid CVV Number'>Please enter a valid CVV Number</span>
        }
        {props.zip && !props.zipValid &&
          <span role="alert" aria-errormessage='Please enter a valid ZIP code'>Please enter a valid ZIP code</span>
        }
      </div>}
    </div>
  );
});

export default CardInformation;
