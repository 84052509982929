import {useMemo, useState} from 'react';
import DotButton from './dot-button/DotButton';
import FirstScreen from './screens/first-screen/FirstScreen';
import FourthScreen from './screens/fourth-screen/FourthScreen';
import SecondScreen from './screens/second-screen/SecondScreen';
import ThirdScreen from './screens/third-screen/ThirdScreen';

function OnboardingSlider(props) {

  const slides = useMemo(() => [
    <FirstScreen isPredelivery={props.isPredelivery} />,
    <SecondScreen isPredelivery={props.isPredelivery}/>,
    <ThirdScreen isPredelivery={props.isPredelivery}/>,
    <FourthScreen goToDashboard={props.goToDashboard}/>,
  ], [props.isPredelivery, props.goToDashboard]);

  const [presentSlide, updatePresentSlide] = useState(0);

  function onKeyDownDone(e){
    if(e.which === 13){
      props.goToDashboard();
    }
  }

  function handleNavigation(e, newSlide) {
    if (e.which === 13) {
      updatePresentSlide(newSlide);
    }
  }

  return (
    <div className='slider-container'>
      <div className='sliding-panel' aria-live='polite'>
        {slides[presentSlide]}
      </div>
      <div className='base-panel'>
        <button disabled={presentSlide===0} onClick={() => updatePresentSlide(presentSlide-1)} onKeyDown={(e) => handleNavigation(e, presentSlide-1)} className='navigation' aria-label='Go to previous slide'>PREV</button>
        <div className='slider-buttons'>
          {slides.map((slide, index) => <DotButton aria-hidden={index !== presentSlide} key={index} index={index} selected={index === presentSlide} onKeyDown={(e) => handleNavigation(e, index)} onClick={() => updatePresentSlide(index)} />)}
        </div>
        {(presentSlide===slides.length-1) ?
          <button disabled={props.isDoneDisabled} className='navigation' onClick={props.goToDashboard} onKeyDown={onKeyDownDone}>DONE</button>
          :
          <button onClick={() => updatePresentSlide(presentSlide+1)} onKeyDown={(e) => handleNavigation(e, presentSlide + 1)} className='navigation' aria-label='Go to Next slide'>NEXT</button>
        }
        
      </div>
    </div>
  );
}

export default OnboardingSlider;