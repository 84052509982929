import React from 'react';
import { connect } from 'react-redux';
import Branding from '../navigation-menu/Branding';
import IsVisible from '../permissions/IsVisible';
import DateUtility from '../dateUtility';
import config from '../../appConfig';
import AdChoices from '../../_styles/images/AdChoices.png';
import privacyChoices from '../../_styles/images/privacyChoices.png';

export function FooterPresentation({ hasUser }) {
  return (
    <IsVisible permission={hasUser}>
      <footer className="global" aria-hidden='true'>
        <div className="top">
          <Branding.Footer />
          <div className="links">
            <div className="section">
              <div className="title">Other Enterprise Services</div>
              <a
                href={config.enterpriseWebLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Enterprise
              </a>
              <a
                href={config.vansalesLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Van Sales
              </a>
            </div>
            <div className="section desk-privacy">
              <div className="title">Privacy and Legal</div>
              <a
                href={config.privacyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href={config.policyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>
              <a
              href={"#one_trust"}
                rel="noopener noreferrer"
              >
                 Privacy Choices
                 <img className='privacyImg' src={privacyChoices}/>
              </a>
              <a
                href={config.adChoicesLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                 AdChoices
                 <img className='adchoiceImg' src={AdChoices}/>
              </a>
              <a
                href={config.termsOfUseLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Use{' '}
              </a>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="mobile-privacy">
          <div className="title">Privacy and Legal</div>
            <a
              href={config.privacyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
         
            <a
              href={config.policyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a>
      
            <a
                href={"#one_trust"}
                rel="noopener noreferrer"
              >
                 Privacy Choices
                 <img className='privacyImg' src={privacyChoices}/>
            </a>
      
            <a
                href={config.adChoicesLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                 AdChoices
                 <img className='adchoiceImg' src={AdChoices}/>
            </a>
         
            <a
              href={config.termsOfUseLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use{' '}
            </a>
          </div>
          <p>
            &copy;{DateUtility.currentYear()} Commute with Enterprise. Commute
            with Enterprise is a trademark of Enterprise Holdings, Inc. All
            other trademarks are the property of their respective owners.
          </p>
        </div>
      </footer>
    </IsVisible>
  );
}

function mapStateToProps(state) {
  return {
    hasUser: !!state.loginState.hasToken
  };
}

export default connect(mapStateToProps)(FooterPresentation);
