import React from 'react';

const focus = e => e && e.focus();

const sanitize = str => {
  let newStr = '';

  if (!str) {
    return newStr;
  }

  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) < 128) {
      newStr += str[i];
    }
  }

  return newStr;
};

export function MaxLengthTextEditor({
  value = '',
  placeholder,
  maxLength,
  onChange,
  rows = 1,
  autoFocus
}) {
  const remaining = maxLength - value.length;
  const isOverflowed = remaining < 20;
  const currentLength = isOverflowed ? (
    <span className="overflowed">{remaining}</span>
  ) : null;

  return (
    <div className="editor-wrapper">
      <textarea
        onChange={e =>
          e.target.value.length <= maxLength &&
          onChange(sanitize(e.target.value))
        }
        value={value}
        rows={rows}
        maxLength={maxLength}
        placeholder={placeholder}
        className={rows > 1 ? 'textarea-editor' : 'textbox'}
        ref={autoFocus && focus}
      ></textarea>
      <div className="editor-counter">{currentLength}</div>
    </div>
  );
}
