import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import currencyFormatter from '../../shared/currencyFormatter';
import dateUtility from '../../shared/dateUtility';
import RequestedPayment from './RequestedPayment';

class MyRequestedPayments extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    const { allocation } = this.props;

    return (
      <div className="etable requested">
        <div className="etable-cat">
          <div className="title">
            My Requested Payments
            <div className="description">
              For {dateUtility.currentMonth()}, your coordinator has requested
              the following payment(s)
            </div>
          </div>
          <div className="etable-body">
            {allocation.requestedPayments.length ? (
              <div>
                {' '}
                {allocation.requestedPayments.map((requestedPayment, index) => (
                  <RequestedPayment
                    requestedPayment={requestedPayment}
                    index={index}
                    key={`index-${index}`}
                  />
                ))}
                <div>
                  <div className="etable-footer requested-payments-footer">
                    <div className="etable-row">
                      <div className="etable-foot total-row">
                        Requested Payments
                      </div>
                      <div className="etable-foot right due total-row">
                        {currencyFormatter.formatUSD(allocation.amount)}
                      </div>
                    </div>
                  </div>
                  <div className="disclaimer">
                    Requested Payments above is a monthly snapshot and will not
                    reflect payments process. See Online Payment History below
                    recent payments processed and posted to your account.
                  </div>
                </div>
              </div>
            ) : (
              <NoRequestedPayments />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function NoRequestedPayments() {
  return (
    <div className="etable-row no-requested-payments">
      <div className="etable-cell desktop-only">
        No Payment Requests at this time. Please contact your coordinator with
        questions
      </div>
      <div className="etable-cell"></div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    allocation: state.paymentManagement
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.PAYMENT_ALLOCATION_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyRequestedPayments);
