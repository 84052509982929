import PriceComponent from '../common/price-component/PriceComponent';
import SponsoredVanpool from '../helpers/sponsoredVanpool';

export default function Recommended(props) {
  return (
    <div className='recommended' role='group'>
      <div className='tag'>
        <div className='text'>Recommended</div>
      </div>
      {props.noOfDays > 4
        ? 
          <div className='select-heading'>Unlimited Plan</div>
        :
          <div className='select-heading'>{props.noOfDays}-Day Plan</div>
      }
      <div className='comment2'>
        <ul>
          <li>Guaranteed seat on your specified day</li>
        </ul>
      </div>
      {props.selectedVan.isSubsidized && <SponsoredVanpool />}
      {props.isDriver && !props.wasRider
        ?
        <div className='prices-container'>
          <PriceComponent small={false} charge={props.driverPrice} selected={props.selectedOption === 0 && props.selectedType === 0} onSelect={() => props.updateSelection(0,0)} />
        </div>
        :
        <div className='prices-container'>
          <PriceComponent
            small={true}
            charge={props.riderPrice} heading='Rider Plan' selected={props.selectedOption === 0 && props.selectedType === 1} onSelect={() => props.updateSelection(0,1)} />
          <PriceComponent small={true} charge={props.driverPrice} heading='Willing to drive?' selected={props.selectedOption === 0 && props.selectedType === 0} onSelect={() => props.updateSelection(0,0)} />
        </div>
      }
      <div className='condition'>*All prices include fuel, maintenance, roadside assistance, insurance, applicable taxes and fees</div>
      {
        props.selectedType === 0 && props.selectedOption === 0 &&
        <div className='condition'>You can adjust your preferred driving days and frequency when you become an approved driver</div>
      }
    
    </div>);
}