import * as actionTypes from '../../redux/actionTypes';

import {call, put, takeLatest} from 'redux-saga/effects';

import CurrencyFormatter from '../../shared/currencyFormatter';
import DateUtility from '../../shared/dateUtility';
import Http from '../../shared/http-service';
import config from '../../appConfig';
import logger from '../../shared/logger';
import paymentProcessor from '../../shared/credit-card/paymentProcessor';

export default function* () {
  yield takeLatest(
    actionTypes.MY_PAYMENT_HISTORY_LOADING,
    loadMyPaymentHistory
  );
  yield takeLatest(
    actionTypes.GET_PAYMENT_HISTORY, getPaymentHistory
  );
  yield takeLatest(
    actionTypes.GET_BIN_NUMBERS, getBINNumbers
  );
  yield takeLatest(
    actionTypes.ADD_PAYMENT_CARD, addPaymentCard
  );
  yield takeLatest(
    actionTypes.ADD_VERIFIED_CARD, addVerifiedCard
  );
  yield takeLatest(
    actionTypes.ADD_BOTH_CARDS_TOGETHER, addCardsTogether
  );
  yield takeLatest(
    actionTypes.SAVE_EDITED_CARD, saveEditedCard
  );
  yield takeLatest(
    actionTypes.DELETE_CARD, deleteCard
  );
  yield takeLatest(
    actionTypes.SIMPLE_SAVE_CARD, simpleSaveCard
  )
}

function* loadMyPaymentHistory() {
  try {
    const paymentHistory = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/participant/profile/payments`)
    );

    let mappedPaymentHistory = {
      payments: paymentHistory.transactionGroup.map(
        mapParticipantTransactionGroup
      )
    };

    yield put({
      type: actionTypes.MY_PAYMENT_HISTORY_LOADED,
      data: mappedPaymentHistory
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.MY_PAYMENT_HISTORY_LOADED_FAILURE,
      data: {error: true},
      errorString: ex,
    });
  }
}

////////

function mapTransactionGroup(item) {
  let newItem = {};

  newItem.monthlyTotal =
    !!item.paymentList.length &&
    CurrencyFormatter.formatUSD(
      item.paymentList
        .map(item => item.totalCharges)
        .reduce((prev, next) => prev + next)
    );
  newItem.receivedEndDate = item.groupTitle;
  newItem.billingTransactionDetail = item.paymentList.map(
    mapTransactionBillingDetail
  );
  return newItem;
}

function mapParticipantTransactionGroup(item) {
  let newItem = {};

  newItem.monthlyTotal =
    !!item.paymentList.length &&
    CurrencyFormatter.formatUSD(
      item.paymentList
        .map(item => item.totalCharges)
        .reduce((prev, next) => prev + next)
    );
  newItem.receivedEndDate = item.groupTitle;
  newItem.billingTransactionDetail = item.paymentList.map(
    mapParticipantTransactionBillingDetail
  );
  return newItem;
}

function mapParticipantTransactionBillingDetail(p) {
  return {
    transactionDate: DateUtility.parseDateAsMonthDay(p.date),
    transactionDescription: `${p.description}: ${p.cardType}`,
    transactionAmount: CurrencyFormatter.formatUSD(p.totalCharges)
  };
}

function mapTransactionBillingDetail(p) {
  let newPayment = {
    transactionDate: p.appliedToInvoiceDate
  };
  newPayment.transactionDescription = p.description;
  newPayment.transactionAmount = CurrencyFormatter.formatUSD(p.totalCharges);
  newPayment.transactionDate = DateUtility.parseDateAsMonthDay(
    p.appliedToInvoiceDate
  );
  return newPayment;
}

function* getPaymentHistory() {
  try {
    const paymentHistory = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/participant/profile/payments`)
    );

    yield put({
      type: actionTypes.GET_PAYMENT_HISTORY_SUCCESS,
      data: paymentHistory
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.GET_PAYMENT_HISTORY_FAILURE,
      data: {error: true},
      errorString: ex
    });
  }
}

function* getBINNumbers() {
  const url = `${config.kongApiBaseUrl}/payment/recurringChargeInformation`;
  try {
    const result = yield call(() => Http.get(url));
    if (result && result.recurringChargeInformation) {
      const chargeInfo = result.recurringChargeInformation;
      const maxAmount = result.maxFederalBenefitAmount;
      const binNumbers = chargeInfo.map(charge => charge.binCode);
      yield put({
        type: actionTypes.SET_BIN_NUMBERS ,data: {binNumbers: binNumbers, maxAmount: maxAmount}
      });
    }
  }
  catch (e) {
    logger.log(e);
    return [];
  }
}

export async function verifyCard(saveCardInfo, addCardInfo) {
  const verifyPaymentURL = `${config.kongApiBaseUrl}/participant/payment/method/verify`;
  let processorResponse = await paymentProcessor.addCard(saveCardInfo);
  let verifyResponse = await Http.post(verifyPaymentURL, processorResponse);
  if (verifyResponse.success && processorResponse.tokenId) {
    return {...addCardInfo, tokenId: processorResponse.tokenId};
  } else {
    return {};
  }
}

function* addPaymentCard(action) {
  try {
    const verifyPaymentURL = `${config.kongApiBaseUrl}/participant/payment/method/verify`;
    const addPaymentUrl = `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`;

    let processorResponse = yield paymentProcessor.addCard(action.verifyCardInfo);

    let verifyResponse = yield call(() =>
      Http.post(verifyPaymentURL, processorResponse)
    );

    if (verifyResponse.success) {
      if (processorResponse.tokenId) {
        const addPaymentResponse = yield call(() =>
          Http.post(addPaymentUrl, {...action.apiCardInfo, tokenId: processorResponse.tokenId})
        );

        if (addPaymentResponse.isCardSavedSuccessful) {
          if(action && action.deleteCard){
            yield call(deleteCard, {data: action.deleteCard});
          } else {
            yield put({
              type: actionTypes.PAYMENT_DETAILS_LOADING
            });
          }
        }
      }
    }

  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.PAYMENT_DETAILS_LOADING
    });
  }
}

function* addVerifiedCard(action) {
  const addPaymentUrl = `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`;

  try {
    const addPaymentResponse = yield call(() =>
      Http.post(addPaymentUrl, action.data)
    );
    if (addPaymentResponse.isCardSavedSuccessful) {
      yield put({
        type: actionTypes.PAYMENT_DETAILS_LOADING
      })
    }
  } catch (ex) {
    logger.log(ex);
  }
}

function* addCardsTogether(action) {
  const addPaymentUrl = `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`;

  try {
    const addPrimaryCardResponse = yield call(() =>
      Http.post(addPaymentUrl, action.primaryCard)
    );
    if (addPrimaryCardResponse.isCardSavedSuccessful) {
      const addBackupCardResponse = yield call(() =>
        Http.post(addPaymentUrl, action.backupCard)
      );
      if (addBackupCardResponse.isCardSavedSuccessful) {
        if (action && action.deleteCard) {
          yield call(deleteCard, {data: action.deleteCard});
        } else {
          yield put({
            type: actionTypes.PAYMENT_DETAILS_LOADING
          });
        }
      }
    }
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.PAYMENT_DETAILS_LOADING
    });
  }
}

function* saveEditedCard(action) {
  const editCardURL = `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`;
  try {
    const editCardResponse = yield call(() => Http.put(editCardURL, action.data));
    if (editCardResponse.isCardSavedSuccessful) {
      yield put({
        type: actionTypes.PAYMENT_DETAILS_LOADING
      });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.PAYMENT_DETAILS_LOADING
    });
  }
}

function* deleteCard(action) {
  const deleteCardURL = `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`;
  try {
    const deleteCardResponse = yield call(() => Http.delete(deleteCardURL, action.data));
    if (deleteCardResponse && deleteCardResponse.success) {
      yield put({
        type: actionTypes.PAYMENT_DETAILS_LOADING,
        toastMessage: 'Card deleted Successfully'
      });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.PAYMENT_DETAILS_LOADING
    });
  }
}

function* simpleSaveCard(action) {
  const editCardURL = `${config.kongApiBaseUrl}/participant/profile/payment/methods`;
  try {
    const editCardResponse = yield call(() => Http.put(editCardURL, action.data));
    if (editCardResponse && editCardResponse.success) {
      if (action && action.deleteCard) {
        yield call(deleteCard, {data: action.deleteCard});
      } else {
        yield put({
          type: actionTypes.PAYMENT_DETAILS_LOADING
        });
      }
    }
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.PAYMENT_DETAILS_LOADING
    });
  }
}
