export default function LastDaysDetails(props) {
    return (
        <div className="last-days-section" role='region'>
            <div className='row' role='group'>
                <div className='label'>Last Ride Date</div>
                <div className='date'>{props.lastDate}</div>
            </div>
            <div className='row' role='group'>
                <div className='label'>Last Day of Subscription</div>
                <div className='date'>{props.endDate}</div>
            </div>
        </div>
    );
}