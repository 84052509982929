import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import { useState, useEffect } from 'react';
import VanRouteMap from '../../../join-commute/vanpool-details/VanRouteMap';
import * as actionTypes from '../../../redux/actionTypes';

function RouteMap(props) {
  const { route } = props;
  const [accordianOpen, setAccordianOpen] = useState(false)

  // useEffect(() => {
  //   if(route.outboundRoutePoints.length){
  //     props.setDirections(route['outboundRoutePoints'])
  //   }
  // }, [route]);

  function heading() {
    let tempClass = 'arrow ';
    tempClass += accordianOpen ? 'up ' : 'down ';
    return (
      <div className='collapsible-heading-container' role='button' aria-controls='collapsible' aria-expanded={accordianOpen} tabIndex={0}>
        <h1 className='heading'>{"View Map"}</h1>
        <i className={tempClass}></i>
      </div>
    );
  }

  return (
      <Collapsible aria-expanded={accordianOpen} open={accordianOpen }  handleTriggerClick={() => setAccordianOpen(!accordianOpen)}  trigger={heading()} >
         <div className='collapsible-content-container'>
             <div className="map">
                <VanRouteMap isRoute={true} />
              </div>
          </div>
       </Collapsible>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    },
    setOrigin(origin) {
      dispatch({ type: actionTypes.MAP_ORIGIN, data: origin });
    }
  };
}

export default connect(null, mapDispatchToProps)(RouteMap);
