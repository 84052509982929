import React from 'react';

export default function QuestionYesNo({ question, onChange, value }) {
  return (
    <div className="question">
      <label>{question}</label>
      <div className="yes-no">
        <button
          className={`yes pill ${value ? 'selected' : ''}`}
          onClick={() => onChange(true)}
        >
          Yes
        </button>
        <button
          className={`no pill ${value === false ? 'selected' : ''}`}
          onClick={() => onChange(false)}
        >
          No
        </button>
      </div>
    </div>
  );
}
