import React, { Component } from 'react';
import dateUtility from '../shared/dateUtility';
import { Clock, Close } from '../shared/icons';

export class TimeInput extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.value, original: props.value, open: false };
  }

  componentDidMount() {
    this.setState({ value: this.props.value, original: this.props.value });
  }

  componentWillReceiveProps(nextProps) {
    //commenting this to fix the adu time input reset selection issue
    //this.setState({ value: nextProps.value });
  }

  delayedClose() {
    this.props.onSelected(this.state.value);
    this.setState({ open: 0 });
    setTimeout(() => this.setState({ open: false }), 50);
  }

  render() {
    const tabIndex = {};
    if (this.state.open === false) {
      tabIndex.tabIndex = 0;
    }
    return (
      <div
        className={`time-picker ${this.state.open ? 'active' : ''}`}
        onKeyDown={e => e.key === SELECT && this.setState({ open: true })}
        onClick={() => this.setState({ open: true })}
        {...tabIndex}
      >
        <div className="time-display">
          {dateUtility.parseTime(this.state.value)}
        </div>
        {this.state.open ? (
          <TimeButtons
            value={this.state.value}
            onChange={value => this.setState({ value })}
            close={() => this.delayedClose()}
          />
        ) : (
          <Clock />
        )}
      </div>
    );
  }
}

const hours = [
  '12',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11'
];
const minutes = ['00','05','10','15','20','25','30','35','40', '45','50','55'];
const HORIZONTAL = 'Tab';
const VERT_UP = 'ArrowUp';
const VERT_DOWN = 'ArrowDown';
const SELECT = 'Enter';
const CLICK = { key: SELECT, preventDefault() {}, stopPropagation() {} };

class TimeButtons extends Component {
  constructor(props) {
    super(props);
    this.state = { original: props.value, value: props.value };
    this.hours = [];
    this.minutes = [];
  }

  componentDidMount() {
    this.close = () => {
      this.props.close();
      window.removeEventListener('mousedown', this.close);
    };

    window.addEventListener('mousedown', this.close);
    this.hours[0].focus();
  }

  componentWillReceiveProps(props) {
    this.setState({ value: props.value });
  }

  setHour(e, hour, ampm) {
    if (e.key === SELECT) {
      ampm === 'am' ? this.setAM(hour) : this.setPM(hour);
    }

    const index = hours.indexOf(hour);

    if (e.key === VERT_UP && index > 0) {
      e.preventDefault();
      this.hours[index - 1].focus();
    }

    if (e.key === VERT_DOWN && index < this.hours.length - 1) {
      e.preventDefault();
      this.hours[index + 1].focus();
    }
  }

  setMinute(e, minute) {
    if (e.key === SELECT) {
      const updatedTime = this.state.value.replace(/:\d\d/, ':' + minute);
      this.setState({ value: updatedTime });
      this.props.onChange(updatedTime);
    }

    const index = minutes.indexOf(minute);

    if (e.key === VERT_UP && index > 0) {
      e.preventDefault();
      this.minutes[index - 1].focus();
    }

    if (e.key === VERT_DOWN && index < this.minutes.length - 1) {
      e.preventDefault();
      this.minutes[index + 1].focus();
    }
  }

  setAM(hour) {
    hour = hour === '12' ? '00' : hour;
    const updatedTime = this.state.value.replace(/^\d+/, hour);
    this.setState({ value: updatedTime });
    this.props.onChange(updatedTime);
  }

  setPM(hour) {
    hour = hour === '12' ? '12' : parseInt(hour, 10) + 12;
    const updatedTime = this.state.value.replace(/^\d+/, hour);
    this.setState({ value: updatedTime });
    this.props.onChange(updatedTime);
  }

  tabOnHours(e) {
    e.stopPropagation();
    e.preventDefault();

    if (e.shiftKey) {
      return this.cancel.focus();
    }

    this.minutes[0].focus();
  }

  tabOnMinutes(e) {
    e.stopPropagation();
    e.preventDefault();

    if (e.shiftKey) {
      return this.hours[0].focus();
    }

    this.am.focus();
  }

  keyOnAMPM(e) {
    if (e.key === HORIZONTAL && !e.shiftKey) {
      return this.close();
    }

    e.stopPropagation();
    e.preventDefault();

    if (e.key === VERT_UP && e.target !== this.am) {
      return this.am.focus();
    }

    if (e.key === VERT_DOWN && e.target !== this.pm) {
      return this.pm.focus();
    }

    if (e.key === HORIZONTAL && e.shiftKey) {
      return this.minutes[0].focus();
    }
  }

  onKeyCancel(e) {
    if (e.key === SELECT) {
      this.props.onChange(this.state.original);
      return this.close();
    }

    if (e.key === HORIZONTAL) {
      if (e.shiftKey) {
        return this.close();
      }

      e.preventDefault();
      return this.hours[0].focus();
    }
  }

  render() {
    const { value } = this.state;
    const [hour, minute] = value.split(':');
    const selected = {
      hour: hours[parseInt(hour, 10) % 12],
      minute,
      ampm: parseInt(hour, 10) > 11 ? 'pm' : 'am'
    };

    return (
      <div className="time-options" onMouseDown={e => e.stopPropagation()}>
        <div
          className="cancel"
          tabIndex={0}
          ref={e => (this.cancel = e)}
          onClick={() => this.onKeyCancel(CLICK)}
          onKeyDown={e => this.onKeyCancel(e)}
        >
          <Close />
        </div>
        <div
          className="hours"
          onKeyDown={e => e.key === HORIZONTAL && this.tabOnHours(e)}
        >
          {hours.map((hour, index) => (
            <div
              key={hour}
              ref={e => (this.hours[index] = e)}
              className={`hour ${selected.hour === hour ? 'active' : ''}`}
              onKeyDown={e => this.setHour(e, hour, selected.ampm)}
              onClick={() => this.setHour(CLICK, hour, selected.ampm)}
              tabIndex={0}
            >
              {hour}
            </div>
          ))}
        </div>
        <div
          className="minutes"
          onKeyDown={e => e.key === HORIZONTAL && this.tabOnMinutes(e)}
        >
          {minutes.map((minute, index) => (
            <div
              key={minute}
              ref={e => (this.minutes[index] = e)}
              className={`minute ${selected.minute === minute ? 'active' : ''}`}
              onKeyDown={e => this.setMinute(e, minute)}
              onClick={() => this.setMinute(CLICK, minute)}
              tabIndex={0}
            >
              {minute}
            </div>
          ))}
        </div>
        <div className="am-pm" onKeyDown={e => this.keyOnAMPM(e)}>
          <div
            className={`am ${selected.ampm === 'am' ? 'active' : ''}`}
            ref={e => (this.am = e)}
            onKeyDown={e => e.key === SELECT && this.setAM(selected.hour)}
            onClick={() => this.setAM(selected.hour)}
            tabIndex={0}
          >
            AM
          </div>
          <div
            className={`pm ${selected.ampm === 'pm' ? 'active' : ''}`}
            ref={e => (this.pm = e)}
            onKeyDown={e => e.key === SELECT && this.setPM(selected.hour)}
            onClick={() => this.setPM(selected.hour)}
            tabIndex={0}
          >
            PM
          </div>
        </div>
      </div>
    );
  }
}
