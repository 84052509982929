import React from 'react';

import RiPencil from 'react-icons/lib/fa/pencil';
import RiPlus from 'react-icons/lib/fa/plus';
import riAngleDown from 'react-icons/lib/fa/angle-down';
import riAngleLeft from 'react-icons/lib/fa/angle-left';
import riAngleRight from 'react-icons/lib/fa/angle-right';
import riClose from 'react-icons/lib/md/close';
import riWarning from 'react-icons/lib/fa/exclamation-circle';
import riCheck from 'react-icons/lib/md/check';
import riCheckCircle from 'react-icons/lib/md/check-circle';
import riUnchecked from 'react-icons/lib/fa/square-o';
import riChecked from 'react-icons/lib/fa/check-square';
import riDollar from 'react-icons/lib/fa/dollar';
import riInfo from 'react-icons/lib/md/info-outline';
import riRecurring from 'react-icons/lib/fa/refresh';
import riAlmost from 'react-icons/lib/io/ios-timer-outline';
import riUser from 'react-icons/lib/md/person';
import riPhone from 'react-icons/lib/md/local-phone';
import riEmail from 'react-icons/lib/md/mail-outline';
import riHome from 'react-icons/lib/fa/home';
import riContact from 'react-icons/lib/md/contact-mail';
import riBriefcase from 'react-icons/lib/fa/briefcase';
import riVanpool from 'react-icons/lib/md/airport-shuttle';
import riArrowUp from 'react-icons/lib/fa/arrow-up';
import riArrowDown from 'react-icons/lib/fa/arrow-down';
import riArrowRight from 'react-icons/lib/fa/chevron-right';
import riArrowLeft from 'react-icons/lib/fa/chevron-left';
import riChevronDown from 'react-icons/lib/fa/chevron-down';
import riTrash from 'react-icons/lib/fa/trash';
import riPlusCircle from 'react-icons/lib/fa/plus-circle';
import riStopWatch from 'react-icons/lib/md/timer';
import riClock from 'react-icons/lib/fa/clock-o';
import riFuel from 'react-icons/lib/md/local-gas-station';
import riMenu from 'react-icons/lib/md/menu';
import riCreditCard from 'react-icons/lib/md/credit-card';
import riCar from 'react-icons/lib/md/directions-car';
import riInvite from 'react-icons/lib/fa/user-plus';
import riDrag from 'react-icons/lib/md/drag-handle';
import riSignOut from 'react-icons/lib/md/input';
import riCalendar from 'react-icons/lib/fa/calendar';
import riSteeringWheel from 'react-icons/lib/md/adjust';
import riStar from 'react-icons/lib/fa/star-o';
import riBell from 'react-icons/lib/fa/bell';
import riBlock from 'react-icons/lib/md/block';
import riWalk from 'react-icons/lib/md/directions-walk';
import riTrain from 'react-icons/lib/md/train';
import riRail from 'react-icons/lib/md/directions-transit';
import riBoat from 'react-icons/lib/md/directions-boat';
import riBus from 'react-icons/lib/md/directions-bus';
import riTaxi from 'react-icons/lib/md/local-taxi';
import riBike from 'react-icons/lib/md/directions-bike';
import riLock from 'react-icons/lib/md/lock';
import riNoComment from 'react-icons/lib/md/mode-comment';
import riComment from 'react-icons/lib/md/comment';
import riDownload from 'react-icons/lib/md/file-download';
import riRecycle from 'react-icons/lib/fa/recycle';
// Custom Icons
import Van from './ehi-icons/van.svg';
import People from './ehi-icons/people.svg';
import Person from './ehi-icons/person.svg';
import Distance from './ehi-icons/distance.svg';
import Money from './ehi-icons/money.svg';
import FuelGauge from './ehi-icons/fuel-gauge.svg';
import FuelPump from './ehi-icons/fuel-pump.svg';
import Tree from './ehi-icons/tree.svg';
import Road from './ehi-icons/road.svg';
import Video from './ehi-icons/video.svg';
import EmailChat from './ehi-icons/email.svg';
import Globe from './ehi-icons/globe.svg';
import Employer from './ehi-icons/employer.svg';
import Social from './ehi-icons/social.svg';
import HamburgerMenu from './ehi-icons/menu.svg';
import PlayStore from './ehi-icons/google-play-store-badge.svg';
import AppStore from './ehi-icons/app-store-apple-badge.svg';
import  { ReactComponent as Stopwatch } from './ehi-icons/stopwatch.svg';

const icon = Base => props => <Base focusable="false" {...props} />;

const Pencil = props => (
  <RiPencil
    focusable="false"
    {...props}
    className={'edit-icon ' + (props.className || '')}
  />
);
const ChevronDown = icon(riChevronDown);
const Star = icon(riStar);
const SteeringWheel = icon(riSteeringWheel);
const AngleDown = icon(riAngleDown);
const AngleLeft = icon(riAngleLeft);
const AngleRight = icon(riAngleRight);
const Close = icon(riClose);
const Warning = icon(riWarning);
const Check = icon(riCheck);
const CheckCircle = icon(riCheckCircle);
const Unchecked = icon(riUnchecked);
const Checked = icon(riChecked);
const Dollar = icon(riDollar);
const Info = icon(riInfo);
const Recurring = icon(riRecurring);
const Almost = icon(riAlmost);
const User = icon(riUser);
const Phone = icon(riPhone);
const Email = icon(riEmail);
const Home = icon(riHome);
const Contact = icon(riContact);
const Briefcase = icon(riBriefcase);
const Vanpool = icon(riVanpool);
const ArrowUp = icon(riArrowUp);
const ArrowDown = icon(riArrowDown);
const ArrowRight = icon(riArrowRight);
const ArrowLeft = icon(riArrowLeft);
const Trash = icon(riTrash);
const PlusCircle = icon(riPlusCircle);
const StopWatch = icon(riStopWatch);
const Clock = icon(riClock);
const Fuel = icon(riFuel);
const Menu = icon(riMenu);
const CreditCard = icon(riCreditCard);
const Car = icon(riCar);
const Invite = icon(riInvite);
const Drag = icon(riDrag);
const SignOut = icon(riSignOut);
const Calendar = icon(riCalendar);
const Bell = icon(riBell);
const Block = icon(riBlock);
const Walk = icon(riWalk);
const Train = icon(riTrain);
const Rail = icon(riRail);
const Boat = icon(riBoat);
const Bus = icon(riBus);
const Taxi = icon(riTaxi);
const Bike = icon(riBike);
const Lock = icon(riLock);
const NoComment = icon(riNoComment);
const Comment = icon(riComment);
const Download = icon(riDownload);
const Recycle = icon(riRecycle);
const Plus = icon(RiPlus)

export {
  Pencil,
  ChevronDown,
  Star,
  Calendar,
  SteeringWheel,
  AngleDown,
  AngleLeft,
  AngleRight,
  Close,
  Warning,
  Check,
  CheckCircle,
  Unchecked,
  Checked,
  Dollar,
  Info,
  Recurring,
  Almost,
  User,
  Phone,
  Email,
  Home,
  Contact,
  Briefcase,
  Vanpool,
  ArrowUp,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  Trash,
  PlusCircle,
  StopWatch,
  Clock,
  Fuel,
  Menu,
  CreditCard,
  Car,
  Invite,
  Drag,
  SignOut,
  Bell,
  Block,
  Walk,
  Train,
  Rail,
  Boat,
  Bus,
  Taxi,
  Bike,
  Lock,
  NoComment,
  Comment,
  Download,
  Recycle,
  Plus,
  // Custom Icons
  Van,
  People,
  Person,
  Distance,
  Money,
  FuelGauge,
  FuelPump,
  Tree,
  Road,
  HamburgerMenu,
  Video,
  Employer,
  Globe,
  EmailChat,
  Social,
  PlayStore,
  AppStore,
  Stopwatch
};
