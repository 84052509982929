export const PERMISSION_BILLING_VIEWER = 'PERMISSION_BILLING_VIEWER';
export const PERMISSION_VANPOOL_STATUS_PRE_DELIVERY =
  'PERMISSION_VANPOOL_STATUS_PRE_DELIVERY';
export const PERMISSION_VANPOOL_STATUS_RENTAL =
  'PERMISSION_VANPOOL_STATUS_RENTAL';
export const PERMISSION_VANPOOL_TRIP_RECORDING = 'VANPOOL_TRIP_RECORDING';
export const PERMISSION_COORDINATOR = 'PERMISSION_COORDINATOR';
export const PERMISSION_RIDER = 'PERMISSION_RIDER';
export const PERMISSION_DRIVER = 'PERMISSION_DRIVER';
export const PERMISSION_APPROVED_DRIVER = 'PERMISSION_APPROVED_DRIVER';
export const PERMISSION_TRIP_RECORDING = 'TRIP_RECORDING';
export const PERMISSION_REGISTERED_USER = 'REGISTERED_USER';
export const PERMISSION_SUBSCRIPTION_USER = 'SUBSCRIPTION_USER';
export const PERMISSION_TRADITIONAL_USER = 'PERMISSION_TRADITIONAL_USER';
export const PERMISSION_NTD_TRIP_RECORDING = 'PERMISSION_NTD_TRIP_RECORDING';
export const PERMISSION_MANAGE_PAYMENT_METHODS =
  'PERMISSION_MANAGE_PAYMENT_METHODS';
export const PERMISSION_IPB = 'PERMISSION_IPB';
export const PERMISSION_ACCOUNT_DASHBOARD_USER =
  'PERMISSION_ACCOUNT_DASHBOARD_USER';
export const PERMISSION_ACCOUNT_DASHBOARD_ADMIN =
  'PERMISSION_ACCOUNT_DASHBOARD_ADMIN';
export const PERMISSION_FUEL_CARD_ACTIVE = 'PERMISSION_FUEL_CARD_ACTIVE';
export const PERMISSION_FUEL_CARD_PENDING = 'PERMISSION_FUEL_CARD_PENDING';
export const PERMISSION_FUEL_CARD_NONE = 'PERMISSION_FUEL_CARD_NONE';
export const SUBSCRIPTION_TRIP_RECORDER = 'SUBSCRIPTION_TRIP_RECORDER';
export const PERMISSION_VAN_STARTING_IN_FUTURE = 'PERMISSION_VAN_STARTING_IN_FUTURE';
export const PERMISSION_SUBSCRIPTION_CHANGED = 'PERMISSION_SUBSCRIPTION_CHANGED';
export const PERMISSION_SUBSCRIPTION_UNCHANGED = 'PERMISSION_SUBSCRIPTION_UNCHANGED';
export const PERMISSION_NOVANPOOL = 'PERMISSION_NOVANPOOL';
export const TRADITIONAL_ROSTER_ADDRESS_PERMISSION = 'TRADITIONAL_ROSTER_ADDRESS_PERMISSION';
export const TRADITIONAL_COORDINATOR_PERMISSION = 'TRADITIONAL_COORDINATOR_PERMISSION';

function pushUnique(arr, item) {
  if (!arr.includes(item)) {
    arr.push(item);
  }
}

export function createPermissions(loginProfile) {
  const VANPOOL_PRE_DELIVERY = ['A', 'P'];
  const VANPOOL_RENTAL = ['R', 'Z'];
  const FUEL_CARD_ACTIVE = 'Active';
  const FUEL_CARD_PENDING = 'Pending';
  const FUEL_CARD_NONE = 'None';

  const VANPOOL_FUELCARD_STATUS = [
    FUEL_CARD_ACTIVE,
    FUEL_CARD_PENDING,
    FUEL_CARD_NONE
  ];

  let permissions = [];

  if (loginProfile.isTripRecordingVanpool) {
    permissions.push(PERMISSION_VANPOOL_TRIP_RECORDING);
  }

  if (loginProfile.isIPB) {
    permissions.push(PERMISSION_IPB);
  }

  if (loginProfile.isVanpoolNTDReporting) {
    permissions.push(PERMISSION_NTD_TRIP_RECORDING);
  }

  if (loginProfile.isAllowManagePaymentMethods) {
    permissions.push(PERMISSION_MANAGE_PAYMENT_METHODS);
  }

  if (VANPOOL_PRE_DELIVERY.includes(loginProfile.vanpoolStatusCode)) {
    permissions.push(PERMISSION_VANPOOL_STATUS_PRE_DELIVERY);
  } else if (VANPOOL_RENTAL.includes(loginProfile.vanpoolStatusCode)) {
    permissions.push(PERMISSION_VANPOOL_STATUS_RENTAL);
  }

  if (loginProfile.vanpoolId) {
    loginProfile.roles.coordinator
      ? permissions.push(PERMISSION_COORDINATOR)
      : permissions.push(PERMISSION_RIDER);
  } else {
    permissions.push(PERMISSION_NOVANPOOL);
  }

  if (loginProfile.vanpoolId && loginProfile.isSubscription && (loginProfile.roles.driver || loginProfile.roles.coordinator)) {
    permissions.push(SUBSCRIPTION_TRIP_RECORDER);
  }

  if (loginProfile.vanpoolId && loginProfile.roles.driver) {
    permissions.push(PERMISSION_DRIVER);
  }

  if (loginProfile.isSubscription) {
    permissions.push(PERMISSION_SUBSCRIPTION_USER);
  } else {
    permissions.push(PERMISSION_TRADITIONAL_USER);
  }

  if (!loginProfile.vanpoolId && !loginProfile.roles.accountDashboardUser) {
    permissions.push(PERMISSION_REGISTERED_USER);
  }

  if (!loginProfile.vanpoolId && loginProfile.roles.accountDashboardUser) {
    permissions.push(PERMISSION_ACCOUNT_DASHBOARD_USER);
  }

  if (
    loginProfile.roles.tripRecorder ||
    loginProfile.isRegisteredUserTripRecorder
  ) {
    permissions.push(PERMISSION_TRIP_RECORDING);
  }

  if (loginProfile.roles.billingViewer) {
    permissions.push(PERMISSION_BILLING_VIEWER);
  }

if( !loginProfile.isSubscription &&  (loginProfile.roles.coordinator || loginProfile.roles.driver ) ){
    permissions.push(TRADITIONAL_ROSTER_ADDRESS_PERMISSION);
}

if( !loginProfile.isSubscription && loginProfile.roles.coordinator){
  permissions.push(TRADITIONAL_COORDINATOR_PERMISSION);
}

  if (VANPOOL_FUELCARD_STATUS.includes(loginProfile.fuelCardStatus)) {
    switch (loginProfile.fuelCardStatus) {
      case FUEL_CARD_ACTIVE:
        permissions.push(PERMISSION_FUEL_CARD_ACTIVE);
        break;
      case FUEL_CARD_PENDING:
        permissions.push(PERMISSION_FUEL_CARD_PENDING);
        break;
      case FUEL_CARD_NONE:
        permissions.push(PERMISSION_FUEL_CARD_NONE);
        break;
      default:
        permissions.push(PERMISSION_FUEL_CARD_NONE);
    }
  } else {
    permissions.push(PERMISSION_FUEL_CARD_NONE);
  }

  return permissions;
}

export function getSubscriptionPermissions(subscriptionAPIInfo) {
  let subscriptionPermissions = [];
  if(subscriptionAPIInfo){
    if (subscriptionAPIInfo.package === null && subscriptionAPIInfo.pendingChange !== null) {
      pushUnique(subscriptionPermissions, PERMISSION_VAN_STARTING_IN_FUTURE);
    } else {
      if (subscriptionAPIInfo.endDate && subscriptionAPIInfo.pendingChange) {
        pushUnique(subscriptionPermissions, PERMISSION_SUBSCRIPTION_CHANGED);
      }
      if (!subscriptionAPIInfo.endDate && !subscriptionAPIInfo.pendingChange && subscriptionAPIInfo.package) {
        pushUnique(subscriptionPermissions, PERMISSION_SUBSCRIPTION_UNCHANGED);
  
      }
    }
  }
  return subscriptionPermissions;
}

export function getProfilePermissions(profileData) {
  let profilePermissions = [];
  if(profileData && profileData.driverStatus === 'Approved'){
    pushUnique(profilePermissions, PERMISSION_APPROVED_DRIVER);
  }
  return profilePermissions;
}
