import React, { Component } from 'react';
import analyticsService from './analytics-service';

export default class AnalyticsButton extends Component {
  clickWrapper() {
    analyticsService.analyticsEvent({ type: this.props.eventType });
    this.props.onClick();
  }

  render() {
    return (
      <button
        className={this.props.className}
        onClick={() => this.clickWrapper()}
      >
        {this.props.children}
      </button>
    );
  }
}
