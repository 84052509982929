import * as actionTypes from '../../../../redux/actionTypes';

import { getPackageFromDays, getVanPrice } from '../../../../join-commute/helpers/vanHelpers';

import ArrowLeft from '../../../../_styles/images/ArrowLeft.png';
import DollarSign from '../../../../_styles/images/new-icons/DollarSign.svg';
import ErrorCircle from '../../../../_styles/images/error-circle.svg';
import { Link } from 'react-router-dom';
import Loading from '../../../../shared/loading/Loading';
import PaymentCard from '../../../../payment/common/paymentCard';
import { RouteDetails } from '../../../../join-commute/common/route-details/RouteDetails';
import SteeringWheel from '../../../../_styles/images/new-icons/SteeringWheel.svg';
import { assignPriceToCards } from '../../../../shared/helpers/cardHelpers';
import { connect } from 'react-redux';
import { getSortedCards } from '../../../../payment/helper';
import { isEqual } from 'lodash';
import { numberWithCommas } from '../../../../join-commute/helpers/commonHelper';
import { useEffect } from 'react';
import { useState } from 'react';
import PriceDisclaimerPopup from '../../../../join-commute/subscription/PriceDisclaimerPopup';
import TaxesAccordion from '../../../../payment/common/TaxesAccordion';
import RateAccordion from '../../../../payment/common/RateAccordion';
import analyticsService from '../../../../shared/analytics/analytics-service';

function ApplyToDriveSummary(props) {

  const [commuteDays, updateCommuteDays] = useState([]);
  const [isLoading, updateIsLoading] = useState(false);
  const [driverRate, updateDriverRate] = useState({
    price: 0,
    decimal: 0,
    rate: 0,
    tax: 0,
    totalAmount: 0,
    surcharges: [],
    taxes: [],
    surchargeAmount: '0',
    taxAmount: '0'
  });
  const [poolPackage, updatePoolPackage] = useState('1X');
  const [paymentCards, updatePaymentCards] = useState([]);
  const [showPDPopup, updatePDPopup] = useState(false);

  useEffect(() => {
    if (props.profile) {
      if (props.profile.vanpool && props.profile.vanpool.commuteDays) {
        updateCommuteDays(props.profile.vanpool.commuteDays)
        const pack = getPackageFromDays(props.profile.vanpool.commuteDays.length);
        updatePoolPackage(pack);
      }
      if (props.profile.paymentCards) {
        const sortedCards = getSortedCards(props.profile.paymentCards);
        const chargedCards = assignPriceToCards(driverRate.totalAmount, sortedCards);
        updatePaymentCards(chargedCards);
      }
    }
  }, [props.profile, driverRate])

  useEffect(() => {
    if (isEqual(props.driverApplicationData, {})) {
      return props.history.push("/myprofile");
    }
    else if (!props.ratesDataLoaded) {
      updateIsLoading(true);
      props.init();
    }
  }, []);

  useEffect(() => {
    if (props.ratesDataLoaded) {
      updateIsLoading(false)

    }
  }, [props.ratesDataLoaded])

  useEffect(() => {
      if (props.vanpoolRates) {
        const driverPrice = getVanPrice({ vanpoolRates: props.vanpoolRates }, true, poolPackage);
        updateDriverRate(driverPrice);
      }
  }, [props.vanpoolRates, poolPackage])

  function applyToDrive() {
      analyticsService.analyticsProcessEvent({
        "event": "click_generic",
        "context": {
          "event_action": "continue" 
           }
      })
      updateIsLoading(true);
      props.applyToDrive(props.history, '/myprofile');
  }

  return (
    <div className='profile-overview-page'>
      <Loading isLoading={isLoading} />
      <div className='profile-edit-page apply-to-drive-page'>
        <div className='view-container apply-to-drive'>
          <div className='header'>
            <div className='backArrow'>
              <Link className="overview-link" onClick={props.history.goBack}>
                <img alt='go back' src={ArrowLeft}></img>
              </Link>
            </div>
            <div className='heading' tabIndex={0}>Summary</div>
          </div>
          <div className='content-container'>
            <div className='thanks-content'>
              <img src={SteeringWheel} />
              <div className='thanks-text' tabIndex={0}>Thanks for applying to be a driver! Your plan will be updated upon approval.</div>
            </div>
            <div className='plan-card'>
              <div className='plan-content'>
                <div className='badge primary' tabIndex={0}>Updated Plan</div>
                {commuteDays && commuteDays.length > 4 ?
                  <div className='plan' tabIndex={0}>Unlimited Plan</div>
                  :
                  <div className='plan' tabIndex={0}>{commuteDays.length}-Day Plan</div>
                }
              </div>
              <div className='plan-content'>
                <div className='rate-badge' tabIndex={0}>
                  <img src={SteeringWheel} />Driver rate</div>
                <div className='amount' tabIndex={0}>
                  <div className='dollar'>$</div>
                  <div className='price'>{numberWithCommas(driverRate.price)}</div>
                  <div className='decimal'>.{driverRate.decimal}</div>
                </div>
                <div className='per-month' tabIndex={0}>total per month</div>
              </div>
            </div>
            <hr />
            <div className='van-info'>
              <div className='section first'>
                <div className='top'>
                  <div className='left'>
                    <div className='van-name' tabIndex={0}>{props.profile.vanpool.name}</div>
                    <div className='van-type' tabIndex={0}> {props.profile.vanpool.vehicleType}</div>
                  </div>
                  <div className='right'>
                    <img className='van-image' src={props.profile.vanpool.vanpoolImage} alt={props.profile.vanpool.vehicleType} />
                  </div>
                </div>
                <div className='bottom'>
                  <div className='days-section'>
                    <div className='commuteDays-heading' tabIndex={0}>Commute days</div>
                    <div className='commute-days'>
                      {commuteDays && commuteDays.map(day => {
                        return <div tabIndex={0} className='block' aria-label={day} key={day}>{day.substring(0, 3)}</div>
                      })}
                    </div>
                    <div className='blue-notes' tabIndex={0}>
                      <div className='warning-icon'>
                        <img className='error-circle' src={ErrorCircle} />
                      </div>
                      Your commute days can be changed later from your profile
                    </div>
                  </div>
                </div>
              </div>
              <div className='section'>
                {props.profile.commuteDetails && <RouteDetails vanpool={{
                  originAddress: props.profile.commuteDetails.originAddress,
                  destinationAddress: props.profile.commuteDetails.destinationAddress,
                  matchedOriginStop: {
                    departureTimestamp: props.profile.commuteDetails.originDepartureTime,
                    arrivalTimestamp: props.profile.commuteDetails.originArrivalTime
                  },
                  matchedDestinationStop: {
                    departureTimestamp: props.profile.commuteDetails.destinationDepartureTime,
                    arrivalTimestamp: props.profile.commuteDetails.destinationArrivalTime
                  }
                }} />}
              </div>
            </div>
            <div className='rate-details'>
              <div className='breakdown bottom-left'>
                <div className='blue-notes' tabIndex={0}>
                  <div className='warning-icon'>
                    <img className='error-circle' src={DollarSign} />
                  </div>
                  Your new subscription rate will take effect next month
                </div>
                <RateAccordion
                  isDesiredDriverRole={true}
                  rateMessage={null}
                  charge={driverRate.rate}
                />
                <TaxesAccordion taxes={driverRate.taxes} surcharges={driverRate.surcharges} total={parseFloat(driverRate.taxAmount)+ parseFloat(driverRate.surchargeAmount)}/>
                <div className='bottom' role='group'>
                  <div className='disclaimer' role='group'>
                        <p>
                        Recurring subscription. <br />
                        Rates subject to change. <br />
                        <span onClick={() => updatePDPopup(true)}>Learn More</span>
                        </p>
                  </div>
                  <div className='total'>
                    <div className='sub' tabIndex={0}>Total</div>
                    <span className='grp'>
                    <div className='total-price' tabIndex={0}>
                      <div className='dollar'>$</div>
                      <div className='price'>{numberWithCommas(driverRate.price)}</div>
                      <div className='decimal'>.{driverRate.decimal}</div>
                    </div>
                    <div className='frequency'>per month</div>
                    </span>
                  </div>
                </div>
              </div>
              <div className='bottom-right'>
                <div className='payment-cards'>
                  <div className='title'>
                    <div className='sub-title' tabIndex={0}>Payment Cards</div>
                    <div className='sub-text' tabIndex={0}>Recurring charges</div>
                  </div>
                  <div className='cards-container'>
                    {
                      paymentCards && paymentCards.map((card) => <PaymentCard key={card.partialAccountNumber} card={card} />)
                    }
                  </div>
                </div>
              </div>
            </div>
            {
              showPDPopup &&  <PriceDisclaimerPopup updatePDPopup={() => updatePDPopup(false)} />
            }
            <div className='button'>
                <button className='continue' onClick={applyToDrive}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    ratesDataLoaded: state.profile.ratesLoaded,
    vanpoolRates: state.profile.vanpool.vanpoolRates,
    driverApplicationData: state.profile.driverApplicationData,
    subscriptionChanged: state.profile && state.profile.subscription && state.profile.subscription.changed,
    subscriptionAPIInfo: state.profile.subscriptionAPIInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.GET_VANPOOL_RATES_INFO })
    },
    applyToDrive(history, navigateTo) {
      dispatch({ type: actionTypes.APPLY_TO_DRIVE, history, navigateTo })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyToDriveSummary);