import React from 'react';
import { Close } from '../../shared/icons';

export default function VanpoolModal({ active, close, children }) {

  if (!active) {
    return null;
  }

  return (
    <div className="vanpool-modal-container">
      <div className="modal">
        <div className="header">
        <span className="close" onClick={close} >
        <Close />
        </span>
        </div>
        <div className="body">{children}</div>
        <div className="footer">
        </div>
      </div>
    </div>
  );
}
