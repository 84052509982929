import {useState,useEffect} from 'react';
import {connect} from 'react-redux';


function FuelCardHistory(props) {

  return (
      <div className='payment-cards-page'>
          {"fuel Cards Screen"}
      </div>
  );
}

function mapStateToProps(state) {
  return {
  }
}

function mapDispatchToProps(dispatch) {
  return {
    }
  }

export default connect(mapStateToProps,mapDispatchToProps)(FuelCardHistory);
