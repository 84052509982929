import * as actionTypes from '../redux/actionTypes';

const initialState = {
  directions: [],
  googleDirections: null,
  durations: [],
  origin:[]
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MAP_DIRECTIONS:
      return { ...state, directions: action.data };
    case actionTypes.MAP_GOOGLE_DIRECTIONS:
      return { ...state, googleDirections: action.data };
    case actionTypes.MAP_DURATIONS:
      return { ...state, durations: action.data };
    case actionTypes.MAP_ORIGIN:
      return { ...state, origin: action.data };
    default:
      return state;
  }
};
