import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Trash, Pencil } from '../../shared/icons';
import currencyFormatter from '../../shared/currencyFormatter';
import * as actionTypes from '../../redux/actionTypes';
import StopRecurringConfirmationPopup from '../../payment/StopRecurringConfirmationPopup';
import { RestrictFrom, RestrictTo } from '../../shared/permissions/RestrictTo';
import { PERMISSION_REGISTERED_USER } from '../../shared/permissions/permissions';

class ScheduledPayments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupOpen: false,
      selectedPayment: {}
    };
  }

  componentDidMount() {
    this.props.init();
  }

  openPopup(item) {
    this.setState({ popupOpen: true, selectedPayment: item });
  }

  cancel() {
    this.setState({ popupOpen: false, selectedPayment: {} });
  }

  deleteItem() {
    this.props.deleteScheduledPayment(this.state.selectedPayment);
    this.setState({ popupOpen: false });
  }

  render() {
    const payments = this.props.scheduled;
    return (
      <div className="etable recurring">
        <div className="etable-cat">
          <div className="etable-row header-labels">
            <div className="etable-header title">My Recurring Payments</div>
            <div className="etable-header payment-schedule">
              Payment Schedule
            </div>
            <div className="etable-header amount">Amount</div>
            <div className="etable-header edit">Edit</div>
            <div className="etable-header delete">Delete</div>
          </div>
          <StopRecurringConfirmationPopup
            active={this.state.popupOpen}
            paymentName={this.state.selectedPayment.name}
            cancel={() => this.cancel()}
            confirm={() => this.deleteItem()}
          />
          <RestrictFrom roles={[PERMISSION_REGISTERED_USER]} required={false}>
            <div className="etable-body">
              {payments.length ? (
                payments.map(item => (
                  <ScheduledPayment
                    item={item}
                    key={item.id}
                    deleteItem={() => this.openPopup(item)}
                  />
                ))
              ) : (
                <NoScheduledPayments />
              )}
            </div>
          </RestrictFrom>
          <RestrictTo roles={[PERMISSION_REGISTERED_USER]} required={false}>
            <div className="etable-body">
              {payments.length
                ? payments.map(item => (
                    <InactiveScheduledPayment
                      item={item}
                      key={item.id}
                      deleteItem={() => this.openPopup(item)}
                    />
                  ))
                : null}
            </div>
          </RestrictTo>
        </div>
      </div>
    );
  }
}

function ScheduledPayment({ item, deleteItem }) {
  return (
    <div className="etable-row" item={item}>
      <div className="mobile-only-label">
        <label>Name:&nbsp;</label>
        <div className="name etable-cell mobile-only">{item.name}</div>
      </div>
      <div className="name etable-cell desktop-only">
        <Link
          className="edit"
          to={`/payment/pay/recurring/${item.id}`}
          aria-label={`Edit recurring payment ${item.name}`}
        >
          {item.name}
        </Link>
      </div>
      <div className="mobile-only-label">
        <label>Description:&nbsp;</label>
        <div className="description etable-cell">
          {item.every} of every month
        </div>
      </div>
      <div className="mobile-only-label">
        <label>Amount:&nbsp;</label>
        <div className="amount etable-cell right">
          {currencyFormatter.formatUSD(item.amount)}
        </div>
      </div>
      <div className="actions etable-cell">
        <Link
          className="edit"
          to={`/payment/pay/recurring/${item.id}`}
          aria-label={`Edit recurring payment ${item.name}`}
        >
          <Pencil />
        </Link>
        <span
          tabIndex="0"
          className="delete"
          onClick={deleteItem}
          onKeyPress={e => e.key === 'Enter' && deleteItem()}
          aria-label={`Delete recurring payment ${item.name}`}
        >
          <Trash />
        </span>
      </div>
    </div>
  );
}

function InactiveScheduledPayment({ item, deleteItem }) {
  return (
    <div className="etable-row" item={item}>
      <div className="name etable-cell">{item.name}</div>
      <span />
      <div className="description etable-cell italic emergency">
        Currently Inactive
      </div>
      <div className="amount etable-cell right">
        {currencyFormatter.formatUSD(item.amount)}
      </div>
      <div className="actions etable-cell">
        <span
          tabIndex="0"
          className="delete"
          onClick={deleteItem}
          onKeyPress={e => e.key === 'Enter' && deleteItem()}
          aria-label={`Delete recurring payment ${item.name}`}
        >
          <Trash />
        </span>
      </div>
    </div>
  );
}

function NoScheduledPayments() {
  return (
    <div className="etable-row no-scheduled-payments">
      <div className="etable-cell desktop-only">No Scheduled Payments</div>
      <div className="etable-cell">
        <Link to="/payment/pay/recurring">Setup a Recurring Payment</Link>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    scheduled: state.scheduledPayments.scheduled
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.SCHEDULED_PAYMENTS_LOADING });
    },
    deleteScheduledPayment(payment) {
      dispatch({
        type: actionTypes.SCHEDULED_PAYMENTS_DELETING,
        data: payment
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduledPayments);
