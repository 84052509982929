import * as actionTypes from '../redux/actionTypes';

const initialState = {
  requestToJoin: false,
  count: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_TO_JOIN_SUCCESS:
      return { ...state, requestToJoin: true };
    case actionTypes.REQUEST_TO_JOIN_FAILURE:
      return { ...state, requestToJoin: false };
    case actionTypes.VANPOOL_SEARCH_RESULT_COUNT:
      return { ...state, count: action.data };
    default:
      return state;
  }
};
