import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  participant: {
    previous: {
      milesSaved: 0,
      fuelGallonsSaved: 0,
      fuelSavings: 0,
      co2Savings: 0,
      parkingSavings: 0
    },
    current: {
      milesSaved: 0,
      fuelGallonsSaved: 0,
      fuelSavings: 0,
      co2Savings: 0,
      parkingSavings: 0
    }
  },
  vanpool: {
    previous: {
      milesSaved: 0,
      fuelGallonsSaved: 0,
      fuelSavings: 0,
      co2Savings: 0,
      parkingSavings: 0
    },
    current: {
      milesSaved: 0,
      fuelGallonsSaved: 0,
      fuelSavings: 0,
      co2Savings: 0,
      parkingSavings: 0
    }
  },
  impactDataLoaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.IMPACT_DATA_LOADED:
      return { ...state, ...action.data, impactDataLoaded: true };
    default:
      return state;
  }
};
