import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  invoiceSummary: [],
  summaryLoaded: false,
  chosenInvoice: {},
  invoiceSummaryError: false,
  invoiceSummaryErrorString: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.INVOICE_HISTORY_DETAILS_LOADED:
      return { ...state, invoiceSummary: [...action.data], summaryLoaded: true, invoiceSummaryError: false };
    case actionTypes.INVOICE_HISTORY_DETAILS_LOADING_ERROR:
      return { ...state, invoiceSummary: [...action.data], summaryLoaded: true, invoiceSummaryError: true, invoiceSummaryErrorString: action.errorString };
    case actionTypes.CHOOSE_INVOICE:
      return { ...state, chosenInvoice: action.data };
    default:
      return state;
  }
};
