import * as actionTypes from '../redux/actionTypes';

export const initDriverScheduleChangeData = {
    toCancel: [],
    toDrive: []
}

export const initDriverScheduleMonthlyChangeData = {
    toCancel: {},
    toDrive: {}
}

const driverScheduleInit = {
    driverScheduleList: null,
    loaded: false,
    loading: false,
    updatePreferencesSuccess: null,
    updateScheduleSuccess: null,
    changedData: initDriverScheduleChangeData,
    monthlyChangedData: initDriverScheduleMonthlyChangeData,
    saveState: {
        isToggled: false,
        indexSelected: 0
    },
    preferences: {},
    preferencesLoaded: false,
    preferencesLoading: false,
    preferencesLoadingError: false,
    scheduleLoadingError: false,
    scheduleLoadingErrorString: ''
}

export const driverScheduleReducer = (state = driverScheduleInit, action) => {
    switch (action.type) {
        case actionTypes.GET_DRIVER_SCHEDULE: return {
            ...state,
            loading: true,
            loaded: false
        }
        case actionTypes.GET_DRIVER_SCHEDULE_ERROR: return {
            ...state,
            loading: false,
            loaded: true,
            scheduleLoadingError: true,
            scheduleLoadingErrorString: action.errorString
        }
        case actionTypes.SET_DRIVER_SCHEDULE: return {
            ...state,
            ...action.data,
            loading: false,
            loaded: true,
            scheduleLoadingError: false
        }
        case actionTypes.GET_DRIVER_PREFERENCES: return {
            ...state,
            preferencesLoading: true
        }
        case actionTypes.GET_DRIVER_PREFERENCES_FAILURE: return {
            ...state,
            preferencesLoading: false,
            preferencesLoaded: true,
            preferencesLoadingError: true,
        }
        case actionTypes.SET_DRIVER_PREFERENCES: return {
            ...state,
            preferences: action.data,
            preferencesLoading: false,
            preferencesLoaded: true,
            preferencesLoadingError: false,
        }
        case actionTypes.UPDATE_DRIVER_PREFERENCES_SUCCESS: return {
            ...state,
            loading: false,
            updatePreferencesSuccess: true
        }
        case actionTypes.UPDATE_DRIVER_PREFERENCES_ERROR: return {
            ...state,
            loading: false,
            updatePreferencesSuccess: false
        }
        case actionTypes.UPDATE_DRIVER_PREFERENCES_RESET: return {
            ...state,
            updatePreferencesSuccess: null
        }
        case actionTypes.UPDATE_CHANGED_DATA: return {
            ...state,
            changedData: action.changedData,
            monthlyChangedData: action.monthlyChangedData
        }
        case actionTypes.SAVE_DRIVER_SCHEDULE_PRESENT_STATE: return {
            ...state,
            saveState: action.saveState
        }
        case actionTypes.UPDATE_DRIVER_SCHEDULE: return {
            ...state,
            loading: true,
            updateScheduleSuccess: null
        }
        case actionTypes.UPDATE_DRIVER_SCHEDULE_SUCCESS: return {
            ...state,
            loading: false,
            updateScheduleSuccess: true
        }
        case actionTypes.UPDATE_DRIVER_SCHEDULE_FAILURE: return {
            ...state,
            loading: false,
            updateScheduleSuccess: false
        }
        case actionTypes.UPDATE_DRIVER_SCHEDULE_RESET: return {
            ...state,
            updateScheduleSuccess: null
        }
        case actionTypes.RESET_DRIVER_SCHEDULE_PRESENT_STATE: return {
            ...state,
            changedData: {
                toCancel: [],
                toDrive: []
            },
            monthlyChangedData: {
                toCancel: [],
                toDrive: []
            },
            saveState: {
                isToggled: false,
                indexSelected: 0
            }
        }
        default:
            return state;
    }
}