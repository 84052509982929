import Van from '../../_styles/images/Van.png';
import Timer from '../../_styles/images/Timer.png';

export default function PredeliveryPopup(props) {

  return (
    <div className="vanpool-popup-container">
        <div className="heading">{"You have selected a commute that is forming now"}</div>
        <div className="title">{"With a little help from you, we can work to getting this van up and running"}</div>
        <div className="container">
                <div className="section">
                    <div className="icon">
                    <img src={Van} />
                    </div>
                    <div className="details">
                        {"This van is in the process of becoming active and may take a few weeks until you will be able to ride. Changes to vehicle type or seating arrangements can result in a different price. We’ll notify you if there is any change. "}
                    </div>
                </div>
                <div className="section">
                    <div className="icon">
                    <img src={Timer} />
                    </div>
                    <div className="details">
                        {"After signing up, you will be in contact with a representative to inform you of the status of the van"}
                    </div>
                </div>
        </div>
        <div className="button">
            <button className="continue" onClick={props.goToChoosePlan}> {"continue"}</button>
        </div>
    </div>   
  )
}

