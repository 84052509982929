import React, { Component } from 'react';
import { connect } from 'react-redux';
import FancyInput from '../shared/FancyInput';
import { AddressInput } from '../shared/AddressInput';
import { TimeInput } from '../shared/TimeInput';
import DaysInput from '../shared/DaysInput';
import QuestionYesNo from '../shared/inputs/questionYesNo';
import WorkAddressSelect from './WorkAddressSelect';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';
import Http from '../shared/http-service';
import config from '../appConfig';
import Callable from '../shared/Callable';
import nonceUtility from '../login/nonceUtility';
import azureService from '../login/azureService';

const PARTNER_MESSAGE = (
  <span>
    The company entered is associated with a Commute with Enterprise partner.
    Please sign up with your valid work email.  If you have questions, please
    call <Callable phone="800-VAN-4-WORK" />.
  </span>
);

export default class CommuteEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employer: props.employer,
      showInvalidEmployer: false
    };

    this.cancelEmployerWarning = this.cancelEmployerWarning.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.employerBlur = this.employerBlur.bind(this);
  }

  async employerBlur() {
    if (!this.props.employer) {
      return;
    }

    this.props.update({ validEmployer: false });

    const { isPartner } = await Http.get(
      `${config.kongApiBaseUrl}/user/profile/validate/company?companyName=${this.props.employer}`
    );

    this.props.update({ validEmployer: !isPartner });
    this.setState({ showInvalidEmployer: isPartner });
  }

  cancelEmployerWarning() {
    this.setState({ showInvalidEmployer: false });
  }

  changeEmail() {
    localStorage.setItem('mobileDeepLink', '/profile/commute-info');
    azureService.changeEmail();
  }

  render() {
    const {
      worksiteId,
      employer,
      activeDays,
      arriveAtWork,
      departFromWork,
      homeAddress,
      workAddress,
      mode,
      arriveFlexible,
      departFlexible,
      update,
      isPartner,
      isFederatedLogin
    } = this.props;
    const notAllowed = activeDays.length === 1 ? activeDays : [];
    return (
      <div>
        <ConfirmationPopup
          active={this.state.showInvalidEmployer}
          title="Welcome Commute Vanpool Partner"
          message={PARTNER_MESSAGE}
          cancelMessage="Cancel"
          cancel={this.cancelEmployerWarning}
          confirmMessage="Change Email Address"
          confirm={this.changeEmail}
        />
        <div>
          <FancyInput
            type="text"
            className={`edit-employer ${employer ? '' : 'invalid'}`}
            disabled={isPartner}
            placeholder="Employer"
            maxLength="100"
            value={employer}
            onChange={e => update({ employer: e.target.value })}
            onBlur={this.employerBlur}
          />
          {!employer ? (
            <span className="employer-invalid">Please enter an employer.</span>
          ) : null}
          {isPartner && !isFederatedLogin ? (
            <span className="not-employer" onClick={this.changeEmail}>
              I do not work for the company listed above
            </span>
          ) : null}
          <div className="days-editor">
            <label htmlFor="commuteSchedule">Commute Schedule</label>
            <DaysInput
              value={activeDays}
              notAllowed={notAllowed}
              onChange={activeDays => update({ activeDays })}
            />
          </div>
          <label htmlFor="arriveWorkTime">
            What time do you arrive at work?
          </label>
          <TimeInput
            id="arriveWorkTime"
            value={arriveAtWork}
            onSelected={arriveAtWork => update({ arriveAtWork })}
          />
          <QuestionYesNo
            question="Is your arrival time flexible?"
            onChange={arriveFlexible => update({ arriveFlexible })}
            value={arriveFlexible}
          />
          <label htmlFor="arriveHomeTime">
            What time do you depart for home?
          </label>
          <TimeInput
            id="arriveHomeTime"
            value={departFromWork}
            onSelected={departFromWork => update({ departFromWork })}
          />
          <QuestionYesNo
            question="Is your departure time flexible?"
            onChange={departFlexible => update({ departFlexible })}
            value={departFlexible}
          />
          <label htmlFor="commuteStart" className="attach">
            Commute Start
          </label>
          <AddressInput
            placeholder="Address"
            className="addr-edit"
            id="commuteStart"
            value={homeAddress}
            onChange={value => update({ homeAddress: value.address })}
          />
          <label htmlFor="commuteEnd" className="attach">
            Commute End
          </label>
          <WorkAddressSelect
            workAddress={workAddress}
            isPartner={isPartner}
            worksiteId={worksiteId}
            update={update}
          />
          <label htmlFor="commuteStart" className="attach">
            Transportation Mode
          </label>
          <TransportationDropDown
            value={mode}
            onChange={e => update({ mode: e.target.value })}
          />
        </div>
      </div>
    );
  }
}

const TransportationDropDown = connect(state => ({
  modes: state.commuteProfile.modes
}))(({ modes, value, onChange }) => (
  <div className="select transportation">
    <select value={value} onChange={onChange}>
      {modes
        .filter(mode => mode.label !== 'Did not commute')
        .map(mode => (
          <option key={mode.label} value={mode.label}>
            {mode.label}
          </option>
        ))}
    </select>
  </div>
));
