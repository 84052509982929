import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
// eslint-disable-next-line
import './_styles/style.css';
import './polyfill';
import {Provider} from 'react-redux';
import createStoreWithMiddleware from './redux/store';
import {HashRouter} from 'react-router-dom';
import App from './App';
import UserService from './shared/user-service';
import {PersistGate} from 'redux-persist/lib/integration/react';

if(window.location.hash.startsWith('#/login')) {
  UserService.clearAuthentication();
}

window.addEventListener('hashchange',() =>
  window.location.hash.startsWith('#/stats') ? null : window.scrollTo(0,0)
);

const {store,persistor} = createStoreWithMiddleware();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HashRouter baseUrl="/">
        <App />
      </HashRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
