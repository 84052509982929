import {
  LINK_VANPOOL_SUCCESS,
  LINK_VANPOOL_FAILURE,
  LINK_VANPOOL,
  CLEAR_AUTHENTICATION,
  SAVE_CONTACT_DETAILS_SUCCESS,
  SAVE_CONTACT_DETAILS_FAILURE
} from '../redux/actionTypes';

const initialState = {
  vanpoolLinkSuccess: null,
  contactInfoSaved: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LINK_VANPOOL_SUCCESS:
      return {
        vanpoolLinkSuccess: true,
        domainMismatch:
          action.data.isPartner && !action.data.isPartnerEmailDomainMatch
      };
    case LINK_VANPOOL_FAILURE:
      return {
        vanpoolLinkSuccess: false,
        existingEmail: action.data.existingAccountMaskedEmail
      };
    case LINK_VANPOOL:
      return { vanpoolLinkSuccess: null };
    case CLEAR_AUTHENTICATION:
      return { vanpoolLinkSuccess: null };
    case SAVE_CONTACT_DETAILS_SUCCESS:
      return {...state, contactInfoSaved: action.data.success  };
    case SAVE_CONTACT_DETAILS_FAILURE:
      return {...state, contactInfoSaved: false };
    default:
      return state;
  }
}
