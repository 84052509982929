import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Loading from '../shared/loading/Loading';
import analyticsService from '../shared/analytics/analytics-service';


function ContractAgreement(props) {
    const [loading, setLoading] = useState(props.loading);


    useEffect(() => {
        if (props.agreementURL && !props.contractInfo.isAgreed) {
            setLoading(true);
           // props.updateLoading(false);
            getDS(props.agreementURL)
        }else {
            return handleDownloadModal2();
        }
    }, [])


    const getDS = (agreementURL) => {
        docuSignClick.Clickwrap.render({
            agreementUrl: agreementURL,
            onAgreed: onAgreed,
            onDownloadCancelled: handleDownloadModal2,
            onDownloaded: handleDownloadModal2,
            onDeclining: handleDownloadModal2,
            onError: handleDownloadModal2
        }, "#ds-terms-of-service");
        checkIframeLoaded();
        analyticsService.pageInfo("sign contract");
    }

    const handleDownloadModal2 = () => {
        setLoading(true);
        props.updateLoading(true);
        analyticsService.analyticsProcessEvent({
            "event": "user_error",
            "context": {
              "event_action": "we ran into an error trying to load this data"
            }
        });
        props.callLoginProfile()
    };

    const onAgreed = (data) => {
        props.saveAgreementDetails({ agreementId: data.agreementId, clickwrapId: data.clickwrapId, isAgreed: true, agreementURL: null, fromBroucher: false, isPendingApplication: props.isPendingApplication });
        props.updateContractStatus({agreementId: data.agreementId,clickwrapId:data.clickwrapId})
        setLoading(true);
        props.updateLoading(true);
        analyticsService.analyticsProcessEvent({
            "event": "agreement_agree_click",
            "context": {
              "event_action": "agree"
            }
        });
        analyticsService.pageInfo("sign contract");
        if(!props.isPendingApplication){
            props.callLoginProfile();
        }
    };

    const checkIframeLoaded = () => {
        const interval = setInterval(() => {
          const iframe = document.querySelector("#ds-terms-of-service iframe");
          if (iframe) {
            console.log("DocuSign Clickwrap iframe detected.");
                setLoading(false);
                props.updateLoading(false);
                clearInterval(interval); 
          }
        }, 3000); // Check every 2000 milliseconds
      };

    return (
        <>
            <Loading isLoading={loading} />
            <div className="ds-terms-of-service" id="ds-terms-of-service">
            </div>
        </>
    );
}

export default connect(null, null)(ContractAgreement);
