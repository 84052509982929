import { connect } from 'react-redux';
import { useEffect } from 'react';
import * as actionTypes from '../../redux/actionTypes';
import ThankYouTick from '../../_styles/images/thankyou-tick.svg';
import ThankYouCommute from '../../_styles/images/thankyou-commute.svg';
import config from '../../appConfig';
import MapService from '../../shared/map-service';
import AddressUtility from '../../shared/addressUtility';
import { WEEKDAYS } from '../../shared/inputs/Calendar/Calendar';

function TraditionalThankYou(props) {
  useEffect(() => {
    if (props.isTraditionalLead) {
      props.setIsTraditionalLead(false);
      saveTradioalInfo();
    }
  });

  useEffect(() => {
    props.updateProfileData();
  }, [])

  function goToDashboard() {
    props.setRefresh(true);
    props.history.push('/dashboard');
  }

  async function getAddressUtility(address) {
    let addressString = await MapService.getAddressAndLocation(address);
    var addressObj = AddressUtility.fromGeocode(addressString);
    return addressObj;
  }

  function getCommuteDays(daysArray) {
    var commuteDays = [];
    WEEKDAYS.forEach((day, ind) => {
      if (daysArray[day] === true) {
        commuteDays.push(ind + 1);
      }
    });
    return commuteDays;
  }

  async function saveTradioalInfo() {
    var requestObj = {
      ...props.joinCommuteData.employerData,
      firstName: props.userInfo.firstName,
      lastName: props.userInfo.lastName,
      emailAddress: props.profile.email,
      phone: {
        number: props.userInfo.phone.number
      },
      homeAddress: await getAddressUtility(
        props.joinCommuteData.userCommuteData.homeAddress
      ),
      workAddress: await getAddressUtility(
        props.joinCommuteData.userCommuteData.workAddress
      ),
      workArrivalTime: props.joinCommuteData.userCommuteData.arriveTime,
      workDepartureTime: props.joinCommuteData.userCommuteData.departTime,
      isWorkArriveTimeFlexible:
        props.joinCommuteData.userCommuteData.flexArrivalMinutes !== 0,
      isWorkDepartTimeFlexible:
        props.joinCommuteData.userCommuteData.flexDepartureMinutes !== 0,
      commuteDays: getCommuteDays(
        props.joinCommuteData.userCommuteData.rosterDays
      ),
      arriveFlexTimeInMinutes:
        props.joinCommuteData.userCommuteData.flexArrivalMinutes,
      departureFlexTimeInMinutes:
        props.joinCommuteData.userCommuteData.flexDepartureMinutes,
      vanpoolMatch: []
    };
    props.saveUserInfo(requestObj);
  }

  return (
    <div className="thank-you-page">
      <div className="thank-you-container">
        <div className="content-container">
          <div className="left-container">
            <div className="heading-container">
              <img className="tick" src={ThankYouTick} alt="tick" />
              <div className="heading">Thank you!</div>
            </div>
            <div className="comment">
              One of our representatives will contact you within 24 business
              hours to get your commute set up!
            </div>
            <div className="comment-two">
              In the mean time feel free to update your profile picture or apply
              to be a driver
            </div>
            <button onClick={goToDashboard}>Go To Dashboard</button>
          </div>
          <div className="right-container">
            <img
              className="commute-image"
              src={ThankYouCommute}
              alt="commute-image"
            />
          </div>
        </div>
        <div className="foot-privacy">
          View our{' '}
          <a
            href={config.privacyPolicyLink}
            target="_blank"
            rel="noopener noreferrer"
            className="privacy"
          >
            Privacy Policy
          </a>{' '}
          and{' '}
          <a
            href={config.termsOfUseLink}
            target="_blank"
            rel="noopener noreferrer"
            className="terms"
          >
            Terms of Use{' '}
          </a>
        </div>
      </div>
    </div>
  );
}
function mapStateToProps(state) {
  return {
    joinCommuteData: state.joinCommute,
    profile: state.profile,
    userInfo: state.userAuth,
    isTraditionalLead: state.dashboard.isTraditionalLead
  };
}
function mapDispatchToProps(dispatch) {
  return {
    setRefresh(refresh) {
      dispatch({ type: actionTypes.SET_REFRESH_DASHBOARD, refresh });
    },
    saveUserInfo(data) {
      dispatch({ type: actionTypes.SET_USER_INFO, data: data });
    },
    setIsTraditionalLead(data) {
      dispatch({ type: actionTypes.SET_TRADITIONAL_LEAD, data: data });
    },
    updateProfileData(){
      dispatch({type: actionTypes.UPDATE_USER_PROFILE_DATA});
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TraditionalThankYou);
