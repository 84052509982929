import React, { Component } from 'react';
import SegmentedAddressInput from './SegmentedAddressInput';
import { PlusCircle, Home, Close, Email } from '../icons';
import AddressUtility from '../addressUtility';

const emptyAddress = () => ({
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: ''
});

export default class HomeAndContactAddressInput extends Component {
  constructor(props) {
    super(props);

    const different = !AddressUtility.equal(
      props.homeAddress,
      props.mailingAddress
    );

    let homeAddress = props.homeAddress;
      homeAddress.valid = true;
    let mailingAddress = props.mailingAddress;
      mailingAddress.valid = true;

    this.state = {
      different,
      homeAddress: homeAddress,
      mailingAddress: different ? mailingAddress : emptyAddress(),
      validAddresses: !!homeAddress.line1,
      valid:true
    };
  }

  checkValid (homeAddress,mailingAddress) {
   let  isMainlingEmpty = AddressUtility.empty(mailingAddress);
    if(isMainlingEmpty){
      return homeAddress.valid;
    }else{
      return  (homeAddress.valid && mailingAddress.valid)
    }
  }

  update(update) {
    const { homeAddress, mailingAddress, different } = {
      ...this.state,
      ...update
    };

    const newState = {
      homeAddress,
      mailingAddress,
      different,
      valid:this.checkValid(homeAddress,mailingAddress),
      validAddresses:
        AddressUtility.valid(homeAddress, false) &&
          AddressUtility.valid(mailingAddress, true)
        //  || !AddressUtility.equal(homeAddress, mailingAddress)
    };

    this.setState(newState);
    this.props.update({
      ...newState,
      mailingAddress: AddressUtility.empty(mailingAddress)
        ? homeAddress
        : mailingAddress
    });
  }

  render() {
    return (
      <div className="addresses">
        <div className="home">
          <h3>
            <Home /> Home
          </h3>
          <SegmentedAddressInput
            {...this.state.homeAddress}
            valid={this.state.valid}
            isValid= {this.state.homeAddress.valid || AddressUtility.valid(this.state.homeAddress)}
            update={(homeAddress,valid) => {
              homeAddress.valid = valid; 
              this.update({ homeAddress })}
            }
          />
          {!this.state.different ? (
            <button
              className="pad-desk diff-mail"
              desktopafter="(If different from Home)"
              onClick={() => {
                this.update({
                  different: true,
                  mailingAddress: this.state.homeAddress
                });
              }}
            >
              <PlusCircle /> Mailing Address
            </button>
          ) : null}
        </div>
        {this.state.different ? (
          <div className="mailing-address">
            <div className="optional">
              <h3>
                <Email /> Mailing Address <span>(optional)</span>
              </h3>
              <Close
                className="close"
                onClick={() =>
                  this.update({
                    different: false,
                    mailingAddress: this.state.homeAddress
                  })
                }
              />
            </div>
            <SegmentedAddressInput
              {...this.state.mailingAddress}
              valid={this.state.valid}
              isValid= {this.state.mailingAddress.valid}
              update={ (mailingAddress, valid) =>{
                mailingAddress.valid = valid; 
                 this.update({ mailingAddress })}
              }
            />
          </div>
        ) : null}
      </div>
    );
  }
}
