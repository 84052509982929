import {useEffect,useRef,useState} from 'react';
import {flexibilityTimeIntervals, getIntervalFromFlexibility} from '../../join-commute/helpers/commonHelper';
import Tooltip from '../Tooltip'
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE, SPACE_KEY_CODE } from '../helpers/accessibilityHelpers';


export default function FlexibilityEntry(props) {
  const node = useRef();

  const [isOpen,setIsOpen] = useState(false);
  const [selectedOption,setSelectedOption] = useState({});

  const toggle = (e) => {
    if (e.which === ENTER_KEY_CODE || e.which === SPACE_KEY_CODE) {
      if(isOpen){
        node && node.current.focus();
      }
      setIsOpen(!isOpen);
    }
  }

  function optionClicked(opt) {
    setSelectedOption(opt);
    setIsOpen(false);
    node && node.current.focus();
    props.onChange(opt.flexibility);
  }

  function optionKeydown(e, opt) {
    if (e.which === ENTER_KEY_CODE || e.which === SPACE_KEY_CODE) {
      setSelectedOption(opt);
      setIsOpen(false);
      node && node.current.focus();
      props.onChange(opt.flexibility);
    }
  }

  const handleKeyNavigation = (e) => {
    if (e.which === ESCAPE_KEY_CODE) {
      node && node.current.focus();
      setIsOpen(false);
    }
  };
  
  useEffect(() => {
    const flexibility = getIntervalFromFlexibility(props.value);
    setSelectedOption(flexibility)
  }, [props.value]);

  const tempClass = `time-arrow ${isOpen ? 'up' : 'down'}`;
  const dropdownClass = `dropdown-list ${isOpen ? 'unhide' : 'hide'}`;

  const clickOutside = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown',clickOutside);
    document.addEventListener('keydown', handleKeyNavigation);
    return () => {
      document.removeEventListener('mousedown',clickOutside);
      document.removeEventListener('keydown', handleKeyNavigation);
    }
  },[isOpen])

  return (
    <fieldset className='flexibility-container' ref={node}>
      <div id={props.label} className='flexibility-label'>
      <label htmlFor={props.label}>{props.label}</label>
      {!props.hideTooltip && <Tooltip tooltipLabel={props.label + ' info'} message={props.message} />}
      </div>
     
      <div className='flexibility-header' aria-label={selectedOption && selectedOption.text ? selectedOption.text : props.label} role="button" aria-expanded={isOpen ? 'true' : 'false'} tabIndex={props.tabIndex || 0} onClick={() => setIsOpen(!isOpen)} onKeyDown={(e) => toggle(e)} aria-disabled={props.disabled}>
        {selectedOption && selectedOption.text ?
          <div className='placeholder-content'>
            {selectedOption.text}
          </div> :
          <div className='placeholder' aria-placeholder='flexibility'>
            <span className='text' aria-hidden='true'>Flexibility</span>
          </div>}
        <i className={tempClass}></i>
      </div>
      { isOpen && <div className='dropdown-container'>
        <div className={dropdownClass} role='listbox' aria-labelledby={props.label}>
          {flexibilityTimeIntervals.map(time =>
            <div tabIndex={0} role='option' id={time.text} className='list-item' onKeyDown={(e) => optionKeydown(e,time)} onClick={() => optionClicked(time)} key={time.flexibility} aria-selected={selectedOption && selectedOption.flexibility === time.flexibility}>{time.text}</div>
          )}
        </div>
      </div>}
    </fieldset>
  );

}