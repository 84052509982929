import React from 'react';
import { NavLink } from 'react-router-dom';
import { RestrictTo, RestrictFrom } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_VANPOOL_STATUS_PRE_DELIVERY,
  PERMISSION_REGISTERED_USER,
  PERMISSION_VANPOOL_TRIP_RECORDING,
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_COORDINATOR,
  PERMISSION_BILLING_VIEWER,
  PERMISSION_MANAGE_PAYMENT_METHODS,
  SUBSCRIPTION_TRIP_RECORDER,
  PERMISSION_VAN_STARTING_IN_FUTURE,
  PERMISSION_NOVANPOOL,
  PERMISSION_TRADITIONAL_USER
} from '../../shared/permissions/permissions';

import UserService from '../user-service';
import Dashboard from '../../_styles/images/new-icons/Dashboard';
import Van from '../../_styles/images/new-icons/Van';
import Payment from '../../_styles/images/new-icons/Payment';
import Trip from '../../_styles/images/new-icons/Trip';
import analyticsService from '../analytics/analytics-service';

export default function MainNavigation() {
  const isCoordinatorOrBillingViewer = UserService.hasPermissions([
    PERMISSION_COORDINATOR,
    PERMISSION_BILLING_VIEWER
  ]);

  function sendAnalyticEvent(str) {
    analyticsService.analyticsProcessEvent({
      "event": "click_nav",
      "context": {
        "event_action": `navigation:${str}`
      }
    });
  }
  const hideDashboarLink =  localStorage.getItem('inviteId') ? true : false;
  return (
    <div className="faded-overlay">
      {/* Main Navigation */}
      <ul className="main-nav">
        <RestrictTo
          roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
          required={false}
        >
          <li className={(window.location.hash.includes('#/stats')) ? "active" : ""}>
            <div className={(window.location.hash.includes('#/stats')) ? "inner-content active" : "inner-content"}>
              <NavLink
                id="stats-nav-link"
                replace={'#/stats' === window.location.hash}
                activeClassName="active"
                to="/stats"
                onClick={() => sendAnalyticEvent('stats')}
              >
                Stats
              </NavLink>
            </div>
          </li>
        </RestrictTo>
        {
          !hideDashboarLink &&
          <li className={(window.location.hash.includes('#/dashboard')) ? "active" : ""}>
          <div className={(window.location.hash.includes('#/dashboard')) ? "inner-content active" : "inner-content"}>
            <Dashboard className="icon side-navigation-only" />
            {/* <NewDashboard className='icon side-navigation-only'/> */}
            <NavLink
              id="dashboard-nav-link"
              replace={'#/dashboard' === window.location.hash}
              activeClassName="active"
              to="/dashboard"
              onClick={() => sendAnalyticEvent('dashboard')}
            >
              Dashboard
            </NavLink>
          </div>
        </li>
        }

        <RestrictFrom
          roles={[
            PERMISSION_REGISTERED_USER
          ]}
          required={false}
        >
          <li className={(window.location.hash.includes('#/vanpool')) ? "active" : ""}>
            <div className={(window.location.hash.includes('#/vanpool')) ? "inner-content active" : "inner-content"}>
              <Van className="icon side-navigation-only" />
              <NavLink
                id="vanpool-nav-link"
                replace={'#/vanpool' === window.location.hash}
                activeClassName="active"
                to="/vanpool"
                onClick={() => sendAnalyticEvent('vanpool')}
              >
                Vanpool
              </NavLink>
            </div>
          </li>
        </RestrictFrom>
        <RestrictTo
          roles={[
            PERMISSION_MANAGE_PAYMENT_METHODS,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]}
          required={false}
        >
          <li className={(window.location.hash.includes('#/payment')) ? "active" : ""}>
            <div className={(window.location.hash.includes('#/payment')) ? "inner-content active" : "inner-content"}>
              <Payment className="icon side-navigation-only" />
              <NavLink
                id="payment-nav-link"
                replace={'#/payment' === window.location.hash}
                activeClassName="active"
                to="/payment"
                onClick={() => sendAnalyticEvent('payment')}
              >
                {/* {isCoordinatorOrBillingViewer ? 'Account' : 'Payment'} */}
                Payment
              </NavLink>
            </div>
          </li>
        </RestrictTo>
        <RestrictFrom
        roles={[
          PERMISSION_VAN_STARTING_IN_FUTURE, PERMISSION_REGISTERED_USER
        ]}
        required={false}>
          <RestrictTo
            roles={[
              [
                PERMISSION_VANPOOL_STATUS_RENTAL,
                PERMISSION_VANPOOL_TRIP_RECORDING
              ],
              [PERMISSION_REGISTERED_USER, PERMISSION_TRIP_RECORDING],
              [
                PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
                PERMISSION_VANPOOL_TRIP_RECORDING
              ],
              [
                SUBSCRIPTION_TRIP_RECORDER,
                PERMISSION_VANPOOL_TRIP_RECORDING
              ]
            ]}
            required={false}
          >
            <li className={(window.location.hash.includes('#/trip-recording')) ? "active" : ""}>
              <div className={window.location.hash.includes('#/trip-recording') ? "inner-content active" : "inner-content"}>
                <Trip className="icon side-navigation-only" />
                <NavLink
                  id="trip-recording-nav-link"
                  replace={'#/trip-recording' === window.location.hash}
                  activeClassName="active"
                  to="/trip-recording"
                  onClick={() => sendAnalyticEvent('trip recording')}
                >
                  Trip Recording
                </NavLink>
              </div>
            </li>
          </RestrictTo>
        </RestrictFrom>
        <RestrictFrom
          roles={[
            PERMISSION_VAN_STARTING_IN_FUTURE,
            PERMISSION_NOVANPOOL,
            PERMISSION_TRADITIONAL_USER,
            PERMISSION_VANPOOL_STATUS_PRE_DELIVERY
          ]}
          required={false}
        >
          <li className={(window.location.hash.includes('#/driver-schedule')) ? "active" : ""}>
            <div className={(window.location.hash.includes('#/driver-schedule')) ? "inner-content active" : "inner-content"}>
              <Payment className="icon side-navigation-only" />
              <NavLink
                id="driver-schedule-nav-link"
                replace={'#/driver-schedule' === window.location.hash}
                activeClassName="active"
                to="/driver-schedule"
                onClick={() => sendAnalyticEvent('driver schedule')}
              >
                Driver Schedule
              </NavLink>
            </div>
          </li>
        </RestrictFrom>
        <hr className='navigation-hr side-navigation-only' />
      </ul>
    </div>
  );
}
