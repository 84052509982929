import React from 'react';
import config from '../appConfig';
import nonceUtility from './nonceUtility';
import analyticsService from '../shared/analytics/analytics-service';
import azureService from './azureService';

class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = { nonce: nonceUtility.generateAndStoreNonce() };
  }

  componentDidMount() {
    localStorage.setItem('passwordReset', 'start');
    analyticsService.analyticsEvent({ type: 'Password Reset Start' });

    const { policy } = this.props;
    const { nonce } = this.state;
    azureService.passwordReset(policy);

  }

  render() {
    return null;
  }
}

export default PasswordReset;
