import React from 'react';
import { Drag, Close, User } from '../../../shared/icons';
import { AddressInput } from '../../../shared/AddressInput';
import AddressUtility from '../../../shared/addressUtility';
import WaitTimeEditor from './WaitTimeEditor';

export default function({
  dragHandleProps,
  active,
  index,
  stop,
  existingAddresses,
  totalFilteredStops,
  autoFocusStop,
  removeStop,
  updateStop,
  updateWaitTime
}) {
  const lastStopIndex = totalFilteredStops - 1;

  return (
    <div className={`stop-editor ${active ? 'active' : ''}`}>
      <div className="stop-editor-container">
        <div {...dragHandleProps} className="drag-handle">
          <Drag />
        </div>
        <div className="editor">
          <AddressInput
            placeholder="Address (Required)"
            className="addr-edit"
            value={AddressUtility.format(stop)}
            autoFocus={stop.stopOrder === autoFocusStop}
            onChange={value => updateStop(index, value)}
            existingAddresses={existingAddresses}
          />
        </div>
        <div className="delete">
          {totalFilteredStops > 2 && stop.canDelete ? (
            <button onClick={() => removeStop(index)}>
              <Close />
            </button>
          ) : null}
        </div>
      </div>
      <div className="footer-container">
        <WaitTimeEditor
          waitTime={stop.waitTime}
          index={index}
          lastStopIndex={lastStopIndex}
          updateWaitTime={updateWaitTime}
        />
        <div className="participants">
          <div className="user-icon">
            <User />
          </div>
          <div>{stop.participantCount ? stop.participantCount : 0}</div>
        </div>
      </div>
    </div>
  );
}
