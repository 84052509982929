import React from 'react';
import { Warning } from '../../shared/icons';
import BackTo from './BackTo';
import Callable from '../../shared/Callable';

export default function ErrorConfirmation({
  edit,
  modify,
  recurring,
  payment
}) {
  return (
    <div className="review error-confirmation">
      {payment && payment.isDeclined ? (
        <DeclinedMessage payment={payment} />
      ) : (
        <SomethingWentWrongMessage />
      )}
      <div className="tools">
        <BackTo modify={modify} recurring={recurring} />
        <button className="button primary" onClick={edit}>
          Try Again
        </button>
      </div>
    </div>
  );
}

function DeclinedMessage({payment}) {
  return (
    <section>
      <h2 className="title">
        <Warning /> Transaction Incomplete!
      </h2>
      <p>
      <b>{payment.declinedMessage ? payment.declinedMessage : "We’re sorry, your payment was declined." }</b>
      </p>
    </section>
  );
}

function SomethingWentWrongMessage() {
  return (
    <section>
      <h2 className="title">
        <Warning /> Transaction Incomplete!
      </h2>
      <p>
        <b>Sorry for the inconvenience.</b>
      </p>
      <p>
        We are experiencing technical difficulties with your payment request.
        Please call <Callable phone="800-VAN-4-WORK" /> for assistance.
      </p>
      <p>Would you like to try again with a different payment method?</p>
    </section>
  );
}
