import React, { useEffect, useRef, useState } from 'react';
import Close from '../../_styles/images/close-icon-green.svg';
import { getUpdatedRoosterDays, weekdays_upper } from '../../join-commute/helpers/commonHelper';
import SelectDays from '../../profile/components/change-days/select-days/SelectDays';
import InfoCircle from '../../_styles/images/new-icons/driver-status/info_circle.svg';
import { isEmpty, xor } from 'lodash';
import { convertKeysToLowerCase } from '../../profile/helper';
import analyticsService from '../../shared/analytics/analytics-service';

export default function EditSchedulePreferencesPopup(props) {
  const node = useRef();
  const [selectedDays, updateSelectedDays] = useState(props.alreadyDrivingDays);
  const [saveDisabled, updateSaveDisabled] = useState(true);
  const [isFrequentDriver, updateIsFrequentDriver] = useState(props.isFrequentDriver);

  useEffect(() => {
    if (props && props.vanpoolInfo && props.vanpoolInfo.commuteDays) {
      updateSelectedDays(props.vanpoolInfo.commuteDays);
    }
  }, [props]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 9 ) {
        if(saveDisabled){
          const list = document.querySelectorAll('button.day-btn');
          const lastElement = list[list.length-1];
          if(lastElement && lastElement.isSameNode(e.target)){
            e.preventDefault();
            document.querySelector('#change-commute-days-popup .close').focus();
          }
        } else {
          const saveButton = document.querySelector('.save-pref');
          if(saveButton && saveButton.isSameNode(e.target)){
            e.preventDefault();
            document.querySelector('#change-commute-days-popup .close').focus();
          }
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [saveDisabled]);

  const onSave = () => {
    const autoRepeatSchedule = getUpdatedRoosterDays(selectedDays, true);
    const convertedObject= convertKeysToLowerCase(autoRepeatSchedule);
    analyticsService.analyticsProcessEvent({
      "event": "modal_click_generic",
      "context": {
        "event_action": "save preferences",
        "event_detail": {
            "modal": "edit driving schedule preferences"
        }  
      }
    })
    props.saveSchedulePreferences({
      autoRepeatSchedule: convertedObject,
      isFrequentDriver,
      isAutoRepeat: true,
    });
  };

  const handleOptionClick = (option) => {
    updateIsFrequentDriver(option);
  };

  function handleOptionKeyDown(e, option) {
    if (e.key === 'Enter' || e.key === ' ') {
      handleOptionClick(option);
    }
  }

  function handleCloseKeyDown(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      props.updateIsOpen(false);
    }
  }

  useEffect(() => {
    if (!isEmpty(xor(selectedDays, props.alreadyDrivingDays)) || isFrequentDriver !== props.isFrequentDriver) {
      updateSaveDisabled(false);
    } else {
      updateSaveDisabled(true);
    }
  }, [selectedDays, isFrequentDriver])

  useEffect(() => {
    analyticsService.analyticsProcessEvent(
      {
        "event": "modal_view_generic",
        "context": {
          "event_action": "edit driving schedule preferences"
         }
      })
  }, [])

  return (
    <div className="charges-popup show" id='change-commute-days-popup' aria-live='polite' role='alert' aria-modal='true' ref={node}>
      <div className='big-container edit-pref-popup'>
        <div className="card-operations-container cancel-subscription-popup">
          <div className="sp-header">
            <div className='header'>Edit Driving Schedule Preferences</div>
            <button
              role='button'
              className="close"
              onClick={() => props.updateIsOpen(false)}
              onKeyDown={handleCloseKeyDown}
              tabIndex={0}
              aria-label="Close edit driving schedule popup"
            >
              <img src={Close} alt='Close' />
            </button>
          </div>
          <div className='change-days-popup-container'>
            <div className='schedule' role="radiogroup" aria-labelledby='schedule'>
              <label id='schedule'>I want to drive</label>
              <div className='radio-section'>
                <div
                  tabIndex={0}
                  role='radio'
                  aria-checked={isFrequentDriver === true}
                  onClick={() => handleOptionClick(true)}
                  onKeyDown={(e) => handleOptionKeyDown(e, true)}
                  className={isFrequentDriver === true ? 'radio active' : 'radio'}
                  aria-labelledby='Frequently'
                >
                  <div className='round'></div>
                  <label id="Frequently" className='text'>Frequently</label>
                </div>
                <div
                  tabIndex={0}
                  role='radio'
                  aria-checked={isFrequentDriver === false}
                  onClick={() => handleOptionClick(false)}
                  onKeyDown={(e) => handleOptionKeyDown(e, false)}
                  className={isFrequentDriver === false ? 'radio active' : 'radio'}
                  aria-labelledby='Occasionally'
                >
                  <div className='round'></div>
                  <label id="Occasionally" className='text'>Occasionally</label>
                </div>
              </div>
            </div>

            <div className="commuteDays">
              <label htmlFor="selectDays">I prefer driving these days</label>
              <SelectDays
                runningDays={props.vanDays}
                value={selectedDays}
                onlyShowRunningDays={true}
                onChange={updateSelectedDays}
                availableDays={weekdays_upper}
                alreadySelected={props.alreadyDrivingDays}
                notAllowed={[]}
                isTitleRequired={false}
              />
            </div>
          </div>
        </div>
        <div className='blue-notes'>
          <div className='warning-icon'>
            <img className='info-circle' alt='information-icon' src={InfoCircle} aria-hidden='true' />
          </div>
          After saving, changes will take 7 days to take effect
        </div>
        <button className='save-preferences-button save-pref' disabled={saveDisabled} aria-disabled={saveDisabled} onClick={onSave}>Save preferences</button>
      </div>
    </div>
  );
}
