import { put, takeLatest, take, call } from 'redux-saga/effects';
import {
  REGISTRATION_JOIN_SITE,
  COMMUTE_PROFILE_SAVED,
  COMMUTE_PROFILE_SAVING,
  AUTHENTICATION_SUCCESS,
  LINK_VANPOOL,
  LINK_VANPOOL_SUCCESS,
  LINK_VANPOOL_FAILURE,
  SET_USER_AUTHORIZATION,
  SAVE_CONTACT_DETAILS,
  SAVE_CONTACT_DETAILS_SUCCESS,
  SAVE_CONTACT_DETAILS_FAILURE,
  AUTHORIZATION_REQUEST,
  DISPLAY_SUCCESS,
  DISPLAY_ERROR
} from '../redux/actionTypes';
import logger from '../shared/logger';
import UserService from '../shared/user-service';
import loginRedirectService from '../login/loginRedirectService';
import http from '../shared/http-service';
import config from '../appConfig';
import analyticsService from '../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(REGISTRATION_JOIN_SITE, joinSite);
  yield takeLatest(LINK_VANPOOL, linkVanpool);
  yield takeLatest(SAVE_CONTACT_DETAILS, saveContactDetails);
}

function* joinSite({ data }) {
  try {
    yield put({
      type: COMMUTE_PROFILE_SAVING,
      data: { ...data, refresh: false }
    });
    yield take(COMMUTE_PROFILE_SAVED);

    const profile = UserService.getUserProfile();
    profile.registrationComplete = true;
    profile.employer = data.employer;

    analyticsService.analyticsEvent({ type: 'Enrollment Submit' });
    yield put({ type: SET_USER_AUTHORIZATION, data: profile });
    yield put({
      type: AUTHENTICATION_SUCCESS,
      data: { redirectUrl: loginRedirectService.getRedirectUrl(profile) }
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* linkVanpool({ data }) {
  try {
    const res = yield call(() =>
      http.post(`${config.kongApiBaseUrl}/user/profile/vanpool/link`, data)
    );

    if (res.isJoinedToParticipant) {
      analyticsService.analyticsEvent({ type: 'Enrollment Submit' });
      yield put({ type: LINK_VANPOOL_SUCCESS, data: res });
    } else {
      yield put({ type: LINK_VANPOOL_FAILURE, data: res });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: LINK_VANPOOL_FAILURE, data: {} });
  }
}

function* saveContactDetails({ data }) {
  try {
    const res = yield call(() =>
      http.put(`${config.kongApiBaseUrl}/user/profile/summary`, data)
    );
      yield put({ type: SAVE_CONTACT_DETAILS_SUCCESS, data: res });
      yield put({
        type: DISPLAY_SUCCESS,
        data:
          'Thanks!  Your data has been saved.'
      });
      yield put({ type: AUTHORIZATION_REQUEST });
   
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: DISPLAY_ERROR,
      data: 'Unable to update details.'
    });
    yield put({ type: SAVE_CONTACT_DETAILS_FAILURE, data: { "success": false } });
  }
}