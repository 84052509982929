import React from 'react';
import { AngleDown } from '../../shared/icons';
import dateUtility from '../../shared/dateUtility';
import currencyFormatter from '../../shared/currencyFormatter';

export function FuelCardChargesHeading({ month }) {
  if (month) {
    return (
      <div className="etable-heading toggle monthly-charges">
        <div className="etable-row">
          <div className="etable-column">
            <div className="etable-head current-month">
              <AngleDown className="angle-down-button" />{' '}
              {`${dateUtility.monthNameFromIndex(month.activityMonth)} ${
                month.activityYear
              }`}{' '}
            </div>
            <div className="etable-head total">
              {currencyFormatter.formatUSD(month.monthTotalAmount)}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export function FuelCardChargesTable({ transactions }) {
  if (!transactions) {
    return <div />;
  }
  
  return (
    <div>
      <div className="etable-body fuel-card-charges-table">
        {transactions.length === 0 ? (
          <p className="note no-recent-activity">
            No Fuel Card Data Available
          </p>
        ) : (
          transactions.map((transaction, index) => (
            <div
              className="etable-row"
              key={`${transaction.authorizationCode}_${index}`}
            >
              <div className="etable-column">
                <div className="etable-cell spacer"></div>
                <div className="etable-cell fc-activity-date">
                  <div className="mobile-only-label"><label>Act. Date:&nbsp;</label><span>{transaction.activityDate}</span></div>
                </div>
                <div className="etable-cell merchant">
                  <div className="mobile-only-label"><label>Merchant:&nbsp;</label><span>{transaction.merchantName}</span></div>
                </div>
                <div className="etable-cell auth-num">
                <div className="mobile-only-label"><label>Auth #:&nbsp;</label><span>{transaction.authorizationCode}</span></div>
                </div>
                <div className="etable-cell gallons">
                  <div className="mobile-only-label"><label>Gallons:&nbsp;</label><span>{`${transaction.numberOfGallons ? transaction.numberOfGallons + " " + "gal" : "N/A"}`}</span></div>
                </div>
                <div className="etable-cell amount right">
                  <div className="mobile-only-label"><label>Amount:&nbsp;</label><span>{currencyFormatter.formatUSD(transaction.amount)}</span></div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}
