import * as actionTypes from '../redux/actionTypes';

const initialState = [];

const importantAlerts = ['NTD', 'SBY'];

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ALERTS_LOADED:
      return action.data;
    case actionTypes.MARK_ALL_ALERTS_READ:
      return state.map(alert => ({
        ...alert,
        isRead: !importantAlerts.includes(alert.alertTypeCode)
      }));
    case actionTypes.DELETE_ALERT:
      return state.filter(alert => alert.alertId !== action.data.alertId);
    default:
      return state;
  }
};
