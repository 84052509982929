import * as actionTypes from '../../redux/actionTypes';
import config from '../../appConfig';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
export default function* () {
  yield takeLatest(actionTypes.SUBSCRIPTION_SUMMARY_LOADING, loadSubscriptionSummary);
}

function* loadSubscriptionSummary() {
  try {
    let response = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/user/participant/monthly/subscription/summary`)
    );
    yield put({ type: actionTypes.SUBSCRIPTION_SUMMARY_LOADED, data: response, });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.SUBSCRIPTION_SUMMARY_LOADED_ERROR,
      data: { error: true },
      errorString: ex,
    });
  }
}
