import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import analyticsService from '../../../../shared/analytics/analytics-service';
import { getFullDayString } from '../../../helpers/vanHelpers';
import { getTimeObjectFromString } from '../../../helpers/commonHelper';
import ImageNotAvailable from '../../../../_styles/images/ImageNotAvailable.svg';
import dateUtility from '../../../../shared/dateUtility';

function SecondScreen(props) {
  const ref = useRef(null);
  useEffect(() => {
    ref?.current?.focus();
    analyticsService.getPageName({
      'pagename': "customer onboarding/your first day",
      'process': "customer onboarding"
    });
  }, []);

  function getTime(time) {
    if (time) {
      const timeObj = getTimeObjectFromString(time);
      return `${timeObj.hour}:${timeObj.minutes} ${timeObj.day}`
    }
  }

  return (
    (props.selectedVan && props.selectedVan != {}) && <div className='screen-container second-screen' ref={ref}>
      {props.isPredelivery ? <div className='heading'>
        Commute Details
      </div>
      :
      <div className='heading'>
        Your first day
      </div>}
      <div className='content-container'>
        <div className='title'>
          <div className="left" role='group'>
            <div className="van-class">{props.selectedVan.vanpoolName}</div>
            <div className="van-model">{props.selectedVan.ymms}</div>
          </div>
          <div className="right" role='group'>
            <div className="label">License Plate</div>
            <div className="number">{props.selectedVan.vehicleRegistrationNumber ? props.selectedVan.vehicleRegistrationNumber : (props.isPredelivery ? <div className='coming-soon'>Coming Soon!</div> : <div className='coming-soon'></div>)}</div>
          </div>
        </div>
        <div className="vanpool-summary-image">
          <div className="van-Image">
            <div className={`${props.selectedVan.isPredelivery ? 'car-image-opacity' : 'car-image'}`}>
              <img src={props.selectedVan.carClassImage}
                alt={props.selectedVan.vehicleClass}
                onError={e => {
                  e.currentTarget.src = ImageNotAvailable;
                  e.currentTarget.className = "image-not-found";
                }} />
            </div>
          </div>
        </div>
        <div className='content desktop' role='group'>
          {props.isPredelivery &&
            <div className='row'>
              <div className='label'>Date</div>
              <div className='value predelivery-date'>Your vanpool is still forming. More details about your start date will be communicated soon.</div>
            </div>}
          <div className='row'>
            <div className='label'>Pickup time</div>
            <div className='value'>{props.selectedVan.matchedOriginStop && props.selectedVan.matchedOriginStop.departureTimestamp && dateUtility.parseTime(props.selectedVan.matchedOriginStop.departureTimestamp)}</div>
          </div>
          {!props.isPredelivery && <div className='row'>
            <div className='label'>Date</div>
            <div className='value'>{getFullDayString(props.userData.startDate)}</div>
          </div>}
          <div className='row'>
            <div className='label'>Pickup address</div>
            <div className='value'>{props?.summaryData?.originAddress}</div>
          </div>
        </div>
      </div>
      <div className='content-container mobile'>
        <div className='content'>
          {props.isPredelivery &&
            <div className='row'>
              <div className='label'>Date</div>
              <div className='value predelivery-date'>Your vanpool is still forming. More details about your start date will be communicated soon.</div>
            </div>}
          <div className='row'>
            <div className='label'>Pickup time</div>
            <div className='value'>{props.selectedVan.matchedOriginStop && props.selectedVan.matchedOriginStop.departureTimestamp && dateUtility.parseTime(props.selectedVan.matchedOriginStop.departureTimestamp)}</div>
          </div>
          {!props.isPredelivery && <div className='row'>
            <div className='label'>Date</div>
            <div className='value'>{getFullDayString(props.userData.startDate)}</div>
          </div>}
          <div className='row'>
            <div className='label'>Pickup address</div>
            <div className='value'>{props?.summaryData?.originAddress}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    userData: state.joinCommute.userCommuteData,
    selectedVan: state.joinCommute.selectedVan,
    summaryData: state.joinCommute.userCommuteData.summaryData
  }
}

export default connect(mapStateToProps, null)(SecondScreen);