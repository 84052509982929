import React from 'react';
import { NoComment, Comment } from '../../shared/icons';
import currencyFormatter from '../../shared/currencyFormatter';
import dateUtility from '../../shared/dateUtility';

export default class RequestedPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    const { requestedPayment, index } = this.props;
    const { expanded } = this.state;
    return (
      <div>
        <div className="etable-row" id={`requested-payment-${index}`}>
          <div className="etable-column">
            <div className="date etable-cell">
              {dateUtility.parseDateAsMonthDay(requestedPayment.sentDate)}
              <button
                className={`note-toggle ${expanded ? 'chosen' : ''}`}
                onClick={() => this.toggleExpanded()}
                disabled={
                  !requestedPayment.participantNote &&
                  !requestedPayment.vanpoolNote
                }
              >
                {expanded ? <Comment /> : <NoComment />}
              </button>
            </div>
          </div>
          <div className="etable-cell right due">
            {currencyFormatter.formatUSD(requestedPayment.amount)}
          </div>
        </div>
        {expanded ? (
          <div className="notes-section">
            {requestedPayment.participantNote ? (
              <div className="participant-section">
                <div className="heading">To You:</div>
                <div className="note">{requestedPayment.participantNote}</div>
              </div>
            ) : null}
            {requestedPayment.vanpoolNote ? (
              <div className="vanpool-section">
                <div className="heading">To Vanpool:</div>
                <div className="note">{requestedPayment.vanpoolNote}</div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
