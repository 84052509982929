import Close from '../../../_styles/images/close-icon-green.svg';
import Firestone from '../../../_styles/images/new-icons/Firestone.png';
import Pepboys from '../../../_styles/images/new-icons/Pepboys.png';
import TickIcon from '../../../_styles/images/tick-badge.svg';
import { useState, useEffect } from 'react';
import analyticsService from '../../../shared/analytics/analytics-service';
import Callable from '../../../shared/Callable';
import TickIconContainer from '../../../shared/components/tick-icon-container/TickIconContainer';

export default function SPPopup(props) {
  const [selectedStore, updateSelectedStore] = useState(null);

  useEffect(() => {
    analyticsService.analyticsProcessEvent({
      "event": "primary_service_provider_modal_view",
      "context": {
        "event_action": "primary service provider modal"
        }
    });
  },[]);

  const storeSelected = id => {
    analyticsService.analyticsProcessEvent({
      "event": "primary_service_provider_modal_click",
      "context": {
        "event_action": id ? "pepboys": "firestone",
        "event_detail": {
            "modal": "primary service provider modal"
            }
        }
    })
    if (id == 0) {
      updateSelectedStore(0);
    } else {
      updateSelectedStore(1);
    }
  };

  const providerLiks = [
    {
      storeId: 0,
      storeLink:
        'https://www.firestonecompleteautocare.com/locate/display-map/ '
    },
    {
      storeId: 1,
      storeLink: 'https://www.pepboys.com/stores'
    }
  ];

  const continueClicked = () => {
    analyticsService.analyticsProcessEvent({
      "event": "primary_service_provider_modal_click",
      "context": {
        "event_action": "continue",
        "event_detail": {
            "modal": "primary service provider modal"
            }
        }
    })
    window.open(providerLiks[selectedStore].storeLink, '_blank');
  };

  return (
    <div className="charges-popup show">
      <div className="card-operations-container service-provider-popup">
        <div className="sp-header">
          <div className="sp-heading">Select a Primary Service Provider</div>
          <img className="close" src={Close} onClick={props.close} />
        </div>
        <div className="pre-text">If you’re unable to find a nearby service provider please call <Callable className="greentext" phone="800 VAN-4-WORK" /></div>
        <div className="service-provider-popup-content">
          <div className="sp-box">
            <div
              className={
                'sp-brand' + ' ' + `${selectedStore == 0 ? 'selected' : ''}`
              }
              onClick={() => storeSelected(0)}
            >
              {selectedStore == 0 && (
                <TickIconContainer src={TickIcon}/>
              )}
              <img className="storeImage-firestone" src={Firestone}></img>
            </div>
          </div>
          <div className="sp-box">
            <div
              className={
                'sp-brand' + ' ' + `${selectedStore == 1 ? 'selected' : ''}`
              }
              onClick={() => storeSelected(1)}
            >
              {selectedStore == 1 && (
                <TickIconContainer src={TickIcon}/>
              )}
              <img className="storeImage-pepboys" src={Pepboys}></img>
            </div>
          </div>
        </div>
        <div className='title-container'>
          <div className='title'>Firestone</div>
          <div className='title'>Pepboys</div>
        </div>
        <div className="button-container">
          <button
            disabled={selectedStore == null}
            className='continue'
            onClick={() => continueClicked()}
          >
            continue
          </button>
        </div>
      </div>
    </div>
  );
}