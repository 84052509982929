import React, { Component } from 'react';
import { User } from '../shared/icons';
import StandardLineGraph from './StandardLineGraph';
import StandardBarChart from './StandardBarChart';
import { gridFormat, GRID_TYPE_PERCENTAGE } from './chart-service';
import Legend from './Legend';

const DATA_TYPE_PARTICIPANTS = 'participants';
const DATA_TYPE_VEHICLES = 'vehicles';
const DATA_TYPE_OCCUPANCY = 'occupancy';

export default function VanpoolChart(props) {
  const { isQuarter, timePeriod } = props;

  return (
    <div className="participation-container">
      <div className="top-bar">
        <div className="label">
          <User /> Participation
        </div>
        {!isQuarter ? <div className="selected-dates">{timePeriod}</div> : null}
      </div>
      {isQuarter ? <QuarterlyChart {...props} /> : <MonthlyCharts {...props} />}
    </div>
  );
}

class QuarterlyChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      init: false,
      selectedGridDisplay: 'participants',
      legend: null
    };
  }

  componentWillReceiveProps(newProps) {
    if (!this.state.init) {
      this.setState({
        init: true,
        selectedGridDisplay: DATA_TYPE_PARTICIPANTS,
        legend: newProps.participants
      });
    }
  }

  componentDidMount() {
    this.setState({ legend: this.props.participants });
  }

  render() {
    const { participants, vehicles, occupancy } = this.props;
    const { selectedGridDisplay, legend } = this.state;
    return (
      <div>
        <div className="control-section">
          <div className="grid-navigation">
            <button
              className={
                selectedGridDisplay === DATA_TYPE_PARTICIPANTS ? 'active' : null
              }
              onClick={() => {
                this.setState({
                  selectedGridDisplay: DATA_TYPE_PARTICIPANTS,
                  legend: participants
                });
              }}
            >
              Participants
            </button>
            <button
              className={
                selectedGridDisplay === DATA_TYPE_VEHICLES ? 'active' : null
              }
              onClick={() => {
                this.setState({
                  selectedGridDisplay: DATA_TYPE_VEHICLES,
                  legend: vehicles
                });
              }}
            >
              Vehicles
            </button>
            <button
              className={
                selectedGridDisplay === DATA_TYPE_OCCUPANCY ? 'active' : null
              }
              onClick={() => {
                this.setState({
                  selectedGridDisplay: DATA_TYPE_OCCUPANCY,
                  legend: occupancy
                });
              }}
            >
              Occupancy
            </button>
          </div>
          <Legend gridData={legend} />
        </div>
        <div className="content">
          {legend != null ? (
            <ChartAnalysisLabel data={legend.datasets} />
          ) : null}
          {selectedGridDisplay === DATA_TYPE_PARTICIPANTS ? (
            <StandardLineGraph gridData={participants} useCustomTooltip />
          ) : null}
          {selectedGridDisplay === DATA_TYPE_VEHICLES ? (
            <StandardLineGraph gridData={vehicles} useCustomTooltip />
          ) : null}
          {selectedGridDisplay === DATA_TYPE_OCCUPANCY ? (
            <StandardLineGraph
              gridData={occupancy}
              displayType={GRID_TYPE_PERCENTAGE}
              useCustomTooltip
            />
          ) : null}
        </div>
      </div>
    );
  }
}

class MonthlyCharts extends Component {
  render() {
    const { participants, vehicles, occupancy } = this.props;
    const legendData = Object.keys(gridFormat).reduce((prev, curr) => {
      prev.push({ label: curr, color: gridFormat[curr] });
      return prev;
    }, []);

    return (
      <div className="month-charts-container">
        <div className="legend control-section">
          <Legend legendData={legendData} />
        </div>
        <div className="charts">
          <div className="bar-chart">
            <ChartAnalysisLabel data={participants.datasets} />
            <StandardBarChart gridData={participants} height={400} />
            <div className="label">PARTICIPANTS</div>
          </div>
          <div className="bar-chart">
            <ChartAnalysisLabel data={vehicles.datasets} />
            <StandardBarChart gridData={vehicles} height={400} />
            <div className="label">VEHICLES</div>
          </div>
          <div className="bar-chart">
            <ChartAnalysisLabel data={occupancy.datasets} />
            <StandardBarChart
              gridData={occupancy}
              displayType={GRID_TYPE_PERCENTAGE}
              height={400}
            />
            <div className="label">OCCUPANCY</div>
          </div>
        </div>
      </div>
    );
  }
}

function ChartAnalysisLabel({ data }) {
  const isEmptyDataset = datasets => datasets.length === 0;
  return isEmptyDataset(data) ? (
    <div className="no-bar-data">No data for selected time period</div>
  ) : null;
}
