import React from 'react';
import { Pencil, Almost } from '../../shared/icons';
import OneTimeDetails from './OneTimeDetails';
import RecurringDetails from './RecurringDetails';

export default function PaymentReview(props) {
  const { payment, edit, recurring } = props;
  const method = payment.selectedCard;

  return (
    <div className="review">
      <section className="prompt section">
        <h2 className="title">
          <Almost /> Almost There
        </h2>
        <p className="description">
          Please review your payment details
          {recurring
            ? `. Your card will be charged on the ${payment.selectedCard.every} of every month.`
            : ''}
        </p>
        <div
          tabIndex="0"
          onClick={edit}
          onKeyPress={e => e.key === 'Enter' && edit()}
          className="edit"
        >
          <Pencil /> Edit Payment
        </div>
      </section>
      {recurring ? (
        <RecurringDetails
          className="review-recurring-details"
          {...props}
          method={method}
        />
      ) : (
        <OneTimeDetails
          className="review-one-time-details"
          {...props}
          method={method}
        />
      )}
    </div>
  );
}
