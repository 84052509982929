import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  addOns: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VANPOOL_ADDONS_LOADED:
      return { ...state, ...initialState, addOns: action.data, error: false };
    case actionTypes.VANPOOL_ADDONS_ERROR:
      return { ...state, ...initialState, error: true };
    default:
      return state;
  }
};
