import React from 'react';
import ConfirmationPopup from '../shared/popup/ConfirmationPopup';

export default function({ cancel, confirm, paymentName, active }) {
  const selectedPayment = `Would you like to stop all future scheduled payments for '${paymentName}'?`;

  return (
    <ConfirmationPopup
      title="Stop Recurring Payment"
      message={selectedPayment}
      cancelMessage="Dismiss"
      confirmMessage="Stop"
      active={active}
      cancel={() => cancel()}
      confirm={() => confirm()}
    />
  );
}
