import { useEffect, useState } from 'react';
import NewUserBackground from '../../../_styles/images/new-icons/download-app/newUserBackground.png';
import VanpoolForming from '../../../_styles/images/new-icons/download-app/vanpoolForming.png';
import HeroBanner from '../../../_styles/images/HeroBanner.png';
import HeroBanner_mobile from '../../../_styles/images/HeroBanner_mobile.png';
import HeroBanner_tablet from '../../../_styles/images/HeroBanner_tablet.png';

export default function DashboardBackground(props) {
    const { name, image, hasMap, startDay, isInVanpool, isVanForming, isRecentLeadSubmitted } = props;
    
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const getBannerImage = () => {
        if (!isInVanpool) {
            return NewUserBackground;
        } else if (windowWidth < 768) {
            return HeroBanner_mobile;
        } else if (windowWidth >= 768 && windowWidth < 930) {
            return HeroBanner_tablet;
        } else {
            return HeroBanner;
        }
    };

    return (
        <div className='top-dashboard-new' role='group'>
            <div className="dashboard-background">
                <img src={getBannerImage()} aria-hidden='true' alt="dashboard background" />
            </div>
            {!hasMap && (
                <div className='green-strip'>
                    <div className='left'>
                        <div className='header'>Welcome, {name}<span aria-hidden='true'>!</span></div>
                        {startDay && <div className='text'>Your Start Date is {startDay}</div>}
                        {isRecentLeadSubmitted && !isInVanpool && <div className='new-user-text'>We received your request to join a vanpool</div>}
                        {!isInVanpool && !isRecentLeadSubmitted && <div className='new-user-text'>It Looks Like You're Not Part of a Vanpool</div>}
                        {isVanForming && <div className='new-user-text'>Your Vanpool Is Now Forming!</div>}
                    </div>
                    {image && (
                        <div className='right'>
                            <img src={image} aria-hidden='true' alt="user specific" />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
