import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import DateUtility from '../../shared/dateUtility';
import logger from '../../shared/logger';
import config from '../../appConfig';

export default function*() {
  yield takeLatest(
    actionTypes.SCHEDULED_PAYMENTS_LOADING,
    loadScheduledPayments
  );
  yield takeLatest(
    actionTypes.SCHEDULED_PAYMENTS_DELETING,
    deleteScheduledPayments
  );
}

function* loadScheduledPayments() {
  try {
    const scheduledPayments = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/participant/profile/payments/recurring`)
    );
    let viewFormat = {
      scheduled: scheduledPayments.recurringPayments.map(mapScheduledPayment)
    };

    yield put({
      type: actionTypes.SCHEDULED_PAYMENTS_LOADED,
      data: viewFormat
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.SCHEDULED_PAYMENTS_LOADED,
      data: { error: true }
    });
  }
}

function* deleteScheduledPayments({ data }) {
  const success = payment =>
    `Recurring payment '${payment.name}' has been successfully stopped.`;
  const failure = payment =>
    `Recurring payment '${payment.name}' cannot be removed at this time, please call 1-800-VAN4WORK.`;
  const id = data.id;

  try {
    yield call(() =>
      Http.delete(
        `${config.kongApiBaseUrl}/participant/profile/payment/recurring/${id}`
      )
    );

    yield put({ type: actionTypes.SCHEDULED_PAYMENTS_LOADING });
    yield put({ type: actionTypes.DISPLAY_SUCCESS, data: success(data) });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.DISPLAY_ERROR, data: failure(data) });
  }
}

////////

function mapScheduledPayment(payment) {
  return {
    id: payment.recurringPaymentId,
    name: payment.paymentName,
    amount: payment.paymentAmount,
    month: DateUtility.parseDateAsFullMonth(payment.nextRecurringChargeDate),
    next: DateUtility.parseDateAsMonthDayYear(payment.nextRecurringChargeDate),
    every: DateUtility.parseDayAsOrdinal(payment.nextRecurringChargeDate),
    brand: payment.cardBrand,
    account: '*' + payment.partialAccountNumber,
    exp: payment.expirationMMYY.replace(/^\d\d/, '$&/')
  };
}
