import React from 'react';
import chartService from './chart-service';

export default function Legend({ gridData, legendData }) {
  if (!legendData && !gridData) {
    return null;
  }
  legendData = legendData || chartService.generateLegend(gridData);
  return (
    <div className="current-legend">
      {legendData.map((item, index) => (
        <div className="legend" key={index}>
          <div className="legend-circle" style={{ borderColor: item.color }}>
            <img alt="" />
          </div>
          <span className="legend-label">{item.label}</span>
        </div>
      ))}
    </div>
  );
}
