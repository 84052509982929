import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import {
  MonthlyChargesHeading,
  MonthlyChargesTable
} from './monthly-charges/MonthlyCharges';
import AccountSummaryHeader from './AccountSummaryHeader';
import Questions from '../shared/Questions';

class AccountSummaryPresentation extends Component {
  componentDidMount() {
    this.props.init(this.props.accountSummaryLoaded);
  }

  render() {
    if (this.props.error) {
      return <div />;
    } else {
      return (
        <div className="account-summary">
          <AccountSummaryHeader />
          <div className="etable">
            <div className="etable-body header-labels">
              <div className="etable-row">
                <div className="etable-header service-month">Service Month</div>
                <div className="etable-header invoice-date">Invoice Date</div>
                <div className="etable-header activity-date">Activity Date</div>
                <div className="etable-header description">Description</div>
                <div className="etable-header right">Amount</div>
              </div>
            </div>
            {this.props.summary.monthlyAccountSummary.length > 0 ? (
              this.props.summary.monthlyAccountSummary.map((month, index) => {
                const monthlyChargesHeading = (
                  <MonthlyChargesHeading monthsTransactions={month} />
                );
                return (
                  <Collapsible
                    trigger={monthlyChargesHeading}
                    tabIndex={index}
                    key={month.item}
                    open={index === 0}
                  >
                    <MonthlyChargesTable monthsTransactions={month} />
                  </Collapsible>
                );
              })
            ) : (
              <p className="no-monthly-data">No monthly data available</p>
            )}
          </div>
          <Questions/>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    summary: state.accountSummary.summary,
    error: state.accountSummary.summaryError,
    vanpoolInfo: state.vanpoolInfo,
    accountSummaryLoaded: state.accountSummary.accountSummaryLoaded,
    lastUpdatedDateTime: state.dashboardPaymentSummary.lastUpdatedDateTime
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init(accountSummaryLoaded) {
      if (!accountSummaryLoaded) {
        dispatch({ type: actionTypes.ACCOUNT_SUMMARY_LOADING });
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSummaryPresentation);
