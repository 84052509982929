import config from '../appConfig';
import {
  PublicClientApplication,
  InteractionRequiredAuthError
} from '@azure/msal-browser';
import * as actionTypes from '../redux/actionTypes';
import { store } from '../redux/store';
import UserService from '../shared/user-service';
import analyticsService from '../shared/analytics/analytics-service';
import matchCookieService from '../dashboard/registered-user/matchCookieService';
import MapService from '../shared/map-service';

const azureService = {};
const signIn = 'b2c_1a_signin_web_public';
const signUp = 'b2c_1a_signup_web_public';
//const signIn = 'b2c_1a_signup_signin';  // this was added to test XQA policies and testing tradional features
const letsRideTogether = 'b2c_1a_signup_ridetogether_web_public';
const privateVanpools = 'B2C_1A_MAGIC_LINK_REGISTRATION';
const passwordChange = 'b2c_1a_PasswordChange';
const emailChange = 'b2c_1a_email_update';
const passwordReset = 'b2c_1a_PasswordReset';
const phonePasswordReset = 'b2c_1a_PhonePasswordReset';
const privateVanpoolMagicLInk = 'b2c_1a_magic_link'; // B2C_1A_MAGIC_LINK';
const rosParticipantInvite =  'b2c_1a_vanpool_signup_web';
const traditionalPrivateVanpools = 'b2c_1a_traditional_signup';
const defaultsScopes = ['read', 'offline_access', config.readScope];

azureService.msalInstance = null;
azureService.account = null;
azureService.accessToken = null;
azureService.msalConfig = {
  auth: {
    clientId: config.clientId,
    authority: `${config.azureAuthorityUrl}/${signIn}`,
    knownAuthorities: [
      `${config.azureAuthorityUrl}/${signIn}`,
      `${config.azureAuthorityUrl}/${signUp}`,
      `${config.azureAuthorityUrl}/${passwordChange}`,
      `${config.azureAuthorityUrl}/${emailChange}`,
      `${config.azureAuthorityUrl}/${passwordReset}`,
      `${config.azureAuthorityUrl}/${phonePasswordReset}`,
      `${config.azureAuthorityUrl}/${privateVanpools}`,
      `${config.azureAuthorityUrl}/${privateVanpoolMagicLInk}`,
      `${config.azureAuthorityUrl}/${rosParticipantInvite}`
    ],
    redirectUri: window.location.origin,
    scope: ['read', 'offline_access', config.readScope],
    prompt: 'login',
    postLogoutRedirectUri: config.brochureUrl,
    navigateToLoginRequestUrl:false
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true, 
}
};

azureService.createAzureInstance = async () => {
  azureService.msalInstance = new PublicClientApplication(
    azureService.msalConfig
  );
  try {
  var authResult =  await azureService.msalInstance.handleRedirectPromise();
      azureService.HandleRedirectResponse(authResult);
  } catch (error) {
    if (error.errorMessage.indexOf('AADB2C90118') >= 0) {
      document.cookie = "forgotPasswordStart=true;domain="+window.location.hostname+";path=/";
      azureService.passwordReset(passwordReset);
    } else if (error.errorMessage.indexOf('AADB2C90091') >= 0) {
      document.cookie = "forgotPasswordStart=true;expires = Thu, 01 Jan 1970 00:00:00 GMT;domain="+window.location.hostname+";path=/";
      azureService.signIn();
    } else if (error.errorMessage.indexOf('AADB2C99001') >= 0) {
      return false;
    }else if(error.errorMessage.indexOf('AADB2C90080') >= 0) {
      var accounts = await azureService.msalInstance.getAllAccounts();
      var account = accounts.filter(account => account.homeAccountId.indexOf(`${rosParticipantInvite}`) >= 0 );
      azureService.accquireToken(account);
    } else {
      window.location.href = '/#/login?error=LOGIN_ERROR';
    }
    console.error(error);
  }
};

azureService.accquireToken = async(account) => {
  var request = {
    scopes: [config.readScope],
    account: account ? account : azureService.account
  };

  try {
    var response  =  await azureService.msalInstance.acquireTokenSilent(request)
    if (!response.accessToken || response.accessToken === '') {
      throw new InteractionRequiredAuthError();
    }
        azureService.HandleRedirectResponse(response);
  } catch(error) {
    console.log(error)
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return azureService.msalInstance.acquireTokenRedirect(request);
      }
  }
};

azureService.accquireTokenRedirect = async(account) => {
  var request = {
    scopes: [config.readScope],
    account: account ? account : azureService.account
  };
  try {
    var response  =  await azureService.msalInstance.acquireTokenRedirect(request)
    if (!response.accessToken || response.accessToken === '') {
      throw new InteractionRequiredAuthError();
    }
        azureService.HandleRedirectResponse(response);
  } catch(error) {
    console.log(error)
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return azureService.msalInstance.acquireTokenRedirect(request);
      }
  }
};

azureService.HandleRedirectResponse = async authResult => {
  if (authResult !== null) {
    azureService.account = authResult.account;
    azureService.msalInstance.setActiveAccount(authResult.account)
    if (authResult.accessToken) {
      var accessTokenDetails = JSON.parse(atob(authResult.accessToken.split('.')[1]));
      var profileId = accessTokenDetails.profileId;
      UserService.setAccount(azureService.account, profileId);
      azureService.accessToken = authResult.accessToken;
      UserService.setAccessToken(true);
      var link = localStorage.getItem('mobileDeepLink');
      if(link){
        localStorage.removeItem('mobileDeepLink');
      } else {
        localStorage.setItem('login', 'success');
      }
      if(localStorage.getItem('passwordReset')) {
        localStorage.setItem('passwordReset', 'success');
      }
      var profile = UserService.getUserProfile();
      var inviteId = localStorage.getItem('inviteId');  //need to get invite id from azure or local storage
      if (!profile) {
        var isTraditionalSignup = authResult.authority.indexOf(`${letsRideTogether}`) >= 0 ;
        var privateVanpoolSearch = authResult.authority.indexOf(`${privateVanpoolMagicLInk}`) >= 0 ;
        store.dispatch({ type: actionTypes.AUTHORIZATION_REQUEST,userid:accessTokenDetails.userid ? accessTokenDetails.userid : "", isTraditional: isTraditionalSignup, privateVanpoolSearch: privateVanpoolSearch, inviteId:inviteId});
      }

    } else {
      var accounts = await azureService.msalInstance.getAllAccounts();
      var account = accounts.filter(account => account.homeAccountId.indexOf(`${signIn}`) >= 0 );
      if (account.length ) {
          azureService.accquireToken(account[0]);
      }else {
        var cookie = matchCookieService.getcookie("forgotPasswordStart");
        if(cookie) {
          analyticsService.analyticsEvent({ type: 'commutePasswordResetSuccess' });
          document.cookie = "forgotPasswordStart=true;expires = Thu, 01 Jan 1970 00:00:00 GMT;domain="+window.location.hostname+";path=/";
        }
        UserService.clearAuthentication();
        azureService.signOut();
      }
    }
  }
};
azureService.getAccessToken = async () => {
  var accounts = await azureService.msalInstance.getAllAccounts();
  var accountInfo = await azureService.getAccount(); //await azureService.msalInstance.getAllAccounts()[0];
  var account = accounts.filter(account => account.homeAccountId.indexOf(`${signIn}`) >= 0 ) 
  var request = {
    scopes: [config.readScope],
    account: account.length ? account[0] : accountInfo  // accountInfo //accountdetails
  };
  if(!request.account) {
    UserService.clearAuthentication();
    localStorage.setItem('path', window.location.hash)
    return azureService.msalInstance.acquireTokenRedirect(request);
  } 
  try {
    if(request.account){
      var response = await azureService.msalInstance.acquireTokenSilent(request);
      return response.accessToken;
    }
  } catch (error){
    var request = {
      scopes: [config.readScope],
    };
      console.log("error from get accessToken" + error)
      return azureService.msalInstance.acquireTokenRedirect(request);
  }

};

azureService.signIn = (guid) => {
  let loginRedirectRequestPayload = {
    authority: `${config.azureAuthorityUrl}/${signIn}`,
    scope: defaultsScopes,
    scopes: [config.readScope],
    prompt: 'login',
  };
  guid ? loginRedirectRequestPayload.extraQueryParameters = guid : null
  azureService.msalInstance.acquireTokenRedirect(loginRedirectRequestPayload);
};

azureService.signUp = (guid) => {
  let loginRedirectRequestPayload = {
    authority: `${config.azureAuthorityUrl}/${signUp}`,
    scope: defaultsScopes,
    scopes: [config.readScope]
  };
  guid ? loginRedirectRequestPayload.extraQueryParameters = {
    "userid": guid,
  } : null
 // azureService.msalInstance.config.auth.authority = `${config.azureAuthorityUrl}/${signUp}`;
  azureService.msalInstance.acquireTokenRedirect(loginRedirectRequestPayload);
};

azureService.signUpROSParticipant = (inviteId) => {
  let loginRedirectRequestPayload = {
    authority: `${config.azureAuthorityUrl}/${rosParticipantInvite}`,
    scope: defaultsScopes,
    scopes: [config.readScope]
  };
  //azureService.msalInstance.config.auth.authority = `${config.azureAuthorityUrl}/${rosParticipantInvite}`;
  azureService.msalInstance.acquireTokenRedirect(loginRedirectRequestPayload);
};


azureService.privateSignUp = (guid,idToken) => {
  let loginRedirectRequestPayload = {
    authority: `${config.azureAuthorityUrl}/${privateVanpools}`,
    scope: defaultsScopes,
    scopes: [config.readScope]
  };
  guid ? loginRedirectRequestPayload.extraQueryParameters = {
    "userid": guid,
    "id_token_hint": idToken
  } : null
  azureService.msalInstance.acquireTokenRedirect(loginRedirectRequestPayload);
};

azureService.privateVanpoolSignup = (idToken) => {
  let loginRedirectRequestPayload = {
    authority: `${config.azureAuthorityUrl}/${privateVanpools}`,
    scope: defaultsScopes,
    scopes: [config.readScope]
  };
  idToken ? loginRedirectRequestPayload.extraQueryParameters = {
    "id_token_hint": idToken
  } : null
  azureService.msalInstance.acquireTokenRedirect(loginRedirectRequestPayload);
};

azureService.TraditionalPrivateSignup = (guid,idToken) => {
  let loginRedirectRequestPayload = {
    authority: `${config.azureAuthorityUrl}/${traditionalPrivateVanpools}`,
    scope: defaultsScopes,
    scopes: [config.readScope]
  };
  guid ? loginRedirectRequestPayload.extraQueryParameters = {
    "userid": guid,
    "id_token_hint": idToken
  } : null
  azureService.msalInstance.acquireTokenRedirect(loginRedirectRequestPayload);
}

azureService.letsRideTogether = (guid) => {
  let loginRedirectRequestPayload = {
    authority: `${config.azureAuthorityUrl}/${letsRideTogether}`,
    scope: defaultsScopes,
    scopes: [config.readScope]
  };
  guid ? loginRedirectRequestPayload.extraQueryParameters = {
    "userid": guid,
  } : null
  azureService.msalInstance.acquireTokenRedirect(loginRedirectRequestPayload);
};

azureService.passwordChange = () => {
  let passwordChangePayload = {
    authority: `${config.azureAuthorityUrl}/${passwordChange}`,
    scope: defaultsScopes
  };
  localStorage.setItem('mobileDeepLink', '/profile/azure-settings');
  localStorage.setItem('passwordReset', 'start');
  azureService.msalInstance.acquireTokenRedirect(passwordChangePayload);
};

azureService.passwordReset = policy => {
  let passwordResetPayload = {
    authority: `${config.azureAuthorityUrl}/${policy}`,
    scope: defaultsScopes
  };
  azureService.msalInstance.acquireTokenRedirect(passwordResetPayload);
};

azureService.changeEmail = () => {
  let changeEmailPayload = {
    authority: `${config.azureAuthorityUrl}/${emailChange}`,
    scope: defaultsScopes
  };
  localStorage.setItem('mobileDeepLink', '/profile/azure-settings');
  azureService.msalInstance.acquireTokenRedirect(changeEmailPayload);
};

azureService.getAccount = async () => {
  if (azureService.account) {
    return azureService.account;
  } else {
    var account = await azureService.msalInstance.getAllAccounts()[0];
    return account;
  }
};

azureService.signOut = () => {
  const logoutRequest = {
   // authority: `${config.azureAuthorityUrl}/${signIn}`,
    account: azureService.account
  };

  //azureService.msalInstance.logout(logoutRequest);
  azureService.msalInstance.logoutRedirect();
  
};

export default azureService;
