import { connect } from 'react-redux';
import Cone from '../../../_styles/images/error/cone.svg';
import Trees from '../../../_styles/images/error/Trees.svg';
import Trees2 from '../../../_styles/images/error/Trees2.svg';
import Vans from '../../../_styles/images/error/Vans.svg';
import * as actionTypes from '../../../redux/actionTypes';
import { useEffect } from 'react';
import analyticsService from '../../../shared/analytics/analytics-service';
function ErrorScreen(props) {
  useEffect(() => {
    if (props && props.apiErrorOccurred) {
      const timeID = setTimeout(() => {
        props.clearError();
      }, 1000);
      return () => {
        clearTimeout(timeID)
      }
    }
  }, [props]);

  const goBackUntilPathChanges = () => {
    if (history.length > 1) {
      history.back();
      setTimeout(() => {
        if (location.hash === '#/error') {
          goBackUntilPathChanges();
        }
      }, 0);
    }
  };

  useEffect(() => {
    analyticsService.sendErrorPageLoadEvent();
    if (sessionStorage.getItem('reloaded')) {
      goBackUntilPathChanges();
    }
    sessionStorage.setItem('reloaded', 'true');
    return () => {
      sessionStorage.removeItem('reloaded');
    };
  }, []);

  return (
    <div className='error-screen-container'>
      <div className='error-screen'>
        <div className='content'>

          <div className='heading'>
            <h2>We ran into an error trying to load this data</h2>
          </div>
          <div className='comment'>
            Please try again
          </div>
          <div className='picture'>
            <div className='left'>
              <img src={Trees} alt="error" />
              <img src={Trees2} alt="error" />
            </div>
            <div className='right'>
              <img src={Vans} alt="error" />
              <img src={Cone} alt="error" />
            </div>
          </div>
        </div>
        <div className='line' />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    apiErrorOccurred: state.navigation.apiErrorOccurred
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearError() {
      dispatch({ type: actionTypes.RESET_API_ERROR });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorScreen);
