import {connect} from 'react-redux';
import {useEffect} from 'react';
import ThankYouTick from '../../_styles/images/thankyou-tick.svg'
import ThankYouCommute from '../../_styles/images/thankyou-commute.svg'
import config from '../../appConfig';
import analyticsService from '../../shared/analytics/analytics-service';
import { SET_REFRESH_DASHBOARD, UPDATE_USER_PROFILE_DATA } from '../../redux/actionTypes';


function LetRideThankYou(props) {

  useEffect(() => {
    props.updateProfileData();
  }, [])

  function goToDashboard() {
    props.setRefresh(true);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "go to dashboard"
      }
    });
    props.history.push("/dashboard");
  }

  return (
    <div className='thank-you-page'>
      <div className='thank-you-container'>
        <div className='content-container'>
          <div className='left-container'>
            <div className='heading-container'>
              <img className='tick' src={ThankYouTick} alt="tick" />
              <div className='heading'>Thank you!</div>
            </div>
            <div className='comment'>One of our representatives will contact you within 24 business hours to get your commute set up!</div>
            <div className='comment-two'>In the mean time feel free to update your profile picture or apply to be a driver</div>
            <button onClick={goToDashboard}>Go To Dashboard</button>
          </div>
          <div className='right-container'>
            <img className='commute-image' src={ThankYouCommute} alt="commute-image" />
          </div>
        </div>
        <div className='foot-privacy'>
        View our{' '}
        <a
          href={config.privacyPolicyLink}
          target="_blank"
          rel="noopener noreferrer"
          className='privacy'
        >
          Privacy Policy
        </a>
        {' '}and{' '}
        <a
          href={config.termsOfUseLink}
          target="_blank"
          rel="noopener noreferrer"
          className='terms'
        >
          Terms of Use{' '}
        </a>
      </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setRefresh(refresh) {
      dispatch({ type: SET_REFRESH_DASHBOARD, refresh });
    },
    updateProfileData(){
      dispatch({type: UPDATE_USER_PROFILE_DATA});
    }
  };
}



export default connect(null, mapDispatchToProps)(LetRideThankYou);