import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import Http from '../shared/http-service';
import logger from '../shared/logger';
import config from '../appConfig';
import analyticsService from '../shared/analytics/analytics-service';
import UserService from '../shared/user-service';

const DEFAULT_WAIT_TIME = 0;

export default function*() {
  yield takeLatest(actionTypes.ROUTE_LOADING, loadRoute);
  yield takeLatest(actionTypes.ROUTE_SAVING, saveRoute);
  yield takeLatest(actionTypes.PARTICIPANT_ROUTE_LOADING, loadParticipantRoute);
}

function* loadParticipantRoute() {
  try {
    const routeResponse = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/user/profile/commuteProfile`)
    );
    const mappedRouteResponse = {
      outboundRoutePoints: [
        {
          address: routeResponse.originAddress,
          latitude: routeResponse.originLatitude,
          longitude: routeResponse.originLongitude,
          stopTime: routeResponse.originDepartureTime,
          description: 'Home'
        },
        {
          address: routeResponse.destinationAddress,
          latitude: routeResponse.destinationLatitude,
          longitude: routeResponse.destinationLongitude,
          stopTime: routeResponse.destinationArrivalTime,
          description: routeResponse.employerName
        }
      ],
      inboundRoutePoints: [
        {
          address: routeResponse.destinationAddress,
          latitude: routeResponse.destinationLatitude,
          longitude: routeResponse.destinationLongitude,
          stopTime: routeResponse.destinationDepartureTime,
          description: routeResponse.employerName
        },
        {
          address: routeResponse.originAddress,
          latitude: routeResponse.originLatitude,
          longitude: routeResponse.originLongitude,
          stopTime: routeResponse.originArrivalTime,
          description: 'Home'
        }
      ]
    };
    yield put({
      type: actionTypes.PARTICIPANT_ROUTE_LOADED,
      data: mappedRouteResponse
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* loadRoute() {
  try {
    const user = UserService.getUserProfile();
    let routeResponse = yield call(() =>
    user && user.vanpoolId && Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/route`)
    );
    const state = yield select();
    const participants = state.roster.todaysParticipants;
    if(routeResponse){
      routeResponse.outboundRoutePoints = addIsDeleteFlag(
        routeResponse.outboundRoutePoints.map(calculateWaitTimes)
      );
      routeResponse.inboundRoutePoints = addIsDeleteFlag(
        routeResponse.inboundRoutePoints.map(calculateWaitTimes)
      );

      let inArr=[];
      let outArr=[];
      routeResponse.outboundRoutePoints.forEach((route, index) => {
        outArr.push(participants.filter(part => part.toWorkStart && part.toWorkStart.routePointId === route.routePointId));
        routeResponse.outboundRoutePoints[index].todaysParticipants = outArr[outArr.length - 1];
      });

      routeResponse.inboundRoutePoints.forEach((route, index) => {
        inArr.push(participants.filter(part => part.toHomeEnd && part.toHomeEnd.routePointId === route.routePointId));
        routeResponse.inboundRoutePoints[index].todaysParticipants = inArr[inArr.length - 1];
      });
      yield put({ type: actionTypes.ROUTE_LOADED, data: routeResponse });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.ROUTE_LOADED_ERROR, data: { error: true }, errorString: ex });
  }
}

function* saveRoute(action) {
  try {
    yield call(() =>
      Http.put(`${config.kongApiBaseUrl}/vanpools/vanpool/route`, action.data)
    );

    yield put({ type: actionTypes.ROUTE_LOADING });
    analyticsService.analyticsEvent({ type: 'Route Updated' });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.ROUTE_LOADED_ERROR, data: { error: true }, errorString: ex });
  }
}

///////

function addIsDeleteFlag(collection) {
  if (collection) {
    collection.forEach(route => {
      route.isDelete = false;
    });
  }
  return collection;
}

function calculateWaitTimes(point) {
  return {
    ...point,
    waitTime:
      !point.waitTime || point.waitTime < 0 ? DEFAULT_WAIT_TIME : point.waitTime
  };
}
