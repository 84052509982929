import * as actionTypes from '../redux/actionTypes';
import { call, all, put, takeLatest, take } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';
import MapService from '../shared/map-service';
import dateUtility from '../shared/dateUtility';
import NA_VALUE from '../shared/na';

export default function*() {
  yield takeLatest(actionTypes.COMMUTE_PROFILE_LOADING, loadCommuteProfile);
  yield takeLatest(actionTypes.COMMUTE_PROFILE_SAVING, saveCommuteProfile);
  yield takeLatest(
    actionTypes.TRANSPORTATION_MODES_LOADING,
    loadTransportationModes
  );
  yield takeLatest(actionTypes.PARTNER_WORKSITES_LOADING, loadWorksites);
  yield takeLatest(actionTypes.MARKETING_OPTIONS_LOADING, loadMarketingOptions);
  
}
const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;

function* loadCommuteProfile(action) {
  try {
    const modesUrl = `${commuteUrl}/transportation/modes`;

    let [commute, modes] = yield all([
      call(() => Http.get(commuteUrl)),
      call(() => Http.get.cache(modesUrl))
    ]);
    yield put({
      type: actionTypes.COMMUTE_PROFILE_LOADED,
      data: {
        commute: {
          employer: commute.employerName || '',
          employerId: commute.employerId,
          activeDays: commute.commuteDays || [],
          arriveAtWork: commute.destinationArrivalTime || NA_VALUE,
          departFromWork: commute.destinationDepartureTime || NA_VALUE,
          homeAddress: commute.originAddress || NA_VALUE,
          workAddress: commute.destinationAddress || NA_VALUE,
          worksiteId: commute.worksiteId || undefined,
          mode: commute.defaultTransportationModeId
            ? modes.modes.find(
                mode => mode.domainId === commute.defaultTransportationModeId
              ).label
            : NA_VALUE,
          arriveFlexible: commute.isWorkArriveTimeFlexible ? 10 : 0,
          departFlexible: commute.isWorkDepartTimeFlexible ? 10 : 0,
          isEditable: !!commute.isEditable
        },
        modes: modes.modes
      }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.COMMUTE_PROFILE_LOADED, data: {} });
    if(action && action.source && action.source === 'joinCommute'){
      action.history.push('/JoinCommute/error');
    }
  }
}

function* loadTransportationModes() {
  try {
    const modesUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile/transportation/modes`;

    const response = yield call(() => Http.get.cache(modesUrl));

    yield put({
      type: actionTypes.TRANSPORTATION_MODES_LOADED,
      data: response.modes
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.TRANSPORTATION_MODES_LOADED, data: [] });
  }
}

function* loadWorksites() {
  const worksiteUrl = `${config.kongApiBaseUrl}/user/profile/parentCompanyWorksites`;

  try {
    const response = yield call(() => Http.get.cache(worksiteUrl));

    yield put({
      type: actionTypes.PARTNER_WORKSITES_LOADED,
      data: response.worksites
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* loadMarketingOptions() {
  const marketingOptionsUrl = `${config.kongApiBaseUrl}/user/profile/marketingOptions`;

  try {
    const response = yield call(() => Http.get.cache(marketingOptionsUrl));
    yield put({
      type: actionTypes.MARKETING_OPTIONS_LOADED,
      data: response.marketingOptions
    });
  } catch (ex) {
    logger.log(ex);
  }
}

function* saveCommuteProfile({ data }) {
  try {
    const {
      employer,
      homeAddress,
      workAddress,
      activeDays,
      arriveAtWork,
      departFromWork,
      arriveFlexible,
      departFlexible,
      phone,
      referral,
      shouldContact,
      mode,
      worksiteId
    } = data;
    const modesUrl = `${commuteUrl}/transportation/modes`;

    const modes = yield call(() => Http.get.cache(modesUrl));

    const modeId = modes.modes.find(({ label }) => label === mode).domainId;

    const [home, work] = yield all([
      call(() => MapService.getAddressAndLocation(homeAddress)),
      call(() => MapService.getAddressAndLocation(workAddress))
    ]);

    yield put({ type: actionTypes.MAP_DIRECTIONS, data: [home, work] });
    const {
      data: [duration]
    } = yield take(actionTypes.MAP_DURATIONS);

    const originDepartureTime = dateUtility.shiftBySeconds(
      arriveAtWork,
      -duration
    );
    const originArrivalTime = dateUtility.shiftBySeconds(
      departFromWork,
      duration
    );

    const registration = {};

    if (phone) {
      registration.phone = phone;
      registration.referralChannel = referral;
      registration.isAllowNotifyCommuteMatch = shouldContact;
    }
    yield call(() =>
      Http.put(commuteUrl, {
        employerName: employer,
        defaultTransportationModeId: modeId,
        originAddress: home.address,
        originLatitude: home.latitude,
        originLongitude: home.longitude,
        destinationAddress: work.address,
        destinationLatitude: work.latitude,
        destinationLongitude: work.longitude,
        destinationDepartureTime: departFromWork,
        destinationArrivalTime: arriveAtWork,
        isWorkArriveTimeFlexible: arriveFlexible,
        isWorkDepartTimeFlexible: departFlexible,
        commuteDays: activeDays,
        worksiteId,
        originArrivalTime,
        originDepartureTime,
        employerId: null,
        ...registration
      })
    );

    yield put({ type: actionTypes.COMMUTE_PROFILE_SAVED });
    yield put({
      type: actionTypes.COMMUTE_PROFILE_LOADED,
      data: { commute: data }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.COMMUTE_PROFILE_ERROR});
  }

  yield put({ type: actionTypes.COMMUTE_PROFILE_LOADING });
}
