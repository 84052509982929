import React, { Component } from 'react';

const SCROLL_PADDING = 50;
const passive = { passive: true };

const windowProxy = {
  get scrollTop() {
    return window.pageYOffset;
  },
  set scrollTop(value) {
    window.scrollTo(0, value);
  },
  addEventListener: (...args) => window.addEventListener(...args),
  removeEventListener: (...args) => window.removeEventListener(...args),
  offsetTop: 115
};

function isNearTop(selector) {
  const child = document.querySelector(selector);

  if (!child) {
    return false;
  }

  const parent = windowProxy;
  const prev = child.previousSibling;

  if (child.offsetTop - parent.scrollTop < 0) {
    return false;
  }

  if (prev) {
    return prev.offsetTop - parent.scrollTop < 0;
  }

  return true;
}

export function scrollIntoView(selector) {
  let child;
  try {
    child = document.querySelector(selector);
  } catch (e) {}

  if (!child) return;

  const parent = windowProxy;
  const start = parent.scrollTop;
  const end = child.offsetTop - parent.offsetTop - SCROLL_PADDING;
  const stepCount = 80;
  const steps = [];
  let v = 0;
  let x = start;

  for (let i = 0; i < stepCount; i++) {
    const a =
      ((i < stepCount / 2 ? 1 : -1) * 4 * (end - start)) /
      (stepCount * stepCount);
    v += a;
    x += v;
    steps[i] = Math.round(x);
  }

  let step = 0;

  const interval = setInterval(() => {
    step++;
    if (step < steps.length) {
      parent.scrollTop = steps[step];
    } else {
      clearInterval(interval);
    }
  }, 4);
}

export default class ScrollLink extends Component {
  constructor(props) {
    super(props);
    this.state = { active: false };
  }

  componentDidMount() {
    this.watch(this.props.selector);
    this.setState({ active: isNearTop(this.props.selector) });
  }

  componentWillUnmount() {
    this.unwatch();
  }

  watch(selector) {
    const child = document.querySelector(selector);
    const parent = windowProxy;

    if (!child) return setTimeout(() => this.watch(selector), 10);

    const updateState = () => this.setState({ active: isNearTop(selector) });

    parent.addEventListener('scroll', updateState, passive);

    this.unwatch = () =>
      parent.removeEventListener('scroll', updateState, passive);
  }

  render() {
    const { selector, children } = this.props;
    const { active } = this.state;

    return (
      <div
        className={`scroll-link ${active ? 'active' : ''}`}
        onClick={() => scrollIntoView(selector)}
      >
        {children}
      </div>
    );
  }
}
