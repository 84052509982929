import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavigationMenu from '../navigation-menu/NavigationMenu';
import IsVisible from '../permissions/IsVisible';
import * as actionTypes from '../../redux/actionTypes';
import AggregateNav from '../navigation-menu/AggregateNav';
import { RestrictTo } from '../permissions/RestrictTo';
import { PERMISSION_ACCOUNT_DASHBOARD_USER } from '../permissions/permissions';
import SmartBanner from '../../dashboard/smart-banner/SmartBanner';

export class HeaderPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  isActive(value) {
    this.setState({ isOpen: value });
  }

  componentDidMount() {
    if(!this.props.profileLoaded){
      this.props.loadProfile();
    }
  }

  render() {
    const { hideNavigation } = this.props;
 
    return (
      <IsVisible permission={this.props.hasUser}>
        <SmartBanner />
        <header className={`global ${this.state.isOpen ? 'active' : null}`}>
          <NavigationMenu
            onActive={value => this.isActive(value)}
            disabled={this.props.disabled}
            hideNavigation={hideNavigation}
          />
        </header>

        <RestrictTo
          roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
          required={false}
        >
          <div className="aggregate-nav mobile-only">
            <AggregateNav />
          </div>
        </RestrictTo>
      </IsVisible>
    );
  }
}

function mapStateToProps(state) {
  return {
    hasUser: !!state.loginState.hasToken,
    profileLoaded: state.profile.isLoaded
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadProfile() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING})
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { pure: false }
)(HeaderPresentation);
