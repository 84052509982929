import { SET_USER_AUTHORIZATION, CLEAR_AUTHENTICATION } from './actionTypes';
import UserService from '../shared/user-service';

export default function(_, action) {
  switch (action.type) {
    case SET_USER_AUTHORIZATION:
      UserService.setAuthorization(action.data);
      return action.data;
    case CLEAR_AUTHENTICATION:
      return null;
    default:
      return UserService.getUserProfile() || null;
  }
}
