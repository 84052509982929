import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import config from '../../appConfig';

export default function*() {
  yield takeLatest(
    actionTypes.VEHICLE_SWITCH_REASONS_LOADING,
    loadSwitchReasons
  );
  yield takeLatest(actionTypes.VEHICLE_SWITCH_REQUEST, requestVehicleSwitch);
}

function* loadSwitchReasons() {
  try {
    const response = yield call(() =>
      Http.get.cache(
        `${config.kongApiBaseUrl}/vanpools/vanpool/vehicle/switch/reasons`
      )
    );

    yield put({
      type: actionTypes.VEHICLE_SWITCH_REASONS_LOADED,
      data: response.vehicleSwitchReasons
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.VEHICLE_SWITCH_REASONS_ERROR,
      data: { error: true }
    });
  }
}

function* requestVehicleSwitch(action) {
  try {
    const response = yield call(() =>
      Http.post(
        `${config.kongApiBaseUrl}/vanpools/vanpool/vehicle/switch/request`,
        action.data
      )
    );

    if (!response.success) throw new Error('failed');

    yield put({ type: actionTypes.VEHICLE_SWITCH_SUCCESS });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.VEHICLE_SWITCH_FAILURE });
  }
}
