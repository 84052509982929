import React from 'react';
import { Block } from '../../shared/icons';

export function RegisteredUser() {
  return (
    <div className="legend registered">
      <div className="colors">
        <div className="pair">
          <span className="color black" /> Default
        </div>
        <div className="pair">
          <span className="color green" /> Saved
        </div>
        <div className="pair">
          <span className="color grey" /> Future / Past
        </div>
      </div>
    </div>
  );
}

export function Rider() {
  return (
    <div className="legend">
      <div className="symbols">
        <div className="pair">
          <span className="symbol">
            <Block />
          </span>{' '}
          Did Not Commute
        </div>
      </div>
      <div className="colors">
        <div className="pair">
          <span className="color green" /> Active / Saved
        </div>
        <div className="pair">
          <span className="color grey" /> Future / Past / Inactive
        </div>
      </div>
    </div>
  );
}
