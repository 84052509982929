import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import dateUtility from '../../shared/dateUtility';
import { PlusCircle } from '../../shared/icons';
import { TimeInput } from '../../shared/TimeInput';
import calculator from './routeDurationCalculator';
import DraggableStopList from './draggable-stop-list/DraggableStopList';
import Questions from '../../shared/Questions';

const NEW_STOP_ID = '';

export class StopsEditor extends Component {
  constructor(props) {
    super(props);

    if (props.stops.length) {
      const toHome = props.stops[0].stopTime;
      const toWork = this.activeStops(props.stops).pop().stopTime;
      this.state = {
        stops: props.stops,
        time: props.route === 'Work' ? toWork : toHome
      };
    } else {
      this.state = { stops: props.stops || [], time: '00:00:00' };
    }
  }

  componentDidMount() {
    this.setState({ stops: this.props.stops });

    if (this.props.stops.length) {
      const toHome = this.props.stops[0].stopTime;
      const toWork = this.activeStops(this.props.stops).pop().stopTime;
      this.setState({ time: this.props.route === 'Work' ? toWork : toHome });
    }
  }

  updateStop(index, updates) {
    const stops = this.state.stops.map((stop, i) =>
      i === index ? { ...stop, ...updates } : stop
    );
    this.update(stops);
  }

  updateWaitTime(index, value) {
    const stops = this.state.stops.map((stop, i) =>
      i === index ? { ...stop, waitTime: value } : stop
    );
    this.update(stops);
  }

  removeStop(index) {
    let active = this.activeStops();
    let deleted = this.deletedStops();
    const stop = active[index];

    if (stop.routePointId === NEW_STOP_ID) {
      active = active.filter((_, i) => i !== index);
    } else {
      stop.isDelete = true;
      deleted = this.deletedStops();

      active = this.activeStops().map((stop, i) => ({ ...stop, stopOrder: i }));
      active[active.length - 1].stopOrder = -1;
    }

    this.update([...active, ...deleted]);
  }

  addStop() {
    const active = this.activeStops();
    const last = active.pop();
    const prev = active.pop();

    const stop = {
      routePointId: NEW_STOP_ID,
      description: '',
      address: '',
      stopTime: dateUtility.nextMinute(prev.stopTime),
      waitTime: 0,
      canDelete: true,
      isDelete: false,
      stopOrder: active.length + 1
    };

    this.autoFocusStop = stop.stopOrder;

    const stops = [...active, prev, stop, last, ...this.deletedStops()];

    this.update(stops);
  }

  activeStops(stops = this.state.stops) {
    return stops.filter(stop => !stop.isDelete);
  }

  deletedStops(stops = this.state.stops) {
    return stops.filter(stop => stop.isDelete);
  }

  updateTime(time) {
    if (time) {
      this.setState({ time });
    }
  }

  reorder(a, b) {
    const stops = [...this.state.stops];
    const [moved] = stops.splice(a, 1);
    stops.splice(b, 0, moved);

    stops.forEach((stop, index) => {
      if (!stop.isDelete) {
        stop.stopOrder =
          !stops[index + 1] || stops[index + 1].isDelete ? -1 : index;
      }
    });

    this.update(stops);
  }

  update(stops) {
    this.setState({ stops });
    this.props.setDirections(this.activeStops(stops));
  }

  invalidState() {
    return !this.state.stops.every(
      stop => stop.isDelete || (stop.address && stop.description)
    );
  }

  save() {
    let newStops = this.state.stops.map(stop => {
      if (stop.stopOrder === 0 && !stop.isDelete) {
        return { ...stop, waitTime: 0 };
      }

      if (stop.stopOrder === -1 && !stop.isDelete) {
        return { ...stop, waitTime: 0 };
      }

      return stop;
    });

    if (this.props.route === 'Work') {
      newStops = calculator.calculateTimesBasedOnArrival(
        newStops,
        this.state.time,
        this.props.durations
      );
    } else {
      newStops = calculator.calculateTimesBasedOnDeparture(
        newStops,
        this.state.time,
        this.props.durations
      );
    }
    this.props.save(newStops);
  }

  render() {
    const { cancel, route } = this.props;
    const scheduledTo = route === 'Work' ? 'Arrival' : 'Departure';
    const { stops, time } = this.state;
    const existingAddresses = stops.map(({ address }) => address);
    const filteredStops = stops.filter(stop => !stop.isDelete);

    return (
      <div className="route-editor">
        <h3>Editing {route} Route</h3>
        <div className="time">
          <span className="time-label">{scheduledTo} time</span>
          <TimeInput value={time} onSelected={time => this.updateTime(time)} />
        </div>
        <p className="rider-message">
          Route points with riders can not be deleted.
        </p>
        <DraggableStopList
          autoFocusStop={this.autoFocusStop}
          stops={filteredStops}
          existingAddresses={existingAddresses}
          reorder={(...args) => this.reorder(...args)}
          removeStop={(...args) => this.removeStop(...args)}
          updateStop={(...args) => this.updateStop(...args)}
          updateWaitTime={(...args) => this.updateWaitTime(...args)}
        />
        <p
          tabIndex={0}
          className="add-route-point"
          onClick={() => this.addStop()}
        >
          <PlusCircle />
          <span>Add Route Point</span>
        </p>
        <div className="sticky-footer">
          <p className="form-buttons">
            <input id="cancel" type="button" value="Cancel" onClick={cancel} />
            <input
              id="save"
              type="submit"
              value="Save"
              disabled={this.invalidState()}
              onClick={() => this.save()}
            />
          </p>
          <Questions />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    durations: state.map.durations
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    setDirections(directions) {
      dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StopsEditor);
