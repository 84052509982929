import React from 'react';
import Callable from './Callable';

export default function Questions() {
  return (
    <p className="note call">
      If you have any questions, please call <Callable phone="800-VAN-4-WORK" />
      .
    </p>
  );
}
