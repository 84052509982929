import React from 'react';
import OneTimePaymentForm from './OneTimePaymentForm';
import RecurringPaymentForm from './RecurringPaymentForm';
import ModifyRecurringPaymentForm from './ModifyRecurringPaymentForm';

export default function PaymentForm(props) {
  const { recurring, modify } = props;

  if (!recurring) {
    return <OneTimePaymentForm {...props} />;
  }

  if (!modify) {
    return <RecurringPaymentForm {...props} />;
  }

  return <ModifyRecurringPaymentForm {...props} />;
}
