import React, { useEffect, useRef } from 'react';
import VanNotFound from '../../_styles/images/new-icons/vanNotFound.svg';
import Close from '../../_styles/images/close-icon-green.svg';
import analyticsService from '../../shared/analytics/analytics-service';


export default function NoPreferredVanPopup(props) {
    const node = useRef();

    function updatePVPKeydown(e) {
        if (e.which === 13) {
            props.updatePDPopup(false)
        }
    }

    const close = (e) => {
        if (e.keyCode === 27) {
            const changesPopup = document.getElementById('preferred-vanpool-popup');
            if (changesPopup) {
                props.updatePDPopup(false);
            }
        }
    }

    useEffect(() => {
        analyticsService.analyticsProcessEvent({
            "event": "modal_view_generic",
            "context": {
                "event_action": "vanpool is no longer available modal"
            }
        })
        document.querySelector('.preferred-container') && document.querySelector('.preferred-container').focus();
        document.querySelector('.update-commute') && document.querySelector('.update-commute').setAttribute('aria-hidden', 'true');
        document.querySelector('.exactMatch') && document.querySelector('.exactMatch').setAttribute('aria-hidden', 'true');
        document.querySelector('.matched-van-container') && document.querySelector('.matched-van-container').setAttribute('aria-hidden', 'true');
        window.addEventListener('keydown', close);
        return () => {
            window.removeEventListener('keydown', close);
            document.querySelector('.update-commute') && document.querySelector('.update-commute').setAttribute('aria-hidden', 'false');
            document.querySelector('.exactMatch') && document.querySelector('.exactMatch').setAttribute('aria-hidden', 'false');
            document.querySelector('.matched-van-container') && document.querySelector('.matched-van-container').setAttribute('aria-hidden', 'false');
        }
    }, [])

    return (
        <div className="charges-popup show" id='preferred-vanpool-popup' aria-live='polite' role='alert' aria-modal='true' ref={node}>
            <div className='preferred-container'>
                <div className="preferred-vanpool-container">
                    <div className='top-header'>
                        <button
                            role='button'
                            className="close traditional"
                            onClick={() => props.updatePDPopup(false)}
                            onKeyDown={updatePVPKeydown}
                            tabIndex={0}
                            aria-label="Close popup"
                        >
                            <img src={Close} alt='Close' />
                        </button>

                    </div>
                    <div className="sp-header">

                        <div className='header'>
                            <div className='van-image'>
                                <img src={VanNotFound} alt='van not found' />
                            </div>
                            <div className='heading'>
                                The vanpool you were invited to is no longer available
                            </div>
                        </div>
                    </div>
                    <div className='preferred-vanpool-popup-container'>
                        <div className='content'>
                            <div className='first-section'>
                                Please consider these other vanpools with similar commutes
                            </div>
                            <div className='last-section spaced'>
                                To explore more options you can edit the commute
                            </div>
                        </div>

                    </div>
                    <div className='button-container'>
                        <button tabIndex={0} className='continue-button' onClick={() => props.updatePDPopup(false)} onKeyDown={updatePVPKeydown}>Close</button>
                    </div>

                </div>
            </div>
        </div>
    );
}
