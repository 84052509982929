import Tick from '../../_styles/images/tick.svg';
export default function CardCheckBox(props) {
  function onTickKeyDown(e){
    if(e.which === 13){
      props.toggleCheckBox()
    }
  }
  const displayText =  props.setDefault ? "Make this my default card" : props.setBackup ? 'Make this my backup for my Federal Benefit card' : '';
  return (
    <div className='card-checkbox'>
      {props.checked ?
        <div role='checkbox' aria-disabled={props.disabled} tabIndex={0} aria-checked='true' aria-labelledby='default' onKeyDown={onTickKeyDown} className={props.disabled ? 'checkbox selected disabled' : 'checkbox selected'} onClick={props.toggleCheckBox}>
          <img src={Tick} aria-hidden='true'/>
        </div>
        :
        <div role='checkbox' className='checkbox' aria-labelledby='default' aria-checked='false' tabIndex={0} onKeyDown={onTickKeyDown} onClick={props.toggleCheckBox}></div>
      }
      <div className='text' id="default" aria-hidden='true'>{displayText}</div>
    </div>
  );
}