import React from 'react';
import {
  People,
  Person,
  Van,
  Distance,
  Video,
  Employer,
  Globe,
  EmailChat,
  Social
} from '../shared/icons';

const options = [
  { label: 'The Web', icon: Globe },
  { label: 'Social Media', icon: Social },
  { label: 'TV / Video', icon: Video },
  { label: 'Email', icon: EmailChat },
  { label: 'Saw a Vehicle', icon: Van },
  { label: 'Employer', icon: Employer },
  { label: 'Coworker', icon: People },
  { label: 'Friend', icon: Person },
  { label: 'Transit Agency', icon: Distance }
];

export function ReferralOption({ label, icon, onChange, value }) {
  return (
    <button
      className={`option ${label === value ? 'selected' : ''}`}
      onClick={() => onChange(label)}
    >
      <img src={icon} alt={label} />
      <div className="label">{label}</div>
    </button>
  );
}

export default function ReferralInput({ value, onChange, marketingOptions }) {

  var newOptions = [];
  options.map((opt) => {
    marketingOptions.map((o) => {
      if(opt.label === o) {
        newOptions.push({ label: o, icon: opt.icon }) 
      }
    })
  });
  return (
    <div className="referral">
      {newOptions.map(option => (
        <ReferralOption
          {...option}
          onChange={onChange}
          value={value}
          key={option.label}
        />
      ))}
    </div>
  );
}
