import React from 'react';
import { PhoneInput } from '../shared/inputs/PhoneEntry';
import CommuteEdit from './CommuteEdit';
import QuestionYesNo from '../shared/inputs/questionYesNo';
import Section from '../profile/Section';
import { User } from '../shared/icons';

export default function RegistrationCommuteForm(props) {
  return (
    <Section title="Commute Profile" Icon={User} isEditable={false}>
      <CommuteEdit {...props} />
      <PhoneInfo {...props} />
    </Section>
  );
}

function PhoneInfo({ phone, shouldContact, update }) {
  return (
    <div className="other-stuff">
      <div className="phone">
        <label>What's the best number to reach you at?</label>
        <PhoneInput value={phone} update={phone => update({ phone })} />
      </div>
      <QuestionYesNo
        question="Please contact me when matching commutes are available"
        onChange={shouldContact => update({ shouldContact })}
        value={shouldContact}
      />
    </div>
  );
}
