import * as actionTypes from '../../redux/actionTypes';
import config from '../../appConfig';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import dateUtility from '../../shared/dateUtility';
import currencyFormatter from '../../shared/currencyFormatter';
export default function*() {
  yield takeLatest(actionTypes.BALANCE_DETAILS_LOADING, loadBalanceDetails);
}

function* loadBalanceDetails() {
  try {
    let response = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/balance`)
    );
    const formatedLastUpdatedTime = dateUtility.parseDatetimeAsUTCDate(response.lastUpdatedDateTime);
    const balanceFormatted = currencyFormatter.formatUSD(response.currentAccountBalance);
    response = {
      invoiceDueDate: response.invoiceDueDate,
      lastUpdatedDateTime: formatedLastUpdatedTime,
      currentAccountBalance: balanceFormatted
    }
    yield put({ type: actionTypes.BALANCE_DETAILS_LOADED, data: response,  });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.BALANCE_DETAILS_LOADED,
      data: { error: true }
    });
  }
}
