import React, { Component } from 'react';
import { Phone } from '../icons';

const phoneTypeList = ['Mobile', 'Work', 'Home'];

export function validPhoneNumber(num) {
  return /^\d{3}-\d{3}-\d{4}$/.test(num);
}
export function formatPhoneNumber(num) {
  num = num.replace(/[^\d]/g, '');
  if (num.length <= 3) return num;
  num = num.substr(0, 3) + '-' + num.substr(3);
  if (num.length <= 7) return num;
  return num.substr(0, 7) + '-' + num.substr(7, 4);
}
const formatExtension = num => num.replace(/[^\d]/g, '').substr(0, 5);

export function PhoneInput({
  placeholder = 'Phone',
  options = phoneTypeList,
  value: { type, number, extension },
  update
}) {
  const displayedType = type || options[0];
  const isWorkNumber = displayedType === 'Work';

  return (
    <div className={displayedType}>
      <div className="select-switch pad-desk">
        <div className="select">
          <select
            value={type || options[0]}
            onChange={e => update({ type: e.target.value, extension: '' })}
          >
            {options.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <input
          type="text"
          className="number"
          value={number}
          placeholder={placeholder}
          onChange={e =>
            update({
              type: displayedType,
              number: formatPhoneNumber(e.target.value)
            })
          }
        />
        {isWorkNumber ? (
          <input
            value={extension}
            type="text"
            className="ext"
            placeholder="Ext"
            onChange={e =>
              update({
                type: displayedType,
                extension: formatExtension(e.target.value)
              })
            }
          />
        ) : null}
      </div>
    </div>
  );
}

export default class PhoneEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      primary: props.primary,
      secondary: props.secondary,
      valid: this.valid(props)
    };
  }

  updatePrimary(updates) {
    const actualUpdate = { primary: { ...this.state.primary, ...updates } };

    if (updates.type === this.state.secondary.type) {
      actualUpdate.secondary = {
        ...this.state.secondary,
        type: phoneTypeList.find(t => t !== updates.type)
      };
    }

    this.update(actualUpdate);
  }

  updateSecondary(updates) {
    this.update({ secondary: { ...this.state.secondary, ...updates } });
  }

  update(updates) {
    const newState = { ...this.state, ...updates };

    newState.validPhones = this.valid(newState);

    this.setState(newState);
    this.props.update(newState);
  }

  valid({ primary, secondary }) {
    return (
      validPhoneNumber(primary.number) &&
      (!secondary.number || validPhoneNumber(secondary.number))
    );
  }

  render() {
    const { primary, secondary } = this.state;

    const primaryTypes = secondary.number
      ? phoneTypeList.filter(type => type !== secondary.type)
      : phoneTypeList;
    const secondaryTypes = phoneTypeList.filter(type => type !== primary.type);

    return (
      <div className="phone">
        <h3>
          <Phone /> Phone
        </h3>
        <PhoneInput
          value={primary}
          placeholder="Preferred"
          options={primaryTypes}
          update={updates => this.updatePrimary(updates)}
        />
        <PhoneInput
          value={secondary}
          placeholder="Optional"
          options={secondaryTypes}
          update={updates => this.updateSecondary(updates)}
        />
      </div>
    );
  }
}
