import { useEffect } from 'react';
import { ArrowRight } from '../shared/icons';
import NA_VALUE from '../shared/na';
import React from 'react';
import alertIcon from '../_styles/images/AlertYellow.png'
import dateUtility from '../shared/dateUtility';
import analyticsService from '../shared/analytics/analytics-service';
export default function Maintenance({
  nextMaintenanceDate,
  nextMaintenanceMileage,
  lastMaintenanceDate,
  lastMaintenanceMileage
}) {

  useEffect(() => {
    analyticsService.BannerInfo("preventive maintenance");
  }, []);

  return (
    <div
      className={`maintenance ${
        !nextMaintenanceDate || dateUtility.afterToday(nextMaintenanceDate)
          ? ''
          : 'past-due'
      }`}
      role='group'
    >
      <div className="title">
      <img src={alertIcon} alt='alert icon' aria-hidden='true'/>
      <div className="message-content">
        <div className="text">PREVENTATIVE MAINTENANCE - DUE{' '}
          {nextMaintenanceDate !== NA_VALUE
            ? dateUtility.parseDate(nextMaintenanceDate)
            : NA_VALUE}
        {' or '}
        <span className="miles">{nextMaintenanceMileage && nextMaintenanceMileage.toLocaleString()}</span>
        <span className="mi" aria-label='miles'>Mi</span>
      </div>
        <div className="updated">
          {'Last completed '}
          <span className="date">
            {lastMaintenanceDate !== NA_VALUE
              ? dateUtility.parseDate(lastMaintenanceDate)
              : NA_VALUE}
          </span>
          {' at '}
          <span className="miles">
            {lastMaintenanceMileage && lastMaintenanceMileage.toLocaleString()}
          </span>
          <span aria-label='miles'>{' Mi'}</span>
        </div>
      </div>
      {/* <div className="next">
        <span className="date">
          {nextMaintenanceDate !== NA_VALUE
            ? dateUtility.parseDate(nextMaintenanceDate)
            : NA_VALUE}
        </span>
        {' or '}
        <span className="miles">{nextMaintenanceMileage.toLocaleString()}</span>
        <span className="mi">Mi</span> */}
      </div>
      <div className="arrow-right">
        <ArrowRight/>
      </div>
    </div>
  );
}
