import React from 'react';
import Section from './Section';
import { Lock } from '../shared/icons';
import nonceUtility from '../login/nonceUtility';
import AnalyticsButton from '../shared/analytics/analyticsButton';
import azureService from '../login/azureService';

const redirectToAzure = url => () => {
  localStorage.setItem('mobileDeepLink', '/profile/azure-settings');

  window.location.assign(nonceUtility.addNonceToUrl(url));
};

const redirectToAzureForPasswordReset = url => () => {
  localStorage.setItem('passwordReset', 'start');

  redirectToAzure(url)();
};

export default function AzureSettings() {
  return (
    <div className="azure-settings">
      <Section title="Email / Password" Icon={Lock} isEditable={false}>
        <button className="button" 
        onClick={azureService.changeEmail}
        >
          Change Email
        </button>
        <AnalyticsButton
          className="button"
         onClick={azureService.passwordChange}
         eventType="Password Reset Start"
        >
          Change Password
        </AnalyticsButton>
      </Section>
    </div>
  );
}
