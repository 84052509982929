import { Link } from 'react-router-dom';
import PaymentOverviewCollapsible from './collapsibles/PaymentOverviewCollapsible';

export default function PaymentHistoryOverview(props) {
  return (
    <div className='payment-history-overview' role='group'>
      <div className='header'>
        <div className='heading' role='heading' aria-level='2'>
          Payment History
        </div>
        {props.paymentHistory && props.paymentHistory.length > 0 &&
          <Link className="view-link green-link-underline" to="/payment/summary/history">
            VIEW
          </Link>
        }
      </div>
      <div className='content'>

        {
          props.paymentHistory && props.paymentHistory.length ?
            props.paymentHistory.slice(0, 3).map((month, index) => <PaymentOverviewCollapsible key={month.groupTitle} data={month} index={index} />)
            :
            <div className='no-records'>You don’t have any recent payments</div>
        }
      </div>
    </div>
  )
}