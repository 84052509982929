import { Link } from 'react-router-dom';
import { getCardInfoFromBrand, numberWithCommas } from '../../join-commute/helpers/commonHelper';
import { getSortedCards } from '../helper';
import { makeDecimal2numbers } from '../helper';
import CardMessage from '../../payment-history/card-operations/CardMessage';
import Callable from '../../shared/Callable';

export default function PaymentCardsOverview(props) {

  const { cards, isVanStartingInFuture } = props
  const sortedCards = cards && getSortedCards(cards);
  const vars = {};
  const text_message = (
    <span>
      Payment cards can't be modified until your subscription starts. If you need to make changes before then, please call us at <Callable phone="800-VAN-4-WORK" />.
    </span>
  );
  return (
    <div className='PaymentCardsOverview' role='group'>
      <div className='PaymentCardsHeading'>
        <div className='paymentCards' role='heading' aria-level='2'>
          Payment Cards
        </div>
        {cards && cards.length > 0 && !isVanStartingInFuture &&
          <Link className="view-link green-link-underline" to="/payment/summary/cards">
            view
          </Link>}
      </div>
      {isVanStartingInFuture && <CardMessage message={text_message}/>}
      <div className='card-list-container'>
        {
          sortedCards && sortedCards.length ?
            sortedCards.slice(0, 2).map(card => {
              { vars.expiry = card.expirationMMYY.replace(/^\d\d/, '$&/') }
              { vars.amount = makeDecimal2numbers(card.maxAuthorizedAmount) }
              { vars.cardType = card.isPrimary ? "Default" : "Backup" }
              return <div key={card.partialAccountNumber} className={'main' + `${!card.isPrimary ? ' backup' : ""}`} role='group' >
                <div className='leftSection'>
                  <div className='CardImage'>
                    <img src={getCardInfoFromBrand(card.cardBrand).imgSrc} aria-hidden='true' />
                  </div>
                  <div className='cardDetails'>
                    <div className='name'>{card.cardBrand.toLowerCase()}</div>
                    <div className='type'> {card.isFederalBenefitCard ? "Federal Benefits Card" : " "} </div>
                    <div className='details' aria-label={`"Card ending with ${card.partialAccountNumber} expiring on ${vars.expiry}`}>{`${"Card ending *" + card.partialAccountNumber + " | Exp: " + vars.expiry}`}</div>
                    {card.isFederalBenefitCard && <div className='amount' aria-label={`${vars.amount} dollars`}> {"Authorized amount: $" + numberWithCommas(vars.amount)}</div>}
                  </div>
                </div>
                <div className='rightSection'>
                  <div aria-label={`${vars.cardType} card`} className={`${card.isPrimary ? "primary" : "backup"}`}>{vars.cardType}</div>
                </div>
              </div>
            }

            )
            :
            <div className='no-records'>You have not added any payment methods yet</div>
        }

      </div>
    </div>
  )
}