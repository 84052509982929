import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  participantRouteProfileId: null,
  roles: {},
  rideDays: [],
  runDays: [],
  routeId: null,
  outboundRoutePoints: [],
  toWorkStart: {},
  toWorkEnd: {},
  toHomeStart: {},
  toHomeEnd: {},
  proratedInfo: {},
};

export default (state = {}, action) => {
  switch (action.type) {
    case actionTypes.PARTICIPANT_PROFILE_LOADED:
      const existing = state[action.data.id] || {};
      return {
        ...state,
        [action.data.id]: { ...initialState, ...existing, ...action.data }
      };
    case actionTypes.PARTICIPANT_PROFILE_DELETED:
      const newState = { ...state };
      delete newState[action.data];
      return newState;
      case actionTypes.LOAD_PRORATED_INFO_SUCCESS: return {
        ...state,
        proratedInfo: action.data,
      }
    default:
      return state;
  }
};
