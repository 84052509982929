import Close from '../../_styles/images/close-icon-green.svg';
import { Fragment, useEffect, useState } from 'react';
import AddressUtility from '../../shared/addressUtility';
import DaysInput from '../../shared/DaysInput';
import LocationInput from '../../shared/inputs/LocationInput';
import TimeEntry from '../../shared/inputs/TimeEntry';
import MapService from '../../shared/map-service';

import { check2hoursApart, getTimeFromArrival, getUpdatedRoosterDays, getUserPackage, getVanAvailableDays, getVanOperationalDays } from '../../join-commute/helpers/commonHelper';


export default function EditMyCommutePopup(props) {

    const initState = {
        homeLatitude: 0,
        homeLongitude: 0,
        workLatitude: 0,
        workLongitude: 0,
        homeAddress: '',
        workAddress: '',
        arriveTime: '',
        departTime: '',
        flexArrivalMinutes: 0,
        flexDepartureMinutes: 0,
        rosterDays: { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false },
        activeDays: [],
        homeAddressExpanded: {},
    }

    const [commuteState, updateCommuteState] = useState(initState);
    const [arriveTime, updateArrivalTime] = useState({});
    const [departTime, updateDepartTime] = useState({});
    const [employerName, updateEmployerName] = useState('');
    const [isStartValid, updateIsStartValid] = useState(true);
    const [startInvalidMessage, updateStartInvalidMessage] = useState('');
    const [isEndValid, updateIsEndValid] = useState(true);
    const [endInvalidMessage, updateEndInvalidMessage] = useState('');
    const [isTimeValid, updateIsTimeValid] = useState(true);
    const [timeInvalidMessage, updateTimeInvalidMessage] = useState('');
    const [buttonDisabled, updateButtonDisabled] = useState(true);
    const [lastChangedLocation, updateLastChangedLocation] = useState('');
    const [isAriveFlexible, updateIsAriveFlexible] = useState(false);
    const [isDepartFlexible, updateIsDepartFlexible] = useState(false);

    useEffect(() => {
        if (props.commuteProfile) {
            const prevCommuteState = {
                homeLatitude: 0,
                homeLongitude: 0,
                workLatitude: 0,
                workLongitude: 0,
                homeAddress: props.commuteProfile.homeAddress,
                workAddress: props.commuteProfile.workAddress,
                arriveTime: props.commuteProfile.arriveAtWork,
                departTime: props.commuteProfile.departFromWork,
                flexArrivalMinutes: props.commuteProfile.arriveFlexible ? props.commuteProfile.arriveFlexible : false,
                flexDepartureMinutes: props.commuteProfile.departFlexible ? props.commuteProfile.departFlexible : false,
                activeDays: props.commuteProfile.activeDays,
                employerName: props.commuteProfile.employer
            }
            updateCommuteState(prevCommuteState);
            updateEmployerName(props.commuteProfile.employer);
            updateArrivalTime(getTimeFromArrival(props.commuteProfile.arriveAtWork));
            updateDepartTime(getTimeFromArrival(props.commuteProfile.departFromWork));
            updateIsAriveFlexible(props.commuteProfile.arriveFlexible && props.commuteProfile.arriveFlexible > 0 ? true : false);
            updateIsDepartFlexible(props.commuteProfile.departFlexible  && props.commuteProfile.departFlexible > 0 ? true : false);
        }
    }, [props.commuteProfile]);

    function validateData() {
        var isAnyLocationEmpty = commuteState.homeAddress === '' || commuteState.workAddress === '';
        var areLocationsEqual = commuteState.homeAddress === commuteState.workAddress;
        var isAnyTimeEmpty = commuteState.arriveTime === '' || commuteState.departTime === '' || JSON.stringify(arriveTime) === JSON.stringify({}) || JSON.stringify(departTime) === JSON.stringify({});
        var areTimesEqual = commuteState.arriveTime === commuteState.departTime;
        var areTimes2hourApart = check2hoursApart(arriveTime, departTime);
        var activeDaysValid = commuteState.activeDays.length >= 2;
        var employerNameEmpty = employerName === ''
        // var isAnyFlexibilityEmpty = commuteState.flexArrivalMinutes === null || commuteState.flexDepartureMinutes === null;

        if (!isAnyLocationEmpty && isStartValid && isEndValid && areLocationsEqual) {
            if (lastChangedLocation === 'start') {
                updateStartInvalidMessage('Address cannot be the same as arrival');
            } else if (lastChangedLocation === 'end') {
                updateEndInvalidMessage('Address cannot be the same as departure');
            }
        } else {
            updateStartInvalidMessage('');
            updateEndInvalidMessage('');
        }

        if (!isAnyTimeEmpty) {
            if (areTimesEqual) {
                updateTimeInvalidMessage('Arrival and departure times cannot be the same');
                updateIsTimeValid(false);
            } else if (!areTimes2hourApart) {
                updateTimeInvalidMessage('Arrival and departure times must be more than two hours apart');
                updateIsTimeValid(false);
            } else {
                updateTimeInvalidMessage('');
                updateIsTimeValid(true);
            }
        }

        return !isAnyLocationEmpty && !areLocationsEqual && isStartValid && isEndValid && !areTimesEqual && !isAnyTimeEmpty && activeDaysValid && areTimes2hourApart && !employerNameEmpty;
    }

    useEffect(() => {
        var isDataValid = validateData();
        updateButtonDisabled(!isDataValid);
    }, [commuteState, isStartValid, isEndValid, isTimeValid, arriveTime, departTime, employerName])

    function getLocationValue(key, value, valid) {
        var newValue = value;
        if (key === 'start') {
            newValue = {
                latitude: value.latitude,
                longitude: value.longitude,
                address: value.address,
            }
            updateLastChangedLocation(key);
            updateIsStartValid(valid);
        } else if (key === 'end') {
            newValue = {
                latitude: value.latitude,
                longitude: value.longitude,
                address: value.address,
            }
            updateLastChangedLocation(key);
            updateIsEndValid(valid)
        }
        return newValue;
    }

    function onFormChange(key, value, valid) {
        var tempState;
        if (key === 'start') {
            var data = value;
            data = getLocationValue(key, value, valid)
            const homeAddressExpanded = AddressUtility.fromGeocode(value);
            var updatedState = {
                ...commuteState,
                stateName: value.state,
                homeLatitude: data.latitude,
                homeLongitude: data.longitude,
                homeAddress: data.address,
                homeAddressExpanded,
            }
            updateCommuteState(updatedState);
            updateIsStartValid(valid);
        } else if (key === 'end') {
            var data = value;
            data = getLocationValue(key, value, valid)
            var updatedState = {
                ...commuteState,
                workLatitude: data.latitude,
                workLongitude: data.longitude,
                workAddress: data.address,
            }
            updateCommuteState(updatedState);
            updateIsEndValid(valid)
        }
        else {
            tempState = {
                ...commuteState,
                [key]: value
            };
            updateCommuteState(tempState)
        }
        if (key === 'rosterDays') {
            const keys = Object.keys(value).filter(el => value[el] == true);
            var updatedState = {
                ...commuteState,
                activeDays: keys
            }
            updateCommuteState(updatedState);
        }
    }


    function updateDays(days) {
        const DAYS = getUpdatedRoosterDays(days.rideDays, true);
        var updatedState = {
            ...commuteState,
            rosterDays: DAYS
        }
        updateCommuteState(updatedState);
        onFormChange('rosterDays', DAYS, true);
    }

    function onTimeChange(key, value) {
        if (key === 'arriveTime') {
            updateArrivalTime(value);
        } else {
            updateDepartTime(value);
        }
        onFormChange(key, value.value);
    }

    const isAriveFlexibleClicked = (option) => {
        updateIsAriveFlexible(option);
      };

      const isDepartFlexibleClicked = (option) => {
        updateIsDepartFlexible(option);
      };

      

    async function onContinue() {
        let home = await MapService.getAddressAndLocation(commuteState.homeAddress);
        let work = await MapService.getAddressAndLocation(commuteState.workAddress);
          var paylaod = {
            ...commuteState,
            homeLatitude: home.latitude,
            homeLongitude: home.longitude,
            workLatitude: work.latitude,
            workLongitude: work.longitude,
            flexArrivalMinutes: isAriveFlexible,
            flexDepartureMinutes: isDepartFlexible,
            employerName: employerName
          }
          props.save(paylaod);
          props.close();
    }

    return (
        <div
            className="edit-my-commute-popup show"
            tabIndex={0}
            aria-live="polite"
            role="alert"
            id="modal"
        >
            <div className="edit-my-commute-details-popup">
                <div className='edit-commute-header'>
                    <div className='header' aria-hidden='true'>
                        Edit Commute Profile
                    </div>
                    <img
                        role="button"
                        className="close"
                        src={Close}
                        onClick={props.close}
                        tabIndex={0}
                        alt="close icon"
                        autoFocus={true}
                    // onKeyDown={keydownHandler}
                    />
                </div>

                <div className="edit-my-commute-details-popup-content">
                    <div className='commute-details'>
                        <fieldset className='commute-locations'>
                            <fieldset className='field commute-start'>
                                <label htmlFor='commuteStart' className='attach' aria-label='commute start'>
                                    Commute Start*
                                </label>
                                <LocationInput
                                    id='commuteStart'
                                    name='commuteStart'
                                    testid='start-input'
                                    placeholder='Enter a location'
                                    value={commuteState.homeAddress}
                                    invalidmessage={startInvalidMessage}
                                    onChange={(value, valid) => onFormChange('start', value, valid)}
                                />
                            </fieldset>
                            <fieldset className='field commute-end'>
                                <label htmlFor='commuteEnd' className='attach' aria-label='commute end'>
                                    Commute End*
                                </label>
                                <LocationInput
                                    id='commuteEnd'
                                    name='commuteEnd'
                                    testid='end-input'
                                    placeholder='Enter a location'
                                    value={commuteState.workAddress}
                                    invalidmessage={endInvalidMessage}
                                    onChange={(value, valid) => onFormChange('end', value, valid)}
                                />
                            </fieldset>
                        </fieldset>
                        <fieldset className='commute-days'>
                            <div className="commuteDays">
                                <DaysInput
                                    value={commuteState.activeDays}
                                    onChange={rideDays => updateDays({ rideDays })}
                                    notAllowed={[]}

                                />
                            </div>
                        </fieldset>
                        <fieldset className='time-entry'>
                            <Fragment>
                                <TimeEntry
                                    onChange={(value) => onTimeChange('arriveTime', value)}
                                    label='Arrive At Work*'
                                    value={arriveTime}
                                    valid={isTimeValid}
                                />
                            </Fragment>
                            <Fragment>
                                <TimeEntry
                                    onChange={(value) => onTimeChange('departTime', value)}
                                    label='Depart From Work*'
                                    value={departTime}
                                    valid={isTimeValid}
                                />
                            </Fragment>
                        </fieldset>
                        <div className='flexible' role="radiogroup" aria-labelledby='schedule'>
                        <label id='flexible'>Is your arrival time flexible?</label>
                            <div className='radio-section'>
                                <div
                                tabIndex={0}
                                role='radio'
                                aria-checked={isAriveFlexible}
                                onClick={() => isAriveFlexibleClicked(true)}
                                className={isAriveFlexible === true? 'radio active' : 'radio'}
                                aria-labelledby='isFlexibleYes'
                                >
                                <div className='round'></div>
                                <label id="isFlexibleYes" className='text'>Yes</label>
                                </div>
                                <div
                                tabIndex={0}
                                role='radio'
                                aria-checked={isAriveFlexible}
                                onClick={() => isAriveFlexibleClicked(false)}
                                className={isAriveFlexible === false ? 'radio active' : 'radio'}
                                aria-labelledby='isFlexibleNo'
                                >
                                <div className='round'></div>
                                <label id="isFlexibleNo" className='text'>No</label>
                                </div>
                            </div>
                        </div>
                        <div className='flexible' role="radiogroup" aria-labelledby='schedule'>
                        <label id='flexible'>Is your departure time flexible?</label>
                            <div className='radio-section'>
                                <div
                                tabIndex={0}
                                role='radio'
                                aria-checked={isDepartFlexible}
                                onClick={() => isDepartFlexibleClicked(true)}
                                className={isDepartFlexible === true? 'radio active' : 'radio'}
                                aria-labelledby='isFlexibleYes'
                                >
                                <div className='round'></div>
                                <label id="isFlexibleYes" className='text'>Yes</label>
                                </div>
                                <div
                                tabIndex={0}
                                role='radio'
                                aria-checked={isDepartFlexible}
                                onClick={() => isDepartFlexibleClicked(false)}
                                className={isDepartFlexible === false ? 'radio active' : 'radio'}
                                aria-labelledby='isFlexibleNo'
                                >
                                <div className='round'></div>
                                <label id="isFlexibleNo" className='text'>No</label>
                                </div>
                            </div>
                        </div>
                        <fieldset className='employer'>
                            <fieldset className='field'>
                                <label htmlFor='ename' className='label' aria-label='Name of Employer'>
                                    Name of Employer*
                                </label>
                                <input id='ename' value={employerName} onChange={(e) => updateEmployerName(e.target.value)}></input>
                            </fieldset>
                        </fieldset>
                        <div className='button-container'>
                            <button className='continue-button' disabled={buttonDisabled} onClick={onContinue} >UPDATE COMMUTE</button>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    );
}
