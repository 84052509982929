import React from 'react';
import { round } from 'lodash';
import StackedBar from './StackedBar';
import currencyFormatter from '../shared/currencyFormatter';

export default function ImpactDataStackedBar({
  label,
  total,
  goals,
  potential,
  metric,
  isCurrency,
  noDataMessage
}) {
  total = total || 0;

  const showStackedBar = goals || potential;

  return (
    <div className="impact-data-stacked-bar">
      <div className={showStackedBar ? 'label' : 'label  underline'}>
        {label}
      </div>
      <ShowStackedBar
        hide={!showStackedBar}
        total={total}
        isCurrency={isCurrency}
        goals={goals}
        potential={potential}
      />
      <ShowTotalOnly
        hide={showStackedBar}
        total={total}
        isCurrency={isCurrency}
        metric={metric}
        noDataMessage={noDataMessage}
      />
    </div>
  );
}

function ShowStackedBar({ total, goals, potential, isCurrency, hide }) {
  if (hide) {
    return null;
  }

  const goalPercent = round((total / goals) * 100, 1).toLocaleString();
  const potentialPercent = round((total / potential) * 100, 1).toLocaleString();

  const GoalDiv = goals ? (
    <div>
      <label>Goal:</label>
      <span className="percent goal">{goalPercent}%</span>
    </div>
  ) : null;

  const PotentialDiv = potential ? (
    <div>
      <label>Potential:</label>
      <span className="percent potential">{potentialPercent}%</span>
    </div>
  ) : null;

  return (
    <div className="bar-container">
      <StackedBar
        total={total}
        goals={goals}
        potential={potential}
        isCurrency={isCurrency}
      ></StackedBar>
      <div className="details">
        {GoalDiv}
        {PotentialDiv}
      </div>
    </div>
  );
}

function ShowTotalOnly({ hide, total, isCurrency, metric, noDataMessage }) {
  if (hide) {
    return null;
  }

  return total ? (
    <div className="total">
      {isCurrency ? currencyFormatter.formatUSD(total) : total.toLocaleString()}
      <span className="metric">{metric}</span>
    </div>
  ) : (
    <div className="no-data">
      {noDataMessage || `No data for selected time period`}
    </div>
  );
}
