import React, { Component } from 'react';
import { Star } from '../../shared/icons';
import { SteeringWheel, Warning, PlusCircle, Close } from '../../shared/icons';
import QuestionYesNo from '../../shared/inputs/questionYesNo';
import DriversLicense from '../../shared/inputs/DriversLicense';

const newLicense = () => ({ licenseNumber: '', licenseState: '' });

function validLicenseList(licenses) {
  return (
    licenses.length &&
    licenses.every(license => license.licenseNumber && license.licenseState)
  );
}

export default class DriverInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      licenses: [newLicense()],
      licenseFiveOrMore: null,
      foreignLicense: null,
      militaryLicense: null
    };
  }

  update(updates) {
    if (updates.licenses) {
      updates.validLicenses = validLicenseList(updates.licenses);
    }

    const newState = { ...this.state, ...updates };

    const validDriver =
      (newState.licenseFiveOrMore && newState.foreignLicense === null) ||
      (newState.licenseFiveOrMore === false &&
        newState.foreignLicense !== null);
    this.setState(updates, () =>
      this.props.onChange({
        ...newState,
        driverInformationIsValid:
          newState.validLicenses &&
          newState.licenseFiveOrMore !== null &&
          newState.militaryLicense !== null &&
          validDriver
      })
    );
  }

  toggleSecondLicense(value) {
    this.update({ licenseFiveOrMore: value, foreignLicense: null });

    if (value && this.state.licenses.length > 1) {
      this.update({ licenses: [this.state.licenses[0]] });
    }
  }

  toggleForeignLicense(value) {
    this.update({ foreignLicense: value });

    if (!value && this.state.licenses.length === 1) {
      this.update({ licenses: [...this.state.licenses, newLicense()] });
    }

    if (value && this.state.licenses.length > 1) {
      this.update({ licenses: [this.state.licenses[0]] });
    }
  }

  render() {
    const {
      licenseFiveOrMore,
      foreignLicense,
      licenses,
      militaryLicense
    } = this.state;
    return (
      <div>
        <div className="driver-information">
          <h3>
            <SteeringWheel /> Driver Information
          </h3>
          <div className="pad-desk">
            <DriversLicense
              value={licenses[0]}
              onChange={value =>
                this.update({ licenses: [value, ...licenses.slice(1)] })
              }
            />
            <QuestionYesNo
              className="five-or-more-question"
              question="Have you had the above license for 5 or more years?"
              onChange={value => this.toggleSecondLicense(value)}
              value={licenseFiveOrMore}
            />
            {licenseFiveOrMore === false ? (
              <QuestionYesNo
                className="foreign-question"
                question={`For driver verification purposes, have you ever had a foreign driver's license?`}
                onChange={value => this.toggleForeignLicense(value)}
                value={foreignLicense}
              />
            ) : null}
            {foreignLicense === false && licenses.length > 1 ? (
              <SecondLicense
                licenses={licenses}
                onChange={licenses => this.update({ licenses })}
                addLicense={() => this.addLicense(1)}
                removeLicense={() => this.setLicenseLength(2)}
              />
            ) : null}
          </div>
        </div>
        <div className="military-service">
          <h3>
            <Star /> Military Service
          </h3>
          <QuestionYesNo
            question="Are you active military ?"
            onChange={value => this.update({ militaryLicense: value })}
            value={militaryLicense}
          />
        </div>
      </div>
    );
  }
}

function SecondLicense({ licenses, onChange }) {
  return (
    <div className="second-license">
      <p className="pad-desk warning">
        <Warning /> You must provide 5+ years of driver history to be approved
        to drive for Commute with Enterprise.
      </p>
      <p className="note">
        If you do not have your prior driver license information available, your
        local DMV or your insurance may be able to assist{' '}
      </p>
      <DriversLicense
        value={licenses[1]}
        className="second-license-comp"
        onChange={value => onChange([licenses[0], value, ...licenses.slice(2)])}
      />
      {licenses.length < 3 ? (
        <button
          className="another-license-button"
          onClick={() => onChange([...licenses, newLicense()])}
        >
          <PlusCircle /> Another Drivers License
        </button>
      ) : (
        <ThirdLicense licenses={licenses} onChange={onChange} />
      )}
    </div>
  );
}

function ThirdLicense({ licenses, onChange }) {
  return (
    <div className="third-license">
      <button onClick={() => onChange(licenses.slice(0, 2))} className="remove">
        <Close />
      </button>
      <DriversLicense
        value={licenses[2]}
        className="third-license-comp"
        onChange={value => onChange([licenses[0], licenses[1], value])}
      />
    </div>
  );
}
