let matchCookieService = {}

matchCookieService.getcookie = (cname) => {
    var cookie = document.cookie.split(';').some((item) => item.trim().startsWith(cname + "="));
     if(cookie) {
         return cookie
     }else {
         return ""
     }
}

matchCookieService.createCookie = (cname,expiration) => {
    var d = new Date();
  if(expiration){
    d.setTime(d.getTime() + (3600 * 1000 * 24 * 365 * 10));                   
  } else {
    d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
  } 
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + true + ";" + expires + ";path=/";
  }


export default matchCookieService;