import React from 'react';
import Collapsible from 'react-collapsible';
import { AngleDown } from '../../shared/icons';
import useError from '../../shared/components/error-screen/useError';

export default function PaymentTable({ isMyPayments, payments, isError, history, errorString }) {
  if (!payments) {
    return null;
  }

  useError(isError, history, errorString);

  return (
    <div className={isMyPayments ? 'my-history' : 'vanpool-history'}>
      <div className="title">
        {isMyPayments ? 'My Online Payment History' : 'Vanpool Payment History'}
        <div className="description">(One-time and Recurring Payments)</div>
      </div>
      <div className="etable">
        <div className="etable-row">
          <div className="etable-header my-history-month">Month</div>
          <div className="etable-header my-history-payment-date">Payment Date</div>
          <div className="etable-header my-history-description">Description</div>
          <div className="etable-header my-history-amount">Amount</div>
        </div>
        {payments.map((payment, index) => {
          const PaymentHistoryHeading = paymentHistoryHeading(payment, index);
          return (
            <Collapsible
              trigger={PaymentHistoryHeading}
              tabIndex={index}
              key={index}
              open={index === 0}
            >
              <div className="etable-body">
                {!!payment.billingTransactionDetail.length ? (
                  payment.billingTransactionDetail.map((item, itemIndex) => (
                    <TableRow
                      index={index}
                      item={item}
                      itemIndex={itemIndex}
                      key={`index-${index}-itemindex-${itemIndex}`}
                    />
                  ))
                ) : (
                  <p className="note no-recent-activity">No data available</p>
                )}
              </div>
            </Collapsible>
          );
        })}
      </div>
    </div>
  );
}

function paymentHistoryHeading(payment, index) {
  if (payment.receivedEndDate) {
    return (
      <div className="etable-heading toggle monthly-charges">
        <div id={`month-entry-${index}`} className="etable-row">
          <div className="etable-column">
            <div className="etable-head current-month">
              <AngleDown className="angle-down-button" />
              {`${payment.receivedEndDate}`}
            </div>
            <div className="etable-head total">{payment.monthlyTotal}</div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

function TableRow(props) {
  let index = props.index;
  let item = props.item;
  let itemIndex = props.itemIndex;

  return (
    <div className="etable-row" id={`month-entry-${index}-detail-${itemIndex}`}>
      <div className="etable-column">
        <div className="etable-cell spacer"></div>
        <div className="date etable-cell">
          <div className="mobile-only-label"><label>Trans. Date:&nbsp;</label><span>{item.transactionDate}</span></div>
        </div>
        <div className="description etable-cell">
          <div className="mobile-only-label"><label>Description:&nbsp;</label><span>{item.transactionDescription}</span></div>
        </div>
        <div className="total-charges etable-cell right">
          <div className="mobile-only-label"><label>Amount:&nbsp;</label><span>{item.transactionAmount}</span></div>
        </div>
      </div>
    </div>
  );
}
