import { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import config from '../../appConfig';
import MapService from '../map-service';
import analyticsService from '../../shared/analytics/analytics-service';
import useDebounce from '../helpers/useDebounce';
const googleMapsURL = `https://maps.googleapis.com/maps/api/js?${config.googleMapsCredentials}&libraries=places,geometry,drawing`;

export default function LocationInput(props) {
  const [value,setValue] = useState(props.value);
  const [isReady,setIsReady] = useState(false);
  const [isValid,updateIsValid] = useState(true);
  const debouncedValue = useDebounce(value, 1000);
  const [errorCalled, setErrorCalled] = useState(false);

  useEffect(() => {
    const scriptjs = require('scriptjs');
    scriptjs(googleMapsURL,() => setIsReady(true));
  },[])
  
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!isValid && debouncedValue !== '' && debouncedValue.length > 5 && !errorCalled) {
      addUserError();
      setErrorCalled(true); // Mark that error has been handled
    } else if (isValid && errorCalled) {
      setErrorCalled(false); // Reset when input becomes valid
    }
  }, [debouncedValue, isValid, errorCalled]);

  const tempClass = (!isValid || props.invalidmessage) ? 'invalid-address-auto' : props.className ? props.className : '';

  function validateLocation(address) {
    var arr = address.geocode[0].address_components;
    var requiredKeys = ['route','administrative_area_level_1','postal_code','country'];
    var optionalKeys = ['locality','neighborhood']
    var comp = arr.map(c => c.types[0]);
    var validRequired = requiredKeys.every(i => comp.includes(i));
    var validOptional = optionalKeys.some(i => comp.includes(i));
    return validRequired && validOptional;
  }

  async function handleChange(inputValue) {
    setValue(inputValue);
    if(inputValue === '') {
      updateIsValid(true);
      props.onChange(inputValue,false);
      return;
    }
    try {
      let address = await MapService.getAddressAndLocation(inputValue);
      let state = await MapService.getStateFromGeocode(address.geocode);
      let valid = validateLocation(address);
      updateIsValid(valid);
      address = {...address,state};
      props.onChange(address,valid);
    }
    catch(e) {
      if(inputValue.length > 5) {
        updateIsValid(false);
      }
      props.onChange(inputValue,false);
    }
  }

  function addUserError() {
    analyticsService.analyticsProcessEvent({
      "event": "user_error",
      "context": {
        "event_action": 'Please enter a valid address'
      }
    });
  }

  return (
    isReady && <div>
      <PlacesAutocomplete
        className="autocomplete"
        tabIndex={0}
        options={{componentRestrictions: {country: 'US'},types: ['address']}}
        styles={{
          autocompleteContainer: {
            zIndex: 99,
            top: 40,
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
            border: 'none',
            overflow: 'hidden'
          }
        }}
        classNames={{
          input: tempClass
        }}
        inputProps={{
          placeholder: props.placeholder,
          value: value,
          onChange: value => setValue(value),
          onFocus: e => e.target.select(),
          invalidmessage: props.invalidmessage,
          testid: props.testid,
          name: props.name,
          required: true,
          tabIndex: props.tabIndex,
          disabled: props.disabled
        }}
        onSelect={handleChange}
        onError={(_) => updateIsValid(false)}
      />
      {(!isValid || props.invalidmessage) &&
        <div className="invalid-address" role="alert" aria-errormessage={props.invalidmessage || 'Please enter a valid address'}>
          {props.invalidmessage || 'Please enter a valid address'}
        </div>}
    </div>
  );
}
