import parse from 'parse-url';
import Http from '../http-service';
import config from '../../appConfig';
import docusignEvents from './docusign-events';

let docusignService = {};

docusignService.updateStatus = async action => {
  const { envelopeId, contractName, event } = parse(action.data).query;
  await Http.put(`${config.kongApiBaseUrl}/user/participant/contract/status`, {
    envelopeId,
    contractName,
    docusignEventStatus: event
  });

  return { contractName, event };
};

docusignService.getSigningEventConfirmation = event => {
  switch (event) {
    case docusignEvents.SIGNING_COMPLETE:
      return {
        title: 'Thank You',
        confirmMessage: 'Continue',
        message:
          'Your contract/application was submitted, and we will be in touch if we require additional information to complete the process. If you have any questions, please call 800-VAN-4-WORK'
      };
    case docusignEvents.DECLINE:
    case docusignEvents.CANCEL:
      return {
        declined: true
      };
    case docusignEvents.EXCEPTION:
    case docusignEvents.FAX_PENDING:
    case docusignEvents.ID_CHECK_FAILED:
    case docusignEvents.SESSION_TIMEOUT:
    case docusignEvents.TTL_EXPIRED:
    case docusignEvents.VIEWING_COMPLETE:
      return {
        title: 'Sorry For The Inconvenience',
        confirmMessage: 'Continue',
        message: `We are experiencing technical difficulties and this process must be completed before you can proceed.  Please call 1-(800)-VAN-4-WORK for assistance.`
      };
    default:
      return {};
  }
};

export default docusignService;
