import Close from '../../../_styles/images/close-icon-green.svg';
import Callable from '../../Callable';

export default function ContactPopup(props) {

  function onCloseKeyDown(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      props.close();
    }
  }

  return (
    <div className={props.class ? 'charges-popup show ' + props.class : 'charges-popup show'} role='alert' id='one-time-charges-popup' aria-live='assertive'>
      <div className='card-operations-container one-time-charges-popup' ref={props.reference}>
        <div className='charges-header'>
          {props.heading && <div className='popup-heading'>{props.heading}</div>}
          <img
            className='close'
            src={Close}
            onKeyDown={onCloseKeyDown}
            onClick={props.close}
            alt="Close popup"
            role='button'
            tabIndex={0}
            aria-label='Close popup'
          />
        </div>
        <div className='one-time-charges-popup-content'>
          {props.title && <div className='popup-title'>{props.title}</div>}
          {props.firstText && <div className='pre-text'>{props.firstText}</div>}
          {props.contactText && <div className='pre-text'>{props.contactText} <Callable phone="800 VAN 4 WORK" /></div>}
        </div>
        {props.showCloseButton &&
          <div className='button-container'>
            <button tabIndex={0} className='continue-button' onClick={props.close}>Close</button>
          </div>}
      </div>
    </div>
  );
}
