import React from 'react';
import VanpoolSummary from '../payment/VanpoolSummary';
import { Recycle } from '../shared/icons';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ExportCsv from '../shared/ExportCsv';
import { RestrictTo , isAllowed} from '../shared/permissions/RestrictTo';
import { PERMISSION_BILLING_VIEWER,PERMISSION_COORDINATOR,PERMISSION_IPB } from '../shared/permissions/permissions';

const AccountVanpoolSummary = ({
  currentBalanceAsOfDateTime,
  location,
  isPaperless,
  hasPaperless
}) => {
  const PBLink = (
    <div className="paperless-invoice">
      <Recycle />
      <Link className="paperless-billing-cta" to="/profile/preferences">
        {isPaperless ? 'Currently Enrolled' : 'Enroll'} in Paperless Invoices
      </Link>
    </div>
  );

  return (
    <div className="summary">
      <VanpoolSummary />
      {
        !isAllowed([PERMISSION_IPB]) ? 
  
         <RestrictTo  roles={[PERMISSION_BILLING_VIEWER,PERMISSION_COORDINATOR]}
            required={false} >
            <p id="as-of-date-time">
            Account - Last Updated {currentBalanceAsOfDateTime}
            </p>
          </RestrictTo>
          :
          ""
      }  

      {location.pathname === '/payment/history/invoices' && hasPaperless
        ? PBLink
        : null}
      {location.pathname === '/payment' ? <ExportCsv /> : ''}
    </div>
  );
};

export default withRouter(AccountVanpoolSummary);
