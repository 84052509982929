import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cell from '../../shared/Cell';
import * as actionTypes from '../../redux/actionTypes';
import paymentProcessor from '../../shared/credit-card/paymentProcessor';
import Loading from '../../shared/loading/Loading';

export class RecurringDetailsPresenation extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  async submit() {
    this.setState({ loading: true });
    const newCard = !!this.props.payment.selectedCard.isNewCard;
    const payment = newCard
      ? await paymentProcessor.processNewCard(this.props.payment)
      : paymentProcessor.processExistingCard(this.props.payment);
    payment.paymentName = this.props.payment.name;

    this.props.submitRecurringPayment(payment);
  }

  render() {
    const { vanpool, payment, method, cancel } = this.props;

    return (
      <div className="details">
        <Loading isLoading={this.state.loading} />
        <div className="row">
          <Cell label="Type" value="Recurring Payment" />
          <Cell label="Vanpool ID" value={vanpool} />
        </div>
        <div className="row">
          <Cell size="full" label="Payment Name" value={payment.name} />
        </div>
        <div className="row">
          <Cell label="Payment Amount" value={'$' + payment.amount} />
          <Cell label="First Scheduled Payment" value={method.next} />
        </div>
        <div className="row">
          <Cell
            size="full"
            label="Payment Method"
            value={`${method.brand} ${method.account} - EXP ${method.exp}`}
          />
        </div>
        <div className="tools">
          <button className="cancel button" onClick={cancel}>
            Cancel
          </button>
          <button
            className="submit button primary"
            desktopbefore="I Agree,"
            onClick={() => this.submit()}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    submitRecurringPayment(payment) {
      dispatch({ type: actionTypes.RECURRING_PAYMENT_POSTING, data: payment });
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(RecurringDetailsPresenation);
