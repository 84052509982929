import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import * as actionTypes from '../redux/actionTypes';
import { Link } from 'react-router-dom';
import { Check, Calendar } from '../shared/icons';
import { isAllowed, restrictTo } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_REGISTERED_USER,
  PERMISSION_TRIP_RECORDING
} from '../shared/permissions/permissions';
import analyticsService from '../shared/analytics/analytics-service';

function passAnalyticsEvent(str) {
  analyticsService.analyticsProcessEvent({
    "event": "click_generic",
    "context": {
        "event_action": str
    }
});
}
class TripRecordingBanner extends Component {
  componentDidMount() {
    if(!this.props.tripRecordingStatusLoaded){
      this.props.init();
    }
  }

  render() {
    if (!this.props.status) {
      return null;
    }

    const {
      displayDidEveryoneRideToday,
      daysPastDue,
      isPastMonthApproved
    } = this.props.status;
    const addressWordForDidRide = isAllowed([PERMISSION_REGISTERED_USER])
      ? 'you'
      : 'everyone';

    if (!isPastMonthApproved) {
      const currentMonth = moment().format('MMMM');
      const lastMonth = moment()
        .add(-1, 'month')
        .format('MMMM');
      const lastMonthYear = moment()
        .add(-1, 'month')
        .year();

      return (
        <div className="row trip-banner solid overdue">
          <div className="trip-banner-message">
            <Calendar />
            <div className="message">
              {lastMonth} {lastMonthYear} is open for approval until{' '}
              {currentMonth} 10th.
            </div>
          </div>
          <div className="trip-banner-tools">
            <Link
              className="button warning"
              to="/trip-recording"
              desktopafter={lastMonth}
              onClick={() => passAnalyticsEvent("approve")}
            >
              Approve
            </Link>
          </div>
        </div>
      );
    }

    if (daysPastDue) {
      return (
        <div className="row trip-banner solid overdue">
          <div className="trip-banner-message">
            <div className="days">{daysPastDue}</div>
            <div className="message">
              You have travel days that need to be recorded.
            </div>
          </div>
          <div className="trip-banner-tools">
            <Link
              className="button warning"
              to="/trip-recording"
              desktopafter="Past Days"
              onClick={() => passAnalyticsEvent("record past days")}
            >
              Record
            </Link>
          </div>
        </div>
      );
    }

    if (displayDidEveryoneRideToday) {
      return (
        <div className="row trip-banner solid">
          <div className="trip-banner-message">
            <Check aria-hidden='true'/>
            <div className="message">
              Did {addressWordForDidRide} ride both AM and PM trips today?
            </div>
          </div>
          <div className="trip-banner-tools yes-no">
            <button
              className="button primary"
              onClick={() => this.props.submit()}
            >
              Yes
            </button>
            <Link className="button cancel" to="/trip-recording">
              No
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div className="row trip-banner solid overdue">
        <div className="trip-banner-message not-mobile">
          <Check aria-hidden='true'/>
          <div className="message">You are all caught up!</div>
        </div>
        <div className="trip-banner-tools not-mobile">
          <Link
            className="button primary"
            to="/trip-recording"
            onClick={() => passAnalyticsEvent("view trip recording")}
          >
            view Trip Recording
          </Link>
        </div>
        <div className="trip-banner-message mobile-only">
          <Check aria-hidden='true'/>
          <div className="message">
            Trip Recording Complete
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    status: state.dashboard.status,
    tripRecordingStatusLoaded: state.dashboard.tripRecordingStatusLoaded
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.TRIP_RECORDING_STATUS_LOADING });
    },
    submit() {
      dispatch({ type: actionTypes.TRIP_RECORDING_STATUS_SUBMITTING });
    }
  };
}

export default restrictTo([PERMISSION_TRIP_RECORDING])(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TripRecordingBanner)
);
