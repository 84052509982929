import { COMMUTE_PROFILE_LOADING, USER_PROFILE_LOADING } from "../../redux/actionTypes";
import { getCommonDays, getTimeFromArrival, getUpdatedRoosterDays, getUserPackage, getVanAvailableDays, getVanOperationalDays } from '../helpers/commonHelper';

import AddressUtility from '../../shared/addressUtility';
import Close from '../../_styles/images/close-icon-green.svg';
import FlexibilityEntry from '../../shared/inputs/FlexibilityEntry';
import { Fragment } from 'react';
import LocationInput from '../../shared/inputs/LocationInput';
import MapService from '../../shared/map-service';
import SelectDays from '../../profile/components/change-days/select-days/SelectDays';
import TimeEntry from '../../shared/inputs/TimeEntry';
import { connect } from "react-redux";
import { initialJoinCommuteState } from '../JoinCommuteReducer';
import { useEffect } from 'react';
import { useState } from 'react';
import { isEqual } from 'lodash';
import analyticsService from '../../shared/analytics/analytics-service';

function EditCommutePopup(props) {
  const initState = {
    homeLatitude: 0,
    homeLongitude: 0,
    workLatitude: 0,
    workLongitude: 0,
    homeAddress: '',
    workAddress: '',
    stateName: '',
    arriveTime: '',
    departTime: '',
    flexArrivalMinutes: 0,
    flexDepartureMinutes: 0,
    rosterDays: { "Monday": false, "Tuesday": false, "Wednesday": false, "Thursday": false, "Friday": false, "Saturday": false, "Sunday": false },
    package: null,
    homeAddressExpanded: {},
  }

  const [commuteState, updateCommuteState] = useState(initState);

  const [arrivalTime, updateArrivalTime] = useState({});

  const [departTime, updateDepartTime] = useState({});

  const [profile, updateProfile] = useState(initState);

  const [loading, updateLoading] = useState(false);

  const [enabledDays, updateEnabledDays] = useState([]);
  const [operationalDays, updateOperationalDays] = useState([]);
  const [selectedDays, updateSelectedDays] = useState([]);

  const [isDisabled, updateIsDisabled] = useState(true);


  useEffect(() => {
    props.getPreviousCommuteData(props.history);
    updateLoading(true)
  }, [])

  useEffect(() => {
    if(selectedDays.length > 1){
      updateIsDisabled(false);
    } else {
      updateIsDisabled(true);
    }
  }, [selectedDays])

  useEffect(() => {
    if (profile && !isEqual(profile, {})) {
      (async () => {
        const startDetails = await MapService.getAddressAndLocation(profile.homeAddress);
        const stateName = MapService.getStateFromGeocode(startDetails.geocode);
        const homeAddressExpanded = AddressUtility.fromGeocode(startDetails);
        const endDetails = await MapService.getAddressAndLocation(profile.workAddress);
        if (profile.arriveTime && profile.arriveTime !== 'N/A') {
          updateArrivalTime(getTimeFromArrival(profile.arriveTime));
        }
        if (profile.departTime && profile.departTime !== 'N/A') {
          updateDepartTime(getTimeFromArrival(profile.departTime));
        }
        const prevCommuteState = {
          homeLatitude: startDetails.latitude,
          homeLongitude: startDetails.longitude,
          workLatitude: endDetails.latitude,
          workLongitude: endDetails.longitude,
          homeAddress: profile.homeAddress,
          homeAddressExpanded,
          workAddress: profile.workAddress,
          stateName,
          arriveTime: profile.arriveTime,
          departTime: profile.departTime,
          flexArrivalMinutes: profile.flexArrivalMinutes,
          flexDepartureMinutes: profile.flexDepartureMinutes,
          rosterDays: profile.rosterDays
        }
        updateCommuteState(prevCommuteState);
      })();
    }
  }, [profile]);

  useEffect(() => {
    let details = {};
    if (JSON.stringify(initialJoinCommuteState.userCommuteData) === JSON.stringify(props.joinCommuteDetails)) {

      details = {
        homeAddress: props.commuteProfile.homeAddress || props.joinCommuteDetails.homeAddress,
        workAddress: props.commuteProfile.workAddress || props.joinCommuteDetails.workAddress,
        stateName: props.joinCommuteDetails.stateName,
        arriveTime: props.commuteProfile.arriveAtWork || props.joinCommuteDetails.arriveTime,
        departTime: props.commuteProfile.departFromWork || props.joinCommuteDetails.departTime,
        flexArrivalMinutes: props.joinCommuteDetails.flexArrivalMinutes,
        flexDepartureMinutes: props.joinCommuteDetails.flexDepartureMinutes,
        rosterDays: getUpdatedRoosterDays(props.commuteProfile.activeDays, true),
      }
    } else {
      details = {
        homeAddress: props.joinCommuteDetails.homeAddress || props.commuteProfile.homeAddress,
        workAddress: props.joinCommuteDetails.workAddress || props.commuteProfile.workAddress,
        stateName: props.joinCommuteDetails.stateName,
        arriveTime: props.joinCommuteDetails.arriveTime || props.commuteProfile.arriveAtWork,
        departTime: props.joinCommuteDetails.departTime || props.commuteProfile.departFromWork,
        flexArrivalMinutes: props.joinCommuteDetails.flexArrivalMinutes,
        flexDepartureMinutes: props.joinCommuteDetails.flexDepartureMinutes,
        rosterDays: props.joinCommuteDetails.rosterDays,
      }
    }
    updateProfile(details);
  }, [props.commuteProfile])

  useEffect(() => {
    if (props.vanData && props.vanData.operationalDays) {
      const days = getVanAvailableDays(props.vanData.operationalDays);
      updateEnabledDays(days);
      const opDays = getVanOperationalDays(props.vanData.operationalDays);
      updateOperationalDays(opDays);
      if(props.joinCommuteDetails){
        const commonDays = getCommonDays(days, props.joinCommuteDetails.rosterDays);
        updateSelectedDays(commonDays);
      }
    }
  }, [props.vanData, props.joinCommuteDetails])

  function updateComponent() {
    const days = getUpdatedRoosterDays(selectedDays, true);
    const updatedPackage = getUserPackage(selectedDays.length);
    const newCommuteState = {
      ...commuteState,
      rosterDays: days,
      package: updatedPackage,
      isUpdated: true,
      noOfDays: selectedDays.length
    }
    props.updateCommute(newCommuteState, selectedDays);
    var activeDays = Object.keys(days).filter(el => days[el] == true);
    var daysString =  activeDays.map(d => d.substring(0, 2)).join(".").toLowerCase()
    analyticsService.analyticsProcessEvent({
      "event": "edit_my_commute_modal_click",
      "context": {
        "event_action": "update",
        "event_detail": {
            "modal": "edit my commute modal",
            "days_selected": daysString,
            "arrival_flexibility": newCommuteState.flexArrivalMinutes,
            "departure_flexibility": newCommuteState.flexDepartureMinutes,
            }
        }
    });
  }

  function updateKeydown(e){
    if(e.which === 13){
      const days = getUpdatedRoosterDays(selectedDays, true);
      const updatedPackage = getUserPackage(selectedDays.length);
      const newCommuteState = {
        ...commuteState,
        rosterDays: days,
        package: updatedPackage,
        isUpdated: true
      }
      props.updateCommute(newCommuteState, selectedDays);
    }
    if (e.keyCode === 9) {
      e.preventDefault();
      const firstFocusElement = document.querySelector('.close');
      firstFocusElement.focus();
    }
  }

  const keyDownBackTabHandler = (e) => {
    if (e.shiftKey && e.keyCode == 9) {
      e.preventDefault();
      const buttonFocus = document.querySelector('.continue-button:not(:disabled)');
      if(buttonFocus){
        buttonFocus.focus();
      } else {
        const lastFocusElement = document.querySelector('.day-btn.active');
        lastFocusElement.focus();
      }
    }
  }

  return (
    <div className="charges-popup show edit-popup" tabIndex={0} aria-live='polite' role='alert' id='modal'>
      <div className="card-operations-container edit-commute-popup" id='edit-commute-popup'>
        <div className="sp-header">
          <div className='heading'>Edit my commute</div>
          <img role='button' className="close" src={Close} onClick={props.close} alt='close edit my commute popup' tabIndex={0} onKeyDown={keyDownBackTabHandler}/>
        </div>
        <div className="edit-commute-container">
          <div className='collapsible-content-container'>
            <div className='commute-locations'>
              <fieldset className='field commute-start'>
                <label htmlFor='commuteStart' className='attach' aria-label='commute start'>
                  Commute Start
                </label>
                <LocationInput
                  id='commuteStart'
                  name='commuteStart'
                  testid='start-input'
                  placeholder='Enter a location'
                  value={commuteState.homeAddress}
                  onChange={(value, valid) => onFormChange('start', value, valid)}
                  tabIndex={-1}
                  disabled={true}
                />
              </fieldset>
              <fieldset className='field commute-end'>
                <label htmlFor='commuteEnd' aria-label='commute end'>
                  Commute End
                </label>
                <LocationInput
                  id='commuteEnd'
                  name='commuteEnd'
                  testid='end-input'
                  placeholder='Enter a location'
                  value={commuteState.workAddress}
                  onChange={(value, valid) => onFormChange('end', value, valid)}
                  tabIndex={-1}
                  disabled={true}
                />
              </fieldset>
            </div>
            <div className="edit-commute">
              <div className="vanpool-info">These are the days this vanpool runs. Save your seat in the van by selecting the days you’d like to ride.</div>
              <div className="selected-days-container">
                <div className="selected-days" role='group'>Days Selected : {selectedDays.length}</div>
              </div>
              <div className="commuteDays-selectDays">
                <SelectDays
                  runningDays={operationalDays}
                  value={selectedDays}
                  onlyShowRunningDays={true}
                  onChange={updateSelectedDays}
                  minDaysError={selectedDays.length < 2}
                  availableDays={enabledDays}
                  alreadySelected={[]}
                  minDays={2}
                />
              </div>
            </div>
            <div className='time-entry'>
              <Fragment>
                <TimeEntry
                  onChange={(value) => onTimeChange('arriveTime', value)}
                  label='Arrive At Work'
                  value={arrivalTime}
                  tabIndex={-1}
                  disabled={true} />
                <FlexibilityEntry
                  onChange={(value) => onFormChange('flexArrivalMinutes', value, true)}
                  label='Arrival Flexibility'
                  message="Arrival Flexibility includes the selected amount of time before or after your designated arrival time."
                  value={commuteState.flexArrivalMinutes}
                  tabIndex={-1}
                  hideTooltip={true}
                  disabled={true}/>
              </Fragment>
              <Fragment>
                <TimeEntry
                  onChange={(value) => onTimeChange('departTime', value)}
                  label='Depart From Work'
                  value={departTime}
                  tabIndex={-1}
                  disabled={true}
                  />
                <FlexibilityEntry
                  onChange={(value) => onFormChange('flexDepartureMinutes', value, true)}
                  label='Departure Flexibility'
                  message="Departure Flexibility includes the selected amount of time before or after your designated departure time."
                  value={commuteState.flexDepartureMinutes}
                  tabIndex={-1}
                  hideTooltip={true}
                  disabled={true}
                  />
              </Fragment>
            </div>
            <button id="firstContinue" tabIndex={0} aria-disabled={isDisabled} disabled={isDisabled} className='continue-button' onClick={updateComponent} onKeyDown={updateKeydown}>update</button>
          </div>
        </div>
      </div>
    </div>
  )
}


function mapDispatchToProps(dispatch) {
  return {
    getPreviousCommuteData(history) {
      dispatch({ type: COMMUTE_PROFILE_LOADING, source: 'joinCommute', history });
      dispatch({ type: USER_PROFILE_LOADING, source: 'joinCommute', history });
    },
  };
}

function mapStateToProps(state) {
  return {
    commuteProfile: state.commuteProfile.commute,
    joinCommuteDetails: state.joinCommute.userCommuteData,
    vanData: state.joinCommute.selectedVan
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditCommutePopup)
