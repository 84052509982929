import React, { useEffect, useRef, useState } from 'react';
import Close from '../../_styles/images/close-icon-green.svg';


export default function PriceDisclaimerPopup(props) {
  const node = useRef();

  function updatePDPKeydown(e){
    if(e.which === 13){
        props.updatePDPopup(false)
    }
  }

  return (
    <div className="charges-popup show" id='price-disclaimer-popup' aria-live='polite' role='alert' aria-modal='true' ref={node}>
      <div className='price-container'>
        <div className="price-disclaimer-container">
          <div className="sp-header">
            <div className='header'>
            Recurring subscription. <br/>
            Rates subject to change. 
            </div>
            <button
              role='button'
              className="close"
              onClick={() => props.updatePDPopup(false)}
              onKeyDown={updatePDPKeydown}
              tabIndex={0}
              aria-label="Close edit driving schedule popup"
            >
              <img src={Close} alt='Close' />
            </button>
          </div>
          <div className='price-disclaimer-popup-container'>
            <div className='content'>
             Displayed monthly rate includes a monthly recurring subscription. Monthly rate is subject to change, and includes estimated fuel, tolls, add-on equipment, and certain taxes. Displayed rate does not include other optional or non-recurring fees such as key replacement, cleaning, and re-fueling. Depending on your state, your monthly rate may not include certain variable state taxes.  You may cancel online or by telephone with 30 days prior notice. Your Commute with Enterprise Participant Agreement will include full details.
            </div>
          
         </div>
         <div className='button-container'>
         <button  tabIndex={0} className='continue-button' onClick={() => props.updatePDPopup(false)} onKeyDown={updatePDPKeydown}>Close</button>
         </div>

          </div>
        </div>
      </div>
  );
}
