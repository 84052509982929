import React from 'react';
import creditCardService from '../../../shared/credit-card/creditCardService';

export default function PaymentSchedule({ selectedCard, lookup }) {
  const { next, every } = selectedCard.isNewCard
    ? creditCardService.scheduleForCard(selectedCard.num)
    : selectedCard;

  return (
    <div className={`schedule section ${next ? 'visible' : 'hidden'}`}>
      <h3>Payment Schedule</h3>
      <div>
        Your first payment is scheduled for <span className="date">{next}</span>
      </div>
      <div>
        The selected card is charged based on the funding schedule of the card
        provider and will be charged on the{' '}
        <span className="every">{every} of the month</span>.
      </div>
    </div>
  );
}
