import React, { Component } from 'react';
import Card from '../shared/credit-card/Card';

export default class ExistingCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      validExp: false
    };
  }

  onEdit() {
    this.props.editToggle();
    this.setState({ isEditing: true });
  }

  onStopEditing() {
    this.props.editToggle();
    this.setState({ isEditing: false });
  }

  render() {
    return (
      <div>
        <Card
          {...this.props.card}
          isEditable={!this.state.isEditing && !this.props.isEditable}
          isEditing={this.state.isEditing}
          onEdit={() => this.onEdit()}
          validExp={true}
          onStopEditing={() => this.onStopEditing()}
        />
      </div>
    );
  }
}
