import {connect} from 'react-redux';
import ThankYouTick from '../../_styles/images/thankyou-tick.svg'
import ThankYouCommute from '../../_styles/images/thankyou-commute.svg'
import {useEffect} from 'react';
import {initialJoinCommuteState} from '../JoinCommuteReducer';
import isEqual from 'lodash/isEqual';
import analyticsService from '../../shared/analytics/analytics-service';

function ThankYouComponent(props) {

  function goToJoinCommute() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "find a new route"
      }
    });
    props.history.push("/JoinCommute");
  }
  useEffect(() => {
    analyticsService.pageInfo("describe commute");
    if(isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      goToJoinCommute();
    }
  },[])

  return (
    <div className='thank-you-page'>
      <div className='thank-you-container'>
        <div className='content-container'>
          <div className='left-container'>
            <div className='heading-container'>
              <img className='tick' src={ThankYouTick} alt="tick" />
              <div className='heading'>Thank you!</div>
            </div>
            <div className='comment'>We’ll let you know when a match becomes available</div>
            <div className='comment-two'>You can still try searching for a different route</div>
            <button onClick={goToJoinCommute}>Find a new route</button>
          </div>
          <div className='right-container'>
            <img className='commute-image' src={ThankYouCommute} alt="commute-image" />
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    joinCommuteData: state.joinCommute
  }
}

export default connect(mapStateToProps,null)(ThankYouComponent);