import React, { Component } from 'react';
import { connect } from 'react-redux';
import TagsInput from 'react-tagsinput';
import FormPopup from './FormPopup';
import * as actionTypes from '../../redux/actionTypes';
import { MaxLengthTextEditor } from '../InlineTextEditor';

const VALID_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const DEFAULT_MESSAGE = `I'd like you to join our vanpool.`;

export class InviteRiderPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { emails: [], message: DEFAULT_MESSAGE };
    this.reset = this.reset.bind(this);
  }

  inviteUsers() {
    const { emails, message } = this.state;
    this.props.inviteUsers(emails, message);
    this.reset();
  }

  reset() {
    this.props.close();
    this.setState({ emails: [], message: DEFAULT_MESSAGE });
  }

  render() {
    const { active } = this.props;
    const { emails, message } = this.state;

    return (
      <FormPopup
        title="Invite Rider"
        active={active}
        save={() => this.inviteUsers()}
        cancel={this.reset}
        disabled={emails.length === 0}
      >
        <div>
          <TagsInput
            value={emails}
            className="emails"
            inputProps={{ placeholder: 'Enter Email Address' }}
            onlyUnique={true}
            validationRegex={VALID_EMAIL}
            onChange={emails => this.setState({ emails })}
            addOnBlur={emails => this.setState({ emails })}
          />
        </div>
        <h4>Add a Custom Message</h4>
        <MaxLengthTextEditor
          maxLength={500}
          rows={5}
          value={message}
          onChange={message => this.setState({ message })}
        />
      </FormPopup>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    inviteUsers(emailIds, message) {
      dispatch({
        type: actionTypes.ROSTER_INVITE_USERS_LOADING,
        data: { emailIds, message }
      });
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(InviteRiderPopup);
