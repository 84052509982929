import React from 'react';
import { User } from '../shared/icons';
import ImpactDataStackedBar from './ImpactDataStackedBar';

export default function({ timePeriod, data }) {
  return (
    <div className="active-users-impact-data-container">
      <div className="top-bar">
        <div className="label">
          <User /> Active Users
        </div>
        <div className="selected-dates">{timePeriod}</div>
      </div>
      <div className="content">
        <ImpactDataStackedBar
          {...data}
          noDataMessage="No data for selected time period"
        />
      </div>
    </div>
  );
}
