let currencyFormatter = {};

currencyFormatter.formatUSD = value => {
  return !value || isNaN(value)
    ? '$0.00'
    : value
        .toLocaleString('en-us', { style: 'currency', currency: 'USD' })
        .replace(/-|\(/, '\u2212')
        .replace(')', '');
};

currencyFormatter.round = value => Math.round(value * 100) / 100;

currencyFormatter.dollarPad = amount => {
  if (!amount) {
    return '';
  }

  if (amount.includes('.')) {
    let [dollar, cents] = (amount + '00').split('.');

    const asCents = cents.substr(0, 2) + '.' + cents.substr(2);
    cents = ('0' + Math.round(asCents)).substr(-2);

    return dollar + '.' + cents.substr(0, 2);
  }

  return amount + '.00';
};

export default currencyFormatter;
