import React, { Component } from 'react';

export default class Stepper extends Component {
  render() {
    const { step, recurring, modify } = this.props;
    const stepOneTitle = recurring
      ? modify
        ? 'Modify Payment'
        : 'Recurring Payment'
      : 'One Time Payment';

    return (
      <div className={`stepper step-${step}`}>
        <div className={`step one ${step === 1 ? 'active' : ''}`}>
          <div className="label">
            <label>1. {stepOneTitle}</label>
          </div>
        </div>
        <div className={`step two ${step === 2 ? 'active' : ''}`}>
          <div className="label">
            <label>2. Review</label>
          </div>
        </div>
        <div className={`step three ${step === 3 ? 'active' : ''}`}>
          <div className="label">
            <label>3. Confirmation</label>
          </div>
        </div>
        {/* Mobile Stepper */}
        <div className="mobile">
          <span className="italic">Step {step} of 3</span>&nbsp; {stepOneTitle}
        </div>
      </div>
    );
  }
}
