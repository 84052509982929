import * as actionTypes from '../redux/actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import AccountSummaryService from './account-summary-service';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';

export default function*() {
  yield takeLatest(actionTypes.ACCOUNT_SUMMARY_LOADING, loadAccountSummary);
}

function* loadAccountSummary(action) {
  try {

    const monthlyTransactionsUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/account/monthly`;
    let monthlyTransactions = yield call(() =>
      Http.get(monthlyTransactionsUrl)
    );
    
    const transactionsFormated = yield call(() =>
      AccountSummaryService.getMonthlyTransactions(monthlyTransactions)
    );

    yield put({
      type: actionTypes.MONTHLY_ACCOUNT_SUMMARY_LOADED,
      data: transactionsFormated
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.ACCOUNT_SUMMARY_ERROR });
  }
}
