import { Route, Switch, Redirect } from 'react-router-dom';
import DriverScheduleMain from "./DriverScheduleMain";
import ReviewDriverScheduleChanges from './review-changes/ReviewDriverScheduleChanges';

const prefix = '/driver-schedule';

export default function DriverScheduleRoute() {
  return (
    <div className="driverScheduleRoute">
      <Switch>
        <Route path={prefix} component={DriverScheduleMain} exact />
        <Route path={`${prefix}/reviewChanges`} component={ReviewDriverScheduleChanges} exact />
      </Switch>
    </div>
  );
}
