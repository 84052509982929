import React, { Component } from 'react';
import { FuelPump, FuelGauge, Money } from '../../shared/icons';
import * as actionTypes from '../../redux/actionTypes';
import { connect } from 'react-redux';
import DollarInput from '../../shared/inputs/DollarInput';
import currencyFormatter from '../../shared/currencyFormatter';
import dateUtility from '../../shared/dateUtility';
import Transactions from './Transactions';
import { Unchecked, Checked, Plus } from '../../shared/icons';
import TransactionsLists from './transactionsList';
import AddExpensesPopup from './addExpensePopup';
import { Droppable } from 'react-beautiful-dnd';
import analyticsService from '../../shared/analytics/analytics-service';

function mostRecent(dates) {
  const recent = dates
    .map(date => date && dateUtility.formatAsSortableDate2(date))
    .reduce((recent, date) => {
      return !date || recent > date ? recent : date;
    });
  return dateUtility.formatAsSortableDate2(recent);
}

class Expenses extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expenses:{
        fuelCost: 0,
        fuelGallons: 0,
        others:  0
      },
      allSelected: null,
      transactions: [],
      month:"current",
      lastUpdated:null
    };
  }

  componentDidMount() {
    const presentMonth = dateUtility.currentMonthAsTwoDigits();
    const previousMonth = dateUtility.previousDate();
    const curMonth = presentMonth;
    const curYear = dateUtility.currentYear();
    const preMonth = dateUtility.getMonthFromDate(previousMonth);
    const preYear = dateUtility.getYearFromDate(previousMonth);
    if(this.props.month == 'current' ){
      this.props.init(curMonth, curYear, preMonth, preYear);
    }else {
      this.props.init(preMonth, preYear, preMonth, preYear);
    }
  }

  componentWillReceiveProps(props) {
    if (props.expenses && Object.keys(props.expenses.previous).length) {
      this.props.hasPreviousData(true);
    }
    if(props.month !== this.state.month){
      this.setState({month:props.month});
      const presentMonth = dateUtility.currentMonthAsTwoDigits();
      const previousMonth = dateUtility.previousDate();
      let month = null;
      let year = null;
      if(props.month == 'current' ){
        month = presentMonth;
        year  = dateUtility.currentYear();
      }else {
        month = dateUtility.getMonthFromDate(previousMonth);
        year = dateUtility.getYearFromDate(previousMonth);
      }
     this.props.getWEXTransactions(month, year);
    }
    if (props.wextransaction && props.wextransaction.expenses && props.wextransaction.expenses.length) {
          let transactionList = props.wextransaction.expenses;
          transactionList.map((transaction,i)=> {
              transaction.id = i;
          });
          transactionList.sort(function(a,b){
           return new Date(b.activityDateTime) - new Date(a.activityDateTime);
          });
          this.setState({ transactions: transactionList })
          this.calculateExpenses(props.wextransaction.expenses);
    }else {
      this.setState({ transactions: [] })
    }
  }

  getPreviousMonth() {
     const previousDate = dateUtility.previousDate();
     const Month =  dateUtility.getMonthFromDate(previousDate);
     const Year = dateUtility.getYearFromDate(previousDate);
 
     return {
       "Month": Month,
       "Year": Year
     };
   }

  calculateExpenses(trasactions) {
    let fuelCost = 0, fuelGallons = 0 , others = 0   
    trasactions.map((t)  =>  {
      if(t.isSelected){
        fuelCost = t.type == 'Fuel' ? fuelCost  + t.cost : fuelCost;
        fuelGallons = fuelGallons +  t.fuelGallons;
        others  = t.type == 'Other' ?  others + t.cost : others;
      }
    });
    this.setState({
      expenses: {
        ...this.state.expenses,
        fuelCost: fuelCost,
        fuelGallons:parseFloat(fuelGallons).toFixed(2),
        others:others
      },
    },() => {
      this.calculatelastUpdate();
    });
  }

  updateTransactionSelected(p) {
    const transactions = this.state.transactions;

    this.setState({
      transactions: transactions.map(transaction =>
        transaction.id !== p.id
          ? transaction
          : {
            ...p,
            isSelected: !p.isSelected
          }
      )
    },() => {this.calculateExpenses(this.state.transactions)});
  }

  checkSelected() {
    const transactions = this.state.transactions;
    const selected = transactions.filter(transaction => { 
      return transaction.isSelected == true
     })
     if(selected.length){
       return true
     }else {
       return false
     }
  }

  selectAll() {
    const transactions = this.state.transactions;

    this.setState({
      transactions: transactions.map(transaction => ({ ...transaction, isSelected: true }))
    },() => {this.calculateExpenses(this.state.transactions)});

  }

  unselectAll() {
    const transactions = this.state.transactions;

    this.setState({
      transactions: transactions.map(transaction => ({ ...transaction, isSelected: false }))
    }, () => {this.calculateExpenses(this.state.transactions)});
  }

  submitTransaction() {
    let transactions = this.state.transactions;
    const updatedTransaction = [];
      transactions.map((t) => {
          if(t.isSelected){
            const obj =    
             {
              "activityDateTime": t.activityDateTime,
              "merchantName": t.merchantName,
              "transactionId": t.transactionId,
              "cost": t.cost,
              "fuelGallons": t.fuelGallons,
              "expenseId": t.expenseId,
              "type": t.type
            }
          updatedTransaction.push(obj);
          }
      })
      const presentMonth = dateUtility.currentMonthAsTwoDigits();
      const previousMonth = dateUtility.previousDate();
      let month = null;
      let year = null;
      if(this.props.month == 'current' ){
        month = presentMonth;
        year  = dateUtility.currentYear();
      }else {
        month = dateUtility.getMonthFromDate(previousMonth);
        year = dateUtility.getYearFromDate(previousMonth);
      }
      const current = this.props.month == 'current' ? true : false
      this.props.submitTransactions(updatedTransaction,month,year,current);
  }

  openAddExpenses() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "add expense"
        }
    });

    analyticsService.analyticsProcessEvent(
      {
        "event": "modal_view_generic",
        "context": {
          "event_action": "trip recording/add expense modal"
          }
      });
    this.setState({ addExpensesActive: true })
  }

  saveTransaction(Transaction) {
    const transactions = this.state.transactions;
      transactions.push(Transaction);
      transactions.sort(function(a,b){
        return new Date(b.activityDateTime) - new Date(a.activityDateTime);
       });
      this.setState({transactions},() => {this.calculateExpenses(this.state.transactions)})
  }

  calculatelastUpdate() {
    const dates = this.state.transactions.map((t) =>{
    if(t.updateTimestamp !== null)  {
      return t.updateTimestamp
    }
    });
    const lastUpdated = mostRecent(dates);
    this.setState({lastUpdated})
  }

  render() {
    // if (!this.state.transactions.length) {
    //   return null;
    // }
    const { month, beforeEleventh, approved } = this.props;
    const expenses = this.state.expenses;

    const fuelCost = currencyFormatter.formatUSD(expenses.fuelCost);
    const others = currencyFormatter.formatUSD(expenses.others);
    const allSelected = this.state.transactions.every(transaction => transaction.isSelected);
    const lastUpdated = dateUtility.formatToLocal(this.state.lastUpdated)
    
    return (
      <div className="expenses" key={month}>
        <div className="update">
          <div className="totals">Monthly Totals</div>
          <div className="last-updated">Last Updated {lastUpdated}</div>
        </div>
        <div className="stats">
          <div className="fuel-expense">
            <img src={FuelPump} alt="fuel expense" />
            <div className="column">
              <div className="label">Fuel Expense</div>
              <div className="data">{fuelCost}</div>
            </div>
          </div>
          <div className="fuel-gallons">
            <img src={FuelGauge} alt="fuel gallons" />
            <div className="column">
              <div className="label">Fuel Gallons</div>
              <div className="data">{expenses.fuelGallons || 0}</div>
            </div>
          </div>
          <div className="other-expenses">
            <img src={Money} alt="other expenses" />
            <div className="column">
              <div className="label">Other Expenses</div>
              <div className="data">{others}</div>
            </div>
          </div>
        </div>
        <div className="transactionsContainer">
          <div className="header">
            <span className="heading">All Expenses</span>
            {
              this.state.transactions.length ?
                <span className="expenseCount">{this.state.transactions.length}</span>
                :
                null
            }
          </div>
          {this.state.transactions.length > 0 ?
            <div className="etable">
              <div className="etable-body header-labels">
                <div className="etable-row">
                  <div className="etable-header wexheader  select-all wexSelectAll">
                    <button
                      className={`selected ${allSelected ? 'chosen' : ''}`}
                      onClick={() =>
                        allSelected ? this.unselectAll() : this.selectAll()
                      }
                    >
                      {allSelected ? <Checked /> : <Unchecked />}
                    </button>
                  </div>
                  <div className="etable-header wexheader activityDate">ACTIVITY DATE</div>
                  <div className="etable-header wexheader vendorName">VENDOR</div>
                  <div className="etable-header wexheader gallons">GALLONS</div>
                  <div className="etable-header wexheader expenses">EXPENSES</div>
                </div>
              </div>
              {this.state.transactions.map((transaction) => {
                return (

                  <Transactions {...transaction}
                    toggleSelected={() => this.updateTransactionSelected(transaction)}
                  />

                )
              })
              }
            </div>

            : <div className="noTransactions">There are no open Transactions</div>
          }
        </div>

       {month === 'previous' ? (
        beforeEleventh && !approved ? (
          (
            <div className="addExpenses">
              <button className="addexpenseButton" onClick={() => this.openAddExpenses()}>
                <Plus />
                <span className="title" >ADD EXPENSE</span>
              </button>
              <AddExpensesPopup
                active={this.state.addExpensesActive}
                close={() => this.setState({ addExpensesActive: false })}
                {...this.props}
                saveTransaction={(transaction) => this.saveTransaction(transaction)}
                transactionsLength={this.state.transactions.length}
              />
            </div>
          )
        ) : null
      ) : 
      (
        <div className="addExpenses">
          <button className="addexpenseButton" onClick={() => this.openAddExpenses()}>
            <Plus />
            <span className="title" >ADD EXPENSE</span>
          </button>
          <AddExpensesPopup
            active={this.state.addExpensesActive}
            close={() => this.setState({ addExpensesActive: false })}
            {...this.props}
            saveTransaction={(transaction) => this.saveTransaction(transaction)}
            transactionsLength={this.state.transactions.length}
          />
        </div>
      )
    
    }

        {month === 'previous' ? (
          beforeEleventh && !approved ? (
            (this.state.transactions.length ?
              <div className="submitExpenses">
              <button className="submitexpenseButton"  disabled={!this.checkSelected()} onClick={() => this.submitTransaction()}>
                <span className="title" >Submit Expenses</span>
              </button>
              </div>
              :
              null )
          ) : null
        ) : 
        (this.state.transactions.length ?
          <div className="submitExpenses">
          <button className="submitexpenseButton"  disabled={!this.checkSelected()} onClick={() => this.submitTransaction()}>
            <span className="title" >Submit Expenses</span>
          </button>
          </div>
          :
          null )
      
      }

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    expenses: state.expenses.expenses,
    wextransaction: state.expenses.wexTransactions
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init(month, year, preMonth, preYear) {
      dispatch({ type: actionTypes.TRIP_EXPENSES_LOADING, data:{month:preMonth,year:preYear} });
      dispatch({ type: actionTypes.WEX_TRANSACTIONS_LOADING , month, year});
    },
    getWEXTransactions(month,year){
      dispatch({ type: actionTypes.WEX_TRANSACTIONS_LOADING , month, year});
    },
    addFuelExpense(current, payload) {
      dispatch({ type: actionTypes.ADD_FUEL_EXPENSE, current, payload });
    },
    subtractFuelExpense(current, payload) {
      dispatch({ type: actionTypes.SUBTRACT_FUEL_EXPENSE, current, payload });
    },
    addOthersExpense(current, payload) {
      dispatch({ type: actionTypes.ADD_OTHERS_EXPENSE, current, payload });
    },
    subtractOthersExpense(current, payload) {
      dispatch({ type: actionTypes.SUBTRACT_OTHERS_EXPENSE, current, payload });
    },
    displayError(data) {
      dispatch({ type: actionTypes.DISPLAY_ERROR, data: data });
    },
    submitTransactions(data,month,year, current) {
      dispatch({ type: actionTypes.SUBMIT_TRANSACTION, data, month, year, current });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Expenses);
