import React from 'react';
import StateDropDown from './StateDropDown';
import { AddressInput } from '../AddressInput';
import AddressUtility from '../addressUtility';

export default function SegmentedAddressInput({ update, valid, isValid, ...addr }) {

  let { line1, line2, city, state, zip } = addr;

    if (!isValid){
      line2 = "";
      city = "";
      state = "";
      zip = "";
    }

  return (
    <div className="address pad-desk">
      <div>
        <AddressInput
          placeholder="Address"
          className="street-number"
          value={line1}
          onChange={(addr,valid) => update(AddressUtility.fromGeocode(addr), valid)}
        />
        {line1 ? (
          null
        ) :
          <span className="fields-invalid streetInvalid">
            Street is required.
    </span>}
      </div>
      <input
        type="text"
        placeholder="Apt, Suite, Etc (optional)"
        className="apt"
        value={line2}
        maxLength={100}
        onChange={e => update({ ...addr, line2: e.target.value },valid)}
      />
      <div className="region">
        <div className="validationClass">
          <input
            type="text"
            placeholder="City"
            className={`city ${city ? '' : 'invalid'}`}
            value={city}
            maxLength={100}
            disabled={true}
            onChange={e => update({ ...addr, city: e.target.value},valid)}
          />
          {city ? (
            null
          ) :
            <span className="fields-invalid">
              City is required.
      </span>}
        </div>
        <StateDropDown
          value={state}
          disabled={true}
          onChange={e => update({ ...addr, state: e.target.value })}
        />
        <div className="validationClass">
          <input
            type="text"
            placeholder="Zipcode"
            className={`zip ${zip ? '' : 'invalid'}`}
            value={zip}
            disabled={true}
            onChange={e =>
              /^\d{0,5}$/.test(e.target.value) &&
              update({ ...addr, zip: e.target.value },valid)
            }
          />
          {zip ? (
            null
          ) :
            <span className="fields-invalid">
              Zipcode is required.
    </span>}
        </div>
      </div>
    </div>
  );
}
