import milesaved from '../../_styles/images/milesaved.png';
import coSaved from '../../_styles/images/coSaved.png';
import costSavings from '../../_styles/images/costSavings.png';
import fuelSaved from '../../_styles/images/fuelSaved.png';
import currencyFormatter from '../../shared/currencyFormatter';
import LetUsHelpYouSection from './let-us-help-you/LetUsHelpYouSection';
import EditCommuteDays from './edit-commute-days/EditCommuteDays';
import { numberWithCommas } from '../helpers/commonHelper';
import SponsoredVanpool from '../helpers/sponsoredVanpool';

export default function PersonalSavings(props) {
  const { milesSaved, fuelGallonsSaved, co2PoundsSaved, fuelCostSavings, isSubscriptionVanpool, charge, isOptimalMatch, editCommute, isUpdated, selectedDays, updatePDPopup, updateTDPopup, isSubsidized } = props;
  const fuelSavings = currencyFormatter.formatUSD(fuelCostSavings);

  return (
    <div className="vanpool-savings" role='group'>
      <div className="personal-savings">
        {"Personal Savings"} <span>{"per month"}</span>
      </div>
      <div className="savings">
        <ul role="none presentation">
          <li role="none presentation">
            <div className="start" role='text'>
              <div className="icon">
                <img src={milesaved} alt='' />
              </div>
              <div className="description">{"Miles Saved"}</div>
            </div>
            <div className="end" aria-label={milesSaved + ' miles'}>
              {numberWithCommas(milesSaved)} mi
            </div>
          </li>
          <li role="none presentation">
            <div className="start">
              <div className="icon">
                <img src={fuelSaved} alt='' />
              </div>
              <div className="description">{"Fuel Saved"}</div>
            </div>
            <div className="end" aria-label={fuelGallonsSaved + ' gallons'}>
              {numberWithCommas(fuelGallonsSaved)} gal
            </div>
          </li>
          <li role="none presentation">
            <div className="start">
              <div className="icon">
                <img src={coSaved} alt='' />
              </div>
              <div className="description">{"CO2 Saved"}</div>
            </div>
            <div className="end" aria-label={co2PoundsSaved + ' pounds'}>
              {numberWithCommas(co2PoundsSaved)} lbs
            </div>
          </li>
          <li role="none presentation">
            <div className="start">
              <div className="icon">
                <img src={costSavings} alt='' />
              </div>
              <div className="description">{"Cost Savings"}</div>
            </div>
            <div className="end">
              {numberWithCommas(fuelSavings)}
            </div>
          </li>
        </ul>
      </div>
      {(selectedDays.length || isUpdated) && <EditCommuteDays selectedDays={selectedDays} openEditCommuteModal={editCommute} />}
      <div className="booknow-container-mobile mobile">
        {isSubscriptionVanpool ? (
          <div className="booknow">
              <div className='disclaimer mobile' role='group'>
                <p>
                Recurring subscription. <br />
                Rates subject to change. <br />
                <span onClick={updatePDPopup}>Learn More</span>
                </p>
            </div>
            <div className="total">Total</div>
            <div className="van-amount">
              <div className='price-group'>
                <div className="doller">{'$'}</div>
                <div className="price">{numberWithCommas(charge.price)}</div>
                <div className="decimal">.{charge.decimal}</div>
              </div>
              <div className="month">{'per month'}</div>
            </div>
          </div>
        ) : (

           charge && (
                <div className="booknow traditional">
                  <div className='total-disclaimer'>
                    <div className="total">
                      Total
                      </div>
                    <div className='traditional-disclaimer'>
                      Estimate only. <span onClick={() => updateTDPopup(true)}> Learn More</span>
                    </div>
                  </div>
                <div className="van-amount" role='group'>
                  <div className='price-group'>
                    <div className="dollar">{'$'}</div>
                    <div className="price">{numberWithCommas(charge.price)}</div>
                    <div className="decimal">.{charge.decimal}</div>
                  </div>
                  <div className="month">{'per month'}</div>
                </div>
              </div>
              
              )
        
        
        )}
        {isSubsidized && <SponsoredVanpool />}
        <span className={isUpdated ? 'booknow-help-container updated': 'booknow-help-container'}>
        {
          !isOptimalMatch &&
          <LetUsHelpYouSection isSelected={selectedDays.length !== 0} goToStayInTouch={props.goToStayInTouch} isMobile={true} isTraditional={!isSubscriptionVanpool} />
        }
        {!isOptimalMatch && <div className='divider'></div>}
        {
          !isSubscriptionVanpool ?
            <button onClick={props.openCloseModal} className="check-now-button" >continue</button>
            :
            (isOptimalMatch || (!isOptimalMatch && isUpdated)) ?
              <button onClick={props.openCloseModal} className="book-now-button" >BOOK NOW</button> :
              <button onClick={editCommute} className={'edit-commute-button secondary'} >EDIT MY COMMUTE</button>

        }
        </span>
      </div>
      {
        isSubscriptionVanpool && 
        <div className='disclaimer tablet' role='group'>
          <p>
          Recurring subscription. 
          Rates subject to change.<span>&nbsp;</span>
          <span onClick={updatePDPopup}>Learn More</span>
          </p>
      </div>
      }

    </div>
  )
}

