import React from 'react';
import logo from '../commute_logo_full.svg';
import azureService from './azureService';

export default function AlreadyRegistered() {
  return (
    <div className="login-error">
      <img src={logo} alt="Logo" className="branding" />
      <p>
      You are already registered, please login to continue
      </p>

      <button className="button primary" onClick={() => azureService.signIn()}>
        Login In
      </button>
    </div>
  );
}
