import Collapsible from 'react-collapsible';

export default function JoinCommuteCollapsible(props) {
  function JoinCommuteHeading(accordionInfo) {
    var tempClass = 'arrow ';
    tempClass += accordionInfo.isOpen ? 'up ' : 'down ';
    tempClass += props.isLocked ? 'locked' : 'unlocked';
    return (
      <div className='collapsible-heading-container' role='button' tabIndex={0}>
        <h1 className='heading'>{`${accordionInfo.heading}`}</h1>
        <i className={tempClass}></i>
      </div>
    );
  }
  return (
    <Collapsible aria-expanded={props.accordionInfo.isOpen} open={props.accordionInfo.isOpen} className='collapsible-container' handleTriggerClick={() => {props.accordionClicked(props.accordionInfo.id)}} trigger={JoinCommuteHeading(props.accordionInfo)}>
      <div>{props.component}</div>
    </Collapsible>
  );
}
