import React, { useState } from 'react';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';
import Tooltip from '../../shared/Tooltip';
import ChevronUp from '../../_styles/images/new-icons/chevron-up.png';
import Steering from '../../_styles/images/Steering.png';
import Seat from '../../_styles/images/GreenSeat.png';
import { makeDecimal2numbers } from '../helper';

const RateAccordion = ({ isDesiredDriverRole, rateMessage, charge, decimal }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='rates-accordion'>
     <div className="accordion-wrapper">
  <button className={`accordion ${isOpen ? 'active' : ''}`} onClick={toggleAccordion}>
    <div className='description'>
      <img src={ChevronUp} alt="Caret" className={`caret ${isOpen ? '' : 'rotate'}`} />
      <img
        src={isDesiredDriverRole ? Steering : Seat}
        className='icon'
        alt={'seat image'}
        aria-hidden="true"
      />
      <span className='rate-desc'>{isDesiredDriverRole ? 'Driver' : 'Rider'} rate </span>
    </div>
    {charge && (
      <div className='price' aria-label={`${charge} dollars`}>
        <div className='dollar'>$</div>
        <div>{charge && numberWithCommas(makeDecimal2numbers(charge))}</div>
      </div>
    )}
  </button>
    {rateMessage && <Tooltip tooltipLabel='rate' message={rateMessage} newIcon={true} />}
</div>
      <div className={isOpen ? 'panel active' : 'panel'}>
          <div className='tax-description' role='group'>
              <div className='description'>Fuel</div>
              <div className='amount'>
                <div>Included</div>
              </div>
            </div>
            <div className='tax-description' role='group'>
              <div className='description'>Maintenance</div>
              <div className='amount'>
                <div>Included</div>
              </div>
            </div>
            <div className='tax-description' role='group'>
              <div className='description'>Roadside Assistance</div>
              <div className='amount'>
                <div>Included</div>
              </div>
            </div>
            <div className='tax-description' role='group'>
              <div className='description'>Insurance</div>
              <div className='amount'>
                <div>Included</div>
              </div>
            </div>
      </div>
    </div>
  );
};

export default RateAccordion;
