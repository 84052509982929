import ErrorCircle from '../../_styles/images/error-circle.svg';
export default function CardMessage(props) {
  return (
    <div className='max-cards-message'>
      <div className='warning-icon'>
      <img className='tick-icon' src={ErrorCircle} alt="selected" />
      </div>
      <div className='warning-text'>{props.message}</div>
    </div>
  );
}