import Steering from '../../../_styles/images/Steering.png';
import TickIcon from '../../../_styles/images/tick-badge.svg';
import { numberWithCommas } from '../../../join-commute/helpers/commonHelper';
import seat from '../../../_styles/images/GreenSeat.png';

export default function PlanComponent(props) {

  function optionKeydown(e, index) {
    if (e.which === 13) {
      props.updateSelectedOption(index)
    }
  }

  const plan = props.rateObject.packageName == 'Unlimited' ? 'Unlimited Plan' : `${props.rateObject.packageName.charAt(0)} - Day Plan`;
  return (
    <div className="price-comp" role='group' key={props.index}>
      <span className='visually-hidden'>plan {props.index + 1}</span>
      <div className="packages">
        <div className="select-heading" aria-hidden='true'>
          {plan}
        </div>
        <div className="role-title" aria-hidden='true'>
          <img src={props.isRiderRate ? seat : Steering}></img>
          <div className="role">
            {props.isRiderRate ? 'Rider' : 'Driver'}
          </div>
        </div>
      </div>

      <div
        className={
          props.index === props.selectedOption
            ? 'price-component selected'
            : 'price-component'
        } //""
        role="button"
        tabIndex={0}
        onKeyDown={e => optionKeydown(e, props.index)}
        onClick={() => props.updateSelectedOption(props.index)}
      >
        <span className='visually-hidden'>{plan} plan</span>
        {props.index === props.selectedOption && (
          <div className="tick-icon-container-Change-subscription">
            <img
              className="tick-icon"
              src={TickIcon}
              alt="selected"
            />
          </div>
        )}
        <div className="sub-heading">
          {props.isRiderRate ? 'Rider rate' : props.isDriver ? 'Driver rate' : 'Willing to drive?'}
        </div>
        <div
          className="price-group"
          aria-label={`${props.priceObject.price} dollars and ${props.priceObject.decimal} cents total per month`}
        >
          <div className="doller">{'$'}</div>
          <div className="price">{numberWithCommas(props.priceObject.price)}</div>
          <div className="decimal">.{props.priceObject.decimal}</div>
          <div className="frequency">total per month</div>
        </div>
      </div>
      <div className="footer-text">
      *All prices include fuel, maintenance, roadside assistance, insurance, applicable taxes and fees.
      </div>
      <hr></hr>
    </div>
  );
}