import React from 'react';
import { Pencil } from '../../shared/icons';

export default function Section({ Icon, title, children, isEditable, edit }) {
  return (
    <div className="section">
      <div className="head">
        <h3>{title}</h3>
        {isEditable ? (
          <button className="edit" onClick={edit} aria-label={`Edit ${title}`}>
            <Pencil />
          </button>
        ) : null}
      </div>
      <div className="body">{children}</div>
    </div>
  );
}

export function Entry({ label, children, className }) {
  return (
    <div className="entry">
      <div className="label">{label}</div>
      <div className={`value ${className || ''}`.trim()}>{children}</div>
    </div>
  );
}
