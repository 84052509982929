import React from 'react';
import { Line } from 'react-chartjs-2';
import chartService, {
  standardLineOptions,
  percentageLineOptions,
  legendOptions,
  maxStep,
  GRID_TYPE_PERCENTAGE
} from './chart-service';

export default function StandardLineGraph({
  gridData,
  displayType,
  useCustomTooltip
}) {
  let options =
    displayType && displayType === GRID_TYPE_PERCENTAGE
      ? percentageLineOptions
      : standardLineOptions;

  if (useCustomTooltip) {
    options.tooltips = Object.assign({}, options.tooltips, customTooltip);
  }

  options.scales.yAxes[0].ticks.suggestedMax = maxStep(gridData.datasets);

  return (
    <Line
      height={100}
      data={chartService.formatVanpoolLineChart(gridData)}
      legend={legendOptions}
      options={options}
    />
  );
}

function chartJSTooltip() {
  let tooltipEl = document.getElementById('chartjs-tooltip');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = 'chartjs-tooltip';
    tooltipEl.innerHTML = '';
    document.body.appendChild(tooltipEl);
  }

  return tooltipEl;
}

export const customTooltip = {
  enabled: false,
  callbacks: {
    title: function(tooltipItem) {
      return tooltipItem[0].xLabel;
    },
    label: function(tooltipItem, data) {
      const index = tooltipItem.index;
      const total = data.datasets.find(
        dataset => dataset.label === 'Total'
      ) || { data: [0] };

      return data['datasets'].map(dataset => {
        if (dataset.label === 'Potential' || dataset.label === 'Goal') {
          let percent =
            Math.floor(((total.data[index] || 0) / dataset.data[index]) * 100) +
            '%';
          if (percent === 'Infinity%' || percent === 'NaN%') {
            percent = '0%';
          }
          return [
            dataset.label,
            (dataset.data[index] || 0).toLocaleString(),
            percent
          ];
        }

        return [dataset.label, (dataset.data[index] || 0).toLocaleString()];
      });
    }
  },
  custom(tooltipModel) {
    const tooltip = chartJSTooltip();

    if (!tooltipModel.opacity) {
      tooltip.style.opacity = 0;
      return;
    }

    tooltip.classList.remove('above', 'below', 'no-transform');
    tooltip.classList.add(tooltipModel.yAlign || 'no-transform');

    if (tooltipModel.body) {
      const titles = tooltipModel.title || [];
      const data = tooltipModel.body.map(item => item.lines);

      tooltip.innerHTML = `
            <div class="custom-chart-js-tooltip">
                ${titles.map(title => `<div class="title">${title}</div>`)}
                ${data[0]
                  .map(
                    item => `<div class="item">
                    <div class="label">${item[0]}</div>
                    <div class="values">
                        ${item
                          .slice(1)
                          .map(data => `<div class="value">${data}</div>`)
                          .join('')}
                    </div>
                </div>`
                  )
                  .join('')}
            </div>
            `;
    }

    const position = this._chart.canvas.getBoundingClientRect();
    const left = position.left + window.pageXOffset + tooltipModel.caretX;
    const top = position.top + window.pageYOffset + tooltipModel.caretY;

    Object.assign(tooltip.style, {
      opacity: 1,
      position: 'absolute',
      left: `${left}px`,
      top: `${top}px`
    });
  }
};
