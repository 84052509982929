import * as actionTypes from '../redux/actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';
import dateUtility from '../shared/dateUtility';
import paymentProcessor from '../shared/credit-card/paymentProcessor';
import analyticsService from '../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(actionTypes.PAYMENT_METHODS_LOADING, loadPaymentData);
  yield takeLatest(actionTypes.PAYMENT_DETAILS_LOADING, loadPaymentDetails);
  yield takeLatest(actionTypes.LAST_PAYMENT_LOADING, loadLastPayment);
  yield takeLatest(actionTypes.ONE_TIME_PAYMENT_POSTING, submitOneTimePayment);
  yield takeLatest(
    actionTypes.RECURRING_PAYMENT_POSTING,
    submitRecurringPayment
  );
  yield takeLatest(actionTypes.ADD_PAYMENT_METHOD_POSTING, addPayment);
  yield takeLatest(actionTypes.UPDATE_PAYMENT_PUTTING, updatePayment);
  yield takeLatest(actionTypes.DELETE_PAYMENT_METHOD, deletePayment);
}


function* loadPaymentDetails(action) {
  try {
    const paymentMethodsUrl = `${config.kongApiBaseUrl}/participant/profile/payment/methods`;
    let response = yield call(() => Http.get(paymentMethodsUrl));
    yield put({
      type: actionTypes.PAYMENT_METHODS_LOADED,
      data: {
        cards: (response.storedPaymentMethods || []).map(method => ({
          ...method,
          next: dateUtility.parseDateAsMonthDayYear(
            method.nextRecurringChargeDate
          )
        }))
      }
    });
    if(action && action.toastMessage) {
      yield put({type: actionTypes.SHOW_TOAST, data: action.toastMessage});
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.PAYMENT_METHODS_ERROR });
  }
}

function* loadPaymentData(action) {
  try {
    const paymentMethodsUrl = `${config.kongApiBaseUrl}/participant/profile/payment/methods`;
    let response = yield call(() => Http.get(paymentMethodsUrl));
    yield put({
      type: actionTypes.PAYMENT_METHODS_LOADED,
      data: {
        cards: (response.storedPaymentMethods || []).map(method => ({
          id: method.referenceId,
          deletable: !method.isLinkedToRecurringPayment,
          brand: method.cardBrand,
          account: '*' + method.partialAccountNumber,
          exp: method.expirationMMYY.replace(/^\d\d/, '$&/'),
          next: dateUtility.parseDateAsMonthDayYear(
            method.nextRecurringChargeDate
          ),
          every: dateUtility.parseDayAsOrdinal(method.nextRecurringChargeDate)
        }))
      }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.PAYMENT_METHODS_ERROR });
  }
}

function* loadLastPayment(action) {
  try {
    const lastPaymentUrl = `${config.kongApiBaseUrl}/participant/profile/payments/last`;
    let response = yield call(() => Http.get(lastPaymentUrl));

    yield put({
      type: actionTypes.LAST_PAYMENT_LOADED,
      data: response
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.LAST_PAYMENT_ERROR });
  }
}

function* updatePayment(action) {
  try {
    const updatePaymentUrl = `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`;

    let putData = {
      cvc: action.data.cvc,
      expirationMMYY: action.data.expirationMMYY,
      postalCode: action.data.postalCode,
      storedMethodReference: action.data.id
    };

    let response = yield call(() => Http.put(updatePaymentUrl, putData));

    if (!response.isCardSavedSuccessful) {
      yield put({
        type: actionTypes.DISPLAY_ERROR,
        data: 'Unable to update payment.'
      });
    } else {
      yield put({ type: actionTypes.PAYMENT_METHODS_LOADING });
    }
  } catch (ex) {
    logger.log(ex);

    yield put({
      type: actionTypes.DISPLAY_ERROR,
      data: 'Unable to update payment.'
    });
  }
}

function* addPayment(action) {
  try {
    const addPaymentUrl = `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`;
    let processorResponse = yield paymentProcessor.addCard(action.data);

    let response = yield call(() =>
      Http.post(addPaymentUrl, processorResponse)
    );

    yield put({
      type: actionTypes.ADD_PAYMENT_METHOD_RESPONSE,
      data: { success: response.isCardSavedSuccessful }
    });

    yield put({ type: actionTypes.PAYMENT_METHODS_LOADING });
  } catch (ex) {
    logger.log(ex);

    yield put({
      type: actionTypes.ADD_PAYMENT_METHOD_RESPONSE,
      data: { success: false }
    });
  }
}

function* deletePayment(action) {
  try {
    yield call(() =>
      Http.delete(
        `${config.kongApiBaseUrl}/participant/profile/payment/methods/method`,
        { storedMethodReference: action.data }
      )
    );
    yield put({ type: actionTypes.DELETE_PAYMENT_RESPONSE, data: action.data });
  } catch (ex) {
    logger.log(ex);
  }
}

function* submitOneTimePayment(action) {
  const submitPaymentUrl = `${config.kongApiBaseUrl}/participant/profile/payment`;

  try {
    let { isPaymentSuccessful: success, ...details } = yield call(() =>
      Http.post(submitPaymentUrl, action.data)
    );

    if (success) {
      logger.serverLog({
        url: submitPaymentUrl,
        data: action.data,
        message: 'payment completes',
        error: null
      });
      analyticsService.analyticsEvent({
        type: 'Payment Completes',
        data: {
          payment: {
            type: 'one-time payment made',
            amount: `${action.data.paymentAmount}`,
            currencyCode: 'USD'
          }
        }
      });
    }

    yield put({
      type: actionTypes.ONE_TIME_PAYMENT_RESPONSE,
      data: { success, ...details, isDeclined: !success }
    });
  } catch (ex) {
    logger.log(ex);
    logger.serverLog({
      url: submitPaymentUrl,
      data: action.data,
      message: 'one-time payment error',
      error: ex.stack
    });

    yield put({
      type: actionTypes.ONE_TIME_PAYMENT_RESPONSE,
      data: { success: false, isDeclined: false }
    });
  }
}

function* submitRecurringPayment(action) {
  try {
    const isNewPayment = !!(
      action.data.storedMethodReference || action.data.tokenId
    );

    if (action.data.storedMethodReference) {
      delete action.data.cvc;
    }

    let response = yield call(() =>
      isNewPayment
        ? createRecurringPayment(action.data)
        : modifyRecurringPayment(action.data)
    );

    if (response.success) {
      logger.serverLog({
        url: action.data.url,
        data: action.data,
        message: 'recurring payment submitted',
        error: null
      });
      analyticsService.analyticsEvent({
        type: 'Payment Completes',
        data: {
          payment: {
            type: 'recurring payment made',
            amount: `${action.data.paymentAmount}`,
            currencyCode: 'USD'
          }
        }
      });
    }

    yield put({
      type: actionTypes.RECURRING_PAYMENT_RESPONSE,
      data: { success: response.success }
    });
  } catch (ex) {
    logger.log(ex);
    logger.serverLog({
      url: action.data.url,
      data: action.data,
      message: 'recurring payment error',
      error: ex.stack
    });
    yield put({
      type: actionTypes.RECURRING_PAYMENT_RESPONSE,
      data: { success: false, isDeclined: false }
    });
  }
}

function createRecurringPayment(payload) {
  return Http.post(
    `${config.kongApiBaseUrl}/participant/profile/payment/recurring`,
    payload
  );
}

function modifyRecurringPayment(payload) {
  return Http.put(
    `${config.kongApiBaseUrl}/participant/profile/payment/recurring/${payload.recurringPaymentId}`,
    payload
  );
}
