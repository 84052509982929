const aggregateService = {};

aggregateService.getAggregateIndex = function(
  currentAggregate,
  currentCount = 0
) {
  if (currentAggregate.parent) {
    return this.getAggregateIndex(currentAggregate.parent, currentCount + 1);
  } else {
    return currentCount;
  }
};

aggregateService.getRootAggregate = function(currentAggregate) {
  if (currentAggregate.parent) {
    return this.getRootAggregate(currentAggregate.parent);
  } else {
    return currentAggregate;
  }
};

aggregateService.getEmployer = function(
  currentAggregate,
  aggregateIndex,
  isTransit
) {
  if (aggregateIndex === 0 || isTransit) {
    return;
  } else if (aggregateIndex === 1) {
    return currentAggregate;
  } else {
    return this.getEmployer(currentAggregate.parent, --aggregateIndex);
  }
};

aggregateService.getWorksite = function(
  currentAggregate,
  aggregateIndex,
  isTransit
) {
  if (aggregateIndex <= 1 || isTransit) {
    return;
  } else if (aggregateIndex === 2) {
    return currentAggregate;
  } else {
    return this.getWorksite(currentAggregate.parent, --aggregateIndex);
  }
};

aggregateService.getVanpool = function(
  currentAggregate,
  aggregateIndex,
  isTransit
) {
  if (isTransit && aggregateIndex === 1) {
    return currentAggregate;
  } else if (aggregateIndex <= 2) {
    return;
  } else {
    return currentAggregate;
  }
};

export default aggregateService;
