import {getRandomColor} from '../../helpers/commonHelper'
import { useMemo } from 'react';

export function TextImage({ text, color, tooltip }){
  const bgColor = useMemo(() => color || getRandomColor(), [color]);

  return(
    <div className='image-container' title={tooltip || ''} style={{"background": bgColor}} aria-hidden='true'>
      <div className='vanpooler-text'>
      {text}
      </div>
    </div>
  );
}