import { useEffect, useRef } from "react";
import Close from '../../../_styles/images/close-icon-green.svg';
import analyticsService from "../../../shared/analytics/analytics-service";

export function QRModal(props) {
    const modalRef = useRef();

    useEffect(() => {
        modalRef.current.focus();
        if(props.content && props.content.type === 'apple'){
            analyticsService.analyticsProcessEvent({
                "event": "modal_view_generic",
                "context": {
                    "event_action": "dashboard/ios app modal"
                }
            });
        } else if(props.content && props.content.type === 'android'){
            analyticsService.analyticsProcessEvent({
                "event": "modal_view_generic",
                "context": {
                    "event_action": "dashboard/android app modal"
                }
            });
        }
    }, []);

    function handleCloseKeyDown(e) {
        if (e.key === 'Enter' || e.key === ' ') {
            props.updateIsOpen(false);
        }
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 9) {
                const closeButton = document.querySelector('.save-button.close');
                if (closeButton && closeButton.isSameNode(e.target)) {
                    e.preventDefault();
                    document.querySelector('#download-app-popup .close').focus();
                }
            }
            else if (e.keyCode === 27) { // Esc key
                props.updateIsOpen(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="download-app-popup show" id='download-app-popup' aria-live='polite' role='alert' aria-modal='true' ref={modalRef}>
            <img role='button' className="close" src={Close} onKeyDown={handleCloseKeyDown} onClick={() => props.updateIsOpen(false)} alt='close download popup' tabIndex={0} />
            <div className="download-app-popup-container">
                <div className="header-content">
                    <div className='header'>To download</div>
                </div>
                <div className='download-app-popup-content'>
                    <div className='header'>Scan this code using your phone's camera app or a QR scanner app.</div>
                    <img src={props.content.qr} className='qr' alt='QR code'></img>
                    <img src={props.content.icon} className='appstore' aria-hidden='true'></img>
                    <span className='content question'>Having trouble scanning?</span>
                    <span className='content text'>Visit your phone's app store and search for the 'Commute with Enterprise' app</span>
                    <button className='save-button close' onKeyDown={handleCloseKeyDown} onClick={() => props.updateIsOpen(false)} >Close</button>
                </div>
            </div>
        </div>
    );
}