import React, { Component } from 'react';
import { StopWatch } from '../../../shared/icons';

function Render({ index, lastStopIndex, waitTime, updateWaitTime, changed }) {
  return (
    <div className="wait-time-container">
      {index !== lastStopIndex && index !== 0 ? (
        <div className="wait-time">
          <div className={`select ${changed ? 'changed' : ''}`}>
            <StopWatch />
            <select
              value={waitTime}
              id={`wait-time-${index}`}
              onChange={e =>
                updateWaitTime(index, parseInt(e.target.value, 10))
              }
            >
              <option value={0}>No wait</option>
              <option value={5}>5 min</option>
              <option value={10}>10 min</option>
              <option value={15}>15 min</option>
              <option value={30}>30 min</option>
              <option value={45}>45 min</option>
              <option value={60}>1 hour</option>
            </select>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default class WaitTimeEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { waitTime: props.waitTime, original: props.waitTime };
  }

  componentDidMount() {
    this.setState({
      waitTime: this.props.waitTime,
      original: this.props.waitTime
    });
  }

  componentWillReceiveProps(props) {
    this.setState({ waitTime: props.waitTime });
  }

  render() {
    return (
      <Render
        {...this.props}
        changed={this.state.original !== this.state.waitTime}
      />
    );
  }
}
