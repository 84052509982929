import { getTextForImage, getVanpoolerName } from '../../helpers/vanHelpers';
import { TextImage } from '../text-image/TextImage';
import analyticsService from '../../../shared/analytics/analytics-service';
const subscriptionRoles = ["coordinator","driver"];

export function Vanpooler(props) {
  const { participant, isSubscription } = props;
  const { firstName, lastName, profileImage, employer } = participant || {};
  
  // Extract and format the roles
  const roles = participant?.roles 
    ? Object.keys(participant.roles).filter(key => participant.roles[key]) 
    : [];
  
  const showRole = roles.length > 0;
  var filteredRoles = isSubscription ? roles.filter((r => subscriptionRoles.includes(r))) : roles

  // editiong this since it was giving error in test file
  // Function to format roles (camelCase -> space-separated and joined with " / ")
  const formattedRoles = filteredRoles
    .map(role => role.replace(/([a-z])([A-Z])/g, '$1 $2')) // Add space between camelCase words
    .join(' / '); // Join roles with " / "

  function openDetails(participant) {
    if (props.fromRoster) {
      analyticsService.analyticsProcessEvent({
        "event": "roster_profile_click",
        "context": {
          "event_action": "roster profile click"
        }
      });
      props.showParticipantDetails(participant);
    }
  }

  function onTabKeyDown(participant, e) {
    if (e.which === 13) {
      openDetails(participant);
    }
  }

  return (
    <div 
      className='vanpooler-container' 
      role='button' 
      tabIndex={0} 
      aria-label={`View details of ${getVanpoolerName(firstName, lastName)}`} 
      onClick={() => openDetails(participant)} 
      onKeyDown={(e) => onTabKeyDown(participant, e)}
    >
      <div className='image' aria-hidden='true'>
        {profileImage ? (
          <img src={profileImage} alt={`${firstName} ${lastName}`} />
        ) : (
          <TextImage text={getTextForImage(firstName, lastName)} />
        )}
      </div>
      <div className='name'>{getVanpoolerName(firstName, lastName)}</div>
      <div className='company-role'>
        {showRole && <div className='type'>{formattedRoles}</div>}
        <div className='company'>{employer}</div>
      </div>
    </div>
  );
}
