import React, { useEffect } from 'react';
import analyticsService from './analytics/analytics-service';

function hash(children) {
  return children.split('').reduce((sum, c) => c.charCodeAt() + sum, 0);
}

export default function ProfileImage({profileImage, children = '' }) {
  function analyticsEvent(){
    analyticsService.analyticsProcessEvent({
      "event": "click_nav",
      "context": {
        "event_action": "navigation:profile menu"
      }
    });
  }

  return (
    <div key={hash(children)} className= { `generated-user-image ${profileImage ? "withImage"  : "" }`}>
      {
        profileImage ?
        <img alt='profile image' src={profileImage} onClick={analyticsEvent}></img>
        :
        <span aria-label={"user initials"} onClick={analyticsEvent}>{children
          .split(' ')
          .map(s => s[0])
          .join('')}</span>
        
      }
    </div>
  );
}
