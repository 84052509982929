import React, { Component } from 'react';
import DaysEditor from './DaysEditor';
import DaysViewer from './DaysViewer';

class RouteDays extends Component {
  constructor(props) {
    super(props);
    this.state = { editing: false };
  }

  toggleMyEdit() {
    this.props.toggleEdit();
    this.setState({ editing: !this.state.editing });
  }

  save(days) {
    this.props.onChange(days);
    this.toggleMyEdit();
  }

  render() {
    const { days, participantDays, editable, isSubscription } = this.props;
    
    return this.state.editing ? (
      <DaysEditor
        days={days}
        participantDays={participantDays}
        save={days => this.save(days)}
        cancel={() => this.toggleMyEdit()}
      />
    ) : (
      <DaysViewer
        days={days}
        edit={() => this.toggleMyEdit()}
        editable={editable}
        isSubscription={isSubscription}
      />
    );
  }
}

export default RouteDays;
