import * as actionTypes from '../redux/actionTypes';
import NA_VALUE from '../shared/na';

const initialState = {
  commute: {
    employer: '',
    activeDays: [],
    arriveAtWork: NA_VALUE,
    departFromWork: NA_VALUE,
    homeAddress: NA_VALUE,
    workAddress: NA_VALUE,
    mode: NA_VALUE,
    flexible: false
  },
  worksites: [],
  modes: [],
  marketingOptions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.COMMUTE_PROFILE_LOADING:
      return { ...state, loading: true };
    case actionTypes.COMMUTE_PROFILE_LOADED:
      return { ...state, ...action.data, loading: false };
    case actionTypes.TRANSPORTATION_MODES_LOADED:
      return { ...state, modes: action.data };
    case actionTypes.PARTNER_WORKSITES_LOADED:
      return { ...state, worksites: action.data };
    case actionTypes.MARKETING_OPTIONS_LOADED:
      return { ...state, marketingOptions: action.data };
    default:
      return state;
  }
};
