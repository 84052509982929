import Dropdown from "../join-commute/payment-screen/dropdown/Dropdown";
import analyticsService from "../shared/analytics/analytics-service";
export const editSchedulePreferencesOptions = ['View Driver Schedule', 'Change My Assigned Days', 'Edit Schedule Preferences'];

export default function EditSchedulePreferencesDropdown(props) {

    function updateOption(val){
      if(val){
        analyticsService.analyticsProcessEvent(
          {
            "event": "click_generic",
            "context": {
              "event_action": `${val.toLowerCase()}`
            }
          })
        props.updateIndex(editSchedulePreferencesOptions.indexOf(val));
      }
    }
    return (
        <div className="preference-dropdown-container" role='group'>
            <h2 className="preference-dropdown-heading">Edit Schedule and Preferences</h2>
            <div className="preference-dropdown-picker">
                <div className='field'>
                <Dropdown
                  name="I want to"
                  label="I want to"
                  onChange={(val) => updateOption(val)}
                  options={editSchedulePreferencesOptions}
                  value={editSchedulePreferencesOptions[props.indexSelected]}
                />
              </div>
            </div>

        </div>
    );
}