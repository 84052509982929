import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import DriverInformation from './DriverInformation';
import DocusignPopup from '../../shared/docusign/DocusignPopup';
import PersonalInformation from '../PersonalInformation';
import { Link } from 'react-router-dom';
import Callable from '../../shared/Callable';
import ContractAgreement from '../../contracts/contractAgreement';
import CoordinatorConfirmationPopup from '../ThankyouPopup';
import dateUtility from '../../shared/dateUtility';

export class DriverApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      birthDate: '',
      homeAddress: null,
      mailingAddress: null,
      phone: null,
      secondPhone: null,
      personalInformationIsValid: false,
      militaryLicense: null,
      licenses: [],
      driverInformationIsValid: false,
      continueClicked: false,
      isSubscription: this.props.profile.isSubscription
    };

    this.update = updates => this.setState(updates);
  }

  componentDidMount() {
    this.props.init();
  }


  save() {
    if(this.state.isSubscription){
      let isDriver =  true;
      let isPendingApplication = true;
      this.setState({ loading: true });
      this.props.getDocusignContract(isDriver, this.props.profile.vanpoolId, this.props.history, "/driver-application", isPendingApplication);
    }else{
      this.setState({ loading: true });
      this.props.save(this.state);
    }
  }

  pendingNotification() {
    return (
      <div className="pending-notification">
        <h2>Driver Application</h2>
        <p>
          Thank you for volunteering to be a Driver of your commute. Please
          complete and sign the Driver Application. Should you have any
          questions, contact us at <Callable phone="800-VAN-4-WORK" />
        </p>
        <div className="tools">
          <button
          id="continue"
          className="button primary"
          onClick={() => {
            this.setState({ continueClicked: true });
          }}
        >
          Continue
        </button>
        </div>
      </div>
    );
  }

  submitDriverApplication = () => {
    let phones = [];
    let primaryPhone = this.state.phone;
    if(primaryPhone){
      primaryPhone.isPreferred = true;
    }
    phones.push(primaryPhone);
    let secondaryPhone = this.state.secondPhone;
    if(secondaryPhone && secondaryPhone.number ) {
        secondaryPhone.isPreferred = false;
      phones.push(this.state.secondPhone)
    }
    const payload = {
      dateOfBirth: dateUtility.parseDateYYYY(this.state.birthDate),
      firstName: this.props.profile.firstName,
      lastName: this.props.profile.lastName,
      homeAddress: this.state.homeAddress,
      isCoordinatorApplication: false,
      isDriverApplication: true,
      phones,
      licenses: this.state.licenses.map((license, i) => ({
        ...license,
        isLatestLicense: i === 0
      })),
      isActiveMilitary: this.state.militaryLicense ? true : false,
      isForeignLicense: this.state.foreignLicense
    }
    const application = 'driver';
    this.setState({ loading: true });
    this.props.SubmitDriverApplication(payload, this.props.history, '/dashboard', application);
    this.props.saveAgreementUrl({ isPendingApplication: false, agreementURL: null, agreementId: this.props.contractInfo.agreementId, clickwrapId: this.props.contractInfo.clickwrapId, isAgreed: this.props.contractInfo.isAgreed })
  }


  continue(){
    this.props.refreshDashboard();
    this.props.closeConfirmation();
    this.props.history.push('/dashboard')
}

  render() {
    const isPendingDriver = this.props.profile.isPendingDriver;
    const isSubscription = this.props.profile.isSubscription;
    const {
      personalInformationIsValid,
      driverInformationIsValid,
      loading
    } = this.state;

    if (this.props.contractInfo.agreementURL && !this.props.contractInfo.isAgreed) {
      return (
        <ContractAgreement fromDriverApplication={true} loading={loading} contractInfo={this.props.contractInfo} history={this.props.history} agreementURL={this.props.contractInfo.agreementURL} saveAgreementDetails={this.props.saveAgreementUrl} navigateTo={"/dashboard"} updateLoading={() => this.updateLoading} updateContractStatus={(data) => {}} isPendingApplication={true} />
      )
    }

    if (isPendingDriver && this.props.contractInfo.agreementId && this.props.contractInfo.isAgreed && this.props.contractInfo.isPendingApplication) {
        this.submitDriverApplication();
    }

    return (
      <div>
        {!this.state.continueClicked && isPendingDriver ? (
          this.pendingNotification()
        ) : (
          <div className="driver-application">
            <h2>Driver Application</h2>
            <p>
              You have requested to be a vanpool driver. Please complete the
              below form. All fields are required.
            </p>

            <PersonalInformation onChange={this.update} />
            <DriverInformation onChange={this.update} />

            <hr />

            <p>
              On Submit, a contract with Commute with Enterprise will be
              displayed. This is a binding legal contract.
            </p>

            <div className="tools">
              <Link className="button cancel" to="/myprofile">
                Cancel
              </Link>
              <button
                className="button primary save"
                onClick={() => this.save()}
                disabled={
                  !personalInformationIsValid ||
                  !driverInformationIsValid ||
                  loading
                }
              >
                Review & Sign
              </button>
            </div>
            <DocusignPopup
              status={url => this.props.docusignStatus(url)}
              loading={loading}
              redirectUrl={'/myprofile'}
              cancelRedirectUrl={'/myprofile'}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { 
    profile: state.userAuth,
    contractInfo: state.joinCommute.contractInfo
   };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    save(data) {
      dispatch({ type: actionTypes.SUBMIT_DRIVER_APPLICATION, data });
    },
    docusignStatus(url) {
      dispatch({
        type: actionTypes.UPDATE_DRIVER_APPLICATION_DOCUSIGN_STATUS,
        data: url
      });
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo, isPendingApplication) {
      dispatch({ type: actionTypes.APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo, isPendingApplication } })
    },
    SubmitDriverApplication(data, history, navigateTo, application){
      dispatch({ type: actionTypes.SUMBIT_SUBSCRIPTION_COORDINATOR_APPLICATION, data, history, navigateTo, application })
    },
    saveAgreementUrl(data) {
      dispatch({
        type: actionTypes.SAVE_CONTRACT_DETAILS,
        data
      });
    },
    refreshDashboard(){
      dispatch({ type: actionTypes.UPDATE_USER_PROFILE_DATA });
      dispatch({ type: actionTypes.SET_REFRESH_DASHBOARD, refresh:true });
    },
    closeConfirmation() {
      dispatch({ type: actionTypes.SHOW_COORDINATOR_THANKYOU_SCREEN, data: false});
    },
    init() {
      dispatch({ type: actionTypes.COMMUTE_PROFILE_LOADING });
      dispatch({ type: actionTypes.USER_PERSONAL_INFO_LOADING });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverApplication);
