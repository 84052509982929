import React from 'react';
import {getCardInfoFromBrand} from '../../join-commute/helpers/commonHelper';
import TickIcon from '../../_styles/images/tick-badge.svg';
import TickIconContainer from '../../shared/components/tick-icon-container/TickIconContainer';

const SelectCardBox = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} tabIndex={0} role='button' key={props.card.partialAccountNumber + props.card.cardBrand} onClick={() => props.onClick(props.card)} className={`box select-box ${props.selected ? ' selected' : ''}`} >
      {
        props.selected && <TickIconContainer src={TickIcon} />
      }
      <div className='leftSection'>
        <div className='CardImage'>
          <img src={getCardInfoFromBrand(props.card.cardBrand).imgSrc} />
        </div>
        <div className='cardDetails'>
          <div className='name'>{props.card.cardBrand.toLowerCase()}</div>
          <div className='details' aria-label={`Card ending ${props.card.partialAccountNumber} expiring on ${props.card.expirationMMYY.replace(/^\d\d/, '$&/')}`}>{`${'Card ending *' + props.card.partialAccountNumber + ' | Exp: ' + props.card.expirationMMYY.replace(/^\d\d/, '$&/')}`}</div>
        </div>
      </div>
    </div>
  )
})

export default SelectCardBox;
