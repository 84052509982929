import { useEffect } from 'react';
import { useState } from 'react'

export function RadioButton(props) {
  const [activeOption, updateActiveOption] = useState(props.value);
  function onClick(opt) {
    props.onChange(opt);
    updateActiveOption(opt)
  }

  useEffect(() => {
    updateActiveOption(props.value);
  }, [props.value]);

  function onOptionKeyDown(e, option){
    if(e.which === 13){
      onClick(option);
    }
  }
  
  return (
    <div className='radio-section'>
      <div aria-labelledby='yes' value='yes' tabIndex={0} role='radio' onClick={() => onClick(1)} onKeyDown={(e) => onOptionKeyDown(e, 1)} aria-checked={activeOption === 1} className={activeOption === 1 ? 'radio active' : 'radio'}>
        <div className='round'></div>
        <label className='text' id='yes'>Yes</label>
      </div>
      <div aria-labelledby='no' value='no' tabIndex={0} role='radio' onClick={() => onClick(2)} onKeyDown={(e) => onOptionKeyDown(e, 2)} aria-checked={activeOption === 2} className={activeOption === 2 ? 'radio active' : 'radio'}>
        <div className='round'></div>
        <label className='text' id='no'>No</label>
      </div>
    </div>
  )
}