import React, { useEffect } from 'react';
import DollarInput from '../../shared/inputs/DollarInput';
import { Dollar } from '../../shared/icons';
import dateUtility from '../../shared/dateUtility';
import DateTimePicker from 'react-datetime-picker';
import analyticsService from '../../shared/analytics/analytics-service';

export default function AddFuelExpense({
    props,
    state,
    vendor,
    activityDate,
    subtract,
    add,
    dollars,
    gallons,
    onChange,
    onError,
    month
}) {
    const expenses = state;
    let maxDate, minDate;
    if(activityDate == "") {
        activityDate = new Date();
    }
    
    if(month == "previous") {
        const date = new Date();
        minDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        maxDate = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0);
        const current = dateUtility.checkPreviousDate(activityDate,maxDate);
        if(current) {
            activityDate = activityDate;
        }else {
            activityDate = maxDate
        }
    }else{
        const date = new Date();
        minDate = new Date(date.getFullYear(), date.getMonth(), 1);
        maxDate = date //new Date(date.getFullYear(), date.getMonth() + 1, 0); 
    }

    let hasFuelGallons,hasfuelCost,hasVendorName,isdatevalid;
    function validFields() {
         hasFuelGallons = ( expenses.gallons !== '0.00' && expenses.gallons !== '0' && expenses.gallons !== "" )
         hasfuelCost = (expenses.dollars !== '0.00' && expenses.dollars !== '0' && expenses.dollars !== "" )
         hasVendorName = (expenses.vendor !== "" && expenses.vendor !== undefined)
         isdatevalid = validatedate(expenses.activityDate)
        if(hasFuelGallons && hasfuelCost && hasVendorName && isdatevalid ) {
            return false
        }else {
            return true
        }
    }

    function handleChange(date) {
        onChange({ activityDate: date, vendor, dollars, gallons })
    };

    function validatedate(value) {
        const date = dateUtility.formatexpenses(value);
        //  var  validregex = /^\d\d\/\d\d\/\d{4}$/;
        const validregex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
        return validregex.test(date);
    }

    function isValidGallons(value) {
        return (
            !value.length ||
            value === '0' ||
            (/^([1-9]\d*|0)(\.\d{0,3})?$/.test(value) && parseFloat(value) < 10000)
        );
    }
    function fieldValidation(field) {
        if(field == "vendor"){
        return (expenses.vendor !== "" && expenses.vendor !== undefined)
        } else if(field == "gallons") {
            return ( expenses.gallons !== '0.00' && expenses.gallons !== '0' && expenses.gallons !== "" )
        } else if (field == "dollars") {
            return (expenses.dollars !== '0.00' && expenses.dollars !== '0' && expenses.dollars !== "" )
        } 
    }

    useEffect(() => {
        analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
              "event_action": 'fuel',
              "event_detail": {
                  "modal": "trip recording/add expense modal"
                  }
            }
          })
    }, [])


    return (
        <div className="addexpenses">
            <div className="expensesInput activity">
                <label>Activity Date</label>
            <DateTimePicker
            onChange={ date => handleChange(date)}
            format={'MM/dd/yyyy'}
            disableClock={true}
            value={activityDate}
            clearIcon={null}
            required={true}
            maxDate={maxDate}
            minDate={minDate}
          />
            </div>
            <div className="expensesInput">
                <label>Vendor Name</label>
                <input
                    type="text"
                    className={`vendor ${ fieldValidation('vendor') ? '' : 'invalid'}`}
                    placeholder="Vendor"
                    value={vendor}
                    onChange={e => onChange({ vendor: e.target.value, dollars, gallons, activityDate })
                    }
                />
            </div>
            <div className="fuelGallons">
                <div className="fuelGallonsInput">
                    <label>Fuel Expense</label>
                    <Dollar />
                    <DollarInput
                        type="text"
                        className={`dollars ${ fieldValidation('dollars') ? '' : 'invalid'}`}
                        placeholder="0.00"
                        value={dollars}
                        onChange={dollars => onChange({ dollars, gallons, vendor, activityDate })}
                    />
                </div>
                <div className="fuelGallonsInput GallonsInput">
                    <label>Fuel Gallons</label>
                    <input
                        type="text"
                        className={`gallons ${ fieldValidation('gallons') ? '' : 'invalid'}`}
                        placeholder="0"
                        value={gallons}
                        onChange={e =>
                            isValidGallons(e.target.value) &&
                            onChange({ dollars, gallons: e.target.value, vendor, activityDate })
                        }
                    />
                    <span className="unitMeasure">gal</span>
                </div>

            </div>

            <button
                className="primary add button"
                onClick={add}
                disabled={validFields()}
            >
                Add to Expenses
            </button>
        </div>
    );
}