import {connect} from 'react-redux';
import ThankYouTick from '../../_styles/images/thankyou-tick.svg'
import ThankYouCommute from '../../_styles/images/thankyou-commute.svg'
import Callable from '../../shared/Callable';
import { SET_REFRESH_DASHBOARD, UPDATE_USER_PROFILE_DATA } from '../../redux/actionTypes';
import { useEffect } from 'react';


function PreDeliveryThankYou(props) {

  useEffect(() => {
    props.updateProfileData();
  }, [])

  function goToDashboard() {
    props.setRefresh(true);
    props.history.push("/dashboard");
  }

  return (
    <div className='thank-you-page'>
      <div className='thank-you-container'>
        <div className='content-container'>
          <div className='left-container'>
            <div className='heading-container'>
              <img className='tick' src={ThankYouTick} alt="tick" />
              <div className='heading'>Thank you!</div>
            </div>
            <div className='comment'>One of our representatives will contact you as soon as the van is setup.</div>
            <button onClick={goToDashboard}>Go To Dashboard</button>
            <div className='comment-two'>In the meantime, if you have any questions or concerns, please contact</div>
            <Callable phone="800-VAN-4-WORK" />
          </div>
          <div className='right-container'>
            <img className='commute-image' src={ThankYouCommute} alt="commute-image" />
          </div>
        </div>
        {/* <div className='foot-privacy'>
        View our{' '}
        <a
          href={config.privacyPolicyLink}
          target="_blank"
          rel="noopener noreferrer"
          className='privacy'
        >
          Privacy Policy
        </a>
        {' '}and{' '}
        <a
          href={config.termsOfUseLink}
          target="_blank"
          rel="noopener noreferrer"
          className='terms'
        >
          Terms of Use{' '}
        </a>
      </div> */}
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    setRefresh(refresh) {
      dispatch({ type: SET_REFRESH_DASHBOARD, refresh });
    },
    updateProfileData(){
      dispatch({type: UPDATE_USER_PROFILE_DATA});
    }
  };
}

export default connect(null,mapDispatchToProps)(PreDeliveryThankYou);