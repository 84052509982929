import * as actionTypes from '../../redux/actionTypes';
import logger from '../../shared/logger';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import config from '../../appConfig';

export default function*() {
  yield takeLatest(
    actionTypes.VANPOOLER_IMPACT_DATA_LOADING,
    loadVanpoolerImpactData
  );
  yield takeLatest(
    actionTypes.REGISTERED_USER_IMPACT_DATA_LOADING,
    loadRegisteredUserImpactData
  );
}

function* loadVanpoolerImpactData(options) {
  const tripImpactData = options.data;
  const [
    previousParticipant,
    currentParticipant,
    previosVanpool,
    currentVanpool
  ] = yield all([
    call(() => getParticipantImpactData(false,tripImpactData)),
    call(() => getParticipantImpactData(true,tripImpactData)),
    call(() => getVanpoolImpactData(false,tripImpactData)),
    call(() => getVanpoolImpactData(true,tripImpactData))
  ]);

  const participant = {
    previous: previousParticipant,
    current: currentParticipant
  };
  const vanpool = { previous: previosVanpool, current: currentVanpool };

  yield put({
    type: actionTypes.IMPACT_DATA_LOADED,
    data: { participant, vanpool }
  });
}

function* loadRegisteredUserImpactData(options) {
  const tripImpactData = options.data;
  const [previous, current] = yield all([
    call(() => getRegisteredUserImpactData(false, tripImpactData)),
    call(() => getRegisteredUserImpactData(true, tripImpactData))
  ]);

  yield put({
    type: actionTypes.IMPACT_DATA_LOADED,
    data: { registeredUser: { previous, current } }
  });
}

function getParticipantImpactData(current,tripImpactData) {

    const url = tripImpactData ? `${config.kongApiBaseUrl}/vanpools/vanpool/participant/impactData/triprecording?current=${current}`
     :  `${config.kongApiBaseUrl}/vanpools/vanpool/participant/impactData?current=${current}`

  return getImpactData(url);
}

function getVanpoolImpactData(current,tripImpactData) {
 
   const url = tripImpactData ? `${config.kongApiBaseUrl}/vanpools/vanpool/impactData/triprecording?current=${current}`
      :  `${config.kongApiBaseUrl}/vanpools/vanpool/impactData?current=${current}`
    return getImpactData(url);
}

function getRegisteredUserImpactData(current,tripImpactData) {

   const url =  tripImpactData ? `${config.kongApiBaseUrl}/user/profile/impactData/triprecording?current=${current}`
     : `${config.kongApiBaseUrl}/user/profile/impactData?current=${current}`

  return getImpactData(url);
}

async function getImpactData(url) {
  try {
    return await Http.get(url);
  } catch (ex) {
    logger.log(ex);
  }

  return {
    milesSaved: 0,
    fuelGallonsSaved: 0,
    fuelSavings: 0,
    co2Savings: 0,
    parkingSavings: 0
  };
}
