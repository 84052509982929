import React from 'react';
import currency from '../currencyFormatter';

const leadingZeroes = /^0+(\d)/;
const AMOUNT_LIMIT = 100000;

function isValidAmount(value) {
  return !isNaN(value) && value >= 0 && value < AMOUNT_LIMIT;
}

function padAmount(value) {
  return currency.dollarPad(value);
}

function formatAmount(value) {
  if (value.includes('.')) {
    const [dollar, cents] = value.split('.');
    return dollar.replace(leadingZeroes, '$1') + '.' + cents.substr(0, 2);
  }

  return value.replace(leadingZeroes, '$1');
}

export default function DollarInput(props) {
  return (
    <input
      {...props}
      onChange={e =>
        isValidAmount(e.target.value) &&
        props.onChange(formatAmount(e.target.value))
      }
      onFocus={e => e.target.select()}
      onBlur={() => props.onChange(padAmount(props.value))}
    />
  );
}
