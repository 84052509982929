import {useEffect, useState} from 'react';
import Close from '../../_styles/images/close-icon-green.svg';
import CardMessage from './CardMessage';
import SelectCardBox from './SelectCardBox';
import analyticsService from '../../shared/analytics/analytics-service';
export default function SelectCard(props) {

  const [selected, updateSelected] = useState({});
  const [saveDisabled, updateSaveDisabled] = useState(true);

  function onSelect(card){
    if(selected.referenceId === card.referenceId){
      updateSelected({});
    } else {
      if(card && card.referenceId){
        updateSelected(card);
      } else {
        updateSelected({});
      }
    }
  }

  useEffect(() => {
    if(selected && selected.referenceId){
      updateSaveDisabled(false);
    } else {
      updateSaveDisabled(true);
    }
  }, [selected])

  useEffect(() => {
    if(props.cards && props.cards.length === 1){
      updateSelected(props.cards[0]);
    }
  }, [props.cards])

  function onSave() {
    analyticsService.analyticsProcessEvent({
      "event": "select_card_save",
      "context": {
        "event_action": "save"
      }
    });
    props.saveClicked(selected);
  }

  useEffect(() => {
      setTimeout(() => {
        const selectedBox = document.querySelector('.selected.box');
        selectedBox.focus()}, 100);
  }, [])

  return (
    <div className='card-operations-container' ref={props.reference} role='dialog' aria-describedby='heading' aria-modal='true'>
      <div className='header'>
        <div className='heading' id='heading'>{props.heading}</div>
        <img className='close' src={Close} onClick={props.close} role='button' alt='close icon'/>
      </div>
      <div className='comment'>{props.text}</div>
      {props.message && <CardMessage message={props.message}/>}
      <div className='card-list-container'>
      {
        props && props.cards && props.cards.map((card) => <SelectCardBox key={card.partialAccountNumber + card.cardBrand} ref={props.reference} card={card} selected={card.referenceId === selected.referenceId} onClick={() =>  onSelect(card)}/>)
      }
      </div>
      <div className='button-container'>
        <div className='buttons'>
          <button disabled={saveDisabled} className='select-save' onClick={onSave}>Save</button>
        </div>
      </div>

    </div>
  );
}