import VanpoolModal from '../helpers/vanpoolModal';
import PredeliveryPopup from'./predeliveryPopup';
import TraditionalPopup from './traditionalPopup';

export default function VanpoolPopup(props) {
        const {active, close, type, goToChoosePlan, saveTraditionalInfo} = props

  return (
   <VanpoolModal  active={active}  close={close}>
       {type.traditional ? <TraditionalPopup saveTraditionalInfo={saveTraditionalInfo} /> : <PredeliveryPopup goToChoosePlan={goToChoosePlan}/>}
    </VanpoolModal>
  )
}

