import React, { Component } from 'react';
import { createPortal } from 'react-dom';

const DEFAULT_PORTAL = document.createElement('div');

export class ConfirmationPopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      deactivating: false
    };
  }

  componentWillReceiveProps() {
    this.delayed = setTimeout(() => {
      if (!this.delayed) return;
      this.setState({ deactivating: false });
    });
  }

  componentWillUnmount() {
    clearTimeout(this.delayed);
    this.delayed = null;
  }

  cancelModal() {
    this.setState({ deactivating: true });
    this.delayed = setTimeout(this.props.cancel, 200);
  }

  confirmModal() {
    this.setState({ deactivating: true });
    this.delayed = setTimeout(this.props.confirm, 200);
  }

  render() {
    let {
      active,
      title,
      message,
      confirmMessage,
      cancelMessage,
      cancel,
      validationcss
    } = this.props;

    if (!active) {
      return null;
    }

    return (
      <div
        className={`popup-container ${this.state.deactivating ? 'out' : ''}`}
      >
        <div className="popup">
          <h3 className="title">{title}</h3>
          <div className="message" id="confirm-popup-message">
            {message}
          </div>
          <div className="tools response">
            {cancel ? (
              <button
                onClick={() => this.cancelModal()}
                ref={e => e && e.focus()}
                className="button cancel"
                aria-describedby="confirm-popup-message"
                onKeyDown={e =>
                  e.key === 'Tab' &&
                  (e.preventDefault() ||
                    e.stopPropagation() ||
                    e.target.nextSibling.focus())
                }
              >
                {cancelMessage}
              </button>
            ) : null}
            <button
              onClick={() => this.confirmModal()}
              ref={e => !cancel && e && e.focus()}
              className={`button confirm primary ${validationcss ? 'validation' : ''}`}
              onKeyDown={e =>
                e.key === 'Tab' &&
                (e.preventDefault() ||
                  e.stopPropagation() ||
                  e.target.parentElement.firstChild.focus())
              }
            >
              {confirmMessage}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default function(props) {
  return createPortal(
    <ConfirmationPopup {...props} />,
    document.getElementById('confirmation-popup') || DEFAULT_PORTAL
  );
}
