import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ThankyouScreen from './ThankyouScreen';
import CoordinatorApplication from './coordinator-application/CoordinatorApplication';
import DriverApplication from './driver-application/DriverApplication'


export default function ApplicationRoute() {
  return (
    <div className="applicationRoute">
      <Switch>
        <Route path={'/driver-application'} exact component={DriverApplication} />
        <Route path={'/driver-application/Thankyou'} exact component={ThankyouScreen} />
        <Route path={'/coordinator-application'} exact component={CoordinatorApplication} />
        <Route path={'/coordinator-application/Thankyou'} exact component={ThankyouScreen} />
      </Switch>
    </div>
  );
}
