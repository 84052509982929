import { useState, useEffect } from 'react';
import SUV from '../../../_styles/images/SUV.svg';
import CoSaved from '../../../_styles/images/CoSavings.svg';
import CostSavings from '../../../_styles/images/CostSavings.svg';
import FuelSaved from '../../../_styles/images/FuelSavings.svg';
import Time from '../../../_styles/images/placeholder-time.svg';
import People from '../../../_styles/images/people.svg';
import ChevronRight from '../../../_styles/images/ChevronRight.png';
import EditMyCommutePopup from '../EditMyCommutePopup';
import { COMMUTE_PROFILE_LOADING, SAVE_USER_COMMUTE_PROFILE } from '../../../redux/actionTypes';
import { connect } from 'react-redux';

const bottomSection = [
    {
        img: Time,
        text: 'Gain valuable time for catching up on work or just relaxing.'
    },
    {
        img: CostSavings,
        text: 'Split costs and save money.'
    },
    {
        img: CoSaved,
        text: 'Reduce your carbon footprint.'
    },
    {
        img: FuelSaved,
        text: 'Save fuel and contribute to a sustainable future.'
    },
    {
        img: People,
        text: 'Make new friends and enjoy a stress-free commute!'
    }
]

function JoinVanpoolSection(props) {
    const [showECPPopup, updateShowECPPopup] = useState(false);

    useEffect(() => {
        props.init();
      }, [])

    function goToFindAVan(){
        props.history.push('/JoinCommute');
    }

    function goToEditCommuteprofile() {
        updateShowECPPopup(true)
    }

    function saveCommuteProfile(data) {
        props.updateLoading();
        props.saveCommuteProfile(data);
        
    }

    return (
            <div className='join-vanpool'>
                <div className='top-section'>
                <div className='edit-my-commute'>
                    <div className='heading'>
                    My Commute
                    </div>
                    <div className='edit-my-commute-heading'>
                        You are currently not part of a commute
                    </div>
                    <div className="edit-my-commute-action" tabIndex={0} role='button' onClick={() => goToEditCommuteprofile()}>
                                    <span>Edit Commute Profile</span>
                                    <img src={ChevronRight} />
                    </div>

                </div>
                </div>
                <div className='seprator'></div>
                <div className='below-section'>
                    <div className='header-section'>
                    <div className="header">Join a Vanpool</div>
                    <div className='vanpool'>
                    <img src={SUV} aria-hidden='true' />
                    </div>
                    </div>
               
                <span className="sub-header">Say goodbye to the hassle of driving alone to work!</span>

                <div className='bottom-section'>
                    <ul>
                        {bottomSection.map((each, index) =>
                            <li key={index}>
                                <img src={each.img} className='icon' />
                                <span>{each.text}</span>
                            </li>
                        )}
                    </ul>
                </div>
                <div className='button-container'>
                    <button className='continue' onClick={goToFindAVan}>find A vanpool</button>
                </div>
                </div>
                {
              showECPPopup && <EditMyCommutePopup  commuteProfile={props.commuteProfile} save={(data) => saveCommuteProfile(data)} close={() => updateShowECPPopup(false)}/>
            }

            </div>

    );
}

function mapDispatchToProps(dispatch) {
    return {
      init() {
        dispatch({ type: COMMUTE_PROFILE_LOADING});
      },
      saveCommuteProfile(data) {
        dispatch({ type: SAVE_USER_COMMUTE_PROFILE, data});
      }
    };
  }
  
  function mapStateToProps(state) {
    return {
      commuteProfile: state.commuteProfile.commute
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(JoinVanpoolSection)