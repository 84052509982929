import {useEffect,useState} from 'react';
import isEqual from 'lodash/isEqual';
import {connect} from 'react-redux';
import {SET_OLD_DRIVING_SCHEDULE, SET_PLAN_DATA, SET_SELECTED_VAN, SET_USER_DRIVING_SCHEDULE, UPDATE_USER_COMMUTE_DATA} from '../../../redux/actionTypes';
import {joinArray} from '../../helpers/arrayHelpers';
import {getActiveDaysFromRoosterDays, getAvailableDaysOfVan,getNextPackage,getPackageFromDays,getVanPrice} from '../../helpers/vanHelpers';
import {initialJoinCommuteState} from '../../JoinCommuteReducer';
import MoreSelect from '../MoreSelect';
import Recommended from '../Recommended';
import analyticsService from '../../../shared/analytics/analytics-service';

function ChoosePlan(props) {

  const [activeDays,updateActiveDays] = useState([]);
  const [noOfDays,updateNoOfDays] = useState(0);
  const [oldPackage,updateOldPackage] = useState(null);
  const [oldRosterDays,updateOldRosterDays] = useState({});
  const [isDriver,updateIsDriver] = useState(false);
  const [recommendedDriverPrice,updateRecommendedDriverPrice] = useState({});
  const [recommendedRiderPrice,updateRecommendedRiderPrice] = useState({});
  const [selectedOption,updateSelectedOption] = useState(0); //0-recommended, 1- 1day more
  const [selectedType,updateSelectedType] = useState(1); //0-driver 1-rider
  const [upsellDriverPrice,updateUpsellDriverPrice] = useState({});
  const [upsellRiderPrice,updateUpsellRiderPrice] = useState({});
  const [vanAvailableDays,updateVanAvailableDays] = useState(0);
  const [oldDriverSchedule, updateOldDriverSchedule] = useState({});

  useEffect(() => {
    if(isEqual(props.joinCommuteState, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
      analyticsService.pageInfo("matching vans");
    }
  },[])

  useEffect(() => {
    if(props.joinCommuteState.oldPlan) {
      const roosterDays = props.joinCommuteState.oldPlan.rosterDays;
      const commuteDays = getActiveDaysFromRoosterDays(roosterDays);
      updateActiveDays(commuteDays);
      updateNoOfDays(commuteDays.length);
      updateOldPackage(props.joinCommuteState.oldPlan.package);
      updateOldRosterDays(props.joinCommuteState.oldPlan.rosterDays);
    }
    updateIsDriver(props.joinCommuteState.drivingScheduleData.isDesiredDriverRole);
    if(props.joinCommuteState){
      if(props.joinCommuteState.oldDrivingScheduleData){
        updateOldDriverSchedule(props.joinCommuteState.oldDrivingScheduleData);
      }
    }
  },[props.joinCommuteState])
  
  useEffect(() => {
    if (isEqual(props.joinCommuteState.plan, initialJoinCommuteState.plan)) {
      updateSelectedOption(0)
      if(props.joinCommuteState.drivingScheduleData.isDesiredDriverRole) {
        updateSelectedType(0)
      } else {
        updateSelectedType(1)
      }
    } else {
      if(props.joinCommuteState && props.joinCommuteState.plan && props.joinCommuteState.plan.noOfDays === noOfDays) {
        updateSelectedOption(0)
      } else {
        updateSelectedOption(1)
      }
      if(props.joinCommuteState && props.joinCommuteState.plan && props.joinCommuteState.plan.isDriver) {
        updateSelectedType(0)
      } else {
        updateSelectedType(1)
      }
    }
  },[props.joinCommuteState, noOfDays])

  useEffect(() => {
    if(props.joinCommuteState && props.joinCommuteState.oldPlan){
      const pack = props.joinCommuteState.oldPlan.package;
      const van = props.joinCommuteState.selectedVan;
      //Getting recommended prices based on the package
      if(!isDriver) {
        const price1 = getVanPrice(van,false,pack);
        updateRecommendedRiderPrice(price1);
      }
      const price2 = getVanPrice(van,true,pack);
      updateRecommendedDriverPrice(price2);
      const available = getAvailableDaysOfVan(van).length;
      updateVanAvailableDays(available);
      //Getting upsell prices based on package
      if(noOfDays <= 4 && available > noOfDays) {
        const nextPackage = getNextPackage(pack);
        if(!isDriver) {
          const price3 = getVanPrice(van,false,nextPackage);
          updateUpsellRiderPrice(price3)
        }
        const price4 = getVanPrice(van,true,nextPackage);
        updateUpsellDriverPrice(price4);
      }
    }

  },[isDriver])

  function updateSelection(section,type) {
    updateSelectedOption(section);
    updateSelectedType(type);
  }

  function continueClicked() {
    let plan = {
      noOfDays,
      package: oldPackage,
      rosterDays: oldRosterDays,
      isDriver: false,
    }
    let van = props.joinCommuteState.selectedVan;
    if(selectedType === 0){
      plan.isDriver = true;
      if(!props.joinCommuteState.drivingScheduleData.isDesiredDriverRole){
        props.setOldDrivingSchedule(props.joinCommuteState.drivingScheduleData);
        props.setUserDrivingSchedule({
          isDesiredDriverRole: true,
          role: 1,
          isFrequentDriver: false,
        });
      }
    } else if(selectedType === 1) {
      plan.isDriver = false;
      if(props.joinCommuteState.drivingScheduleData.isDesiredDriverRole){
        props.setOldDrivingSchedule(props.joinCommuteState.drivingScheduleData);
        props.setUserDrivingSchedule({
          isDesiredDriverRole: false,
          role: 2,
          isFrequentDriver: null,
        });
      }
    }
    let vanCharge = {};
    let vanChargeRider = {};
    if(selectedOption === 1) {
      plan.noOfDays = noOfDays+1;
      const userPack = getPackageFromDays(plan.noOfDays)
      props.updateUserCommuteData({
        noOfDays: plan.noOfDays,
        package: userPack
      })
      vanCharge = getVanPrice(van, plan.isDriver, userPack);
      vanChargeRider = getVanPrice(van, false, userPack);
      van.charge = vanCharge;
      van.riderCharge = vanChargeRider;
      props.setSelectedVan(van)
      props.setPlanData(plan);
      props.history.push('/JoinCommute/choose-plan/upgrade');
    } else {
      const packageWithDays = getPackageFromDays(plan.noOfDays);
      props.updateUserCommuteData({
        noOfDays: plan.noOfDays,
        package: plan.package,
        rosterDays: plan.rosterDays
      })
      vanCharge = getVanPrice(van, plan.isDriver, packageWithDays);
      vanChargeRider = getVanPrice(van, false, packageWithDays);
      van.charge = vanCharge;
      van.riderCharge = vanChargeRider;
      props.setSelectedVan(van)
      props.setPlanData(plan);
      props.history.push('/JoinCommute/choose-plan/summary');
    }

    const newPackage = plan.noOfDays >= 5 ? 'unlimited Plan' : plan.noOfDays + "-day plan";
    const type = selectedType === 0 ? 'driver' : 'rider';

    analyticsService.analyticsProcessEvent({
      "event": "choose_plan_continue",
      "context": {
        "event_action": "continue",
        "event_detail": {
            "plan_selected": newPackage +":"+ type
            }
        }
    });
  }

  return (
    <div className='choose-plan-page'>
      <div className='choose-plan-container'>
        <div className='heading'>Choose a Plan</div>
        <div className='comment'>Based on your {noOfDays}-day commute of {joinArray(activeDays,', ')}, here are plans that fit for your schedule</div>
        <Recommended
          noOfDays={noOfDays}
          isDriver={isDriver}
          driverPrice={recommendedDriverPrice}
          riderPrice={recommendedRiderPrice}
          selectedOption={selectedOption}
          selectedType={selectedType}
          updateSelection={updateSelection}
          selectedVan={props.joinCommuteState.selectedVan}
          wasRider={oldDriverSchedule && !oldDriverSchedule.isDesiredDriverRole}/>
        {noOfDays < 5 && vanAvailableDays > noOfDays
          && <div className='line'></div>
        }
        {noOfDays < 5 && vanAvailableDays > noOfDays
          &&
          <MoreSelect
            noOfDays={noOfDays + 1}
            isDriver={isDriver}
            driverPrice={upsellDriverPrice}
            riderPrice={upsellRiderPrice}
            selectedOption={selectedOption}
            selectedType={selectedType}
            updateSelection={updateSelection}
            selectedVan={props.joinCommuteState.selectedVan}
            wasRider={oldDriverSchedule && !oldDriverSchedule.isDesiredDriverRole}/>
        }
        {selectedType === 0 ? <div className='driver-only-text'>
          <div className='line text'></div>*Standard rate assumes you will be an approved driver</div> : <></>}
        <div>
          <button className='continue-button' onClick={continueClicked}>CONTINUE</button>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    joinCommuteState: state.joinCommute
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setPlanData(data) {dispatch({type: SET_PLAN_DATA,data})},
    setSelectedVan(van){
      dispatch({type: SET_SELECTED_VAN, data: van})
    },
    setUserDrivingSchedule(data) {
      dispatch({type: SET_USER_DRIVING_SCHEDULE, data});
    },
    setOldDrivingSchedule(data) {
      dispatch({type: SET_OLD_DRIVING_SCHEDULE, data});
    },
    updateUserCommuteData(data) {
      dispatch({type: UPDATE_USER_COMMUTE_DATA, data: data, updateOldPlan: false})
    },
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ChoosePlan);