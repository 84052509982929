import React, { Component } from 'react';
import Callable from '../shared/Callable';
import DocusignPopup from '../shared/docusign/DocusignPopup';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import ContractAgreement from './contractAgreement';
import ContractImage from '../_styles/images/contract.png';
import logoutService from '../login/logoutService';
import ErrorScreen from '../join-commute/error-screen/ErrorScreen';

export class Contract extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signAgreement: false
    };
  }

  submit(data) {
    this.setState({ loading: true });
    this.props.submit(data);
  }

  signAgreement() {
    this.setState({ signAgreement: true });
  }

  render() {
    const { loading, signAgreement } = this.state;
    const contract = this.props.userProfile.contracts[0];
    const contractName = contract && contract.contractName;
    const agreementURL = contract && contract.agreementURL;
    const error = this.props.error;

    let title = "Sign Participant Agreement";
    let message = "Before proceeding, please review and sign the updated participant agreement"


    if (signAgreement) {
      return (
        <ContractAgreement loading={loading} callLoginProfile={this.props.callLoginProfile} contractInfo={this.props.contractInfo} history={this.props.history} agreementURL={agreementURL} saveAgreementDetails={this.props.saveAgreementUrl} navigateTo={"/dashboard"} updateLoading={this.props.updateLoading} updateContractStatus={this.props.updateContractStatus} />
      )
    }
    return (
      !error ?

        (<div className='main-container'>
          <div className="contracts">
            <div className='container'>

              <img src={ContractImage} alt="contract img" />
              <h2 className='title'>{title}</h2>
              <p className='heading'>
                {message}
              </p>
              <div className="tools">
                {
                  !agreementURL ?
                    <button
                      id="continue"
                      className="button primary"
                      onClick={() => this.submit(contract)}
                    >
                      Review & Sign Agreement
                    </button>
                    :
                    <button
                      id="continue"
                      className="button primary"
                      onClick={() => this.signAgreement()}
                    >
                      Review & Sign Agreement
                    </button>

                }
              </div>
              <button
                id="logout"
                className="button primary"
                onClick={() => logoutService.logout()}
              >
                Log out
              </button>
            </div>

            <DocusignPopup
              status={url => this.props.status(url)}
              loading={loading}
              redirectUrl={'/'}
              cancelRedirectUrl={'/login'}
            />
          </div>
        </div>)
        :
        <ErrorScreen> </ErrorScreen>


    );
  }
}

function mapStateToProps(state) {
  return {
    contractInfo: state.joinCommute.contractInfo,
    userProfile: state.userAuth,
    error: state.application.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submit(data) {
      dispatch({ type: actionTypes.CREATE_CONTRACT_ENVELOPE, data });
    },
    status(url) {
      dispatch({
        type: actionTypes.UPDATE_CONTRACT_DOCUSIGN_STATUS,
        data: url
      });
    },
    saveAgreementUrl(data) {
      dispatch({
        type: actionTypes.SAVE_CONTRACT_DETAILS,
        data
      });
    },
    updateLoading(data) {
      dispatch({ type: actionTypes.LOADER_ACTIVE, data });
    },
    callLoginProfile() {
      dispatch({ type: actionTypes.AUTHORIZATION_REQUEST });
    },
    updateContractStatus(data) {
      dispatch({ type: actionTypes.UPDATE_CONTRACT_CLICKWRAP_STATUS, data: data });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contract);
