import { useEffect } from "react";
export default function RangeSlider(props) {
  useEffect(() => {
    const ele = document.querySelector('.buble');
    if (ele) {
      ele.style.left = `${Number(props.slide / 4)}px`;
    }
  }, [props.slide])
  return (
    <div className="slider-parent" aria-label='zoom slider'>
      <input type="range" role='slider' aria-valuemax={props.max} aria-valuemin={props.min} aria-valuenow={props.slide} min={props.min} max={props.max} step={0.1} value={props.slide}
        onChange={({ target: { value: radius } }) => {
          props.onSlide(radius);
        }}
      />
    </div>
  );
}