import React, { Component } from 'react';
import DaysInput from '../../shared/DaysInput';

function union(a, b) {
  return [...a, ...b.filter(e => !a.includes(e))];
}

class DaysEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { activeDays: [] };
  }

  componentDidMount() {
    this.setState({
      activeDays: union(this.props.days, this.props.participantDays)
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      activeDays: union(nextProps.days, nextProps.participantDays)
    });
  }

  render() {
    const { save, cancel, participantDays } = this.props;
    const { activeDays } = this.state;

    return (
      <div className="days-editor">
        <p className="instructions">What days will the routes be traveled?</p>
        <DaysInput
          value={this.state.activeDays}
          notAllowed={participantDays}
          onChange={activeDays => this.setState({ activeDays })}
        />
        <div className="tools">
          <button className="button small cancel" onClick={cancel}>
            Cancel
          </button>
          <button
            className="button small save"
            onClick={() => save(activeDays)}
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}

export default DaysEditor;
