import { RouteDetails } from '../../../../join-commute/common/route-details/RouteDetails';
import { numberWithCommas } from '../../../../join-commute/helpers/commonHelper';
import TaxesAccordion from '../../../../payment/common/TaxesAccordion';
import RateAccordion from '../../../../payment/common/RateAccordion';

export default function LeftSection(props) {
  const { van, subscription, addressDetails, isDriver, tax, showRideDays, routeDetails, rideDays, showCommuteDays, updatePDPopup, proratedTotal } = props;
  return (
    <>
      <div className="vanpool-summary-details">
        <div className="vanpool-Details">
          <div className="van-details" role='group'>
            <div className="van-class-details" role='group'>
              <div className="van-class">{van.name}</div>
              <div className="van-model">{van.vehicleType}</div>
            </div>
            <div className="van-price-details" role='group'>
              {subscription && <div className='price-group' aria-label={`${subscription.price} dollars and ${subscription.decimal} cents`}>
                <div className="doller">{'$'}</div>
                <div className="price">{numberWithCommas(subscription.price)}</div>
                <div className="decimal">.{subscription.decimal}</div>
              </div>}
              <div className="sub">{"total per month"}</div>
            </div>
          </div>
          {addressDetails && <RouteDetails vanpool={routeDetails} />}
          {showRideDays && <div className="rideDays" role='group'>
            <ul>
              {rideDays.length && rideDays.map(day => (<li aria-label={day} key={day.substr(0, 3)}> {day.substr(0, 3)} </li>))}
            </ul>
          </div>}
          {
            showCommuteDays && <span className='commute-days-heading'>Commute days</span>
          }
          {
            showCommuteDays && <div className='commute-days'>
              {rideDays && rideDays.map(day => {
                return <span className='block' aria-label={day} key={day}>{day.substring(0, 3)}</span>
              })}
            </div>
          }
        </div>
      </div>
      <div className="vanpool-summary-prices" role='group'>
        <RateAccordion
          isDesiredDriverRole={isDriver}
          rateMessage={null}
          charge={subscription.rate}
        />
        <TaxesAccordion total={props.totalTax} taxes={props.taxes} surcharges={props.surcharges} />
        <div className='bottom' role='group'>
          <div className='disclaimer' role='group'>
            <p>
              Recurring subscription. <br />
              Rates subject to change. <br />
              <span onClick={updatePDPopup}>Learn More</span>
            </p>
          </div>
          <div className='total'>
            <div className='label'>Total</div>
              {subscription &&
              <span className='grp'>
                <div className='price' aria-label={`${subscription.price} dollars and ${subscription.decimal} cents`}>
                  <div className='dollar'>$</div>
                  <div>{numberWithCommas(subscription.price)}</div>
                  <div className='dollar'>.{subscription.decimal}</div>
                </div>
                <div className='frequency'>per month</div>
              </span>}
          </div>
        </div>
      </div>
    </>

  )
}