import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RegistrationCommuteForm from '../commute-profile/RegistrationCommuteForm';
import {
  TRANSPORTATION_MODES_LOADING,
  REGISTRATION_JOIN_SITE,
  MARKETING_OPTIONS_LOADING
} from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';
import ReferralInput from './ReferralInput';
import {
  validPhoneNumber,
  formatPhoneNumber
} from '../shared/inputs/PhoneEntry';

export class NotInVanpool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      employer: props.profile.employer,
      validEmployer: true,
      activeDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
      arriveAtWork: '08:00:00',
      departFromWork: '17:00:00',
      homeAddress: '',
      workAddress: '',
      mode: 'Car',
      arriveFlexible: false,
      departFlexible: false,
      phone: props.profile.phone
        ? {
            ...props.profile.phone,
            number: formatPhoneNumber(props.profile.phone.number)
          }
        : { type: 'Mobile', number: '', extension: '' },
      shouldContact: true,
      referral: '',
      loading: false,
      getReferral: false,
      isPartner: props.profile.isPartner
    };

    this.update = this.update.bind(this);
  }

  componentWillReceiveProps(props) {
    if (props.message) {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.props.init();
  }

  update(updates) {
    this.setState(updates);
  }

  valid() {
    if (this.state.getReferral) {
      return !!this.state.referral;
    }

    const {
      employer,
      validEmployer,
      arriveAtWork,
      departFromWork,
      homeAddress,
      workAddress,
      phone,
      worksiteId
    } = this.state;
    return (
      !!employer &&
      validEmployer &&
      arriveAtWork !== departFromWork &&
      !!homeAddress &&
      (!!workAddress || !!worksiteId) &&
      homeAddress !== workAddress &&
      validPhoneNumber(phone.number)
    );
  }

  next() {
    this.setState({ getReferral: true });
    window.scrollTo(0, 0);
  }

  prev() {
    this.setState({ getReferral: false });
    window.scrollTo(0, 0);
  }

  save() {
    this.props.save(this.state);
    this.setState({ loading: true });
  }

  render() {
    const { getReferral, referral } = this.state;

    if (getReferral) {
      return (
        <div className="commute-profile refer-question" key="referral">
          <Loading isLoading={this.state.loading} />
          <div className="title">
            Please tell us how you heard about Commute
          </div>
          <ReferralInput
            value={referral}
            onChange={referral => this.setState({ referral })}
            marketingOptions={this.props.marketingOptions}
          />
          <div className="actions">
            <button className="button" onClick={() => this.prev()}>
              Back
            </button>
            <button
              className="primary button"
              disabled={!this.valid()}
              onClick={() => this.save()}
            >
              Next
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="commute-profile" key="commute">
          <div className="title">
            {this.state.isPartner
              ? `Welcome ${this.state.employer} Partner, Let's build your Commute Profile`
              : `Ok, let’s build your Commute Profile`}
          </div>
          <RegistrationCommuteForm {...this.state} update={this.update} />
          <div className="actions">
            <Link to="/registration" className="button">
              Back
            </Link>
            <button
              className="primary button"
              disabled={!this.valid()}
              onClick={() => this.next()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.banner.message,
    profile: state.userAuth,
    marketingOptions: state.commuteProfile.marketingOptions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: TRANSPORTATION_MODES_LOADING });
      dispatch({ type: MARKETING_OPTIONS_LOADING });
    },
    save(data) {
      dispatch({ type: REGISTRATION_JOIN_SITE, data });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotInVanpool);
