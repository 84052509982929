export default {
  SIGNING_COMPLETE: 'signing_complete',
  CANCEL: 'cancel',
  DECLINE: 'decline',
  EXCEPTION: 'exception',
  FAX_PENDING: 'fax_pending',
  ID_CHECK_FAILED: 'id_check_failed',
  SESSION_TIMEOUT: 'session_timeout',
  TTL_EXPIRED: 'ttl_expired',
  VIEWING_COMPLETE: 'viewing_complete'
};
