import dateUtility from './dateUtility';

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

function seconds(hours, minutes, seconds) {
  return hours * HOUR + minutes * MINUTE + seconds;
}

function diffSeconds(a, b) {
  if(a && b){
    if (a === b) {
      return DAY;
    }
  
    if (a < b) {
      return DAY -  (b, a);
    }
  
    const [ah, am, as] = a.split(':').map(n => parseInt(n, 10));
    const [bh, bm, bs] = b.split(':').map(n => parseInt(n, 10));
  
    return seconds(ah, am, as) - seconds(bh, bm, bs);
  }
}

function getHoursBetween(startTime, endTime) {
  var start = new Date("2000-01-01 " + startTime);
  var end = new Date("2000-01-01 " + endTime);
  var difference = end - start;
  var hours = difference / (1000 * 60 * 60);

  return hours;
}

function getNextRoutePoint(props) {
  if(props){

    if (!props.inboundRoutePoints.length) {
      return { index: -1, route: 'outboundRoutePoints' };
    }
  
    const currentTime = dateUtility.currentTime();
    const points = [
      ...props.inboundRoutePoints.map((stop, index) => ({
        diff: diffSeconds(stop.stopTime, currentTime),
        index,
        route: 'inboundRoutePoints'
      })),
      ...props.outboundRoutePoints.map((stop, index) => ({
        diff: diffSeconds(stop.stopTime, currentTime),
        index,
        route: 'outboundRoutePoints'
      }))
    ];
  
    return points.reduce((min, stop) => (stop.diff < min.diff ? stop : min));
  }
}

function getNextRidingDay(rideSchedule, rideDays) {
  if(rideSchedule && rideSchedule.length > 0){
    const nextRideDay = rideSchedule.find(ele => rideDays.includes(ele.day));
    return nextRideDay;
  }
  return null;
}

function getValidVanpoolDays(days, props) {
  if (!props.inboundRoutePoints.length) {
    return { index: -1, route: 'outboundRoutePoints' };
  }
  const currentTime = dateUtility.currentTime();
  const lastStop = props.inboundRoutePoints[props.inboundRoutePoints.length - 1];
  const diffHours = getHoursBetween(lastStop.stopTime, currentTime);
  if(diffHours > 0){
    return days.slice(1);
  }
  return days;
}

export { getNextRoutePoint, getNextRidingDay, getValidVanpoolDays };
