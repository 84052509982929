import AppStoreQR from '../../_styles/images/new-icons/download-app/appStoreQR.svg';
import PlayStoreQR from '../../_styles/images/new-icons/download-app/playStoreQR.svg';
import AppStore from '../../_styles/images/new-icons/download-app/app-store.svg';
import PlayStore from '../../_styles/images/new-icons/download-app/play-store.svg';
import Close from '../../_styles/images/close-icon-green.svg';
import { useEffect, useRef } from 'react';
import analyticsService from '../../shared/analytics/analytics-service';

export default function CommonQRModal(props) {
    const modalRef = useRef();

    useEffect(() => {
        modalRef.current.focus();
        analyticsService.analyticsProcessEvent({
            "event": "modal_view_generic",
            "context": {
              "event_action": "download commute with enterprise app"
             }
          })
    }, []);
    
    function handleCloseKeyDown(e) {
        if (e.key === 'Enter' || e.key === ' ') {
            props.updateOpenQRModal(false);
        }
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.keyCode === 9) {
                const closeButton = document.querySelector('.save-button.close');
                if (closeButton && closeButton.isSameNode(e.target)) {
                    e.preventDefault();
                    document.querySelector('#download-app-popup .close').focus();
                }
            }
            else if (e.keyCode === 27) { // Esc key
                props.updateOpenQRModal(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.querySelector('.open-app-or-modal').focus();
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div className="download-app-popup show" id='download-app-popup' aria-live='polite' role='alert' aria-modal='true' ref={modalRef} tabIndex="-1">
            <img role='button' className="close" src={Close} onKeyUp={handleCloseKeyDown} onClick={() => props.updateOpenQRModal(false)} alt='close download popup' tabIndex={0} />
            <div className="download-app-popup-container">
                <div className="header-content">
                    <div className='header'>To download</div>
                </div>
                <div className='download-app-popup-content'>
                    <div className='header'>scan this code using your phone's camera app or a QR scanner app.</div>
                    <div className='apps-section'>
                        <div className="iphone-section section">
                            <img src={AppStoreQR} className='qr' alt='QR code'></img>
                            <img className='store' src={AppStore} alt='Download on the Appstore' />
                        </div>
                        <div className='divider'></div>
                        <div className="android-section section">
                            <img src={PlayStoreQR} className='qr' alt='QR code'></img>
                            <img className='store' src={PlayStore} alt='Get it on Google Play' />
                        </div>
                    </div>
                    <span className='content question'>Having trouble scanning?</span>
                    <span className='content text'>Visit your phone's app store and search for the 'Commute with Enterprise' app</span>
                    <button className='save-button close' onKeyUp={handleCloseKeyDown} onClick={() => props.updateOpenQRModal(false)} tabIndex={0}>Close</button>
                </div>
            </div>
        </div>
    );
}