function translate(entry) {
  if (entry instanceof Array) {
    return entry.map(c => c).join(' ');
  }

  if (entry instanceof Object) {
    return Object.keys(entry)
      .filter(key => entry[key])
      .join(' ');
  }

  return entry
    .split(' ')
    .filter(c => c)
    .join(' ');
}

export default function(...data) {
  return translate(data.map(translate).join(' '));
}
