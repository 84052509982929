import React, { Component } from 'react';
import FieldInfo from '../shared/FieldInfo';
import config from '../appConfig';


function WexCardActivationInfo() {
  return (
    <div>
      <p>Enter your card number as displayed below in the combination of 13 digits + 5 digits, for a total of 18 digits. There's no need to enter the dash.</p>
      <div className="wexCard">
                    <img
                      src={`${config.imagePath}/WexCard.png`}
                      alt="Wex Card"
                    />
    </div>
    </div>
  );
}
export default function() {
    return (
      <span className="wexCardInfo">
      <FieldInfo message={<WexCardActivationInfo />} />
      </span>
    );
}