const localStorageService = {};

localStorageService.getItem = key => localStorage.getItem(key);

localStorageService.getJsonItem = key => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
};

localStorageService.setItem = (key, value) => localStorage.setItem(key, value);

localStorageService.setJsonItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

localStorageService.clear = key => localStorage.removeItem(key);

export default localStorageService;
