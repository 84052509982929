import {useEffect,useRef,useState} from 'react';
import {getTimeIntervals} from '../../join-commute/helpers/commonHelper';
import timeIcon from '../../_styles/images/placeholder-time.svg';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE, SPACE_KEY_CODE } from '../helpers/accessibilityHelpers';

export default function TimeEntry(props) {
  const node = useRef();
  const timeIntervals = getTimeIntervals();

  const [isOpen,setIsOpen] = useState(false);
  const [selectedOption,setSelectedOption] = useState({});
  
  useEffect(() => {
    if(props.value) {
      setSelectedOption(props.value)
    }
  }, [props.value])

  const toggle = (e) => {
    if (e.which === ENTER_KEY_CODE || e.which === SPACE_KEY_CODE) {
      if(isOpen){
        node && node.current.focus();
      }
      setIsOpen(!isOpen);
    }
  }

  function optionClicked(opt) {
    setSelectedOption(opt);
    setIsOpen(false);
    node && node.current.focus();
    props.onChange(opt);
  }

  function optionKeydown(e, opt) {
    if (e.which === ENTER_KEY_CODE || e.which === SPACE_KEY_CODE) {
      optionClicked(opt);
      node && node.current.focus();
    }
  }

  const handleKeyNavigation = (e) => {
    if (e.which === ESCAPE_KEY_CODE) {
      setIsOpen(false);
      node && node.current.focus();
    }
  };

  const arrowClass = `time-arrow ${isOpen ? 'up' : 'down'}`;
  const dropdownClass = `dropdown-list ${isOpen ? 'unhide' : 'hide'}`;
  const tempClass = `select-header ${props.valid ? '' : 'invalid-address-auto'}`;

  const clickOutside = (e) => {
    if (node.current && !node.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown',clickOutside);
    document.addEventListener('keydown', handleKeyNavigation);
    return () => {
      document.removeEventListener('mousedown',clickOutside);
      document.removeEventListener('keydown', handleKeyNavigation);
    }
  },[isOpen])

  return (
    <fieldset className='select-container' ref={node}>
      <label htmlFor={props.label} className='flexibility-label'>{props.label}</label>
      <div tabIndex={props.tabIndex || 0} className={tempClass} onClick={() => setIsOpen(!isOpen)} aria-expanded={isOpen ? 'true' : 'false'} role='button' id={props.label} aria-labelledby={props.label} onKeyDown={toggle} aria-disabled={props.disabled} disabled={props.disabled}>
        {selectedOption.hour ? <div className='selected-time'>
          <div className='selected-hour'>
            <div className='hour'>{selectedOption.hour}</div>
          </div>
          <div className='selected-mins-day'>
            <div className='mins'>{`:${selectedOption.minutes}`}</div>
            <div className='day'>{selectedOption.day}</div>
          </div>
        </div> :
          <div className='placeholder'>
            <img src={timeIcon} aria-hidden={true} />
            <span className='text'>Time</span>
          </div>}
        <i className={arrowClass}></i>
      </div>
      { isOpen && <div className='dropdown-container' tabIndex={-1}>
        <div className={dropdownClass} role='listbox' aria-labelledby={props.label}>
          {timeIntervals.map(time => 
            <div role='option' className='list-item' aria-selected={selectedOption.hour === time.hour && selectedOption.minutes === time.minutes} tabIndex={0} onKeyDown={(e) => optionKeydown(e, time)} onClick={() => optionClicked(time)} key={`${time.hour}:${time.minutes} ${time.day}`}>{`${time.hour}:${time.minutes} ${time.day}`}</div>
          )}
        </div>
      </div>}
    </fieldset>
  );

}