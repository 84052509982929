import React from 'react';
import { Unchecked, Checked } from '../../shared/icons';
import currencyFormatter from '../../shared/currencyFormatter';
import SelectToolTip from './selectTooltip';
import dateUtility from '../../shared/dateUtility';

export default function Transactions({
  activityDateTime,
  merchantName,
  isSelected,
  fuelGallons,
  cost,
  toggleSelected
}) {
  const formatedExpense =  currencyFormatter.formatUSD(cost);
  const formatedDate = dateUtility.parseFormalDate1(activityDateTime)
  return (
    <div className="etable-body wex-transactions-table">
      <div className= { `etable-row ${isSelected ? '' : 'unchosen'}` }>
        <div className="etable-column">
        <div className="etable-cell wexSelect tooltip">
           <button
            className={`selected ${isSelected ? 'chosen' : ''}`}
            onClick={toggleSelected}
          >
            {isSelected ? <Checked /> : <Unchecked />}
          </button>
          <SelectToolTip />
          </div>
          <div className="etable-cell activityDate">
            <div className="mobile-only-label"><label>Activity Date:&nbsp;</label><span>{formatedDate}</span></div>
          </div>
          <div className="etable-cell vendorName">
            <div className="mobile-only-label"><label>Vendor:&nbsp;</label><span>{merchantName}</span></div>
          </div>
          <div className="etable-cell gallons">
            <div className="mobile-only-label"><label>Gallons:&nbsp;</label><span>{`${fuelGallons ? fuelGallons + " " + 'Gallons': 'N/A'}`}</span></div>
          </div>
          <div className="etable-cell expenses">
            <div className="mobile-only-label"><label>Expense:&nbsp;</label><span>{formatedExpense}</span></div>
          </div>
        </div>
      </div>
  </div>
  );
}
