import * as actionTypes from '../redux/actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import logger from '../shared/logger';
import statsService from './stats-service';

export default function*() {
  yield takeLatest(actionTypes.STATS_IMPACT_DATA_LOADING, loadImpactData);
  yield takeLatest(actionTypes.STATS_CSV_DATA_LOADING, loadAggregateCSVData);
  yield takeLatest(
    actionTypes.STATS_MONTHLY_ENROLLMENT_LOADING,
    loadEnrollmentMonthlyData
  );
  yield takeLatest(actionTypes.STATS_TRIPDATA_LOADING, loadTripData);
}

function* loadImpactData(action) {
  try {
    let impactData = yield call(() => statsService.impactData(action.data));

    yield put({
      type: actionTypes.STATS_IMPACT_DATA_LOADED,
      data: impactData
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.STATS_IMPACT_DATA_ERROR });
  }
}

function* loadAggregateCSVData(action) {
  try {
    let csvData = yield call(() => statsService.csvData(action.data));
    yield put({
      type: actionTypes.STATS_CSV_DATA_LOADED,
      data: csvData
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.STATS_CSV_DATA_ERROR });
  }
}

function* loadEnrollmentMonthlyData(action) {
  try {
    let monthlyEnrollment = yield call(() =>
      statsService.enrollmentMonthly(action.data)
    );

    yield put({
      type: actionTypes.STATS_MONTHLY_ENROLLMENT_LOADED,
      data: monthlyEnrollment
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.STATS_MONTHLY_ENROLLMENT_ERROR });
  }
}

function* loadTripData(action) {
  try {
    let tripData = yield call(() => statsService.tripData(action.data));

    yield put({
      type: actionTypes.STATS_TRIPDATA_LOADED,
      data: tripData
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.STATS_TRIPDATA_ERROR });
  }
}
