import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { User } from '../shared/icons';
import Section, { Entry } from '../profile/Section';
import dateUtility from '../shared/dateUtility';
import CommuteEdit from './CommuteEdit';
import CommuteTools from './CommuteTools';

export function CommuteInfo({
  employer,
  activeDays,
  arriveAtWork,
  departFromWork,
  homeAddress,
  workAddress,
  mode,
  arriveFlexible,
  departFlexible,
  isEditable,
  edit
}) {
  return (
    <Section
      title="Commute Profile"
      Icon={User}
      isEditable={isEditable}
      edit={edit}
    >
      {!isEditable ? (
        <div className="note">
          Commute profile is not editable if part of an active vanpool.
        </div>
      ) : null}
      <div className="employer">{employer}</div>
      <Entry label="Commute Schedule" className="schedule">
        {dateUtility.formatDays(activeDays)}
      </Entry>
      <Entry label="Work Arrival Time" className="arrive">
        {dateUtility.parseTime(arriveAtWork)}
      </Entry>
      <Entry label="Work Departure Time" className="depart">
        {dateUtility.parseTime(departFromWork)}
      </Entry>
      <Entry label="Commute Start" className="home">
        {homeAddress}
      </Entry>
      <Entry label="Commute End" className="work">
        {workAddress}
      </Entry>
      <Entry label="Preferred Mode of Transportation" className="mode">
        {mode}
      </Entry>
    </Section>
  );
}

export class CommuteSection extends Component {
  constructor(props) {
    super(props);
    this.state = { editing: false, validEmployer: true };
  }

  componentDidMount() {
    this.props.init();
  }

  edit() {
    this.setState({ editing: true, ...this.props });
  }

  cancel() {
    this.setState({ editing: false, ...this.props });
  }

  save() {
    this.props.save(this.state);
    this.setState({ editing: false });
  }

  render() {
    const {
      employer,
      worksiteId,
      workAddress,
      isPartner,
      validEmployer
    } = this.state;
    return (
      <div className={`commute-info ${this.state.editing ? 'edit' : ''}`}>
        {this.state.editing ? (
          <Section title="Commute Profile" Icon={User} isEditable={false}>
            <CommuteEdit
              {...this.state}
              update={update => this.setState(update)}
            />
            <CommuteTools
              employer={employer}
              cancel={() => this.cancel()}
              save={() => this.save()}
              worksiteId={worksiteId}
              workAddress={workAddress}
              isPartner={isPartner}
              validEmployer={validEmployer}
            />
          </Section>
        ) : (
          <CommuteInfo {...this.props} edit={() => this.edit()} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state.commuteProfile.commute,
    isPartner: state.userAuth.isPartner,
    isFederatedLogin: state.userAuth.isFederatedLogin
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.COMMUTE_PROFILE_LOADING });
      dispatch({ type: actionTypes.TRANSPORTATION_MODES_LOADING });
    },
    save(commuteProfile) {
      dispatch({
        type: actionTypes.COMMUTE_PROFILE_SAVING,
        data: commuteProfile
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommuteSection);
