 export const cancelReasons = [
    {
      reason: 'Moving to a new company or worksite destination',
      id: 0,
      suggestion: null,
      isChecked: false
    },
    {
      reason: 'My work schedule changed',
      id: 1,
      suggestion: null,
      isChecked: false
    },
    {
      reason: 'Conflicts within Vanpool',
      id: 2,
      suggestion: 'A representative will reach out to gather feedback and support you',
      isChecked: false
    },
    {
      reason: "I'm taking a different commute solution",
      id: 3,
      suggestion:
        'Thank you for * with us! We’ll be here when you need us',
      isChecked: false
    },
    {
      reason: 'Price is too high',
      id: 4,
      suggestion: null,
      isChecked: false
    },
    {
      reason: 'Moving to a different vanpool',
      id: 5,
      suggestion: null,
      isChecked: false
    },
    {
      reason: 'Military Deployment',
      id: 6,
      suggestion: 'Thank you for your service! We’ll be here when you return',
      isChecked: false
    },
    {
      reason: 'My van is disbanding',
      id: 7,
      suggestion: null,
      isChecked: false
    },
    {
      reason: "I'm in a seasonal/short-term Vanpool",
      id: 8,
      suggestion:
        'Thank you for * with us! We’ll be here when you need us',
      isChecked: false
    },
    {
      reason: 'Prefer not to answer',
      id: 9,
      suggestion: null,
      isChecked: false
    }
  ];