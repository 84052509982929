import React, { Component } from 'react';
import AddressUtility from '../../shared/addressUtility';
import Questions from '../../shared/Questions';
import { Home, Briefcase, Vanpool } from '../../shared/icons';
import DaysInput from '../../shared/DaysInput';
import Slider from '../../shared/Slider';
import { RestrictTo } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../../shared/permissions/permissions';
import LocationGreen from '../../_styles/images/LocationGreen.png';
import startLocation from '../../_styles/images/Subtract.png';
import SelectDays from '../../profile/components/change-days/select-days/SelectDays';

export default class NewParticipantEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toWorkStartIndex: 0,
      toWorkEndIndex: 0,
      toHomeStartIndex: 0,
      toHomeEndIndex: 0,
      rideDays: [],
      isNtdRecorder: false,
      isBillingViewer: false,
      isDisabled: false
    };
  }

  componentDidMount() {
    this.setIndices(this.props);
    this.setState({
      rideDays: this.props.rideDays,
      isNtdRecorder: this.props.roles.tripRecorder,
      isBillingViewer: this.props.roles.billingViewer
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setIndices(nextProps);
    this.setState({
      rideDays: nextProps.rideDays,
      isNtdRecorder: nextProps.roles.tripRecorder,
      isBillingViewer: nextProps.roles.billingViewer
    });
  }

  setIndices({
    toWorkStart,
    toWorkEnd,
    toHomeStart,
    toHomeEnd,
    outboundRoutePoints,
    inboundRoutePoints
  }) {
    this.setState({
      toWorkStartIndex: outboundRoutePoints.indexOf(toWorkStart),
      toWorkEndIndex: outboundRoutePoints.indexOf(toWorkEnd),
      toHomeStartIndex: inboundRoutePoints.indexOf(toHomeStart),
      toHomeEndIndex: inboundRoutePoints.indexOf(toHomeEnd)
    });
  }

  change(route, field, value) {
    this.setState({ ['to' + route + field + 'Index']: value });
  }

  setRideDays(rideDays) {
    rideDays.length < 2 ?  this.setState({rideDays,isDisabled:true}) : this.setState({rideDays,isDisabled:false})
  }

  render() {
    const {
      outboundRoutePoints,
      inboundRoutePoints,
      runDays,
      roles,
      status,
      cancel,
      save,
    } = this.props;
    const {
      toWorkStartIndex,
      toWorkEndIndex,
      toHomeStartIndex,
      toHomeEndIndex,
      rideDays,
      isDisabled
    } = this.state;
    const notAllowed = rideDays.length === 1 ? rideDays : [];
    const participantName = this.props.name
    .split(' ')
    .slice(0, 1)
    .join(' ');

    return (
      <div className="participant-route">
        <div className="route-days">
          <div className="days-editor">
            <div className="flex-grow">
            <div className="schedule-label">Commute Details</div>
            <div className='commute-days'>
            <div className='commute-label'>Commute days</div>
            <div className='days'>
                 <SelectDays
                  runningDays={runDays}
                  value={rideDays}
                  onlyShowRunningDays={false}
                  onChange={rideDays => this.setRideDays(rideDays)}
                  minDaysError={rideDays.length < 2}
                  availableDays={runDays}
                  alreadySelected={[]}
                  minDays={2}
                  hideFullBadge={true}
                />
            </div>
            </div>
            </div>
          </div>
        </div>
        <div className="route-set">
          <div className="to-work route-type">
          <div className='route-label'>
          Edit {participantName}'s To Work
            </div>
            <ParticipantRouteEditor
              start={toWorkStartIndex}
              end={toWorkEndIndex}
              points={outboundRoutePoints}
              change={(field, value) => this.change('Work', field, value)}
            />
          </div>
          <div className="to-home route-type">
          <div className='route-label'>
          Edit {participantName}'s To Home
            </div>
            <ParticipantRouteEditor
              start={toHomeStartIndex}
              end={toHomeEndIndex}
              points={inboundRoutePoints}
              change={(field, value) => this.change('Home', field, value)}
            />
          </div>
        </div>

        <div className="tools">
            <button className="button primary"  disabled={isDisabled} onClick={() => save(this.state)}>
              Save
            </button>
        </div>
      </div>
    );
  }
}

function ParticipantRouteEditor({ start, end, points, change }) {
  return (
    <ul>
      <li className="source">
        <div className="select first">
          <div className='image-box'>
          <img className="imageBefore" src={startLocation}  alt='Start Address'/>
          </div>
          <select
            className="start"
            value={points[start].address}
            onChange={event => change('Start', event.target.selectedIndex)}
          >
            {points
              .filter((_, i) => i < end)
              .map(point => (
                <option key={point.address} value={point.address}>
                  {' '}
                  {point.address}
                  {/* {AddressUtility.format(point)} */}
                  {' '}
                </option>
              ))}
          </select>
        </div>
      </li>
      <li className="destination">
        <div className="select first">
        <div className='image-box'>
        <img className="imageAfter" src={LocationGreen}  alt='End Address' />
        </div>
          <select
            className="end"
            value={points[end].address}
            onChange={event =>
              change('End', event.target.selectedIndex + 1 + start)
            }
          >
            {points
              .filter((_, i) => i > start)
              .map(point => (
                <option key={point.address} value={point.address}>
                  {' '}
                  {/* {AddressUtility.format(point)} */}
                  {point.address}
                  {' '}
                </option>
              ))}
          </select>
        </div>
      </li>
    </ul>
  );
}
