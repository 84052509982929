import * as actionTypes from '../redux/actionTypes';

const initialState = {
  showCommuteModal: false,
  reasons: [],
  otherValue: undefined,
  userDetails: [],
  page: 1,
  matchedVanpools: {},
  matchedVanpoolsError: false,
  matchCount: 0,
  selectedRoute:null,
  updatedUserDetails: null,
  flexibleDetails: {},
  matchedSelectedRoutes:{},
  matches:[],
  matchesOtherValue: undefined,
  isInVanpool:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_COMMUTE_MODAL:
      return { ...state, showCommuteModal: true, page: 1, isInVanpool: action.data.isInVanpool, reasons: [], matches: [], updatedUserDetails: null,selectedRoute: null,otherValue: undefined, matchesOtherValue: undefined };
    case actionTypes.HIDE_COMMUTE_MODAL:
      return { ...state, showCommuteModal: false };
    case actionTypes.GOTO_PAGE_TWO:
      return { ...state, reasons: action.data.reasons, otherValue: action.data.otherValue, page: 2 };
    case actionTypes.GO_BACK:
      return { ...state, page: action.data };
    case  actionTypes.UPDATE_USER_DETAILS:
      return { ...state, updatedUserDetails: action.data };
    case actionTypes.UPDATE_FLEXIBLE_DETAILS:
      return { ...state, flexibleDetails: action.data };
    case actionTypes.VANPOOL_MATCHES_RESULT:
      return { ...state, matchedVanpools: action.data, matchCount: action.data.matches };
    case actionTypes.SET_SELECTED_ROUTE:
        return { ...state, selectedRoute: action.data };
    case actionTypes.SAVE_MATCHED_ROUTES_SELECTED: 
        return { ...state, matchedSelectedRoutes: action.data };
    case actionTypes.VANPOOL_MATCHES_ERROR:
      return { ...state, matchedVanpoolsError: action.data };
    case actionTypes.SAVE_MATCHES:
      return { ...state, matches:action.data.matches, matchesOtherValue:action.data.matchesOtherValue}
    case actionTypes.GET_MATCHED_VANPOOLS:
      return state.matchedVanpools;
    case actionTypes.GOTO_PAGE_FOUR_SUCCESS:
      return { ...state, page: 4.1 };
    case actionTypes.GOTO_PAGE_FOUR_FAILURE:
        return { ...state, page: 4.2 };
    case actionTypes.GOTO_PAGE_RIDE_TOGETHER:
        return { ...state, page: 5.1 };
    case actionTypes.GOTO_STAY_IN_TOUCH:
        return { ...state, page: 5.2 };
    case actionTypes.GOTO_PAGE_THREE_MATCHES:
        return {...state, page: 3};
    case actionTypes.PROXIMITY_LOADED:
      return {...state, matches:action.data}
    default:
      return state;
  }
};
