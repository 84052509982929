import React, { Component } from 'react';
import { connect } from 'react-redux';
import config from '../appConfig';
import Callable from '../shared/Callable';
import http from '../shared/http-service';
import { SIGN_TERMS_AND_CONDITIONS } from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';

export class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = { srcdoc: '', loading: true };
  }

  componentDidMount() {
    this.loadTerms(this.props);
  }

  componentWillUnmount() {
    this.unlockScrolling && this.unlockScrolling();
    this.unmounted = true;
  }

  componentWillReceiveProps(props) {
    this.loadTerms(props);
    this.unlockScrolling && !props.termsAndConditions && this.unlockScrolling();
  }

  lockScrolling() {
    if (this.unlockScrolling) return;

    const handler = e =>
      window.scrollTo(0, 0) || e.preventDefault() || e.stopPropagation();
    window.addEventListener('scroll', handler);
    this.unlockScrolling = () => window.removeEventListener('scroll', handler);
  }

  async loadTerms(props) {
    if (!props.registrationComplete || !props.termsAndConditions) {
      return null;
    }

    this.lockScrolling();

    const { documentId } = props.termsAndConditions;
    const { terms } = await http.get.cache(
      `${config.kongApiBaseUrl}/user/profile/terms?documentId=${documentId}`
    );

    if (this.unmounted) {
      return;
    }

    this.setState({ srcdoc: terms });

    setTimeout(() => this.iframeFallback(), 200);
  }

  iframeFallback() {
    if (!this.frame) {
      return;
    }

    const isIE = this.frame.contentWindow.location.toString() === 'about:blank';

    if (!isIE) {
      return;
    }

    this.frame.contentWindow.location = `javascript: window.frameElement.getAttribute('srcdoc');`;
  }

  signTerms() {
    this.setState({ loading: true });
    this.props.dispatch({
      type: SIGN_TERMS_AND_CONDITIONS,
      data: { termsId: this.props.termsAndConditions.termsId }
    });
  }

  handleAttemptedFocusChange(e) {
    e.stopPropagation();
    e.preventDefault();
    e.target.focus();
  }

  render() {
    const { employer, termsAndConditions } = this.props;
    const { loading, srcdoc } = this.state;

    if (!srcdoc || !termsAndConditions) {
      return null;
    }

    return (
      <div className="terms-overlay">
        <div className="terms">
          <div className="title">
            {employer
              ? employer + ' Terms and Conditions'
              : 'Terms and Conditions'}
          </div>
          <div className="desc">
            Before you can proceed you must you must review and accept your
            employers terms of service. If you have questions or require
            assistance, please call <Callable phone="(800)-VAN-4-WORK" />.
          </div>
          <div className="frame">
            <Loading isLoading={loading} absolute={true} />
            <iframe
              ref={e => (this.frame = e)}
              srcDoc={srcdoc}
              title="Terms and Conditions"
              onLoad={() => this.setState({ loading: false })}
            />
          </div>
          <div className="desc bottom">
            By clicking Agree and Continue, you agree and consent to{' '}
            {employer || 'the'} Terms of Service and/or Privacy Policy listed
            above.{' '}
          </div>
          <button
            className="button primary"
            disabled={loading}
            onClick={() => this.signTerms()}
            onBlur={e => !loading && this.handleAttemptedFocusChange(e)}
            ref={el => el && el.focus()}
          >
            Accept &amp; Continue
          </button>
          {loading ? (
            <button
              className="hidden"
              onBlur={e => loading && this.handleAttemptedFocusChange(e)}
              ref={el => el && el.focus()}
            ></button>
          ) : null}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state.userAuth || {};
}

export default connect(
  mapStateToProps,
  null
)(TermsAndConditions);
