function convertToAbbreviations(days) {
    const abbreviations = {
        'Monday': 'mo',
        'Tuesday': 'tu',
        'Wednesday': 'we',
        'Thursday': 'th',
        'Friday': 'fr',
        'Saturday': 'sa',
        'Sunday': 'su'
    };

    return days.map(day => abbreviations[day]);
}
export function getDaysForAnalytics(daysOfWeek) {
    const arr = convertToAbbreviations(daysOfWeek);
    const joinedArray = arr.join('.');
    return joinedArray;
}

export function getPackageNameForAnalytics(packageName) {
    if (packageName.toLowerCase() === 'unlimited') {
        return 'unlimited plan';
    }
    else {
        const dayStr = packageName.toLowerCase().replace('x', '');
        return `${dayStr}-day plan`;
    }
}