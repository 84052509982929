import React from 'react';
import Lock from '../../_styles/images/lock.png';
import config from '../../appConfig';

const LegalCopy = React.forwardRef((isFederal) => {
    return (
        <div className={`legal-copy ${isFederal ? 'federal' : ''}`}>
            <div className='lock-image'>
                <img src={Lock} aria-hidden='true' />
            </div>
            <div className='legal-copy-text'>
                Your information will be submitted over a secure connection. <br />
                For additional information, please review our
                {' '}
                <a
                    href={config.privacyPolicyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='link'
                >
                    Privacy Policy
                </a>
                {' '}and{' '}
                <a
                    href={config.termsOfUseLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className='link'
                >
                    Terms of Use{' '}
                </a>
            </div>
        </div>
    )
})

export default LegalCopy;


