import { ADD_CARD, CLEAR_CARDS, GET_MATCHING_VANPOOLS_ERROR, RESET_UPLOADING_ERROR, GET_PRORATED_AMOUNT, GET_PRORATED_AMOUNT_SUCCESS, GET_VANPOOL_INFO_SUCESS, GET_VANPOOL_PARTICIPANTS_SUCESS, LOADER_ACTIVE, RESET_USER_EMPLOYER_DATA, SAVE_SUBSCRIPTION_SUMMARY, SAVE_SUBSCRIPTION_SUMMARY_FAILURE, SAVE_SUBSCRIPTION_SUMMARY_SUCCESS, SET_BIN_NUMBERS, SET_EMPLOYERS_LIST, SET_MATCHING_VANPOOLS, SET_PLAN_DATA, SET_SELECTED_VAN, SET_USER_COMMUTE_DATA, SET_USER_DRIVING_SCHEDULE, SET_USER_EMPLOYER_DATA, SET_USER_INFO, SET_USER_INFO_FAILURE, SET_USER_INFO_SUCCESS, UPDATE_COMMUTE_CHARGE, UPDATE_START_DATE, UPDATE_USER_COMMUTE_DATA, UPLOAD_PROFILE_PICTURE, UPLOAD_PROFILE_PICTURE_FAILURE, UPLOAD_PROFILE_PICTURE_SUCCESS, VAN_LIST_UPDATED, VERIFY_CARD, SAVE_CONTRACT_DETAILS, SAVE_AGREEMENT_DETAILS, SAVE_EDIT_COMMUTE_INFO, RESET_EDIT_COMMUTE_INFO, GET_EMPLOYERS_LIST, GET_EMPLOYERS_LIST_ERROR, INVITE_VANPOOL_SEARCH, SET_OLD_DRIVING_SCHEDULE } from '../redux/actionTypes';

export const initUserInfo = {
  firstName: '',
  lastName: '',
  emailAddress: '',
  phone: { number: '' }
};

export const initialCardData = {
  cardNumber: '',
  cardType: {},
  cvv: '',
  expiry: {
    month: '01',
    year: 2022
  },
  zip: '',
  isFederal: false,
  isBackup: false,
  isDefault: false,
  maxAmount: null,
  chargeAmount: null,
}

export const initialJoinCommuteState = {
  userCommuteData:
  {
    homeLatitude: 0,
    homeLongitude: 0,
    workLatitude: 0,
    workLongitude: 0,
    homeAddress: '',
    workAddress: '',
    stateName: '',
    rosterDays: {},
    arriveTime: '',
    departTime: '',
    flexArrivalMinutes: null,
    flexDepartureMinutes: null,
    package: null,
    startDate: null,
    charge: 0,
    proratedAmount: 0,
    homeAddressExpanded: {},
    isUpdated: false,
    fromBroucher: false
  },
  drivingScheduleData: {
    isDesiredDriverRole: true,
    role: 1,
    isFrequentDriver: false,
  },
  oldDrivingScheduleData: {
    isDesiredDriverRole: true,
    role: 1,
    isFrequentDriver: false,
  },
  employerData: {
    employerId: '',
    employerName: ''
  },
  employersList: [],
  matchingVans: [],
  impactMetrics: null,
  loading: false,
  employerListLoading: false,
  userInfo: initUserInfo,
  userInfoSaved: false,
  selectedVan: null,
  plan: {
    noOfDays: 0,
    package: null,
    isDriver: false,
  },
  oldPlan: {
    noOfDays: 0,
    package: null,
    rosterDays: {},
  },
  vanListUpdated: false,
  payment: {
    binNumbers: [],
    cards: [],
    isCardSaving: false,
    isLoadingProratedAmount: false,
    isSavingSubscription: false,
    maxAmount: 0
  },
  vanInfoLoaded: false,
  participantsInfoLoaded: false,
  profile: {
    image: null,
    imageLoading: false,
    imageUploadError: null,
  },
  contractInfo: {
    isAgreed: false,
    agreementId: null,
    agreementURL: null,
    clickwrapId: null,
    fromBroucher: false,
    isPendingApplication: false
  },
  editCommute: {
    popupOpened: false,
    commuteEdited: false,
  },
  inviteId: false,
  isCoordinatorInvite: null,
  isInviteError: false,
}

export const joinCommuteReducer = (state = initialJoinCommuteState, action) => {
  switch (action.type) {
    case SET_USER_COMMUTE_DATA:
      return { ...state, userCommuteData: action.data, matchingVans: [], impactMetrics: null, oldPlan: {
        noOfDays: action.data.noOfDays,
        package: action.data.package,
        rosterDays: action.data.rosterDays,
      } }
    case RESET_USER_EMPLOYER_DATA:
      return { ...state, employerData: initialJoinCommuteState.employerData }
    case SET_USER_DRIVING_SCHEDULE:
      return { ...state, drivingScheduleData: action.data };
    case SET_OLD_DRIVING_SCHEDULE:
      return { ...state, oldDrivingScheduleData: action.data };
    case GET_EMPLOYERS_LIST:
      return { ...state, employerListLoading: true }
    case SET_EMPLOYERS_LIST:
      return { ...state, employersList: action.data, employerListLoading: false }
    case GET_EMPLOYERS_LIST_ERROR:
      return { ...state, employersList: [], employerListLoading: false }
    case SET_USER_EMPLOYER_DATA:
      return { ...state, employerData: action.data };
    case SET_MATCHING_VANPOOLS:
      return { ...state, matchingVans: action.data.vanpoolMatches, impactMetrics: action.data.impactMetrics, loading: false, vanListUpdated: true, inviteId: action.data.inviteId && action.data.inviteId, isCoordinatorInvite: action.data.isCoordinatorInvite, isInviteError: action.data.isInviteError};
    case LOADER_ACTIVE:
      return { ...state, loading: action.data };
    case GET_MATCHING_VANPOOLS_ERROR:
      return { ...state, matchingVans: action.data.vanpoolMatches, impactMetrics: action.data.impactMetrics, loading: false, vanListUpdated: true };
    case SET_USER_INFO:
      return { ...state, userInfo: action.data };
    case SET_USER_INFO_SUCCESS:
      return { ...state, userInfoSaved: action.data };
    case SET_USER_INFO_FAILURE:
      return { ...state, userInfoSaved: false };
    case SET_SELECTED_VAN:
      return { ...state, selectedVan: action.data, plan: initialJoinCommuteState.plan };
    case SET_PLAN_DATA:
      return { ...state, plan: action.data };
    case UPDATE_USER_COMMUTE_DATA:
      if(action && action.updateOldPlan === true){
        return { ...state, userCommuteData: { ...state.userCommuteData, ...action.data }, vanListUpdated: false, oldPlan: {
          noOfDays: action.data.noOfDays,
          package: action.data.package,
          rosterDays: action.data.rosterDays,
        } };
      } else {
        return { ...state, userCommuteData: { ...state.userCommuteData, ...action.data }, vanListUpdated: false};
      }
    case SET_BIN_NUMBERS:
      return { ...state, payment: { ...state.payment, binNumbers: action.data.binNumbers, maxAmount: action.data.maxAmount } };
    case VERIFY_CARD:
      return {
        ...state, payment: { ...state.payment, isCardSaving: true }
      };
    case ADD_CARD:
      return {
        ...state, payment: { ...state.payment, cards: [...state.payment.cards, action.data], isCardSaving: false }
      };
    case CLEAR_CARDS:
      return {
        ...state, payment: { ...state.payment, cards: [] }
      };
    case UPDATE_START_DATE:
      return {
        ...state, userCommuteData: { ...state.userCommuteData, startDate: action.data.startDate, startDateString: action.data.startDateString }
      };
    case UPDATE_COMMUTE_CHARGE:
      return {
        ...state, userCommuteData: { ...state.userCommuteData, charge: action.data }
      }
    case GET_PRORATED_AMOUNT:
      return {
        ...state, payment: { ...state.payment, isLoadingProratedAmount: true }
      };
    case GET_PRORATED_AMOUNT_SUCCESS:
      return {
        ...state, userCommuteData: { ...state.userCommuteData, ...action.data, summaryData: action.data }, payment: { ...state.payment, isLoadingProratedAmount: false }
      };
    case SAVE_SUBSCRIPTION_SUMMARY:
      return {
        ...state, payment: { ...state.payment, isSavingSubscription: true }, profile: {...state.profile, image: null}
      };
    case SAVE_SUBSCRIPTION_SUMMARY_SUCCESS:
      return {
        ...state, payment: { ...state.payment, isSavingSubscription: false }
      };
    case SAVE_SUBSCRIPTION_SUMMARY_FAILURE:
      return {
        ...state, payment: { ...state.payment, isSavingSubscription: false }
      };
    case GET_VANPOOL_INFO_SUCESS:
      return { ...state, vanInfoLoaded: true, selectedVan: { ...action.data, ...state.selectedVan } };
    case GET_VANPOOL_PARTICIPANTS_SUCESS:
      return { ...state, participantsInfoLoaded: true, selectedVan: { ...state.selectedVan, ...action.data } };
    case UPLOAD_PROFILE_PICTURE: {
      return {
        ...state,
        profile: {
          ...state.profile,
          imageLoading: true,
          imageUploadError: null,
        }
      }
    };
    case UPLOAD_PROFILE_PICTURE_SUCCESS: {
      return {
        ...state, profile: {
          ...state.profile,
          image: action.data,
          imageLoading: false,
          imageUploadError: false,
          uploadErrorOccurred: false
        }
      }
    };
    case UPLOAD_PROFILE_PICTURE_FAILURE: {
      return {
        ...state, profile: {
          ...state.profile,
          imageLoading: false,
          imageUploadError: true,
          uploadErrorOccurred: true
        }
      };
    };
    case VAN_LIST_UPDATED: {
      return {
        ...state,
        userCommuteData: {
          ...state.userCommuteData,
          isUpdated: false,
        }
      }
    }
    case SAVE_CONTRACT_DETAILS:
      return {
        ...state,
        contractInfo: action.data
      };
    case SAVE_AGREEMENT_DETAILS:
      return {
        ...state,
        contractInfo: { ...state.contractInfo, ...action.data }
      };
    case RESET_UPLOADING_ERROR:
      return {
        ...state, profile: {
          ...state.profile,
          imageUploadError: null,
        }
      }
    case SAVE_EDIT_COMMUTE_INFO:
      return {
        ...state,
        editCommute: {
          popupOpened: action.data.popupOpened
        }
      }
    case RESET_EDIT_COMMUTE_INFO:
      return {
        ...state,
        editCommute: {
          popupOpened: false
        }
      }
    default:
      return state;
  }

}
