import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  subscriptionSummary: {},
  summaryLoaded: false,
  loadError: false,
  loadErrorString: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SUBSCRIPTION_SUMMARY_LOADED:
      return { ...initialState, subscriptionSummary: action.data, summaryLoaded: true, loadError: false };
    case actionTypes.SUBSCRIPTION_SUMMARY_LOADED_ERROR:
      return { ...initialState, subscriptionSummary: action.data, summaryLoaded: true, loadError: true, loadErrorString: action.errorString };
    default:
      return state;
  }
};
