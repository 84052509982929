import { takeLatest, put, call, all, take, race } from 'redux-saga/effects';
import moment from 'moment';
import * as actionTypes from '../../redux/actionTypes';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import dateUtility from '../../shared/dateUtility';
import tripRecordingService from '../tripRecordingService';
import analyticsService from '../../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(actionTypes.TRIP_RECORDING_LOADING, tripData);
  yield takeLatest(actionTypes.TRIP_RECORDING_APPROVAL, tripRecordingApproval);
  yield takeLatest(actionTypes.TRIP_RECORDING_SAVING, saveTripData);
  yield takeLatest(
    actionTypes.TRIP_RECORDING_SAVE_AND_APPROVE,
    saveAndApproveTripData
  );
  yield takeLatest(actionTypes.TRIP_RECORDING_STATUS_VALIDATION,selectedValidation);
}

function* selectedValidation(validationData) {
  let validation = false;
    validationData.data.filter(function(items){
      items.vanpoolers.filter( function(e){
        if(e.isChanged === true) {
          validation = true;
 
        }
      });
      // if(items.timedata.isChanged === true) {
      //   validation = true;

      // }
    });
    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_VALIDATE,data : {changeValidation : validation} });
 }

function* saveAndApproveTripData({ data }) {
  try {
    yield put({ type: actionTypes.TRIP_RECORDING_SAVING, data });
    const result = yield race({
      success: take(actionTypes.TRIP_RECORDING_LOADING),
      failure: take(actionTypes.TRIP_RECORDING_NOT_SAVED)
    });

    if (result.failure) {
      return;
    }

    yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/vanpools/vanpool/tripdata`)
    );

    yield put({ type: actionTypes.TRIP_RECORDING_APPROVED });
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data: `Your ${data.monthToSave} trip data has been submitted and can no longer be updated.`
    });
    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_VALIDATE,data : {changeValidation : false} });
    analyticsService.analyticsEvent({ type: 'Trip Approved' });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_VALIDATE,data : {changeValidation : false} });
  }
}

function* saveTripData({ data }) {
  try {
    const reshapedTripData = {
      dailyTripDataList: data.tripData
        .filter(day => day.vanpoolers.length > 0)
        .filter(removeUnalteredDidNotCommuteDays)
        .map(day => {
          return {
            date: dateUtility.parseFormalDate(day.date),
            riderTripDataList: day.vanpoolers.map(vanpooler => {
              let orientation = tripRecordingService.getOrientation(vanpooler);
              return {
                riderId: vanpooler.riderId,
                orientation
              };
            }),
            workBoundStartTime: day.timedata.workBoundStartTime,
            workBoundEndTime: day.timedata.workBoundEndTime,
            homeBoundStartTime: day.timedata.homeBoundStartTime,
            homeBoundEndTime: day.timedata.homeBoundEndTime,
            workCommute: day.timedata.workCommute,
            homeCommute: day.timedata.homeCommute,
            homeBoundMiles: day.timedata.homeBoundMiles,
            workBoundMiles: day.timedata.workBoundMiles
          };
        })
    };

    yield Http.put(
      `${
        config.kongApiBaseUrl
      }/vanpools/vanpool/tripdata?current=${data.monthStatus === 'current'}`,
      reshapedTripData
    );
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data:
        'Thanks!  Your data has been saved.  Please record all trips and approve before the 10th of the following month.'
    });
    yield put({ type: actionTypes.TRIP_RECORDING_LOADING });
    yield put({ type: actionTypes.VANPOOLER_IMPACT_DATA_LOADING });
    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_VALIDATE,data : {changeValidation : false} });
    analyticsService.analyticsEvent({ type: 'Trip Recorded' });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.TRIP_RECORDING_NOT_SAVED });
    yield put({ type: actionTypes.TRIP_RECORDING_STATUS_VALIDATE,data : {changeValidation : false} });
  }
}

function* tripData() {
  try {
    const [previous, current] = (yield all([
      call(() => previousMonthTripData()),
      call(() =>
        Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/tripdata?current=true`)
      )
    ])).map((response, i) => vanpoolStatus(i, response));

    yield put({
      type: actionTypes.TRIP_RECORDING_LOADED,
      data: { current, previous, error: null }
    });
  } catch (ex) {
    logger.log(ex);

    yield put({
      type: actionTypes.TRIP_RECORDING_LOADING_ERROR,
      data: { error: true },
      errorString: ex
    });
  }
}

function* previousMonthTripData() {
  try {
    return yield call(() =>
      Http.get(
        `${config.kongApiBaseUrl}/vanpools/vanpool/tripdata?current=false`,
        {},
        () => {
          throw new Error('Could not get previous vanpool data.');
        }
      )
    );
  } catch (ex) {
    logger.log(ex);
    return { riderTripDataList: [] };
  }
}

function* tripRecordingApproval() {
  try {
    const approved = !!(yield call(() =>
      Http.get(
        `${config.kongApiBaseUrl}/vanpools/vanpool/tripdata/date`,
        {},
        () => {
          throw new Error('Could not get approval data.');
        }
      )
    )).recordingCompleteDate;

    if (approved) {
      yield put({ type: actionTypes.TRIP_RECORDING_APPROVED });
    } else {
      yield put({ type: actionTypes.TRIP_RECORDING_NOT_APPROVED });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.TRIP_RECORDING_NOT_APPROVED });
  }
}

function vanpoolStatus(current, response) {
  if (!response.riderTripDataList.length ) {
    return { vanpoolers: [], days: [], timedata: [] };
  }

  const vanpoolers = response.riderTripDataList.map(rider =>
    riderStatuses(current, rider)
  );

  const timedata = timeStatuses(current, response.vanpoolCommuteDataList)

  const days = vanpoolers[0].data.map((status, i) => ({
    date: status.date,
    future: false,
    vanpoolers: vanpoolers.map(rider => rider.data[i]),
    timedata: timedata.find(time => time.date ===  status.date)
  }));

  let lastDayOfWeek = dateUtility.getMomentInFromDateFormat(
    days[days.length - 1].date
  );
  let currentWeek = lastDayOfWeek.week();
  let currentMonth = lastDayOfWeek.month();
  lastDayOfWeek = lastDayOfWeek.add(1, 'day');

  while (
    lastDayOfWeek.week() === currentWeek &&
    lastDayOfWeek.month() === currentMonth
  ) {
    days.push({
      date: lastDayOfWeek.format('YYYY-MM-DD'),
      future: true,
      vanpoolers: [],
      timedata: []
    });
    timedata.push({
      date: lastDayOfWeek.format('YYYY-MM-DD'),
      homeBoundEndTime: null,
      homeBoundStartTime:null ,
      workBoundEndTime: null,
      workBoundStartTime:null,
      workCommute:false,
      homeCommute:false,
      homeBoundMiles: null,
      workBoundMiles: null
    })

    lastDayOfWeek = lastDayOfWeek.add(1, 'day');
  }

  return { vanpoolers, days, timedata };
}

function timeStatuses(current, driveTime) {
  let start = current
  ? moment().date(1)
  : moment()
      .date(1)
      .subtract(1, 'month');
let end = current ? moment().add(1, 'day') : start.clone().add(1, 'month');

let time = [];

while (start.isBefore(end, 'day')) {
  time.push(timeStatus(start.format('MM/DD/YYYY'), driveTime));
  start = start.add(1, 'day');
}

return time
}

function timeStatus(date, driveTime) {
  const day = driveTime.find(trip => trip.date === date);
  if (!day) {
    return {
      date: dateUtility.formatAsSortableDate(date),
      homeBoundEndTime: null,
      homeBoundStartTime: null ,
      workBoundEndTime: null,
      workBoundStartTime: null,
      workCommute:false,
      homeCommute:false,
      homeBoundMiles: null,
      workBoundMiles: null
  }
}
  return {
    date: dateUtility.formatAsSortableDate(date),
    homeBoundEndTime: day.homeBoundEndTime,
    homeBoundStartTime: day.homeBoundStartTime ,
    workBoundEndTime: day.workBoundEndTime,
    workBoundStartTime: day.workBoundStartTime,
    workCommute:day.workCommute,
    homeCommute:day.homeCommute,
    homeBoundMiles: day.homeBoundMiles,
    workBoundMiles: day.workBoundMiles
  };
}

function riderStatuses(current, rider) {
  let start = current
    ? moment().date(1)
    : moment()
        .date(1)
        .subtract(1, 'month');
  let end = current ? moment().add(1, 'day') : start.clone().add(1, 'month');

  let statuses = [];

  while (start.isBefore(end, 'day')) {
    statuses.push(riderStatus(start.format('MM/DD/YYYY'), rider));
    start = start.add(1, 'day');
  }

  return {
    id: rider.riderId,
    firstName: rider.riderFirstName,
    lastName: rider.riderLastName,
    driver: rider.isDriver,
    data: statuses
  };
}

function riderStatus(date, rider) {
  const day = rider.dailyTripDataList.find(trip => trip.date === date);

  if (!day) {
    return {
      riderId: rider.riderId,
      toWork: false,
      toHome: false,
      date: dateUtility.formatAsSortableDate(date),
      didNotCommute: true,
      saved: false,
      inVanpool: false,
      isCommuteDay: false
    };
  }

  return {
    riderId: rider.riderId,
    toWork: day.orientation === 'R' || day.orientation === 'W',
    toHome: day.orientation === 'R' || day.orientation === 'H',
    date: dateUtility.formatAsSortableDate(day.date),
    didNotCommute: day.orientation === 'U',
    saved: day.isSaved,
    inVanpool: true,
    isCommuteDay: day.isCommuteDay
  };
}

function removeUnalteredDidNotCommuteDays(day) {
  return day.vanpoolers.some(rider => !rider.didNotCommute || rider.isChanged);
}
