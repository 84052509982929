import tickIcon from '../../../../_styles/images/tick-badge.svg';
import * as actionTypes from '../../../../redux/actionTypes';
import {connect} from 'react-redux';
import closeIcon from './../../../../_styles/images/close-icon.svg';
import {useEffect} from 'react';

function ToastMessage(props) {

  useEffect(() => {
    if(props && props.toastData && props.toastData.isOpen){
      const timeID = setTimeout(() => {
        props.closeToast();
      }, 6000);
      return () => {
        clearTimeout(timeID)
      }
    }
  }, [props.toastData]);
  return (
    props.toastData.isOpen && <div className='toast-container' role='alert' aria-live='polite'>
      <div className='tick'>
        <img src={tickIcon} aria-hidden='true'></img>
      </div>
      <div className='message-container'>
        <div className='text' aria-busy='true'>
          {props.toastData.message}
        </div>
        <div className='close-section'>
          <div className='divider'></div>
          <div className='close' onClick={props.closeToast}>
            <img src={closeIcon} aria-hidden='true'/>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    toastData: state.paymentHistory.generalInfo.toastData
  }
}

function mapDispatchToProps(dispatch) {
  return {
    closeToast() {
      dispatch({type: actionTypes.HIDE_TOAST})
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToastMessage);