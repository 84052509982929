import React, { Component } from 'react';

const focusable =
  'input:not(:disabled), select:not(:disabled), textarea:not(:disabled), button:not(:disabled), [tabindex]:not([tabindex="-1"])';

export default class FormPopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      deactivating: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const activating = nextProps.active && !this.props.active;

    this.setState({ deactivating: false, activating });
  }

  componentDidUpdate() {
    if (this.state.activating) {
      this.sendFocusUp({
        key: 'Tab',
        shiftKey: false,
        stopPropagation() {},
        preventDefault() {}
      });
    }
  }

  cancelModal() {
    this.setState({ deactivating: true });
    setTimeout(this.props.cancel, 200);
  }

  saveModal() {
    this.setState({ deactivating: true });
    setTimeout(this.props.save, 200);
  }

  sendFocusUp(e) {
    if (e.key !== 'Tab' || e.shiftKey) return;

    e.stopPropagation();
    e.preventDefault();

    const toFocus = this.body.querySelector(focusable) || this.cancel;
    toFocus.focus();
  }

  sendFocusDown(e) {
    const topFocusableElement = this.body.querySelector(focusable);
    if (e.key !== 'Tab' || !e.shiftKey || e.target !== topFocusableElement)
      return;

    e.stopPropagation();
    e.preventDefault();

    if (this.props.disabled) {
      this.cancel.focus();
    } else {
      this.save.focus();
    }
  }

  render() {
    let { active, title, children, disabled } = this.props;
    let saveButtonText = this.props.saveButtonText || 'Send';
    let cancelButtonText = this.props.cancelButtonText || 'Cancel';

    if (!active) {
      return null;
    }

    return (
      <div
        className={`form-popup-container popup-container ${
          this.state.deactivating ? 'out' : ''
        }`}
      >
        <div className="popup">
          <h3 className="title">{title}</h3>
          <div
            className="body"
            ref={e => (this.body = e)}
            onKeyDown={e => this.sendFocusDown(e)}
          >
            {children}
          </div>
          <div className="tools response">
            <button
              onClick={() => this.cancelModal()}
              className="button cancel"
              ref={e => (this.cancel = e)}
              onKeyDown={e => disabled && this.sendFocusUp(e)}
            >
              {cancelButtonText}
            </button>
            <button
              onClick={() => this.saveModal()}
              className="button save primary"
              ref={e => (this.save = e)}
              onKeyDown={e => this.sendFocusUp(e)}
              disabled={disabled}
            >
              {saveButtonText}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
