import { takeLatest } from "redux-saga/effects";
import { DISPLAY_ERROR } from "../../redux/actionTypes";
import analyticsService from "../analytics/analytics-service";

export default function* () {
    yield takeLatest(DISPLAY_ERROR, sendAnalyticsForNetworkError);
}

function* sendAnalyticsForNetworkError(action) {
    yield analyticsService.analyticsProcessEvent({
        "event": "network_error",
        "context": {
          "event_action": action && action.data && action.data.toLowerCase()
        }
      });
}
