import { useEffect, useState } from "react";
import { ShortRouteDetails } from "../../../join-commute/common/route-details/ShortRouteDetails";
import MapService from "../../../shared/map-service";
import config from "../../../appConfig";
import { isEmpty } from "lodash";
import ImageNotAvailable from '../../../_styles/images/ImageNotAvailable.svg';
import Timer from '../../../_styles/images/TimerGreen.png';
const googleMapsURL = `https://maps.googleapis.com/maps/api/js?${config.googleMapsCredentials}&libraries=places,geometry,drawing`;

function DashboardVanDetails(props) {

    const [vanpool, updateVanpool] = useState(props && props.profile && props.profile.vanpool || {});
    const [userCommuteDays, updateUserCommuteDays] = useState([]);
    const [commuteDetails, updateCommuteDetails] = useState(props && props.profile && props.profile.commuteDetails || {});
    const [addresses, updateAddresses] = useState({});
    const [noVanpoolDetails, updateNoVanpoolDetails] = useState(false);

    useEffect(() => {
        const scriptjs = require('scriptjs');
        scriptjs(googleMapsURL);
    }, [])

    useEffect(() => {
        if(commuteDetails?.commuteDays?.length === 0 || !(commuteDetails?.originAddress && commuteDetails?.destinationAddress) ) {
            updateNoVanpoolDetails(true);
        }
        if (commuteDetails && commuteDetails.originAddress && commuteDetails.destinationAddress) {
            (async () => {
                try {
                    const [origin, destination] = await Promise.all([
                        MapService.getAddressAndLocation(commuteDetails.originAddress),
                        MapService.getAddressAndLocation(commuteDetails.destinationAddress),
                    ]);
                    updateAddresses({
                        origin,
                        destination
                    })
                } catch (ex) {
                    console.error(ex);
                }
            })();
        }
    }, [commuteDetails])

    useEffect(() => {
        if (props.profile) {
            updateVanpool(props.profile.vanpool);
            updateCommuteDetails(props.profile.commuteDetails);
            if (props.profile.vanpool && props.profile.vanpool.commuteDays && props.profile.vanpool.commuteDays.length !== 0) {
                updateUserCommuteDays(props.profile.vanpool.commuteDays)
            } else if (props.profile.subscription && props.profile.subscription.dashboardCommuteDays && props.profile.subscription.dashboardCommuteDays.length !== 0) {
                updateUserCommuteDays(props.profile.subscription.dashboardCommuteDays);
            }
        }
    }, [props, props.profile])

    return (
        <div className="dashboard-van-details-container" role="group">
            <div className="header" role='heading'>My Commute</div>
            {
                noVanpoolDetails ? 
                <div className="no-vanpool-sections">
                    <div className="no-vanpool"> 
                    Vanpool ID: {vanpool.id}
                    </div>
                    <div className="divider"> </div>
                    <div className="additional-details">
                    <img src={Timer} alt="wait" />
                    <div className="wait-text">Additional details about your commute will be available soon</div>
                     </div>

                </div>
                :
                <div className="sections">
                <div className="left">
                    <div className="commute-days-section">
                        <div className="heading">Commute Days</div>
                        <div className="days">
                            {
                                vanpool && vanpool.operationalDays && vanpool.operationalDays.map((day) => {
                                    const isCommuting = userCommuteDays.indexOf(day) !== -1;
                                    const isFull = vanpool.vanAvailableDays && !vanpool.vanAvailableDays.includes(day) || false;
                                    return <div key={day} aria-label={day + (isCommuting ? ' commuting' : ' not commuting') + (isFull ? ' full' : '')} className="day-group">
                                        <div className={isCommuting ? 'day commuting' : 'day notcommuting'}>{day.substring(0, 3)}</div>
                                        {isFull && <span className="full-indicator">Full</span>}
                                    </div>
                                }
                                )
                            }
                        </div>
                    </div>
                    <div className="vanpool-section van-not-desktop">
                        <div className="name">{vanpool.vehicleType}</div>
                        {props.vanpoolDetails && <div className="capacity">
                            <span>Seats: {props.vanpoolDetails.vehicleCapacity}</span>
                            <span>Riders: {props.vanpoolDetails.participantCount}</span>
                        </div>}
                        <div className={`${props.isVanForming ? 'image-opacity' : 'image'
                        }`}>
                            <img src={vanpool.vanpoolImage} onError={e => {
                                e.currentTarget.src = ImageNotAvailable;
                                e.currentTarget.className = "image-not-found";
                            }} alt='van image' />
                        </div>
                        <div className="id">Vanpool ID: {vanpool.id}</div>
                    </div>
                    {!isEmpty(commuteDetails) && !isEmpty(addresses) && <div className="address-section">
                        <ShortRouteDetails commute={commuteDetails} addresses={addresses} />
                    </div>}

                </div>
                <div className="vanpool-section van-desktop-only" role="group">
                    <div className="name">{vanpool.vehicleType}</div>
                    {props.vanpoolDetails && <div className="capacity">
                        <span>Seats: {props.vanpoolDetails.vehicleCapacity}</span>
                        <span>Riders: {props.vanpoolDetails.participantCount}</span>
                    </div>}
                    <div className={`${props.isVanForming ? 'image-opacity' : 'image'
                        }`}>
                        <img src={vanpool.vanpoolImage} onError={e => {
                            e.currentTarget.src = ImageNotAvailable;
                            e.currentTarget.className = "image-not-found";
                        }} alt='van image' />
                    </div>
                    <div className="id">Vanpool ID: {vanpool.id}</div>
                </div>
            </div>
            }

        </div>
    );
}

export default DashboardVanDetails;