import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import PhoneEntry from '../shared/inputs/PhoneEntry';
import DateOfBirthEntry from '../shared/inputs/DateOfBirthEntry';
import HomeAndMailingAddressInput from '../shared/inputs/HomeAndMailingAddressInput';

class PersonalInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validAddresses: true,
      validPhones: true,
      validBirthDate: false,
      loading: true,
      valid:true
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillReceiveProps(props) {
    this.setState(
      {
        homeAddress: props.profile.homeAddress,
        mailingAddress: props.profile.mailingAddress,
        phone: props.profile.phone,
        secondPhone: props.profile.secondPhone,
        loading: props.profile.loading,
        validAddresses: !!props.profile.homeAddress.line1,
        validPhones: !!props.profile.phone,
        valid:true
      },
      () => this.update({}, this.state)
    );
  }

  update(delta, updates) {
    this.setState(delta, () => {
      this.props.onChange({
        ...updates,
        ...delta,
        personalInformationIsValid: !!(
          this.state.validAddresses &&
          this.state.validPhones &&
          this.state.validBirthDate &&
          this.state.valid
        )
      });
    });
  }

  updateBirthdate({ validBirthDate, ...rest }) {
    this.update({ validBirthDate }, rest);
  }

  updatePhones({ validPhones, primary, secondary }) {
    this.update({ validPhones }, { phone: primary, secondPhone: secondary });
  }

  updateAddresses({ validAddresses, valid,  ...rest }) {
    this.update({ validAddresses, valid }, rest);
  }

  render() {
    const {
      homeAddress,
      mailingAddress,
      phone,
      secondPhone,
      loading
    } = this.state;

    if (loading || !phone) {
      return null;
    }

    return (
      <div>
        <p className="note">
          Any new changes below to your contact info will update your
          information in your profile.
        </p>
        <PhoneEntry
          primary={phone}
          secondary={secondPhone}
          update={updates => this.updatePhones(updates)}
        />
        <HomeAndMailingAddressInput
          homeAddress={homeAddress}
          mailingAddress={mailingAddress}
          update={updates => this.updateAddresses(updates)}
        />
        <DateOfBirthEntry onChange={updates => this.updateBirthdate(updates)} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.USER_PERSONAL_INFO_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalInformation);
