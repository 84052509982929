import dateUtility, { fromDateFormat } from '../shared/dateUtility';

const timePeriodService = {};

timePeriodService.currentQuarter = () => currentQuarter();

timePeriodService.generateQuartersList = () => {
  let quarters = [];

  quarters.push(currentQuarter());

  let numOfQuarters;
  for (numOfQuarters = 1; numOfQuarters < 12; numOfQuarters++) {
    let quarterStartMoment = dateUtility.startOfQuarter(-numOfQuarters);
    let quarterEndMoment = dateUtility.endOfQuarter(-numOfQuarters);
    quarters.push({
      startDate: dateUtility.formatMoment(quarterStartMoment, fromDateFormat),
      endDate: dateUtility.formatMoment(quarterEndMoment, fromDateFormat),
      displayValue: `${dateUtility.formatMoment(
        quarterStartMoment,
        'MMMM D'
      )} - ${dateUtility.formatMoment(quarterEndMoment, 'MMMM D, YYYY')}`
    });
  }

  return quarters.filter(
    quarter => parseInt(quarter.startDate.split(' ').pop(), 10) >= 2019
  );
};

timePeriodService.generateMonthsList = () => {
  return [
    { displayValue: 'January', value: 0 },
    { displayValue: 'February', value: 1 },
    { displayValue: 'March', value: 2 },
    { displayValue: 'April', value: 3 },
    { displayValue: 'May', value: 4 },
    { displayValue: 'June', value: 5 },
    { displayValue: 'July', value: 6 },
    { displayValue: 'August', value: 7 },
    { displayValue: 'September', value: 8 },
    { displayValue: 'October', value: 9 },
    { displayValue: 'November', value: 10 },
    { displayValue: 'December', value: 11 }
  ];
};

timePeriodService.generateYearsList = () => {
  const currentYear = new Date().getFullYear();
  const array = new Array(4).fill(currentYear);
  return array
    .map((value, index) => value - index)
    .filter(year => year >= 2019);
};

timePeriodService.currentMonth = () => {
  const currentDate = dateUtility.currentMoment();
  return {
    displayValue: currentDate.format('MMMM'),
    value: currentDate.month()
  };
};

timePeriodService.currentYear = () => {
  const currentDate = dateUtility.currentMoment();
  return currentDate.year();
};

timePeriodService.mapMonthYearToTimePeriod = (month, year) => {
  const startDate = dateUtility.formatMoment(
    dateUtility
      .getMoment(`${month.displayValue}, ${year}`, 'MMMM, YYYY')
      .startOf('month'),
    fromDateFormat
  );
  const endDate = dateUtility.formatMoment(
    dateUtility
      .getMoment(`${month.displayValue}, ${year}`, 'MMMM, YYYY')
      .endOf('month'),
    fromDateFormat
  );

  return {
    startDate,
    endDate,
    displayValue: `${month.displayValue} ${year}`
  };
};

function currentQuarter() {
  return {
    startDate: dateUtility.formatMoment(
      dateUtility.firstDayOfQuarter(dateUtility.yesterdayMoment()),
      fromDateFormat
    ),
    endDate: dateUtility.yesterday(),
    displayValue: `${dateUtility.formatMoment(
      dateUtility.firstDayOfQuarter(dateUtility.yesterdayMoment()),
      'MMMM D'
    )} - ${dateUtility.formatMoment(
      dateUtility.yesterdayMoment(),
      'MMMM D, YYYY'
    )}`
  };
}

export default timePeriodService;
