/*eslint no-extend-native: ["error", { "exceptions": ["String", "Array"] }]*/
import 'core-js/fn/array/find';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/every';
import 'core-js/fn/array/some';
import 'core-js/fn/array/fill';
import 'core-js/fn/array/from';
import 'core-js/fn/string/ends-with';
import 'core-js/fn/string/starts-with';
import 'core-js/fn/string/includes';
import 'core-js/fn/symbol/iterator';
import 'core-js/fn/symbol';

/**
 * Custom Event
 */
(function() {
  if (typeof window.CustomEvent === 'function') return false;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
      event,
      params.bubbles,
      params.cancelable,
      params.detail
    );
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

String.prototype.removeWhiteSpace = function() {
  return this.split(' ').join('');
};
