import { getDateObjFromString } from '../payment-history/helper';
import { phoneTypeList } from '../shared/FancySelect';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_APPROVED_DRIVER, PERMISSION_COORDINATOR, PERMISSION_SUBSCRIPTION_USER } from '../shared/permissions/permissions';

export function validatePhone(phoneObj, canBeEmpty = false) {
  if (phoneObj) {
    const numberValid = canBeEmpty ? (phoneObj.number && phoneObj.number.length === 12 || !phoneObj.number) : phoneObj.number && phoneObj.number.length === 12;
    const typeValid = canBeEmpty ? true : phoneTypeList.indexOf(phoneObj.type) !== -1;
    return numberValid && typeValid;
  } else {
    return canBeEmpty;
  }
}

export function getDriverStatus(driverStatus, licenseExpiryObj) {
  let status = {};
  if (driverStatus) {
    if (driverStatus.toLowerCase().includes('exception') || driverStatus.toLowerCase().includes('pending')) {
      status = {
        status: "pending"
      };
    }
    if (driverStatus.toLowerCase().includes('declined')) {
      status = {
        status: "declined"
      };
    }

    if (driverStatus.toLowerCase().includes('approved')) {
      status = checkExpiry(licenseExpiryObj);
      if (!(status && status.status)) {
        return {
          status: "approved"
        }
      } else {
        return status;
      }
    }

    if (driverStatus.toLowerCase().includes('expired')) {
      status = {
        status: "expired"
      };

    }
    return status;
  }
  return {
    status: 'N/A'
  };
}

function checkExpiry(licenseExpiryObj) {
  let status = {};
  if (licenseExpiryObj && licenseExpiryObj.licenseExpiry) {
    const today = new Date();
    const expiryDate = new Date(licenseExpiryObj.licenseExpiry);
    const dateInfo = getDateObjFromString(licenseExpiryObj.licenseExpiry);
    const dateStr = `${dateInfo.month.substring(0, 3)} ${dateInfo.day}, ${dateInfo.year}`;
    if (today.toDateString() === expiryDate.toDateString()) {
      status = {
        status: "expiring today",
        dateInfo: dateStr
      };
    } else {
      const diff = expiryDate.getTime() - today.getTime();
      const dayDiff = (diff / (1000 * 60 * 60 * 24)).toFixed();
      if (dayDiff >= 1 && dayDiff <= 5) {
        status = {
          status: "expiring",
          remainingDays: dayDiff,
          dateInfo: dateStr
        };
      } else if (dayDiff <= 0) {
        //expired in past 
        status = {
          status: "expired"
        };
      }
    }
  }
  return status;
}

export function convertKeysToLowerCase(obj) {
  const convertedObject = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      convertedObject[key.toLowerCase()] = obj[key];
    }
  }
  return convertedObject;
}

export function shouldShowAddress(stop, participantID) {
  if (isAllowed([PERMISSION_SUBSCRIPTION_USER])) {
    const isParticipantOfStop = stop?.todaysParticipants.some(participant => participant.id === participantID);
    if (!stop.isPrivateAddress || isAllowed([PERMISSION_COORDINATOR, PERMISSION_APPROVED_DRIVER]) || isParticipantOfStop) {
      return true;
    } return false;
  }
  return true;
}

export function shouldShowAddressToParticipant(stop, participantID, viewedParticipantID) {
  if (isAllowed([PERMISSION_SUBSCRIPTION_USER])) {
    if (!stop.isPrivateAddress || isAllowed([PERMISSION_COORDINATOR, PERMISSION_APPROVED_DRIVER]) || participantID === viewedParticipantID) {
      return true;
    } return false;
  } return true;
}