import * as actionTypes from '../../redux/actionTypes';
import NA_VALUE from '../../shared/na';

const initialState = {
  vanpoolName: NA_VALUE,
  vanpoolDescription: NA_VALUE,
  vanpoolId: NA_VALUE,
  vanpoolStatus: NA_VALUE,
  vanpoolStartDate: NA_VALUE,
  contractedMileage: NA_VALUE,
  insuranceCoverage: NA_VALUE,
  monthlyRentAmount: NA_VALUE,
  vehicleType: NA_VALUE,
  vehicleCapacity: NA_VALUE,
  participantCount: NA_VALUE,
  loaded: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VANPOOL_INFO_LOADED:
      return { ...state, ...initialState, ...action.data, loaded:true };
    default:
      return state;
  }
};
