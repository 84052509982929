import React, { useEffect, useMemo } from 'react';
import analyticsService from '../../../../shared/analytics/analytics-service';
import { WEEKDAYS } from '../../../../shared/inputs/Calendar/Calendar';
import { DAYS } from '../../../../shared/DaysInput';

function addDay(current, add) {
  return WEEKDAYS.filter(day => current.includes(day) || day === add);
}

function removeDay(current, remove) {
  return WEEKDAYS.filter(day => current.includes(day) && day !== remove);
}

function disabledMessage(active, disabled) {
  if (active && disabled) {
    return 'Day with riders. Remove riders and try again.';
  } else if (!active && disabled) {
    return 'Unavailable';
  } else if (active && !disabled) {
    return 'Commuting';
  } else {
    return 'Not Commuting';
  }
}

export default function SelectDays({
  value,
  notAllowed = [],
  allowed = WEEKDAYS,
  onChange,
  onlyShowRunningDays,
  runningDays,
  minDaysError,
  availableDays,
  alreadySelected,
  maxDaysError,
  maxDays,
  minDays,
  isTitleRequired = true,
  hideFullBadge
}) {

  const shownDays = useMemo(() => {
    return onlyShowRunningDays ? runningDays : DAYS;
  }, [onlyShowRunningDays, runningDays]);

  useEffect(() => {
    let errorText = '';
    if (maxDaysError) {
      errorText = `Please ensure you have selected a maximum of ${maxDays} days.`;
    }
    else if (minDaysError) {
      errorText = `Please ensure you have selected at least ${minDays ? `${minDays} days` : `1 day`}.`;
    }
    if (errorText !== '') {
     // analyticsService.pageInfo('change my commute days');
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": errorText
        }
      });
    }
  }, [maxDaysError, minDaysError, maxDays, minDays]);
  return (
    <div>
      <fieldset className="days">
        {shownDays && shownDays.map(day => ({
          day,
          disabled: notAllowed.includes(day) || !allowed.includes(day),
          unavailable: (!availableDays.includes(day) && !alreadySelected.includes(day)),
          active: value.includes(day)
        })).map(({ day, active, disabled, unavailable }) => (
          <div className='day' key={day}>
            {unavailable && !hideFullBadge && <div className='full-badge' aria-hidden='true'>Full</div>}
            <button
              role='button'
              aria-pressed={active}
              aria-selected={active}
              disabled={disabled || unavailable}
              day={day}
              aria-label={day + (unavailable ? ' full' : '')}
              onClick={() =>
                onChange(active ? removeDay(value, day) : addDay(value, day))
              }
              type="button"
              title={isTitleRequired ? disabledMessage(active, disabled) : ''}
              className={`day-btn ${active ? 'active' : ''} ${unavailable ? 'unavailable' : ''}`}
            >
              {day.substr(0, 3)}
            </button>
          </div>
        ))}
      </fieldset>
      {minDaysError && <div className='error' role='alert'>Please ensure you have selected at least {minDays ? `${minDays} days` : `1 day`}</div>}
      {maxDaysError && <div className='error' role='alert'>Please ensure you have selected a maximum of {maxDays} days.</div>}
    </div>

  );
}
