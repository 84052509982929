import config from '../appConfig';
import Http from '../shared/http-service';
import aggregateService from '../shared/aggregate/aggregate-service';
import dateUtility, { fromDateFormat } from '../shared/dateUtility';
import {
  GRID_LABEL_TOTAL,
  GRID_LABEL_GOAL,
  GRID_LABEL_POTENTIAL,
  GRID_LABEL_NTD_DATA,
  GRID_LABEL_NTD_VANPOOLS
} from './chart-service';

const aggregateUrlBase = `${config.kongAccountDashBoardBaseUrl}/enrollment`;
const aggregateTripdataUrlBase = `${config.kongAccountDashBoardBaseUrl}/trip`;
const impactAggType = {
  0: {
    aggType: isTransit =>
      isTransit
        ? "EnrollmentImpactAggTACorporate"
        : "EnrollmentImpactAggCorporate"
  },
  1: {
    aggType: isTransit =>
      isTransit
        ? "EnrollmentImpactAggTAVanpool"
        : "EnrollmentImpactAggEmployer"
  },
  2: {
    aggType: isTransit => "EnrollmentImpactAggWorksite"
  },
  3: {
    aggType: isTransit => "EnrollmentImpactAggVanpool"
  }
};

const enrollmentMontlyAggType = {
  0: {
    aggType: isTransit =>
      isTransit
        ? "EnrollmentAggByMthTACorporate"
        : "EnrollmentAggByMthCorporate"
  },
  1: {
    aggType: isTransit =>
      isTransit
        ? "EnrollmentAggByMthTAVanpool"
        : "EnrollmentAggByMthEmployer"
  },
  2: {
    aggType: isTransit => "EnrollmentAggByMthWorksite"
  },
  3: {
    aggType: isTransit => "EnrollmentAggByMthVanpool"
  }
};

const tripDataAggType = {
  0: {
    aggType: isTransit =>
      isTransit
        ? "TripAggTACorporate"
        : "TripAggCorporate"
  },
  1: {
    aggType: isTransit =>
      isTransit
        ? "TripAggregateTAVanpool"
        : "TripAggEmployer"
  },
  2: {
    aggType: isTransit => "TripAggWorksite"
  },
  3: {
    aggType: isTransit => "TripAggregateVanpool"
  }
};

const travelModes = [
  { vanpool_triprecorded: 'VANPOOL' },
  { carpool_driver_triprecorded: ['CARPOOL', 'DRIVER'] },
  { carpool_rider_triprecorded: ['CARPOOL', 'RIDER'] },
  { commuterrail_triprecorded: ['COMMUTER', 'RAIL'] },
  { lighrail_triprecorded: ['LIGHT', 'RAIL'] },
  { carsov_triprecorded: 'CAR' },
  { tnc_triprecorded: ['RIDE HAIL', '(TNC)'] },
  { bus_triprecorded: 'BUS' },
  { ferry_triprecorded: 'FERRY' },
  { bike_triprecorded: 'BIKE' },
  { walk_triprecorded: 'WALK' },
  { didnotcommute_triprecorded: ['DID NOT', 'COMMUTE'] }
];

const statsService = {};

statsService.impactData = async ({ currentAggregate, timePeriod }) => {
  const { startDate, endDate, displayValue } = timePeriod;
  const isTransit = currentAggregate.parent
    ? currentAggregate.parent.isTransit
    : currentAggregate.isTransit;
  const impactUrl = aggregateUrlBase;
  const impactData = await bodyBuilder(
    currentAggregate,
    startDate,
    endDate,
    impactUrl,
    impactAggType
  );

  if (!impactData.rows[0]) {
    return {};
  }

  const currentImpactData = impactData.rows[0].columns;
  return {
    employees: {
      count: currentImpactData.no_of_emp,
      description: 'Current Quarter'
    },
    vanpoolers: {
      count: currentImpactData.potn_vp,
      description: 'Current Quarter'
    },
    privateSubsidies: {
      count: currentImpactData.vp_private_subsidies,
      description: displayValue
    },
    transitSubsidies: {
      count: currentImpactData.vp_transit_subsidies,
      description: displayValue
    },
    vanpoolImpactData: {
      milesSaved: {
        total: mapEmptyStringToUndefined(currentImpactData.miles_saved_tot),
        goals: mapEmptyStringToUndefined(currentImpactData.miles_saved_goal),
        potential: mapEmptyStringToUndefined(
          currentImpactData.miles_saved_potn
        ),
        isCurrency: false
      },
      parkingSpotsSaved: {
        total: mapEmptyStringToUndefined(
          currentImpactData.parking_spots_saved_tot
        ),
        goals: mapEmptyStringToUndefined(currentImpactData.parking_spots_goal),
        potential: mapEmptyStringToUndefined(
          currentImpactData.parking_spots_potn
        ),
        isCurrency: false
      },
      parkingSavings: {
        total: isTransit
          ? mapEmptyStringToUndefined(
              currentImpactData.parking_spots_saved_tot * 24000
            )
          : mapEmptyStringToUndefined(currentImpactData.parking_savings_tot),
        goals: mapEmptyStringToUndefined(
          currentImpactData.parking_savings_goal
        ),
        potential: mapEmptyStringToUndefined(
          currentImpactData.parking_savings_potn
        ),
        isCurrency: true
      },
      fuelGallonsSaved: {
        total: mapEmptyStringToUndefined(
          currentImpactData.fuel_gallons_saved_tot
        ),
        goals: mapEmptyStringToUndefined(
          currentImpactData.fuel_gallons_saved_goal
        ),
        potential: mapEmptyStringToUndefined(
          currentImpactData.fuel_gallons_saved_potn
        ),
        isCurrency: false
      },
      fuelSavings: {
        total: mapEmptyStringToUndefined(currentImpactData.fuel_savings_tot),
        goals: mapEmptyStringToUndefined(currentImpactData.fuel_savings_goal),
        potential: mapEmptyStringToUndefined(
          currentImpactData.fuel_savings_potn
        ),
        isCurrency: true
      },
      co2Saved: {
        total: mapEmptyStringToUndefined(currentImpactData.co2_savings_tot),
        goals: mapEmptyStringToUndefined(currentImpactData.co2_savings_goal),
        potential: mapEmptyStringToUndefined(
          currentImpactData.co2_savings_potn
        ),
        isCurrency: false
      }
    }
  };
};

statsService.csvData = async ({ currentAggregate, timePeriod }) => {
  const { startDate, endDate } = timePeriod;
  const body = {
    agg_type: "AccountDashExtract",
    aggregate_id: aggregateService.getRootAggregate(currentAggregate).id,
    proc_dt_start: startDate,
    proc_dt_end: endDate
  };
  let results = await Http.post(
    aggregateUrlBase,
    body
  );
  return results.csvData
    ? results
    : { csvData: [['No Data'], ['No data for selected time period']] };
};

statsService.enrollmentMonthly = async ({
  currentAggregate,
  timePeriod,
  isQuarter
}) => {
  const { startDate, endDate } = timePeriod;
  const enrollmentMontlyUrl = aggregateUrlBase;
  const startDateMoment = dateUtility.getMoment(startDate, fromDateFormat);
  const endOfSelectedQuarter = dateUtility
    .getMoment(endDate, fromDateFormat)
    .endOf('quarter')
    .endOf('day');
  const beginningOfPrevQuarter = dateUtility
    .getMoment(endDate, fromDateFormat)
    .subtract(1, 'quarter')
    .startOf('quarter')
    .startOf('day');
  const passedStartDate = isQuarter
    ? dateUtility.formatMoment(beginningOfPrevQuarter, fromDateFormat)
    : startDate;
  const enrollmentMonthlyData = await bodyBuilder(
    currentAggregate,
    passedStartDate,
    endDate,
    enrollmentMontlyUrl,
    enrollmentMontlyAggType
  );

  if (!enrollmentMonthlyData.proc_month) {
    let emptyMonths = [startDateMoment];
    if (isQuarter) {
      emptyMonths = new Array(5).fill(1).map((num, index) => {
        let newMonth = beginningOfPrevQuarter.clone();
        return newMonth.add(num + index, 'month');
      });
      emptyMonths.unshift(beginningOfPrevQuarter);
    }

    const emptyMonthLabels = emptyMonths.map(date =>
      dateUtility.formatMoment(date, 'MMM')
    );

    return isQuarter
      ? {
          participants: {
            labels: emptyMonthLabels,
            datasets: []
          },
          vehicles: {
            labels: emptyMonthLabels,
            datasets: []
          },
          occupancy: {
            labels: emptyMonthLabels,
            datasets: []
          }
        }
      : {
          participants: {
            labels: [],
            datasets: []
          },
          vehicles: {
            labels: [],
            datasets: []
          },
          occupancy: {
            labels: [],
            datasets: []
          }
        };
  }

  const monthsArray = enrollmentMonthlyData.proc_month.map(date =>
    dateUtility.getMoment(date, fromDateFormat)
  );
  const possibleItemsToFill = [
    enrollmentMonthlyData.vp_partcpnt_tot,
    enrollmentMonthlyData.vp_partcpnt_potn,
    enrollmentMonthlyData.vp_partcpnt_goal,
    enrollmentMonthlyData.vp_veh_tot,
    enrollmentMonthlyData.vp_veh_potn,
    enrollmentMonthlyData.vp_veh_goal,
    enrollmentMonthlyData.ntd_data_cmpl_tot,
    enrollmentMonthlyData.ntd_vp_tot,
    enrollmentMonthlyData.vp_seat_util_goal,
    enrollmentMonthlyData.rideshare_capacity
  ];

  if (isQuarter && monthsArray.length < 6) {
    if (monthsArray[0].diff(beginningOfPrevQuarter, 'months') > 0) {
      while (monthsArray[0].diff(beginningOfPrevQuarter, 'months') > 0) {
        let newMonth = monthsArray[0].clone();
        newMonth.subtract(1, 'month');
        monthsArray.unshift(newMonth);
        possibleItemsToFill.forEach(item => item.unshift(null));
      }
    }

    if (
      endOfSelectedQuarter.diff(monthsArray[monthsArray.length - 1], 'months') >
      0
    ) {
      while (
        endOfSelectedQuarter.diff(
          monthsArray[monthsArray.length - 1],
          'months'
        ) > 0
      ) {
        let newMonth = monthsArray[monthsArray.length - 1].clone();
        newMonth.add(1, 'month');
        monthsArray.push(newMonth);
        possibleItemsToFill.forEach(item => item.push(null));
      }
    }
  }

  const monthLabels = monthsArray.map(date =>
    dateUtility.formatMoment(date, 'MMM')
  );

  return {
    participants: {
      labels: isQuarter ? monthLabels : [],
      datasets: filterDataset([
        isArrayEmpty(GRID_LABEL_TOTAL, enrollmentMonthlyData.vp_partcpnt_tot),
        isArrayEmpty(GRID_LABEL_GOAL, enrollmentMonthlyData.vp_partcpnt_goal),
        isArrayEmpty(
          GRID_LABEL_POTENTIAL,
          enrollmentMonthlyData.vp_partcpnt_potn
        )
      ])
    },
    vehicles: {
      labels: isQuarter ? monthLabels : [],
      datasets: filterDataset([
        isArrayEmpty(
          GRID_LABEL_NTD_DATA,
          isQuarter
            ? populateNTDData(
                enrollmentMonthlyData.ntd_data_cmpl_tot,
                monthsArray
              )
            : enrollmentMonthlyData.ntd_data_cmpl_tot
        ),
        isArrayEmpty(GRID_LABEL_NTD_VANPOOLS, enrollmentMonthlyData.ntd_vp_tot),
        isArrayEmpty(GRID_LABEL_TOTAL, enrollmentMonthlyData.vp_veh_tot),
        isArrayEmpty(GRID_LABEL_GOAL, enrollmentMonthlyData.vp_veh_goal),
        isArrayEmpty(GRID_LABEL_POTENTIAL, enrollmentMonthlyData.vp_veh_potn)
      ])
    },
    occupancy: {
      labels: isQuarter ? monthLabels : [],
      datasets: filterDataset([
        isArrayEmpty(
          GRID_LABEL_TOTAL,
          calculateOccupancyTotal(
            enrollmentMonthlyData.rideshare_capacity,
            enrollmentMonthlyData.vp_partcpnt_tot
          )
        ),
        isArrayEmpty(GRID_LABEL_GOAL, enrollmentMonthlyData.vp_seat_util_goal),
        {
          label: GRID_LABEL_POTENTIAL,
          data: new Array(isQuarter ? 6 : 1).fill(100)
        }
      ])
    }
  };
};

statsService.tripData = async ({ currentAggregate, timePeriod }) => {
  const { startDate, endDate } = timePeriod;
  const isTransit = currentAggregate.parent
    ? currentAggregate.parent.isTransit
    : currentAggregate.isTransit;
  const tripDataUrl = aggregateTripdataUrlBase;
  const tripData = await bodyBuilder(
    currentAggregate,
    startDate,
    endDate,
    tripDataUrl,
    tripDataAggType
  );

  const currentTripData = tripData.rows[0].columns;

  if (!tripData.rows[0]) {
    return {
      commutePrimaryModes: {
        labels: travelModes.map(item => item[Object.keys(item)[0]]),
        datasets: new Array(travelModes.length - 1).fill(0)
      }
    };
  }

  return {
    activeUsers: {
      total: mapEmptyStringToUndefined(currentTripData['active_users_total']),
      goals: mapEmptyStringToUndefined(currentTripData['active_users_goal']),
      potential: mapEmptyStringToUndefined(
        currentTripData['active_users_potential']
      )
    },
    commutePrimaryModes: {
      labels: travelModes.map(item => item[Object.keys(item)[0]]),
      datasets: tripModesBuilder(currentTripData.commute_primary_modes)
    },
    recordedTripsImpactData: {
      milesSaved: {
        total: mapEmptyStringToUndefined(currentTripData.miles_saved_total),
        goals: mapEmptyStringToUndefined(currentTripData.miles_saved_goal),
        potential: mapEmptyStringToUndefined(
          currentTripData.miles_saved_potential
        ),
        isCurrency: false
      },
      parkingSpotsSaved: {
        total: mapEmptyStringToUndefined(
          currentTripData.parking_spots_saved_total
        ),
        goals: mapEmptyStringToUndefined(currentTripData.parking_spots_goal),
        potential: mapEmptyStringToUndefined(
          currentTripData.parking_spots_potential
        ),
        isCurrency: false
      },
      parkingSavings: {
        total: isTransit
          ? mapEmptyStringToUndefined(
              currentTripData.parking_spots_saved_total * 24000
            )
          : mapEmptyStringToUndefined(currentTripData.parking_savings_total),
        goals: mapEmptyStringToUndefined(currentTripData.parking_savings_goal),
        potential: mapEmptyStringToUndefined(
          currentTripData.parking_savings_potential
        ),
        isCurrency: true
      },
      fuelGallonsSaved: {
        total: mapEmptyStringToUndefined(
          currentTripData.fuel_gallons_saved_total
        ),
        goals: mapEmptyStringToUndefined(
          currentTripData.fuel_gallons_saved_goal
        ),
        potential: mapEmptyStringToUndefined(
          currentTripData.fuel_gallons_saved_potential
        ),
        isCurrency: false
      },
      fuelSavings: {
        total: mapEmptyStringToUndefined(currentTripData.fuel_savings_total),
        goals: mapEmptyStringToUndefined(currentTripData.fuel_savings_goal),
        potential: mapEmptyStringToUndefined(
          currentTripData.fuel_savings_potential
        ),
        isCurrency: true
      },
      co2Saved: {
        total: mapEmptyStringToUndefined(currentTripData.co2_saved_total),
        goals: mapEmptyStringToUndefined(currentTripData.co2_saved_goal),
        potential: mapEmptyStringToUndefined(
          currentTripData.co2_saved_potential
        ),
        isCurrency: false
      }
    }
  };
};

const tripModesBuilder = modes => {
  const { labels, datasets } = modes;
  const data = travelModes.map(
    item => datasets[0].data[labels.indexOf(Object.keys(item)[0])]
  );
  return [
    {
      label: 'travel modes',
      data
    }
  ];
};

const bodyBuilder = async (
  currentAggregate,
  startDate,
  endDate,
  aggregateUrl,
  aggregateType
) => {
  const aggregateIndex = aggregateService.getAggregateIndex(currentAggregate);
  const isTransit = currentAggregate.parent
    ? currentAggregate.parent.isTransit
    : currentAggregate.isTransit;
  const employer = aggregateService.getEmployer(
    currentAggregate,
    aggregateIndex,
    isTransit
  );
  const worksite = aggregateService.getWorksite(
    currentAggregate,
    aggregateIndex,
    isTransit
  );
  const vanpool = aggregateService.getVanpool(
    currentAggregate,
    aggregateIndex,
    isTransit
  );
  const body = {
    agg_type: aggregateType[aggregateIndex].aggType(isTransit),
    aggregate_id: aggregateService.getRootAggregate(currentAggregate).id,
    employer_id: employer ? employer.id : undefined,
    worksite_id: worksite ? worksite.id : undefined,
    vp_id: vanpool ? vanpool.id : undefined,
    proc_dt_start: startDate,
    proc_dt_end: endDate
  };
  return await Http.post(aggregateUrl, body);
};

const populateNTDData = (ntdDataArray, selectedMonths) => {
  const currentMonth = dateUtility.currentMoment();
  const defaultNTDData = new Array(6).fill(null);
  const isCurrentMonthIndex = selectedMonths
    .map(month => month.month())
    .indexOf(currentMonth.month());
  if (
    isCurrentMonthIndex > -1 &&
    selectedMonths[isCurrentMonthIndex].year() === currentMonth.year()
  ) {
    defaultNTDData[isCurrentMonthIndex] = ntdDataArray[isCurrentMonthIndex];
    return defaultNTDData;
  }
  return defaultNTDData;
};

const calculateOccupancyTotal = (numerator, denominator) => {
  return denominator.map((number, index) => {
    if (!number) {
      return null;
    }

    let currentNumeratorValue = numerator[index];
    if (!currentNumeratorValue) {
      return null;
    }

    return Math.floor((currentNumeratorValue / number) * 100);
  });
};

const filterDataset = dataset => {
  return dataset.filter(data => data !== undefined);
};

const isArrayEmpty = (label, data) => {
  const nullifiedData = nullifyArray(data);
  if (nullifiedData.every(item => item === null)) {
    return;
  }
  return {
    label,
    data: nullifiedData
  };
};

const nullifyArray = data => {
  return data.map(item => (item === '' ? null : item));
};

function mapEmptyStringToUndefined(value) {
  return value || undefined;
}

export default statsService;
