export function checkForDeeplink(page) {
    switch (page) {
        case 'myprofile':
            return '/myprofile';
        case 'myprofile/applyToDrive':
            return '/myprofile/applyToDrive';
        case 'myprofile/manageSubscription':
            return '/myprofile'
        case 'myprofile/commuteDetails':
            return '/myprofile'
         case 'trip-recording':
            return '/trip-recording'
        case 'trip-recording/nonQrChecking':
            return '/trip-recording'
        case 'driver-schedule':
            return '/driver-schedule'
        case 'vanpool/roster':
            return '/vanpool/roster'
        default:
            return null;
    }
};

export function parseQueryParams(params) {
    const queryParts = params.substr(1).split('&');

    let query = {};

    for (let i = 0; i < queryParts.length; i++) {
        let key = decodeURIComponent(queryParts[i].split('=')[0]);
        let value = decodeURIComponent(queryParts[i].split('=')[1]);

        query[key] = value;
    }
    return query;
}

export function isMobile() {
    var obj = {
        isAndroid: false,
        isIphone: false,
    }
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        if(regex.test(navigator.userAgent)){
            if (/Android/i.test(navigator.userAgent)) {
                obj.isAndroid = true;
            } else if (/iPhone/i.test(navigator.userAgent)) {
                obj.isIphone = true
            }
        return obj;

        }else{
            return false;
        }
     ;
}