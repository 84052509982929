import React from 'react';

export default function Slider({ label, checked, onChange, disabled, selected }) {
  return (
    <div className="slider-container">
      <div className="text"> {label}</div>
      <div className="toggle">
        <div className="selected">{selected ? "Yes": "No"}</div>
        <label className="switch">
          <input
            type="checkbox"
            className="tog-btn"
            checked={!!checked}
            onChange={onChange}
            disabled={disabled}
          />
          <div className="slider round">
            <span className="on" />
            <span className="off" />
          </div>
        </label>
      </div>
    </div>
  );
}
