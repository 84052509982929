import Loading from '../../shared/loading/Loading';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import { useRef } from 'react';
import analyticsService from '../../shared/analytics/analytics-service';
import AddressSection from '../../profile/components/common/AddressSection';
import { isEmpty, isEqual } from 'lodash';
import Location from '../../_styles/images/new-icons/Location.svg';
import Tick from '../../_styles/images/tick.svg';
import AddressUtility from '../../shared/addressUtility';
import Badge from '../../_styles/images/question-icon.svg';
import ContactPopup from '../../shared/components/contact-popup/ContactPopup';
import { initialJoinCommuteState } from '../JoinCommuteReducer';

function ApplyToCoordinate(props) {

  const homeAddressPopupNode = useRef(null);

  const [startAddress, updateStartAddress] = useState('');
  const [homeAddress, updateHomeAddress] = useState({});
  const [homeAddressValid, updateHomeAddressValid] = useState(true);
  const [continueDisabled, updateContinueDisabled] = useState(true);
  const [isChecked, updateIsChecked] = useState(true);
  const [showHomeAddressPopup, setShowHomeAddressPopup] = useState(false);

  useEffect(() => {
    if (isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    }
    checkAndUpdateDataFromStore();
  }, []);

  function checkAndUpdateDataFromStore() {
    if (props.driverApplicationData && props.driverApplicationData.apiData) {
      const appData = props.driverApplicationData.apiData.driverCoordInformation;
      const homeAddressFromStore = appData.homeAddress;
      if (homeAddressFromStore && !isEmpty(homeAddressFromStore)) {
        updateHomeAddress(homeAddressFromStore);
        updateHomeAddressValid(true);
        if (isEqual(props.startAddress, homeAddressFromStore)) {
          updateIsChecked(true);
        } else {
          updateIsChecked(false);
        }
      } else {
        updateIsChecked(true);
      }
    }
  }

  function onHomeAddressChange(value, valid) {
    updateHomeAddress(value);
    const prevHomeAddressValid = homeAddressValid;
    updateHomeAddressValid(valid);
    if (value !== "" && prevHomeAddressValid !== valid && !valid) {
      analyticsService.analyticsProcessEvent({
        "event": "user_error",
        "context": {
          "event_action": "Please enter a valid address"
        }
      })
    }
  }

  useEffect(() => {
    if (isChecked) {
      updateHomeAddress({});
      updateHomeAddressValid(true);
    }
  }, [isChecked])

  useEffect(() => {
    if (isChecked) {
      updateContinueDisabled(false);
    } else {
      if (homeAddress && !isEmpty(homeAddress) && homeAddressValid) {
        updateContinueDisabled(false);
      } else {
        updateContinueDisabled(true);
      }
    }

  }, [homeAddress, homeAddressValid, isChecked])

  useEffect(() => {
    if (props.startAddress) {
      const combinedAddress = AddressUtility.getAddressStringFromObject(props.startAddress);
      updateStartAddress(combinedAddress);
    }
  }, [props.startAddress])

  function setApplicationData() {
    let appData;
    if ((props.profile && props.profile.driverApplicationData && props.profile.driverApplicationData.apiData && !isEmpty(props.profile.driverApplicationData) && !isEmpty(props.profile.driverApplicationData.apiData))) {
      appData = props.profile.driverApplicationData;
    } else {
      appData = { apiData: { driverCoordInformation: {} } };
    }
    if (isChecked) {
      appData = {
        driverCoordInformation: {
          ...appData.apiData.driverCoordInformation,
          homeAddress: props.startAddress,
        }
      }
    } else {
      appData = {
        driverCoordInformation: {
          ...appData.apiData.driverCoordInformation,
          homeAddress,
        }
      }
    }
    props.setApplicationData(appData);
    props.updateLoading(true);
    props.getDocusignContract(props.isDesiredDriverRole, props.selectedVan.vanpoolId, props.history, '/JoinCommute/add-payment');
    analyticsService.analyticsProcessEvent({
      "event": isChecked ? "verify_home_address_click_continue" : 'verify_home_address_click_continue_new_address',
      "context": {
        "event_action": 'continue',
        "event_detail": {
            "is_checked": isChecked ? 'checked' : 'unchecked'
        }
      }
    });
  }

  useEffect(() => {
    checkAndUpdateDataFromStore();
  }, [props.driverApplicationData])

  return (
    <div className='profile-overview-page coordinator-page'>
      <Loading isLoading={props.isLoading} />
      <div className='profile-edit-page apply-coordinator-page'>
        <div className='view-container' role='group'>
          <div className='content-container' role='group'>
            <div className='heading'>Verify Home Address</div>
            <div className='small-heading verify'>Is your Commute Start also your home address?</div>
            <div className='verify-address'>
              <img src={Location} aria-hidden />
              <div className='address-view'>
                <div className='label'>Commute Start</div>
                <div className='text'>{startAddress}</div>
              </div>
            </div>
            <div className='checkbox-section'>
              <div
                className={`checkbox ${isChecked ? 'selected' : ''}`}
                role='checkbox'
                aria-checked={isChecked}
                aria-label={isChecked ? 'This is also my home address' : 'check to confirm this is also my home address'}
                onClick={() => updateIsChecked(!isChecked)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    updateIsChecked(!isChecked);
                  }
                }}
              >
                {isChecked && <img src={Tick} alt='Checked' />}
              </div>
              <div className='text'>This is also my home address</div>
            </div>

            {
              !isChecked &&
              <div>
                <AddressSection onChange={onHomeAddressChange} address={homeAddress} label="Home Address*" />
              </div>
            }
            <div className='tooltip-link-container' onClick={() => setShowHomeAddressPopup(true)} role='button'>
              <img src={Badge} alt="tooltip icon" aria-hidden='true' />
              <span className='link-text'>Why is this needed?</span>
            </div>
            <button className='save-button' disabled={continueDisabled} onClick={setApplicationData}>Continue</button>
          </div>
        </div>
        {showHomeAddressPopup && <ContactPopup showCloseButton={true} class='verify-home-address' heading='Why is my home address needed?' firstText='We use your home address to send you important information about your vanpool, and for any required ridership reporting' reference={homeAddressPopupNode} close={() => setShowHomeAddressPopup(false)} />}
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    isLoaded: state.profile.isLoaded,
    isLoading: state.joinCommute.loading,
    isDesiredDriverRole: state.joinCommute.drivingScheduleData.isDesiredDriverRole,
    selectedVan: state.joinCommute.selectedVan,
    joinCommuteData: state.joinCommute,
    driverApplicationData: state.profile.driverApplicationData,
    startAddress: state.joinCommute.userCommuteData.homeAddressExpanded,
    prevHomeAddress: state.joinCommute.driverApplicationData && state.joinCommute.driverApplicationData.driverCoordInformation && state.joinCommute.driverApplicationData.driverCoordInformation.homeAddress
  }
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading(data) {
      dispatch({ type: actionTypes.LOADER_ACTIVE, data });
    },
    getDocusignContract(isDriver, vanpoolId, history, navigateTo) {
      dispatch({ type: actionTypes.APPLY_TO_SIGN_CONTRACT, data: { isDriver: isDriver, vanpoolId: vanpoolId, history, navigateTo } })
    },
    setApplicationData(apiData) {
      dispatch({ type: actionTypes.SET_APPLY_TO_DRIVE_DATA, data: apiData });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplyToCoordinate);