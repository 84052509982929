import { useState, useEffect } from 'react';
import { PERMISSION_COORDINATOR, PERMISSION_DRIVER } from '../../shared/permissions/permissions';

import AlertIcon from '../../_styles/images/AlertIcon.png';
import { Link } from 'react-router-dom';
import { RestrictTo } from '../../shared/permissions/RestrictTo';
import dateUtility from '../../shared/dateUtility';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';
import rightArrow from '../../_styles/images/rightArrow.png';
import analyticsService from '../../shared/analytics/analytics-service';

export default function MainteanceAlert(props) {
  const { vehicleDetails, vanpoolStatus } = props;

  function handleClick() {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "maintenance"
      }
    });
  }

  return (
    // <div className="other-information">
    <>
      {vanpoolStatus === 'P' || vanpoolStatus === 'A' ? null :<div className="maintenance-due">
        <div className="alert-icon">
          <img src={AlertIcon} alt="Alert Icon"></img>
        </div>
        <div className="middle-container">
          <div  className='first-section'>
            <h3>Preventative Maintenance Due</h3>
            <div className="due-on">
              Due on{' '}
              <span>
                {dateUtility.parseDateAsMonthDayYear(
                  vehicleDetails.nextScheduledMaintenanceDate
                )}{' '}
              </span>{' '}
              or at{' '}
              <span>{numberWithCommas(vehicleDetails.nextScheduledMaintenanceOdometer)} mi</span>
            </div>
            <div className="last-completed">
              Last completed on{' '}
              <span>
                {dateUtility.parseDateAsMonthDayYear(
                  vehicleDetails.lastPreventativeMaintenanceOdometerDate
                )}{' '}
              </span>{' '}
              at{' '}
              <span>{numberWithCommas(vehicleDetails.lastPreventativeMaintenanceOdometer)} mi</span>
            </div>
          </div>
          <RestrictTo roles={[PERMISSION_DRIVER, PERMISSION_COORDINATOR]} required={false}>
            <div className="view-container" onClick={() => handleClick()}>
            <Link className="view-desktop" to="/vanpool/maintenance" tabIndex={0}>
              View
            </Link>
            <img className="view-mobile"  onClick={() =>  props.history.push("/vanpool/maintenance")} tabIndex={0} src={rightArrow} alt={'view right arrow'} ></img>
            </div>
          </RestrictTo>
        </div>
      </div> }
    </>
    // </div>
  );
}
