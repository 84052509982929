import React from 'react';
import Profile from '../shared/profile-image';
import { Unchecked, Checked, NoComment, Comment } from '../shared/icons';
import DollarInput from '../shared/inputs/DollarInput';
import { MaxLengthTextEditor } from '../shared/InlineTextEditor';

export default function Participant({
  name,
  roles,
  selected,
  amount,
  commenting,
  comments,
  toggleSelected,
  onChange,
  toggleComment,
  updateComments
}) {
  return (
    <div className={`participant ${commenting ? 'expand' : ''}`}>
      <button
        className={`selected ${selected ? 'chosen' : ''}`}
        onClick={toggleSelected}
      >
        {selected ? <Checked /> : <Unchecked />}
      </button>
      <Profile>{name}</Profile>
      <div className="credentials">
        <div className="name">{name}</div>
        {roles !== 'Rider' ? <div className="roles">{roles}</div> : null}
      </div>
      <DollarInput
        value={amount}
        onChange={onChange}
        className="amount"
        disabled={!selected}
      />
      <button
        className={`comment-toggle ${commenting ? 'chosen' : ''}`}
        onClick={toggleComment}
        disabled={!selected}
      >
        {commenting ? <Comment /> : <NoComment />}
      </button>
      {commenting ? (
        <MaxLengthTextEditor
          maxLength={500}
          rows={2}
          autoFocus
          value={comments}
          onChange={value => updateComments(value)}
          placeholder="Comments to individual..."
        />
      ) : null}
    </div>
  );
}
