import React, { Component } from 'react';
import Collapsible from 'react-collapsible';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import config from '../appConfig';
import {
  FuelCardChargesHeading,
  FuelCardChargesTable
} from './fuelcard-charges/FuelCardCharges';

class FuelCardSummary extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    return (
      <div>
        <div className="logo">
          <img src={`${config.imagePath}/wex-logo.png`} alt="Wex Card" />
        </div>
        <div className="etable card-summary-table">
          <div className="etable-body">
            <div className="etable-row header-labels">
              <div className="etable-header fc-service-month">Month</div>
              <div className="etable-header fc-activity-date">
                Activity Date
              </div>
              <div className="etable-header merchant">Merchant</div>
              <div className="etable-header auth-num">Authorization Number</div>
              <div className="etable-header gallons">Gallons</div>
              <div className="etable-header right">Amount</div>
            </div>
            {this.props.tableData
              ? this.props.tableData.map((data, index) => {
                  return (
                    <Collapsible
                      trigger={<FuelCardChargesHeading month={data} />}
                      tabIndex={index}
                      key={data.activityMonth}
                      open={index===0}
                    >
                      <FuelCardChargesTable transactions={data.transactions} />
                    </Collapsible>
                  );
                })
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = state => {
  return {
    tableData: state.fuelCardPayment.summary.fuelActivities
  };
};

export const mapDispatchToProps = dispatch => {
  return {
    init() {
      dispatch({ type: actionTypes.GET_FUEL_CARD_SUMMARY });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FuelCardSummary);
