import { Component } from 'react';
import config from '../appConfig';
import nonceUtility from './nonceUtility';

class RedirectAzure extends Component {
  componentDidMount() {
    window.location.href = nonceUtility.addNonceToUrl(
      config.redirectLoginToUrl
    );
  }

  render() {
    return null;
  }
}

export default RedirectAzure;
