import {useEffect, useRef, useState} from 'react';
import Badge from '../_styles/images/badge.svg';
import CloseIcon from '../_styles/images/close-icon.svg';
import InfoIcon from '../_styles/images/new-icons/info-icon.svg'

export default function Tooltip(props) {

  const [open, updateOpen] = useState(false);
  
  const node = useRef();
  
  const clickOutside = (e) => {
    if(node.current.contains(e.target)) {
      return;
    }
    updateOpen(false)
  }
  
  useEffect(() => {
    document.addEventListener('mousedown',clickOutside);
    return () => {
      document.removeEventListener('mousedown',clickOutside);
    }
  },[open])

  function showInfo(event) {
    if (event.key === 'Enter') {
      updateOpen(!open);
    }
  }

  const onBadgeClick = (e) => {
    e.stopPropagation();
    if (
      e.target.className == 'closeIcon' ||
      e.currentTarget.className == 'info '
    ) {
      updateOpen(!open);
    }
  }

  function onKeyDown(e) {
    if(e.which === 13){
      props.onClick && props.onClick();
      props.onBadgeClick && props.onBadgeClick();
    }
  }

    return (
      <div
        className={`info ${open ? 'open' : ''}`}
        onClick={props.onClick || onBadgeClick}
        tabIndex={0}
        aria-labelledby={props.tooltipLabel}
        onKeyPress={event => showInfo(event)}
        onKeyDown={onKeyDown}
        ref={node}
        role='button'
      >
        <img src={props.newIcon ? InfoIcon : Badge} id={props.tooltipLabel} alt={`${props.tooltipLabel} tooltip`}/>
        {props.message && <div className="message" role='alert'>
          <img className='closeIcon' src={CloseIcon} alt="close icon" role='button' tabIndex={0}/>
          <span>
          {props.message}
          </span>
        </div>}
      </div>
    );
}
