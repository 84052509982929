import React from 'react';
import { Pencil } from '../../shared/icons';

export default function DaysPage({ days, edit, editable, isSubscription }) {
  return (
    <div className="column">
      <div className="note">Active Commute Days</div>
      <div className="days">
        <div className="route-days">
          {days.map(day => day.substr(0, 3)).join(', ')}
        </div>
        {editable && !isSubscription ? <Pencil onClick={edit} /> : null}
      </div>
    </div>
  );
}
