import uuidv4 from 'uuid/v4';

const nonceUtility = {};

nonceUtility.generateAndStoreNonce = () => {
  const nonce = uuidv4();
  localStorage.setItem('nonce', nonce);
  return nonce;
};

nonceUtility.addNonceToUrl = url => {
  return (
    url +
    (url.includes('?') ? '&' : '?') +
    'nonce=' +
    nonceUtility.generateAndStoreNonce()
  );
};

export default nonceUtility;
