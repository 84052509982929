import { Link } from 'react-router-dom';
import dateUtility from '../../shared/dateUtility';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

export default function InvoiceOverview(props) {
  const { invoiceMonth, invoiceYear, invoiceDate, lastUpdatedDateTime, invoiceAmount, areInvoicesAvailable } = props;

  const getBillingperiod = invoiceMonth && invoiceYear ? dateUtility.monthNameFromIndex(invoiceMonth) + " " + invoiceYear : "";
  const statementDate = invoiceDate ? dateUtility.parseDateAsMonthDayYear(invoiceDate) : "";
  const lastUpdatedTime = dateUtility.parseDateAsMonthDayYearFromGMT(lastUpdatedDateTime)

  return (
    <div className='invoiceOverview' role='region'>
      <div className='invoiceHeading' id='invoiceHeading'>
        <div className='invoice' role='heading' aria-level='2'>
          Invoices
        </div>
        {
          areInvoicesAvailable &&
          <Link className="view-link green-link-underline" to="/payment/summary/invoices">
            view
          </Link>

        }
      </div>
      {lastUpdatedTime && <div className='lastUpdate' role='note'>
        Last updated {lastUpdatedTime}
      </div>}
      {
        (invoiceMonth || invoiceYear || invoiceDate || invoiceAmount) ?
          <div className='invoice-summary-details' role='region'>
            {invoiceAmount && <div className='currentInvoice' role='group'>
              <label>Current invoice</label><span className='amount'>${numberWithCommas(invoiceAmount)}</span>
            </div>}
            {invoiceMonth && invoiceYear && invoiceDate && invoiceAmount &&
              <div className='invoiceDetails'>
                <div className='billingDetails' role='group'>
                  <div className='heading'>Billing Period</div>
                  <div className='date'>{getBillingperiod}</div>
                </div>
                <div className='billingDetails statement' role='group'>
                  <div className='heading'>Statement Date</div>
                  <div className='date'>{statementDate}</div>
                </div>
              </div>
            }
            <div className="invoice-link green-link-underline" onClick={props.getCurrentInvoice}>
              View current invoice
            </div>
          </div>
          :
          (!areInvoicesAvailable && <div className='no-records'>You don’t have any recent invoices.</div>)
      }

    </div>
  )
}