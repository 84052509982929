import * as actionTypes from '../redux/actionTypes';

const initialState = { confirmation: {}, showCoordinatorThankyou: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LAUNCH_DOCUSIGN:
      return { ...state, ...action.data, confirmation: {} };
    case actionTypes.DOCUSIGN_CLOSE:
      return { ...action.data, nextProfile: state.nextProfile };
    case actionTypes.SET_NEXT_USER_AUTHORIZATION:
      return { ...state, nextProfile: action.data };
    case actionTypes.SET_USER_AUTHORIZATION:
      return { ...state, nextProfile: null };
    case actionTypes.CLOSE_BANNER:
      return state;
    case actionTypes.SHOW_COORDINATOR_THANKYOU_SCREEN:
      return { ...state, showCoordinatorThankyou: action.data}
    default:
      return { ...state, confirmation: {} };
  }
};
