import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import InfoChangedPopup from './InfoChangedPopup';


function EmailVerification(props) {

  const [email, updateEmail] = useState('');
  const [employer, updateEmployer] = useState('');
  const [showInfoChanged, updateShowInfoChanged] = useState(false);

  useEffect(() => {
    if (props.profile.email && props.profile.employerName) {
      updateEmployer(props.profile.employerName);
      updateEmail(props.profile.email)
    }

  }, [props.profile]);

  function continueWithFindVan() {
    props.history.push("/JoinCommute")
  }


  return (
    <div className='profile-overview-page coordinator-page'>
      <div className='profile-edit-page email-verification-page'>
        <div className='view-container' role='group'>
          <div className='content-container' role='group'>
            <div className='heading'>Verify Information</div>
            <div className='small-heading verify'>Is your information still accurate?</div>
            <div className='email-section'>
              <div className='email-view'>
                <div className='label'>Email</div>
                <div className='text'>{email}</div>
              </div>
              <div className='email-view'>
                <div className='label'>Work at</div>
                <div className='text'>{employer}</div>
              </div>
            </div>
            <div className='button-container'>
              <button className='continue-button' onClick={continueWithFindVan}>yes, continue</button>
              <button className='change-button' onClick={() => updateShowInfoChanged(true)}>No, my info has changed</button>
            </div>


          </div>
        </div>
      </div>
      {
        showInfoChanged && <InfoChangedPopup close={() => updateShowInfoChanged(false)} />
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    commuteProfile: state.commuteProfile
  }
}

function mapDispatchToProps(dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);