import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  current: [],
  previous: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VANPOOL_MY_TRIPS_LOADED:
    case actionTypes.REGISTERED_USER_MY_TRIPS_LOADED:
      return { ...state, ...action.data };
    default:
      return state;
  }
};
