import * as actionTypes from '../redux/actionTypes';
import NA_VALUE from '../shared/na';

const initialState = {
  amount: NA_VALUE,
  billingDate: NA_VALUE,
  requestedPayments: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.PAYMENT_MANAGEMENT_LOADED:
    case actionTypes.PAYMENT_ALLOCATION_LOADED:
      return { ...initialState, ...action.data };
    default:
      return state;
  }
}
