import React from 'react';
import { Route } from 'react-router-dom';
import Invoice from './Invoice';
import InvoiceMobilePDF from './invoiceMobilePDF';
import InvoiceMobileView from './InvoiceMobileView';
import InvoiceDesktopPDF from './invoiceDesktopPDF';

export default function(props) {
  return (
    <div className="invoice-detail">
      <Route path={`${props.match.path}`} exact component={Invoice} />
      <Route path={`${props.match.path}/details`} exact component={InvoiceMobileView} />
      <Route path={`${props.match.path}/view`} exact component={InvoiceMobilePDF} />
      <Route path={`${props.match.path}/desktop-view`} exact component={InvoiceDesktopPDF} />
    </div>
  );
}
