import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import React from 'react';
import Idle from 'react-idle-timer';
import TermsAndConditions from '../terms-and-conditions/TermsAndCondtions';
import config from '../appConfig';
import logoutService from '../login/logoutService';

let PrivateRoutePresentation = ({
  component: PassedComponent,
  hasUser,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        hasUser ? (
          <div className="private-route">
            <Idle
              timeout={config.idleTimeout}
              idleAction={() => {
                logoutService.logout();
              }}
            />
            <TermsAndConditions />
            <PassedComponent {...props} />
          </div>
        ) : null
      }
    />
  );
};

function mapStateToProps(state) {
  return {
    hasUser: !!state.loginState.hasToken
  };
}

export default connect(mapStateToProps)(PrivateRoutePresentation);
