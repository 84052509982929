import React from 'react';

export default function({
  employer,
  worksiteId,
  workAddress,
  isPartner,
  validEmployer,
  cancel,
  save
}) {
  return (
    <div className="tools">
      <button className="button small cancel" onClick={cancel}>
        Cancel
      </button>
      <button
        className="button small save"
        onClick={save}
        disabled={
          !employer ||
          (!worksiteId && !workAddress && isPartner) ||
          !validEmployer
        }
      >
        Update Commute
      </button>
    </div>
  );
}
