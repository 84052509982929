import Clock from '../../../_styles/images/new-icons/sprout-green.svg';
import Savings from '../../../_styles/images/new-icons/savings-green.svg';
import Sprout from '../../../_styles/images/new-icons/clock-green.svg';
import GasPump from '../../../_styles/images/new-icons/gas-pump-green.svg';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import People from '../../../_styles/images/new-icons/people-green.svg';
import ContactInfo from '../../ContactInfo';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function NewRegisteredUser(props) {

  function passAnalyticsEvent(str) {
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
          "event_action": str
      }
  });
  }
  return (
    <div className="new-registered-user" role='group'>
      <h3>Say Goodbye to the Hassle of Driving Alone to Work!</h3>
      <div className='top-section'>
        <div className='left-section'>
          <div className="benefits">
            <ul>
              <div className='list-item'>
                <img src={Clock} aria-hidden='true' />
                <span className='text'>Gain valuable time for catching up on work or just relaxing.</span>
              </div>
              <div className='list-item'>
                <img src={Savings} aria-hidden='true' />
                <span className='text'>Split costs and save money.</span>
              </div>
              <div className='list-item'>
                <img src={Sprout} aria-hidden='true' />
                <span className='text'>Reduce your carbon footprint.</span>
              </div>
              <div className='list-item'>
                <img src={GasPump} aria-hidden='true' />
                <span className='text'>Save fuel and contribute to a sustainable future.</span>
              </div>
              <div className='list-item'>
                <img src={People} aria-hidden='true' />
                <span className='text'>Make new friends and enjoy a stress-free commute!</span>
              </div>
            </ul>
          </div>
        </div>
        {
            !props.isRecentLeadSubmitted &&  
            <div className="controls">
              <div className="find-vanpool-button">
                  <Link to="/JoinCommute/verify-email" replace={'#/JoinCommute/verify-email' === window.location.hash} onClick={() => passAnalyticsEvent("find a vanpool")}>
                    Find A Vanpool
                  </Link>
              </div>
              <Link className="sync-vanpool" to="/link-vanpool"  onClick={() => passAnalyticsEvent("sync vanpool")}>
                SYNC MY VANPOOL TO THIS ACCOUNT
              </Link>
            </div>
        }

      </div>
      <ContactInfo />
    </div>)
}