let loginRedirectService = {};

loginRedirectService.getRedirectUrl = (userProfile, path, deeplink, initialFlow) => {
  if (
    !userProfile &&
    path !== '#/passwordReset' &&
    path !== '#/phonePasswordReset'
  ) {
    return '/login';
  }

  if (!!userProfile) {

    if(deeplink) {
      return path;
    }
    if(userProfile.isTraditional && initialFlow){
      return  '/dashboard';
    } 
    if(userProfile.isLead && initialFlow){
     // return '/JoinCommute/add-payment';
      return '/JoinCommute/choose-plan/summary'
    }
    if((userProfile.isPrivateVanpoolSearch || userProfile.inviteId)  && initialFlow){
      return '/JoinCommute/view-matched-vans';
    }
    
    if (!userProfile.registrationComplete &&  !userProfile.isPrivateVanpoolSearch && !userProfile.inviteId) {
      return '/registration';
    }

    if (userProfile.isPendingCoordinator && !userProfile.isCoordinatorInvite) {
      return '/coordinator-application';
    }

    if (userProfile.hasPendingContract) {
      return '/contract';
    }

    if (userProfile.isPendingDriver) {
      return '/driver-application';
    }

    if (userProfile.isAccountDashboardUser) {
      return '/stats';
    }

    return '/dashboard';
  }else {
    return '/login';
  }
};

export default loginRedirectService;
