import { get2numberDigit } from '../../payment/helper';
import { FULL_MONTHS, FULL_WEEKDAYS, MONTHS } from '../../shared/inputs/Calendar/Calendar';
import { initDate } from './commonHelper';

export function getVanPrice(vanDetails, isDriver, userPackage) {
  const vanRates = vanDetails && vanDetails.vanpoolRates && vanDetails.vanpoolRates[0]
    ? vanDetails.vanpoolRates[0].rates
    : [];
  let rate = [];
  vanRates.map(obj => {
    if (isDriver && obj.discountType == 'Driver') {
      rate = obj.packages.filter(pack => pack.packageName == userPackage);
    }
    if (!isDriver && obj.discountType == 'Rider') {
      rate = obj.packages.filter(pack => pack.packageName == userPackage);
    }
  });
  if (rate && rate.length > 0 && rate[0] && rate[0].totalAmount) {
    const totalAmount = parseFloat(rate[0].totalAmount);
    const split = `${rate[0].totalAmount}`.split('.');
    return {
      price: split[0],
      decimal: split[1],
      rate: rate[0].rate,
      tax: rate[0].taxAmount,
      totalAmount,
      surcharges: rate[0].surcharges,
      taxes: rate[0].taxes,
      surchargeAmount: rate[0].surchargeAmount,
      taxAmount: rate[0].taxAmount
    };
  }
  return {
    price: '0',
    decimal: '00',
    rate: '0.00',
    tax: '0.00'
  }
}

export function getTraditionalVanPrice(vanDetails) {
  const vanRates = vanDetails && vanDetails.vanpoolRates && vanDetails.vanpoolRates[0]
    ? vanDetails.vanpoolRates[0].rates[0]
    : [];
  const rate = vanRates && vanRates.packages && vanRates.packages[0];
  if (rate && rate.totalAmount) {
    const totalAmount = parseFloat(rate.totalAmount);
    const split = `${rate.totalAmount}`.split('.');
    return {
      price: split[0],
      decimal: split[1],
      rate: rate.rate,
      tax: rate.taxAmount,
      totalAmount,
      surcharges: rate.surcharges,
      taxes: rate.taxes,
      surchargeAmount: rate.surchargeAmount,
      taxAmount: rate.taxAmount
    };
  }
  return {
    price: '0',
    decimal: '00',
    rate: '0.00',
    tax: '0.00'
  }
}

const packages = ['2X', '3X', '4X', 'Unlimited'];

export function getNextPackage(selectedPackage) {
  return packages[packages.indexOf(selectedPackage) + 1];
}

export function getPackageFromDays(noOfDays) {
  if(noOfDays){
    if(noOfDays < 5){
      return packages[noOfDays - 2];
    } else {
      return packages[3];
    }
  }
}

export function getDaysFromPackage(packageName) {
  if (packageName) {
    if (packageName.toLowerCase() === 'unlimited') {
      return 5;
    }
    else {
      const dayStr = packageName.toLowerCase().replace('x', '');
      const days = parseInt(dayStr);
      return days;
    }
  }
}

export function getMaxDaysFromPackage(packageName) {
  if (packageName) {
    if (packageName.toLowerCase() === 'unlimited') {
      return 7;
    }
    else {
      const dayStr = packageName.toLowerCase().replace('x', '');
      const days = parseInt(dayStr);
      return days;
    }
  }
}

export function getAvailableDaysOfVan(van) {
  const days = van && van.operationalDays;
  let availableDays = [];
  if (days) {
    Object.keys(days).forEach((day) => {
      if (days[day]['available']) {
        availableDays.push(day)
      }
    })
  }
  return availableDays;
}

export function getActiveDaysFromRoosterDays(roosterDays) {
  if(roosterDays){
    const days = Object.keys(roosterDays);
    let commuteDays = days.filter((key) => roosterDays[key]);
    return commuteDays;
  }
  return [];
}

export function getFullPrice(price) {
  if (price) {
    const parsed = parseFloat(price);
    const rounded = parsed.toFixed(2);
    const splits = `${rounded}`.split('.');
    return {
      price: splits[0] ? splits[0] : '0',
      decimal: splits[1] ? splits[1] : '00',
      totalAmount: parsed,
    }
  } else {
    return {
      price: '0',
      decimal: '00',
      totalAmount: 0,
    }
  }
}

export function getNextMonth(date) {
  if (date) {
    const month = date.getMonth() + 1;
    if (month == 12) {
      return FULL_MONTHS[0];
    } else {
      return FULL_MONTHS[month];
    }
  }
}

export function getDayString(dateValue, fullMonth) {
  if (!isNaN(dateValue)) {
    const month = fullMonth ? FULL_MONTHS[dateValue.getMonth()] : MONTHS[dateValue.getMonth()];
    const year = dateValue.getFullYear();
    const date = dateValue.getDate();
    return `${month} ${date}, ${year}`;
  }
}

export function getMonthDayString(dateValue) {
  if (dateValue) {
    const month = MONTHS[dateValue.getMonth()];
    const date = get2numberDigit(dateValue.getDate());
    return `${month} ${date}`;
  }
}

export function getSaveCardAPIInfo(cardInfo) {
  return {
    account: `*${cardInfo.cardNumber.substring(cardInfo.cardNumber.length - 3)}`,
    cvc: cardInfo.cvv,
    brand: cardInfo.cardType.type.toUpperCase(),
    exp: getExpiryFromObject(cardInfo.expiry, false),
    num: cardInfo.cardNumber,
    valid: true,
    zip: cardInfo.zip,
  }
}

export function getAddCardAPIInfo(cardInfo, tokenId) {
  return {
    cvc: cardInfo.cvv,
    expirationMMYY: getExpiryFromObject(cardInfo.expiry, true),
    isBackup: cardInfo.isBackup,
    isPrimary: cardInfo.isDefault,
    maxAuthorizedAmount: cardInfo.maxAuthorizedAmount,
    postalCode: cardInfo.zip,
    tokenId,
  }
}

export function getExpiryFromObject(exp, withoutSlash) {
  if (exp) {
    if (withoutSlash) {
      return `${exp.month}${exp.year.toString().substring(exp.year.toString().length - 2)}`
    }
    return `${exp.month}/${exp.year.toString().substring(exp.year.toString().length - 2)}`
  }
  return;
}

export function getExpiryFromString(expStr, withoutSlash) {
  if (expStr) {
    return expStr.slice(0, 2) + '/' + expStr.slice(2);
  }
  return '';
}

export function getExpiryObjectFromString(expStr) {
  if (expStr) {
    const month = expStr.slice(0, 2);
    const year = '20' + expStr.slice(2);
    return {
      month,
      year
    }
  } else {
    return initDate;
  }
}

export function getCardsArray(cards, forProratedAmount) {
  let cardsObjects = [];
  if (forProratedAmount) {
    cardsObjects = cards.map(card => {
      var cObj = {
        isPrimary: card.isDefault,
        tokenId: card.token,
        maxAuthorizedAmount: card.maxAmount,
      }
      if(card.isBackup) {
        cObj.isBackup = card.isBackup
      }
      return cObj;
    });
  } else {
    cardsObjects = cards.map(card => {
      return {
        cvc: card.cvv,
        expirationMMYY: getExpiryFromObject(card.expiry, true),
        isPrimary: card.isDefault,
        isBackup: card.isBackup,
        maxAuthorizationAmount: card.maxAmount,
        postalCode: card.zip,
        tokenId: card.token
      };
    })
  }
  return cardsObjects;
}


export function getFullDayString(day) {
  if (day) {
    const weekday = FULL_WEEKDAYS[day.getDay()];
    const dateString = getDayString(day, false);
    return `${weekday}, ${dateString}`;
  }
}

export function getSimpleSaveCardInfo(cardProps, isPrimary, isBackup) {
  if (cardProps) {
    return {
      storedMethodReference: cardProps.referenceId,
      isPrimary,
      isBackup,
    }
  }
}

export function getDeleteCardInfo(cardProps) {
  if (cardProps && cardProps.referenceId) {
    return { storedMethodReference: cardProps.referenceId }
  }
}

export function checkIsPlanUpgrade(previousSubscription, newSubscription, previousRole, newRole) {
  if (packages.indexOf(previousSubscription) <= packages.indexOf(newSubscription) && !(previousRole === 'driver' && newRole === 'rider')) {
    return true;
  }
  return false;
}

export function shouldEnableFromTomorrow(previousSubscription, newSubscription, previousRole, newRole) {
  if(previousRole === 'driver' && newRole === 'rider' && packages.indexOf(previousSubscription) === packages.indexOf(newSubscription)){
    return true;
  }
  if (packages.indexOf(previousSubscription) <= packages.indexOf(newSubscription)) {
    return true;
  }
  return false;
}


export function getUpgradeText(previousSubscription, newSubscription, prevRole, newRole) {
  let resultText = '';
  const days = getDaysFromPackage(newSubscription);
  const planText = (days === 5) ? ' an Unlimited' : `a ${days}-Day`;
  if (checkIsPlanUpgrade(previousSubscription, newSubscription, prevRole, newRole)) {
    resultText = `You’ve upgraded to ${planText} Plan!`;
  } else {
    if (prevRole !== newRole) {
      resultText = `You’ve selected to become a ${newRole} and changed your subscription plan to ${planText} Plan!`;
    } else {
      resultText = `You’ve changed your subscription plan to ${planText} Plan!`;
    }
  }
  return resultText;
}

export function getTextForImage(fname, lname) {
  let nameStr = fname.charAt(0);
  nameStr += lname.charAt(0);
  return nameStr;
}

export function getVanpoolerName(fname, lname) {
  if(fname && lname) {
    const firstName = fname.charAt(0).toUpperCase() + fname.slice(1);
    return `${firstName} ${lname.charAt(0).toUpperCase()}.`;
  }
}
