import UserService from '../shared/user-service';

const RegistrationSecurityProvider = {};

RegistrationSecurityProvider.isAuthorized = () => {
  return UserService.isAuthenticated() &&
  !UserService.getUserProfile().registrationComplete && !UserService.getUserProfile().isLead && !UserService.getUserProfile().isPrivateVanpoolSearch && !UserService.getUserProfile().inviteId
};

RegistrationSecurityProvider.isOverride = () =>  {
  if( UserService.getUserProfile().inviteId  || UserService.getUserProfile().isPrivateVanpoolSearch  || UserService.getUserProfile().isLead ) {
    return false;
  }else {
    return true;
  }
}

export default RegistrationSecurityProvider;