import React from 'react';
import { Menu, Close } from '../../shared/icons';

export default function MenuButtons({ onClick }) {
  return (
    <div>
      <div
        className="menu-button"
        role="button"
        onClick={() => {
          onClick(true);
        }}
      >
        <Menu />
      </div>
      <div
        className="close-button"
        role="button"
        onClick={() => {
          onClick(false);
        }}
      >
        <Close />
      </div>
    </div>
  );
}
