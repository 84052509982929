import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../shared/http-service';
import logger from '../shared/logger';
import config from '../appConfig';
import imageChecker from '../shared/image-checker';
import analyticsService from '../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(actionTypes.VEHICLE_LOADING, loadVehicle);
  yield takeLatest(actionTypes.VEHICLE_ODOMETER_SAVING, saveVehicleOdometer);
}

function* loadVehicle() {
  try {
    const vehicle = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/vehicle`)
    );

    const isValidImage = yield call(() =>
      imageChecker.isValidImage(
        `${config.imagePath}/vehicle-images/${vehicle.vehicleType}.jpg`
      )
    );
    const vehicleImagePath = isValidImage
      ? `${config.imagePath}/vehicle-images/${vehicle.vehicleType}.jpg`
      : `${config.imagePath}/vehicle-images/null.jpg`;
    const vehicleWithImage = { ...vehicle, vehicleImage: vehicleImagePath };
    yield put({ type: actionTypes.VEHICLE_LOADED, data: vehicleWithImage });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.VEHICLE_LOADED_ERROR, data: { error: true }, errorString: ex });
  }
}

function* saveVehicleOdometer(action) {
  try {
    yield call(() =>
      Http.put(`${config.kongApiBaseUrl}/vanpools/vanpool/vehicle/odometer`, {
        odometerValue: action.data
      })
    );
    yield put({ type: actionTypes.VEHICLE_LOADING });
    analyticsService.analyticsEvent({ type: 'Odometer Updated' });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.VEHICLE_LOADED_ERROR, data: { error: true }, errorString: ex });
  }
}
