import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import AccountSummary from '../payment-account/AccountSummary';
import PaymentHistory from '../payment-history/PaymentHistory';
import PaymentWizard from '../payment-pay/PaymentWizard';
import PaymentOverview from './paymentOverview/PaymentOverview';
import { NavLink } from 'react-router-dom';
import {
  isAllowed,
  RestrictTo,
  isAnyPresent
} from '../shared/permissions/RestrictTo';
import {
  PERMISSION_BILLING_VIEWER,
  PERMISSION_REGISTERED_USER,
  PERMISSION_IPB,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_FUEL_CARD_ACTIVE,
  PERMISSION_FUEL_CARD_PENDING,
  PERMISSION_FUEL_CARD_NONE,
  PERMISSION_COORDINATOR,
  PERMISSION_RIDER,
  PERMISSION_SUBSCRIPTION_USER,
  PERMISSION_ACCOUNT_DASHBOARD_USER
} from '../shared/permissions/permissions';
import PaymentManagement from '../payment-management/PaymentManagement';
import FuelCardPayment from '../payment-fuelcard/FuelCardPayment';
import analyticsService from '../shared/analytics/analytics-service';

function SubNav() {
  function sendAnalyticEvent(str) {
    analyticsService.analyticsProcessEvent({
      "event": "click_nav",
      "context": {
        "event_action": `navigation:payment:${str}`
      }
    });
  }
  return (
    <div className="tnav-tray">
      <div className="tnav">
        <ul>
          {isAllowed([
            PERMISSION_SUBSCRIPTION_USER
          ]) && !isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="overview-link"
                exact={true}
                to="/payment"
                aria-label='Payment overview'
                onClick={() => sendAnalyticEvent('overview')}
              >
                Overview
              </NavLink>
            </li>
          ) : null}

          {!isAllowed([
            PERMISSION_SUBSCRIPTION_USER
          ]) && isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]) && !isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="overview-link"
                exact={true}
                to="/payment"
                aria-label='Payment Monthly summary'
                onClick={() => sendAnalyticEvent('monthly summary')}
              >
                Monthly <span>Summary</span>
              </NavLink>
            </li>
          ) : null}
          {isAllowed([
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
            PERMISSION_SUBSCRIPTION_USER
          ]) && !isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="summary-link"
                exact={true}
                to="/payment/summary/history"
                aria-label='Payment history'
                onClick={() => sendAnalyticEvent('payment history')}
              >
                Payment <span>History</span>
              </NavLink>
            </li>
          ) : null}
          {isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
            PERMISSION_SUBSCRIPTION_USER
          ]) && !isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="invoices-link"
                exact={true}
                to="/payment/summary/invoices"
                aria-label='Payment invoices'
                onClick={() => sendAnalyticEvent('invoices')}
              >
                Invoices
              </NavLink>
            </li>
          ) : null}
          {
            !isAllowed([PERMISSION_SUBSCRIPTION_USER]) ? (
              <li>
                <NavLink
                  className="button tnavLink"
                  activeClassName="active"
                  id="payments-link"
                  exact={true}
                  aria-label='Payment My Payment History'
                  to="/payment/summary/payments"
                  onClick={() => sendAnalyticEvent('my payment history')}
                  >
                  <span>My Payment</span> History
                </NavLink>
              </li>
            ) : null
          }
          {
            isAllowed([PERMISSION_SUBSCRIPTION_USER]) ? (
              <li>
                <NavLink
                  className="button tnavLink"
                  activeClassName="active"
                  id="payment-cards-link"
                  exact={true}
                  to="/payment/summary/cards"
                  aria-label='Payment Cards'
                  onClick={() => sendAnalyticEvent('payment cards')}
                  >
                  <span>Payment</span> Cards
                </NavLink>
              </li>
            ) : null
          }
          {isAllowed([
            PERMISSION_COORDINATOR,
            PERMISSION_BILLING_VIEWER,
            PERMISSION_RIDER
          ]) &&
            isAllowed([
              PERMISSION_FUEL_CARD_ACTIVE,
              PERMISSION_FUEL_CARD_PENDING
            ]) &&
            !isAllowed([PERMISSION_FUEL_CARD_NONE, PERMISSION_SUBSCRIPTION_USER]) ? (
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="fuel-link"
                to="/payment/fuel"
                aria-label='Payment fuel card'
                onClick={() => sendAnalyticEvent('fuel card')}
              >
                Fuel <span>Card</span>
              </NavLink>
            </li>
          ) : 
          isAllowed([PERMISSION_SUBSCRIPTION_USER]) ? (
            <li>
            <NavLink
              className="button tnavLink"
              activeClassName="active"
              id="fuel-link"
              to="/payment/summary/fuel"
              aria-label='Payment fuel card'
              onClick={() => sendAnalyticEvent('fuel card')}
              >
              Fuel <span>Card</span>
            </NavLink>
          </li>
          ) : null

        }
          {!isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
            PERMISSION_SUBSCRIPTION_USER
          ]) || isAnyPresent([PERMISSION_IPB]) ? (
            <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="paynow-link"
                exact={true}
                to="/payment/pay"
                aria-label='Payment pay now'
                onClick={() => sendAnalyticEvent('pay now')}
              >
                Pay Now
              </NavLink>
            </li>
          ) : (
            !isAllowed([PERMISSION_SUBSCRIPTION_USER]) ? (
              <li>
                <NavLink
                  className="button tnavLink"
                  activeClassName="active"
                  id="bill-link"
                  exact={true}
                  to="/payment/manage"
                  aria-label='Payment split expenses'
                  onClick={() => sendAnalyticEvent('split expenses')}
                  >
                  Split <span>Expenses</span>
                </NavLink>
              </li>
            ) : null
          )}
          {
             !isAllowed([PERMISSION_ACCOUNT_DASHBOARD_USER,PERMISSION_SUBSCRIPTION_USER]) ? 
             (     
             <li>
              <NavLink
                className="button tnavLink"
                activeClassName="active"
                id="cardwallet-link"
                exact={true}
                to="/payment/summary/cards"
                aria-label='Payment Cards'
                onClick={() => sendAnalyticEvent('payment cards')}
              >
               <span>Payment</span> Cards
              </NavLink>
            </li>) : null

          }
        </ul>
      </div>
    </div>
  );
}

function showSubNav() {
  return (
    !window.location.hash.includes('/payment/pay') &&
    !isAllowed([PERMISSION_REGISTERED_USER])
  );
}

export default function Payment() {
  return (
    <div className="billing">
      {showSubNav() ? <SubNav /> : null}
      <RestrictTo roles={[PERMISSION_REGISTERED_USER]} required={false}>
        <div className="tnav" />
      </RestrictTo>
      <Switch>
        <Route path="/payment/summary" component={PaymentHistory} />
        {isAllowed([
          PERMISSION_SUBSCRIPTION_USER
        ]) ? (
          <Route exact path="/payment" component={PaymentOverview} />
        ) :
          isAllowed([PERMISSION_BILLING_VIEWER]) ?
            <Route
              exact
              path="/payment"
              render={() => <Route component={AccountSummary} />} />
            :
            <Route
              exact
              path="/payment"
              render={() => <Redirect to="/payment/summary/payments" />} />
        }

        {isAllowed([
          PERMISSION_COORDINATOR,
          PERMISSION_BILLING_VIEWER,
          PERMISSION_RIDER
        ]) &&
          isAllowed([
            PERMISSION_FUEL_CARD_ACTIVE,
            PERMISSION_FUEL_CARD_PENDING
          ]) &&
          !isAllowed([PERMISSION_FUEL_CARD_NONE]) ? (
          <Route path="/payment/fuel" component={FuelCardPayment} />
        ) : null}
        {!isAllowed([PERMISSION_REGISTERED_USER]) ? (
          <Route path="/payment/pay" component={PaymentWizard} />
        ) : null}
        {isAllowed([
          PERMISSION_BILLING_VIEWER,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ]) && !isAnyPresent([PERMISSION_IPB]) ? (
          <Route path="/payment/manage" component={PaymentManagement} />
        ) : null}
        {isAllowed([
          PERMISSION_BILLING_VIEWER,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ]) && !isAnyPresent([PERMISSION_IPB]) ? (
          <Route to={`/payment/history`} component={AccountSummary} />
        ) : null}
        {!isAllowed([
          PERMISSION_BILLING_VIEWER,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ]) || isAnyPresent([PERMISSION_IPB]) ? (
          <Redirect to={`/payment/summary`} />
        ) : null}
      </Switch>
    </div>
  );
}
