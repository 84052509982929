import { isCardExpired } from "../../payment/helper";

export function isZipValid(value) {
    if(value.length === 5){
      return true
    } else {
      return false
    }
}

export function assignPriceToCards(price, cards) {
  const primaryCard = cards.find(card => (card.isFederalBenefitCard || card.isFederal) && (card.isPrimary || card.isDefault));
  const backupCard = cards.find(card => !(card.isFederalBenefitCard || card.isFederal) && card.isBackup);
  const notFederalPrimaryCard = cards.find(card => !(card.isFederalBenefitCard || card.isFederal) && (card.isPrimary || card.isDefault));
  if(primaryCard || backupCard){
    const maxAmountFromCard = primaryCard.maxAuthorizedAmount || primaryCard.maxAmount;
    const maxAuthAmount = parseFloat(maxAmountFromCard);
    if(price <= maxAuthAmount){
      primaryCard.chargeAmount = price;
      if(backupCard){
        backupCard.chargeAmount = 0;
        return [primaryCard,backupCard];
      }
      return [primaryCard];
    }
    else if(price > maxAuthAmount) {
      primaryCard.chargeAmount = maxAuthAmount;
      if(backupCard){
        backupCard.chargeAmount = price - maxAuthAmount;
        return [primaryCard,backupCard]
      }
      return [primaryCard];
    }
  }
  else if(notFederalPrimaryCard){
    notFederalPrimaryCard.chargeAmount = price;
    return [notFederalPrimaryCard]
  }
}
