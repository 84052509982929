import { Fragment } from 'react';
import DefaultDriver from '../../_styles/images/new-icons/DefaultDriverAvatar.svg';
import { getVanpoolerName } from '../../join-commute/helpers/vanHelpers';
import cancelImage from '../../_styles/images/new-icons/close-small.svg';
import checkImage from '../../_styles/images/new-icons/check-open.svg';
import DateSection from './date-section/DateSection';
import analyticsService from '../../shared/analytics/analytics-service';
import DriverProfileImage from './DriverProfileImage';

export default function DriverDayRow(props) {
    const { dayData, driverInfo, changedData } = props;
    const buttonType = dayData && dayData.isCurrentUser ? 'cancel' : 'drive';
    let toCancel = false;
    let toDrive = false;
    if (buttonType === 'cancel') {
        toCancel = changedData && changedData.toCancel && changedData.toCancel.includes(dayData.dateString);
    } else {
        toDrive = changedData && changedData.toDrive && changedData.toDrive.includes(dayData.dateString);
    }

    const handleButtonClick = (action, date) => {
        analyticsService.analyticsProcessEvent(
            {
                "event": "click_generic",
                "context": {
                    "event_action": action
                }
            });
        props.handleClick(action, date);
    };

    const handleAppButtonClick = () => {
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
              "event_action": "commute with enterprise app"
            }
          });
        props.checkAndExecuteAppStoreAction();
    };

    return (
        <div className='row driver-day-row' key={props.compKey} role='group'>
            <div className='left-section'>
                <DateSection dayData={dayData} month={props.month} />
                {driverInfo ?
                    <Fragment>
                        <DriverProfileImage driverInfo={driverInfo} />
                        <div className='vanpooler-name'>{getVanpoolerName(driverInfo.firstName, driverInfo.lastName)}</div>
                    </Fragment>
                    :
                    <Fragment>
                        <div className='image'>
                            <img className='profile-image' src={DefaultDriver} alt="Default Driver" />
                        </div>
                        <div className='vanpooler-name unassigned'>Driver unassigned</div>
                    </Fragment>
                }
            </div>
            {props.indexSelected === 1 &&
                <div className='right-section'>
                    {dayData.isPresentDay ?
                        <div className='app-info-container'>Change today’s drive with the<br /><button className='button-as-link open-app-or-modal' onClick={handleAppButtonClick}>Commute with Enterprise app</button></div>
                        :
                        (
                            buttonType === 'cancel' ? (
                                <button aria-selected={toCancel} className={`big-button cancel ${toCancel ? 'active' : ''}`} onClick={() => handleButtonClick('cancel', dayData)}>{toCancel && <img src={cancelImage} aria-hidden={true} />}Cancel</button>
                            ) : (
                                <button aria-selected={toDrive} className={`big-button drive ${toDrive ? 'active' : ''}`} onClick={() => handleButtonClick('drive', dayData)}>{toDrive && <img src={checkImage} aria-hidden={true} />}Drive</button>
                            )

                        )
                    }
                </div>}
        </div>
    );
}
