import React, { Component } from 'react';
import { Close } from '../../shared/icons';

const focusable =
  'input:not(:disabled), select:not(:disabled), textarea:not(:disabled), button:not(:disabled), [tabindex]:not([tabindex="-1"])';

export default class FormPopup extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      deactivating: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const activating = nextProps.active && !this.props.active;

    this.setState({ deactivating: false, activating });
  }

  saveModal() {
    this.setState({ deactivating: true });
    setTimeout(this.props.save, 200);
  }


  render() {
    let { active, title, children } = this.props;
    let saveButtonText = this.props.saveButtonText || 'Add to Expenses';

    if (!active) {
      return null;
    }

    return (
      <div
        className={`form-popup-container popup-container ${
          this.state.deactivating ? 'out' : ''
          }`}
      >
        <div className="popup">
          <div className="header">
            <div onClick={() => this.props.cancel()} className="close">
              <Close />
            </div>
          </div>
          <h3 className="title">{title}</h3>
          <div
            className="body"
            ref={e => (this.body = e)}
          // onKeyDown={e => this.sendFocusDown(e)}
          >
            {children}
          </div>
          <div className="tools response">
            {/*   <button
              onClick={() => this.saveModal()}
              className="button save primary"
            >
              {saveButtonText}
      </button> */}
          </div>
        </div>
      </div>
    );
  }
}
