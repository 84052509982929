import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Download } from './icons';
import config from '../appConfig';
import Http from './http-service';
import { saveAs } from 'file-saver';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CommonToast from './components/common-toast-message/CommonToast';
import analyticsService from './analytics/analytics-service';

export const ExportCsv = ({
  location,
  id,
  summary,
  fuelCardData,
  fuelCardDetails,
  subscriptionFuelCard,
  toastMessage
}) => {
  const [downloadError, setDownloadError] = useState(toastMessage);
  const [toast, setToastvisibility] = useState(false);

  const { url, fileName, hide } = getExportCsvConfig(
    location,
    id,
    summary,
    fuelCardData,
    fuelCardDetails
  );

  const handleDownload = async () => {
    analyticsService.analyticsProcessEvent({
      "event": "download",
      "context": {
        "event_action": "export all",
        "file_name": "fuel card transaction history export"
      }
    });
    try {
      const response = await Http.get(url);
      let csvContent = response.csvData.map(e => e.join(',')).join('\n');
      let blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8'
      });
      saveAs(blob, fileName);
    } catch (error) {
      console.error('Download failed:', error);
      setDownloadError(toastMessage);
      setToastvisibility(true);
    }
  };

  return (
    <>
        {toast && downloadError && ( // Conditionally render CommonToast based on showToast and downloadError
        <CommonToast
          isOpen={true}
          message={downloadError}
          type="error"
          timeOut={5000}
          closeToast={() => setDownloadError(null)}
        />
      )}
      {!hide && (
        <div className="export-csv">
          <button onClick={handleDownload}>
            {subscriptionFuelCard ? <label> EXPORT All</label> : <><Download /> <label>Export</label></>}
          </button>
        </div>
      )}
    </>
  );
};

export function getExportCsvConfig(
  location,
  id,
  summary,
  fuelCardData,
  fuelCardDetails
) {
  let url = config.kongApiBaseUrl;
  let fileName = '';
  let hide = true;

  if (location.pathname === '/payment') {
    url += '/vanpools/vanpool/account/monthly/export/csv';
    fileName = `Account Summary_${id}.csv`;
    hide = summary.length === 0;
  } else if (location.pathname === '/payment/fuel') {
    url += '/user/participant/fuelcard/summary/export/csv';
    fileName = `Fuel Transactions_${id}.csv`;
    hide =
      isEmpty(fuelCardData) ||
      fuelCardData.fuelActivities.length === 0 ||
      fuelCardDetails.cardStatus !== 'Active';
  }else if (location.pathname === '/payment/summary/fuel') {
    url += '/user/participant/fuelcard/summary/export/csv';
    fileName = `Fuel Transactions_${id}.csv`;
    hide =
      isEmpty(fuelCardData) ||
      fuelCardData.fuelActivities.length === 0 ||
      fuelCardDetails.cardStatus !== 'Active';
  }

  return {
    url,
    hide,
    fileName
  };
}

export const mapStateToProps = state => {
  return {
    id: state.vanpoolInfo.vanpoolId,
    summary: state.accountSummary.summary.monthlyAccountSummary,
    fuelCardData: state.fuelCardPayment.summary,
    fuelCardDetails: state.fuelCardPayment.activeFuelCardDetails
  };
};

export default withRouter(connect(mapStateToProps)(ExportCsv));
