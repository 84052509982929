import React, { Component } from 'react';
import FieldInfo from '../FieldInfo';
import { Calendar, Warning } from '../icons';
import DateInput from '../inputs/DateInput';

const birthDateInfoMessage = `All drivers must be 25 years or older (21 years or older in Michigan or New York)

      and meet minimum driver requirements. As part of the Enterprise Commute driver application process, each 
      driver's MVR (Motor Vehicle Record) is checked annually to ensure that it is in compliance with our policies. 
      Only approved drivers may operate the vehicles.`;

export default class DateOfBirthEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validBirthDate: true,
      birthDate: ''
    };
  }

  onChange(birthDate, validBirthDate) {
    let newState = { birthDate, validBirthDate };
    this.setState(newState);
    this.props.onChange(newState);
  }

  render() {
    const { birthDate, validBirthDate } = this.state;

    return (
      <div className="dob">
        <h3>
          <Calendar className="calendar" /> Date of Birth{' '}
          <FieldInfo message={birthDateInfoMessage} />
        </h3>
        <div className="date-picker pad-desk birthdate">
          <DateInput
            value={birthDate}
            onChange={state => this.onChange(state.value, state.valid)}
          />
          {!validBirthDate ? (
            <div className="invalid-date">
              <Warning /> Please enter a valid birthdate.
            </div>
          ) : null}
        </div>
        <p className="note pad-desk">
          You must be 25 years or older (21 years in MI and NY)
        </p>
      </div>
    );
  }
}
