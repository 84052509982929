import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SAVE_CONTACT_DETAILS } from '../redux/actionTypes';
import { Link } from 'react-router-dom';
import Loading from '../shared/loading/Loading';
import FancyInput from '../shared/FancyInput';
import FancySelect from '../shared/FancySelect';
import {formatPhoneNumber, validPhoneNumber} from '../shared/inputs/PhoneEntry';



export class CommuteUserContactDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.contactDetails.firstName,
      lastName: props.contactDetails.lastName,
      phoneNumber: props.contactDetails.phone? props.contactDetails.phone.number : null,
      phoneType: props.contactDetails.phone? props.contactDetails.phone.type : null
    };
  }

  updatePhoneNumber = (phone) => {
   var numb = formatPhoneNumber(phone)
    this.setState({ phoneNumber: numb })
  }


  validateFields =  () => {
    if(this.state.firstName.length > 0 && this.state.lastName.length > 0 && validPhoneNumber(this.state.phoneNumber) ){
      return true
    }else {
      return false
    }
  }



  submit() {
      if( this.state.firstName !== this.props.contactDetails.firstName || 
        this.state.lastName !== this.props.contactDetails.lastName  || 
        this.state.phoneNumber !== this.props.contactDetails.phone.number ||
        this.state.phoneType !== this.props.contactDetails.phone.type
      ) {
        var request = { 
          firstName: this.state.firstName, 
          lastName: this.state.lastName,
          phoneNumber: this.state.phoneNumber,
          phoneType: this.state.phoneType
        };
        this.props.saveContactDetails(request);
        this.props.history.push("/registration/vanpool") 
      }else {
        this.props.history.push("/registration/vanpool")
      }
  }


  render() {
    const { loading, firstName, lastName, phoneNumber, phoneType} = this.state;

    return (
      <div>
        <div className="commuteUser-contact">
          <Loading isLoading={loading} />
          <div className="title">
            Welcome to Commute!
          </div>
          <div className="desc">
           In order to provide you with the best Commute experience,
           please make sure your contact information is updated and accurate.
          </div>
          <FancyInput
            type="text"
            placeholder="First Name"
            label="First Name"
            value={firstName}
            onChange={e =>  this.setState({ firstName: e.target.value })}
          />
          <FancyInput
            type="text"
            placeholder="Last Name"
            label="Last Name"
            value={lastName}
            onChange={e =>
              this.setState({ lastName: e.target.value })
            }
          />
          <FancySelect value={phoneType} update={phonetype => this.setState({phoneType:phonetype})} />
          <FancyInput
            type="text"
            placeholder="Phone Number"
            label="Phone Number"
            value={phoneNumber}
            onChange={e =>
              this.updatePhoneNumber(e.target.value )
            }
          />
          <div className="actions">
              <Link to="/registration" className="button">
                Back
              </Link>
            <button
              className="primary button"
              onClick={() => this.submit()}
              disabled={!this.validateFields()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { contactDetails : state.userAuth, contactInfoSaved: state.registration.contactInfoSaved };
}

function mapDispatchToProps(dispatch) {
  return {
    saveContactDetails(data) {
      return dispatch({ type: SAVE_CONTACT_DETAILS, data });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommuteUserContactDetails);
