import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  message: '',
  type: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DISPLAY_ERROR:
      return { ...state, message: action.data, type: 'error' };
    case actionTypes.DISPLAY_SUCCESS:
      return { ...state, message: action.data, type: 'success' };
    case actionTypes.CLOSE_BANNER:
      return { ...initialState };
    default:
      return state;
  }
};
