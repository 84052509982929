import { useEffect, useRef, useState } from 'react';
import ArrowLeft from '../../../../_styles/images/ArrowLeft.png';
import { Link } from 'react-router-dom';
import Van from '../../../../_styles/images/SUV.svg';
import { connect } from 'react-redux';
import * as actionTypes from '../../../../redux/actionTypes';


function UpgradeToDriver(props) {

  const navigationAlertNode = useRef(null);

  const [selectedDays, updateSelectedDays] = useState([]);

  useEffect(() => {
    if (props.vanpoolInfo) {
      if (props.vanpoolInfo.commuteDays) {
        updateSelectedDays(props.vanpoolInfo.commuteDays);
      }
    }
  }, [props.vanpoolInfo])

  function checkNextStep() {
    props.saveSelectedPlan({ ...props.selectedPlan, days: selectedDays })
    props.history.push("/myprofile/change/plan/licenseNotification");
  }

  return (
    <div className="change-subscription-container">
      <div className="change-subscription-page upgrade-section">
        <div className="header">
          <div className="backArrow">
            <Link className="overview-link" onClick={props.history.goBack}>
              <img alt="go back" src={ArrowLeft}></img>
            </Link>
          </div>
          <div className="heading">Change Subscription Plan</div>
        </div>
        <div className="container">
          <div className='upgrade-content'>
            <div className='left-section'>
              {props.selectedPlan && <h2 className='header-text small'>You’ve upgraded to a Driver Plan!</h2>}
              <div className='comment-text driver'>Your new subscription rate may take effect when your driver application is approved.</div>
              <div className='comment-text driver'>Prorated amounts owed for the upgraded plan for this month will be reflected in an upcoming invoice.</div>
              <div className='comment-text driver'>If needed, you can change your commute days later in your profile.</div>
              <div className='your-days-label'>Your commute days</div>
              <div className="oprational-days">
                {selectedDays.map(day => ({
                  day,
                })).map(({ day }) =>
                (
                  <button
                    day={day}
                    key={day}
                    type="button"
                    disabled={true}
                    aria-label='available'
                    className='day-btn not-active'
                  >
                    {day.substr(0, 3)}
                  </button>
                ))}
              </div>
            </div>
            <div className='right-section'>
              <img src={Van} alt='van image'></img>
            </div>
          </div>
          <div className="buttons">
            <button className='cancel-button' onClick={props.history.goBack}>cancel</button>
            <button className='continue-button' onClick={checkNextStep}>Continue</button>
          </div>
        </div>
      </div>

    </div>
  );
}

function mapStateToProps(state) {
  return {
    selectedPlan: state.profile.selectedPlan,
    vanpoolInfo: state.profile.vanpool,
    loaded: state.profile.isLoaded,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    saveSelectedPlan(selectedPackage) {
      dispatch({ type: actionTypes.SET_SELECTED_PLAN, data: selectedPackage });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeToDriver);