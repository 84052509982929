import { takeLatest, take, put, call, all } from 'redux-saga/effects';
import moment from 'moment';
import * as actionTypes from '../../redux/actionTypes';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import dateUtility from '../../shared/dateUtility';
import analyticsService from '../../shared/analytics/analytics-service';

export default function*() {
  yield takeLatest(actionTypes.VANPOOL_MY_TRIPS_LOADING, vanpoolMyTrips);
  yield takeLatest(
    actionTypes.REGISTERED_USER_MY_TRIPS_LOADING,
    registeredUserMyTrips
  );

  yield takeLatest(actionTypes.VANPOOL_MY_TRIPS_SAVING, saveVanpoolerTrips);
  yield takeLatest(
    actionTypes.REGISTERED_USER_MY_TRIPS_SAVING,
    saveRegisteredUserTrips
  );

  yield takeLatest(actionTypes.MY_TRIPS_APPROVAL, registeredUserApproval);

  yield takeLatest(
    actionTypes.REGISTERED_USER_MY_TRIPS_APPROVING,
    saveAndApproveRegisteredUserTrips
  );
}

function* vanpoolMyTrips() {
  try {
    const baseUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/tripmodes`;

    const [modeMap, previousData, currentData] = yield all([
      call(() =>
        Http.get.cache(
          `${config.kongApiBaseUrl}/user/profile/commuteProfile/transportation/modes`
        )
      ),
      call(() => previousMonthTripData(baseUrl)),
      call(() => Http.get(`${baseUrl}?current=true`))
    ]);

    const didNotCommute = modeMap.modes.find(
      mode => mode.label === 'Did not commute'
    );

    const previous = previousData.dailyTripModesList.map(day => ({
      date: dateUtility.formatAsSortableDate(day.date),
      saved: day.isSaved,
      toWork: ['U', 'H'].includes(day.orientation)
        ? modeMap.modes.find(
            mode =>
              mode.domainId === (day.workTripMode || didNotCommute.domainId)
          ).label
        : 'Vanpool',
      toHome: ['U', 'W'].includes(day.orientation)
        ? modeMap.modes.find(
            mode =>
              mode.domainId === (day.homeTripMode || didNotCommute.domainId)
          ).label
        : 'Vanpool',
      orientation: day.orientation,
      editable: true
    }));

    const current = currentData.dailyTripModesList.map(day => ({
      date: dateUtility.formatAsSortableDate(day.date),
      saved: day.isSaved,
      toWork: ['U', 'H'].includes(day.orientation)
        ? modeMap.modes.find(
            mode =>
              mode.domainId === (day.workTripMode || didNotCommute.domainId)
          ).label
        : 'Vanpool',
      toHome: ['U', 'W'].includes(day.orientation)
        ? modeMap.modes.find(
            mode =>
              mode.domainId === (day.homeTripMode || didNotCommute.domainId)
          ).label
        : 'Vanpool',
      orientation: day.orientation,
      editable: true
    }));

    backfillDates(previous);
    backfillDates(current);

    yield put({
      type: actionTypes.VANPOOL_MY_TRIPS_LOADED,
      data: { current, previous }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.VANPOOL_MY_TRIPS_LOADED,
      data: { error: true }
    });
  }
}

function* registeredUserMyTrips() {
  try {
    const baseUrl = `${config.kongApiBaseUrl}/user/profile/tripmodes`;

    const [modeMap, previousData, currentData] = yield all([
      call(() =>
        Http.get.cache(
          `${config.kongApiBaseUrl}/user/profile/commuteProfile/transportation/modes`
        )
      ),
      call(() => previousMonthTripData(baseUrl)),
      call(() => Http.get(`${baseUrl}?current=true`))
    ]);

    const previous = previousData.dailyTripModesList
      .filter(day => day.workTripMode != null)
      .map(day => ({
        date: dateUtility.formatAsSortableDate(day.date),
        saved: day.isSaved,
        toWork: modeMap.modes.find(mode => mode.domainId === day.workTripMode)
          .label,
        toHome: modeMap.modes.find(mode => mode.domainId === day.homeTripMode)
          .label,
        editable: true
      }));

    const current = currentData.dailyTripModesList
      .filter(day => day.workTripMode != null)
      .map(day => ({
        date: dateUtility.formatAsSortableDate(day.date),
        saved: day.isSaved,
        toWork: modeMap.modes.find(mode => mode.domainId === day.workTripMode)
          .label,
        toHome: modeMap.modes.find(mode => mode.domainId === day.homeTripMode)
          .label,
        editable: true
      }));

    backfillDates(previous);
    backfillDates(current);

    yield put({
      type: actionTypes.REGISTERED_USER_MY_TRIPS_LOADED,
      data: { current, previous }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.REGISTERED_USER_MY_TRIPS_LOADED,
      data: { error: true }
    });
  }
}

function* previousMonthTripData(baseUrl) {
  try {
    return yield call(() =>
      Http.get(`${baseUrl}?current=false`, {}, () => {
        throw new Error('Could not get previous trip data.');
      })
    );
  } catch (ex) {
    logger.log(ex);
    return { dailyTripModesList: [] };
  }
}

function backfillDates(list) {
  if (!list.length) {
    return [];
  }

  let day = dateUtility.getMomentInFromDateFormat(list[0].date);
  const currentMonth = day.month();
  day = day.subtract(day.date() - 1, 'day');

  const hasDay = (list, day) =>
    list.some(entry => entry.date === day.format('YYYY-MM-DD'));

  while (day.month() === currentMonth) {
    if (!hasDay(list, day)) {
      list.push({
        date: day.format('YYYY-MM-DD'),
        editable: false,
        toWork: '',
        toHome: '',
        saved: false
      });
    }

    day = day.add(1, 'day');
  }

  list.sort((a, b) => (b.date > a.date ? -1 : 1));
}

function* saveVanpoolerTrips({ data }) {
  try {
    const modeMap = yield call(() =>
      Http.get.cache(
        `${config.kongApiBaseUrl}/user/profile/commuteProfile/transportation/modes`
      )
    );

    const mappedDays = data.days
      .filter(day => day.differentToWork || day.differentToHome)
      .map(day => ({
        date: dateUtility.parseFormalDate(day.date),
        workTripMode:
          day.orientation === 'W'
            ? undefined
            : modeMap.modes.find(mode => mode.label === day.toWork).domainId,
        homeTripMode:
          day.orientation === 'H'
            ? undefined
            : modeMap.modes.find(mode => mode.label === day.toHome).domainId
      }));

    yield Http.put(
      `${
        config.kongApiBaseUrl
      }/vanpools/vanpool/tripmodes?current=${data.monthStatus === 'current'}`,
      { dailyTripModesList: mappedDays }
    );
    yield put({ type: actionTypes.VANPOOL_MY_TRIPS_LOADING });
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data:
        'Thanks!  Your data has been saved.  Please record all trips before the 10th of the following month.'
    });
    analyticsService.analyticsEvent({ type: 'Trip Recorded' });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.VANPOOL_MY_TRIPS_LOADED,
      data: { error: true }
    });
  }
}

function* saveRegisteredUserTrips({ data }) {
  try {
    const modeMap = yield call(() =>
      Http.get.cache(
        `${config.kongApiBaseUrl}/user/profile/commuteProfile/transportation/modes`
      )
    );

    const mappedDays = data.days
      .filter(
        day =>
          day.editable &&
          (!day.saved || day.differentToHome || day.differentToWork)
      )
      .map(day => ({
        date: dateUtility.parseFormalDate(day.date),
        workTripMode: modeMap.modes.find(mode => mode.label === day.toWork)
          .domainId,
        homeTripMode: modeMap.modes.find(mode => mode.label === day.toHome)
          .domainId
      }));

    if (mappedDays.length) {
      yield Http.put(
        `${
          config.kongApiBaseUrl
        }/user/profile/tripmodes?current=${data.monthStatus === 'current'}`,
        { dailyTripModesList: mappedDays }
      );
    }

    yield put({ type: actionTypes.REGISTERED_USER_MY_TRIPS_LOADING });
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data:
        'Thanks!  Your data has been saved.  Please record all trips and approve before the 10th of the following month.'
    });
    analyticsService.analyticsEvent({ type: 'Trip Recorded' });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.REGISTERED_USER_MY_TRIPS_LOADED,
      data: { error: true }
    });
  }
}

function* registeredUserApproval() {
  try {
    const approved = !!(yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/user/profile/tripdata/date`)
    )).recordingCompleteDate;

    if (approved) {
      yield put({ type: actionTypes.TRIP_RECORDING_APPROVED });
    } else {
      yield put({ type: actionTypes.TRIP_RECORDING_NOT_APPROVED });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.TRIP_RECORDING_NOT_APPROVED });
  }
}

function* saveAndApproveRegisteredUserTrips({ data }) {
  try {
    yield put({ type: actionTypes.REGISTERED_USER_MY_TRIPS_SAVING, data });
    yield take(actionTypes.REGISTERED_USER_MY_TRIPS_LOADING);

    yield call(() => Http.post(`${config.kongApiBaseUrl}/user/profile/tripdata`));
    const prevMonth = moment()
      .subtract(1, 'month')
      .format('MMMM');

    yield put({ type: actionTypes.TRIP_RECORDING_APPROVED });
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data: `Your ${prevMonth} trip data has been submitted and can no longer be updated.`
    });
    analyticsService.analyticsEvent({ type: 'Trip Approved' });
  } catch (ex) {
    logger.log(ex);
  }
}
