import config from '../appConfig';
import UserService from '../shared/user-service';
import azureService from './azureService';

const logoutService = {};

logoutService.logout = () => {
  UserService.clearAuthentication();
  localStorage.removeItem('inviteId');
  localStorage.removeItem('persist:joinCommuteState');
  localStorage.removeItem('persist:profileState');
  if(!config.localLogin) {
    azureService.signOut();
  }else {
    window.location.href = config.logoutUrl;
  }
};

export default logoutService;
