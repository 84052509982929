const NA_VALUE = 'N/A';

const EMPTY_ADDRESS = {
  line1: '',
  line2: '',
  city: '',
  state: '',
  zip: '',
  country: '',
  latitude: '',
  longitude: ''
};

export default NA_VALUE;

export { EMPTY_ADDRESS };
