import {getExpiryObjectFromString} from '../join-commute/helpers/vanHelpers';
import dateUtility from '../shared/dateUtility';

export function getCardTypeNumber(str) {
  if (str && typeof str === 'string') {
    const splits = str.split(' ');
    if (splits.length === 2) {
      const cardNumber = splits[1].replace('*', '');
      return {
        cardType: splits[0],
        cardNumber
      }
    }
  }
  return {
    cardType: '',
    cardNumber: ''
  }
}

export function getCardInfo(cardType, device) {
  if (cardType && typeof cardType === 'string') {
    const cardInfo = getCardTypeNumber(cardType);
    if (device === 'desktop') {
      return `${cardInfo.cardType} ending with ${cardInfo.cardNumber}`;
    } else if (device === 'mobile') {
      return `${cardInfo.cardType} **** ${cardInfo.cardNumber}`;
    }
  }
  return '';
}

export function makeDecimal2numbers(num) {
  if (!num) return '0.00';
  const [integerPart, decimalPart = '00'] = `${num}`.replace('$', '').split('.');
  const formattedDecimal = decimalPart.padEnd(2, '0').slice(0, 2);

  return `${integerPart}.${formattedDecimal}`;
}


export function splitNumber(num) {
  if (num) {
    const splits = `${num}`.split('.');
    return {
      fullPrice: num,
      price: splits[0].replace('$', ''),
      decimal: splits[1] ? (splits[1] < 10 && splits[1] != '00' && splits[1].length === 1 ? `${splits[1]}0` : splits[1]) : '00'
    };
  }
  return {
    fullPrice: 0,
    price: '0',
    decimal: '00'
  };
}

export function getCumulativeCost(list, prop) {
  if (list && prop) {
    const p = list.reduce((total, obj) => total + obj[prop], 0);
    return splitNumber(p);
  }
  return {
    fullPrice: 0,
    price: '0',
    decimal: '00'
  };
}

export function getPostedText(dateText, withPosted) {
  const date = new Date(dateText);
  if (date.getTime() === date.getTime()) {
    const month = dateUtility.monthNameFromIndex(date.getMonth() + 1);
    const day = date.getDate();
    const year = date.getFullYear();
    if (!withPosted) {
      return `${month} ${day}, ${year}`;
    }
    return `Posted ${month} ${day}, ${year}`;
  }
  return '';
}

export function getSortedCards(cards) {
  if (cards && cards.length > 0) {
    return cards && cards.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary));
  }
  return [];
}

export function isCardExpired(expirationMMYY) {
  const dateObj = getExpiryObjectFromString(expirationMMYY);
  const today = new Date();
  const date = {
    month: parseInt(dateObj.month),
    year: parseInt(dateObj.year)
  }
  const compareDate = {
    month: today.getMonth() + 1,
    year: today.getFullYear(),
  }
  if ((date.year < compareDate.year) || (date.year === compareDate.year && date.month < compareDate.month)) {
    return true;
  }
  return false;
}

export function sortCardForDisplay(cards) {
  if (cards && cards.length > 0) {
    const sortedFederalCards = cards.sort((a, b) => Number(b.isPrimary) - Number(a.isPrimary));
    const sortedBackupCards = sortedFederalCards.slice(1).sort((a, b) => Number(b.isBackup) - Number(a.isBackup));
    const sortedExpiredCards = sortedBackupCards.slice(1).sort((a, b) => !isCardExpired(b.expirationMMYY) - !isCardExpired(a.expirationMMYY));
    return [sortedFederalCards[0], sortedBackupCards[0], ...sortedExpiredCards];
  }
  return [];
}

export function getCardDetails(cards) {
  if (cards && cards.length > 0) {
    const federalCards = cards.filter((card) => card.isFederalBenefitCard);
    const retailCards = cards.filter((card) => !card.isFederalBenefitCard);
    const primaryCards = cards.filter((card) => card.isPrimary);
    const backupCards = cards.filter((card) => card.isBackup);
    const extraFederalCards = cards.filter((card) => card.isFederalBenefitCard && !card.isPrimary);
    const extraRetailCards = cards.filter((card) => !card.isFederalBenefitCard && !card.isBackup && !card.isPrimary);
    return {
      federal: federalCards.length,
      retail: retailCards.length,
      primaryCards: primaryCards,
      backupCards: backupCards,
      retailCards,
      extraCards: {
        federal: extraFederalCards,
        retail: extraRetailCards
      }
    };
  }
  return {
    federal: 0,
    retail: 0,
    primaryCards: [],
    backupCards: [],
    retailCards: []
  };
}

export function removeDecimals(num) {
  if (num) {
    const splits = `${num}`.split('.');
    if(splits && splits[0] && parseInt(splits[0])){
      return splits[0];
    }
  }
  return '0';
}

export function hoursInBetween(lastDate, today){
  const diff = Math.abs(today.getTime() - lastDate.getTime());
  return diff / (1000 * 60 * 60);
}

export function get2numberDigit(digit) {
  const digitNumber = parseInt(digit);
  if(digitNumber < 10){
    return `0${digitNumber}`;
  }
  return `${digitNumber}`;
}
