import * as actionTypes from '../redux/actionTypes';

const initialState = {
  runDays: [],
  outboundRoutePoints: [],
  inboundRoutePoints: [],
  loaded: false,
  loadError: false,
  loadErrorString: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROUTE_LOADED:
      return { ...state, ...initialState, ...action.data, loaded: true, loadError: false };
    case actionTypes.ROUTE_LOADED_ERROR:
      return { ...state, ...initialState, ...action.data, loaded: true, loadError: true, loadErrorString: action.errorString };
    case actionTypes.ROUTE_SAVING:
      return { ...state, ...initialState };
    default:
      return state;
  }
};
