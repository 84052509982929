import React from 'react';
import { Pencil, Warning } from '../icons';
import dateUtility from '../dateUtility';
import creditCardService from '../credit-card/creditCardService';
import EditCardForm from './EditCardForm';

export default function Card({
  brand,
  account,
  exp,
  isEditable,
  onEdit,
  isEditing,
  deletable,
  onStopEditing,
  id
}) {
  return (
    <div className={`card ${isEditing ? 'editing' : ''} `}>
      <div className="card-info">
        <div className="logo">
          <img
            className="card-image"
            src={creditCardService.getImage(brand)}
            alt="Credit Card"
          />
        </div>
        <div className="details">
          <div className="account">***{account}</div>
          {!isEditing ? (
            <div
              className={`exp ${dateUtility.beforeToday(exp) ? 'expired' : ''}`}
            >
              Exp {exp}
              {dateUtility.beforeToday(exp) ? <Warning /> : null}
            </div>
          ) : (
            <div className="exp" />
          )}
        </div>
        {isEditable ? <Pencil onClick={() => onEdit()} /> : null}
      </div>
      {isEditing ? (
        <EditCardForm
          deletable={deletable}
          isEditing={isEditing}
          id={id}
          exp={exp}
          onStopEditing={onStopEditing}
        />
      ) : null}
    </div>
  );
}
