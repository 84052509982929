import { useEffect, useState } from 'react';
import hand from '../../_styles/images/hand.svg';
import carIcon from '../../_styles/images/smallCar.svg';
import { MONTH_NAMES } from '../../shared/dateUtility';
import { connect } from 'react-redux';
import DateSection from '../driver-month-collapsible/date-section/DateSection';
import { isEmpty } from 'lodash';
import * as actionTypes from '../../redux/actionTypes';
import Loading from '../../shared/loading/Loading';
import analyticsService from '../../shared/analytics/analytics-service';

export function getCancelData(dateString) {
    return {
        "date": dateString,
        "isRunDay": true,
        "scheduledDriverData": [
            { "orientation": "R", "driverId": null }
        ]
    }
}
export function getDriveData(dateString, participantId) {
    return {
        "date": dateString,
        "isRunDay": true,
        "scheduledDriverData": [
            { "orientation": "R", "driverId": participantId }
        ]
    }
}

function getUpdateSchedulePostData(data, participantId) {
    const toCancelDataArray = data.toCancel && data.toCancel.map((day) => getCancelData(day));
    const toDriveDataArray = data.toDrive && data.toDrive.map((day) => getDriveData(day, participantId));
    return {
        driverScheduleList: [...toCancelDataArray, ...toDriveDataArray]
    };
}

function ReviewDriverScheduleChanges(props) {
    const [toCancel, updateToCancel] = useState({});
    const [toDrive, updateToDrive] = useState({});
    const [monthsToCancel, updateMonthsToCancel] = useState([]);
    const [monthsToDrive, updateMonthsToDrive] = useState([]);
    const [loading, updateLoading] = useState(false);

    function goBack() {
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": "edit schedule"
            }
        });
        props.history.goBack();
    }

    useEffect(() => {
        if (isEmpty(props.driverSchedule.monthlyChangedData.toCancel) && isEmpty(props.driverSchedule.monthlyChangedData.toDrive)) {
            props.history.replace('/driver-schedule')
        }
    }, []);

    useEffect(() => {
        if (props.driverSchedule.monthlyChangedData) {
            updateToCancel(props.driverSchedule.monthlyChangedData.toCancel);
            updateToDrive(props.driverSchedule.monthlyChangedData.toDrive);
            updateMonthsToCancel(Object.keys(props.driverSchedule.monthlyChangedData.toCancel));
            updateMonthsToDrive(Object.keys(props.driverSchedule.monthlyChangedData.toDrive));
        }
    }, [props.driverSchedule.monthlyChangedData]);

    function update() {
        updateLoading(true);
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": "save changes to schedule"
            }
        });
        const processedData = getUpdateSchedulePostData(props.driverSchedule.changedData, props.profile.participantId);
        props.updateDriverSchedule(processedData, props.history);
    }

    return (
        <div className="driver-schedule-page">
            <Loading isLoading={loading} />
            <div className="driver-schedule-container">
                <div className='review-driver-schedule'>
                    <h1 className="heading">Review Driver Schedule</h1>
                    <div className="changes-section">
                        <h3 className='second-heading'>Please review your changes before saving</h3>
                        {!isEmpty(toCancel) && <div className="section to-cancel">
                            <div className="top">
                                <div className='icon-container' aria-hidden={true}>
                                    <img src={carIcon} className='icon' />
                                </div>
                                <div className="text">
                                    <h2>Drives to Cancel</h2>
                                    <span className='sub-text'>You can still ride in the van</span>
                                </div>
                            </div>
                            <div className="bottom">
                                {
                                    monthsToCancel.map(month =>
                                        <div className='month-section' role='group'>
                                            <div className='month-name' aria-label={MONTH_NAMES[month]}>{MONTH_NAMES[month].substr(0, 3)}</div>
                                            <div className='dates-list'>
                                                {
                                                    toCancel[month].map(dates =>
                                                        <DateSection dayData={dates} month={MONTH_NAMES[month]} />)
                                                }
                                            </div>

                                        </div>
                                    )
                                }
                            </div>
                        </div>}
                        {!isEmpty(toDrive) && !isEmpty(toCancel) && <div className='divider'></div>}
                        {!isEmpty(toDrive) && <div className="section to-drive">
                            <div className="top">
                                <div className='icon-container' aria-hidden={true}>
                                    <img src={hand} className='icon' />
                                </div>
                                <div className="text">
                                    <h2>New Days to Drive</h2>
                                </div>
                            </div>
                            <div className="bottom">
                                {
                                    monthsToDrive.map(month =>
                                        <div className='month-section' role='group'>
                                            <div className='month-name' aria-label={MONTH_NAMES[month]}>{MONTH_NAMES[month].substr(0, 3)}</div>
                                            <div className='dates-list'>
                                                {
                                                    toDrive[month].map(dates =>
                                                        <DateSection dayData={dates} month={MONTH_NAMES[month]} />)
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>}
                    </div>
                    <div className='bottom-button-section'>
                        <button className={'edit-button'} onClick={goBack}>edit schedule</button>
                        <button className={'review-button'} onClick={update}>save changes to schedule</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        driverSchedule: state.driverSchedule,
        profile: state.profile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateDriverSchedule(data, history) {
            dispatch({ type: actionTypes.UPDATE_DRIVER_SCHEDULE, data, history })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDriverScheduleChanges);