import UserService from '../shared/user-service';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_MANAGE_PAYMENT_METHODS,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';

export default {
  isAuthorized: () =>
    UserService.getUserProfile() &&
    isAllowed([
      PERMISSION_MANAGE_PAYMENT_METHODS,
      PERMISSION_ACCOUNT_DASHBOARD_ADMIN
    ])
};
