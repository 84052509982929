import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.166 20H3.027a1.03 1.03 0 01-.726-.3A1.018 1.018 0 012 18.98V5.02c0-.27.108-.53.3-.722.194-.191.455-.299.727-.299h10.118c.273 0 .534.108.727.3.192.19.3.45.3.721v1.766a1.423 1.423 0 01-.42 1.022l-4.746 4.727a.142.142 0 000 .061v1.848H10.906L21.055 4.337l.945.95-10.139 10.077a1.449 1.449 0 01-1.027.419H8.687a1.03 1.03 0 01-.726-.299 1.018 1.018 0 01-.301-.722v-2.165c0-.382.152-.748.421-1.02l4.787-4.738a.14.14 0 000-.062v-1.44H3.346v13.336h9.522v-1.338l1.346-1.348v2.972c0 .27-.109.53-.301.721a1.03 1.03 0 01-.727.3"
        fill="#4A4A4A"
      />
      <path
        d="M10.036 10.577l-.952.945 2.847 2.831.952-.946-2.847-2.83z"
        fill="#4A4A4A"
      />
    </svg>
  )
}

export default SvgComponent
