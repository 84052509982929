import { takeLatest, call, put } from 'redux-saga/effects';
import {
  SIGN_TERMS_AND_CONDITIONS,
  AUTHORIZATION_REQUEST
} from '../redux/actionTypes';
import Http from '../shared/http-service';
import config from '../appConfig';
import logger from '../shared/logger';

export default function*() {
  yield takeLatest(SIGN_TERMS_AND_CONDITIONS, signTermsAndConditions);
}

function* signTermsAndConditions({ data }) {
  try {
    yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/user/profile/terms`, {
        termsId: data.termsId
      })
    );

    yield put({ type: AUTHORIZATION_REQUEST });
  } catch (ex) {
    logger.log(ex);
  }
}
