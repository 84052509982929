import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import Loading from '../../shared/loading/Loading';
import SubscriptionPlan from './SubscriptionPlan';
import PaymentHistoryOverview from './PaymentHistoryOverview';
import InvoiceOverview from './InvoiceOverview';
import PaymentCardsOverview from './PaymentCardsOverview';


function PaymentOverview(props) {
  const [loading, updateLoading] = useState(false);

  useEffect(() => {
    updateLoading(true);
    props.init();
    if (!props.profileLoaded) {
      updateLoading(true);
      props.loadProfile();
    }
    if(!props.isInvoiceSummaryLoaded && !props.isInvoiceSummaryError){
      props.loadInvoices();
    }
  }, []);

  useEffect(() => {
    if (props.isSummaryLoaded && props.isPaymentHistoryLoaded && props.profileLoaded) {
      updateLoading(false);
    }
  }, [props.isSummaryLoaded, props.isPaymentHistoryLoaded, props.profileLoaded])

  //resetting the chosen invoice
  useEffect(() => {
    props.refreshChosenInvoice();
  }, [])

  function getCurrentInvoice() {
    props.loadCurrentInvoice(props.history);
  }

  return (
    <div className='payment-overview-page'>
      <Loading isLoading={loading} />
      <div className='container'>
        <SubscriptionPlan
          subscription={props.subscriptionSummary}
          vanpoolInfo={props.vanpoolInfo}
          viewLink={true}
          isVanStartingInFuture={props.isVanStartingInFuture}
          startDate={props.startDate}
          isVanCancelled={props.isVanCancelled}
          endDate={props.endDate}
          profile={props.profile}
          subscriptionAPIInfo={props.subscriptionAPIInfo}
        />
        <PaymentHistoryOverview paymentHistory={props.paymentHistory} />
        <div className='invoiceCardsOverview'>
          <InvoiceOverview areInvoicesAvailable = {props.invoiceHistory.length > 0} {...props.subscriptionSummary.invoiceSummary} lastUpdatedDateTime={props.subscriptionSummary.lastUpdatedDateTime} getCurrentInvoice={getCurrentInvoice} />
          <PaymentCardsOverview cards={props.subscriptionSummary.storedPaymentCards} isVanStartingInFuture={props.isVanStartingInFuture} isVanCancelled={props.isVanCancelled} />
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    subscriptionSummary: state.PaymentOverview.subscriptionSummary,
    isSummaryLoaded: state.PaymentOverview && state.PaymentOverview.summaryLoaded,
    vanpoolInfo: state.vanpoolInfo,
    vanpoolInfoLoaded: state.vanpoolInfo.loaded,
    paymentHistory: state.paymentHistory.rawPaymentHistory && state.paymentHistory.rawPaymentHistory.transactionGroup,
    isPaymentHistoryLoaded: state.paymentHistory && state.paymentHistory.historyLoaded,
    profile: state.profile,
    profileLoaded: state.profile.isLoaded,
    isVanStartingInFuture: state.profile.subscription.isVanStartingInFuture,
    isVanCancelled: state.profile.subscription.isVanCancelled,
    startDate: state.profile.subscription.startDate,
    endDate: state.profile.subscription.endDate,
    isInvoiceSummaryLoaded: state.invoiceHistory.summaryLoaded,
    isInvoiceSummaryError: state.invoiceHistory.invoiceSummaryError,
    invoiceHistory: state.invoiceHistory.invoiceSummary,
    subscriptionAPIInfo: state.profile.subscriptionAPIInfo
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.SUBSCRIPTION_SUMMARY_LOADING });
      dispatch({ type: actionTypes.GET_PAYMENT_HISTORY });
    },
    refreshChosenInvoice() {
      dispatch({ type: actionTypes.CHOOSE_INVOICE, data: {} });
    },
    loadProfile() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
    },
    loadInvoices() {
      dispatch({ type: actionTypes.INVOICE_HISTORY_DETAILS_LOADING });
    },
    loadCurrentInvoice(history) {
      dispatch({ type: actionTypes.LOAD_CURRENT_INVOICE, history})
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentOverview);
