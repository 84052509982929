import {useEffect,useState} from 'react';
import {Document,Page,pdfjs} from 'react-pdf/dist/esm/entry.webpack';
import config from '../../appConfig';
import Http from '../../shared/http-service';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
import ArrowLeft from '../../_styles/images/ArrowLeft.png';
import analyticsService from '../../shared/analytics/analytics-service';

export default function InvoiceMobilePDF(props) {

  const [numPages,setNumPages] = useState(null);
  const [pdfFile,updatePdfFile] = useState(null);
  const [loading, updateLoading] = useState(false);
  const invoiceMonth = props.location && props.location.state && props.location.state.invoiceMonth;
  const invoiceYear = props.location && props.location.state && props.location.state.invoiceYear;
  function onDocumentLoadSuccess({numPages: nextNumPages}) {
    setNumPages(nextNumPages);
  }

  async function downloadAndSaveInvoice(download) {
    const invoiceId = props.location.state.invoiceId;
    const invoiceMonth = props.location.state.invoiceMonth;
    const invoiceYear = props.location.state.invoiceYear;
    let response = await Http.getStatic(
      `${config.kongApiBaseUrl}/vanpools/vanpool/invoices/invoice/pdf/download/${invoiceId}`,{"Content-Type": "application/pdf"});
    if(response.ok) {
      const pdf = await response.blob();
      if(!download) {
        const file = window.URL.createObjectURL(pdf);
        updatePdfFile(file)
        updateLoading(false)
      } else {
        saveAs(pdf,`${invoiceId}.pdf`);
        analyticsService.analyticsProcessEvent({
          "event": "download",
          "context": {
            "event_action": "download",
            "file_name": `${invoiceMonth}${invoiceYear}invoice.pdf`
          }
        });
      }
    } else {
      props.history.replace('/error')
    }

  }

  function goBack() {
    props.history.goBack();
  }

  useEffect(() => {
      //handling refresh
    if(!props.location.state){
      props.history.replace('/payment/summary/invoices');
    } else {      
      updateLoading(true);
      downloadAndSaveInvoice(false);
    }
  },[])
  
  function download() {
    downloadAndSaveInvoice(true);
  }

  return (
    <div className='invoice-view-container'>
      <div className='top-header'>
        <div className='backArrow'>
          <div onClick={goBack}>
            <img src={ArrowLeft} alt='go back'></img>
          </div>

        </div>
        <div className='heading'>{invoiceMonth} {invoiceYear}</div>
      </div>
      <div className='content'>
        <button className='download-button' onClick={download}>Download invoice</button>
        {loading ? <div className="pdf-loader"></div> :
        <Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} >
          {Array.from(new Array(numPages),(el,index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
}
      </div>
    </div>
  );
}