import React, { Component } from 'react';
import { Route, Switch, NavLink, Link } from 'react-router-dom';
import FancyInput from '../shared/FancyInput';
import { Check } from '../shared/icons';
import * as actionTypes from '../redux/actionTypes';
import { connect } from 'react-redux';
import Callable from '../shared/Callable';
import Loading from '../shared/loading/Loading';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_FUEL_CARD_ACTIVE,
  PERMISSION_FUEL_CARD_PENDING,
  PERMISSION_COORDINATOR,
  PERMISSION_BILLING_VIEWER
} from '../shared/permissions/permissions';
import VanpoolSummary from '../payment/VanpoolSummary';
import FuelCardSummary from './FuelCardSummary';
import config from '../appConfig';
import ExportCsv from '../shared/ExportCsv';
import WexFuelCardActivationInfo from './WexFuelCardActivationInfo'

class FuelCardPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cardInfoLoading: false,
      fuelCardDetails: null,
      cardStatus: null,
      cardInfoLoaded: true,
      cardNumber: '',
      pin: '',
      resetPin: '',
      reset: false,
      resetting: false,
      apiEncounteredError: false
    };
    this.activateCard = this.activateCard.bind(this);
    this.resetPin = this.resetPin.bind(this);
  }

  componentDidMount() {
    if (
      isAllowed([PERMISSION_FUEL_CARD_ACTIVE, PERMISSION_FUEL_CARD_PENDING])
    ) {
      this.props.init();
      this.setState({ cardInfoLoading: true, cardInfoLoaded: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { activeFuelCardDetails } = nextProps.fuelCardPayment;
    let pinReset = nextProps.fuelCardPayment.pinReset;
    let apiEncounteredError = nextProps.fuelCardPayment.apiEncounteredError;
    const cardStatus = activeFuelCardDetails.cardStatus;

    switch (cardStatus) {
      case 'Active':
        this.setState({
          fuelCardDetails: activeFuelCardDetails,
          cardStatus: cardStatus,
          cardInfoLoaded: true,
          cardInfoLoading: false,
          cardNumber: activeFuelCardDetails.partialCardNumber
        });
        break;
      case 'InActive':
        this.setState({
          fuelCardDetails: activeFuelCardDetails,
          cardStatus: cardStatus,
          cardInfoLoaded: true,
          cardInfoLoading: false,
          cardNumber: activeFuelCardDetails.partialCardNumber
        });
        break;
      case 'Pending':
        this.setState({
          fuelCardDetails: activeFuelCardDetails,
          cardStatus: cardStatus,
          cardInfoLoaded: true,
          cardInfoLoading: false
        });
        break;
      default:
        this.setState({
          fuelCardDetails: activeFuelCardDetails,
          cardStatus: cardStatus,
          cardInfoLoaded: true,
          cardInfoLoading: false
        });
    }

    if (pinReset) {
      if (this.state.resetting === true) {
        this.setState({ resetting: false });
      }
      pinReset = false;
    }

    if (apiEncounteredError && this.state.cardInfoLoading) {
      this.setState({ cardInfoLoading: false });
    } else if (apiEncounteredError && this.state.resetting) {
      this.setState({ resetting: false });
    }
    apiEncounteredError = false;
  }

  activateCard() {
    let data = {
      cardNumber: this.props.fuelCardPayment.number,
      pinNumber: this.state.pin
    };
    this.props.activateCard(data);
    this.setState({ pin: '', cardInfoLoading: true });
  }
  resetPin() {
    let data = {
      pinNumber: this.state.resetPin
    };
    this.props.resetCardPin(data);
    this.setState({ reset: false, resetPin: '', resetting: true });
  }

  isValidNumber(value) {
    return !value.length || /^([0-9]\d*)?$/.test(value);
  }

  isValidpin(value) {
    return /[0-9]{4}/.test(value);
  }

  render() {
    const maskedNumber = this.state.cardNumber
      ? '*'.repeat(14).concat(this.state.cardNumber)
      : null;
    const activeCard = isAllowed([PERMISSION_FUEL_CARD_ACTIVE]);
    const pendingCard = isAllowed([PERMISSION_FUEL_CARD_PENDING]);

    return (
      <div className="fuel-card-page">
        {
          <Loading
            isLoading={this.state.cardInfoLoading || this.state.resetting}
          />
        }
        <VanpoolSummary />
        <p id="as-of-date-time">
          Fuel Card - Last Updated{' '}
          <span className="fuel-card-date">
            {this.props.fuelCardPayment.summary.wexFilelastImportedDate}
          </span>
        </p>
        <ExportCsv />
        <div className="qnav">
          <NavLink
            activeClassName="active"
            id="card-summary-link"
            exact={true}
            to="/payment/fuel"
          >
            Card Summary
          </NavLink>
          {isAllowed([PERMISSION_COORDINATOR, PERMISSION_BILLING_VIEWER]) ? (
            <NavLink
              activeClassName="active"
              id="card-activated-link"
              exact={true}
              to="/payment/fuel/activated"
            >
              {pendingCard ? 'Activate Card' : 'Card Activated'}
            </NavLink>
          ) : null}
        </div>
        <Switch>
          <Route
            path="/payment/fuel"
            exact
            render={() =>
              pendingCard ? (
                <div className="card-pending">
                  <h4>Your Commute fuel card has not been activated.</h4>
                  <p>
                    Please{' '}
                    <Link to="/payment/fuel/activated">activate your card</Link>{' '}
                    or for assistance call <Callable phone="800-VAN-4-WORK" />.
                  </p>
                </div>
              ) : (
                <FuelCardSummary />
              )
            }
          />
          {isAllowed([PERMISSION_COORDINATOR, PERMISSION_BILLING_VIEWER]) ? (
            <Route
              path="/payment/fuel/activated"
              exact
              render={() => (
                <div>
                  <div className="logo">
                    <img
                      src={`${config.imagePath}/wex-logo.png`}
                      alt="Wex Card"
                    />
                  </div>
                  {pendingCard ? (
                    <div className="activate-the-card">
                      <div>
                        <h1>Activate Your Card</h1>
                        <span style={{ paddingBottom: '2rem' }}>
                          To activate your Commute fuel card, enter the account
                          number from the front of your card.
                        </span>
                      </div>
                      <div className="card-input">
                        <div className="input-box">
                          <FancyInput
                            type="text"
                            className="fuel-card-input"
                            autoFocus
                            label="Account Number"
                            maxLength="18"
                            value={this.props.fuelCardPayment.number}
                            onChange={e =>
                              this.isValidNumber(e.target.value) &&
                              this.props.updateNumber(e.target.value)
                            }
                          />
                           <WexFuelCardActivationInfo />
                          {this.isValidNumber(
                            this.props.fuelCardPayment.number
                          ) &&
                          this.props.fuelCardPayment.number.length === 18 ? (
                            <span className="active-card">
                              <Check focusable="false" />
                            </span>
                          ) : null}
                          {isNaN(this.props.fuelCardPayment.number) &&
                          this.props.fuelCardPayment.number.length < 18 ? (
                            <span className="error-text">
                              Invalid fuel card account number, please try
                              again.
                            </span>
                          ) : null}
                          <br />
                        </div>
                        <div className="input-info">
                          Numbers only. No Spaces or Dashes.
                        </div>
                      </div>
                      <div className="pin-input">
                        <div>Create a four-digit PIN for your card.</div>
                        <br />
                        <div className="input-box">
                          <FancyInput
                            type="text"
                            className="fuel-card-pin"
                            label="PIN Number"
                            maxLength="4"
                            value={this.state.pin}
                            onChange={e =>
                              this.isValidNumber(e.target.value) &&
                              this.setState({ pin: e.target.value })
                            }
                          />
                          {this.isValidpin(this.state.pin) ? (
                            <span className="active-card">
                              <Check focusable="false" />
                            </span>
                          ) : null}
                          <br />
                        </div>
                        <div className="input-info">
                          The PIN must be numbers only.
                        </div>
                      </div>
                      <div>
                        <button
                          className="primary button"
                          disabled={
                            this.isValidNumber(
                              this.props.fuelCardPayment.number
                            ) &&
                            this.props.fuelCardPayment.number.length === 18 &&
                            this.isValidpin(this.state.pin)
                              ? false
                              : true
                          }
                          onClick={this.activateCard}
                        >
                          Activate Card
                        </button>
                      </div>
                    </div>
                  ) : null}
                  {activeCard ? (
                    <div className="active-card">
                      <div>
                        <h1>Card Activated</h1>
                        <span style={{ paddingBottom: '2rem' }}>
                          Your Commute fuel card is active.
                        </span>
                      </div>
                      <div className="card-input">
                        <div className="input-box">
                          <FancyInput
                            type="text"
                            className="fuel-card-input"
                            label="Account Number"
                            maxLength="18"
                            value={maskedNumber || ''}
                            readOnly
                          />
                          {
                            <span className="active-card">
                              <Check focusable="false" />
                            </span>
                          }
                          {isNaN(this.props.fuelCardPayment.number) &&
                          this.props.fuelCardPayment.number.length < 18 ? (
                            <span className="error-text">
                              Invalid fuel card account number, please try
                              again.
                            </span>
                          ) : null}
                          <br />
                        </div>
                      </div>
                      {this.state.reset ? (
                        <div>
                          <div className="pin-input">
                            <div>Create a four-digit PIN for your card.</div>
                            <br />
                            <div className="input-box">
                              <FancyInput
                                type="text"
                                className="fuel-card-pin"
                                autoFocus
                                label="PIN Number"
                                maxLength="4"
                                value={this.state.resetPin}
                                onChange={e =>
                                  this.isValidNumber(e.target.value) &&
                                  this.setState({ resetPin: e.target.value })
                                }
                              />
                              {this.isValidpin(this.state.resetPin) ? (
                                <span className="active-card">
                                  <Check focusable="false" />
                                </span>
                              ) : null}
                              <br />
                            </div>
                            <div className="input-info">
                              The PIN must be numbers only.
                            </div>
                          </div>
                          <div>
                            <button
                              className="primary button"
                              value=""
                              disabled={
                                this.isValidNumber(
                                  this.props.fuelCardPayment.number
                                ) && this.isValidpin(this.state.resetPin)
                                  ? false
                                  : true
                              }
                              onClick={this.resetPin}
                            >
                              Reset Pin
                            </button>
                          </div>
                        </div>
                      ) : this.state.fuelCardDetails &&
                        this.state.fuelCardDetails.isResetPinAllowed ? (
                        <div className="reset-pin">
                          <button
                            className="reset-pin-link"
                            onClick={() => this.setState({ reset: true })}
                            rel="noopener noreferrer"
                          >
                            Reset PIN Number
                          </button>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              )}
            />
          ) : null}
        </Switch>

        <div className="note call">
          For assistance with your Commute fuel card, call{' '}
          <Callable phone="800 VAN 4 WORK" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    fuelCardPayment: state.fuelCardPayment
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.GET_FUEL_CARD_SUMMARY });
      dispatch({ type: actionTypes.GET_FUEL_CARD_DETAILS });
    },
    activateCard(data) {
      dispatch({ type: actionTypes.ACTIVATE_FUEL_CARD, payload: data });
    },
    resetCardPin(data) {
      dispatch({ type: actionTypes.RESET_FUEL_CARD_PIN, payload: data });
    },
    updateNumber(number) {
      dispatch({
        type: actionTypes.ACTIVATE_FUEL_CARD_NUMBER_INPUT,
        payload: number
      });
    },
    updatePin(pin) {
      dispatch({ type: actionTypes.UPDATE_FUEL_CARD_PIN, payload: pin });
    },
    updateResetPin(resetPin) {
      dispatch({
        type: actionTypes.RESET_FUEL_CARD_PININPUT,
        payload: resetPin
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FuelCardPayment);
