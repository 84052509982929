import React from 'react';
import { Link } from 'react-router-dom';
import UserService from '../shared/user-service';
export default function() {
  const profile = UserService.getUserProfile();
  return (
    <div className="vanpool-question">
      <label>Do you belong to a Commute Vanpool?</label>
      <div className="vanpool-yes-no">
        {
          profile.isFederatedLogin ? 
          <Link to="/registration/commute-user-contact-details" className="button yes pill">
            Yes
          </Link>
          :
          <Link to="/registration/vanpool" className="button yes pill">
          Yes
          </Link>
        }
       
        <Link
          to="/registration/commute-profile"
          className="button no primary pill"
        >
          No
        </Link>
      </div>
    </div>
  );
}
