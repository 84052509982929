import Line from '../../../_styles/images/Line.png';
import TripOrigin from '../../../_styles/images/TripOrigin.png';
import LocationGreen from '../../../_styles/images/LocationGreen.png';
import dateUtility from '../../../shared/dateUtility';
import { useEffect, useState } from 'react';
import MapService from '../../../shared/map-service';


export function ShortRouteDetails(props) {

  return (
    <div className='addresses-container'>
      <div className='address-details' role='group'>
        <div className='heading' role='heading'>To Work</div>
        <div className="van-route-details">
          <div className="route-icon" aria-hidden='true'>
            <img className="group" src={TripOrigin} />
            <img className="line" src={Line} />
            <img className="LocationGreen" src={LocationGreen} />
          </div>
          {props.commute && props.addresses && props.addresses.origin && props.addresses.destination && <div className="route-address">
            <div className="origin-address">
              <div className="start-address">{`Depart: ${props.commute && props.commute.originDepartureTime && dateUtility.parseTime(props.commute.originDepartureTime)}`}</div>
              <div className="start-timings" aria-label={`Depart address, ${props.addresses.origin.description}`}>{props.addresses.origin.description}</div>
            </div>
            <div className="destination-address">
              <div className="end-address">{`Arrive: ${props.commute && props.commute.destinationArrivalTime && dateUtility.parseTime(props.commute.destinationArrivalTime)}`}</div>
              <div className="end-timings" aria-label={`Arrive address, ${props.addresses.destination.description}`}>{props.addresses.destination.description}</div>
            </div>
          </div>}
        </div>
      </div>
      <div className='address-details' role='group'>
        <div className='heading' role='heading'>To Home</div>
        <div className="van-route-details">
          <div className="route-icon" aria-hidden='true'>
            <img className="group" src={TripOrigin} />
            <img className="line" src={Line} />
            <img className="LocationGreen" src={LocationGreen} />
          </div>
          {props.commute && props.addresses && props.addresses.origin && props.addresses.destination && <div className="route-address">
            <div className="origin-address">
              <div className="start-address">{`Depart: ${props.commute && props.commute.destinationDepartureTime && dateUtility.parseTime(props.commute.destinationDepartureTime)}`}</div>
              <div className="start-timings" aria-label={`Depart address, ${props.addresses.destination.description}`}>{props.addresses.destination.description}</div>
            </div>
            <div className="destination-address">
              <div className="end-address">{`Arrive: ${props.commute && props.commute.originArrivalTime && dateUtility.parseTime(props.commute.originArrivalTime)}`}</div>
              <div className="end-timings" aria-label={`Arrive address, ${props.addresses.origin.description}`}>{props.addresses.origin.description}</div>
            </div>
          </div>}
        </div>
      </div>
    </div>

  );
}