import * as React from "react"

function Van(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.72 3.8h12.727c1.419 0 2.365.358 2.981.81.304.224.51.458.645.654a2.128 2.128 0 01.18.312l1.721 3.034a6.199 6.199 0 011.28.729c.81.603 1.746 1.653 1.746 3.243v1.956c0 1.373-.372 2.267-.95 2.803a2.1 2.1 0 01-.807.473 1.842 1.842 0 01-.448.08h-.03c-.001 0-.002 0-.002-.914v.914h-1.32l.064-.996c.039-.595-.128-1.174-.453-1.585-.306-.388-.79-.68-1.535-.68-.743 0-1.227.291-1.534.68-.325.412-.493.991-.455 1.586l.064.995H7.67l.082-1.01c.048-.601-.114-1.191-.44-1.612-.306-.397-.794-.694-1.548-.694-.75 0-1.238.297-1.546.694-.326.421-.489 1.012-.44 1.612l.08 1.01H2.482a2.077 2.077 0 01-1.6-.738c-.343-.394-.536-.873-.652-1.27a6.066 6.066 0 01-.226-1.44A4.79 4.79 0 010 14.34v-.032L0 14.299v-.005s0-.002.935-.002H0v-7.83l.007-.059c.154-1.212.797-1.912 1.466-2.27A2.899 2.899 0 012.699 3.8h.022v.935V3.8zm.015 1.87a.59.59 0 00-.09.012 1.03 1.03 0 00-.292.101c-.162.087-.402.27-.483.802v7.719l.002.064a4.628 4.628 0 00.155.997c.011.041.024.08.036.117.137-.484.36-.947.677-1.355.666-.86 1.702-1.42 3.024-1.42 1.324 0 2.361.56 3.027 1.42.432.558.691 1.22.792 1.897h5.129c.108-.669.372-1.321.805-1.87.667-.845 1.695-1.39 3.002-1.39 1.307 0 2.336.545 3.003 1.39.242.306.43.644.57 1 .023-.17.038-.374.038-.616v-1.956c0-.767-.434-1.327-.993-1.744a4.333 4.333 0 00-1.083-.58l-.01-.004h-.001l-.348-.11-2.133-3.76-.013-.035a.93.93 0 00-.229-.231c-.239-.176-.777-.448-1.873-.448H2.735z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.318 9.503l.486-2.395c0-.144-.175-.262-.389-.262h-7.1c-.213 0-.388.118-.388.262v2.395c0 .144.175.262.388.262H9.93c.213 0 .388-.118.388-.262zM12.165 7.108l-.436 2.395c0 .144.157.262.349.262h5.264c.192 0 .534 0 .349-.262L16.31 7.108c-.076-.18-.397-.262-.589-.262h-3.207c-.192 0-.349.118-.349.262zM18.438 14.537a1.898 1.898 0 10-.002 3.797 1.898 1.898 0 00.002-3.797zm-3.77 1.898a3.769 3.769 0 117.538 0 3.769 3.769 0 01-7.538 0zM5.76 14.537a1.898 1.898 0 100 3.796 1.898 1.898 0 000-3.796zm-3.768 1.898a3.769 3.769 0 117.537 0 3.769 3.769 0 01-7.537 0z"
        fill="#4A4A4A"
      />
    </svg>
  )
}

export default Van
