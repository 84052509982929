import React, { Component } from 'react';

class SmartBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAndroid: false,
      isIpad: false,
      isIphone: false,
      otherBrowser: false
    };
  }
  componentDidMount() {
    if (/Android/i.test(navigator.userAgent)) {
      this.setState({ isAndroid: true });
    }
    if (/iPad/i.test(navigator.userAgent)) {
      this.setState({ isIpad: true });
    }
    if (/iPhone/i.test(navigator.userAgent)) {
      this.setState({ isIphone: true });
    }
    if (navigator.userAgent.match(/Chrome|CriOS|FxiOS|Firefox/i)) {
      this.setState({ otherBrowser: true });
    }
  }
  render() {
    return (
      <div className="smart-banners">
        <div className="AndroidBanner">
          {this.state.isAndroid ? (
            <div className="smartbanner" id="smartabanner">
              <div className="smartbanner-container">
                <button
                  href="#"
                  id="smb-close"
                  className="smartbanner-close"
                  onClick={() => this.setState({ isAndroid: false })}
                  rel="noopener noreferrer"
                >
                  &times;
                </button>
                <span className="smartbanner-icon"></span>
                <div className="smartbanner-info">
                  <div className="smartbanner-title">
                    Commute With Enterprise
                  </div>
                  <div>Enterprise Holdings, Inc.</div>
                  <span>Free &ndash; Google Play</span>
                </div>
                <div className="smartbanner-btn">
                  <a
                    className="smartbanner-button"
                    href="intent://#Intent;scheme=commutewithenterprise;package=com.commutewithenterprise.mobile;end"
                    rel="noopener noreferrer"
                  >
                    <span className="smartbanner-button-text">VIEW</span>
                  </a>
                </div>
                <div className="hidden"></div>
              </div>
            </div>
          ) : null}
        </div>
        <div className="ios-banner">
          {(this.state.isIpad || this.state.isIphone) &&
          this.state.otherBrowser ? (
            <div className="ios-smartbanner">
              <button
                className="smartbanner__exit "
                onClick={() =>
                  this.setState({ isIpad: false, isIphone: false })
                }
                rel="noopener noreferrer"
              />
              <div className="smartbanner__icon"></div>
              <div className="smartbanner__info">
                <div>
                  <div className="smartbanner__info__title">
                    Commute with Enterprise
                  </div>
                  <div className="smartbanner__info__author">
                    Enterprise Holdings Inc.
                  </div>
                  <div className="smartbanner__info__price">
                    FREE - On the App Store
                  </div>
                </div>
              </div>
              <a
                href="itmss://itunes.apple.com/us/app/id1440331154"
                target="_blank"
                rel="noopener noreferrer"
                className="smartbanner__button"
              >
                <span className="smartbanner__button__label">VIEW</span>
              </a>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default SmartBanner;
