import React from 'react';
import PaymentsPage from './payments/PaymentsPage';
import InvoicePage from './invoices/InvoicesPage';
import FuelCardHistory from './FuelCardsHistory';
import PaymentCards from './PaymentCards';
import { Route, Redirect } from 'react-router-dom';
import { isAllowed, isAnyPresent } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_BILLING_VIEWER,
  PERMISSION_IPB,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN,
  PERMISSION_SUBSCRIPTION_USER
} from '../shared/permissions/permissions';
import InvoicesDetails from './invoices/InvoicesDetails';
import PaymentHistoryDetail from './payments/PaymentHistoryDetail';
import PaymentSubscription from '../payment/payment-subscription/SubscriptionPlanDetail';
import PaymentInfo from './payment-info/PaymentInfo';
import FuelCard from './fuel-card/FuelCard';

export default function(props) {
  return (
    <div className="payment-history">
      {isAllowed([PERMISSION_SUBSCRIPTION_USER])
        ? !isAnyPresent([PERMISSION_IPB]) && (
            <>
              <Route
                path={`${props.match.path}/invoices`}
                component={InvoicesDetails}
              />
              <Route
                path={`${props.match.path}/history`}
                component={PaymentHistoryDetail}
              />
              <Route
                path={`${props.match.path}/subscription`}
                component={PaymentSubscription}
              />
              <Route
                path={`${props.match.path}/cards`}
                component={PaymentCards}
              />
              <Route
                path={`${props.match.path}/fuel`}
                component={FuelCard}
              />
            </>
          )
        : isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]) &&
          !isAnyPresent([PERMISSION_IPB]) && (
            <Route
              path={`${props.match.path}/invoices`}
              component={InvoicePage}
            />
          )
          }

        <Route path={`${props.match.path}/payments`} component={PaymentsPage} />
        {
         !isAllowed([PERMISSION_SUBSCRIPTION_USER]) &&
         <Route path={`${props.match.path}/cards`} component={PaymentInfo} /> 
        }
     


      {isAllowed([PERMISSION_SUBSCRIPTION_USER])
        ? !isAnyPresent([PERMISSION_IPB]) && (
            <Route
              exact
              path={`${props.match.path}`}
              render={() => <Redirect to={`${props.match.path}/invoices`} />}
            />
          )
        : isAllowed([
            PERMISSION_BILLING_VIEWER,
            PERMISSION_ACCOUNT_DASHBOARD_ADMIN
          ]) &&
          !isAnyPresent([PERMISSION_IPB]) && (
            <Route
              exact
              path={`${props.match.path}`}
              render={() => <Redirect to={`${props.match.path}/payments`} />}
            />
          )}
    </div>
  );
}
