import UserService from '../shared/user-service';
import { isAllowed } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_VANPOOL_STATUS_RENTAL,
  PERMISSION_VANPOOL_TRIP_RECORDING,
  PERMISSION_REGISTERED_USER,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../shared/permissions/permissions';

export default {
  isAuthorized: () =>
    UserService.isAuthenticated() &&
    isAllowed([
      [PERMISSION_VANPOOL_STATUS_RENTAL, PERMISSION_VANPOOL_TRIP_RECORDING],
      [PERMISSION_REGISTERED_USER, PERMISSION_TRIP_RECORDING],
      [PERMISSION_ACCOUNT_DASHBOARD_ADMIN, PERMISSION_VANPOOL_TRIP_RECORDING]
    ])
};
