import React, { useEffect, useState } from 'react';
import { User, Vanpool } from '../../shared/icons';

import ImpactData from './ImpactData';
import ImpactDataInfo from './ImpactDataInfo';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../../shared/permissions/permissions';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import analyticsService from '../../shared/analytics/analytics-service';

export default function ImpactDataContainer({ includeParking, includeAccountDashboardInfo, isNewSubscription }) {
  const [show, setShow] = useState(isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL]) ? 'participant' : 'registeredUser');
  const [analyticsSent, setAnalyticsSent] = useState(false);

  useEffect(() => {
    if (!analyticsSent || (analyticsSent && show !== 'registeredUser')) {
      setAnalyticsSent(true);
      if (show === 'participant') {
        analyticsService.analyticsProcessEvent({
          "event": "click_generic",
          "context": {
            "event_action": "view personal savings"
          }
        });
      } else if (show === 'vanpool') {
        analyticsService.analyticsProcessEvent({
          "event": "click_generic",
          "context": {
            "event_action": "view vanpool savings"
          }
        })
      }
    }
  }, [])

  const participantActive = show === 'participant';
  const isRegisteredUser = show === 'registeredUser';

  const handleToggleClick = (toggleShow) => {
    setShow(toggleShow);
  };

  return (
    <div className="impact-data-container solid" role='group'>
      <div className="impact-data-content">
        <div className="label">
          <h3>Estimated Savings</h3>
          <ImpactDataInfo includeParking={includeParking} includeAccountDashboardInfo={includeAccountDashboardInfo} />
          <div className="time">(Month-to-Date)</div>
        </div>
        <div className="impact-data-toggle">
          <div className="title">
            {participantActive ? 'Personal' : 'Vanpool'}
          </div>
          {isRegisteredUser ? (
            <div className="toggle">
              <div className="label">
                <div className="title">
                  My Data <ImpactDataInfo includeParking={includeParking} includeAccountDashboardInfo={includeAccountDashboardInfo} />
                </div>
                <div className="time">Month to Date</div>
              </div>
            </div>
          ) : (
            <div className="toggle">
              <button
                className={`${participantActive ? 'active' : ''} my-data`}
                disabled={participantActive}
                onClick={() => handleToggleClick('participant')}
              >
                <User />
              </button>
              <button
                className={`${!participantActive ? 'active' : ''} vanpool`}
                disabled={!participantActive}
                onClick={() => handleToggleClick('vanpool')}
              >
                <Vanpool />
              </button>

            </div>
          )}
          <div className="mobile-only">
            {participantActive ? 'Personal' : 'Vanpool'}
          </div>
        </div>
      </div>
      <ImpactData show={show} isNewSubscription={isNewSubscription} />
    </div>
  );
}
