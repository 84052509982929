import * as actionTypes from '../../redux/actionTypes';
import analyticsService from '../../shared/analytics/analytics-service';

const initialState = {
  switchReasons: [],
  error: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VEHICLE_SWITCH_REASONS_LOADED:
      return { ...state, ...initialState, switchReasons: action.data };
    case actionTypes.VEHICLE_SWITCH_REASONS_ERROR:
      return { ...state, ...initialState, ...action.data };
    case actionTypes.VEHICLE_SWITCH_REQUEST:
      return { ...state, switchReasonsSuccess: null };
    case actionTypes.VEHICLE_SWITCH_SUCCESS:
      analyticsService.analyticsEvent({ type: 'Vehicle Change' });
      return { ...state, switchRequestSuccess: true };
    case actionTypes.VEHICLE_SWITCH_FAILURE:
      return { ...state, switchRequestSuccess: false };
    default:
      return state;
  }
};
