import alertIcon from '../../../_styles/images/AlertYellow.png'

export default function CloseMatchNotification(props) {
  const {isDesktop } = props;
  return (
    <div role='group' className={isDesktop ? 'alert-box desktop' : 'alert-box mobile'}>
      <div className="alert-box-icon"><img src={alertIcon} alt='alert icon' /></div>
      <div className="alert-text">
        <div className="alert-box-heading">You’ve selected a close match</div>
        <div className="alert-box-content">Please note that this vanpool meets many of your search criteria, but not all of them</div>
      </div>
    </div>
  );
}