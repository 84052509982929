import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import { Redirect } from 'react-router-dom';
import PaymentName from './components/PaymentName';
import PaymentAmount from './components/PaymentAmount';
import Card from '../../shared/credit-card/Card';
import StopRecurringConfirmationPopup from '../../payment/StopRecurringConfirmationPopup';

class ModifyRecurringPaymentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFocused: false,
      originalPayment: null,
      payment: null,
      modal: {
        active: false
      },
      shouldRedirect: false
    };
  }

  componentDidMount() {
    this.props.init();
  }

  componentWillReceiveProps(props) {
    const paymentId = window.location.hash.split('/').pop();

    if (props.scheduledPayments) {
      const payment = props.scheduledPayments.scheduled.find(
        payment => payment.id.toString() === paymentId
      );

      if (!this.state.originalPayment && payment) {
        this.setState({ originalPayment: { ...payment } });
      }

      this.setState({ payment: props.payment || payment });
    }
  }

  updateState(props) {
    this.setState({ payment: { ...this.state.payment, ...props } });
  }

  setNameInput(ref) {
    if (!this.state.hasFocused && ref) {
      this.setState({ hasFocused: true });
      ref.focus();
    }
  }

  canUpdate(payment, original) {
    return (
      payment &&
      payment.amount &&
      payment.name &&
      !(
        payment.name === original.name &&
        parseFloat(payment.amount) === parseFloat(original.amount)
      )
    );
  }

  closeRemoveModal() {
    this.setState({ modal: { active: false } });
  }

  removePaymentClicked() {
    this.props.removePayment(this.state.payment);
    this.closeRemoveModal();
    this.setState({ shouldRedirect: true });
  }

  render() {
    if (!this.state.payment) {
      return null;
    }

    if (this.state.shouldRedirect) {
      return <Redirect to={'/payment/history/payments'} />;
    }

    const { review } = this.props;
    const { payment, originalPayment } = this.state;
    const canUpdate = this.canUpdate(payment, originalPayment);

    return (
      <div className="modify recurring">
        <StopRecurringConfirmationPopup
          active={this.state.modal.active}
          paymentName={this.state.payment.name}
          cancel={() => this.closeRemoveModal()}
          confirm={() => this.removePaymentClicked()}
        />
        <div className="title-toggle section">
          <h2 className="title">Modify Recurring Payment</h2>
        </div>
        <PaymentName
          name={payment.name}
          updateState={state => this.updateState(state)}
          inputRef={ref => this.setNameInput(ref)}
        />
        <PaymentAmount
          amount={payment.amount}
          updateState={state => this.updateState(state)}
        />
        <div className="schedule section visible">
          <h3>Payment Info</h3>
          <Card {...payment} />
          <div className="occurrence-text">
            Your recurring payment is scheduled for the{' '}
            <span className="date">{payment.every} of the month.</span>
          </div>
        </div>

        <div className="tools section">
          <div className="secondary">
            <button
              className="button delete"
              onClick={() => {
                this.setState({ modal: { active: true } });
              }}
            >
              Remove Payment
            </button>
          </div>
          <div className="primary">
            <button className="button cancel" onClick={this.props.cancel}>
              Cancel
            </button>
            <button
              className="button primary pay"
              onClick={() => review({ ...payment, selectedCard: payment })}
              disabled={!canUpdate}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    scheduledPayments: state.scheduledPayments
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.SCHEDULED_PAYMENTS_LOADING });
    },
    removePayment(payment) {
      dispatch({
        type: actionTypes.SCHEDULED_PAYMENTS_DELETING,
        data: payment
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyRecurringPaymentForm);
