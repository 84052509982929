import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from '../loading/Loading';
import ConfirmationPopup from '../popup/ConfirmationPopup';
import { withRouter } from 'react-router-dom';
import { SET_USER_AUTHORIZATION } from '../../redux/actionTypes';

const initialState = { active: false, ready: false, deactivating: false };

export class DocusignPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState, confirming: false, isIpad: false, isIphone: false};
  }

  componentDidMount() {
    if (/iPad/i.test(navigator.userAgent)) {
      this.setState({ isIpad: true });
    }
    if (/iPhone/i.test(navigator.userAgent)) {
      this.setState({ isIphone: true });
    }
  }

  componentWillReceiveProps(props) {
    if (props.docusign) {
      this.setState({ active: true, ready: false });
    } else if (props.confirmation.declined) {
      this.declined();
    } else {
      this.setState({ ...initialState });
    }

    if (props.confirmation.title) {
      this.setState({ confirming: true });
    }
  }

  ready() {
    this.setState({ ready: true, confirming: false });
  }

  docusignComplete(url) {
    this.setState({ ready: false });
    this.props.status(url);
  }

  handleFrame(e) {
    try {
      if (
        this.state.ready === true &&
        e.target.contentDocument.URL.startsWith(window.location.origin)
      ) {
        return this.docusignComplete(e.target.contentDocument.URL);
      }
    } catch (error) {
      console.log(error);
    }

    if (e.target.src === this.props.docusign) {
      if (!this.state.ready) {
        // for some reason, 2 onload events fire and the first does not indicate that the actual page has loaded
        return this.setState({ ready: setTimeout(() => this.ready(), 5000) });
      }
      clearTimeout(this.state.ready);
      this.ready();
    }
  }

  confirm() {
    this.props.history.replace(this.props.redirectUrl);
    this.setState({ confirming: false });
    this.props.dispatch({
      type: SET_USER_AUTHORIZATION,
      data: this.props.nextProfile
    });
  }

  declined() {
    this.props.history.replace(this.props.cancelRedirectUrl);
    this.setState({ confirming: false });
  }

  render() {
    if (this.props.error) {
      return null;
    }

    if (!this.state.active && !this.props.loading && !this.state.confirming) {
      return null;
    }

    return (
      <div
        className={`popup-container ${this.state.deactivating ? 'out' : ''}`}
      >
        <Loading isLoading={this.state.ready !== true} />
        <div className={`docusign ${this.state.ready === true ? 'ready' : ''} ${(this.state.isIpad || this.state.isIphone )  ?  'iosDocusign' : ''}`}>
          {this.props.docusign ? (
            <iframe
              id="docusign-frame"
              title="Docusign"
              src={this.props.docusign}
              onLoad={e => this.handleFrame(e)}
            ></iframe>
          ) : null}
        </div>
        <ConfirmationPopup
          active={this.state.confirming}
          confirm={() => this.confirm()}
          {...this.props.confirmation}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.application.error,
    docusign: state.application.docusignSessionURL,
    title: state.application.title || '',
    confirmation: state.application.confirmation,
    nextProfile: state.application.nextProfile
  };
}

export default withRouter(connect(mapStateToProps)(DocusignPopup));
