import React from 'react';
import AddCard from '../../../shared/credit-card/AddCard';
import { NEW_CARD_ID } from '../../../shared/credit-card/creditCardService';
import Radio from './Radio';

const NEW_CARD = {
  id: 'NEW_CARD_ID',
  isNewCard: true,
  cvc: ''
};

export default function(props) {
  return (
    <div className="new-card section">
      <Radio
        onSelect={() => selectCard(props)}
        className="radio"
        selected={props.selected === NEW_CARD_ID}
      >
        <h3>Enter a New Card</h3>
        <fieldset disabled={props.selected !== NEW_CARD_ID}>
          <AddCard
            recurring={props.recurring}
            onChange={card => updateNewCard(card, props)}
            value={props.newCard}
          />
        </fieldset>
      </Radio>
    </div>
  );
}

export { NEW_CARD };

function updateNewCard(card, props) {
  props.updateState({
    selectedCard: {
      ...NEW_CARD,
      ...card
    }
  });
}

function selectCard(props) {
  if (NEW_CARD_ID === props.selected) {
    return;
  }
  props.updateState({
    selectedCard: { ...NEW_CARD }
  });
}
