import { PERMISSION_COORDINATOR, PERMISSION_DRIVER } from '../../../../shared/permissions/permissions';

import InfoCircle from '../../../../_styles/images/new-icons/driver-status/info_circle.svg';
import { RestrictTo } from '../../../../shared/permissions/RestrictTo';
import { hoursInBetween } from '../../../../payment/helper';
import { numberWithCommas } from '../../../../join-commute/helpers/commonHelper';
import { useState, useEffect } from 'react';
import analyticsService from '../../../../shared/analytics/analytics-service';

const getError = (error, min, max) => {
  if (error === 'sameMileageError') {
    return 'Estimated mileage cannot be the same value';
  } else if (error === 'mileageError') {
    return `Odometer must be between ${min} and ${max}`;
  }
}

export default function UpdateMileage(props) {
  const [isUpdate, updateIsUpdate] = useState(false);
  const [mileage, updateMileage] = useState(props.odometerValue);
  const [prevMileage, updatePrevMileage] = useState(0);
  const [isMileageError, updateIsMileageError] = useState(false);
  const [isSameMileageError, updateIsSameMileageError] = useState(false);
  const [saveDisabled, updateSaveDisabled] = useState(false);
  const [errorText, updateErrorText] = useState('');
  const [isUpdateAvailable, updateIsUpdateAvailable] = useState(false);
  const [lastUpdated, updateLastUpdated] = useState('');

  useEffect(() => {
    if (props.lastOdometerDate) {
      const lastDate = new Date(props.lastOdometerDate);
      const today = new Date().setHours(0, 0, 0, 0);
      const todayAbs = new Date(today);
      const hoursDiff = hoursInBetween(todayAbs, lastDate);
      if (hoursDiff < 24) {
        updateIsUpdateAvailable(false);
        updateLastUpdated('Today, '+props.lastOdometerDate);
      } else {
        updateIsUpdateAvailable(true);
        updateLastUpdated(props.lastOdometerDate);
      }
    }
  }, [props.lastOdometerDate])

  function checkAndUpdateMileage(value) {
    if (/^\d{0,}$/.test(value)) {
      const val = parseInt(value);
      updateMileage(value);
      if (val === prevMileage) {
        updateIsSameMileageError(true);
        const error = getError('sameMileageError');
        updateErrorText(error);
      } else {
        updateIsSameMileageError(false);
      }
      const maxVal = prevMileage + 500;
      const minVal = ((prevMileage - 500) < 0 ? 0 : (prevMileage-500));
      if (val > maxVal || val < minVal || !val) {
        updateIsMileageError(true);
        const error = getError('mileageError', minVal, maxVal);
        updateErrorText(error);
      } else {
        updateIsMileageError(false);
      }
    }
  }

  useEffect(() => {
    updateSaveDisabled(isSameMileageError || isMileageError);
  }, [isSameMileageError, isMileageError])

  useEffect(() => {
    updateMileage(props.odometerValue);
    if (props.odometerValue) {
      const val = parseInt(props.odometerValue)
      updatePrevMileage(val);
    }
  }, [props.odometerValue]);

  function cancelClicked() {
    updateMileage(props.odometerValue);
    updateIsMileageError(false);
    updateIsSameMileageError(false);
    updateErrorText('');
    updateIsUpdate(false);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "cancel"
      }
    });
  }

  function onSave() {
    props.onSave(mileage);
    updateIsUpdate(false);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "save"
      }
    });
  }

  function handleClick() {
    updateIsUpdate(true)
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "update"
      }
    });
  }

  const errorClass = (isSameMileageError || isMileageError) ? 'error' : '';
  return (
    <div>
      <div className='update-section'>
        <h2>Current Estimated Mileage</h2>
        {isUpdate ?
          <div className='dynamic-update'>
            <span className='update-note'>You can add or remove up to 500 miles</span>
            <div className='mileage-input'>
              <input className={errorClass} id='mileage' name='mileage' aria-label='Enter mileage' value={mileage} onChange={(e) => checkAndUpdateMileage(e.target.value)} />
              {(isMileageError || isSameMileageError) && <div id='mileage-error' role='alert' className='error-div'>
                {errorText}
              </div>}
            </div>
            <div className='button-container'>
              <button className='cancel-button' onClick={cancelClicked}>cancel</button>
              <button className='save-button' disabled={saveDisabled} onClick={onSave}>save</button>
            </div>
          </div>
          :
          <div className='dynamic-update'>
            <div className='mileage'>
              <div>{numberWithCommas(mileage)}</div>
              <div aria-label='miles'>mi</div>
            </div>
            {isUpdateAvailable ? <RestrictTo roles={[PERMISSION_DRIVER, PERMISSION_COORDINATOR]} required={false}>
              <div className='button-container'>
                <button className='update-button' onClick={() => handleClick()}>Update</button>
              </div>
            </RestrictTo> :
              <div className='wait-notification'>
                <div className='status-content pending'>
                  <div className='warning-icon' tabIndex={0}>
                    <img className='tick-icon' src={InfoCircle} alt="image icon" />
                  </div>
                  <div className='content-text'>
                    Please wait for 24 hours to make an update here.
                  </div>
                </div>
              </div>}
          </div>
        }

      </div>
      <span className='update-info'>Last Updated: {lastUpdated}</span>
    </div>
  );
}