import React from 'react';
import { Link } from 'react-router-dom';
import { Recurring } from '../../../shared/icons';
import LastPayment from './../components/LastPayment';

export default function(props) {
  return (
    <div className="payment-type">
      <h2 className="title">{paymentType(props.recurring)}</h2>
      <div className="select-payment-type">
        {props.recurring ? null : <Recurring />}
        <div>
          <h4>{paymentType(!props.recurring)}</h4>
          <p>
            <Link
              className="payment-toggle"
              replace={true}
              to={'/payment/pay' + (props.recurring ? '' : '/recurring')}
            >
              {props.recurring ? 'Make' : 'Set up'} a{' '}
              {paymentType(!props.recurring)}
            </Link>
          </p>
        </div>
      </div>
      <LastPayment />
    </div>
  );
}

function paymentType(recurring) {
  return recurring ? 'Recurring Payment' : 'One Time Payment';
}
