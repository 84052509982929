export default function CommuteDays(props) {

  return (
    <div className='days-container'>
      <div className="days">
        {props.days.map(day =>
          <button
            disabled={props.preselected.indexOf(day) !== -1}
            day={day}
            key={day}
            type='button'
            onClick={() => props.daysChanged(day)}
            className={`day-btn ${(props.preselected.indexOf(day) !== -1 || props.newlySelectedDays.indexOf(day) !== -1) ? 'active' : ''}`}>
            {day.substr(0,3)}
          </button>
        )}
      </div>
      {props.isError && <div className='error'>Please ensure you have only selected {props.maxDays} days.</div>}
    </div>
  );
}