import * as actionTypes from '../redux/actionTypes';
import { call, put, takeLatest } from 'redux-saga/effects';
import logger from '../shared/logger';
import config from '../appConfig';
import Http from '../shared/http-service';
import {getDaysFromRosterDays, getUpdatedRoosterDays} from '../join-commute/helpers/commonHelper'

export default function*() {
  yield takeLatest(actionTypes.VANPOOL_SEARCH_COUNT, findNearbyVanpools);
  yield takeLatest(actionTypes.REQUEST_TO_JOIN, requestToJoinVanpool);
}

function* findNearbyVanpools() {
  const vanpoolSearchUrl = `${config.kongApiBaseUrl}/vanpools/search/count`;
  const commuteUrl = `${config.kongApiBaseUrl}/user/profile/commuteProfile`;
  try {
    let commuteDetails = yield call(() => Http.get(commuteUrl));
    var days = getUpdatedRoosterDays(commuteDetails.commuteDays);
    var address = commuteDetails.originAddress.split(",");
    var state = address && address[address.length-2] && address[address.length-2].trim().slice(0,2);
    var request = {
      "homeLongitude": commuteDetails. originLongitude,
      "homeLatitude":  commuteDetails.originLatitude,
      "workLongitude": commuteDetails.destinationLongitude,
      "workLatitude": commuteDetails.destinationLatitude,
      "homeAddress": commuteDetails.originAddress,
      "workAddress": commuteDetails.destinationAddress,
      "arriveTime": commuteDetails.destinationArrivalTime,
      "departTime": commuteDetails.destinationDepartureTime,
      "flexArrivalMinutes": commuteDetails.isWorkArriveTimeFlexible ? 10 : 0,
      "flexDepartureMinutes": commuteDetails.isWorkDepartTimeFlexible ?  10: 0,
      "rosterDays": days,
      "stateName": state,
      "package": "",
      "employerId": commuteDetails.worksiteId ? commuteDetails.worksiteId  : null,
      "employerName": commuteDetails.employerName ? commuteDetails.employerName : null,
      "isDesiredDriverRole": false
  }
    const { matchCount } = yield call(() => Http.post(vanpoolSearchUrl,request));
    yield put({ type: actionTypes.VANPOOL_SEARCH_RESULT_COUNT, data: matchCount });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.VANPOOL_SEARCH_RESULT_COUNT, data: 0 });
  }
}

function* requestToJoinVanpool(data) {
  try {
    const result = yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/vanpool/requestToJoin`,data.data)
    );

    if (result.success) {
      yield put({ type: actionTypes.REQUEST_TO_JOIN_SUCCESS });
    } else {
      yield put({ type: actionTypes.REQUEST_TO_JOIN_FAILURE });
    }
  } catch (error) {
    logger.log(error);
    yield put({ type: actionTypes.REQUEST_TO_JOIN_FAILURE });
  }
}
