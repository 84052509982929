import * as actionTypes from '../../redux/actionTypes';
import NA_VALUE from '../../shared/na';

const initialState = {
  invoiceDueDate: NA_VALUE,
  currentAccountBalance: NA_VALUE,
  lastUpdatedDateTime: NA_VALUE
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BALANCE_DETAILS_LOADED:
      return { ...initialState, ...action.data };
    default:
      return state;
  }
};
