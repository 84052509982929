import React from 'react';
import FieldInfo from '../../shared/FieldInfo';

export function ImpactDataInfo({
  includeParking,
  includeAccountDashboardInfo
}) {
  return (
    <div className="lookup">
      <div className="pair">
        <div className="key">Miles Saved</div>
        <div className="value">Weekly distance traveled both directions</div>
      </div>
      <div className="pair">
        <div className="key">Fuel Saved</div>
        <div className="value">
          Single Occupancy Vehicle MPG value (24.8 MPG)
        </div>
      </div>
      <div className="pair">
        <div className="key">CO2 Saved</div>
        <div className="value">
          Constant CO2 lbs saved per gallon of gas burned x fuel gallons saved
        </div>
      </div>
      <div className="pair">
        <div className="key">Fuel Savings</div>
        <div className="value">
          Fuel gallons saved times weekly state fuel price
        </div>
      </div>
      {includeParking ? (
        <div className="pair">
          <div className="key">Parking Spots Saved</div>
          <div className="value">
            Riders trips, minus driver, multipled by scheduled days this month
          </div>
        </div>
      ) : null}
      {includeAccountDashboardInfo ? (
        <div>
          <div className="pair">
            <div className="key">Parking Spots Saved</div>
            <div className="value">
              Spots saved by not utilizing single occupancy vehicles
            </div>
          </div>
          <div className="pair">
            <div className="key">Parking Savings</div>
            <div className="value">
              Monetary value of a parking space x total parking spaces
              saved.  National average for a US surface spot equals $24,000.00
            </div>
          </div>
          <div className="pair">
            <div className="key">Goals</div>
            <div className="value">Quarterly established target (optional)</div>
          </div>
          <div className="pair">
            <div className="key">Potential</div>
            <div className="value">
              Quarterly established target of total employees likely to
              participate (optional)
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default function({ includeParking, includeAccountDashboardInfo }) {
  return (
    <FieldInfo
      message={
        <ImpactDataInfo
          includeParking={includeParking}
          includeAccountDashboardInfo={includeAccountDashboardInfo}
        />
      }
    />
  );
}
