import closeIcon from '../../../_styles/images/close-icon.svg';
import {useEffect} from 'react';

function CommonToast(props) {

  useEffect(() => {
    if(props && props.isOpen && props.timeOut){
      const timeID = setTimeout(() => {
        props.closeToast();
      }, props.timeOut);
      return () => {
        clearTimeout(timeID)
      }
    }
  }, [props]);
  return (
    props.isOpen && <div className='common-toast-container' role='alert' aria-live='polite'>
      <div className={`message-container ${props.type}`}>
        <div className='text' aria-busy='true'>
          {props.message}
        </div>
        <div className='close-section'>
          <div className='divider'></div>
          <div className='close' onClick={props.closeToast}>
            <img src={closeIcon} aria-hidden='true'/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonToast;