
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import * as actionTypes from '../redux/actionTypes';
import Callable from '../shared/Callable';
import RightIcon from '../_styles/images/Highlight.png';


function ThankyouScreen(props) {

  useEffect(() => {
    props.updateLoading(false);
  }, [])

  function goToDashboard() {
    props.setRefresh(true);
    props.history.push("/dashboard");
  }


  return (
    <div className="driver-coordinator-thankyou-page">
      <div className='container'>
        <div className='header'>
          <div className='header-image'>
            <img src={RightIcon} alt='alert' />
          </div>
          <div className='heading'>
            Thank You
          </div>
        </div>
        <div className="pre-text">Your contract/application was submitted, and we will be in touch if we require additional information to complete the process.
        </div>
        <div className='pre-text-2'>
          If you have any questions, please call <Callable className="greentext" phone="800 VAN-4-WORK" />
        </div>
        <div className="button-container">
          <button
            className='continue'
            onClick={goToDashboard}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoading(data) {
      dispatch({ type: actionTypes.LOADER_ACTIVE, data });
    },
    setRefresh(refresh) {
      dispatch({ type: actionTypes.REFRESH_DASHBOARD });
    },
  };
}

export default connect(null, mapDispatchToProps)(ThankyouScreen);
