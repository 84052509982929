import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionTypes from '../redux/actionTypes';
import { Bell } from '../shared/icons';
import Section from './Section';
import Slider from '../shared/Slider';

export function Preferences({ preferences, toggle }) {
  return (
    <div className="preferences">
      <Section title="Notification Preferences" Icon={Bell} isEditable={false}>
        {preferences.map(pref => {
          return (
            <div className="preference" key={pref.preferenceCode}>
              <Slider
                label={pref.preferenceName}
                checked={pref.isSelected}
                disabled={false}
                onChange={() => toggle(pref)}
              />
              {pref.preferenceCode === 'EPI' ? (
                <p className="note">
                  If you sign up for paperless billing, you’ll receive an email
                  every month with your invoice to view and pay. You can view,
                  print, or download your current and previous invoices from
                  your{' '}
                  <Link className="underline" to="/payment/history/invoices">
                    account Invoice tab
                  </Link>
                  .
                </p>
              ) : null}
            </div>
          );
        })}
      </Section>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    toggle(pref) {
      dispatch({ type: actionTypes.TOGGLE_PREFERENCE, data: pref });
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Preferences);
