import * as actionTypes from '../../../redux/actionTypes';

const initialState = {
  approved: null,
  changeValidation:false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRIP_RECORDING_APPROVED:
      return { ...state, approved: true };
    case actionTypes.TRIP_RECORDING_NOT_APPROVED:
      return { ...state, approved: false };
      case actionTypes.TRIP_RECORDING_STATUS_VALIDATE:
        return {...state,changeValidation : action.data.changeValidation};
    default:
      return state;
  }
};
