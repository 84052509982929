import React, { Component } from 'react';
import { connect } from 'react-redux';
import Cell from '../../shared/Cell';
import dateUtility from '../../shared/dateUtility';
import paymentProcessor from '../../shared/credit-card/paymentProcessor';
import Loading from '../../shared/loading/Loading';
import * as actionTypes from '../../redux/actionTypes';

export class OneTimeDetailsPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  async submit() {
    this.setState({ loading: true });
    const newCard = !!this.props.payment.selectedCard.isNewCard;
    const payment = newCard
      ? await paymentProcessor.processNewCard(this.props.payment)
      : paymentProcessor.processExistingCard(this.props.payment);
    this.props.submitOneTimePayment(payment);
  }

  render() {
    return (
      <div className="details">
        <Loading isLoading={this.state.loading} />
        <div className="row">
          <Cell label="Type" value="One Time Payment" />
          <Cell label="Vanpool ID" value={this.props.vanpool} />
        </div>
        <div className="row">
          <Cell
            label="Payment Amount"
            value={'$' + this.props.payment.amount}
          />
          <Cell
            label="Payment Date"
            value={dateUtility.parseDateAsMonthDayYear(new Date())}
          />
        </div>
        <div className="row">
          <Cell
            size="full"
            label="Payment Method"
            value={`${this.props.method.brand} ${this.props.method.account} - EXP ${this.props.method.exp}`}
          />
        </div>
        <div className="tools">
          <button className="cancel button" onClick={this.props.cancel}>
            Cancel
          </button>
          <button
            className="submit button primary"
            onClick={() => this.submit()}
            desktopbefore="I Agree,"
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    submitOneTimePayment(payment) {
      dispatch({ type: actionTypes.ONE_TIME_PAYMENT_POSTING, data: payment });
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(OneTimeDetailsPresentation);
