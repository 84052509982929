import { useEffect } from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Collapsible from 'react-collapsible';
import * as actionTypes from '../../redux/actionTypes';
import ArrowLeft from '../../_styles/images/ArrowLeft.png';
import FuelCardIcon from '../../_styles/images/new-icons/Vector.png';
import Callable from '../../shared/Callable';
import dateUtility from '../../shared/dateUtility';
import currencyFormatter from '../../shared/currencyFormatter';
import { AngleDown } from '../../shared/icons';
import ResetFuelCardPin from './ResetFuelCardPin';
import ExportCsv from '../../shared/ExportCsv';
import PhoneGreen from '../../_styles/images/PhoneGreen.png'
import ActivateCard from './ActivateCard';
import analyticsService from '../../shared/analytics/analytics-service';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';

export function FuelCardChargesHeading({ month }) {
  function clickExpand(){
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "expand transaction history"
      }
    });
  }
    if (month) {
      return (
        <div className="etable-heading toggle monthly-charges">
          <div className="etable-row">
            <div className="etable-column" onClick={() => clickExpand()}>
              <div className="etable-head current-month">
                {`${dateUtility.monthNameFromIndex(month.activityMonth)} ${
                  month.activityYear
                }`}{' '}
              </div>
              <div className="etable-head total">
                {numberWithCommas(currencyFormatter.formatUSD(month.monthTotalAmount))}
                <AngleDown className="angle-down-button" aria-hidden="true" />{' '}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
export function FuelCardChargesTable({ transactions }) {
  if (!transactions) {
    return <div />;
  }
  
  return (
    <div>
      <div className="etable-body fuel-card-charges-table">
        {transactions.length === 0 ? (
          <p className="note no-recent-activity">
            No Fuel Card Data Available
          </p>
        ) : (
          transactions.map((transaction, index) => (
          <div
              className="etable-row"
              key={`${transaction.authorizationCode}_${index}`}
          >
          <div className="etable-column">
              <div className="etable-cell spacer"></div>
              <div className="etable-cell fc-activity-date">
                <span className='desktop-date'>{dateUtility.getNewFormat(transaction.activityDate)}</span>
                
              </div>
              <div className="mobile-container">
                  <div className="etable-cell merchant">
                    <span>{transaction.merchantName}</span>
                  </div>
                  <div className="etable-cell auth-num">
                    <div className="auth-mobile"><label>Auth: </label><span>{transaction.authorizationCode}</span></div>
                  </div>
              </div>
              <div className="etable-cell gallons">
                <span>{`${transaction.numberOfGallons ? numberWithCommas(transaction.numberOfGallons) + " " + "gal" : "-"}`}</span>
              </div>
              <div className="etable-cell amount right">
                <span>{numberWithCommas(currencyFormatter.formatUSD(transaction.amount))}</span>
              </div>
              <div className="etable-cell">
              <div className="mobile-only">
                  <div className="date-container">
                      <span className='date'>{dateUtility.getDateAndDay(transaction.activityDate).date}</span>
                      <span className='day'>{dateUtility.getDateAndDay(transaction.activityDate).day}</span>
                  </div>
                  <div className="other-info">
                      <div className="merchant-amount">
                          <span className="merchant">{transaction.merchantName}</span>
                          <span className="amount">{numberWithCommas(currencyFormatter.formatUSD(transaction.amount))}</span>
                      </div>
                      <div className="auth-gallons">
                          <span className="auth-num">Auth:{transaction.authorizationCode}</span>
                          <span className="gallons">{`${transaction.numberOfGallons ? numberWithCommas(transaction.numberOfGallons) + " " + "gal" : "N/A"}`}</span>
                      </div>
                  </div>
                </div>
              </div>
              </div>
          </div>
          ))
        )}
      </div>
    </div>
  );
}

function FuelCard(props) {
  useEffect(() => {
    props.init();
},[]);


      return (
        <div className='payment-fuel-card-page'>
            <div className='container'>
                <div className='header'>
                    <div className='backArrow'>
                        <Link className="overview-link" onClick={props.history.goBack}>
                            <img src={ArrowLeft} alt='go back'></img>
                        </Link>
                    </div>
                    <div className='heading'>Fuel Card</div>
                </div>
            </div>
            <ActivateCard coordinatorStatus={props.userAuth.coordinatorStatus}/>
            {props.userAuth.coordinatorStatus === 'Approved' && props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active' ? <ResetFuelCardPin/> : <></>}
            <div className='transaction'>
                <div className='transaction-heading'>
                    <span>Transaction History </span>
                    <ExportCsv subscriptionFuelCard={true} toastMessage={"File could not be downloaded. Try again later."}/>
                </div>
                {props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active' && <div className='mobile-only update'>Last updated {dateUtility.parseDateAsMonthDayYearFromDate(props.fuelCardPayment.summary.wexFilelastImportedDate)}</div>}
                {props.tableData && props.tableData[0].transactions.length !== 0 && props.fuelCardPayment.activeFuelCardDetails.cardStatus === 'Active'
                 ? props.tableData.map((data, index) => {
                  return (
                    <Collapsible
                      trigger={<FuelCardChargesHeading month={data} />}
                      tabIndex={index}
                      key={data.activityMonth}
                      open={index===0}
                      className='collapsible'
                    >
                        <div className="etable card-summary-table">
                            <div className="etable-body">
                                <div className="etable-row header-labels">
                                    <div className="etable-header fc-activity-date">
                                        Activity Date
                                    </div>
                                    <div className="etable-header merchant">Merchant</div>
                                    <div className="etable-header auth-num">Auth. Number</div>
                                    <div className="etable-header gallons">Gallons</div>
                                    <div className="etable-header right">Amount</div>
                                </div>
                            </div>
                        </div>
                      <FuelCardChargesTable transactions={data.transactions} />
                    </Collapsible>
                  );
                })
              : 
              <div className='no-transactions'>No transactions to display</div>}
            </div>

            <div className='fuel-card-assistance'>
                <div className='sub-heading'>Fuel Card Assistance</div>
                <div className='mobile-only icon'>
                  <img src={PhoneGreen} className='phone-green' alt='fuel card icon'></img>
                </div>
                <div className='text'>For help with your WEX fuel card, please call <Callable className="greentext" phone="800-VAN-4-WORK" analyticsEvent={true}/></div>
                {!props.profile.isCoordinator && <div className='sub-text'>If you need help with your PIN, please contact your vanpool’s coordinator</div>}
            </div>
        </div>
    );
}


export function mapStateToProps(state) {
    return {
        fuelCardPayment: state.fuelCardPayment,
        vanpoolInfo: state.vanpoolInfo,
        tableData: state.fuelCardPayment.summary.fuelActivities,
        profile: state.profile,
        userAuth: state.userAuth
    }
}

export function mapDispatchToProps(dispatch) {
    return {
        init() {
            dispatch({ type: actionTypes.GET_FUEL_CARD_SUMMARY });
            dispatch({ type: actionTypes.GET_FUEL_CARD_DETAILS });
        }
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(FuelCard);
