import { useEffect, useState } from 'react';
import { getExpiryFromObject, getFullPrice } from '../../helpers/vanHelpers';
import { makeDecimal2numbers } from '../../../payment/helper';
import { numberWithCommas } from '../../helpers/commonHelper';

export default function DisplayCard(props) {

  const [priceAmount, updatePriceAmount] = useState({});
  const [cardType, updateCardType] = useState();
  const [expiry, updateExpiry] = useState();

  useEffect(() => {
    if (props.card) {
      const priceObj = getFullPrice(props.card.chargeAmount);
      updatePriceAmount(priceObj);
    }
  }, [props.card.chargeAmount])

  useEffect(() => {
    if (props && props.card) {
      if (props.card.isFederal) {
        updateCardType('Federal Benefits Card');
      }
      else {
        if (props.card.isBackup) {
          updateCardType('Backup card');
        } else {
          updateCardType('Default card');
        }
      }

      if (props.card.expiry) {
        const exp = getExpiryFromObject(props.card.expiry, false);
        updateExpiry(exp);
      }
    }

  }, [props.card])

  return (
    props.card && <div className='display-card-container'>
      <div className='left-section'>
        <div className='card-image'>
          <img src={props.card && props.card.cardType && props.card.cardType.imgSrc} aria-hidden='true' />
        </div>
        <div className='content'>
          <div className='heading'>{props.card.cardType && props.card.cardType.type && props.card.cardType.type.toUpperCase()} ending in {props.card.cardNumber && props.card.cardNumber.substr(props.card.cardNumber.length - 4)}</div>
          <div className='type' aria-label={`${cardType} expiring on ${expiry}`}>{props.card.isFederal ? 'Federal Benefits Card ' : props.card.isBackup ? 'Backup' : 'Default'} | Exp: {expiry}</div>
          {props.card.isFederal && props.card.maxAmount && <div className='amount'>Authorized amount: ${numberWithCommas(makeDecimal2numbers(props.card.maxAmount))}</div>}
        </div>
      </div>
      {priceAmount && <div className='right-section' aria-label={`${priceAmount.price} dollars and ${priceAmount.decimal} cents`}>
        -
        <div className='amount' aria-hidden='true'>
          <div className='dollar'>$</div>
          <div className='price'>{numberWithCommas(priceAmount.price)}</div>
          <div className='price'>.{priceAmount.decimal}</div>
        </div>
      </div>}
    </div>
  );
}