import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import { Close } from '../icons';

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = { deactivating: false };
  }

  componentWillReceiveProps({ message }) {
    this.setState({ deactivating: false });

    if (message) {
      setTimeout(() => this.close(), 5500);
    }
  }

  close() {
    this.setState({ deactivating: true });
    setTimeout(this.props.close, 500);
  }

  render() {
    const { message, type, hideNavigation, root } = this.props;
    const { deactivating } = this.state;
    const login = window.location.hash.includes('login');

    if (!message) {
      return null;
    }

    return (
      <div
        className={`banner ${type} ${login || root ? 'login-page' : ''} ${
          hideNavigation ? 'short-nav' : ''
        } ${deactivating ? 'out' : ''}`}
        aria-live={type === 'error' ? 'assertive' : 'polite'} role='alert'
      >
        <div className="message" aria-busy='true'>{message}</div>
        <div
          onClick={() => this.close()}
          onKeyPress={e => e.key === 'Enter' && this.close()}
          className="close-button"
          tabIndex="0"
          ref={e => e && e.focus()}
          aria-label={message}
        >
          <Close />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    message: state.banner.message,
    type: state.banner.type
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    close() {
      dispatch({ type: actionTypes.CLOSE_BANNER });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Banner);