import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import Close from '../../_styles/images/close-icon-green.svg';
import CheckCircle from '../../_styles/images/new-icons/check_circle.png';
import analyticsService from '../../shared/analytics/analytics-service';

function RequestNewVehicle(props){
    const [showPopup, setShowPopup] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [textInput, setTextInput] = useState('');
    const [submitData, setSubmitData] = useState(false);
    const [done, setDone] = useState(false);

    const options = [
        'Lost riders - Need a smaller vehicle',
        'Gained riders - Need a bigger vehicle',
        'Want to upgrade my vehicle',
        'Other'
    ]

    const togglePopup = (str) => {
        setShowPopup(!showPopup);
        setSelectedOption(null);

        if(str === "submit"){
            analyticsService.analyticsProcessEvent({
                "event": "click_generic",
                "context": {
                    "event_action": "submit a request"
                }
            });

            analyticsService.analyticsProcessEvent({
                "event": "modal_view_generic",
                "context": {
                    "event_action": "request new vehicle entry modal"
                    }
            });
        }
    };

    const handleOptionChange = (option) => {
        setSelectedOption(option);
      };

    const handleInputChange = (event) => {
        setTextInput(event.target.value);
    };

    const handleSubmit = () => {
        if(selectedOption.includes("Need")){
            let selectedOptionForAPI = selectedOption.replace("Need", "need");;
            props.submit(selectedOptionForAPI,textInput);
        }
        else {
            props.submit(selectedOption, textInput);
        }
        setSubmitData(true);
        analyticsService.analyticsProcessEvent({
            "event": "modal_click_generic",
            "context": {
                "event_action": "submit",
                "event_detail": {
                    "modal": "request new vehicle submitted modal"
                    }
                }
        })
    }

    useEffect(() => {
        if(submitData){
            analyticsService.analyticsProcessEvent({
                "event": "modal_view_generic",
                "context": {
                    "event_action": "request new vehicle submitted modal"
                    }
            })
        }
    },[submitData]);

    return(
        <div className='request-new-vehicle'>
            {done ? 
            <div className='data-submitted'>
                <div className='heading'>
                    <img className='check' src={CheckCircle} />
                    <div className='request-sent'>REQUEST FOR NEW VEHICLE SENT</div>
                </div>
                <div className='sent-msg'>We expect to contact you within the next business day.</div>
            </div> :
            <>
                <div className='request-heading'>REQUEST A NEW VEHICLE</div>
                <div className='request-text'>Looking for an upgrade or recently change the number of riders?</div>
                <button className='submit-request' onClick={() => togglePopup("submit")}>Submit a request</button>
                {showPopup && (
                    <div className="request-modal-container">
                        <div className="popup">
                            <div className='popup-heading'>
                                Request New Vehicle
                                <img role='button' className="close" src={Close} onClick={() => togglePopup("close")} alt='close icon' tabIndex={0} />
                            </div>
                            {submitData ? 
                            <div className='request-sent'>
                                <img className='check-circle' src={CheckCircle} />
                                <div className='sub-heading'>Request sent</div>
                                <div className='text'>Your new vehicle request has been sent. We expect to contact you within the next business day.</div>
                            </div>
                            :<div className="popup-content">
                                <span className='content-heading'>Please provide a reason for changing vehicles</span>
                                <ul>
                                    {options.map((option, index) => (
                                        <li key={index} className={selectedOption === option? 'selected' : ''}>
                                        <label>
                                            <input
                                            type="radio"
                                            name="options"
                                            value={option}
                                            checked={selectedOption === option}
                                            onChange={() => handleOptionChange(option)}
                                            />
                                            <span>{option}</span>
                                        </label>
                                        </li>
                                    ))}
                                </ul>
                                {selectedOption === 'Other' && (
                                    <div className='text-input'>
                                        <label>
                                            Please provide a reason for changing vehicles*
                                        </label>
                                        <input
                                            type="text"
                                            value={textInput}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                )}

                            </div>}
                            <div className='bottom'>
                                { !submitData && <button className='cancel-button' onClick={() => {togglePopup("cancel");
                                        analyticsService.analyticsProcessEvent({
                                            "event": "modal_click_generic",
                                            "context": {
                                                "event_action": "cancel",
                                                "event_detail": {
                                                    "modal": "request new vehicle submitted modal"
                                                    }
                                                }
                                        })
                                    }}>CANCEL</button>}
                                    {submitData ?
                                    <button className='done' onClick={() => {
                                        setDone(true);
                                        analyticsService.analyticsProcessEvent({
                                            "event": "modal_click_generic",
                                            "context": {
                                                "event_action": "done",
                                                "event_detail": {
                                                    "modal": "request new vehicle submitted modal"
                                                    }
                                                }
                                        })
                                    }}>DONE</button>
                                    :<button 
                                        className='submit' 
                                        onClick={handleSubmit} 
                                        disabled={(selectedOption === 'Other' && textInput === '') || !selectedOption}>
                                            SUBMIT REQUEST
                                    </button>}
                            </div>
                        </div>
                    </div>
                )}
            </>}
        </div>
    )
}

function mapDispatchToProps(dispatch) {
    return {
      submit(reason, comment) {
        dispatch({
          type: actionTypes.VEHICLE_SWITCH_REQUEST,
          data: { reasonType: reason, reasonComment: comment }
        });
      }
    };
  }
  
  export default connect(
    null,
    mapDispatchToProps
  )(RequestNewVehicle);