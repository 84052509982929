import * as React from "react"

function Dashboard(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.13 13.602H6.225A1.275 1.275 0 015.05 12.21v-1.735h1.672v1.455h2.936v-1.455h1.66v1.736a1.277 1.277 0 01-1.188 1.39z"
        fill="#4A4A4A"
      />
      <path
        d="M18.107 22H5.893a3.892 3.892 0 01-3.83-3.829V8.943a1.595 1.595 0 011.532-1.595h16.746V5.893a2.234 2.234 0 00-2.234-2.221H5.893a2.22 2.22 0 00-2.221 2.22H2A3.906 3.906 0 015.893 2h12.214A3.906 3.906 0 0122 5.893v1.519a1.608 1.608 0 01-1.595 1.608H3.672v9.1a2.234 2.234 0 002.22 2.22h12.215a2.246 2.246 0 002.234-2.22v-7.658H22v7.658A3.893 3.893 0 0118.107 22z"
        fill="#4A4A4A"
      />
      <path
        d="M10.13 18.95H6.225a1.276 1.276 0 01-1.174-1.277v-1.787h1.672v1.43h2.935v-1.493h1.66v1.787a1.277 1.277 0 01-1.188 1.34zM17.775 13.602H13.87a1.275 1.275 0 01-1.174-1.392v-1.735h1.672v1.455h2.948v-1.455h1.672v1.736a1.276 1.276 0 01-1.213 1.39zM17.775 18.95H13.87a1.276 1.276 0 01-1.175-1.277v-1.787h1.672v1.43h2.949v-1.493h1.672v1.787a1.277 1.277 0 01-1.213 1.34z"
        fill="#4A4A4A"
      />
    </svg>
  )
}

export default Dashboard;
