import {useEffect,useRef,useState} from 'react';
import Badge from '../../../_styles/images/question-icon.svg';
import CloseIcon from '../../../_styles/images/close-icon.svg';

export default function TooltipLink(props) {

  const [open,updateOpen] = useState(false);

  const node = useRef();

  const clickOutside = (e) => {
    if(node.current.contains(e.target)) {
      return;
    }
    updateOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown',clickOutside);
    return () => {
      document.removeEventListener('mousedown',clickOutside);
    }
  },[open])

  function showInfo(event) {
    if(event.key === 'Enter') {
      updateOpen(!open);
    }
  }

  return (
    <div className='tooltip-link-container'  role='group'>
      <div
        className={`info ${open ? 'open' : ''}`}
        onClick={e => {
          if(
            e.target.className == 'closeIcon' ||
            e.currentTarget.className == 'info '
          ) {
            updateOpen(!open);
          }
        }}
        role='button'
        tabIndex={0}
        onKeyPress={event => showInfo(event)}
        ref={node}
        aria-label='tooltip icon'
      >
        <img src={Badge} alt="tooltip icon" aria-hidden='true'/>
        <div className="message" role='alert'>
          <img className='closeIcon' tabIndex={0} role='button' src={CloseIcon} alt="close icon"/>
          {props.message}
        </div>
      </div>
      <div className='link-text' onClick={e => {
          if(
            e.target.className == 'closeIcon' ||
            e.currentTarget.className == 'info ' ||
            e.target.className == 'link-text'
          ) {
            updateOpen(!open);
          }
        }}>
        {props.linkText}
      </div>
    </div>
  );
}