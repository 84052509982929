import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.883 5.883v12.334h20.234V5.883H1.883zM0 5.407C0 4.631.63 4 1.407 4h21.186C23.369 4 24 4.63 24 5.407v13.285c0 .778-.631 1.407-1.407 1.407H1.407C.629 20.1 0 19.47 0 18.692V5.407z"
        fill="#4A4A4A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.087 8.146a.941.941 0 011.05-.82c-.024-.002-.022-.002.03-.001l.177.003.727.006c.615.004 1.472.006 2.492.007 2.038.002 4.717 0 7.385-.003 2.667-.003 5.322-.007 7.31-.011l3.307-.007.003.941.002.942-3.309.007c-1.988.004-4.643.008-7.311.011-2.668.004-5.35.005-7.39.003a609.15 609.15 0 01-3.254-.013 4.818 4.818 0 01-.4-.016.941.941 0 01-.819-1.05zm23.423.113a.942.942 0 01-.94.944l-.002-.942-.003-.941c.52-.002.944.419.945.939zM3.118 13.589c0-.52.422-.941.942-.941H10a.941.941 0 010 1.882H4.06a.941.941 0 01-.942-.941zM3.118 16.217c0-.52.422-.942.942-.942h1.527a.942.942 0 010 1.883H4.06a.941.941 0 01-.942-.941zM14.947 15.212c0-.778.631-1.407 1.408-1.407h2.99c.776 0 1.407.629 1.407 1.407v.539c0 .778-.631 1.407-1.407 1.407h-2.99c-.777 0-1.408-.629-1.408-1.407v-.54z"
        fill="#4A4A4A"
      />
    </svg>
  )
}

export default SvgComponent
