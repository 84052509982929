import * as actionTypes from '../redux/actionTypes';
import config from '../appConfig';
import logger from '../shared/logger';
import Http from '../shared/http-service';
import { takeLatest, put, call, all } from 'redux-saga/effects';

const aggregateUrl = `${config.kongApiBaseUrl}/aggregates`;

export default function*() {
  yield takeLatest(actionTypes.VANPOOL_AGGREGATES_LOADING, loadAggregates);
}

function* loadAggregates() {
  try {
    const { aggregates = [] } = yield call(() => Http.get(aggregateUrl));

    const tree = yield all(
      aggregates.map(aggregate =>
        call(() => {
          if (aggregate.aggregateType === 'T') {
            return getTransitAgency(aggregate);
          }

          return getAggregateEmployerList(aggregate);
        })
      )
    );

    yield put({ type: actionTypes.VANPOOL_AGGREGATES_LOADED, data: { tree } });
    yield put({
      type: actionTypes.STATS_AGGREGATE_CHANGED,
      data: { ...tree[0] }
    });
  } catch (ex) {
    logger.log(ex);
  }
}

async function getTransitAgency(aggregate) {
  const { transitAgency } = await Http.get(
    `${aggregateUrl}/transitAgencyAggregate/${aggregate.aggregateId}`
  );

  const node = {
    name: aggregate.aggregateName,
    id: aggregate.aggregateId,
    isTransit: true,
    isAdmin: aggregate.isAdmin.toLowerCase() === 'true'
  };

  const vanpools = transitAgency.vanpools || [];
  node.children = vanpools.map(vanpool => ({
    parent: node,
    name: vanpool.vanpoolName || vanpool.vanpoolId,
    id: vanpool.vanpoolId,
    isVanpool: true,
    isAdministrable: node.isAdmin
  }));

  return node;
}

async function getAggregateEmployerList(aggregate) {
  const { employers = [] } = await Http.get(
    `${aggregateUrl}/employerAggregate/${aggregate.aggregateId}`
  );

  const node = {
    name: aggregate.aggregateName,
    id: aggregate.aggregateId,
    isTransit: false,
    isAdmin: aggregate.isAdmin.toLowerCase() === 'true'
  };

  node.children = employers.map(employer => getWorksiteList(employer));

  node.children.forEach(child => (child.parent = node));

  return node;
}

function getWorksiteList(employer) {
  const node = {
    name: employer.employerName,
    id: employer.employerId
  };

  node.children = employer.worksites.map(worksite => getVanpoolList(worksite));

  node.children.forEach(child => (child.parent = node));

  return node;
}

function getVanpoolList(worksite) {
  const node = {
    name: worksite.worksiteName,
    id: worksite.worksiteId
  };

  node.children = worksite.vanpools.map(vanpool => ({
    parent: node,
    name: vanpool.vanpoolName,
    id: vanpool.vanpoolId,
    isVanpool: true,
    isAdministrable: vanpool.isAdministrable === 'true'
  }));

  return node;
}
