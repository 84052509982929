import {useState,useEffect} from 'react';
import dateUtility from '../../shared/dateUtility';
import ArrowLeft from '../../_styles/images/ArrowLeft.png';
import {makeDecimal2numbers,splitNumber} from '../../payment/helper';
import {connect} from 'react-redux';
import Http from '../../shared/http-service';
import config from '../../appConfig';
import {isEqual} from 'lodash';
import { numberWithCommas } from '../../join-commute/helpers/commonHelper';
import analyticsService from '../../shared/analytics/analytics-service';

function InvoiceMobileView(props) {
    const [invoice,updateInvoice] = useState({});
    const [invoiceSummary,updateInvoiceSummary] = useState({});
    const [cost,updateCost] = useState({});

    useEffect(() => {
        if(props && props.chosenInvoice) {
            updateInvoice(props.chosenInvoice);
            const splitCost = props.chosenInvoice.invoiceAmount && splitNumber(props.chosenInvoice.invoiceAmount);
            updateCost(splitCost);
        }
        if(props && props.invoiceSummary) {
            updateInvoiceSummary(props.invoiceSummary)
        }
    },[props])

    function goBack() {
        props.history.goBack();
    }

    function viewPDF() {
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
              "event_action": "view invoice"
            }
          });
        props.history.push({pathname: '/payment/summary/invoices/view',state: {invoiceId: invoice.invoiceId,invoiceMonth: invoice.invoiceMonth,invoiceYear: invoice.invoiceYear}});
    }

    async function downloadAndSaveInvoice(invoiceId,download,invoice) {
        let response = await Http.getStatic(
            `${config.kongApiBaseUrl}/vanpools/vanpool/invoices/invoice/pdf/download/${invoiceId}`,{"Content-Type": "application/pdf"});
        if(response.ok) {
            const pdf = await response.blob();
            if(!download) {
                const file = window.URL.createObjectURL(pdf);
                updatePdfFile(file)
                updateLoading(false)
            } else {
                saveAs(pdf,`${invoiceId}.pdf`);
            }
            analyticsService.analyticsProcessEvent({
                "event": "download",
                "context": {
                  "event_action": "download",
                  "file_name": `${invoice.invoiceMonth}${invoice.invoiceYear}invoice.pdf`
                }
              });

        } else {
            props.showError();
        }

    }

    //handling refresh
    useEffect(() => {
        if(props && isEqual(props.chosenInvoice, {})) {
            props.history.replace('/payment/summary/invoices');
        }
    },[])

    return (
        <div className='invoice-details-mobileOnly'>
            <div className='top-header'>
                <div className='backArrow'>
                    <div onClick={goBack}>
                        <img src={ArrowLeft} alt='go back'></img>
                    </div>

                </div>
                <div className='heading'>{invoice.invoiceMonth} {invoice.invoiceYear}</div>
            </div>
            <div className='heading-container'>
                <div>
                    <div className='heading2'>{`${dateUtility.monthNameFromIndex(invoiceSummary.invoiceMonth)} Invoice balance due by ${dateUtility.parseDateAsMonthDayYear(invoiceSummary.invoiceDueDate)}`}</div>
                    <div className='heading1'>
                        <span className='month-heading'>{invoice.invoiceMonth + " " + "Total"}</span>
                    </div>
                    <div className='heading2'>{"Activity Date: " + dateUtility.parseDateAsMonthDayYear(invoice.invoiceDate)}</div>
                </div>
                <div className='details'>
                    {cost && <div className='price-group' aria-label={`${cost.price} dollars and ${cost.decimal} cents`}>
                        <span className='price'>-</span>
                        <span className='dollar'>$</span>
                        <span className='price'>{numberWithCommas(cost.price)}.</span>
                        <span className='decimal'>{cost.decimal}</span>
                    </div>}
                </div>
            </div>
            <div className='actions'>
                <button className='view-button' onClick={viewPDF} >View Invoice</button>
                <button className='download-button' onClick={() => downloadAndSaveInvoice(invoice.invoiceId,true,invoice)} >Download</button>
            </div>
            <div className='content' role='group'>
                <div className='content-heading'>
                    <div>Description</div>
                    <div className='amount'>Amount</div>
                </div>
                {invoice && invoice.invoiceActivities && invoice.invoiceActivities.map((activity,index) =>
                    <div className='row-container' key={index}>
                        <div className='row'>
                            <div className='card-info'>{activity.activityDescription}</div>
                            <div className='price-value' aria-label={`${makeDecimal2numbers(activity.amount)} dollars`}>${makeDecimal2numbers(activity.amount)}</div>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
}

function mapStateToProps(state) {
    return {
        invoiceSummary: state.PaymentOverview.subscriptionSummary.invoiceSummary,
        chosenInvoice: state.invoiceHistory.chosenInvoice
    }
}

export default connect(mapStateToProps,null)(InvoiceMobileView);