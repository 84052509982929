import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import InvoiceTable from './InvoiceTable'; 
import * as actionTypes from '../../redux/actionTypes';
import AccountSummaryHeader from '../../payment-account/AccountSummaryHeader';
import Loading from '../../shared/loading/Loading';

class InvoicesPagePresentation extends Component {

  constructor(props) {
    super(props);

    this.state = {
      invoiceDataLoading: true,
    };
  }

  componentDidMount() {
    this.props.init(this.props.accountSummaryLoaded);
    this.setState({ invoiceDataLoading: this.props.invoiceSummary });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({invoiceDataLoading: !nextProps.invoiceHistory.summaryLoaded})
  }

  render() {
    return (
      <div className="payment-invoices">
        <Loading isLoading={this.state.invoiceDataLoading} />

        <AccountSummaryHeader
          isPaperless={this.props.isPaperless}
          hasPaperless={this.props.hasPaperlessPreference}
        />

        <Route
          exact
          path={`${this.props.match.path}`}
          render={props => (
            <InvoiceTable
              url={this.props.match.url}
              invoiceSummary={this.props.invoiceSummary}
            />
          )}
        />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  const props = {
    invoiceHistory: state.invoiceHistory,
    summary: state.accountSummary.summary,
    accountSummaryLoaded: state.accountSummary.accountSummaryLoaded,
    invoiceSummary: state.invoiceHistory.invoiceSummary
  };

  if (state.profile.preferences && state.profile.preferences.length > 0) {
    const EPI = state.profile.preferences.find(
      preference => preference.preferenceCode === 'EPI'
    );
    props.isPaperless = EPI ? EPI.isSelected : false;
    props.hasPaperlessPreference = !!EPI;
  }

  return props;
}

export function mapDispatchToProps(dispatch, ownProps) {
  return {
    init(accountSummaryLoaded) {
      dispatch({ type: actionTypes.INVOICE_HISTORY_DETAILS_LOADING });

      dispatch({ type: actionTypes.USER_PERSONAL_INFO_LOADING });
      if (!accountSummaryLoaded) {
        dispatch({ type: actionTypes.ACCOUNT_SUMMARY_LOADING });
      }
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesPagePresentation);
