import * as actionTypes from '../redux/actionTypes';

const initialState = {
  maxTripRecordersAllowed: 0,
  participants: [],
  loaded: false,
  rosterParticipants: [],
  todaysParticipants: [],
  inviteRiderSuccess: null,
  loadError: false,
  loadErrorString: '',
  closeParticipantPopup: false,
  participantUpdateToast: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROSTER_LOADING:
      return { ...state, ...initialState, loaded: false };
    case actionTypes.ROSTER_LOADED:
      return { ...state, ...initialState, loaded: true, ...action.data, loadError: false };
    case actionTypes.ROSTER_LOADED_FAILURE:
      return {
        ...state,
        ...initialState,
        ...action.data,
        loadError: true,
        loadErrorString: action.errorString,
        loaded: true,
      }
    case actionTypes.ROSTER_INVITE_USERS_LOADED:
      return { ...state, ...action.data };
    case actionTypes.PARTICIPANT_PROFILE_LOADED:
      const participants = state.participants.map(p =>
        p.id === action.data.id ? action.data : p
      );
      const atMaxTripRecorders =
        participants.filter(
          participant => participant.roles && participant.roles.tripRecorder
        ).length >= state.maxTripRecordersAllowed;

      return { ...state, participants, atMaxTripRecorders};
    case actionTypes.PARTICIPANT_PROFILE_DELETED:
      return {
        ...state,
        participants: state.participants.filter(p => p.id !== action.data)
      };
    case actionTypes.INVITE_RIDER_SUCCESS:
      return { ...state, inviteRiderSuccess: action.data };
    case actionTypes.PARTICIPANT_UPDATE_SUCCESS:
      return {...state, participantUpdateToast:action.data}
    default:
      return state;
  }
};
