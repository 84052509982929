import React, { Component } from 'react';
import timePeriodService from './time-period-service';

export default class DateSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quarters: timePeriodService.generateQuartersList(),
      months: timePeriodService.generateMonthsList(),
      years: timePeriodService.generateYearsList(),
      selectedQuarter: timePeriodService.currentQuarter(),
      selectedMonth: timePeriodService.currentMonth(),
      selectedYear: timePeriodService.currentYear(),
      open: false
    };
  }

  componentDidMount() {
    this.unsubscribe = () => this.setState({ open: false });
    window.addEventListener('click', this.unsubscribe);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.unsubscribe);
  }

  toggleOpen() {
    this.setState({ open: !this.state.open });
  }

  toggleSelector(isQuarter) {
    isQuarter
      ? this.props.selectTimePeriod(this.state.selectedQuarter, isQuarter)
      : this.selectYear(this.state.selectedYear);
  }

  selectMonth(month) {
    this.setState({ selectedMonth: month }, () => {
      const timePeriod = timePeriodService.mapMonthYearToTimePeriod(
        month,
        this.state.selectedYear
      );
      this.props.selectTimePeriod(timePeriod, false);
    });
  }

  selectQuarter(quarter) {
    this.setState({ selectedQuarter: quarter }, () => {
      this.props.selectTimePeriod(quarter, true);
    });
  }

  selectYear(year) {
    this.setState({ selectedYear: year }, () => {
      const timePeriod = timePeriodService.mapMonthYearToTimePeriod(
        this.state.selectedMonth,
        year
      );
      this.props.selectTimePeriod(timePeriod, false);
    });
  }

  render() {
    const { isQuarter } = this.props;
    const {
      quarters,
      selectedQuarter,
      months,
      selectedMonth,
      selectedYear,
      years,
      open
    } = this.state;

    return (
      <div
        className="stats-period-selector-container"
        onClick={e => e.stopPropagation() && e.preventDefault()}
      >
        <div className="display" onClick={() => this.toggleOpen()}>
          {isQuarter
            ? shorten(selectedQuarter.displayValue)
            : `${selectedMonth.displayValue} ${selectedYear}`}
        </div>
        {open ? (
          <div className="selector toggle-tip">
            <div className="toggle">
              <button
                className={isQuarter ? 'quarter active' : 'quarter'}
                onClick={e => this.toggleSelector(true)}
              >
                Quarter
              </button>
              <button
                className={!isQuarter ? 'month active' : 'month'}
                onClick={e => this.toggleSelector(false)}
              >
                Month
              </button>
            </div>
            {isQuarter ? (
              <QuarterSelect
                {...this.props}
                quarters={quarters}
                selectedQuarter={selectedQuarter}
                selectQuarter={value => this.selectQuarter(value)}
              />
            ) : (
              <MonthSelect
                {...this.props}
                months={months}
                selectedMonth={selectedMonth}
                years={years}
                selectedYear={selectedYear}
                selectMonth={value => this.selectMonth(value)}
                selectYear={value => this.selectYear(value)}
              />
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

const shorten = str =>
  str.replace(/([a-z]{3})[a-z]+/gi, '$1').replace(/Sep/g, 'Sept');

function QuarterSelect({ quarters, selectedQuarter, selectQuarter }) {
  return (
    <div className="select">
      <select
        defaultValue={JSON.stringify(selectedQuarter)}
        onChange={e => selectQuarter(JSON.parse(e.target.value))}
      >
        {quarters.map(quarter => (
          <option key={quarter.displayValue} value={JSON.stringify(quarter)}>
            {shorten(quarter.displayValue)}
          </option>
        ))}
      </select>
    </div>
  );
}

function MonthSelect({
  months,
  selectedMonth,
  years,
  selectedYear,
  selectMonth,
  selectYear
}) {
  return (
    <div className="month-year-select">
      <div className="select month">
        <select
          defaultValue={JSON.stringify(selectedMonth)}
          onChange={e => selectMonth(JSON.parse(e.target.value))}
        >
          {months.map(month => (
            <option key={month.displayValue} value={JSON.stringify(month)}>
              {month.displayValue}
            </option>
          ))}
        </select>
      </div>
      <div className="select year">
        <select
          defaultValue={selectedYear}
          onChange={e => selectYear(e.target.value)}
        >
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}
