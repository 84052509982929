import { useCallback, useEffect, useState } from 'react';

import CancelRide from '../../_styles/images/CancelRide.png';
import dateUtility from '../../shared/dateUtility';
import { getVanPrice, getTraditionalVanPrice } from '../helpers/vanHelpers';
import { numberWithCommas } from '../helpers/commonHelper';
import suitcase from '../../_styles/images/suitcase.png';
import useWindowWidth from '../helpers/useWindowWidth';
import LetUsHelpYouSection from '../../join-commute/vanpool-details/let-us-help-you/LetUsHelpYouSection';
import ImageNotAvailable from '../../_styles/images/ImageNotAvailable.svg';
import analyticsService from '../../shared/analytics/analytics-service';
import PreferredVanpoolPopup from './PreferredVanpoolPopup';
import NoPreferredVanPopup from './NoPreferredVanPopup';
import Callable from '../../shared/Callable';
import SponsoredVanpool from '../helpers/sponsoredVanpool';

export default function MatchedVanpools(props) {
  const [showmore, updateShowMore] = useState(10);
  const [exactMatch, updateExactMatch] = useState(0);
  const [preferredVanpool, updatePreferredVanpool] = useState(null);
  const [ShowPreferredPopup, updateShowPreferredPopup] = useState(false);
  const [showNoPreferredVanPopup, updateShowNoPreferredVanPopup] = useState(false);
  const [showNoVansSection, updateShowNoVansSection] = useState(false);
  const [isCloseMatches, updateIsCloseMatches] = useState(false);
  const [invitedUserDetails, updateInvitedUserDetails] = useState(null)
  const [allMatchingVans, updateAllMatchingVans] = useState([])
  const [showVanList, updateShowVanList] = useState(true);
  const windowSize = useWindowWidth();

  function setMatchingVans(matchedVans) {
    const matchingVans = matchedVans.sort((a, b) => Number(b.isOptimalMatch) - Number(a.isOptimalMatch));
    const exactMatches = matchingVans.filter(van => van.isOptimalMatch);
    updateExactMatch(exactMatches);
    updateIsCloseMatches(exactMatches.length < matchingVans.length);
    updateAllMatchingVans(matchedVans);
  }

  useEffect(() => {
    if (props.matchingVans.length) {
      const preferredVanpools = props.matchingVans.filter(van => van.isPreferredVanpool);
      preferredVanpools.length && updateShowPreferredPopup(true);
      updateInvitedUserDetails(props.userInfo.userCommuteData);
      if (preferredVanpools.length) {
        updatePreferredVanpool(true);
        const exactMatches = preferredVanpools;
        if (preferredVanpools[0].isOptimalMatch) {
          updateExactMatch(exactMatches);
          updateShowNoVansSection(false);
          updateShowVanList(true);
          updateIsCloseMatches(false);
        } else {
          updateExactMatch([]);
          updateIsCloseMatches(true);
        }
        setMatchingVans(exactMatches);
      } else if (preferredVanpools.length === 0 && props.userInfo.inviteId) {
        updateShowNoPreferredVanPopup(true);
        setMatchingVans(props.matchingVans);
      }
      else {
        updateShowNoPreferredVanPopup(false);
        updateShowNoVansSection(false);
        updateShowVanList(true);
        setMatchingVans(props.matchingVans);
      }
      analyticsService.pageInfo("matching vans");
    } else if (props.userInfo.inviteId) {
      updateShowNoVansSection(true);
      updateShowVanList(false);
    }
  }, [props.matchingVans, props.userInfo.inviteId]);



  function showMore() {
    updateShowMore(showmore + 10);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "show additional vans"
      }
    });

  }

  const viewDetals = (van) => {
    props.props.history.push('/JoinCommute/vanpool-details');
    props.setSelectedVan(van);
    analyticsService.analyticsProcessEvent({
      "event": "click_view_van_details",
      "context": {
        "event_action": "View details"
      }
    });
  }

  const navigateToVanpoolDetails = (van) => {
    props.props.history.push('/JoinCommute/vanpool-details');
    props.setSelectedVan(van);
  }

  function onVanpoolKeydown(e, van) {
    if (e.which == 13) {
      navigateToVanpoolDetails(van);
    }
  }

  function goToStayInTouch() {
    analyticsService.pageInfo("stay in touch");
    return props.props.history.push("/JoinCommute/stay-in-touch");

  }

  const getChargeComponent = useCallback((van) => {
    const charge = getVanPrice(van, props.userInfo.drivingScheduleData.isDesiredDriverRole, props.userInfo.userCommuteData.package);
    van.charge = charge;
    return (
      <div className='amount' role='text' aria-label={`${charge.price} dollars and ${charge.decimal} cents`}>
        <div className="dollar">$</div>
        <div className="price">{numberWithCommas(charge.price)}</div>
        <div className="cents">.{charge.decimal}</div>
      </div>
    );
  }, []);

  const getTraditionalCharge = useCallback((van) => {
    const tCharge = getTraditionalVanPrice(van);
    van.charge = tCharge;
    return (
      <div className='amount' role='text' aria-label={`${tCharge.price} dollars and ${tCharge.decimal} cents`}>
        <div className="dollar">$</div>
        <div className="price">{numberWithCommas(tCharge.price)}</div>
        <div className="cents">.{tCharge.decimal}</div>
      </div>
    );
  }, []);

  useEffect(() => {
    props.resetEditCommuteSettings();
  }, [])

  return (
    <div className="matching-vans-container" role='group'>
      {
        showVanList && (exactMatch.length ?
          <div className="exactMatch">{"Exact Matches"}</div>
          : !preferredVanpool ?
            <div>
              <div className="exactMatch">{"Exact Matches"}</div>
              <div>
                <div className="noMatches">
                  <div className="rideIcon"><img alt='canel ride' src={CancelRide} /></div>
                  <div className="no-match-heading">We're unable to find an exact match</div>
                  <div className="no-match-content">Please check out the <span className="inner-content">close matches</span> below or you can try editing your commute information
                    to see if different commutes are available</div>
                </div>
                <div className="divider"></div>
                <div className="closeMatch">{"Close Matches"} <span>{"1 - 2 days off from your selection"}</span></div>
              </div>
            </div>
            :
            preferredVanpool && isCloseMatches &&
            <div className="closeMatch">{"Close Matches"} <span>{"1 - 2 days off from your selection"}</span></div>)

      }
      {showVanList && <div className={`${exactMatch.length ? "matched-van-container exactm" : "matched-van-container"}`}>
        {
          allMatchingVans && allMatchingVans.length && allMatchingVans.slice(0, showmore).map((van, i) => {
            return (
              <div className="matchedMain" key={i} role='group'>
                {windowSize > 1024 ? <div className="matched-van">
                  <div
                    className={`${van.isPredelivery ? 'car-image-opacity' : 'car-image'
                      }`}
                  >
                    <img src={van.carClassImage} onError={e => {
                      e.currentTarget.src = ImageNotAvailable;
                      e.currentTarget.className = "broken-image";
                    }} alt='van image' />
                  </div>
                  <div className="van-details">
                    {van.isSubsidized && <SponsoredVanpool/>}
                    <div className="vehicle-class">{van.vehicleClass}
                      {exactMatch[i] && (exactMatch[i].isOptimalMatch) == true ? null : <div className="close-match-label">
                        Close Match
                      </div>}
                    </div>
                    {van.isPredelivery && <div className='forming mobile'>
                      Forming Now!
                    </div>}
                    <div className="commute-details">
                      <div className="oprational-days">
                        {Object.keys(van.operationalDays).filter(d => van.operationalDays[d] && van.operationalDays[d].operational).map(day => ({
                          day,
                          active: van.operationalDays[day].available
                        })).map(({ day, active, disabled }) =>
                        (
                          <button
                            day={day}
                            key={day}
                            type="button"
                            disabled={true}
                            aria-label={active ? `${day} available` : `${day} unavailable`}
                            className={`day-btn ${active ? 'active' : 'not-active'}`}
                          >
                            {day.substr(0, 3)}
                          </button>

                        ))}
                      </div>
                      <div className="schedule-details">

                        <div className="arrival">
                          <div className="first">Arrive: {dateUtility.parseTime(van.matchedDestinationArrivalTime)}</div>
                          <div className="first">Depart: {dateUtility.parseTime(van.matchedDestinationDepartureTime)}</div>
                        </div>
                        <div className="depart">
                          <div className="second">{van.matchedOriginWalkingDistanceInMiles} <span role='text' aria-label='miles'>MI</span> to pickup</div>
                          <div className="second">{van.matchedDestinationWalkingDistanceInMiles} <span role='text' aria-label='miles'>MI</span> to worksite</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="van-price-details">
                    <div className="privateVan">
                      {van.isPrivate &&
                        <div className='employer'>
                          <img src={suitcase} alt="" />
                          EMPLOYER
                        </div>
                      }
                      {van.isPredelivery && <div className='forming web'>
                        Forming Now!
                      </div>
                      }
                    </div>
                    {van.isSubscriptionVanpool ? (
                      <div className="van-price">
                        {getChargeComponent(van)}
                        <div className="month">{'total per month'}</div>
                      </div>
                    ) : (
                      <div className="van-price-traditional">
                        {getTraditionalCharge(van)}
                        <div className="month">{'total per month'}</div>
                        <div className='estimate'>{'estimate*'}</div>
                      </div>
                    )}
                    <button className="view-details-button" onClick={() => viewDetals(van)}>VIEW DETAILS</button>
                  </div>
                </div>
                  :
                  <div className="matched-van" role='button' tabIndex={0} onClick={() => navigateToVanpoolDetails(van)} onKeyDown={(e) => onVanpoolKeydown(e, van)}>
                    <div
                      className={`${van.isPredelivery ? 'car-image-opacity' : 'car-image'
                        }`}
                    >
                      <img src={van.carClassImage} onError={e => {
                        e.currentTarget.src = ImageNotAvailable;
                        e.currentTarget.className = "image-not-found";
                      }} alt='van image' />
                    </div>
                    <div className="van-details">
                    {van.isSubsidized && <SponsoredVanpool/>}
                      <div className="vehicle-class">{van.vehicleClass}
                        {exactMatch && (exactMatch.length - 1) == i ? null : <div className="close-match-label">
                          Close Match
                        </div>}
                      </div>
                      {van.isPredelivery && <div className='forming mobile'>
                        Forming Now!
                      </div>}
                      <div className="commute-details">
                        <div className="oprational-days">
                          {Object.keys(van.operationalDays).filter(d => van.operationalDays[d] && van.operationalDays[d].operational).map(day => ({
                            day,
                            active: van.operationalDays[day].available
                          })).map(({ day, active, disabled }) =>
                          (
                            <button
                              day={day}
                              key={day}
                              type="button"
                              disabled={true}
                              aria-label={active ? `${day} available` : `${day} unavailable`}
                              className={`day-btn ${active ? 'active' : 'not-active'}`}
                            >
                              {day.substr(0, 3)}
                            </button>

                          ))}
                        </div>
                        <div className="schedule-details">

                          <div className="arrival">
                            <div className="first">Arrive: {dateUtility.parseTime(van.matchedDestinationArrivalTime)}</div>
                            <div className="first">Depart: {dateUtility.parseTime(van.matchedDestinationDepartureTime)}</div>
                          </div>
                          <div className="depart">
                            <div className="second">{van.matchedOriginWalkingDistanceInMiles} <span role='text' aria-label='miles'>MI</span> to pickup</div>
                            <div className="second">{van.matchedDestinationWalkingDistanceInMiles} <span role='text' aria-label='miles'>MI</span> to worksite</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="van-price-details">
                      <div className="privateVan">
                        {van.isPrivate &&
                          <div className='employer'>
                            <img src={suitcase} alt="" />
                            EMPLOYER
                          </div>
                        }
                        {van.isPredelivery && <div className='forming web'>
                          Forming Now!
                        </div>
                        }
                      </div>
                      {van.isSubscriptionVanpool ? (
                        <div className="van-price">
                          {getChargeComponent(van)}
                          <div className="month">{'total per month'}</div>
                        </div>
                      ) : (
                        <div className="van-price-traditional">
                          {getTraditionalCharge(van)}
                          <div className="month">{'total per month'}</div>
                          <div className='estimate'>{'estimate*'}</div>
                        </div>
                      )}
                      <button className="view-details-button" onClick={() => viewDetals(van)}>VIEW DETAILS</button>
                    </div>
                  </div>
                }
                {
                  isCloseMatches && exactMatch && (exactMatch.length - 1) == i ?
                    <div>
                      <div className="divider"></div>
                      <div className="closeMatch">{"Close Matches"} <span>{"1 - 2 days off from your selection"}</span></div>
                    </div> : null
                }
              </div>
            );
          })
        }

        {showmore <= allMatchingVans.length ? (
          <div className="showMore">
            <button className="show-more-button" onClick={showMore}>
              SHOW ADDITIONAL VANS
            </button>
          </div>
        ) : !preferredVanpool ? <LetUsHelpYouSection isSelected={false} goToStayInTouch={goToStayInTouch} isMobile={false} /> : ""}
      </div>}
      {
        ShowPreferredPopup && <PreferredVanpoolPopup inviteDetails={invitedUserDetails} updatePDPopup={() => updateShowPreferredPopup(false)} />
      }
      {
        showNoPreferredVanPopup && <NoPreferredVanPopup updatePDPopup={() => updateShowNoPreferredVanPopup(false)} />
      }
      {
        showNoVansSection &&
        <div className='no-vans-container'>
          <h2 className='heading'>Sorry, this vanpool is no longer available</h2>
          <div className='comment'>With new routes being added daily, we can help you find a suitable commute for your needs.</div>
          {props.userInfo.isInviteError ? 
          <div className='comment'>For assistance, please contact <Callable phone="800 VAN 4 WORK" /></div>
          :
          <div className='stay-in-touch'>
            <button onClick={props.goToStayInTouch}>STAY IN TOUCH</button>
          </div>}
          <div className='comment-two unspaced'>You can try changing your route information to see if different commutes are available</div>
        </div>
      }
    </div>
  );
}
