import React from 'react';

export default function Loading({ isLoading, absolute }) {
  return isLoading ? (
    <div aria-busy='true' className={`overlay ${absolute ? 'absolute' : ''}`}>
      <div className="loader">
        <span className='hide' aria-hidden='false'>Loading page</span>
      </div>
    </div>
  ) : null;
}
