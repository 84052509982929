import React from 'react';
import { Van, Person, Money } from '../shared/icons';
import currencyFormatter from '../shared/currencyFormatter';

export default function ImpactData({ data }) {
  const { employees, vanpoolers, privateSubsidies, transitSubsidies } = data;
  const employeesBadge =
    employees && (employees.count || employees.count === 0) ? (
      <div className="badge employees">
        <img src={Person} alt="person" />
        <div className="column">
          <div className="label">Employees</div>
          <div className="data">{employees.count.toLocaleString('en-us')}</div>
          <div className="context">{employees.description}</div>
        </div>
      </div>
    ) : null;

  const vanpoolersBadge =
    vanpoolers && (vanpoolers.count || vanpoolers.count === 0) ? (
      <div className="badge potential-vanpoolers">
        <img src={Van} alt="van" />
        <div className="column">
          <div className="label">Potential Vanpoolers</div>
          <div className="data">{vanpoolers.count.toLocaleString('en-us')}</div>
          <div className="context">{vanpoolers.description}</div>
        </div>
      </div>
    ) : null;

  const privateSubsidiesBadge =
    privateSubsidies &&
    (privateSubsidies.count || privateSubsidies.count === 0) ? (
      <div className="badge private-subsidies">
        <img src={Money} alt="Money" />
        <div className="column">
          <div className="label">Private Subsidies</div>
          <div className="data">
            {currencyFormatter.formatUSD(privateSubsidies.count)}
          </div>
          <div className="context">{privateSubsidies.description}</div>
        </div>
      </div>
    ) : null;

  const transitSubsitidesBadge =
    transitSubsidies &&
    (transitSubsidies.count || transitSubsidies.count === 0) ? (
      <div className="badge transit-subsidies">
        <img src={Money} alt="Money" />
        <div className="column">
          <div className="label">Transit Subsidies</div>
          <div className="data">
            {currencyFormatter.formatUSD(transitSubsidies.count)}
          </div>
          <div className="context">{transitSubsidies.description}</div>
        </div>
      </div>
    ) : null;

  return (
    <div className="impact-data">
      {employeesBadge}
      {vanpoolersBadge}
      {privateSubsidiesBadge}
      {transitSubsitidesBadge}
    </div>
  );
}
