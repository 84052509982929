import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as actionTypes from '../redux/actionTypes';
import logo from '../commute_logo_full.svg';
import config from '../appConfig';
import RedirectAzure from './RedirectAzure';
import logger from '../shared/logger';
import Error from './ErrorPage';
import AlreadyRegistered from './AlreadyRegistered';
import UserService from '../shared/user-service';
import analyticsService from '../shared/analytics/analytics-service';
import azureService  from './azureService';

class LoginPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: false };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateUserName = this.updateUserName.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
  }

  componentDidMount() {
    this.setState({ authenticated: false });
  }

  componentWillReceiveProps(props) {
    this.setState({
      authenticated:
        props.loginState.hasToken && props.loginState.redirectToReferrer && props.loginState.hasAccount
    });
  }

  updateUserName(event) {
    this.props.loginState.username = event.target.value;
  }

  updatePassword(event) {
    this.props.loginState.password = event.target.value;
  }

  handleSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    let username = this.props.loginState.username;
    let password = this.props.loginState.password;
    this.props.authenticate(username, password);
  }

  render() {
    const { authenticated } = this.state;
    const { loginState, location } = this.props;
    if (authenticated) {
      const { redirectUrl } = loginState;
      const { from } = redirectUrl
        ? { from: redirectUrl }
        : location.state || { from: { pathname: '/' } };
      return <Redirect to={from} />;
    }

    if (location.search) {
      const queryParts = parseQueryParams(location.search);
      logger.log(location.search);
      logger.log(
        'Displaying error message, clearing Auth, and silently logging out'
      );
      UserService.clearAuthentication();
      if (queryParts.error) {
        if(queryParts.error == "USER_ALREADY_EXITS") {
          return <AlreadyRegistered />;
        }
        analyticsService.analyticsEvent({
          type: 'Login Error',
          error: queryParts.error_description
            ? queryParts.error + ':' + queryParts.error_description
            : queryParts.error
        });
        return <Error />;
      }
    }

    return config.localLogin ? (
      <div className="login">
        <form className="card" onSubmit={this.handleSubmit}>
          <img src={logo} alt="Logo" className="branding" />
          <input
            className="long"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            name="username"
            placeholder="Username"
            type="text"
            onInput={event => this.updateUserName(event)}
          />
          <input
            className="long"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            name="password"
            placeholder="Password"
            type="password"
            onInput={event => this.updatePassword(event)}
          />
          <input
            className="submit"
            name="submit"
            type="submit"
            value="Sign In"
          />
        </form>
      </div>
    ) : (
     null
    );
  }
}

function parseQueryParams(params) {
  const queryParts = params.substr(1).split('&');

  let query = {};

  for (let i = 0; i < queryParts.length; i++) {
    let key = decodeURIComponent(queryParts[i].split('=')[0]);
    let value = decodeURIComponent(queryParts[i].split('=')[1]);

    query[key] = value;
  }

  return query;
}

function mapStateToProps(state) {
  return {
    loginState: state.loginState
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({
        type: actionTypes.CLEAR_AUTHENTICATION
      });
    },
    authenticate(username, password) {
      dispatch({
        type: actionTypes.AUTHENTICATION_REQUEST,
        data: { username, password }
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPresentation);
