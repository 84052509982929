import AppStore from '../../_styles/images/new-icons/download-app/app-store.svg';
import PlayStore from '../../_styles/images/new-icons/download-app/play-store.svg';
import QRCode from '../../_styles/images/new-icons/download-app/list-icons/qrcode.svg';
import Connection from '../../_styles/images/new-icons/download-app/list-icons/connection.svg';
import Payments from '../../_styles/images/new-icons/download-app/list-icons/payment.svg';
import Map from '../../_styles/images/new-icons/download-app/list-icons/map.svg';
import Insurance from '../../_styles/images/new-icons/download-app/list-icons/insurance.svg';
import AppStoreQR from '../../_styles/images/new-icons/download-app/appStoreQR.svg';
import PlayStoreQR from '../../_styles/images/new-icons/download-app/playStoreQR.svg';
import MobileImage from '../../_styles/images/new-icons/download-app/mobile-image.png';
import { Fragment, useState } from 'react';
import { QRModal } from './QRModal/QRModal';
import analyticsService from '../../shared/analytics/analytics-service';

export default function DownloadApp(props) {

    const [isOpen, updateIsOpen] = useState(false);
    const [content, updateContent] = useState({});

    const openModal = (type) => {
        updateIsOpen(true);
        if (type === 'android') {
            updateContent({
                qr: PlayStoreQR,
                icon: PlayStore,
                type
            })
            analyticsService.analyticsProcessEvent({
                "event": "click_generic",
                "context": {
                    "event_action": "get android app"
                }
            });
        } else if (type === 'apple') {
            updateContent({
                qr: AppStoreQR,
                icon: AppStore,
                type
            })
            analyticsService.analyticsProcessEvent({
                "event": "click_generic",
                "context": {
                    "event_action": "get ios app"
                }
            });
        }
    }

    return (
        <Fragment>
            <div className="download-app-container" role='group'>
                <div className="mobile-image">
                    <img aria-hidden='true' src={MobileImage}></img>
                </div>
                <div className="mid-section" role='group'>
                    <div className='green-section'>
                        <div className="heading">Manage Your Commute with Ease</div>
                    </div>
                    <div className="app-container">
                        <div className="heading">Get Our App</div>
                        <div className="apps">
                            <img src={AppStore} alt='Download on the Appstore' onClick={() => openModal('apple')} role='button' />
                            <img src={PlayStore} alt='Get it on Google Play' onClick={() => openModal('android')} role='button' />
                        </div>
                    </div>
                </div>
                <div className="bottom-section" role='group'>
                    <ul>
                        {
                           !props.isRecentLeadSubmitted ?
                           <div className='list-item'>
                            <img src={QRCode} aria-hidden='true' />
                            <span className='text'>Quick Commute Check-in</span>
                            </div>
                            :
                            null
                        }

                        <div className='list-item'>
                            <img src={Connection} aria-hidden='true' />
                            <span className='text'>Vanpool Roster</span>
                        </div>
                        <div className='list-item'>
                            <img src={Payments} aria-hidden='true' />
                            <span className='text'>Manage Payments</span>
                        </div>
                        <div className='list-item'>
                            <img src={Map} aria-hidden='true' />
                            <span className='text'>Driver tools with rider count and status updates. </span>
                        </div>
                        <div className='list-item'>
                            <img src={Insurance} aria-hidden='true' />
                            <span className='text'>Roadside Support</span>
                        </div>
                    </ul>
                </div>
            </div>
            {
                isOpen && <QRModal updateIsOpen={updateIsOpen} content={content} />
            }
        </Fragment>
    );
}