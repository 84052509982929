export const phoneTypeList = ['Mobile', 'Work', 'Home'];
export default function FancySelect({
    placeholder = 'Mobile',
    options = phoneTypeList,
    value: phoneType,
    update
  }) {  
    return (
      <div className={'work'}>
        <div className="select-switch pad-desk">
          <div className="select">
            <select
              value={phoneType || options[0]}
              onChange={e => update(e.target.value)}
            >
              {options.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

export function getNextPhoneType(value) {
  const index = phoneTypeList.indexOf(value);
  if(index === phoneTypeList.length-1){
    return phoneTypeList[0];
  } else {
    return phoneTypeList[index+1];
  }
}