import config from '../../appConfig';

export default [
  {
    type: 'mastercard',
    pattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    length: [16],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/mastercard.svg`
  },
  {
    type: 'visa',
    pattern: /^4/,
    length: [13, 16, 19],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/visa.svg`
  },
  {
    type: 'discover',
    pattern: /^(6011|65|64[4-9]|622)/,
    length: [16],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/discover.png`
  },
  {
    type: 'amex',
    pattern: /^(34|37)/,
    length: [15],
    luhn: true,
    cvcLength: 4,
    imgSrc: `${config.imagePath}/amex.png`
  },
  {
    type: '',
    pattern: /.*/,
    length: [16],
    luhn: true,
    cvcLength: 3,
    imgSrc: `${config.imagePath}/DefaultCard.png`
  }
];
