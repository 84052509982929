import React from 'react';
import { connect } from 'react-redux';

function StateDropDown({ states, value, onChange, disabled }) {
  return (
    <div className="select transportation">
      <select value={value} disabled={disabled} onChange={onChange}>
        <option disabled value="">
          State
        </option>
        {states.map(state => (
          <option key={state} value={state}>
            {state}
          </option>
        ))}
      </select>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    states: state.profile.states
  };
}

export default connect(mapStateToProps)(StateDropDown);
