import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import RosterView from './RosterView';
import InviteToVanpool from './InviteToVanpool';

const prefix = '/vanpool';
export default function RosterRoute() {
  return (
    <div className="rosterRouteRoute">
      <Switch>
        <Route path={ prefix + '/roster/invite-to-vanpool'} exact component={InviteToVanpool} />
        <Route path={ prefix + '/roster'} component={RosterView} />
      </Switch>
    </div>
  );
}
