export function getTripType(loginProfile) {
  let tripType;

  if (loginProfile.isVanpoolNTDReporting) {
    tripType = 'ntd';
  } else if (
    loginProfile.isTripRecordingVanpool ||
    loginProfile.isRegisteredUserTripRecorder
  ) {
    tripType = 'standard';
  } else {
    tripType = 'none';
  }

  return tripType;
}

export function createRole(loginProfile) {
  let role;

  if (loginProfile.vanpoolId) {
    let selectedRoles = [];
    let rolesArray = Object.keys(loginProfile.roles);
    rolesArray.forEach(item => {
      if (loginProfile.roles[item]) {
        selectedRoles.push(item);
      }
    });
    if (selectedRoles.length === 0) {
      role = 'rider';
    } else {
      if (!selectedRoles.some(item => item === 'coordinator')) {
        selectedRoles.push('rider');
      }
      role = selectedRoles
        .map(item => changeCamelCaseToStandardWithLowerCase(item))
        .sort()
        .join(', ');
    }
  } else {
    role = 'registered user';
  }

  return role;
}

function changeCamelCaseToStandardWithLowerCase(item) {
  return item.replace(/([A-Z])/g, ' $1').toLowerCase();
}
