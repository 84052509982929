import React from 'react';
import { AngleLeft, AngleRight } from '../../shared/icons';
import dateUtility from '../../shared/dateUtility';

export default function WeekNavigation({ days, next, prev }) {
  if (!days.length) {
    return null;
  }

  const startDay = parseInt(days[0].date.split('-').pop(), 10);
  const endDay = parseInt(days[days.length - 1].date.split('-').pop(), 10);

  return (
    <div className="week-nav">
      <button className="prev-week" disabled={!prev} onClick={prev}>
        <AngleLeft />
      </button>
      <button className="next-week" disabled={!next} onClick={next}>
        <AngleRight />
      </button>
      <span className="month">
        {dateUtility.parseDateAsMonth(days[0].date)}
      </span>
      {startDay === endDay ? (
        <span className="start">{startDay}</span>
      ) : (
        <span>
          <span className="start">{startDay}</span>-
          <span className="end">{endDay}</span>
        </span>
      )}
    </div>
  );
}
