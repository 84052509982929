import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  expenses: null,
  wexTransactions: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TRIP_EXPENSES_LOADED:
      return { ...state, expenses:action.data}
    case actionTypes.WEX_TRANSACTIONS_LOADED:
      return { ...state, wexTransactions:action.data}
    default:
      return state;
  }
};
