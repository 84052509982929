import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import { withRouter } from 'react-router';
import { ArrowRight, ArrowLeft, ChevronDown } from '../icons';
import { isEqual } from 'lodash';

export class AggregateNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAggregate: null,
      showDropdown: false,
      dropdownItems: []
    };
  }

  componentWillReceiveProps(props) {
    if (!this.state.currentAggregate && props.aggregates[0]) {
      this.setState({
        currentAggregate: props.aggregates[0],
        dropdownItems: props.aggregates
      });
    }
  }

  componentDidMount() {
    this.close = () => this.toggleBackdrop(false);
    window.addEventListener('click', this.close);
    this.props.init();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.close);
  }

  view(option) {
    if (option !== null) {
      return this.setState({ dropdownItems: option.children });
    }

    const aggregates = this.props.aggregates;
    const dropdownItems = this.state.dropdownItems;

    if (dropdownItems[0].parent && dropdownItems[0].parent.parent) {
      return this.setState({
        dropdownItems: dropdownItems[0].parent.parent.children
      });
    }

    return this.setState({ dropdownItems: aggregates });
  }

  select(currentAggregate) {
    if (!isEqual(this.state.currentAggregate, currentAggregate)) {
      this.toggleBackdrop(false);
      this.setState({ currentAggregate });
      this.view(currentAggregate.parent || null);
      setTimeout(() => this.props.setCurrentAggregate(currentAggregate)); //NOTE: this needs to pause to fire event after navigation changes
      if (!window.location.hash.startsWith('#/stats')) {
        this.props.history.push(`stats`);
      }
    } else {
      this.toggleBackdrop(false);
    }
  }

  toggleBackdrop(open) {
    open ? this.props.showBackdrop() : this.props.hideBackdrop();
    this.setState({ showDropdown: open });
  }

  render() {
    const { currentAggregate, showDropdown, dropdownItems } = this.state;
    let aggregateDisplay;
    if (!currentAggregate) {
      return <div className="aggregate-nav-container"></div>;
    }
    if (currentAggregate.isVanpool) {
      aggregateDisplay = (
        <span>
          <span>{currentAggregate.id}</span>
          <span className="aggregate-emphasis">
            {' '}
            {currentAggregate.name ? `(${currentAggregate.name})` : null}
          </span>
        </span>
      );
    } else {
      aggregateDisplay = <span>{currentAggregate.name}</span>;
    }
    return (
      <div
        className="aggregate-nav-container"
        onClick={e => currentAggregate && e.stopPropagation()}
      >
        <button
          className={`current-aggregate ${showDropdown ? 'show' : ''}`}
          onClick={() =>
            showDropdown
              ? this.select(currentAggregate)
              : this.toggleBackdrop(true)
          }
        >
          {aggregateDisplay}
          {currentAggregate.isTransit ? (
            <span className="aggregate-emphasis"> (Transit)</span>
          ) : null}
          <ChevronDown className="" />
        </button>
        {showDropdown ? (
          <AggregatesDropdown
            selected={currentAggregate}
            list={dropdownItems}
            view={option => this.view(option)}
            select={option => this.select(option)}
          />
        ) : null}
      </div>
    );
  }
}

function AggregatesDropdown({ selected, list, view, select }) {
  const parent = list[0].parent;

  return (
    <div className="selector aggregates">
      {parent ? (
        <button
          className={`title`}
          onClick={() => view(null)}
          ref={e => e && e.focus()}
        >
          <span>
            <ArrowLeft />
            Back
          </span>
        </button>
      ) : null}
      <div
        className={`aggregate-children ${
          parent && parent.children ? 'pad-left' : ''
        }`}
      >
        {list.map(item =>
          item.children && item.children.length > 0 ? (
            <DrillDown
              key={item.id}
              selected={selected === item}
              isTransit={item.isTransit}
              {...item}
              onDrillDown={() => view(item)}
              onSelect={() => select(item)}
            />
          ) : (
            <Vanpool
              key={item.id}
              selected={selected === item}
              {...item}
              onSelect={() => select(item)}
            />
          )
        )}
      </div>
    </div>
  );
}

function DrillDown({ name, selected, onSelect, onDrillDown, isTransit }) {
  const drill = e => e.stopPropagation() || e.preventDefault() || onDrillDown();

  return (
    <div className={`drilldown ${selected ? 'selected' : ''}`}>
      <button className="drilldown-name" onClick={onSelect}>
        {name}
        {isTransit ? (
          <span className="aggregate-emphasis"> (Transit)</span>
        ) : null}
      </button>
      <button
        tabIndex={0}
        className="down"
        onClick={drill}
        onKeyPress={e => e.key === 'Enter' && drill(e)}
      >
        <ArrowRight />
      </button>
    </div>
  );
}

function Vanpool({ name, id, selected, onSelect }) {
  return (
    <button
      className={`vanpool ${selected ? 'selected' : ''}`}
      onClick={onSelect}
    >
      <span className="id">{id}</span>
      <span className="name">{name ? `(${name})` : ''}</span>
    </button>
  );
}

function mapStateToProps(state) {
  return {
    aggregates: state.vanpoolAggregates.tree
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.VANPOOL_AGGREGATES_LOADING });
    },
    setCurrentAggregate(currentAggregate) {
      dispatch({
        type: actionTypes.STATS_AGGREGATE_CHANGED,
        data: currentAggregate
      });
    },
    showBackdrop() {
      dispatch({ type: actionTypes.SHOW_BACKDROP });
    },
    hideBackdrop() {
      dispatch({ type: actionTypes.HIDE_BACKDROP });
    }
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AggregateNav)
);
