import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import config from '../../appConfig';

export default function*() {
  yield takeLatest(actionTypes.VANPOOL_INFO_LOADING, loadVanpoolInfo);
}

function* loadVanpoolInfo() {
  try {
    const vanpoolInfo = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool`)
    );
    yield put({ type: actionTypes.VANPOOL_INFO_LOADED, data: vanpoolInfo });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.VANPOOL_INFO_LOADED, data: { error: true } });
  }
}
