import React, { useState } from 'react';
import Collapsible from 'react-collapsible';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import { AngleDown } from '../../shared/icons';
import FancyInput from '../../shared/FancyInput';
import CommonToast from '../../shared/components/common-toast-message/CommonToast';
import analyticsService from '../../shared/analytics/analytics-service';

function ResetFuelCardPin(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [resetPin, setResetPin] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  function ResetHeading() {
    return (
      <div className='collapsible-container'>
        <div className='heading'>Reset WEX Fuel Card PIN</div>
        <AngleDown className="angle-down-button" aria-hidden="true" />{' '}
      </div>
    );
  }

  const handlePinChange = (e) => {
    const pin = e.target.value;
    if (/^\d*$/.test(pin) || pin === '') { // Validate only numbers
      setResetPin(pin);
    }
  };
  const handlePinReset = async () => {
    props.resetPin(resetPin);
    setResetPin('');
    setIsErrorModalOpen(true);
    analyticsService.analyticsProcessEvent({
      "event": "click_generic",
      "context": {
        "event_action": "reset pin"
        }
    });
  };

  return (
    <div className='reset-collapsible'>
      <Collapsible
        className='collapsible'
        trigger={ResetHeading()}
        open={isOpen}
        handleTriggerClick={() => setIsOpen(!isOpen)}
      >
        <div className='reset-pin'>
          <div className='text-input'>
            <div className='text'>Enter a new 4 digit PIN</div>
            <div className="input-box">
              <FancyInput
                type="text"
                className="fuel-card-pin"
                maxLength="4"
                value={resetPin}
                onChange={handlePinChange}
              />
            </div>
          </div>
          <button
            className={`primary button ${!resetPin || resetPin.length !== 4 ? 'disabled' : 'active'}`}
            disabled={!resetPin || resetPin.length !== 4}
            onClick={handlePinReset}
            >
            RESET PIN
          </button>
            {/* success/error message will be updated based on api response later */}
            { props.resetPinRes && props.resetPinRes.success &&
             <CommonToast message="PIN successfully updated" type='success' isOpen={isErrorModalOpen} closeToast={() => setIsErrorModalOpen(!isErrorModalOpen)} timeOut={6000}/>}
  
            {!props.resetPinRes &&  <CommonToast message={props.resetPinRes.message ? props.resetPinRes.message : "PIN could not be reset. Please try again"} type='error' isOpen={isErrorModalOpen} closeToast={() => setIsErrorModalOpen(!isErrorModalOpen)} timeOut={6000}/>}
        </div>
      </Collapsible>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    resetPin(resetPin){
      const resetPinData = { pinNumber: resetPin };
      dispatch({
        type: actionTypes.RESET_WEX_FUEL_CARD_PIN,
        payload: resetPinData
      });
    }
  };
}

function mapStateToProps(state) {
  return {
    resetPinRes: state.fuelCardPayment.resetPinRes,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetFuelCardPin);
