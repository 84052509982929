import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import { useState, useEffect } from 'react';
import stopStart from '../../../_styles/images/stopStart.png';
import stopEnd from '../../../_styles/images/stopEnd.png';
import User from '../../../_styles/images/User.png';
import { getTimeObjectFromString } from '../../../join-commute/helpers/commonHelper';
import * as actionTypes from '../../../redux/actionTypes';
import analyticsService from '../../../shared/analytics/analytics-service';
import { shouldShowAddress } from '../../../profile/helper';
const Alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

function RouteStops(props) {
    const [route, updateRoute] = useState({});
    const [accordianOpen, setAccordianOpen] = useState(false)
    const [isToHome, showToHomeData] = useState(false);
    const [isToWork, showToWorkData] = useState(true);
    const [stops, setStops] = useState([]);

    useEffect(() => {
        if (route && route.outboundRoutePoints && route.outboundRoutePoints.length > 0) {
            getRoutes(0);
        }
    }, [route]);

    useEffect(() => {
        if (props && props.route) {
            updateRoute(props.route);
        }
    }, [props])

    const showStop = data => {
        if (data) {
            showToHomeData(true);
            showToWorkData(false);
            getRoutes(data);
        } else {
            showToWorkData(true);
            showToHomeData(false);
            getRoutes(data);
        }
        analyticsService.analyticsProcessEvent({
            "event": "click_generic",
            "context": {
                "event_action": data ? "to home" : "to work"
            }
        })
    };

    function getRoutes(data) {
        const routeDirection = !data ? "outboundRoutePoints" : "inboundRoutePoints";
        const routes = route && route[routeDirection];
        props.setDirections(routes);
        setStops(routes);
    };

    function getTime(time) {
        if (time) {
            const timeObj = getTimeObjectFromString(time);
            return `${timeObj.hour}:${timeObj.minutes} ${timeObj.day}`
        }
    }

    function getAddress(stop) {
        const addArray = stop.address.split(/,(.*)/s);
        if (shouldShowAddress(stop, props.participantId)) {
            return (
                <div className='address'>
                    <div className='first-address'>{addArray[0]}</div>
                    <div className='second-address'>{addArray[1]}</div>
                </div>

            )
        } else {
            return (
                <div className='address'>
                    <div className='first-address'>{"Private Address"}</div>
                </div>
            )
        }
    }

    function onTabKeyDown(data, e) {
        if (e.which === 13) {
            showStop(data);
        }
    }

    function heading() {
        let tempClass = 'arrow ';
        tempClass += accordianOpen ? 'up ' : 'down ';
        return (
            <div className='collapsible-heading-container' role='button' aria-controls='collapsible' aria-expanded={accordianOpen} tabIndex={0}>
                <h1 className='heading'>{"View Stops"}</h1>
                <i className={tempClass}></i>
            </div>
        );
    }

    return (
        <Collapsible aria-expanded={accordianOpen} open={accordianOpen} handleTriggerClick={() => setAccordianOpen(!accordianOpen)} trigger={heading()} >
            <div className='collapsible-content-container'>
                <div className="stops">
                    <div className="tabs">
                        <div
                            className={`tab ${isToWork ? 'active' : ''}`}
                            onClick={() => showStop(0)}
                            onKeyDown={(e) => onTabKeyDown(0, e)}
                            role="button"
                            aria-label={`To Work ${isToWork ? 'selected' : 'not selected'}`}
                            tabIndex={0}
                        >
                            To Work
                        </div>
                        <div
                            className={`tab ${isToHome ? 'active' : ''}`}
                            onClick={() => showStop(1)}
                            onKeyDown={(e) => onTabKeyDown(1, e)}
                            role="button"
                            aria-label={`To Home ${!isToWork ? 'selected' : 'not selected'}`}
                            tabIndex={0}
                        >
                            To Home
                        </div>
                    </div>
                    <div className="tab-container">
                        {
                            stops.map((s, i) =>
                            (
                                !s.todaysParticipants.length && !(s.stopOrder == -1 || s.stopOrder == 0) ?
                                    null
                                    :
                                    <div className='stops-container' key={i}>
                                        <div className='first-section'>
                                            <div className='left-section'>
                                                <div className='icon'>
                                                    {
                                                        s.stopOrder == 0 ? <img alt="stop start" src={stopStart} tabIndex={0} />
                                                            : s.stopOrder == -1 ? <img alt="stop start" src={stopEnd} tabIndex={0} />
                                                                : <div className='stopOrder'>{Alphabets[s.stopOrder - 1]} </div>
                                                    }

                                                </div>
                                                {getAddress(s)}
                                            </div>
                                            <div className='right-section'>
                                                <div className='time'>
                                                    {
                                                        s.stopOrder == 0 ? <div className='label'>{"Pickup"}</div>
                                                            : s.stopOrder == -1 ? <div className='label'>{"Dropoff"}</div>
                                                                : null
                                                    }

                                                    <div className='value'>{getTime(s.stopTime)}</div>
                                                </div>
                                            </div>

                                        </div>

                                        {
                                            (isToWork && s.stopOrder !== -1) || (isToHome && s.stopOrder !== 0) ?
                                                <div className='second-section'>
                                                    <span className='icon'><img alt="stop start" src={User} tabIndex={0} /></span>
                                                    <span className='count'>{s.todaysParticipants.length}</span>
                                                </div>
                                                :
                                                null
                                        }



                                    </div>
                            )
                            )
                        }


                    </div>
                </div>
            </div>
        </Collapsible>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        setDirections(directions) {
            dispatch({ type: actionTypes.MAP_DIRECTIONS, data: directions });
        }
    };
}

function mapStateToProps(state) {
    return {
      participantId: state.userAuth.participantId,
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(RouteStops);
