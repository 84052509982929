import * as actionTypes from '../../redux/actionTypes';

const initialState = {
  history: {},
  myHistory: {},
  rawPaymentHistory: {},
  historyLoaded: false,
  generalInfo: {
    binNumbers: [],
    toastData: {
      isOpen: false
    },
    maxAmount: 0
  },
  loadError: false,
  loadErrorString: '',
  myHistoryLoadError: false,
  myHistoryLoadErrorString: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MY_PAYMENT_HISTORY_LOADED:
      return { ...state, myHistory: action.data, myHistoryLoadError: false };
    case actionTypes.MY_PAYMENT_HISTORY_LOADED_FAILURE:
      return { ...state, myHistory: action.data, myHistoryLoadError: true, myHistoryLoadErrorString: action.errorString}
    case actionTypes.GET_PAYMENT_HISTORY_FAILURE:
      return { ...state, rawPaymentHistory: action.data, historyLoaded: true, loadError: true, loadErrorString: action.errorString};
    case actionTypes.GET_PAYMENT_HISTORY_SUCCESS:
      return { ...state, rawPaymentHistory: action.data, historyLoaded: true, loadError: false };
    case actionTypes.SET_BIN_NUMBERS:
      return { ...state, generalInfo: { ...state.generalInfo, binNumbers: action.data.binNumbers, maxAmount: action.data.maxAmount } }
    case actionTypes.SHOW_TOAST:
      return { ...state, generalInfo: { ...state.generalInfo, toastData: { ...state.generalInfo.toastData, isOpen: true, message: action.data } } }
    case actionTypes.HIDE_TOAST:
      return { ...state, generalInfo: { ...state.generalInfo, toastData: { ...state.generalInfo.toastData, isOpen: false, message: '' } } }
    default:
      return state;
  }
};
