import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import config from '../../appConfig';

const LIMIT = 21;

export default function*() {
  yield takeLatest(actionTypes.SERVICE_PROVIDER_LOADING, loadServiceProvider);
}

function* loadServiceProvider({ data: { zip, distance } }) {
  try {
    const serviceProvider = yield call(() =>
      Http.get(
        `${config.kongApiBaseUrl}/location/serviceProviders/${zip}/${distance}`
      )
    );

    const count = serviceProvider.locations.loc.length;
    const locations = serviceProvider.locations.loc.slice(0, LIMIT);

    yield put({
      type: actionTypes.SERVICE_PROVIDER_LOADED,
      data: {
        locations,
        isMore: count > LIMIT
      }
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.SERVICE_PROVIDER_LOADED,
      data: { error: true }
    });
  }
}
