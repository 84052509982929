import React, { useEffect, useRef, useState } from 'react';
import Close from '../../_styles/images/close-icon-green.svg';


export default function TraditionalDisclaimerPopup(props) {
  const node = useRef();

  function updateTDPKeydown(e){
    if(e.which === 13){
        props.updatePDPopup(false)
    }
  }

  return (
    <div className="charges-popup show" id='price-disclaimer-popup' aria-live='polite' role='alert' aria-modal='true' ref={node}>
      <div className='price-container'>
        <div className="price-disclaimer-container">
          <div className="sp-header">
            <div className='header  traditional'>
            <div className='heading'>
              Displayed total is an estimate only
            </div>
            </div>
            <button
              role='button'
              className="close traditional"
              onClick={() => props.updateTDPopup(false)}
              onKeyDown={updateTDPKeydown}
              tabIndex={0}
              aria-label="Close edit driving schedule popup"
            >
              <img src={Close} alt='Close' />
            </button>
          </div>
          <div className='price-disclaimer-popup-container'>
            <div className='content'>
            Your portion of the overall monthly vanpool rate will be determined by your vanpool. The estimated rate includes estimated fuel, tolls, taxes, and add-on equipment. You may cancel online or by telephone with 30 days prior notice.
            </div>
          
         </div>
         <div className='button-container'>
         <button  tabIndex={0} className='continue-button' onClick={() => props.updateTDPopup(false)} onKeyDown={updateTDPKeydown}>Close</button>
         </div>

          </div>
        </div>
      </div>
  );
}
