import React from 'react';
import { AngleDown } from '../../shared/icons';

export function MonthlyChargesHeading(props) {
  if (props.monthsTransactions) {
    return (
      <div className="etable-heading toggle monthly-charges">
        <div className="etable-row">
          <div className="etable-column">
            <div className="etable-head current-month">
              <AngleDown className="angle-down-button" />{' '}
              {`${props.monthsTransactions.itemMonth} ${props.monthsTransactions.itemYear}`}{' '}
            </div>
            <div className="etable-head total">
              {props.monthsTransactions.itemBalanceAmount}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

export function MonthlyChargesTable(props) {
  if (!props.monthsTransactions) {
    return <div />;
  }
  return (
    <div>
      <div className="etable-body monthly-charges-table">
        {props.monthsTransactions.itemActivities.map((transaction, index) => (
          <div
            className="etable-row"
            key={`${props.monthsTransactions.item}_${index}`}
          >
            <div className="etable-column">
              <div className="etable-cell spacer"></div>
              <div className="etable-cell not-yet-invoiced">
                <div className="mobile-only-label"><label>Invoiced:&nbsp;</label>{transaction.invoiceDate}</div>
              </div>
              <div className="etable-cell activity-date">
                <div className="mobile-only-label"><label>Act. Date:&nbsp;</label>{transaction.activityDate}</div>
              </div>
              <div className="etable-cell description">
                <div className="mobile-only-label"><label>Description:&nbsp;</label>{transaction.activityDescription}</div>
              </div>
              <div className="etable-cell amount">
                <div className="mobile-only-label"><label>Amount:&nbsp;</label>{transaction.amount}</div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
