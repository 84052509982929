import React from 'react';

export default function FancyInput({ autoFocus, ...props }) {
  return (
    <span className="fancy-input">
      <input
        {...props}
        aria-label={props.placeholder}
        aria-required="true"
        ref={e =>
          autoFocus &&
          e &&
          !e.focused &&
          setTimeout(() => (e.focused = true) && e.focus(), 100)
        }
      />
      <span className="label">
        {props.label ? props.label : props.placeholder}
      </span>
    </span>
  );
}
