import { useEffect } from 'react';
import CautionIcon from '../../../../_styles/images/Caution.svg';

export default function ConfirmationPopup(props) {

  useEffect(() => {
    setTimeout(() => {
      const acceptButton = document.querySelector('.confirmation-delete-button');
      acceptButton.focus();
    }, 100)
  }, [])

  const keyDownHandler = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      const firstFocusElement = document.querySelector('.confirmation-cancel-button');
      firstFocusElement.focus();
    }
  }

  const keyDownBackTabHandler = (e) => {
    if (e.shiftKey && e.keyCode == 9) {
      e.preventDefault();
      const lastFocusElement = document.querySelector('.confirmation-delete-button');
      lastFocusElement.focus();
    }
  }

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        const changesPopup = document.getElementById('delete-confirmation-container');
        if(changesPopup){
          props.close();
        }
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [])

  return (
    <div className='card-operations-container' ref={props.reference}  role='dialog' aria-describedby='heading' aria-modal='true'>
      <div className='delete-confirmation-container' id='delete-confirmation-container'>
        <div className='caution-icon'>
          <img src={CautionIcon} alt='warning image'></img>
        </div>
        <div className='heading' id='heading'>{props.heading}</div>
        <div className='foot-notes'>{props.text}</div>
        <div className='buttons'>
          <button className='confirmation-cancel-button' onClick={props.close} onKeyDown={keyDownBackTabHandler}>{props.cancelButtonText || 'cancel'}</button>
          <button className='confirmation-delete-button' disabled={false} onClick={props.confirm} onKeyDown={keyDownHandler}>{props.confirmButtonText}</button>
        </div>
      </div>
    </div>
  );
}
