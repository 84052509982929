import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import currencyFormatter from '../../shared/currencyFormatter';
import { Van, FuelPump, Tree, Money } from '../../shared/icons';
import { isAllowed } from '../../shared/permissions/RestrictTo';
import { PERMISSION_VANPOOL_STATUS_RENTAL } from '../../shared/permissions/permissions';

class ImpactData extends Component {
  componentDidMount() {
    const { impactDataLoaded, init, isNewSubscription } = this.props;
    if (!impactDataLoaded) {
      init(isNewSubscription);
    }
  }

  render() {
    const { impactData, show } = this.props;

    if (!impactData || !impactData[show]) {
      return null;
    }

    const data = impactData[show].current;
    const formattedFuelSavings = currencyFormatter.formatUSD(data.fuelSavings).split('.')[0];

    return (
      <div className="impact-data">
        <div className="miles-saved cell">
          <img src={Van} alt="van" aria-hidden="true" />
          <span role="group">
            <label>Miles Saved</label>
            <span className="value">{data.milesSaved.toLocaleString()}</span>
            <span className="units" aria-label="miles">mi</span>
          </span>
        </div>
        <div className="fuel-saved cell" role="group">
          <img src={FuelPump} alt="fuel pump" aria-hidden="true" />
          <span>
            <label>Fuel Gallons Saved</label>
            <span className="value">{data.fuelGallonsSaved.toLocaleString()}</span>
            <span className="units" aria-label="gallons">gal</span>
          </span>
        </div>
        <div className="co2-saved cell" role="group">
          <img src={Tree} alt="tree" aria-hidden="true" />
          <span>
            <label>CO2 lbs Saved</label>
            <span className="value">{data.co2Savings.toLocaleString()}</span>
            <span className="units">lbs</span>
          </span>
        </div>
        <div className="fuel-savings cell" role="group">
          <img src={Money} alt="money" aria-hidden="true" />
          <span>
            <label>Fuel Cost Savings</label>
            <span className="value">{formattedFuelSavings}</span>
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  impactData: state.impactData,
  impactDataLoaded: state.impactData.impactDataLoaded,
});

const mapDispatchToProps = (dispatch) => ({
  init: (isNewSubscription) => {
    const actionType = isAllowed([PERMISSION_VANPOOL_STATUS_RENTAL]) && !isNewSubscription
      ? actionTypes.VANPOOLER_IMPACT_DATA_LOADING
      : actionTypes.REGISTERED_USER_IMPACT_DATA_LOADING;
    dispatch({ type: actionType });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImpactData);
