import * as actionTypes from '../../../redux/actionTypes';

import { useEffect, useRef, useState } from 'react';

import AddressUtility from '../../../shared/addressUtility';
import Camera from '../../../_styles/images/Camera.png';
import CropPicture from '../../../join-commute/common/crop-picture/CropPicture';
import { Link } from 'react-router-dom';
import Office from '../../../_styles/images/Office.png';
import Star from '../../../_styles/images/Star.png';
import Steering from '../../../_styles/images/Steering.png';
import Vans from '../../../_styles/images/Vans.png';
import azureService from '../../../login/azureService';
import { connect } from 'react-redux';
import Block from '../../../_styles/images/Block.png';
import Timer from '../../../_styles/images/TimerLight.png';
import { ENTER_KEY_CODE, SPACE_KEY_CODE } from '../../../shared/helpers/accessibilityHelpers';

function PersonalInformation(props) {
  const hiddenFileInput = useRef(null);

  const [imageSrc, setImageSrc] = useState();
  const [croppedImage, setCroppedImage] = useState(props.profile.profileImage);
  const [isOpen, setIsOpen] = useState(false);

  const initials = props.profile.name ? props.profile.name.split(' ').map(s => s[0]).join('') : "";
  const primaryNumber = props.profile.phone ? props.profile.phone.number : "N/A";
  const address = props.profile.homeAddress && props.profile.homeAddress.line1
    ? AddressUtility.lines(props.profile.homeAddress) : ["N/A"];
  let addressString = '';
  address.map((str, index) => {
    if (index === address.length - 1) {
      addressString += '\n'
    }
    addressString += ` ${str}`;
  });

  const handleClick = () => {
    hiddenFileInput?.current?.click();
    props.passAnalyticsClickEvent("update profile photo");
  };

  const onKeyDownCamera = (e) => {
    if (e.which === ENTER_KEY_CODE || e.which === SPACE_KEY_CODE) {
      handleClick();
    }
  }

  useEffect(() => {
    setCroppedImage(props.profile.profileImage);
  }, [props.profile.profileImage])

  function finalizeImage(image) {
    if (image && image != '') {
      props.uploadPicture(image);
    }
  }

  const handleFileLoad = async event => {
    const fileUploaded = event.target.files[0];
    const fileURL = URL.createObjectURL(fileUploaded);
    //setting image to be cropped and opening the popup
    setImageSrc(fileURL);
    setIsOpen(true);
  }

  function changePassword() {
    props.passAnalyticsClickEvent("change my password");
    azureService.passwordChange();
  }

  function updateInformation() {
    props.passAnalyticsClickEvent("update my information");
    props.history.push("/myprofile/edit");
  }

  return (
    <div className='personal-information' role='group'>
      <div className='header' aria-hidden='true'>
        <div className='section'>
          <div className="icon">
            <img src={Vans} aria-hidden={true} />
          </div>
          <div className="icon">
            <img src={Office} aria-hidden={true} />
          </div>

        </div>
      </div>
      <div className='sub-container'>
        <div className='header-content'>
          <div className='profile-picture'>
            {
              croppedImage ?
                <img alt='Profile image' src={croppedImage} />
                :
                <div className='initials' aria-label={`Profile initials: ${initials}`}>
                  {initials && initials.toUpperCase()}
                </div>
            }
            {
              props.profile.vanpoolId ?
                <button className='imageIcon'
                  onClick={handleClick}
                  onKeyDown={onKeyDownCamera}
                  tabIndex={0}
                  aria-label='Update profile photo'>
                  <img src={Camera} alt='' />
                </button>
                :
                null
            }

            <input
              type="file"
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              onClick={(e) => { e.target.value = '' }}
              onInput={handleFileLoad}
              accept="image/jpeg"
              aria-hidden='true'
            />
          </div>

          <div className='roles'>
            {
              props.profile.isCoordinator &&
              <div className='role'>
                <img src={Star} alt=''/>
                <span className='role-name'>Coordinator</span>
              </div>
            }
            {
              props.profile.driver?.isDriver && props.profile.isDriver && !props.profile.driverUpdatedStatus ?
                <div className={` role ${props.profile.isCoordinator ? "second-role" : ""}`}>
                  <img src={Steering} alt=''/>
                  <span className='role-name'>driver</span>
                </div>
                :
                props.profile.driverUpdatedStatus ?
                  <div className={`role ${props.profile.isCoordinator ? "second-role" : ""}`}>
                    {(props.profile.driverUpdatedStatus == "Non-Approved Driver" || props.profile.driverUpdatedStatus == "License Expired") ?
                      <img src={Block} alt=''/> :
                      props.profile.driverUpdatedStatus == "Pending Driver Approval" ? <img src={Timer} alt=''/> : null

                    }
                    <span className='role-name'>{props.profile.driverUpdatedStatus}</span>
                  </div>
                  :
                  null

            }
          </div>
        </div>
        <div className='main-content'>
          <div className='first-section' role='group'>
            <div className='name'>{props.profile.name}</div>
            <div className='company-name'>{props.profile.employerName}</div>
            <Link className='reset-passowrd green-link-underline' to='#' onClick={changePassword}>change my password</Link>
            <div className='update-info-container'>
              <button className="update-info" onClick={updateInformation}>
                update my information
              </button>
            </div>
          </div>
          <div className='seprator'></div>
          <div className='second-section' role='group'>
            <div className='details'>
              <div className='content'>
                <div className='key'>Email</div>
                <div className='value'>{props.profile.email}</div>
              </div>
              <div className='content'>
                <div className='key'>Address</div>
                <div className='value'>
                  <span>{addressString}</span>
                </div>
              </div>
              <div className='content'>
                <div className='key'>Phone</div>
                <div className='value'>{primaryNumber}</div>
              </div>
              {props.userInfo && props.userInfo.participantId && <div className='content participant-id'>
                <div className='key'>Participant ID</div>
                <div className='value'>{props.userInfo.participantId}</div>
              </div>}
              {props.userInfo && props.userInfo.vanpoolId && <div className='content'>
                <div className='key'>Vanpool ID</div>
                <div className='value'>{props.userInfo.vanpoolId}</div>
              </div>}
            </div>
          </div>
        </div>

        {isOpen && <div className="crop-popup show">
          <CropPicture src={imageSrc} setCompletedCrop={finalizeImage} close={() => setIsOpen(false)} />
        </div>}
      </div>
    </div >
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    userInfo: state.userAuth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    uploadPicture(data) {
      dispatch({ type: actionTypes.UPLOAD_PROFILE_PICTURE, data })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInformation);
