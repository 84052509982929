import dateUtility from '../../shared/dateUtility';

let routeDurationCalculator = {};

routeDurationCalculator.calculateTimesBasedOnDeparture = (
  stops,
  time,
  durations
) => {
  const newStops = [...stops];

  for (let i = 0; i <= durations.length; i++) {
    newStops[i] = { ...stops[i], stopTime: time };
    time = dateUtility.shiftBySeconds(
      time,
      durations[i] + stops[i].waitTime * 60
    );
  }

  return newStops;
};

routeDurationCalculator.calculateTimesBasedOnArrival = (
  stops,
  time,
  durations
) => {
  const newStops = [...stops];
  const shiftedStops = [{}, ...stops];
  durations = durations.map(d => -d);

  for (let i = durations.length; i >= 0; i--) {
    newStops[i] = { ...stops[i], stopTime: time };
    time = dateUtility.shiftBySeconds(
      time,
      durations[i - 1] - shiftedStops[i].waitTime * 60
    );
  }

  return newStops;
};

export default routeDurationCalculator;
