let objectUtility = {};

function unnullify(defaultValues, obj) {
  if (Array.isArray(obj)) {
    if (obj.length === 0) {
      return defaultValues;
    } else {
      return obj;
    }
  } else {
    if (defaultValues !== null && typeof defaultValues === 'object') {
      Object.keys(defaultValues).forEach(key => {
        if (obj[key] === undefined) {
          obj[key] = null;
        }
      });
    }

    return Object.keys(obj).reduce((newObj, key) => {
      newObj[key] =
        obj[key] === null
          ? defaultValues[key]
          : typeof obj[key] === 'object'
          ? unnullify(defaultValues[key], obj[key])
          : obj[key];
      return newObj;
    }, {});
  }
}

objectUtility.unnullify = unnullify;

export default objectUtility;
