import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import Questions from '../../shared/Questions';
import Collapsible from 'react-collapsible';
import {
  InvoiceMonthlyActivityHeading,
  InvoiceMonthlyActivityTable
} from './InvoiceMonthlyActivity';
import { Download } from '../../shared/icons';
import config from '../../appConfig';
import analyticsService from '../../shared/analytics/analytics-service';
import logger from '../../shared/logger';
import Http from '../../shared/http-service';
import { saveAs } from 'file-saver';

class InvoiceTable extends Component {
  componentDidMount() {
    this.props.loadDueDate();
  }

  render() {
    let invoiceSummary = this.props.invoiceSummary;
    return (
      <div>
        <div className="etable invoice-table">
          <div className="etable-body">
            <div className="etable-row header-labels">
              <div className="etable-header service-month">Month</div>
              <div className="etable-header activity-date">Activity Date</div>
              <div className="etable-header description">Description</div>
              <div className="etable-header right">Amount</div>
            </div>
            {invoiceSummary.map((invoice, index) => {
              const isRecentActivity = !invoice.invoiceId;
              const isAccordianView =
                invoice.invoiceActivities &&
                invoice.invoiceActivities.length > 0;
              if (isAccordianView || isRecentActivity) {
                const monthlyChargesHeading = (
                  <InvoiceMonthlyActivityHeading
                    invoiceDetails={invoice}
                    index={index}
                    monthIndex={index}
                    dueDate={this.props.dueDate}
                    pdfDownloadFail={this.props.pdfDownloadFail}
                  />
                );
                return (
                  <Collapsible
                    trigger={monthlyChargesHeading}
                    tabIndex={index}
                    key={invoice.invoiceId}
                    open={index === 0}
                  >
                    <InvoiceMonthlyActivityTable
                      invoiceDetails={invoice}
                      index={index}
                    />
                  </Collapsible>
                );
              } else {
                return (
                  <div
                    className="invoice-archive etable-row"
                    id={`invoice-entry-${index}`}
                    key={index}
                  >
                    <div className="etable-column">
                      <div className="date etable-head">
                        {invoice.invoiceMonth} {invoice.invoiceYear}
                      </div>
                      <a
                        className="invoice-number mobile-only"
                        href={`${config.kongApiBaseUrl}/vanpools/vanpool/invoices/invoice/pdf/download/${invoice.invoiceId}`}
                      >
                        Download <Download />
                      </a>
                      <button
                        className="invoice-number desktop-only"
                        onClick={async e => {
                          e.preventDefault();
                          let response = await Http.getStatic(
                            `${config.kongApiBaseUrl}/vanpools/vanpool/invoices/invoice/pdf/download/${invoice.invoiceId}`, {"Content-Type" : "application/pdf"});
                          if (response.ok) {
                            const pdf = await response.blob();
                            saveAs(pdf, `${invoice.invoiceId}.pdf`);
                          } else {
                            this.props.pdfDownloadFail(response)
                          }
                        }}
                      >
                        Download <Download />
                      </button>
                    </div>
                    <div className="amount-due etable-head right">
                      {invoice.invoiceAmount}
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
        <p className="old-invoice-notice">
          Due to an update with the Commute billing system, invoices older than
          December 2019 will be download only.
        </p>
        <Questions />
      </div>
    );
  }
}

export function mapStateToProps(state) {
  return {
    dueDate: state.dashboardPaymentSummary.invoiceDueDate
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    loadDueDate() {
      dispatch({ type: actionTypes.BALANCE_DETAILS_LOADING });
    },
    pdfDownloadFail(response) {
      dispatch({ type: actionTypes.DISPLAY_ERROR, data: response.statusText });
      analyticsService.analyticsEvent({
        type: 'Network Error',
        errorCode: response.statusText,
        api: response.url
      });
      logger.serverLog({
        url: response.url,
        data: response.headers,
        message: 'Network Error Occurred : error fetching url'
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoiceTable);
