import { Link } from 'react-router-dom';
import ArrowLeft from '../_styles/images/ArrowLeft.png';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import Loading from '../shared/loading/Loading';
import * as actionTypes from '../redux/actionTypes';
import { Vanpooler } from '../join-commute/onboarding/vanpooler/Vanpooler';
import ParticipantDetailsPopup from './ParticipantDetailsPopup';
import analyticsService from '../shared/analytics/analytics-service';
import { RestrictTo } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR
} from '../shared/permissions/permissions';
import useError from '../shared/components/error-screen/useError';

function RosterView(props) {
  const [loading, updateLoading] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState([]);
  const [showPDPopup, updateShowPDPopup] = useState(false);
  const [vanpoolId, updateVanpoolId] = useState(null)

  useEffect(() => {
    if (!props.loaded) {
      updateLoading(true);
      props.init();
    }
  }, []);

  useError(props.loadError, props.history, props.loadErrorString);

  useEffect(() => {
    if (props.loaded) {
      updateLoading(false);
      setParticipants(props.roster.rosterParticipants);
      updateVanpoolId(props.roster.vanpoolId)
    }
  }, [props.loaded]);


  function showParticipantDetails(participant) {
      setSelectedParticipant(participant);
       const roles = Object.keys(participant.roles).filter(key=>participant.roles[key]);
       analyticsService.modelOpened("vanpool/roster/view profile", roles.length? roles.join(":") : "");
      updateShowPDPopup(true)
  }

  function closeParticipantPopup() {
    updateShowPDPopup(false);
    setTimeout(function(){ 
      document.querySelector(".vanpooler-container").focus();
    },0)
  }

  return (
    <div className="roster-page">
      <Loading isLoading={loading} />
      <div className="container">
        <div className="header">
          <div className="backArrow">
            <Link className="overview-link" onClick={props.history.goBack}>
              <img alt="go back" src={ArrowLeft} tabIndex={0} role='button'/>
            </Link>
          </div>
          <div className="heading">Roster</div>
        </div>
        <div className="main" role="group">
          <div className={`participants-container ${ participants && participants.length <=4 ? "less" : ""}`}>
            {participants &&
              participants.map(participant => (
                <Vanpooler
                  key={participant.participantId}
                  participant={participant}
                  showParticipantDetails={(p) => showParticipantDetails(p)}
                  fromRoster={true}
                  isSubscription={props.isSubscription}
                />
              ))}
          </div>

        </div>
        {   
            !props.isSubscription ?
                <RestrictTo roles={[ PERMISSION_COORDINATOR ]} required={false} >
                  <div className='sticky-footer'>
                    <button className='button invite-vanpool-button' onClick={() => props.history.push('/vanpool/roster/invite-to-vanpool')}>invite to vanpool</button>
                  </div>
               </RestrictTo> 
          : 
          null
        }
      </div>
      {
          showPDPopup && <ParticipantDetailsPopup close={closeParticipantPopup} participant={selectedParticipant} atMaxTripRecorders={props.roster.atMaxTripRecorders}  maxTripRecordersAllowed={props.roster.maxTripRecordersAllowed} save={props.save} updateLoading={ () => updateLoading(true)} delete={props.delete} vanpoolId={vanpoolId} />
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    roster: state.roster,
    loaded: state.roster.loaded,
    loadError: state.roster.loadError,
    loadErrorString: state.roster.loadErrorString,
    isSubscription: state.userAuth.isSubscription
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({ type: actionTypes.ROSTER_LOADING });
    },
    save(data) {
      dispatch({ type: actionTypes.PARTICIPANT_ROLES_SAVING, data });
    },
    delete(id) {
      dispatch({ type: actionTypes.PARTICIPANT_PROFILE_DELETING, data: id });
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RosterView);
