import React, { Component } from 'react';
import { Stopwatch } from '../../shared/icons';
import FieldInfo from '../../shared/FieldInfo';


function DailyTripTimeInfo() {
  return (
    <div>
      <p>Daily Trip Details is a required field for Trip Recording that should have accurate drive times and mileage for each day</p>
    </div>
  );
}
export default function() {
    return (
      <div className="driveTimeDuration">
        <Stopwatch className="stopwatch"/>
      <div className="driveTimeHeading">Daily Trip Details</div>
      <FieldInfo message={<DailyTripTimeInfo />} />

      </div>
    );
}