import { useEffect, useState } from "react";
import { getPreferredDays } from "../../../../join-commute/helpers/commonHelper";
import EditSchedulePreferencesPopup from "../../../../driver-schedule/EditSchedulePreferencesPopup/EditSchedulePreferencesPopup";
import errorIcon from '../../../../_styles/images/new-icons/driver-status/error_circle.svg'

export default function PreferredDaysSection({ driverPreferences, saveSchedulePreferences, vanDays, getDriverPreferences, driverPreferencesError, passAnalyticsClickEvent }) {
    const [days, updatedays] = useState([]);
    const [isOpen, updateIsOpen] = useState(false);
    const [isError, updateIsError] = useState(true);
    useEffect(() => {
        if(driverPreferencesError){
            updateIsError(true);
        } else {
            if(driverPreferences && driverPreferences.autoRepeatSchedule){
                const preferredDays = getPreferredDays(driverPreferences.autoRepeatSchedule);
                updatedays(preferredDays);
            } else {
                updatedays([])
            }
            updateIsError(false);
        }
    }, [driverPreferences, driverPreferencesError])

    function savePreferences(data) {
        saveSchedulePreferences(data);
        updateIsOpen(false);
    }

    function editPreferences() {
        passAnalyticsClickEvent("edit schedule preferences");
        updateIsOpen(true);
    }
    return (
        <div className="commute-days" role='group'>
            <div className="commute-days-heading">
                Preferred Driving Days
            </div>
            {
                isError ?
                    <div className="error-container">
                        <img src={errorIcon} aria-hidden={true}/>
                        <div className="empty-days">We ran into an error while loading your preferences</div>
                    </div>
                    :
                    (!days || days.length == 0 ?
                        <div className="empty-days">You have not selected any preferred days yet</div>
                        :
                        <div className="days">
                            {days.map((day, index) => (
                                <div
                                    key={index}
                                    aria-label={day}
                                    className="day-btn"
                                    role='text'
                                >
                                    {day.substr(0, 3)}
                                </div>
                            ))}
                        </div>)
            }

            <div className={'change-my-commute' + (isError? ' error' : '')}>
                {
                    isError ?
                        <button className="try-button" onClick={getDriverPreferences}>
                            Try again
                        </button> :
                        <button className="change-commute green-link-underline" onClick={editPreferences}>
                            Edit Schedule Preferences
                        </button>
                }
            </div>
            {isOpen &&
                <EditSchedulePreferencesPopup
                    saveSchedulePreferences={savePreferences}
                    vanDays={vanDays}
                    alreadyDrivingDays={days}
                    updateIsOpen={updateIsOpen}
                    isFrequentDriver={driverPreferences.isFrequentDriver}
                />}
        </div>
    );
}
