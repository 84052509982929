import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionTypes from '../redux/actionTypes';
import { Vanpool, Van } from '../shared/icons';
import Section from '../profile/Section';
import AddressUtility from '../shared/addressUtility';
import MapService from '../shared/map-service';
import { WEEKDAYS } from '../shared/inputs/Calendar/Calendar';

export function FindARide({
  matches,
  requestResult = false,
  requestToJoin,
  showVan,
  profile,
  commuteProfile,
  userProfile
}) {

  const getCommuteDays = (daysArray) => {
    var days = daysArray;
    var commuteDays = days.map(function(day) {
        return WEEKDAYS.indexOf(day) + 1;
      });
    return commuteDays;
  }
  
  const getAddressUtility =  async (address) => {
    let addressString = await MapService.getAddressAndLocation(address);
    var addressObj =  AddressUtility.fromGeocode(addressString);
       return addressObj
  }

  const callrequestToJoin = async () => {
    var commuteDetails = commuteProfile.commute;
    var homeAddress = await getAddressUtility(commuteDetails.homeAddress);
    var workAddress = await getAddressUtility(commuteDetails.workAddress);
    var commuteDays = await getCommuteDays(commuteDetails.activeDays);

    let requestObj = {
      "firstName": profile.firstName,
      "lastName": profile.lastName,
      "emailAddress": userProfile.email,
      "employerId": null,
      "employerName": commuteDetails.employer,
      "homeAddress" : homeAddress,
      "workAddress" : workAddress,
      "phone" : profile.phone,
      "workArrivalTime": commuteDetails.arriveAtWork,
      "workDepartureTime": commuteDetails.departFromWork,
      "isWorkArriveTimeFlexible": commuteDetails.arriveFlexible,
      "isWorkDepartTimeFlexible": commuteDetails.departFlexible,
      "commuteDays" :commuteDays,
      "vanpoolMatch" : []
  }
    requestToJoin(requestObj)
  }
  return (
    <Section title="Vanpool" Icon={Vanpool}>
      <div className="upper">
        {showVan ? <img src={Van} alt="van" /> : null}
        <div className="no-vanpool">
          You are currently <span>{profile.isInVanpool ? '' : 'not '}</span>{' '}
          part of a commute.
          {showVan ? (
            <Link to="/link-vanpool" className="link">
              Link My Commute
            </Link>
          ) : null}
        </div>
      </div>
      { !profile.isRecentLeadSubmitted ? 
      <div className="lower">
        {requestResult ? (
          <div className="request-result">
            Thanks!{' '}
            <span>
              We've got all your information and will be reaching out shortly to
              find you the perfect Commute.
            </span>
          </div>
        ) : (
          <span>
            <div>
              <div className="search">
                There are <span className="result">{matches}</span> Commute
                vanpools available that match your current Commute Profile.
              </div>
              <div className="more-info">
                Would you like an Enterprise associate to reach out to you?
              </div>
            </div>
            <button className="button request" onClick={() => callrequestToJoin()}>
              Contact Me
            </button>
          </span>
        )}
      </div>

      :
      ""
        }
    </Section>
  );
}

function mapStateToProps(state) {
  return {
    requestResult: state.findARide.requestToJoin,
    matches: state.findARide.count,
    profile: state.userAuth,
    commuteProfile : state.commuteProfile,
    userProfile: state.profile
  };
}

function mapDispatchToProps(dispatch) {
  dispatch({ type: actionTypes.VANPOOL_SEARCH_COUNT });

  return {
    requestToJoin(request) {
      dispatch({ type: actionTypes.REQUEST_TO_JOIN, data:request });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindARide);
