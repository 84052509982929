import React from 'react';
import StateDropDown from './StateDropDown';
import FancyInput from '../FancyInput';

const validateLicenseNumber = value =>
  !value || /^[A-Z0-9]{1,19}$/i.test(value);

export default function DriversLicenseInput({ value, onChange }) {
  const { licenseNumber = '', licenseState = '' } = value;

  return (
    <div className="drivers-license">
      <FancyInput
        type="text"
        value={licenseNumber}
        className={`edit-license ${licenseNumber ? '' : 'invalid'}`}
        placeholder="License Number"
        maxLength="19"
        onChange={e =>
          validateLicenseNumber(e.target.value) &&
          onChange({ licenseNumber: e.target.value, licenseState })
        }
      />
      {!licenseNumber ? (
        <span className="license-invalid">
          Please enter a valid license number.
        </span>
      ) : null}
      <label htmlFor="stateDropDown" className="attach">
        State
      </label>
      <StateDropDown
        value={licenseState}
        disabled={false}
        onChange={e =>
          onChange({ licenseNumber, licenseState: e.target.value })
        }
      />
    </div>
  );
}
