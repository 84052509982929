import React from 'react';

export default function Cell({ size = 'half', label, value }) {
  return (
    <div className={size}>
      <div className="label">{label}</div>
      <div className="value">{value}</div>
    </div>
  );
}
