import * as actionTypes from '../redux/actionTypes';

const initialState = {
  currentAggregate: {},
  impactData: {
    employees: {},
    vanpoolers: {},
    privateSubsidies: {},
    transitSubsidies: {}
  },
  chartData: false,
  monthlyEnrollment: {
    participants: {
      labels: [],
      datasets: []
    },
    vehicles: {
      labels: [],
      datasets: []
    },
    occupancy: {
      labels: [],
      datasets: []
    }
  },
  tripData: {
    commutePrimaryModes: {
      labels: [],
      datasets: []
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.STATS_AGGREGATE_CHANGED:
      return {
        ...state,
        ...initialState,
        currentAggregate: { ...action.data },
        error: false,
        csvLoaded: false
      };
    case actionTypes.STATS_IMPACT_DATA_LOADED:
      return {
        ...state,
        impactData: { ...action.data },
        error: false,
        csvLoaded: false
      };
    case actionTypes.STATS_MONTHLY_ENROLLMENT_LOADED:
      return {
        ...state,
        monthlyEnrollment: { ...action.data },
        chartData: true,
        error: false,
        csvLoaded: false
      };
    case actionTypes.STATS_MONTHLY_ENROLLMENT_LOADING:
      return {
        ...state,
        monthlyEnrollment: { ...initialState.monthlyEnrollment },
        chartData: false,
        error: false,
        csvLoaded: false
      };
    case actionTypes.STATS_CSV_DATA_LOADING:
      return { ...state, error: false, csvLoaded: false };
    case actionTypes.STATS_CSV_DATA_LOADED:
      return { ...state, ...action.data, error: false, csvLoaded: true };
    case actionTypes.STATS_CSV_DATA_ERROR:
      return { ...state, csvLoaded: true, error: true };
    case actionTypes.STATS_TRIPDATA_LOADING:
      return {
        ...state,
        tripData: { ...initialState.tripData },
        chartData: false,
        error: false,
        csvLoaded: false
      };
    case actionTypes.STATS_TRIPDATA_LOADED:
      return {
        ...state,
        tripData: { ...action.data },
        chartData: true,
        error: false,
        csvLoaded: false
      };
    default:
      return state;
  }
};
