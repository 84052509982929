export default function EditCommuteDays(props) {
  const { selectedDays, openEditCommuteModal, isDesktop} = props;
  return (
      <div>
        <div role='group' className= {isDesktop ? 'commute-updated-container desktop' : 'commute-updated-container mobile'}>
          <div className='heading'>Selected Commute days</div>
          <div className='section'>
            <div className='left'>
              {selectedDays.map((day) => <div className='block' aria-label={day} role='text' key={day}>{day.substr(0, 3)}</div>)}
            </div>
            <button onClick={openEditCommuteModal} className='edit-button'>edit</button>
          </div>
        </div>
      </div>
  );
}