import React from 'react';
import FieldInfo from '../../../shared/FieldInfo';

const NAME_LIMIT = 70;

export default function({ name, updateState, inputRef }) {
  const message =
    'Create a nickname for your recurring payment.' +
    ' For example, Commute Monthly Invoice or Vanpool Gas.' +
    " This description is for your reference only and will not be displayed on your vanpool's invoice";

  return (
    <div className="payment-name section">
      <h3>
        Enter Payment Name <FieldInfo message={message} />
      </h3>
      <input
        className="name"
        value={name}
        onChange={e => updateName(e.target.value, { updateState })}
        onBlur={e => updateName(e.target.value.trim(), { updateState })}
        placeholder="Payment Name"
        ref={inputRef}
      />
    </div>
  );
}

function updateName(value, props) {
  value = value.replace(/^\s/, '').replace(/\s\s+/, ' ');
  if (value.length <= NAME_LIMIT) {
    return props.updateState({ name: value });
  }
}
