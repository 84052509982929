import { isEqual } from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { GET_VANPOOL_INFO, GET_VANPOOL_PARTICIPANTS, SET_REFRESH_DASHBOARD, UPDATE_USER_PROFILE_DATA } from '../../redux/actionTypes';
import Loading from '../../shared/loading/Loading';
import { initialJoinCommuteState } from '../JoinCommuteReducer';
import OnboardingSlider from './OnboardingSlider';
import analyticsService from '../../shared/analytics/analytics-service';

function OnboardingPage(props) {

  useEffect(() => {
    if (isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    } else {
      props.getVanpoolInfo(props.history);
      props.getVanpoolParticipants(props.history);
      props.updateProfileData();
      props.setRefreshDashboard(true);
      analyticsService.pageInfo("customer onboarding");
    }
  }, []);

  function goToDashboard() {
    props.history.push('/dashboard');
    analyticsService.analyticsProcessEvent({
      "event": "add_profile_picture_click_done",
      "context": {
        "event_action": "done"  
      }
    });
  }

  return (
    <div className='onboarding-page'>
      <span aria-live='polite' role='alert'>
        <Loading isLoading={!props.vanInfoLoaded || !props.participantsInfoLoaded} />
      </span>
      <div className='onboarding-container'>
        <OnboardingSlider isPredelivery={props.isPredelivery} goToDashboard={goToDashboard} history={props.history} isDoneDisabled={(!props.uploadedImage && !props.uploadErrorOccurred)}/>
      </div>
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    getVanpoolInfo(history) {
      dispatch({ type: GET_VANPOOL_INFO, history })
    },
    getVanpoolParticipants(history) {
      dispatch({ type: GET_VANPOOL_PARTICIPANTS, history })
    },
    setRefreshDashboard(refresh) {
      dispatch({ type: SET_REFRESH_DASHBOARD, refresh})
    },
    updateProfileData(){
      dispatch({type: UPDATE_USER_PROFILE_DATA});
    }
  }
}

function mapStateToProps(state) {
  return {
    joinCommuteData: state?.joinCommute,
    vanInfoLoaded: state?.joinCommute?.vanInfoLoaded,
    participantsInfoLoaded: state?.joinCommute?.participantsInfoLoaded,
    uploadedImage: state?.joinCommute?.profile?.image,
    uploadErrorOccurred: state?.joinCommute?.profile?.uploadErrorOccurred,
    isPredelivery: state?.joinCommute?.selectedVan?.isPredelivery,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);