import {useState} from 'react';
import Collapsible from 'react-collapsible';
import {getCardInfo,getCumulativeCost,makeDecimal2numbers} from '../../helper';
import { numberWithCommas } from '../../../join-commute/helpers/commonHelper';

export default function PaymentOverviewCollapsible(props) {
  const [isOpen,setIsOpen] = useState(false);

  function Heading() {
    var tempClass = 'arrow ';
    tempClass += isOpen ? 'down' : 'right';
    const cost = getCumulativeCost(props.data.paymentList,'totalCharges')
    return (
      <div className='collapsible-heading-container' role='button' aria-controls={`index${props.index}`} aria-expanded={isOpen} tabIndex={0}>
        <div>
          <div className='heading1'><span className='month-heading'>{props.data.groupTitle}</span>
          </div>
        </div>
        <div className='details'>
          <div className='price-group' aria-label={`${cost.price} dollars and ${cost.decimal} cents`}>
            <span className='dollar'>$</span>
            <span className='price'>{numberWithCommas(cost.price)}.</span>
            <span className='decimal'>{cost.decimal}</span>
          </div>
          <i className={tempClass}></i>
        </div>
      </div>
    );
  }
  return (
    <Collapsible open={isOpen} className='large-collapsible-container' handleTriggerClick={() => {setIsOpen(!isOpen)}} trigger={Heading()}>
      <div className='collapsible-content' id={`index${props.index}`} role='group' aria-hidden={!isOpen}>
        {props.data && props.data.paymentList && props.data.paymentList.map((transaction,index) =>
          <div className='row-container' key={index}>
            <div className='row'>
              <div className='card-info'>{getCardInfo(transaction.cardType, 'desktop')}</div>
              <div className='price-value' aria-label={`${makeDecimal2numbers(transaction.totalCharges)} dollars`}>
                {
                  transaction.totalCharges  > 0  ? "-" : null
                }
                ${makeDecimal2numbers(numberWithCommas(Math.abs(transaction.totalCharges)))}</div>
            </div>
            <hr></hr>
          </div>
        )}
      </div>
    </Collapsible>
  );
}