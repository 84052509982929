import Line from '../../../_styles/images/Line.png';
import TripOrigin from '../../../_styles/images/TripOrigin.png';
import LocationGreen from '../../../_styles/images/LocationGreen.png';
import dateUtility from '../../../shared/dateUtility';

export function RouteDetails(props) {
  return (
    <div className="van-route-details">
      <div className="route-icon" aria-hidden='true'>
        <img className="group" src={TripOrigin} />
        <img className="line" src={Line} />
        <img className="LocationGreen" src={LocationGreen} />
      </div>
      <div className="route-address" role='group'>
        <div className="origin-address" role='group'>
          <div className="start-address" aria-label={`My commute, start address, ${props.vanpool.originAddress}`}>{props.vanpool.originAddress}</div>
          <div className="start-timings">{`Pickup: ${props.vanpool.matchedOriginStop && props.vanpool.matchedOriginStop.departureTimestamp && dateUtility.parseTime(props.vanpool.matchedOriginStop.departureTimestamp)} - Arrive: ${props.vanpool.matchedDestinationStop && props.vanpool.matchedDestinationStop.arrivalTimestamp && dateUtility.parseTime(props.vanpool.matchedDestinationStop.arrivalTimestamp)}`}</div>
        </div>
        <div className="destination-address" role='group'>
          <div className="end-address" aria-label={`end address, ${props.vanpool.destinationAddress}`}>{props.vanpool.destinationAddress}</div>
          <div className="end-timings">{`Depart: ${props.vanpool.matchedDestinationStop && props.vanpool.matchedDestinationStop.departureTimestamp && dateUtility.parseTime(props.vanpool.matchedDestinationStop.departureTimestamp)} - Dropoff: ${props.vanpool.matchedOriginStop && props.vanpool.matchedOriginStop.arrivalTimestamp && dateUtility.parseTime(props.vanpool.matchedOriginStop.arrivalTimestamp)}`}</div>
        </div>
      </div>
    </div>
  );
}