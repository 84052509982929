import React, { useEffect, useRef, useState } from 'react';
import Van from '../../_styles/images/SUV.svg';
import Close from '../../_styles/images/close-icon-green.svg';
import { getDaysForAnalytics } from '../../shared/analytics/analytics-helpers';
import analyticsService from '../../shared/analytics/analytics-service';


export default function PreferredVanpoolPopup(props) {
    const node = useRef();
    const [ridedays, setRidedays] = useState([]);
    const [hasAnalyticsBeenCalled, setHasAnalyticsBeenCalled] = useState(false);

    useEffect(() => {
        if (props && props.inviteDetails && props.inviteDetails.rosterDays) {
            const rosterDays = props.inviteDetails.rosterDays;
            const rideDaysFound = Object.keys(rosterDays).filter(d => rosterDays[d]);
            setRidedays(rideDaysFound);
        }
    }, [props])

    useEffect(() => {
        if (ridedays && ridedays.length > 0 && !hasAnalyticsBeenCalled) {
            const days = getDaysForAnalytics(ridedays);
            analyticsService.analyticsProcessEvent({
                "event": "view_pre_selected_van_modal",
                "context": {
                    "event_action": "pre-selected van modal",
                    "event_detail": {
                        "days_selected": days
                    }
                }
            })
            setHasAnalyticsBeenCalled(true);
        }
    }, [ridedays, hasAnalyticsBeenCalled]);

    function updatePVPKeydown(e) {
        if (e.which === 13) {
            props.updatePDPopup(false)
        }
    }
    
    const close = (e) => {
        if (e.keyCode === 27) {
            const changesPopup = document.getElementById('preferred-vanpool-popup');
            if (changesPopup) {
                props.updatePDPopup(false);
            }
        }
    }

    useEffect(() => {
        document.querySelector('.preferred-container') && document.querySelector('.preferred-container').focus();
        document.querySelector('.update-commute') && document.querySelector('.update-commute').setAttribute('aria-hidden', 'true');
        document.querySelector('.exactMatch') && document.querySelector('.exactMatch').setAttribute('aria-hidden', 'true');
        document.querySelector('.matched-van-container') && document.querySelector('.matched-van-container').setAttribute('aria-hidden', 'true');
        window.addEventListener('keydown', close)
        return () => {
            window.removeEventListener('keydown', close);
            document.querySelector('.update-commute') && document.querySelector('.update-commute').setAttribute('aria-hidden', 'false');
            document.querySelector('.exactMatch') && document.querySelector('.exactMatch').setAttribute('aria-hidden', 'false');
            document.querySelector('.matched-van-container') && document.querySelector('.matched-van-container').setAttribute('aria-hidden', 'false');
        }
    }, [])

    return (
        <div className="charges-popup show" id='preferred-vanpool-popup' aria-live='polite' role='alert' aria-modal='true' ref={node} tabIndex={0}>
            <div className='preferred-container'>
                <div className="preferred-vanpool-container">
                    <div className='top-header'>
                        <button
                            role='button'
                            className="close traditional"
                            onClick={() => props.updatePDPopup(false)}
                            onKeyDown={updatePVPKeydown}
                            tabIndex={0}
                            aria-label="Close popup"
                        >
                            <img src={Close} alt='Close' />
                        </button>

                    </div>
                    <div className="sp-header">

                        <div className='header'>
                            <div className='van-image'>
                                <img src={Van} alt='van' />
                            </div>
                            <div className='heading'>
                                We have pre-selected a van for you
                            </div>
                        </div>
                    </div>
                    <div className='preferred-vanpool-popup-container'>
                        <div className='content'>
                            <div className='first-section'>
                                The selected days for your commute are
                            </div>
                            <div className='middle-section'>
                                <div className='ride-days'>
                                    {
                                        ridedays.map((day, i) => (
                                            <div key={i} className='ride-day'>{day.substr(0, 3)} </div>
                                        ))
                                    }

                                </div>
                            </div>
                            <div className='last-section'>
                                You can update your days and other search criteria under the <span>Edit my commute </span>section
                            </div>
                        </div>

                    </div>
                    <div className='button-container'>
                        <button tabIndex={0} className='continue-button' onClick={() => props.updatePDPopup(false)} onKeyDown={updatePVPKeydown}>Close</button>
                    </div>

                </div>
            </div>
        </div>
    );
}
