import React from 'react';
import Profile from '../../shared/profile-image';
import { Link } from 'react-router-dom';
import Alerts from '../../alerts/Alerts';

export default function ProfileNav({
  dropDownOpen,
  onClick,
  firstName,
  lastName,
  override: hideNavigation,
  className,
  profileImage
}) {
  let classForProfile = dropDownOpen ? 'active' : '';
  let name = !firstName && !lastName ? 'N/A' : `${firstName} ${lastName}`;
  return (
    <div className={className + ' profile-div'}>
    {hideNavigation ? null : <Alerts />}
      <button
        className={`profile-image ${classForProfile}`}
        id="profile-icon"
        aria-label="profile menu"
        onClick={onClick}
        aria-haspopup="true"
        aria-expanded={dropDownOpen}
      >
        <Profile profileImage={profileImage}>{`${name}`}</Profile>
      </button>
      {/* <Link
        className="upper-nav profile-link mobile-only"
        replace={'#/profile' === window.location.hash}
        to="/profile"
      >
        <button
          className={`profile-image ${classForProfile}`}
          id="profile-icon"
          onClick={onClick}
        >
          <Profile>{`${name}`}</Profile>
          <span>{`${name}`}</span>
        </button>
      </Link> */}
    </div>
  );
}
