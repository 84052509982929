import React from 'react';
import { Bar } from 'react-chartjs-2';
import chartService, {
  standardBarOptions,
  percentageBarOptions,
  legendOptions,
  GRID_TYPE_PERCENTAGE,
  GRID_TYPE_COMMUTE,
  commuteBarOptions
} from './chart-service';

export default function StandardBarGraph({ gridData, displayType, height }) {
  let options = standardBarOptions;
  switch (displayType) {
    case GRID_TYPE_PERCENTAGE:
      options = percentageBarOptions;
      break;
    case GRID_TYPE_COMMUTE:
      options = commuteBarOptions;
      break;
    default:
      break;
  }
  return (
    <Bar
      height={height}
      data={chartService.formatVanpoolBarChart(gridData)}
      legend={legendOptions}
      options={options}
    />
  );
}
