import PaymentCard from '../../../../payment/common/paymentCard';

export default function SummaryPaymentCards(props) {
  return (
    <div className='card-list' role='group'>
      <div className='sub-heading'>
        <div>Payment Card</div>
        <div className='side'>
          Recurring charges
        </div>
      </div>
      <div className='card-list-container'>
        {props.cards &&
          props.cards.map(card => <PaymentCard key={card.partialAccountNumber} card={card} />)}
      </div>
    </div>
  )
}