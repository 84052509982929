const chartService = {};

export const GRID_LABEL_TOTAL = 'Total';
export const GRID_LABEL_GOAL = 'Goal';
export const GRID_LABEL_POTENTIAL = 'Potential';
export const GRID_LABEL_NTD_DATA = 'NTD Data';
export const GRID_LABEL_NTD_VANPOOLS = 'NTD Vanpools';

export const GRID_TYPE_PERCENTAGE = 'percentage';
export const GRID_TYPE_COMMUTE = 'commute';

export const maxStep = datasets => {
  const max = Math.ceil(
    datasets.reduce((max, dataset) => Math.max(max, ...dataset.data), 0)
  );
  return Math.ceil(max + 1);
};

export const standardLineOptions = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          fontStyle: 'bold',
          min: 0,
          callback: function(label) {
            return label.toLocaleString();
          }
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          fontStyle: 'bold'
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      title: function(tooltipItem, data) {
        return data['labels'][tooltipItem[0]['index']];
      },
      label: function(tooltipItem, data) {
        const output = [];
        let total = null;
        data['datasets'].forEach(dataset => {
          if (dataset.label === 'Total') {
            total = dataset.data[tooltipItem['index']].toLocaleString();
          }
        });
        data['datasets'].forEach(dataset => {
          if (dataset.label === 'Potential' || dataset.label === 'Goal') {
            output.push(
              `${dataset.data[
                tooltipItem['index']
              ].toLocaleString()}     ${Math.floor(
                (total / dataset.data[tooltipItem['index']]) * 100
              )}%`
            );
          } else {
            output.push(dataset.data[tooltipItem['index']].toLocaleString());
          }
          output.push(dataset.label);
          output.push('');
        });
        return output.reverse();
      }
    },
    backgroundColor: '#049b5c',
    titleFontSize: 25,
    titleFontColor: '#FFFFFF',
    bodyFontColor: '#FFFFFF',
    bodyFontSize: 20,
    displayColors: false,
    xPadding: 30,
    yPadding: 10
  }
};

export const percentageLineOptions = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [
      {
        gridLines: {
          display: false
        },
        ticks: {
          fontStyle: 'bold',
          min: 0,
          callback: function(value) {
            return value.toLocaleString() + '%';
          }
        }
      }
    ],
    xAxes: [
      {
        ticks: {
          fontStyle: 'bold'
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      title: function(tooltipItem, data) {
        return data['labels'][tooltipItem[0]['index']];
      },
      label: function(tooltipItem, data) {
        const output = [];
        data['datasets'].forEach(dataset => {
          output.push(
            `${dataset.data[tooltipItem['index']].toLocaleString()}%`
          );
          output.push(dataset.label);
          output.push('');
        });
        return output.reverse();
      }
    },
    backgroundColor: '#049b5c',
    titleFontSize: 25,
    titleFontColor: '#FFFFFF',
    bodyFontColor: '#FFFFFF',
    bodyFontSize: 20,
    displayColors: false,
    xPadding: 30,
    yPadding: 10
  }
};

export const standardBarOptions = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [
      {
        ticks: {
          fontStyle: 'bold',
          min: 0,
          callback: function(label) {
            return label.toLocaleString();
          }
        }
      }
    ],
    xAxes: [
      {
        maxBarThickness: 40,
        gridLines: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    ]
  },

  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        return `  ${
          data.datasets[tooltipItem['datasetIndex']].label
        }: ${data.datasets[
          tooltipItem['datasetIndex']
        ].data[0].toLocaleString()}`;
      }
    },
    bodyFontSize: 20,
    displayColors: true,
    xPadding: 30,
    yPadding: 10
  }
};

export const commuteBarOptions = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [
      {
        ticks: {
          fontStyle: 'bold',
          min: 0
        }
      }
    ],
    xAxes: [
      {
        maxBarThickness: 40,
        gridLines: {
          display: false
        },
        ticks: {
          fontStyle: 'bold'
        }
      }
    ]
  },

  tooltips: {
    callbacks: {
      title: function() {},
      label: function(tooltipItem, data) {
        return `${data.datasets[tooltipItem['datasetIndex']].data[
          tooltipItem['index']
        ].toLocaleString()}`;
      }
    },
    bodyFontSize: 20,
    displayColors: false,
    xPadding: 30,
    yPadding: 10
  }
};

export const percentageBarOptions = {
  maintainAspectRatio: true,
  scales: {
    yAxes: [
      {
        ticks: {
          fontStyle: 'bold',
          min: 0,
          callback: function(value) {
            return value.toLocaleString() + '%';
          }
        }
      }
    ],
    xAxes: [
      {
        maxBarThickness: 40,
        gridLines: {
          display: false
        },
        ticks: {
          display: false
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        return `  ${
          data.datasets[tooltipItem['datasetIndex']].label
        }: ${data.datasets[
          tooltipItem['datasetIndex']
        ].data[0].toLocaleString()}%`;
      }
    },
    bodyFontSize: 20,
    displayColors: true,
    xPadding: 30,
    yPadding: 10
  }
};

export const legendOptions = {
  display: false,
  labels: {
    usePointStyle: true
  }
};

export const gridFormat = {
  'NTD Data': '#fa6f01',
  'NTD Vanpools': '#b8e986',
  Total: '#5e83ad',
  Goal: '#a5b0ba',
  Potential: '#130c0e'
};

chartService.formatVanpoolLineChart = gridData => {
  const standardFields = {
    fill: false,
    lineTension: 0,
    backgroundColor: 'white',
    borderWidth: 4,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: 'white',
    pointBorderWidth: 4,
    pointHoverRadius: 8,
    pointHoverBackgroundColor: 'white',
    pointHoverBorderWidth: 4,
    pointRadius: 8,
    pointHitRadius: 10
  };

  const updatedDatasets = gridData.datasets.map(dataset => {
    const customFormat = {
      borderColor: gridFormat[dataset.label],
      pointBorderColor: gridFormat[dataset.label],
      pointHoverBorderColor: gridFormat[dataset.label]
    };

    return { ...dataset, ...standardFields, ...customFormat };
  });

  gridData.datasets = updatedDatasets;
  return gridData;
};

chartService.formatVanpoolBarChart = gridData => {
  const updatedDatasets = gridData.datasets.map(dataset => {
    const customFormat = {
      backgroundColor: gridFormat[dataset.label] || '#5e83ad'
    };
    return { ...dataset, ...customFormat };
  });
  gridData.datasets = updatedDatasets;
  return gridData;
};

chartService.generateLegend = gridData => {
  return gridData.datasets.map(dataset => {
    return {
      label: dataset.label,
      color: gridFormat[dataset.label]
    };
  });
};

export default chartService;
