import Http from './http-service';
import config from '../appConfig';

const logger = {};

logger.log = args => {
  if (args instanceof Error) {
    console.log(args.stack);
  } else {
    console.log(args);
  }
};

logger.serverLog = data => {
  Http.post(`${config.kongApiBaseUrl}/user/logger`, data).catch(ex => {
    return ex;
  });
};

logger.middleware = store => next => action => {
  console.log('dispatching', action);
  console.log('store', store.getState().joinCommute);
  return next(action);
};

export default logger;
