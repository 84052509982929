import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import dateUtility from '../../shared/dateUtility';
import currencyFormatter from '../../shared/currencyFormatter';
import { Link } from 'react-router-dom';
import { isAllowed, RestrictFrom } from '../../shared/permissions/RestrictTo';
import {
  PERMISSION_COORDINATOR,
  PERMISSION_BILLING_VIEWER,
  PERMISSION_IPB,
  PERMISSION_RIDER,
  PERMISSION_ACCOUNT_DASHBOARD_ADMIN
} from '../../shared/permissions/permissions';
import LastPayment from '../../payment-pay/payment-form/components/LastPayment';

const Green = ({ children }) => <span className="green">{children}</span>;

function RecurringPaymentMessage({ recurringPayments }) {
  if (recurringPayments.length > 1) {
    return (
      <Link className="payment-recurring" to="/payment/history/payments">
        Multiple recurring payments are scheduled for{' '}
        {recurringPayments[0].month}. <Green>Click to view</Green>
      </Link>
    );
  } else if (recurringPayments.length === 1) {
    return (
      <Link className="payment-recurring" to="/payment/history/payments">
        A recurring payment of{' '}
        <b>{currencyFormatter.formatUSD(recurringPayments[0].amount)}</b> is
        scheduled for {recurringPayments[0].month} {recurringPayments[0].every}.{' '}
        <Green>Click to view</Green>
      </Link>
    );
  }
  return (
    <Link className="payment-recurring" to="/payment/history/payments">
      No recurring payments are scheduled
    </Link>
  );
}

function RiderPayment({ recurringPayments }) {
  return (
    <div className="vanpool-payment-summary solid">
      <div className="tablet-left">
        <div className="payment-group">
          <LastPayment />
          <Link className="primary button" to="/payment">
            Payments
          </Link>
        </div>
        <RecurringPaymentMessage recurringPayments={recurringPayments} />
      </div>
    </div>
  );
}

function RiderPaymentAllocation({ recurringPayments, allocation }) {
  const { billingDate, amount } = allocation;

  const dueDate =
    billingDate !== 'N/A'
      ? dateUtility.parseDateAsMonthDayOrdinal(billingDate).toUpperCase()
      : billingDate;
  const formattedCurrentBalance = currencyFormatter.formatUSD(amount);
  return (
    <div className="vanpool-payment-summary solid">
      <div className="tablet-right">
        <div className="payment-due-date">PAYMENT REQUEST DUE {dueDate}</div>
        <div className="payment-balance-due">{formattedCurrentBalance}</div>
        <div className="tools">
          <Link className="button split" to="/payment/pay">
            Pay Now
          </Link>
          <Link className="primary button" to="/payment">
            Payments
          </Link>
        </div>
      </div>
      <div className="tablet-left">
        <div className="payment-group">
          <LastPayment />
        </div>
        <RecurringPaymentMessage recurringPayments={recurringPayments} />
      </div>
    </div>
  );
}

function BillingViewerPayment({ recurringPayments, paymentSummary }) {
  const invoiceDueDate = paymentSummary.invoiceDueDate;

  const dueDate =
    invoiceDueDate !== 'N/A'
      ? dateUtility.parseDateAsMonthDayOrdinal(invoiceDueDate).toUpperCase()
      : invoiceDueDate;
  const balance = paymentSummary.currentAccountBalance ==='N/A' ? "$0.00" : paymentSummary.currentAccountBalance;
  return (
    <div className="vanpool-payment-summary solid">
      <div className="tablet-right">
        <div className="payment-due-date">NEXT PAYMENT DUE {dueDate}</div>
        <div className="payment-balance-due">{balance}</div>
        <div className="tools">
          <Link className="button split" to="/payment/manage">
            Split Bill
          </Link>
          <Link className="primary button" to="/payment">
            Payments
          </Link>
        </div>
      </div>
      <RestrictFrom
        roles={[PERMISSION_ACCOUNT_DASHBOARD_ADMIN]}
        required={false}
      >
        <div className="tablet-left">
          <div className="payment-group">
            <LastPayment />
          </div>
          <RecurringPaymentMessage recurringPayments={recurringPayments} />
        </div>
      </RestrictFrom>
    </div>
  );
}

class PaymentSummary extends Component {
  componentDidMount() {
    this.props.init();
  }

  render() {
    const { amount } = this.props.allocation;
    const isRiderNotBillingViewer =
      isAllowed([PERMISSION_RIDER]) && !isAllowed([PERMISSION_BILLING_VIEWER]);

    if (
      amount !== 0 &&
      isRiderNotBillingViewer &&
      !isAllowed([PERMISSION_IPB])
    ) {
      return <RiderPaymentAllocation {...this.props} />;
    }

    if (isAllowed([PERMISSION_IPB]) || isRiderNotBillingViewer) {
      return <RiderPayment {...this.props} />;
    }

    return <BillingViewerPayment {...this.props} />;
  }
}

function mapStateToProps(state) {
  return {
    paymentSummary: state.dashboardPaymentSummary,
    recurringPayments: state.scheduledPayments.scheduled,
    allocation: state.paymentManagement
  };
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      if (
        !isAllowed([PERMISSION_IPB]) &&
        isAllowed([
          PERMISSION_COORDINATOR,
          PERMISSION_BILLING_VIEWER,
          PERMISSION_ACCOUNT_DASHBOARD_ADMIN
        ])
      ) {
        dispatch({ type: actionTypes.BALANCE_DETAILS_LOADING });
      }
      if (!isAllowed([PERMISSION_IPB]) && isAllowed([PERMISSION_RIDER])) {
        dispatch({ type: actionTypes.PAYMENT_ALLOCATION_LOADING });
      }

      dispatch({ type: actionTypes.SCHEDULED_PAYMENTS_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSummary);
