import React, { Component } from 'react';
import { connect } from 'react-redux';
import { User, CreditCard, Car, Vanpool, Bell, Lock } from '../shared/icons';
import * as actionTypes from '../redux/actionTypes';
import Loading from '../shared/loading/Loading';
import ScrollLink, { scrollIntoView } from '../shared/ScrollLink';
import PersonalInfo from './PersonalInfo';
import AzureSettings from './AzureSettings';
import CommuteSection from '../commute-profile/CommuteInfo';
import VanpoolInfo from './VanpoolInfo';
import PaymentInfo from './PaymentInfo';
import DriverSection from './DriverSection';
import Preferences from './Preferences';
import _ from 'lodash';
import { FeatureToggle } from '../shared/feature-toggles';
import appConfig from '../appConfig';
import { isAllowed, RestrictFrom } from '../shared/permissions/RestrictTo';
import {
  PERMISSION_REGISTERED_USER,
  PERMISSION_TRIP_RECORDING,
  PERMISSION_ACCOUNT_DASHBOARD_USER
} from '../shared/permissions/permissions';

class Profile extends Component {
  componentDidMount() {
    if (appConfig.toggleFeatures.profile) {
      this.props.init();
    }
  }

  componentWillReceiveProps(props) {
    if (!props.profile.loading && !this.scrolled) {
      const route =
        '.' +
        this.props.location.pathname
          .replace(this.props.match.path, '')
          .substr(1);
      setTimeout(() => scrollIntoView(route), 100);
      this.scrolled = true;
    }
  }

  render() {
    const hasRegisteredUserNoTrip =
      isAllowed([PERMISSION_REGISTERED_USER]) &&
      !isAllowed([PERMISSION_TRIP_RECORDING]);

    return (
      <div className={`profile ${hasRegisteredUserNoTrip ? 'no-trip' : ''}`}>
        <FeatureToggle name="profile">
          <Loading isLoading={this.props.profile.loading} />
          <div className="sidebar">
            <ScrollLink selector=".profile-info">
              <User /> My Profile
            </ScrollLink>
            {!this.props.authorization.isFederatedLogin ? (
            <ScrollLink selector=".azure-settings">
              <Lock /> Password
            </ScrollLink>
            ) : null }
            <ScrollLink selector=".commute-info">
              <User /> Commute Profile
            </ScrollLink>
            <ScrollLink selector=".vanpool-info">
              <Vanpool /> Vanpool
            </ScrollLink>
            {this.props.authorization.isInVanpool ? (
              <ScrollLink className="driver-info-link" selector=".driver-info">
                <Car /> Driver Information
              </ScrollLink>
            ) : null}
            <ScrollLink selector=".preferences">
              <Bell /> Notification Preferences
            </ScrollLink>
            <RestrictFrom
              roles={[
                PERMISSION_REGISTERED_USER,
                PERMISSION_ACCOUNT_DASHBOARD_USER
              ]}
              required={false}
            >
              <ScrollLink selector=".payment-info">
                <CreditCard /> Card Wallet
              </ScrollLink>
            </RestrictFrom>
          </div>
          <div className="body">
            <PersonalInfo {...this.props.profile} />
            {!this.props.authorization.isFederatedLogin ? (
            <AzureSettings />
            ) : null }
            <CommuteSection />
            { !_.isEmpty(this.props.profile.vanpool) ? 
            <VanpoolInfo {...this.props.profile.vanpool} />
            : null}
            <RestrictFrom
              roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
              required={false}
            >
              <DriverSection {...this.props.profile} />
            </RestrictFrom>
            <Preferences preferences={this.props.profile.preferences} />
            <RestrictFrom
              roles={[PERMISSION_ACCOUNT_DASHBOARD_USER]}
              required={false}
            >
              <PaymentInfo cards={this.props.cards} />
            </RestrictFrom>
          </div>
        </FeatureToggle>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
    cards: state.payments.cards,
    authorization: state.userAuth
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    init() {
      dispatch({ type: actionTypes.USER_PROFILE_LOADING });
      dispatch({ type: actionTypes.PAYMENT_METHODS_LOADING });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
