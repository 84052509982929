import React from 'react';
import InVanpool from './InVanpool';
import UserService from '../shared/user-service';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_REGISTERED_USER } from '../shared/permissions/permissions';

export default function() {
  return (
    <div className="registration">
      <InVanpool />
    </div>
  );
}

export const LinkVanpoolSecurityProvider = {
  isAuthorized: () =>
    UserService.isAuthenticated() && isAllowed([PERMISSION_REGISTERED_USER])
};
