import React from 'react';
import { Dollar } from '../../../shared/icons';
import DollarInput from '../../../shared/inputs/DollarInput';

export default function({ amount, updateState }) {
  return (
    <div className="payment-amount section">
      <h3>Enter Payment Amount</h3>
      <div className="icon-field">
        <Dollar />
        <DollarInput
          aria-label="Enter Payment Amount"
          aria-required="true"
          className="amount"
          value={amount}
          onChange={amount => updateState({ amount })}
        />
      </div>
    </div>
  );
}
