import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call, take, all } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import logger from '../../shared/logger';
import config from '../../appConfig';
import imageChecker from '../../shared/image-checker';

export default function*() {
  yield takeLatest(actionTypes.VANPOOL_ADDONS_LOADING, loadVanpoolAddOns);
  yield takeLatest(actionTypes.VANPOOL_ADDONS_ADD, add);
  yield takeLatest(actionTypes.VANPOOL_ADDONS_REMOVE, remove);
}

function* loadVanpoolAddOns() {
  try {
    const response = yield call(() =>
      Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/vehicle/addOns`)
    );

    const addOnsWithImagePath = yield all(
      response.addOns.map(addOn =>
        call(function*() {
          const isValidImage = yield call(() =>
            imageChecker.isValidImage(
              `${config.imagePath}/vehicle-addons/${addOn.addOnName}.svg`
            )
          );
          const addOnImagePath = isValidImage
            ? `${config.imagePath}/vehicle-addons/${addOn.addOnName}.svg`
            : `${config.imagePath}/vehicle-addons/null.svg`;
          return { ...addOn, addOnImage: addOnImagePath };
        })
      )
    );

    yield put({
      type: actionTypes.VANPOOL_ADDONS_LOADED,
      data: addOnsWithImagePath
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.VANPOOL_ADDONS_ERROR,
      data: { error: true }
    });
  }
}

function* add({ data }) {
  try {
    yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/vanpools/vanpool/vehicle/addOns/${data}`)
    );

    yield put({ type: actionTypes.VANPOOL_ADDONS_LOADING });
    yield take(actionTypes.VANPOOL_ADDONS_LOADED);
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data:
        'Added! An Enterprise representative will contact you within 24 hours with further details.'
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.DISPLAY_ERROR,
      data: 'Failed to add. Please call 1-800-VAN-4-WORK for assistance.'
    });
    yield put({
      type: actionTypes.VANPOOL_ADDONS_ERROR,
      data: { error: true }
    });
  }
}

function* remove({ data }) {
  try {
    yield call(() =>
      Http.delete(
        `${config.kongApiBaseUrl}/vanpools/vanpool/vehicle/addOns/${data}`
      )
    );

    yield put({ type: actionTypes.VANPOOL_ADDONS_LOADING });
    yield take(actionTypes.VANPOOL_ADDONS_LOADED);
    yield put({
      type: actionTypes.DISPLAY_SUCCESS,
      data:
        'Removed! Any equipment will need to be dropped off at an Enterprise office.'
    });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.DISPLAY_ERROR,
      data: 'Failed to remove. Please call 1-800-VAN-4-WORK for assistance.'
    });
    yield put({
      type: actionTypes.VANPOOL_ADDONS_ERROR,
      data: { error: true }
    });
  }
}
