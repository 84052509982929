import * as actionTypes from '../../redux/actionTypes';
import { takeLatest, put, call, select } from 'redux-saga/effects';
import Http from '../../shared/http-service';
import DateUtility from '../../shared/dateUtility';
import CurrnencyFormatter from '../../shared/currencyFormatter';
import logger from '../../shared/logger';
import appConfig from '../../appConfig';
import AccountSummaryService from '../../payment-account/account-summary-service';


export const invoiceHistoryDetailsSaga = function* () {
  yield takeLatest(
    actionTypes.INVOICE_HISTORY_DETAILS_LOADING,
    loadInvoiceHistoryDetails
  );
  yield takeLatest(actionTypes.LOAD_CURRENT_INVOICE, loadCurrentInvoice);
};

function* loadInvoiceHistoryDetails() {
  try {
    const invoiceDetails = yield call(() =>
      Http.get(
        `${appConfig.kongApiBaseUrl}/vanpools/vanpool/invoices/summary/details`
      )
    );
    const newInvoiceDetails = [...invoiceDetails.invoiceSummary];
    const transactionsFormated = yield call(() =>
      AccountSummaryService.getMonthlyInvoices(newInvoiceDetails)
    );
    yield put({
      type: actionTypes.INVOICE_HISTORY_DETAILS_LOADED,
      data: transactionsFormated
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.INVOICE_HISTORY_DETAILS_LOADING_ERROR, data: [], errorString: ex });
  }
}

function* loadCurrentInvoice(action) {
  try {
    const state = yield select();
    const presentInvoiceSummary = state.PaymentOverview && state.PaymentOverview.subscriptionSummary && state.PaymentOverview.subscriptionSummary.invoiceSummary;
    const invoiceMonth = presentInvoiceSummary.invoiceMonth;
    const invoiceYear = presentInvoiceSummary.invoiceYear;
    const invoiceDate = presentInvoiceSummary.invoiceDate;
    let transactionsFormated = [];
    if (state && state.invoiceHistory && state.invoiceHistory.invoiceSummary && state.invoiceHistory.invoiceSummary.length > 0) {
      transactionsFormated = state.invoiceHistory.invoiceSummary;
    } else {
      const invoiceDetails = yield call(() =>
        Http.get(
          `${appConfig.kongApiBaseUrl}/vanpools/vanpool/invoices/summary/details`
        )
      );
      const newInvoiceDetails = [...invoiceDetails.invoiceSummary];
      transactionsFormated = yield call(() =>
        AccountSummaryService.getMonthlyInvoices(newInvoiceDetails)
      );
    }
    const currentInvoiceSummary = transactionsFormated.filter(inv => inv.invoiceYear === invoiceYear && inv.monthNumber === invoiceMonth && inv.invoiceDate === invoiceDate);
    // for testing const currentInvoiceSummary = transactionsFormated.filter(inv => inv.invoiceYear === 2023 && inv.monthNumber === 1);
    if (currentInvoiceSummary && currentInvoiceSummary[0]) {
      action.history.push({ pathname: '/payment/summary/invoices/desktop-view', state: { invoiceId: currentInvoiceSummary[0].invoiceId, invoiceMonth: currentInvoiceSummary[0].invoiceMonth, invoiceYear: currentInvoiceSummary[0].invoiceYear } });
    }
    yield put({
      type: actionTypes.INVOICE_HISTORY_DETAILS_LOADED,
      data: transactionsFormated
    });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.INVOICE_HISTORY_DETAILS_LOADING_ERROR, data: [] });
  }
}


