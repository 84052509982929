import React from 'react';
import AlertIcon from '../../_styles/images/AlertIcon.png';
import CloseIcon from '../../_styles/images/delete.png';
import analyticsService from '../../shared/analytics/analytics-service';

export default function Banner(props) {
    const { banner, isDismissible, close } = props;

    function dismissBanner() {
        analyticsService.analyticsProcessEvent({
            "event": "alert interaction",
            "context": {
                "event_action": "dismiss",
                "alert_topic": `${banner.summary}`
            }
        });
        close(banner);
    }
    
    function createMarkup(description){
        return {__html: description};
    }

    return (
        <div className="notification-banner" role='group' key={banner.alertId}>
            <div className='main-content'>
                <div className='notification-icon'>
                    <img className='notification-alert-icon' alt='alert icon' src={AlertIcon} aria-hidden='true' />
                </div>
                <div className='notification-content'>
                    <div className='notification-header'>
                        {banner.summary}
                    </div>
                    <div className='notification-description' dangerouslySetInnerHTML={createMarkup(banner.description)}>
                        {/* {banner.description} */}
                    </div>

                </div>
            </div>
            {
                isDismissible ?
                    <div className='notification-dismiss'>
                        <img alt='close icon' src={CloseIcon} onClick={dismissBanner} role='button' />
                    </div>
                    :
                    null
            }
        </div>
    );
}