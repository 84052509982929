import React, { useState } from 'react';
import { TextImage } from '../../join-commute/onboarding/text-image/TextImage';
import { getTextForImage } from '../../join-commute/helpers/vanHelpers';

function DriverProfileImage({ driverInfo, classes }) {
    const [imgError, setImgError] = useState(false);

    const handleImageError = () => {
        setImgError(true);
    };

    return (
        <div className={classes ? classes : 'image'} aria-hidden="true" key={driverInfo.participantId}>
            {driverInfo.profileImage && !imgError ? (
                <img
                    className='profile-image'
                    src={`${driverInfo.profileImage}`}
                    alt={getTextForImage(driverInfo.firstName, driverInfo.lastName)}
                    onError={handleImageError}
                />
            ) : (
                <TextImage text={getTextForImage(driverInfo.firstName, driverInfo.lastName)} />
            )}
        </div>
    );
}

export default DriverProfileImage;
