import React from 'react';
import Callable from '../shared/Callable';
import logo from '../commute_logo_full.svg';
import logoutService from './logoutService';
import config from '../appConfig';

export default function Error() {
  return (
    <div className="login-error">
      <img src={logo} alt="Logo" className="branding" />
      <p>
        Sorry, Commute is currently experiencing technical issues. Please try
        back again later.
      </p>
      <p>
        If your need is more immediate, please call{' '}
        <Callable phone="800-VAN-4-WORK" />.
      </p>
      <button className="button primary" onClick={() => logoutService.logout()}>
        Try Again
      </button>
      {/* <iframe
        id="silentLogout"
        name="silentLogout"
        title="silentLogout"
        src={config.logoutUrl}
      ></iframe> */}
    </div>
  );
}
