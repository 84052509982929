import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import Banner from './Banner';


function NotificationBanner(props) {
  const [systemBanners, setSystemBanners] = useState([]);
  const [dismissibleBanners, setDismissibleBanners] = useState([]);


  useEffect(() => {
    if (props.alerts.length) {
      var systemBanners = props.alerts.filter((alert) => alert.priority == 1 && !alert.isRead)
      var sortedSystemBanners = getSortedBanners(systemBanners);
      setSystemBanners(sortedSystemBanners);
      var dismissibleBanners = props.alerts.filter((alert) => alert.priority == 2 && !alert.isRead)
      var sortedDismissibleBanners = getSortedBanners(dismissibleBanners);
      setDismissibleBanners(sortedDismissibleBanners);
    }
  }, [props.alerts]);

  const close = (alert) => {
    let unclosedAlerts = dismissibleBanners.filter(a => a.alertId !== alert.alertId);
    setDismissibleBanners(unclosedAlerts);
    props.readBanner(alert)
  }

  const getSortedBanners = (banners) => {
      var sortedbanners =  banners.sort((banner1, banner2) => {
          return new Date(banner2.createdDate) - new Date(banner1.createdDate);
      });
      return sortedbanners
  }

  return (
    (systemBanners.length > 0 || dismissibleBanners.length > 0) && 
      <div className="notification-banners">
      {
        systemBanners.length > 0 && systemBanners.map((banner) => (
          <Banner banner={banner} />
        ))
      }
      {
        dismissibleBanners.length > 0 && dismissibleBanners.map((banner) => (
          <Banner banner={banner} isDismissible={true} close={(e) => close(e)} />
        ))
      }
    </div>
  );
}

function mapStateToProps(state) {
  return {
    alerts: state.alerts
  };
}

function mapDispatchToProps(dispatch) {
  return {
    readBanner(alert) {
      dispatch({ type: actionTypes.READ_BANNER_ALERT, data: alert });
  },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBanner);
