let tripRecordingService = {};

tripRecordingService.getOrientation = vanpooler => {
  if (vanpooler.toHome && vanpooler.toWork) {
    return 'R';
  } else if (vanpooler.toHome) {
    return 'H';
  } else if (vanpooler.toWork) {
    return 'W';
  } else {
    return 'U';
  }
};

export default tripRecordingService;
