import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DraggableStop from '../draggable-stop/DraggableStop';

export default class DraggableStopList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.setState({ items: this.props.stops });
  }

  componentWillReceiveProps(props) {
    this.setState({ items: props.stops });
  }

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    this.props.reorder(result.source.index, result.destination.index);
  }

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div ref={provided.innerRef} className="droppable-stop-list">
              {this.state.items.map((item, index) => (
                <Draggable
                  key={item.stopOrder + '' + item.isDelete}
                  draggableId={item.stopOrder + '' + item.isDelete}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      <DraggableStop
                        active={snapshot.isDragging}
                        dragHandleProps={provided.dragHandleProps}
                        autoFocusStop={this.props.autoFocusStop}
                        stop={item}
                        index={index}
                        totalFilteredStops={this.state.items.length}
                        {...this.props}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}
