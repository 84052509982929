import React, { Component } from 'react';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';
import { isEqual } from 'lodash';
import timePeriodService from './time-period-service';
import * as actionTypes from '../redux/actionTypes';
import ImpactData from './ImpactData';
import { Download, Vanpool, Calendar } from '../shared/icons';
import ImpactDataStackedBarContainer from './ImpactDataStackedBarContainer';
import DateSelector from './DateSelector';
import VanpoolChart from './VanpoolChart';
import Loading from '../shared/loading/Loading';
import ActiveUsersStackedBarContainer from './ActiveUsersStackedBarContainer';
import RecordedTripsChart from './RecordedTripsChart';

export class StatsPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentAggregate: {},
      timePeriod: timePeriodService.currentQuarter(),
      isQuarter: true,
      csvData: null,
      loadingCsv: null,
      csvClicked: false
    };
  }

  componentWillReceiveProps(nextProps) {
    const { currentAggregate, csvData, csvLoaded } = nextProps;
    if (csvLoaded && csvData) {
      this.setState({ csvData, loadingCsv: false });
    }

    if (!isEqual(this.state.currentAggregate, currentAggregate)) {
      this.setState({ currentAggregate }, () => {
        this.props.loadStats(
          currentAggregate,
          this.state.timePeriod,
          this.state.isQuarter
        );
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.csvData && !this.state.csvClicked) {
      this.setState({ csvClicked: true });
      let csvContent = this.state.csvData.map(e => e.join(',')).join('\n');
      let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(
        blob,
        `Commute Aggregate ${this.state.timePeriod.displayValue}.csv`
      );
    }
  }

  selectTimePeriod(timePeriod, isQuarter) {
    this.setState({ timePeriod, isQuarter }, () => {
      this.props.loadStats(this.state.currentAggregate, timePeriod, isQuarter);
    });
  }

  csvLoad() {
    const { currentAggregate, timePeriod } = this.state;
    this.setState({ loadingCsv: true, csvData: null, csvClicked: false });
    this.props.loadCSV(currentAggregate, timePeriod);
  }

  render() {
    const { impactData } = this.props;
    const { currentAggregate, timePeriod, isQuarter, loadingCsv } = this.state;

    return (
      <div className="stats-page">
        <div className="stats-container">
          <Title currentAggregate={currentAggregate} />
          <div className="stats-header">
            <DateSelector
              isQuarter={isQuarter}
              selectTimePeriod={(value, isQuarter) =>
                this.selectTimePeriod(value, isQuarter)
              }
              setChart={isQuarter => this.setState({ isQuarter })}
            />
            <div className="stats-export-container desktop-only">
              <Download />
              <button type="button" onClick={e => this.csvLoad()}>
                Export CSV <span>(complete data)</span>
              </button>
            </div>
          </div>
          <ImpactData data={impactData} />
          <div className="secondary-nav">
            <NavLink
              activeClassName="active"
              id="vanpool-aggregate-link"
              exact={true}
              to="/stats/vanpool"
            >
              <Vanpool className="mobile-only" />
              <span className="desktop-only">Vanpool</span>
            </NavLink>
            <NavLink
              activeClassName="active"
              id="recorded-trips-aggregate-link"
              exact={true}
              to="/stats/recordedTrips"
            >
              {' '}
              <Calendar className="mobile-only" />
              <span className="desktop-only">Recorded Trips</span>
            </NavLink>
          </div>
          <Switch>
            <Route
              path="/stats/vanpool"
              render={() => (
                <VanpoolAggregateData
                  {...this.props}
                  loading={loadingCsv}
                  isQuarter={isQuarter}
                  displayValue={timePeriod.displayValue}
                />
              )}
            />
            <Route
              path="/stats/recordedTrips"
              render={() => (
                <RecordedTripsAggregateData
                  {...this.props}
                  loading={loadingCsv}
                  displayValue={timePeriod.displayValue}
                />
              )}
            />
            <Redirect from="/stats" to="/stats/vanpool" />
          </Switch>
        </div>
      </div>
    );
  }
}

function VanpoolAggregateData({
  chartData,
  impactData,
  monthlyEnrollment,
  isQuarter,
  displayValue,
  loading
}) {
  if (chartData && !loading) {
    return (
      <div>
        <VanpoolChart
          {...monthlyEnrollment}
          isQuarter={isQuarter}
          timePeriod={displayValue}
        />
        <ImpactDataStackedBarContainer
          isVanpool={true}
          data={impactData.vanpoolImpactData}
          title="Vanpool Data"
          timePeriod={displayValue}
        />
      </div>
    );
  }
  return <Loading isLoading={!chartData || loading} />;
}

function RecordedTripsAggregateData({
  chartData,
  tripData,
  displayValue,
  loading
}) {
  if (chartData && !loading) {
    return (
      <div>
        <ActiveUsersStackedBarContainer
          data={tripData.activeUsers}
          timePeriod={displayValue}
        />
        <RecordedTripsChart
          timePeriod={displayValue}
          data={tripData.commutePrimaryModes}
        />
        <ImpactDataStackedBarContainer
          isVanpool={false}
          data={tripData.recordedTripsImpactData}
          title="Recorded Trips Data"
          timePeriod={displayValue}
        />
      </div>
    );
  }
  return <Loading isLoading={!chartData || loading} />;
}

function Title({ currentAggregate }) {
  if (currentAggregate.isTransit) {
    return (
      <div className="selected-aggregate-title">
        {currentAggregate.name}
        <span className="alternate-title"> (Transit)</span>
      </div>
    );
  } else if (currentAggregate.isVanpool && currentAggregate.name) {
    return (
      <div className="selected-aggregate-title">
        {currentAggregate.id}
        <span className="alternate-title"> ({currentAggregate.name})</span>
      </div>
    );
  } else if (currentAggregate.isVanpool) {
    return (
      <div className="selected-aggregate-title">{currentAggregate.id}</div>
    );
  }
  return (
    <div className="selected-aggregate-title">{currentAggregate.name}</div>
  );
}

function mapStateToProps(state) {
  return state.stats;
}

function mapDispatchToProps(dispatch) {
  return {
    loadStats(currentAggregate, timePeriod, isQuarter = true) {
      dispatch({
        type: actionTypes.STATS_IMPACT_DATA_LOADING,
        data: { currentAggregate, timePeriod }
      });
      dispatch({
        type: actionTypes.STATS_MONTHLY_ENROLLMENT_LOADING,
        data: { currentAggregate, timePeriod, isQuarter }
      });
      dispatch({
        type: actionTypes.STATS_TRIPDATA_LOADING,
        data: { currentAggregate, timePeriod }
      });
    },
    loadCSV(currentAggregate, timePeriod) {
      dispatch({
        type: actionTypes.STATS_CSV_DATA_LOADING,
        data: { currentAggregate, timePeriod }
      });
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatsPresentation);
