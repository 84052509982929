import {useEffect,useState} from 'react';
import {connect} from 'react-redux';
import PaymentHistoryCollapsible from '../../payment/paymentOverview/collapsibles/PaymentHistoryCollapsible';
import {GET_PAYMENT_HISTORY} from '../../redux/actionTypes';
import {getPaymentsList} from '../helper';
import ArrowLeft from '../../_styles/images/ArrowLeft.png';
import {Link} from 'react-router-dom';
import useError from '../../shared/components/error-screen/useError';
import analyticsService from '../../shared/analytics/analytics-service';

function PaymentHistoryDetail(props) {

  const [paymentsList,updatePaymentsList] = useState([]);
  const [loading,updateLoading] = useState(false);

  useEffect(() => {
    if(props.paymentHistory) {
      const list = getPaymentsList(props.paymentHistory.transactionGroup);
      updatePaymentsList(list);
    }
  },[props.paymentHistory])

  useEffect(() => {
    updateLoading(true);
    props.init();
  },[]);

  useEffect(() => {
    if(props.loaded) {
      updateLoading(false)
    }
  },[props.loaded])

  useError(props.loadError, props.history, props.loadErrorString);

  return (
    <div className='payment-history-container'>
      <div className='top-header'>
        <div className='backArrow'>
          <Link className="overview-link" onClick={props.history.goBack}>
            <img src={ArrowLeft} alt='go back'></img>
          </Link>
        </div>
        <div className='heading'>Payment History</div>
      </div>
      <div className='content'>
        {loading ? <div className="loader"></div> :
          paymentsList && paymentsList.length > 0 ? paymentsList.map((yearGroup) =>
            <div className='list-container' key={yearGroup.key} role='group'>
              <div className='side-heading'>{yearGroup.key}</div>
              <div className='transaction-list'>
                {yearGroup.value.map((transaction, index) => <PaymentHistoryCollapsible key={transaction.transactionId} transaction={transaction} index={index+1}/>)}
              </div>
            </div>)
            :
            <div className='no-records'>You don’t have any recent payments</div>
        }
      </div>

    </div>
  )
}

function mapStateToProps(state) {
  return {
    paymentHistory: state.paymentHistory.rawPaymentHistory,
    loaded: state.paymentHistory.historyLoaded,
    loadError: state.paymentHistory.loadError,
    loadErrorString: state.paymentHistory.loadErrorString,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    init() {
      dispatch({type: GET_PAYMENT_HISTORY});
    }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PaymentHistoryDetail);