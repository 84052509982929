import React from 'react';
import userService from '../../shared/user-service';

export const isAllowed = roles =>
  roles.some(role =>
    role instanceof Array
      ? role.every(subrole =>
          userService.getUserProfile().permissions.includes(subrole)
        )
      : userService.getUserProfile().permissions.includes(role)
  );

export const isAnyPresent = roles =>
  roles.some(role => userService.getUserProfile().permissions.includes(role));

// decorator
// ex: restrictTo([PERMISSION_PRE_DELIVERY])(Component)
// ex 2: restrictTo([[PERMISSION_ONE, PERMISSION_TWO], PERMISSION_THREE]) = Restrict to (PERMISSION_ONE AND PERMISSION_TWO) OR PERMISSION_THREE
const restrictTo = roles => Component => props =>
  isAllowed(roles) ? <Component {...props} /> : null;

const restrictFrom = roles => Component => props =>
  isAnyPresent(roles) ? null : <Component {...props} />;

export const RestrictTo = ({ children, roles, required = true }) =>
  isAllowed(roles) ? (required ? children() : children) : null;

export const RestrictFrom = ({ children, roles, required = true }) =>
  isAnyPresent(roles) ? null : required ? children() : children;

export { restrictTo, restrictFrom };
