import {getCardInfoFromBrand, numberWithCommas} from '../../join-commute/helpers/commonHelper';
import { makeDecimal2numbers } from '../../payment/helper';

export default function CardBox(props) {
  return (
    props && props.card && <div key={props.card.partialAccountNumber + props.card.cardBrand} className={`box${props.isExpired ? ' expired' : ''}`} role='group'>
      <div className='leftSection'>
        <div className='CardImage'>
          <img src={getCardInfoFromBrand(props.card.cardBrand).imgSrc} alt={`${props.card.cardBrand}`}/>
        </div>
        <div className='cardDetails'>
          <div className='name'>{props.card.cardBrand && props.card.cardBrand.toLowerCase()}</div>
          <div className='type'> {props.card.isFederalBenefitCard ? 'Federal Benefits Card' : ' '} </div>
          <div className='details' aria-label={`Card ending ${props.card.partialAccountNumber} expiring on ${props.card.expirationMMYY.replace(/^\d\d/, '$&/')}`}>{`${'Card ending *' + props.card.partialAccountNumber + ' | Exp: ' + props.card.expirationMMYY.replace(/^\d\d/, '$&/')}`}</div>
          <div className='amount'> {`${props.card.isFederalBenefitCard && props.card.maxAuthorizedAmount ? 'Authorized amount: $' + numberWithCommas(makeDecimal2numbers(props.card.maxAuthorizedAmount)) : ''}`}</div>
        </div>
      </div>
      <div className='rightSection'>
        {props.badgeClass && props.badgeText && <div className={`badge ${props.badgeClass}`}>{props.badgeText}</div>}
        <div className='edit-button'>
          <button onClick={() => props.editCard(props.card)}>Edit</button>
        </div>
      </div>
    </div>
  )
}
