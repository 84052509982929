import { useEffect, useRef, useState } from 'react';
import Close from '../../../_styles/images/close-icon-green.svg';
import SelectDays from './select-days/SelectDays';
import DatePickerCustom from '../../../shared/components/summary/date-picker/DatePickerCustom';
import Calendar from '../../../shared/inputs/Calendar/Calendar';
import { addDays, getDaysFromRosterDays } from '../../../join-commute/helpers/commonHelper';
import { getDaysFromPackage } from '../../../join-commute/helpers/vanHelpers';
import Callable from '../../../shared/Callable';
import PhoneIcon from '../../../_styles/images/new-icons/PhoneIcon.svg';
import analyticsService from '../../../shared/analytics/analytics-service';
import { getDaysForAnalytics } from '../../../shared/analytics/analytics-helpers';
import { ENTER_KEY_CODE, ESCAPE_KEY_CODE, TAB_KEY_CODE } from '../../../shared/helpers/accessibilityHelpers';

export default function ChangeCommuteDaysPopup(props) {

  const node = useRef();
  const popupRef = useRef(null);
  const [selectedDays, updateSelectedDays] = useState(props.vanpoolInfo.commuteDays || []);
  const [startDate, updateStartDate] = useState();
  const [isOpen, updateIsOpen] = useState();
  const [enabledDays, updateEnabledDays] = useState([]);
  const [saveDisabled, updateSaveDisabled] = useState(true);
  const [maxDaysError, updateMaxDaysError] = useState(false);
  const [minDaysError, updateMinDaysError] = useState(false);
  const today = new Date(new Date().setHours(0, 0, 0, 0));
  const endDay = addDays(30, today);
  useEffect(() => {
    if (props && props.vanpoolInfo && props.vanpoolInfo.commuteDays) {
      updateSelectedDays(props.vanpoolInfo.commuteDays);
    }
  }, [props])

  useEffect(() => {
    analyticsService.getPageName({
      'pagename': "profile/main/change commute days modal"
    });
    if(popupRef.current){
      setTimeout(() => {
        popupRef?.current?.focus();
      }, 0);
    }
  }, [])

  useEffect(() => {
    const days = getDaysFromRosterDays(selectedDays);
    updateEnabledDays(days);
  }, [selectedDays])

  function keyboardHandler(e) {
    if (e.keyCode === TAB_KEY_CODE) {
      e.preventDefault();
      const closeB = document.querySelector('#change-commute-days-popup .close');
      closeB?.focus();
    } else if (e.keyCode === ESCAPE_KEY_CODE) {
      props.close();
    }
  }

  function toggleCalendar() {
    if (!isOpen) {
      const modal = document.getElementById("modal");
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "true");
      updateIsOpen(true);
      if (modal) {
        modal.setAttribute("aria-modal", "true");
        modal.querySelector(".calendar-day") && modal.querySelector(".calendar-day").focus();
      }
    } else {
      const all = document.querySelector(".vanpool-summary");
      all && all.setAttribute("aria-hidden", "false");
      updateIsOpen(false);
    }

  }

  function onCalendarKeydown(e) {
    if (e.which == ENTER_KEY_CODE) {
      toggleCalendar();
    }
  }

  const clickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return;
    }
    toggleCalendar();
  }

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', clickOutside);
      if(node?.current){
        setTimeout(() => {
          node.current?.focus();
        }, 0);
      }
      return () => {
        document.removeEventListener('mousedown', clickOutside);
      }
    }
  }, [isOpen])

  useEffect(() => {
    const isMaxError = props.maxDays < selectedDays.length;
    updateMaxDaysError(isMaxError);
    const isMinError = selectedDays.length === 0;
    updateMinDaysError(isMinError);
    if (startDate && selectedDays.length >= 1 && !isMaxError) {
      updateSaveDisabled(false);
    } else {
      updateSaveDisabled(true);
    }
  }, [startDate, selectedDays])

  function onSave(selectedDays, startDate){
    const analyticsDays = getDaysForAnalytics(selectedDays);
    analyticsService.analyticsProcessEvent({
      "event": "change_my_commute_days_click_save",
      "context": {
        "event_action": 'save',
        "event_detail": {
            "modal": "change my commute days modal",
            "days_selected": analyticsDays
        }  
      }
    })
    props.saveCommuteDays(selectedDays, startDate);
  }

  return (
    <div className="charges-popup show" id='change-commute-days-popup' aria-live='polite' role='alert' aria-modal='true' ref={popupRef} tabIndex="-1">
      <div className="card-operations-container cancel-subscription-popup">
        <div className="sp-header">
          <div className='header'>Change My Commute Days</div>
          <img role='button' className="close" src={Close} onClick={props.close} alt='close change my commute popup' tabIndex={0} />
        </div>
        <div className='change-days-popup-container'>
          <div className='pre-text'>These are the days your vanpool runs. Save your seat in the van by selecting the days you’d like to ride.</div>
          <div className='selected-days-badge'><div className='selected-days-label'>Days Selected :</div><div className='selected-days-value'>&nbsp;{selectedDays.length}</div></div>
          <div className="commuteDays">
            <SelectDays
              runningDays={props.vanpoolInfo.operationalDays}
              value={selectedDays}
              onlyShowRunningDays={true}
              onChange={updateSelectedDays}
              minDaysError={minDaysError}
              maxDaysError={maxDaysError}
              availableDays={props.vanpoolInfo.vanAvailableDays}
              alreadySelected={props.vanpoolInfo.commuteDays}
              maxDays={props.maxDays}
            />
          </div>
          {maxDaysError ? 
          <div className='max-days-error-container'>
            <div className='max-days-error'>
              <div className='phone-icon'><img src={PhoneIcon}></img></div>
              <div className='text'>
                <div className='top-text'>If you wish to add more days, please contact </div>
                <div className='callable'><Callable phone="800-VAN-4-WORK" /></div>
              </div>
            </div>
          </div>
            :
            <div className='date-picker'>
              <DatePickerCustom selectedDay={startDate} days={getDaysFromPackage(props.plan)} toggleCalendar={toggleCalendar} onCalendarKeydown={onCalendarKeydown} placeholder='Select Start Date' label='Select a start date for your new weekly schedule*' />
              <div aria-live='polite' role='alert' id='modal' className={"popup" + (isOpen ? " show" : " hide")}>
                <Calendar
                  reference={node}
                  buttonText={'confirm start date'}
                  toggle={toggleCalendar}
                  selectedDay={startDate}
                  onConfirm={updateStartDate}
                  startDay={today}
                  endDay={endDay}
                  reset={isOpen}
                  enabledDays={enabledDays}
                />
              </div>
            </div>}
          {!maxDaysError && <button className='save-button' disabled={saveDisabled} onClick={() => onSave(selectedDays, startDate)}>save</button>}
        </div>
      </div>
    </div>
  );
}
