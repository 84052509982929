import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ViewMatchingVans from './view-matching-vans/ViewMatchingVans';
import VanpoolDetails from './vanpool-details/VanpoolDetails'
import JoinCommute from './JoinCommute';
import StayInTouchForm from './stay-in-touch/StayInTouchForm';
import ThankYouComponent from './stay-in-touch/ThankYou';
import Upgrade from './subscription/upgrade/Upgrade';
import ChoosePlan from './subscription/choose-plan/ChoosePlan';
import LetsRideThankYou from './vanpool-details/LetsRideThankyou';
import TraditionalThankYou from './vanpool-details/TraditionalThakyou';
import Summary from './subscription/summary/Summary';
import ErrorScreen from './error-screen/ErrorScreen';
import PaymentScreen from './payment-screen/PaymentScreen';
import BackupCard from './payment-screen/backup-card/BackupCard';
import PreDeliveryThankYou from './payment-screen/PredeliveryThankyou'
import FinalSummary from './payment-screen/final-summary/FinalSummary';
import OnboardingPage from './onboarding/OnboardingPage';
import EnterLicenseScreen from './driver-license/EnterLicenseScreen';
import AddLicenseScreen from './driver-license/AddLicenseScreen';
import ApplyToCoordinate from './coordinator-application/ApplyToCoordinate';
import VerifyHomeAddress from './verify-home-address/VerifyHomeAddress';
import VerifyEmailInformation from './verify-email-information/VerifyEmailInformation';


const prefix = '/JoinCommute';

export default function JoinCommuteRoute() {
  return (
    <div className="joinCommuteRoute">
      <Switch>
        <Route path={prefix + '/view-matched-vans'} component={ViewMatchingVans} />
        <Route path={prefix + '/vanpool-details'} exact component={VanpoolDetails} />
        <Route path={prefix + '/vanpool-details/thankyou'} exact component={LetsRideThankYou} />
        <Route path={prefix + '/vanpool-details/traditional-thankyou'} exact component={TraditionalThankYou} />
        <Route path={prefix + '/stay-in-touch'} exact component={StayInTouchForm} />
        <Route path={prefix + '/stay-in-touch/thank-you'} exact component={ThankYouComponent} />
        <Route path={prefix + '/choose-plan'} exact component={ChoosePlan} />
        <Route path={prefix + '/choose-plan/upgrade'} exact component={Upgrade} />
        <Route path={prefix + '/choose-plan/summary'} exact component={Summary} />
        <Route path={prefix + '/verify/home-address'} exact component={VerifyHomeAddress} />
        <Route path={prefix + '/apply/coordinator'} exact component={ApplyToCoordinate} />
        <Route path={prefix + '/add-payment'} exact component={PaymentScreen} />
        <Route path={prefix + '/add-payment/backup'} exact component={BackupCard} />
        <Route path={prefix + '/license/enter-license'} exact render={(props) => <EnterLicenseScreen findAVan={true} {...props} />} />
        <Route path={prefix + '/license/add-license'} exact component={AddLicenseScreen} />
        <Route path={prefix + '/predivery/thankyou'} exact component={PreDeliveryThankYou} />
        <Route path={prefix + '/summary'} exact component={FinalSummary} />
        <Route path={prefix + '/error'} exact component={ErrorScreen} />
        <Route path={prefix + '/onboarding'} exact component={OnboardingPage} />
        <Route path={prefix + '/verify-email'} exact component={VerifyEmailInformation} />
        <Route path={prefix} component={JoinCommute} exact />
      </Switch>
    </div>
  );
}
