import React from 'react';
import SuccessConfirmation from './SuccessConfirmation';
import ErrorConfirmation from './ErrorConfirmation';

export default function Confirmation(props) {
  return props.successful ? (
    <SuccessConfirmation {...props} />
  ) : (
    <ErrorConfirmation {...props} />
  );
}
