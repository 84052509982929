import UserService from '../user-service';

let analyticsUserFactory = {};

analyticsUserFactory.getUserObject = () => {
  let analyticInfo = UserService.getAuthentication() || {};
  let user = analyticInfo.profile || {};
  let auth = analyticInfo.profileId ? { id: analyticInfo.profileId } : {};

  return {
    commute: {
      id: user.vanpoolId,
      statusCode: user.vanpoolStatus
    },
    status: {
      coordinator: user.coordinatorStatus,
      driver: user.driverStatus
    },
    customer: { id: auth.id ? auth.id.toString() : auth.id },
    partner: { name: user.employer },
    trip: { type: user.tripType },
    role: { customerRole: user.roles }
  };
};

export default analyticsUserFactory;
