import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { SET_USER_INFO } from '../../redux/actionTypes';
import AddressUtility from '../../shared/addressUtility';
import MapService from '../../shared/map-service';
import { isValidEmail } from '../helpers/commonHelper';
import { initialJoinCommuteState, initUserInfo } from '../JoinCommuteReducer';
import Loading from '../../shared/loading/Loading';
import {
  formatPhoneNumber
} from '../../shared/inputs/PhoneEntry';
import isEqual from 'lodash/isEqual';
import analyticsService from '../../shared/analytics/analytics-service';
import { WEEKDAYS } from '../../shared/inputs/Calendar/Calendar';

function StayInTouchForm(props) {

  const [employerName, updateEmployerName] = useState('');
  const [userInfo, setUserInfo] = useState(initUserInfo);
  const [disabled, updateDisabled] = useState(true);
  const [emailValid, updateEmailValid] = useState(true);
  const [phoneValid, updatePhoneValid] = useState(true);
  const [loading, updateLoading] = useState(false);

  const validateEmail = (value) => {
    const isValid = isValidEmail(value);
    updateEmailValid(isValid);
  }

  const validatePhoneNumber = (num) => {
    if (!num) {
      return true;
    }
    return /^\d{3}-\d{3}-\d{4}$/.test(num);
  }

  function onUserInfoChange(e, key) {
    if (key === 'phone') {
      let val = formatPhoneNumber(e.target.value);
      updatePhoneValid(validatePhoneNumber(val));
      setUserInfo({
        ...userInfo,
        [key]: { number: val }
      })
    } else {
      if (key === 'emailAddress') {
        validateEmail(e.target.value);
      }
      setUserInfo({
        ...userInfo,
        [key]: e.target.value
      })
    }
  }

  useEffect(() => {
    analyticsService.pageInfo("stay in touch");
    if (isEqual(props.joinCommuteData, initialJoinCommuteState)) {
      props.history.push("/JoinCommute");
    }
  }, [])

  useEffect(() => {
    if (userInfo.firstName !== '' &&
      userInfo.lastName !== '' &&
      userInfo.emailAddress !== '' &&
      userInfo.phone.number !== '' &&
      employerName &&
      emailValid &&
      phoneValid &&
      userInfo.emailAddress.length > 5) {
      updateDisabled(false);
    } else {
      updateDisabled(true);
    }
  }, [userInfo, emailValid, phoneValid])

  useEffect(() => {
    updateEmployerName(props.employerData.employerName);
  }, [props.employerData]);


  async function getAddressUtility(address) {
    let addressString = await MapService.getAddressAndLocation(address);
    var addressObj = AddressUtility.fromGeocode(addressString);
    return addressObj
  }

  function getCommuteDays(daysArray) {
    var commuteDays = [];
    WEEKDAYS.forEach((day, ind) => { if (daysArray[day] === true) { commuteDays.push(ind + 1); } })
    return commuteDays;
  }

  async function onContinue() {
    const tempObj = {
      ...props.employerData,
      ...userInfo,
      "homeAddress": await getAddressUtility(props.joinCommuteData.userCommuteData.homeAddress),
      "workAddress": await getAddressUtility(props.joinCommuteData.userCommuteData.workAddress),
      "workArrivalTime": props.joinCommuteData.userCommuteData.arriveTime,
      "workDepartureTime": props.joinCommuteData.userCommuteData.departTime,
      "isWorkArriveTimeFlexible": props.joinCommuteData.userCommuteData.flexArrivalMinutes !== 0,
      "isWorkDepartTimeFlexible": props.joinCommuteData.userCommuteData.flexDepartureMinutes !== 0,
      "commuteDays": getCommuteDays(props.joinCommuteData.userCommuteData.rosterDays),
      "arriveFlexTimeInMinutes": props.joinCommuteData.userCommuteData.flexArrivalMinutes,
      "departureFlexTimeInMinutes": props.joinCommuteData.userCommuteData.flexDepartureMinutes,
      "vanpoolMatch": []
    }
    updateLoading(true);
    props.setUserInfo(tempObj);
    analyticsService.analyticsProcessEvent({
      "event": "stay_in_touch_click_continue",
      "context": {
        "event_action": "continue"
      }
    });
  }

  useEffect(() => {
    updateLoading(false);
    if (props.saveInfoSuccess) {
      props.history.push("/JoinCommute/stay-in-touch/thank-you");
    }
  }, [props.saveInfoSuccess])

  return (
    loading ? <Loading isLoading={loading} absolute={true} /> :
      <div className='stay-in-touch-page'>
        <div className='stay-in-touch-container' role='group'>
          <h1 className='heading'>
            Let’s stay in touch
          </h1>
          <div className='comment'>Don’t worry, new routes are being added every day! We'll let you know when a match becomes available.</div>
          <div className='form'>
            <fieldset className='field'>
              <label htmlFor='firstName' className='label' aria-label='First Name'>
                First Name*
              </label>
              <input id='firstName' value={userInfo.firstName} onChange={(e) => onUserInfoChange(e, 'firstName')}></input>
            </fieldset>
            <fieldset className='field'>
              <label htmlFor='lastName' className='label' aria-label='last Name'>
                Last Name*
              </label>
              <input id='lastName' value={userInfo.lastName} onChange={(e) => onUserInfoChange(e, 'lastName')}></input>
            </fieldset>
            <fieldset className='field'>
              <label htmlFor='emailAddress' className='label' aria-label='Email Address'>
                Email Address*
              </label>
              <input id='emailAddress' className={!emailValid ? 'invalid' : ''} type='email' value={userInfo.emailAddress} onChange={(e) => onUserInfoChange(e, 'emailAddress')}></input>
              {!emailValid && <div className='invalid-text'>Please enter a valid email address</div>}
            </fieldset>
            <fieldset className='field'>
              <label htmlFor='Phone Number*' className='label' aria-label='Phone Number'>
                Phone Number*
              </label>
              <input id='phone' value={userInfo.phone.number} onChange={(e) => onUserInfoChange(e, 'phone')}></input>
              {!phoneValid && <div className='invalid-text'>Please enter a valid phone number</div>}
            </fieldset>
            <fieldset className='field'>
              <label htmlFor='ename' className='label' aria-label='Name of Employer'>
                Name of Employer
              </label>
              <input id='ename' readOnly={true} value={employerName}></input>
            </fieldset>
          </div>
          <div className='button-container'>
            <button className='continue-button' onClick={onContinue} disabled={disabled}>CONTINUE</button>
          </div>
        </div>
      </div>
  );
}

function mapStateToProps(state) {
  return {
    employerData: state.joinCommute.employerData,
    joinCommuteData: state.joinCommute,
    saveInfoSuccess: state.joinCommute.userInfoSaved
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setUserInfo(value) {
      dispatch({ type: SET_USER_INFO, data: value });
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StayInTouchForm);
