import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionTypes from '../redux/actionTypes';
import { Phone, Email, Home, Contact, Pencil } from '../shared/icons';
import ProfileImage from '../shared/profile-image';
import PhoneEntry from '../shared/inputs/PhoneEntry';
import HomeAndMailingAddressInput from '../shared/inputs/HomeAndMailingAddressInput';
import AddressUtility from '../shared/addressUtility';
import MapService from '../shared/map-service';
import NA_VALUE from '../shared/na';

export function PersonalInfo({
  name,
  phone,
  secondPhone,
  email,
  homeAddress,
  mailingAddress
}) {
  const home = homeAddress.line1
    ? AddressUtility.lines(homeAddress)
    : [NA_VALUE];
  const mailing = mailingAddress.line1
    ? AddressUtility.lines(mailingAddress)
    : null;
  const primaryNumber = phone ? phone.number : NA_VALUE;
  const secondaryNumber = secondPhone ? secondPhone.number : '';

  return (
    <div className="details">
      <div className="name">{name}</div>
      <div className="phone field">
        <Phone />
        {secondaryNumber ? (
          <span>
            <span className="line1">
              {primaryNumber} <i>(Preferred)</i>
            </span>
            <span className="line2">{secondaryNumber}</span>
          </span>
        ) : (
          primaryNumber
        )}
      </div>
      <div className="email field">
        <Email />
        {email}
      </div>
      <div className="home-address field">
        <Home />
        <p>
          {home.map(line => (
            <span key={line} className="line2">
              {line}
            </span>
          ))}
        </p>
      </div>
      {mailing && !AddressUtility.equal(homeAddress, mailingAddress) ? (
        <div className="contact-address field">
          <Contact />
          <p>
            {mailing.map(line => (
              <span key={line} className="line2">
                {line}
              </span>
            ))}
          </p>
        </div>
      ) : null}
    </div>
  );
}

export function PersonalInfoForm({
  name,
  phone,
  secondPhone,
  email,
  homeAddress,
  mailingAddress,
  update
}) {
  return (
    <div className="details">
      <div className="name">{name}</div>
      <PhoneEntry primary={phone} secondary={secondPhone} update={update} />
      <div className="email field">
        <Email />
        {email}
      </div>
      <HomeAndMailingAddressInput
        homeAddress={homeAddress}
        mailingAddress={mailingAddress}
        update={update}
      />
    </div>
  );
}

export class PersonalSection extends Component {
  constructor(props) {
    super(props);

    this.state = { editing: false, ...props };
  }

  componentWillReceiveProps(props) {
    this.setState(props);
  }

  edit() {
    var CheckValidAddresses = 
      AddressUtility.valid(this.props.homeAddress, false) &&
      AddressUtility.valid(this.props.mailingAddress, true)
    var mailingAddress = AddressUtility.empty(this.props.mailingAddress)
      ? this.props.homeAddress
      : this.props.mailingAddress
    this.setState({
      editing: true,
      ...this.props,
      mailingAddress: mailingAddress,
      validPhones: true,
      validAddresses: CheckValidAddresses,
      valid:true
    });
  }

   checkForLatLang = async(homeAddress,mailingAddress) => {
    if(!homeAddress.latitude  || !homeAddress.longitude || !mailingAddress.latitude || !mailingAddress.longitude) {
      var hAddressString = AddressUtility.lines(homeAddress).join(', ');
      var hAddress = await MapService.getAddressAndLocation(hAddressString);
      var mAddressString = AddressUtility.lines(mailingAddress).join(', ');
      var mAddress = await MapService.getAddressAndLocation(mAddressString);

      this.setState({
      ...this.state,
      homeAddress: {...this.state.homeAddress, latitude: hAddress.latitude , longitude: hAddress.longitude},
      mailingAddress: {...this.state.mailingAddress, latitude: mAddress.latitude , longitude: mAddress.longitude},
     });
    }
  }

  cancel() {
    window.scrollTo(0, 0);
    this.setState({ editing: false, ...this.props });
  }

  update({
    primary,
    secondary,
    validPhones,
    homeAddress,
    mailingAddress,
    validAddresses,
    valid
  }) {
    if (primary) {
      return this.setState({
        phone: primary,
        secondPhone: secondary,
        validPhones
      });
    }

    this.setState({ homeAddress, mailingAddress, validAddresses, valid });
  }

  async save(data) {
    await this.checkForLatLang(data.homeAddress,data.mailingAddress)
    this.props.save(this.state);
    this.cancel();
  }

  render() {
    const { validPhones, validAddresses, editing, valid } = this.state;

    return (
      <div className="profile-info">
        {editing ? null : (
          <button
            className="edit"
            onClick={() => this.edit()}
            aria-label="Edit Personal Information"
          >
            <Pencil />
          </button>
        )}
        <div className="profile-image">
          <ProfileImage>{this.props.name}</ProfileImage>
        </div>
        {editing ? (
          <PersonalInfoForm
            {...this.state}
            update={updates => this.update(updates)}
          />
        ) : (
          <PersonalInfo {...this.props} />
        )}
        {editing ? (
          <div className="tools">
            <button
              className="button small cancel"
              onClick={() => this.cancel()}
            >
              Cancel
            </button>
            <button
              className="button small save"
              onClick={() => this.save(this.state)}
              disabled={!validPhones || !validAddresses || !valid}
            >
              Update Profile
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    save(data) {
      dispatch({ type: actionTypes.PERSONAL_INFO_SAVING, data });
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(PersonalSection);
