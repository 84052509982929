import { useEffect } from 'react';
import analyticsService from '../../analytics/analytics-service';

export function handleErrorScreen(message, history) {
  analyticsService.analyticsProcessEvent(
    {
      "event": "api_error",
      "context": {
        "event_action": message
      }
    });
  history.state = { ...history.state, errorString: message };
  history.replace('/error');
}

function useError(prop, history, errorString) {

  useEffect(() => {
    const navigateToError = async () => {
      if (prop && prop === true) {
        if (errorString && errorString.message) {
          handleErrorScreen(errorString.message, history);
        }
      };
    }
    navigateToError();
  }, [prop]);

  return;
}

export default useError;
