import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call } from 'redux-saga/effects';
import logger from '../shared/logger';
import MapService from '../shared/map-service';

export default function*() {
  yield takeLatest(actionTypes.MAP_DIRECTIONS, calculateRoute);
}

function* calculateRoute(action) {
  // console.log('action data in saga', action);
  if (action.data.length === 0) {
    yield put({ type: actionTypes.MAP_GOOGLE_DIRECTIONS, data: null });
    yield put({ type: actionTypes.MAP_DURATIONS, data: [] });
    return;
  }

  try {
    const routeResponse = yield call(() =>
      MapService.calculateRoute(action.data)
    );
    yield put({ type: actionTypes.MAP_GOOGLE_DIRECTIONS, data: routeResponse });

    const legs = routeResponse.routes[0].legs;

    const durations = legs.map(leg => roundUpToNextMinute(leg.duration.value));
    yield put({ type: actionTypes.MAP_DURATIONS, data: durations });
  } catch (ex) {
    logger.log(ex);
    yield put({
      type: actionTypes.MAP_GOOGLE_DIRECTIONS,
      data: { error: true }
    });
    yield put({ type: actionTypes.MAP_DURATIONS, data: { error: true } });
  }
}

function roundUpToNextMinute(s) {
  return Math.ceil(s / 60) * 60;
}
