import * as actionTypes from '../redux/actionTypes';
import { store } from '../redux/store';
import UserService from './user-service';
import azureService from '../login/azureService';
import logger from './logger';
import analyticsService from './analytics/analytics-service';
import config from '../appConfig';

export class ServerError {
  constructor(message, code) {
    this.message = message;
    this.code = code;
  }
}

const contentType = { 'Content-Type': 'application/json' };

const defaultHeaders = {
  ...contentType,
  'accept-version': 'application/commute.v1+json;charset=utf-8',
  pragma: 'no-cache'
};

const errorCodes = {
  400: 'Bad Request.',
  401: 'Unauthorized.',
  403: 'Forbidden.',
  404: 'Not Found.'
};

async function defaultOnError({ url, request, response, controller }) {
  const errorResp2 = response.clone();
  if (response.status === 401) {
    controller.abort();
    var errorResponse = await errorResp2.json();
    if (response.status === 401 && !window.location.hash.includes('login') && (errorResponse.error == "1091" || errorResponse.error == "1092")) {
      azureService.signIn();
      controller.abort();
    } else if (response.status === 401 && !window.location.hash.includes('login')) {
      window.location.href = `${window.location.origin}/#/login?error=UNAUTHORIZED`;
      controller.abort();
    }
  }

  let message = 'Unknown';
  if (errorCodes.hasOwnProperty(response.status)) {
    message = errorCodes[response.status];
  } else {
    message = 'A server error has occurred.';
  }

  store.dispatch({ type: actionTypes.DISPLAY_ERROR, data: message });

  let serverError = new ServerError(message);
  serverError.url = url;

  try {
    const error = await response.json();
    serverError.code = error.error || error.ERR;
    serverError.fetchedStatusCode = response && response.status;
    serverError.message = error[0] ? error[0].supportInfo : null || error.ERR ? error.ERR.MSG : null || error.message || serverError.message;
    if (error[0] !== undefined && error[0].supportInfo !== undefined) {
      var errorMessage = "";
      var input = error[0].supportInfo;
      if (input.includes("message")) {
        var supportInfo = input.substring(input.indexOf(':') + 1)
        var supportInfoArray = JSON.parse(supportInfo);
        errorMessage = supportInfoArray[0].message;
      } else {
        errorMessage = input;
      }
      store.dispatch({
        type: actionTypes.DISPLAY_ERROR,
        data: errorMessage
      });
    } else {
      store.dispatch({
        type: actionTypes.DISPLAY_ERROR,
        data: serverError.message
      });
    }
    if (response.status !== 401) {
      logger.serverLog({
        url: url,
        data: request,
        message: 'API Error : error fetching url',
        error: error
      });
    }
    analyticsService.analyticsEvent({
      type: 'API Error',
      error: serverError.message,
      errorCode: (serverError !== undefined && serverError.code !== undefined) ? serverError.code.toString() : undefined,
      api: serverError.url
    });
  } catch (e) {
    logger.log(e);
  }

  throw serverError;
}

async function Http(url, request, onError = defaultOnError) {
  let response;
  let controller = new AbortController();
  try {
    response = await fetch(url, request);
  } catch (err) {
    let msg =
      'Network Error Occurred. Please try again later. If your need is more urgent, call 1-800-VAN-4-WORK';
    store.dispatch({ type: actionTypes.DISPLAY_ERROR, data: msg });
    analyticsService.analyticsEvent({
      type: 'Network Error',
      error: err.stack,
      errorCode: msg,
      api: url
    });
    logger.serverLog({
      url: url,
      data: request,
      message: 'Network Error Occurred : error fetching url',
      error: err.stack
    });
    throw new Error(err);
  }

  if (response.ok) {
    return response.json();
  }

  const data = await onError({ url, request, response, controller });
  return data;
}

Http.getStatic = async (url, optionalHeaders, onError) => {
  var headers = await addHeaders(optionalHeaders);
  return fetch(
    url,
    {
      headers: headers
    },
    onError
  );
}

Http.get = async (url, optionalHeaders, onError) => {
  var headers = await addHeaders(optionalHeaders, null);
  return Http(
    url,
    {
      headers,
    },
    onError
  );
}

Http.post = async (url, body, optionalHeaders, optionalOnly = false, onError) => {
  var headers = await addHeaders(optionalHeaders, optionalOnly);
  return Http(
    url,
    {
      method: 'post',
      headers: headers,
      body: body ? JSON.stringify(body) : null
    },
    onError
  );
}
Http.put = async (url, body, optionalHeaders, onError) => {
  var headers = await addHeaders(optionalHeaders);
  return Http(
    url,
    {
      method: 'put',
      headers: headers,
      body: body ? JSON.stringify(body) : null
    },
    onError
  );
}
Http.delete = async (url, body, optionalHeaders, onError) => {
  var headers = await addHeaders(optionalHeaders);
  return Http(
    url,
    {
      method: 'delete',
      headers: headers,
      body: body ? JSON.stringify(body) : null
    },
    onError
  );
}

let cache = {};
Http.get.cache = async (url, optionalHeaders) => {
  if (url in cache) {
    return await cache[url];
  }

  cache[url] = Http.get(url, optionalHeaders);

  return await cache[url];
};
Http.clearCache = () => (cache = {});

export default Http;

async function addHeaders(optionalHeaders, optionalOnly = false) {

  const profile = UserService.getUserProfile();
  let response;
  if (config.localLogin) {
    let accessToken = UserService.getAuthentication();
    response = accessToken ? accessToken.token.access_token : "";
  } else {
    response = await azureService.getAccessToken();
  }

  var auth = {
    token_type: 'Bearer',
    access_token: response
  }

  let header = auth
    ? {
      ...defaultHeaders,
      authorization: `${auth.token_type} ${auth.access_token}`,
      vanpoolId: profile ? profile.vanpoolId : null,
      participantId: profile ? profile.participantId : null,
      'Ehi-Calling-Application': 'CommuteWeb',
      'Ehi-Locale': 'en-US'
    }
    : defaultHeaders;

  const allHeaders = optionalOnly
    ? { ...contentType, ...optionalHeaders }
    : { ...header, ...optionalHeaders };

  const validHeaders = Object.keys(allHeaders).reduce(
    (valid, key) =>
      allHeaders[key] !== null ? { ...valid, [key]: allHeaders[key] } : valid,
    {}
  );
  return validHeaders;
};
