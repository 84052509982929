import React, { Component } from 'react';
import FancyInput from '../FancyInput';
import { connect } from 'react-redux';
import * as actionTypes from '../../redux/actionTypes';
import CVCLeft from '../../_styles/images/cvc-left.svg';
import cardService from './creditCardService';
import dateUtility from '../dateUtility';
import { Warning } from '../icons';

const MONTHS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
];
const year = new Date().getFullYear();
const YEARS = '0123456789'.split('').map((_, i) => '' + (i + year));

class EditCardForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cvc: '',
      id: this.props.id,
      postalCode: '',
      expirationMMYY: this.props.exp.replace('/', '')
    };
  }

  componentWillReceiveProps(newProps) {
    this.props.onStopEditing();
  }

  onUpdate(e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.update(this.state);
  }

  onDelete() {
    this.props.onStopEditing();
    this.props.delete(this.props.id);
  }

  validData(validExp) {
    return (
      this.state.cvc.length === 3 &&
      validExp &&
      this.state.postalCode.length === 5
    );
  }

  updateZipCode(value) {
    if (value.match(/^\d*?$/) && value.length <= 5) {
      this.setState({ postalCode: value });
    }
  }

  updateCVC(value) {
    if (value.match(/^\d*?$/) && value.length <= 3) {
      this.setState({ cvc: value });
    }
  }

  render() {
    let { onStopEditing, deletable } = this.props;

    let expMonth = cardService.getExpirationMonth(this.state.expirationMMYY);
    let expYear = cardService.getExpirationYear(this.state.expirationMMYY);
    let validExp = !dateUtility.beforeTodayMMYY(expMonth, expYear);
    let originalMonth = cardService.getExpirationMonth(
      this.props.exp.replace('/', '')
    );
    let origianlYear = cardService.getExpirationYear(
      this.props.exp.replace('/', '')
    );

    return (
      <div className="card-editor">
        <form onSubmit={e => this.onUpdate(e)}>
          <div className="row expiration-date">
            <div
              className={`select ${
                expMonth !== originalMonth ? 'changed' : ''
              }`}
            >
              <select
                className="card-exp-month"
                defaultValue={expMonth}
                onChange={e =>
                  this.setState({
                    expirationMMYY: cardService.createExpirationString(
                      e.target.value,
                      expYear
                    )
                  })
                }
              >
                {MONTHS.map(m => (
                  <option key={m} value={m}>
                    {m}
                  </option>
                ))}
              </select>
            </div>
            <div
              className={`select ${
                validExp && expYear !== origianlYear ? 'changed' : ''
              }`}
            >
              <select
                className={`card-exp-year ${validExp ? '' : 'invalid'}`}
                defaultValue={dateUtility.getFourDigitYearFromTwoDigits(
                  expYear
                )}
                onChange={e =>
                  this.setState({
                    expirationMMYY: cardService.createExpirationString(
                      expMonth,
                      e.target.value
                    )
                  })
                }
              >
                {YEARS.map(y => (
                  <option key={y} value={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            {!validExp ? <Warning /> : null}
          </div>
          <div className="row">
            <div className="cvc">
              <FancyInput
                autoFocus
                type="text"
                className={`card-cvc ${
                  this.state.cvc.length > 0 ? 'changed' : ''
                }`}
                placeholder="CVC"
                value={this.state.cvc}
                maxLength="3"
                onChange={e => this.updateCVC(e.target.value)}
              />
              <img src={CVCLeft} alt="CVC help" />
            </div>
          </div>
          <div className="row">
            <div className="zip-input">
              <FancyInput
                maxLength="5"
                value={this.state.postalCode}
                placeholder="Zip"
                type="text"
                className={this.state.postalCode.length > 0 ? 'changed' : ''}
                onChange={e => this.updateZipCode(e.target.value)}
              />
            </div>
          </div>
          <div className="tools">
            <button
              title={
                deletable
                  ? 'Delete Card'
                  : 'Cannot delete a card linked to recurring payment(s).'
              }
              disabled={!deletable}
              type="button"
              className="button small delete"
              onClick={() => this.onDelete()}
            >
              Delete Card
            </button>
            <div className="flex-grow"></div>
            <div>
              <button
                type="button"
                className="button small cancel"
                onClick={() => onStopEditing()}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="button small save"
                disabled={!this.validData(validExp)}
              >
                Update
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    update(payment) {
      dispatch({ type: actionTypes.UPDATE_PAYMENT_PUTTING, data: payment });
    },
    delete(reference) {
      dispatch({ type: actionTypes.DELETE_PAYMENT_METHOD, data: reference });
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(EditCardForm);
