import React from 'react';
import { AngleDown, Download } from '../../shared/icons';
import DateUtility, { fromDateFormat } from '../../shared/dateUtility';
import config from '../../appConfig';
import Http from '../../shared/http-service';
import {saveAs} from 'file-saver';

export function InvoiceMonthlyActivityHeading({
  invoiceDetails,
  monthIndex,
  index,
  dueDate,
  pdfDownloadFail
}) {
  const classList = ['etable-heading', 'toggle', 'monthly-charges'];
  const invoiceDueDate = (
    <p className="invoice-due-by">
      {invoiceDetails.invoiceMonth} Invoice balance due by{' '}
      {DateUtility.formatMoment(
        DateUtility.getMoment(dueDate, fromDateFormat),
        'M/D/YYYY'
      )}
    </p>
  );
  if (monthIndex === 1) {
    classList.push('latest');
  }
  if (invoiceDetails) {
    return (
      <div className={classList.join(' ')}>
        <div className="etable-row" id={`invoice-entry-${index}`}>
          <div className="etable-column">
            <div className="etable-head current-month">
              <AngleDown className="angle-down-button" />{' '}
              {!!invoiceDetails.invoiceId
                ? DownloadLink(
                    invoiceDetails.invoiceMonth,
                    invoiceDetails.invoiceYear,
                    invoiceDetails.invoiceId,
                    pdfDownloadFail
                  )
                : 'Recent Activity View'}
            </div>
            {monthIndex === 1 ? invoiceDueDate : null}
            <div className="etable-head total">
              {invoiceDetails.invoiceAmount}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div />;
  }
}

function DownloadLink(month, invoiceYear, invoiceId, pdfDownloadFail) {
  return (
    <div className="month-label-download">
      {`${month} ${invoiceYear}`} View<span className="pipe">|</span>
      <button
        className="download-link"
        onClick={async e => {
          e.stopPropagation();
          let response = await Http.getStatic(
            `${config.kongApiBaseUrl}/vanpools/vanpool/invoices/invoice/pdf/download/${invoiceId}`, {"Content-Type" : "application/pdf"});
          if (response.ok) {
            const pdf = await response.blob();
            saveAs(pdf, `${invoiceId}.pdf`);
          } else {
            pdfDownloadFail(response)
          }
        }}
      >
        <span className="download-label">Download</span> <Download />
      </button>
    </div>
  );
}

export function InvoiceMonthlyActivityTable(props) {
  if (!props.invoiceDetails) {
    return <div />;
  }
  const activities = props.invoiceDetails.invoiceActivities.map(
    (transaction, index) => (
      <div
        className="etable-row"
        key={`${props.invoiceDetails.invoiceId}_${index}`}
      >
        <div className="etable-column">
          <div className="etable-cell spacer"></div>
          <div className="etable-cell not-yet-invoiced">
            <div className="mobile-only-label">
              <label>Invoiced:&nbsp;</label>
              {transaction.invoiceDate}
            </div>
          </div>
          <div className="etable-cell activity-date">
            <div className="mobile-only-label">
              <label>Act. Date:&nbsp;</label>
              {transaction.activityDate}
            </div>
          </div>
          <div className="etable-cell description">
            <div className="mobile-only-label">
              <label>Description:&nbsp;</label>
              {transaction.activityDescription}
            </div>
          </div>
          <div className="etable-cell amount">
            <div className="mobile-only-label">
              <label>Amount:&nbsp;</label>
              {transaction.amount}
            </div>
          </div>
        </div>
      </div>
    )
  );
  return (
    <div>
      <div className="etable-body monthly-charges-table">
        {activities.length ? (
          activities
        ) : (
          <p className="note no-recent-activity">
            No recent activity at this time
          </p>
        )}
      </div>
    </div>
  );
}
