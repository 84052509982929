import * as actionTypes from '../redux/actionTypes';
import { takeLatest, put, call, all } from 'redux-saga/effects';
import Http from '../shared/http-service';
import logger from '../shared/logger';
import NA_VALUE from '../shared/na';
import config from '../appConfig';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_COORDINATOR } from '../shared/permissions/permissions';
import analyticsService from '../shared/analytics/analytics-service';
import UserService from '../shared/user-service';
import { weekdays_upper } from '../join-commute/helpers/commonHelper';
import { getTodaysDay } from '../shared/helpers/dateHelpers';

export default function* () {
  yield takeLatest(actionTypes.ROSTER_LOADING, loadRoster);
  yield takeLatest(actionTypes.ROSTER_INVITE_USERS_LOADING, inviteUsers);
  yield takeLatest(actionTypes.PARTICIPANT_PROFILE_SAVING, updateParticipant);
  yield takeLatest(actionTypes.PARTICIPANT_ROLES_SAVING, saveParticipantRoles);
  
}

function* loadRoster() {
  try {
    const user = UserService.getUserProfile();
    let [response, route] = yield all([
      call(() =>
        user.vanpoolId && Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/participants`)
      ),
      call(() => user && user.vanpoolId && Http.get(`${config.kongApiBaseUrl}/vanpools/vanpool/route`))
    ]);
    if (response) {
      response.atMaxTripRecorders =
        response.participants.filter(
          participant => participant.roles && participant.roles.tripRecorder
        ).length >= response.maxTripRecordersAllowed;

      response.rawData = response.participants;

      const participantsData = response && response.participants.filter(p => p.participantRouteProfile);

      response.participants = participantsData.map(p =>
        participant(p, route)
      );

      response.todaysParticipants = getTodaysParticipants(response.participants);

      response.rosterParticipants = participantsData.map(p =>
        rosterParticipant(p, route)
      );

      yield all(
        response.participants.map(data =>
          put({ type: actionTypes.PARTICIPANT_PROFILE_LOADED, data })
        )
      );
      yield put({ type: actionTypes.ROSTER_LOADED, data: response });
    } else {
      yield put({ type: actionTypes.ROSTER_LOADED_FAILURE, data: { error: true }, errorString: {message: 'something went wrong'} });
    }


  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.ROSTER_LOADED_FAILURE, data: { error: true }, errorString: ex });
  }
}

function* updateParticipant({ data }) {
  try {
    const profileUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/participants/participant/profile`;
    const routeUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/participants/participant/route`;
      delete data.payload.roles;
      yield call(() => Http.put(routeUrl, data.payload));
      yield put({
        type: actionTypes.PARTICIPANT_PROFILE_LOADED,
        data: { ...data.participant }
      });
    yield put({ type: actionTypes.ROSTER_LOADING });
    yield put({ type: actionTypes.PARTICIPANT_UPDATE_SUCCESS, data:true });
    analyticsService.analyticsEvent({ type: 'Roster Updated' });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.PARTICIPANT_PROFILE_LOADED, data: {} });
  }
}

function* saveParticipantRoles({data}) {
  try {
    const profileUrl = `${config.kongApiBaseUrl}/vanpools/vanpool/participants/participant/profile`;
      const { roleName } = yield call(() => Http.put(profileUrl, data));
      yield put({
        type: actionTypes.PARTICIPANT_PROFILE_LOADED,
        data: { ...data, roleName }
      });
      yield put({ type: actionTypes.ROSTER_LOADING });
      yield put({ type: actionTypes.PARTICIPANT_UPDATE_SUCCESS, data:true });
    analyticsService.analyticsEvent({ type: 'Roster Updated' });
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.PARTICIPANT_PROFILE_LOADED, data: {} });
  }
}

function* inviteUsers({ data }) {
  try {
    let response = yield call(() =>
      Http.post(`${config.kongApiBaseUrl}/vanpools/vanpool/invite`, data)
    );
    if (response.success) {
      yield put({
        type: actionTypes.INVITE_RIDER_SUCCESS,
        data: true
      });
      analyticsService.analyticsEvent({ type: 'Rider Invited' });
    } else {
      yield put({ type: actionTypes.INVITE_RIDER_SUCCESS, data: false });
    }
  } catch (ex) {
    logger.log(ex);
    yield put({ type: actionTypes.INVITE_RIDER_SUCCESS, data: false });
  }
}

function rosterParticipant(participant, route) {
  const {
    participantRouteProfileId,
    outboundStartStopRoutePoints,
    inboundStartStopRoutePoints,
    rideDays
  } = participant.participantRouteProfile;

  const coordinatorStatus =
    participant.coordinatorStatus &&
      !participant.coordinatorStatus.includes('Approved')
      ? `Coordinator ${participant.coordinatorStatus}`
      : '';
  const driverStatus =
    participant.driverStatus && !participant.driverStatus.includes('Approved')
      ? `Driver ${participant.driverStatus}`
      : '';

  const modifiedStatus = getDriverStatus(participant.driverStatus)

  return {
    ...participant,
    status: [coordinatorStatus, driverStatus]
      .filter(status => status)
      .join(', '),
    driverUpdatedStatus: modifiedStatus,
    participantRouteProfileId: participantRouteProfileId,
    rideDays: rideDays,
    name: participant.firstName + ' ' + participant.lastName,
    runDays: route.runDays,
    outboundRoutePoints: route.outboundRoutePoints,
    inboundRoutePoints: route.inboundRoutePoints,
    routeId: route.routeId,
    toWorkStart: route.outboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === outboundStartStopRoutePoints.startRoutePointId
    ),
    toWorkEnd: route.outboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === outboundStartStopRoutePoints.stopRoutePointId
    ),
    toHomeStart: route.inboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === inboundStartStopRoutePoints.startRoutePointId
    ),
    toHomeEnd: route.inboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === inboundStartStopRoutePoints.stopRoutePointId
    )
  };
}

function participant(participant, route) {
  const {
    participantRouteProfileId,
    outboundStartStopRoutePoints,
    inboundStartStopRoutePoints,
    rideDays
  } = participant.participantRouteProfile;

  const coordinatorStatus =
    participant.coordinatorStatus &&
      !participant.coordinatorStatus.includes('Approved')
      ? `Coordinator ${participant.coordinatorStatus}`
      : '';
  const driverStatus =
    participant.driverStatus && !participant.driverStatus.includes('Approved')
      ? `Driver ${participant.driverStatus}`
      : '';
  const modifiedStatus = getDriverStatus(participant.driverStatus)
  return {
    id: participant.participantId,
    name: participant.firstName + ' ' + participant.lastName,
    status: [coordinatorStatus, driverStatus]
      .filter(status => status)
      .join(', '),
    driverUpdatedStatus: modifiedStatus,
    roleName: participant.roleName,
    roles: participant.roles,
    email: participant.emailId || NA_VALUE,
    phone: participant.phone ? participant.phone.number : NA_VALUE,
    participantRouteProfileId: participantRouteProfileId,
    rideDays: rideDays,
    runDays: route.runDays,
    outboundRoutePoints: route.outboundRoutePoints,
    inboundRoutePoints: route.inboundRoutePoints,
    routeId: route.routeId,
    profileImage: participant.profileImage,
    toWorkStart: route.outboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === outboundStartStopRoutePoints.startRoutePointId
    ),
    toWorkEnd: route.outboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === outboundStartStopRoutePoints.stopRoutePointId
    ),
    toHomeStart: route.inboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === inboundStartStopRoutePoints.startRoutePointId
    ),
    toHomeEnd: route.inboundRoutePoints.find(
      ({ routePointId }) =>
        routePointId === inboundStartStopRoutePoints.stopRoutePointId
    )
  };
}

function getTodaysParticipants(participants) {
  const today = getTodaysDay(weekdays_upper);
  const todaysParticipants = participants.filter(p => p.rideDays.includes(today));
  return todaysParticipants;
}

function getDriverStatus(data){
  const status = data.toLowerCase()
  if(status === 'approved' || status === 'expiring' || status === 'expiring today' || status === 'expiring license' ) {
        return null;
  } else if (status === 'pending' || status === 'pending application' || status === 'pending approval' || status === 'pending mvr' || status.includes("exception")) {
        return "Pending Driver Approval";
  } else if(status === 'expired' || status === 'expired license') {
        return "License Expired";
  } else if(status === 'declined'){
        return "Non-Approved Driver";
  }
}
