import UserService from '../shared/user-service';
import { isAllowed } from '../shared/permissions/RestrictTo';
import { PERMISSION_ACCOUNT_DASHBOARD_USER } from '../shared/permissions/permissions';

export default {
  isAuthorized: () => {
    const userProfile = UserService.getUserProfile();
    if (userProfile) {
      return isAllowed([PERMISSION_ACCOUNT_DASHBOARD_USER]);
    }
    return false;
  }
};
