import React from 'react';
import { Vanpool, Calendar } from '../shared/icons';
import ImpactDataStackedBar from './ImpactDataStackedBar';
import ImpactDataInfo from '../vanpool-vehicle/impact-data/ImpactDataInfo';

export default function({ title, timePeriod, data, isVanpool }) {
  const {
    milesSaved,
    parkingSpotsSaved,
    parkingSavings,
    fuelGallonsSaved,
    fuelSavings,
    co2Saved
  } = data || {};
  return (
    <div className="impact-data-container">
      <div className="top-bar">
        <div className="label">
          {isVanpool ? <Vanpool /> : <Calendar />} {title}{' '}
          <ImpactDataInfo includeAccountDashboardInfo={true} />
        </div>
        <div className="selected-dates">{timePeriod}</div>
      </div>
      <div className="content">
        <ImpactDataStackedBar label="Miles Saved" {...milesSaved} metric="mi" />
        <ImpactDataStackedBar label="CO2 Saved" {...co2Saved} metric="lbs" />
        <ImpactDataStackedBar
          label="Fuel Gallons Saved"
          {...fuelGallonsSaved}
          metric="gal"
        />
        <ImpactDataStackedBar label="Fuel Savings" {...fuelSavings} />
        <ImpactDataStackedBar
          label="Parking Spots Saved"
          {...parkingSpotsSaved}
        />
        <ImpactDataStackedBar label="Parking Savings" {...parkingSavings} />
      </div>
    </div>
  );
}
