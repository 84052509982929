import {useCallback,useState} from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from '../../helpers/imageHelpers';
import RangeSlider from '../range-slider/RangeSlider';
import { Close } from '../../../shared/icons';
import rotateImg from '../../../_styles/images/rotate.png';
import analyticsService from '../../../shared/analytics/analytics-service';

export default function CropPicture(props) {
  const aspect = 1 / 1;
  const [crop,setCrop] = useState({x: 0,y: 0});
  const [cropSize,setCropSize] = useState();
  const [croppedAreaPixels,setCroppedAreaPixels] = useState(null)
  const [zoom,setZoom] = useState(1);
  const [rotation,setRotation] = useState(0);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        props.src,
        croppedAreaPixels,
        rotation
      )
      props.setCompletedCrop(croppedImage);
      analyticsService.analyticsProcessEvent({
        "event": "click_generic",
        "context": {
          "event_action": "save"
        }
      });
      close();
    } catch(e) {
      console.error(e)
    }
  },[croppedAreaPixels])

  const onCropComplete = useCallback((croppedArea,croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  },[])

  const rotationChange = () => {
        let newRotation = rotation + 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        setRotation(newRotation)
  }
  
  function close() {
    setZoom(1);
    props.close();
  }

  return (
    <div className='crop-container' tabIndex={0} aria-live='polite' role='alert' id='modal' aria-label='Edit image'>
      <div className="header">
        <span className="close" onClick={close} role='button' aria-label='close' autoFocus={true}>
          <Close aria-hidden='true'/>
        </span>
      </div>
      <div className='image-content'>
        <div className='img-container' aria-hidden='true'>
          <Cropper
            image={props.src}
            crop={crop}
            cropSize={cropSize}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            rotation={rotation}
            aspect={aspect}
            showGrid={false}
            setCropSize={setCropSize}
            objectFit="contain"
            classes="cropper"
            zoom={zoom}
          />
        </div>
        <div className='rotate' onClick={rotationChange} role='button' aria-label='rotate'>
          <img className='image' src={rotateImg} aria-hidden='true'/>
          <span aria-hidden='true'>rotate</span>
        </div>
        <div>
          <RangeSlider min={1} max={3} slide={zoom} onSlide={setZoom} />
        </div>
        <div className='button-container'>
           <button className="cancel" onClick={close}>Cancel</button>
           <button className="save" onClick={showCroppedImage}>Save</button>

        </div>
      </div>
    </div>
  )
}