import * as actionTypes from '../redux/actionTypes';
import NA_VALUE from '../shared/na';

const initialState = {
  vanpoolDetails: {
    vanpoolId: null,
    vanpoolName: null,
    vehicleType: null,
    vehicleImage: null,
    lastPreventativeMaintenanceOdometer: NA_VALUE,
    lastPreventativeMaintenanceOdometerDate: NA_VALUE,
    nextScheduledMaintenanceOdometer: NA_VALUE,
    nextScheduledMaintenanceDate: NA_VALUE
  },
  fuelCardBalance: null,
  employerContent: null,
  contactInfo: null,
  status: null,
  isTraditionalLead: false,
  isDashboardLoaded: false,
  refreshDashboard: null,
  contactInfoLoaded: false,
  tripRecordingStatusLoaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DASHBOARD_LOADING:
      return { ...state, ...action.data, isDashboardLoaded: false };
    case actionTypes.DASHBOARD_LOADED:
      return { ...state, ...action.data, isDashboardLoaded: true };
    case actionTypes.EMPLOYER_CONTENT_LOADED:
      return { ...state, employerContent: action.data };
    case actionTypes.CONTACT_INFO_LOADED:
      return { ...state, contactInfo: action.data, contactInfoLoaded: true };
    case actionTypes.TRIP_RECORDING_STATUS_LOADED:
      return { ...state, status: action.data, tripRecordingStatusLoaded: true };
    case actionTypes.SET_TRADITIONAL_LEAD:
      return { ...state, isTraditionalLead: action.data };
    case actionTypes.SET_REFRESH_DASHBOARD:
      return {...state, refreshDashboard: action.refresh};
    case actionTypes.DASHBOARD_REFRESHED:
      return {...state, refreshDashboard: null};
    case actionTypes.TRIP_RECORDING_STATUS_SUBMITTED:
      return {
        ...state,
        status: { ...state.status, displayDidEveryoneRideToday: false }
      };
    default:
      return state;
  }
};
