import React, { Component } from 'react';
import { round } from 'lodash';

import currencyFormatter from '../shared/currencyFormatter';

const style = {
  root: {
    width: '100%',
    height: '2.5rem',
    position: 'relative',
    border: 'solid 2px #a5b0ba',
    borderRadius: '25px',
    display: 'inline-block'
  },
  potential: {
    position: 'absolute',
    padding: '1rem',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  },
  total: function(zIndex, width) {
    return {
      zIndex: zIndex,
      position: 'absolute',
      padding: '1rem',
      top: 0,
      left: 0,
      height: '100%',
      width: `${width}%`,
      backgroundColor: '#5e83ad',
      color: 'white',
      border: 'solid 1px white',
      borderRadius: '25px',
      display: 'inline-block',
      lineHeight: '0px',
      textAlign: 'right',
      maxWidth: '100%'
    };
  },
  goals: function(zIndex, width) {
    return {
      zIndex: zIndex,
      position: 'absolute',
      padding: '1rem',
      top: 0,
      left: 0,
      width: `${width}%`,
      maxWidth: '100%',
      height: '100%',
      backgroundColor: '#a5b0ba',
      display: 'inline-block',
      border: 'solid 1px white',
      borderRadius: '25px'
    };
  }
};

export default class StackedBar extends Component {
  render() {
    let { total, potential, goals, isCurrency } = this.props;

    const totalWidth = potential ? (total / potential) * 100 : 0;
    const goalsWidth = potential ? (goals / potential) * 100 : 0;

    total = total || 0;

    return (
      <div className="stacked-bar" style={style.root}>
        <div className="bar-potential" style={style.potential}></div>
        <div style={style.goals(1, goalsWidth)}></div>
        <div style={style.total(2, totalWidth)}>
          {isCurrency
            ? currencyFormatter.formatUSD(total)
            : round(total, 1).toLocaleString()}
        </div>
      </div>
    );
  }
}
